import { React, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import useWindowSize from '../../Share/Normal/useWindowSize';

import StatisticsView from './StatisticsView';
import UserAuthenticationView from './UserAuthenticationView';

function TotalStatisticsView() {
  const size = useWindowSize();

  const [isOver, setIsOver] = useState(false);

  const AuthRef = useRef();
  const StatisticsRef = useRef();

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  function SuccessFn() {
    AuthRef.current.setOpacity(0);
    AuthRef.current.setZindex(-3);
    StatisticsRef.current.setOpacity(1);
    StatisticsRef.current.setZindex(3);
  }

  return (
    <TopLevelWrapper>
      <UserAuthenticationView ref={AuthRef} isOver={isOver} clickFn={SuccessFn}/>
      <StatisticsView ref={StatisticsRef} isOver={isOver} size={size}/>
    </TopLevelWrapper>
  )
}

export default TotalStatisticsView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
