import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../Model/Share/Network/FetchModule.js';

import ValidMemberView from '../../Model/Individual/MemberModel/ValidMemberView.js';
import MemberDetailView from '../../Model/Individual/MemberModel/MemberDetail/MemberDetailView.js';
import useWindowSize from '../../Model/Share/Normal/useWindowSize.js';

function ValidMemberInfoPage() {
  const history = useHistory();
  const size = useWindowSize();

  const [data, setData] = useState([]);
  const [isOver, setIsOver] = useState(false);

  const ValidRef = useRef();

  useEffect(() => {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('member/alive/', 'POST');
        
        if (responseData.status === 200) setData(responseData.data);
        else if (responseData.status === 401) history.push('/');
			}
		)();
  }, [history])


  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  return (
    <TopLevelWrapper>
      <ValidMemberView ref={ValidRef} data={data} isOver={isOver}/>
    </TopLevelWrapper>
  )
}

export default ValidMemberInfoPage;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // width: 100%;
`;