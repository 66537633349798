import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import LockerMemberSearchInput from '../../../Component/LockerComponent/LockerMemberSearchInput';
import LockerStartInput from '../../../Component/LockerComponent/LockerStartInput';
import CalenderInput from '../../../Component/LockerComponent/LockerCalenderInput';
import CountDaysInput from '../../../Component/LockerComponent/CountDaysInput';

const LockerStartModal = forwardRef((props, ref) => {
  const { isOver, exitClick, saveClick } = props;

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [dayCount, setDayCount] = useState(endDate.diff(startDate, 'days'));

  const MemberRef = useRef();
  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();
  const PriceRef = useRef();
  const CardRef = useRef();
  const CashRef = useRef();

  useImperativeHandle(ref, () => ({
		getMemberData: () => {
      return MemberRef.current.getValue();
    },
		getStartDate: () => {
      return StartCalenderRef.current.getValue();
    },
		getEndDate: () => {
      return EndCalenderRef.current.getValue();
    },
		getPrice: () => {
      return PriceRef.current.getValue();
    },
		getCard: () => {
      return CardRef.current.getValue();
    },
		getCash: () => {
      return CashRef.current.getValue();
    },
	}));

  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  useEffect(() => {
    setEndDate(startDate.clone().add(dayCount, 'days'));
  }, [dayCount])

  useEffect(() => {
    if (startDate > endDate) {
      alert("사용 종료일이 시작일보다 빠르게 설정할 수 없습니다.");
      setEndDate(startDate);
    }
  }, [startDate, endDate])

  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  return (
    <TopLevelWrapper>
      <ModalView isOver={isOver}>
        <TotalWrapper>
          <Title>사용 시작</Title>
          <ComponentWrapper isOver={isOver}>
            <LockerMemberSearchInput ref={MemberRef} isOver={isOver} />
            <InputWrapper isOver={isOver}>
              <IndividualWrapper>
                <Text>적용 일수</Text>
                <CountDaysInput value={endDate.diff(startDate, 'days')} setValue={setDayCount} />
              </IndividualWrapper>
              <IndividualWrapper>
                <Text>시작일</Text>
                <CalenderInput ref={StartCalenderRef} value={startDate} setValue={setStartDate} isOver={isOver} disabled={false} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width={isOver ? `209` : `153`} />
              </IndividualWrapper>
              <IndividualWrapper>
                <Text>종료일</Text>
                <CalenderInput ref={EndCalenderRef} value={endDate} setValue={setEndDate} isOver={isOver} disabled={false} className='EndCalender' clickFn={CalenderClick} placeholder='종료일' width={isOver ? `209` : `153`} />
              </IndividualWrapper>
            </InputWrapper>
          </ComponentWrapper>
          <ButtonWrapper isOver={isOver}>
            <ExitButton isOver={isOver} onClick={exitClick}>취소</ExitButton>
            <SaveButton isOver={isOver} onClick={saveClick}>저장하기</SaveButton>
          </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
})

export default LockerStartModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: ${props => props.isOver ? `674px` : `290px`};
  width: ${props => props.isOver ? `323px` : `290px`};
  // height: ${props => props.isOver ? `382px` : `478px`};
  // height: 435px;
  height: 477px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  // flex-direction: ${props => props.isOver ? `row` : `column`};
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 36px;
  margin-top: 15px;
  
  // width: ${props => props.isOver ? `618px` : `262px`};
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `13px` : `8px`};

  width: 100%;
`;

const IndividualWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  gap: ${props => props.isOver ? `16px` : `13px`};
  margin-top: 36px;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `72px` : `55px`};
  height: ${props => props.isOver ? `38px` : `34px`};

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #CBCBCB;

  transition: all 0.2s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `78px`};
  height: ${props => props.isOver ? `40px` : `36px`};

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;