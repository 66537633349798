import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../Model/Share/Network/FetchModule';

function PaymentCoachSubscribeCompleteToss({match}) {

  const history = useHistory();

  useEffect(() => {
    window.sessionStorage.setItem('Token', "Bearer " + match.params.token);
    var status = match.params.status;

    if (status === 'success') {
      var customerKey = new URLSearchParams(window.location.search).get("customerKey");
      var authKey = new URLSearchParams(window.location.search).get("authKey");
      console.log("customerKey : " + customerKey);
      console.log("authKey : " + authKey);

      var data = { 
        customerKey: customerKey,
        authKey: authKey,
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/subscribe/tosspayments/membership', 'POST', data);
  
          console.log(responseData);
          if (responseData.status === 200) {
            var extend = window.sessionStorage.getItem("Extend");
            
            if (extend === 'true') {
              const extendResponseData = await fetchModule.postData('membership/extend_coach/' + responseData.data[0], 'POST');
              
              if (extendResponseData.status === 200) {
                alert("결제가 완료되었습니다!\n앱에서 등록을 마무리해주세요!");
              }
            }
            else {
              var registData = {
                storeId: parseInt(window.sessionStorage.getItem("StoreId")),
                storeName: window.sessionStorage.getItem('StoreName'),
                merchantUid: responseData.data[0],
                itemName: window.sessionStorage.getItem('ItemName'),
                userId: '',
                approval: 0
              }
              
              const registResponseData = await fetchModule.postDataOfCoach('registration/create', 'POST', registData);
              
              if (registResponseData.status === 200) {
                alert("결제가 완료되었습니다!\n앱에서 등록을 마무리해주세요!");
              }
            }
          }
        }
      )();
    }
    else if (status === 'fail') {
      alert("결제에 실패하였습니다.");
    }



    window.location.href = "linkcoach://membershipPurchase/";

    setTimeout(() => (
      window.open("about:blank", "_self"),
      window.close()
    ), 5000)

  }, [])

  return <div/>
}

export default PaymentCoachSubscribeCompleteToss;

