import { React } from 'react';
import styled from 'styled-components';

const NoticePagination = (props) => {
  const { totalPage, nowPage, prevFn, nextFn, isOver } = props;
  
  return (
    <TopLevelWrapper>
      <Button isOver={isOver} onClick={prevFn}>이전 페이지</Button>
      <Page isOver={isOver}>{nowPage}/{totalPage}</Page>
      <Button isOver={isOver} onClick={nextFn}>다음 페이지</Button>
    </TopLevelWrapper>
  )
}

export default NoticePagination;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 12px;
  margin-left: 8px;
  transition: all 0.3s ease-in-out;
`;

const Button = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #C7C7C7;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
    color: #232323;
  }
`;

const Page = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;