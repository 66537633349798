import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

export default function AlertModal(props) {
  const { firstText, secondText } = props;

  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  return (
    <TopLevelWrapper>
      <Modal>
        <TitleWrapper>
          <Image src='https://picture.link-zone.org/ZoneLogo.png'/>
          <TitleText>Link Zone</TitleText>
        </TitleWrapper>
        <Text style={{ marginTop: '24px' }}>{firstText}</Text>
        <Text style={{ marginTop: '24px' }}>{secondText}</Text>
      </Modal>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 50vh;
  width: 44vh;
  min-width: 280px;
  max-width: 400px;
  height: 30vh;
  min-height: 200px;
  max-height: 300px;

  overflow: hidden;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 20px;
`;

const Image = styled.img`
  width: 20%;
  height: auto;
`;

const TitleText = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 30px;

  color: #FF8B48;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 30px;
  }
  @media (min-height: 640px) {
    font-size: 32px;
  }
  @media (min-height: 720px) {
    font-size: 34px;
  }
  @media (min-height: 760px) {
    font-size: 36px;
  }
  @media (min-height: 800px) {
    font-size: 38px;
  }
  @media (min-height: 1000px) {
    font-size: 40px;
  }
  @media (min-height: 1200px) {
    font-size: 42px;
  }

  :hover {
    cursor: default;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 10px;

  color: #000000;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 14px;
  }
  @media (min-height: 640px) {
    font-size: 16px;
  }
  @media (min-height: 720px) {
    font-size: 18px;
  }
  @media (min-height: 760px) {
    font-size: 16px;
  }
  @media (min-height: 800px) {
    font-size: 18px;
  }
  @media (min-height: 1000px) {
    font-size: 20px;
  }
  @media (min-height: 1200px) {
    font-size: 22px;
  }

  :hover {
    cursor: default;
  }
`;