import { React } from 'react';
import styled from 'styled-components';

import CircleGraph from './CircleGraph';
import StatisticsChart from './StatisticsChart';

const GraphBox = (props) => {
  const { today, data, isOver, size, isDoughnutLoading, setIsDoughnutLoading  } = props;

  return (
    <TopLevelWrapper isOver={isOver}>
      <CircleGraph today={today} data={data} isOver={isOver} isDoughnutLoading={isDoughnutLoading} setIsDoughnutLoading={setIsDoughnutLoading}/>
      <StatisticsChart today={today} isOver={isOver} size={size}/>
    </TopLevelWrapper>
  )
}

export default GraphBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `30px` : `15px`};
  margin-top: ${props => props.isOver ? `24px` : `10px`};
`;

