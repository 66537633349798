import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

// Model Import //
import PermuteView from '../../Model/Individual/AccountModel/PermuteView.js';
import CompletePermuteView from '../../Model/Individual/AccountModel/CompletePermuteView.js';
import useWindowSize from '../../Model/Share/Normal/useWindowSize.js';

function PermutePage() {
  const size = useWindowSize();

  const [isOver, setIsOver] = useState(false);

  const PermuteRef = useRef();
  const CompleteRef = useRef();

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  function permuteButtonClick() {
    PermuteRef.current.setOpacity();
    CompleteRef.current.setOpacity();
  }

  return (
    <Container>
      <PermuteView ref={PermuteRef} permuteButtonClick={permuteButtonClick} isOver={isOver}/>
      <CompletePermuteView ref={CompleteRef} isOver={isOver}/>
    </Container>
  )
}

export default PermutePage;

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 132px;
  top: 0;
  left: 0;

  // width: 1920px;
  width: 100vw;
  // min-width: 1194px;
  height: 100vh;

  background-color: #FDFCFB;
`;