import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Notice from '../../../Component/MainComponent/Notice.js';

import NoticeList from '../NoticeModel/NoticeList.js';
import Pagination from '../../../Component/MainComponent/NoticePagination.js';

import FetchModule from '../../Share/Network/FetchModule.js';

const NoticeView = (props) => {
  const { isOver } = props;

  const history = useHistory();

  const [data, setData] = useState([]);
  const [useData, setUseData] = useState([]);
  const [nowPage, setNowPage] = useState(1);
  
  useEffect(() => {

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('notice/', 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) setData(responseData.data);
        else if (responseData.status !== 204) {}
      }
    )();
  }, [history])

  useEffect(() => {
    var newDataList = data.slice((nowPage - 1) * 12, (nowPage) * 12);
    setUseData(newDataList);
  }, [nowPage, data])

  function clickNextPage() {
    if (nowPage >= Math.ceil(data.length/12)) return;

    setNowPage(nowPage + 1);
  }

  function clickPrevPage() {
    if (nowPage <= 1) return;

    setNowPage(nowPage - 1);
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      <TitleWrapper>
        <Title isOver={isOver}>공지사항</Title>
        <ExplanationWrapper isOver={isOver}>
          <Explanation isOver={isOver}>일정, 회원관리 등을 공지할 수 있어요</Explanation>
          <Go isOver={isOver} onClick={() => history.push('/notice')}>전체 보기</Go>
        </ExplanationWrapper>
      </TitleWrapper>
      <ViewBox isOver={isOver}>
        <ListBox>
          <Notice data={useData} isOver={isOver}/>
        </ListBox>
        <Pagination 
          isOver={isOver}
          nowPage={nowPage}
          totalPage={Math.ceil(data.length / 12)}
          prevFn={clickPrevPage}
          nextFn={clickNextPage} />
      </ViewBox>
    </TopLevelWrapper>
  )
};

export default NoticeView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 692px;
  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(297px, 92.8125vw)`};
  // height: 385px;
  // height: ${props => props.isOver ? `592px` : `371px`};

  border-radius: 30px;
  background-color: #FFFFFF;
  // background-color: red;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  margin-bottom: ${props => props.isOver ? `109px` : `13px`};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `10px`};

  color: #232323;

  margin: ${props =>props.isOver ? `28px 0 0 0` : `18px 0 0 0`};
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: ${props => props.isOver ? `4px` : `0px`};

  // width: 636px;
  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(255px, 79.6875vw)`};
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};
  
  color: #777777;
  
  margin: 0 0 0 0;
`;

const Go = styled.p`
  font-family: NotoSansKr-Medium;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #FF8B48;
  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  // width: 636px;
  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(255px, 79.6875vw)`};
  // height: ${props => props.isOver ? `448px` : `298px`};

  gap: 16px;
  padding-top: ${props => props.isOver ? `20px` : `11px`};

  margin-top: ${props => props.isOver ? `24px` : `6px`};
  margin-bottom: ${props => props.isOver ? `27px` : `23px`};

  border-top: 2px solid #ECECEC;
  // border-bottom: 2px solid #ECECEC;
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;

  // background-color: red;
`;

