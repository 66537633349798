import { React, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SessionStorage from '../../Share/Network/SessionStorage';

import FetchModule from '../../Share/Network/FetchModule';

function LessonAllLockModal(props) {
  const { allReserveLock, setAllReserveLock, date, cancel } = props;

  const history = useHistory();

  useEffect(() => {
    document.body.style.cssText = `
            position: fixed; 
            top: 0px;
            left: 0px;
            overflow-y: hidden;
            width: 100vm;
            height: 100vm`
      ;
    return () => {
      document.body.style.cssText = '';
      window.scrollTo(0, 0);
    };
  }, []);
  
  function onClickAllReserveLockButton() {
    // console.log(date.format('YYYY-MM-DDT'));
    var subURL = allReserveLock ? 'reserve_lock/create' : 'reserve_lock/delete';
    var requestData = {
      scheduleId: -1,
      // storeId: SessionStorage.getStoreId(),
      storeId: window.localStorage.getItem('StoreId'),
      startDate: date.format('YYYY-MM-DDT') + '00:00:00',
      endDate:  date.format('YYYY-MM-DDT') + '23:59:59',
      reason: '수업 전체잠금'
    }

    // console.log(subURL);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData(subURL, 'POST', requestData);

        if (responseData.status === 200) {
          if (allReserveLock) alert("잠금설정이 완료되었습니다.");
          else alert("잠금 해제가 완료되었습니다.");
          setAllReserveLock(!allReserveLock);
          cancel();
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TitleWrapper>
          <Title>{allReserveLock ? `예약 전체 잠금` : `전체 잠금 해제`}</Title>
        </TitleWrapper>
        <TextWrapper>
          <Text>{allReserveLock ? '해당 날짜의 모든 수업 예약이 불가능하게 설정됩니다.' : '해당 날짜의 모든 수업 예약이 가능하게 설정됩니다.'}</Text>
          <Text>{allReserveLock ? '잠금을 진행하시겠습니까?' : '잠금을 해제하시겠습니까?'}</Text>
        </TextWrapper>
        <ButtonWrapper>
          <ExitButton onClick={cancel}>취소</ExitButton>
          <SaveButton onClick={onClickAllReserveLockButton}>확인</SaveButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default LessonAllLockModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `371px` : `290px`};
  height: ${props => props.isOver ? `248px` : `260px`}; 

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isOver ? `315px` : `240px`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isOver ? `315px` : `240px`};

  margin-top: 16px;
  gap: 12px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: ${props => props.isOver ? `315px` : `240px`};

  gap: 16px;

  margin-top: 52px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #595959;
    color: #595959;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 38px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;