import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../Model/Share/Network/FetchModule';

function ZonePaymentSubscribeCompleteToss({match}) {

  const history = useHistory();

  useEffect(() => {
    var status = match.params.status;

    if (status === 'success') {
      var customerKey = new URLSearchParams(window.location.search).get("customerKey");
      var authKey = new URLSearchParams(window.location.search).get("authKey");
      console.log("customerKey : " + customerKey);
      console.log("authKey : " + authKey);

      var data = { 
        customerKey: customerKey,
        authKey: authKey,
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('zone_subscribe/create', 'POST', data);
  
          console.log(responseData);
          
          if (responseData.status === 200) alert("정기권 구매가 완료되었습니다!");
          else alert("정기권 구매에 실패하였습니다.");

          history.push("/main");
        }
      )();
    }
    else if (status === 'fail') {
      alert("결제에 실패하였습니다.");
    }

  }, [])

  return <div/>
}

export default ZonePaymentSubscribeCompleteToss;

