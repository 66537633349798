import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import FetchModule from '../../Share/Network/FetchModule.js';
import PermutePWInput from '../../../Component/AccountComponent/PermutePWInput.js';

import IdentifierPageLogoPath from '../../../image/SearchIdentifierLogo.png';

const PermuteView = forwardRef((props, ref) => {
  const { permuteButtonClick, isOver } = props;

  const [pwClickCount, setPwClickCount] = useState(0);
  const [confirmClickCount, setConfirmClickCount] = useState(0);
  const [planationText, setPlanationText] = useState('필수항목을 입력해주세요');
  const history = useHistory();
  const TopLevelRef = useRef();
  const PwRef = useRef();
  const ConfirmRef = useRef();

  const PwPlanationRef = useRef();
  const ConfirmPlanationRef = useRef();

  useImperativeHandle(ref, () => ({
    setOpacity: () => {
      TopLevelRef.current.style.opacity = 0;
    }
  }));

  useEffect(() => {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('identifier/asp/permute/' + window.sessionStorage.getItem("PermuteToken"), 'POST');
      	// console.log("ResponseData : " + responseData);
        // console.log(responseData);
				 
				if (responseData.status !== 200) {
          alert("올바르지 않은 접근입니다.");
          history.push("/");
        }
        else {
          window.sessionStorage.setItem('name', responseData.data[0]);
        }
			}
		)();
  }, [])

  useEffect(() => {
		if (pwClickCount === 0 && confirmClickCount === 0) return;

		if (PwRef.current.getValue() === '' && pwClickCount > 0) PwPlanationRef.current.style.opacity = 1;
    else PwPlanationRef.current.style.opacity = 0;

		if (ConfirmRef.current.getValue() === '' && confirmClickCount > 0) ConfirmPlanationRef.current.style.opacity = 1;
		else ConfirmPlanationRef.current.style.opacity = 0;
	}, [pwClickCount, confirmClickCount])


  // Input 컴포넌트 클릭시
  function clickCountFn(className, clickCount) {
    if (className === 'PWInput') setPwClickCount(clickCount);
    else if (className === 'ConfirmInput') setConfirmClickCount(clickCount);
  }

  // 변경버튼 클릭시
  function changeButtonClick() {	
    var password = PwRef.current.getValue();
    var confirmPassword = ConfirmRef.current.getValue();
    var invalidCount = 0;

		if (password === '') { 
			PwRef.current.plusClickCount();
			PwRef.current.setDefaultPlaceholder('비밀번호를 입력해주세요');
			// PwPlanationRef.current.style.opacity = 1;
			
			invalidCount += 1;
		} 
	
		if (confirmPassword === '') { 
			ConfirmRef.current.plusClickCount();
			ConfirmRef.current.setDefaultPlaceholder('다시한번 더 확인해주세요');
			// ConfirmPlanationRef.current.style.opacity = 1;
			
			invalidCount += 1;
		} 

    if (password !== confirmPassword) {
      ConfirmRef.current.setValue('');
      ConfirmRef.current.setDefaultPlaceholder('위에 변경하신 비밀번호와 일치하지 않습니다');

      invalidCount += 1;
    }

    if (invalidCount > 0) return;
    // permuteButtonClick();
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('identifier/asp/permute/' + sessionStorage.getItem("PermuteToken") + '/' + password, 'POST');
      	// console.log("ResponseData : " + responseData);
				 
				if (responseData.status === 200) permuteButtonClick();
        else alert("비밀번호 변경에 실패하였습니다.\n잠시후 다시시도해주세요.");
			}
		)();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TopTitleWrapper>
        <Logo src={IdentifierPageLogoPath}/>
        <TopTitle>Link Zone</TopTitle>
      </TopTitleWrapper>
      <InfoBox isOver={isOver}>
        <InfoWrapper isOver={isOver}>  
          <Title isOver={isOver}>비밀번호 변경</Title>
          <InputInfo isOver={isOver}>새로운 비밀번호</InputInfo>
          {/* <SignUpPWInput ref={PwRef} className='PWInput' placeholder='비밀번호를 입력해주세요' type='password' clickCountFn={clickCountFn} /> */}
          <PermutePWInput ref={PwRef} className='PWInput' placeholder='비밀번호를 입력해주세요' type='password' clickCountFn={clickCountFn} />
          <AccountExplanation ref={PwPlanationRef}>필수항목을 입력해주세요</AccountExplanation>
          <InputInfo>비밀번호 확인</InputInfo>
          {/* <SignUpPWInput ref={ConfirmRef} className='ConfirmInput' placeholder='한번 더 확인해주세요' type='password' clickCountFn={clickCountFn} /> */}
          <PermutePWInput ref={ConfirmRef} className='ConfirmInput' placeholder='한번 더 확인해주세요' type='password' clickCountFn={clickCountFn} />
          <AccountExplanation ref={ConfirmPlanationRef}>{planationText}</AccountExplanation>
          <SendingButton onClick={changeButtonClick} isOver={isOver}>
            <SendingButtonText>비밀번호 변경</SendingButtonText>
          </SendingButton>
        </InfoWrapper> 
      </InfoBox>
    </TopLevelWrapper>
  )
})

export default PermuteView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  opacity: 1;

  transition: all 0.3s ease-in-out;
`;

const TopTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;

  margin-right: 8px;
`;

const TopTitle = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 24px;
  color: #FF8B48;

  margin: 0 0 0 0;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(449px, 58.46354166666667vw)` : `max(298px, 93.125vw)`};
  height: ${props => props.isOver ? `338px` : `265px`};

  margin-top: 16px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  transition: all 0.3s ease-in-out;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: ${props => props.isOver ? `max(369px, 48.046875vw)` : `max(250px, 78.125vw)`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 18px;

  color: #000000;

  margin: ${props => props.isOver ? `0 0 31px 0` : `0 0 15px 0`};
`;

const InputInfo = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #000000;

  margin: ${props => props.isOver ? `11px 0 0 0` : `7px 0 0 0`};
`;

const AccountExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;

	color: #FF3131;

	margin: 4px 0 0 0;

	opacity: 0;

  transition: all 0.3s ease-in-out;
`;

const SendingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(369px, 48.046875vw)` : `max(250px, 78.125vw)`};
  height: ${props => props.isOver ? `28px` : `23px`};

  margin-top: ${props => props.isOver ? `13px` : `16px`};

  background-color: #FF8B48;
  border-radius: 23px;

  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
		background-color: #FF7322;
	}
`;

const SendingButtonText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 12px;

  color: #FFFFFF;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;