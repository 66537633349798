import { React, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';

import NumberOfMonthSelectBox from './NumberOfMonthSelectBox';

import DownImage from '../../image/down.png';
import UpImage from '../../image/up.png';

const MemberShipValidity = forwardRef((props, ref) => {
  const { isOver, selectClickFn } = props;

  const [dateVal, setDateVal] = useState(0); 
	const [changed, setChanged] = useState(false);

  const InputRef = useRef();

  const SelectRef = useRef();

  useImperativeHandle(ref, () => ({
    setValue: (val) => {
      setDateVal(val);
    },
		getValue: () => {
      var units = SelectRef.current.getValue();
      // 일, 개월, 년을 서버에 올리기 위해 분으로 환산하기 위한 곱수
      var multiplier = 1; 
      if (units === '일') multiplier = 1;
      else if (units === '개월') multiplier = 30;
      else if (units === '년') multiplier = 365;

			return (dateVal * multiplier);
    },
    setIsClicked: () => {
      SelectRef.current.setIsClicked();
    }
	}));

	function handleChange(e) {
    var regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    var result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    
    if (e.target.value === '') setDateVal(0);
		else setDateVal(parseInt(result));
		setChanged(true);
	}

	function handleFocus(e) {

	}

  function upButtonClick() {
    setDateVal(dateVal + 1);
  }

  function downButtonClick(){
    if (dateVal > 0) setDateVal(dateVal - 1);
  }

  return (
    <TopLevelWrapper>
      <Input 
        isOver={isOver}
      	ref={InputRef}
        placeholder={0}
        value={dateVal}
        onChange={handleChange}
        onFocus={handleFocus}/>
      <ButtonWrapper>
        <Button img={UpImage} onClick={upButtonClick}/>
        <Button img={DownImage} onClick={downButtonClick}/>
      </ButtonWrapper>
      <NumberOfMonthSelectBox ref={SelectRef} isOver={isOver} selectClickFn={selectClickFn}/>
    </TopLevelWrapper>
  )
})

export default MemberShipValidity;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  margin-top: 8px;

  transition: all 0.3s ease-in-out;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: ${props => props.isOver ? `85px` : `51px`};
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  padding: 0 0 0 8px;
  margin: 0 5px 0 0;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
  margin-right: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background: url(${props => props.img}) no-repeat center;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;