import { React, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import DayCountInput from '../../../../../Component/MemberComponent/MemberDetail/DayCountInput.js';
import DayApplyInput from '../../../../../Component/MemberComponent/MemberDetail/DayApplyInput.js';
import MemberShipSearchInput from '../../../../../Component/MemberComponent/MemberDetail/MemberSearchInput.js';

import FetchModule from '../../../../Share/Network/FetchModule.js';

const MemberShipExtensionModal = (props) => {
  const { isOver, data, className, exitClickFn } = props;

  const history = useHistory();

  const [memberData, setMemberData] = useState([]);
  const [totalIsClicked, setTotalIsClicked] = useState(false);

  const ApplyRef = useRef();
  const MemberSelectRef = useRef();

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('member/alive/', 'GET');
       
        if (responseData.status === 200) {
          setMemberData(responseData.data);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
        else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();
  }, [history])

  function countInterLock(value) {
    ApplyRef.current.setValue(value);
  }

  function exitClick() {
    exitClickFn(className);
  }

  function saveClick() {
    if (totalIsClicked) {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('membership/extends/all/' 
                                                          // + window.sessionStorage.getItem('StoreId') + "/" 
                                                          + window.localStorage.getItem('StoreId') + '/'
                                                          + ApplyRef.current.getValue(), 'POST', null);

          if (responseData.status === 200) {
            exitClickFn(className);
          }
          else if (responseData.status === 401) {
            alert('로그인 후 다시시도하세요');
            history.push('/');
          }
          else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
        }
      )();
    }
    else {
      var valueList = MemberSelectRef.current.getDataList();
      var dataList = [];

      dataList.push(data[0].usageId);

      for (var i = 0; i < valueList.length; i++) {
        dataList.push(valueList[i].usageId);
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('membership/extends/' 
                                                          // + window.sessionStorage.getItem('StoreId') + "/" 
                                                          + window.localStorage.getItem('StoreId') + '/'
                                                          + ApplyRef.current.getValue(), 'POST', dataList);

          if (responseData.status === 200) {
            exitClickFn(className);
          }
          else if (responseData.status === 401) {
            alert('로그인 후 다시시도하세요');
            history.push('/');
          }
          else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
        }
      )();
    }
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
        <Title>회원권 연장</Title>
        <ViewWrapper>
          <DateCountWrapper>
            <DayCountInput isOver={isOver} changeFn={countInterLock}/>
            <DayApplyInput ref={ApplyRef} isOver={isOver}/>
          </DateCountWrapper>
          <MemberShipSearchInput ref={MemberSelectRef} memberData={memberData} isOver={isOver}/>
        </ViewWrapper>
        <ButtonWrapper>
          <CheckBoxWrapper>
            <CheckBox isClicked={totalIsClicked} onClick={() => setTotalIsClicked(!totalIsClicked)}/>
            <CheckBoxText>모든 회원 일괄 적용</CheckBoxText>
          </CheckBoxWrapper>
          <Wrapper>
            <ExitButton onClick={exitClick}>취소</ExitButton>
            <SaveButton onClick={saveClick}>저장하기</SaveButton>
          </Wrapper>
        </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default MemberShipExtensionModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  // align-items: ${props => props.isOver ? `flex-start` : `center`};
  align-items: center;

  width: ${props => props.isOver ? `692px` : `290px`};
  height: ${props => props.isOver ? `354px` : `536px`};

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: ${props => props.isOver ? `0 0 27px 28px` : `0 0 8px 0`};
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: center;
  align-items: flex-start;

  gap: 36px;
`;

const DateCountWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `30px` : `19px`};

  margin: ${props => props.isOver ? `0 0 0 28px` : `0 0 0 0`};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  align-items: center;

  gap: 16px;

  margin: ${props => props.isOver ? `36px 0 0 0` : `36px 0 0 50px`};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  margin: ${props => props.isOver ? `0 0 0 341px` : `0 0 0 0`};
`;

const CheckBox = styled.div`
  width: 14px;
  height: 14px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;

  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
  }
`;

const CheckBoxText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;