import { React } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const MemberList = (props) => {
  const { column, data, isOver } = props;

  const history = useHistory();
  
  return (
    <TopLevelWrapper>
      <Table>
        <Head>
          <HeadTr isOver={isOver}>
            <Td style={ isOver ? { marginRight: `max(69px, 8.984375vw)`, marginLeft: '8px' } : { marginRight: `max(18px, 5.625vw)`, marginLeft: '6px' }} isOver={isOver}>이름</Td>
            <Td style={ isOver ? { marginRight: `max(56px, 7.291666666666667vw)` } : { marginRight: `max(18px, 5.625vw)` }} isOver={isOver}>성별</Td>
            <Td style={ isOver ? { marginRight: `max(74px, 9.635416666666667vw)` } : { marginRight: `max(21px, 6.5625vw)` }} isOver={isOver}>생년월일</Td>
            <Td style={ isOver ? { marginRight: `max(77px, 10.02604166666667vw)` } : { marginRight: `max(19px, 5.9375vw)` }} isOver={isOver}>핸드폰번호</Td>
            <Td isOver={isOver}>{column}</Td>
          </HeadTr>
        </Head>
      </Table>
      <Table>
        <Body isOver={isOver}>
          {
            data.map((data, index) => (
              <BodyTr key={index} onClick={() => history.push('/member/valid/detail/' + data.memberId)}>
                <Td style={ isOver ? { width: `max(95px, 11.6vw)`, marginLeft: '8px' } : { width: `max(37px, 10.4vw)`, marginLeft: '6px' }} isOver={isOver}>
                  {data.name}
                </Td>
                <Td style={ isOver ? { width: `max(82px, 9.8vw)` } : { width: `max(36px, 10.2vw)` }} isOver={isOver}>
                  {data.gender === 'male' ? '남자' : '여자'}
                </Td>
                <Td style={ isOver ? { width: `max(126px, 14.6vw)` } : { width: `max(59px, 17vw)` }} isOver={isOver}>
                  {data.birthDay[0]
                    + data.birthDay[1]
                    + data.birthDay[2]
                    + data.birthDay[3]
                    + '.'
                    + data.birthDay[4]
                    + data.birthDay[5]
                    + '.'
                    + data.birthDay[6]
                    + data.birthDay[7]}
                </Td>
                <Td style={ isOver ? { width: `max(142px, 16.4vw)` } : { width: `max(64px, 18.2vw)` }} isOver={isOver}>
                  {
                    isOver ? 
                    data.phone[0]
                    + data.phone[1]
                    + data.phone[2]
                    + '-'
                    + data.phone[3]
                    + data.phone[4]
                    + data.phone[5]
                    + data.phone[6]
                    + '-' +
                    + data.phone[7]
                    + data.phone[8]
                    + data.phone[9]
                    + data.phone[10]
                    :
                    data.phone[0]
                    + data.phone[1]
                    + data.phone[2]
                    + data.phone[3]
                    + data.phone[4]
                    + data.phone[5]
                    + data.phone[6]
                    + data.phone[7]
                    + data.phone[8]
                    + data.phone[9]
                    + data.phone[10]
                  }
                </Td>
                <Td style={ isOver ? { width: `max(164px, 21.35416666666667vw)` } : { width: `max(54px, 16.875vw)` }} isOver={isOver}>
                  { column === '등록 날짜' ? data.startDate.split('T')[0].replaceAll('-', '.') : data.endDate.split('T')[0].replaceAll('-', '.') }
                </Td>
              </BodyTr>
            ))
          }
        </Body>
      </Table>
    </TopLevelWrapper>
  )
}

export default MemberList;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  display: flex;
  flex-direction: column;
`;

const Head = styled.thead`

`;

const Body = styled.tbody`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(274px, 85.625vw)`};
  height: ${props => props.isOver ? `200px` : `130px`};

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const HeadTr = styled.tr`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  justify-content: flex-start;
  align-items: center;

  // width: 636px;
  height: ${props => props.isOver ? `32px` : `15px`};

  margin-top: ${props => props.isOver ? `16px` : `6px`};
  border-radius: ${props => props.isOver ? `8px` : `4px`};
  background-color: #ECECEC;
`;

const BodyTr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #F4F2F0;
  }
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  height: ${props => props.isOver ? `36px` : `15px`};

  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};
  color: #595959;
`;