import { React } from 'react';
import styled from 'styled-components';
import NotReceivedInfo from './NotReceivedInfo';

const NotReceivedList = (props) => {
  const { data, isOver } = props;

  return (
    <TopLevelWrapper>
      <Table isOver={isOver}>
        <Body isOver={isOver}>
          <HeadTr isOver={isOver}>
            <Td style={{ minWidth: `26px`, marginRight: `41px`, marginLeft: '8px' }} isOver={isOver}>이름</Td>
            <Td style={{ minWidth: `28px`, marginRight: `40px` }} isOver={isOver}>구분</Td>
            <Td style={{ minWidth: `68px`, marginRight: `206px` }} isOver={isOver}>미수금 내역</Td>
            <Td style={{ minWidth: `68px`, marginRight: `24px` }} isOver={isOver}>총 결제금액</Td>
            <Td style={{ minWidth: `26px`, marginRight: `65px` }} isOver={isOver}>카드</Td>
            <Td style={{ minWidth: `26px`, marginRight: `65px` }} isOver={isOver}>현금</Td>
            <Td style={{ minWidth: `52px`, marginRight: `40px` }} isOver={isOver}>미수금액</Td>
            <Td style={{ minWidth: `160px` }} isOver={isOver}>최초 결제</Td>
          </HeadTr>
          {
            data.map((data, index) => (
              <NotReceivedInfo key={index} data={data} isOver={isOver}/>
            ))
          }
        </Body>
      </Table>
    </TopLevelWrapper>
  )
}

export default NotReceivedList;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  display: flex;
  flex-direction: column;

  width: ${props => props.isOver ? `max(659px, 85.80729166666667vw)` : `max(262px, 81.875vw)`};
  height: ${props => props.isOver ? `428px` : `188px`};

  overflow: scroll;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  height: ${props => props.isOver ? `428px` : `188px`};

  margin-top: ${props => props.isOver ? `16px` : `4px`};

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const HeadTr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  height: ${props => props.isOver ? `32px` : `16px`};

  margin-top: ${props => props.isOver ? `16px` : `8px`};
  padding-bottom: ${props => props.isOver ? `16px` : `8px`};

  border-bottom: 1px solid #ECECEC;
  border-radius: 8px;
  // background-color: #ECECEC;
`;

const BodyTr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #F4F2F0;
  }
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  height: ${props => props.isOver ? `36px` : `15px`};

  color: #595959;
`;

const Text = styled.span`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #CBCBCB;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;