import { React, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import MemberSearchInput from '../../../Component/NoteComponent/MemberSearchInput';

const MemberListModal = forwardRef((props, ref) => {
  const { memberData, exitClick, saveClick } = props;

  const MemberRef = useRef();

  useImperativeHandle(ref, () => ({
		getMemberData: () => {
      return MemberRef.current.getValue();
    },
	}));

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
          <Title>회원 목록</Title>
          <ComponentWrapper>
            <MemberSearchInput ref={MemberRef} memberData={memberData}/>
          </ComponentWrapper>
          <ButtonWrapper>
            <ExitButton onClick={exitClick}>취소</ExitButton>
            <SaveButton onClick={() => saveClick(MemberRef.current.getValue())}>완료</SaveButton>
          </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
})

export default MemberListModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 322px;
  height: 384px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 36px;
  margin-top: 27px;
  
  // width: 267px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
  margin-top: 36px;
  margin-left: 103px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.2s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 74px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;