import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';

import DefaultLogoPath from '../../../image/DefaultLogo.png';
import Image from '../../../Component/Share/Image';
import moment from 'moment';

function MemberInfo(props) {
  const { isOver } = props;
  const history = useHistory();

  const [reserveInfo, setReserveInfo] = useState([]);
  const [newInfo, setNewInfo] = useState([]);
  const [expiredInfo, setExpiredInfo] = useState([]);

  // // 금일 예약 회원 정보 불러오기 //
  // useEffect(() => {
  //   (
  //     async () => {
  //       const fetchModule = new FetchModule();
  //       const responseData = await fetchModule.getIndividualData('reserve/all_list/', 'GET');
        
  //       if (responseData.status === 200) {
  //         setReserveInfo(responseData.data);
	// 			}
  //       else if (responseData.status === 204) {}
  //     }
  //   )();
  // }, [history])

  // // 금월 신규 등록자 명단 불러오기 //
  // useEffect(() => {
  //   (
  //     async () => {
  //       const fetchModule = new FetchModule();
  //       // const responseData = await fetchModule.getData('member/new/', 'GET');
  //       const responseData = await fetchModule.postData('member/new', 'POST');
        
  //       if (responseData.status === 200) {
  //         setNewInfo(responseData.data);
	// 			}
  //       else if (responseData.status === 204) {}
  //     }
  //   )();
  // }, [history])

  // // 만료 회원 불러오기 //
  // useEffect(() => {
  //   (
  //     async () => {
  //       const fetchModule = new FetchModule();
  //       const responseData = await fetchModule.getIndividualData('member/expired_main/', 'GET');
        
  //       if (responseData.status === 200) {
  //         setExpiredInfo(responseData.data);
	// 			}
  //       else if (responseData.status === 204) {}
  //       // else if (responseData.status === 401) history.push('/');
  //     }
  //   )();
  // }, [history])


  // 회원 정보 전체 불러오기 //
  useEffect(() => {
    let requestData = {
      standardDate: moment().format('YYYY-MM-DDT23:59:59')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        // const responseData = await fetchModule.getDataVersion2('member/today/status', 'GET');
        const responseData = await fetchModule.postDataVersion2('member/today/status', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          // setExpiredInfo(responseData.data);
          setReserveInfo(responseData.data.reserveInfos);
          // setAttendanceInfo(responseData.data.attendanceInfos);
          setNewInfo(responseData.data.newInfos);
          setExpiredInfo(responseData.data.expiredInfos);
				}
        else if (responseData.status === 204) {}
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history])

  // 오늘 예약한 회원, 신규 등록회원 클릭시 //
  function onClickMemberImage(data) {
    history.push('/member/valid/detail/' + data.memberId);
  }

  // 만료회원 클릭시 //
  function onClickExpiredMemberImage(data) {
    // history.push('/member/valid/detail/' + data.memberId);
    history.push('/member/valid/detail/' + data.id);
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      <TotalWrapper>
        <TitleWrapper>
          <Title isOver={isOver}>회원 정보</Title>
          <ExplanationWrapper isOver={isOver}>
            <Explanation isOver={isOver}>예약, 신규, 만료 회원 목록을 확인하세요</Explanation>
            <Go isOver={isOver} onClick={() => history.push('/member/valid')}>자세히 보기</Go>
          </ExplanationWrapper>
        </TitleWrapper>
        <MemberInfoWrapper isOver={isOver}>
          <ComponentWrapper isOver={isOver}>
            <SubText isOver={isOver}>오늘 예약한 회원 ({reserveInfo.length})</SubText>
            <MemberWrapper isOver={isOver}>
              {
                reserveInfo.map((data, index) => (
                  <InfoWrapper key={index} onClick={() => history.push('/member/valid/detail/' + data.id + '/course')}>
                    {/* <MemberImage src={data.profile === '' ? DefaultLogoPath : data.profile} /> */}
                    <MemberImage>
                      <Image src={data.profile} defaultSrc={DefaultLogoPath}/>
                    </MemberImage>
                    <MemberName>{data.name}</MemberName>
                  </InfoWrapper>
                ))
              }
            </MemberWrapper>
          </ComponentWrapper>
          <ComponentWrapper isOver={isOver}>
            <SubText isOver={isOver}>신규 등록 회원 ({newInfo.length})</SubText>
            <MemberWrapper isOver={isOver}>
              {
                newInfo.map((data, index) => (
                  <InfoWrapper key={index} onClick={() => onClickMemberImage(data)}>
                    {/* <MemberImage src={data.profile === '' ? DefaultLogoPath : data.profile}/> */}
                    <MemberImage>
                      <Image src={data.profile} defaultSrc={DefaultLogoPath}/>
                    </MemberImage>
                    <MemberName>{data.name}</MemberName>
                  </InfoWrapper>
                ))
              }
            </MemberWrapper>
          </ComponentWrapper>
          <ComponentWrapper isOver={isOver}>
            <SubText isOver={isOver}>만료 예정 회원 ({expiredInfo.length})</SubText>
            <MemberWrapper isOver={isOver}>
              {
                expiredInfo.map((data, index) => (
                  <InfoWrapper key={index} onClick={() => onClickExpiredMemberImage(data)}>
                    {/* <MemberImage src={data.profile === '' ? DefaultLogoPath : data.profile} /> */}
                    <MemberImage>
                      <Image src={data.profile} defaultSrc={DefaultLogoPath}/>
                    </MemberImage>
                    <MemberName>{data.name}</MemberName>
                  </InfoWrapper>
                ))
              }
            </MemberWrapper>
          </ComponentWrapper>
        </MemberInfoWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

export default MemberInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  // width: 692px;
  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(300px, 93.75vw)`};
  height: ${props => props.isOver ? `288px` : `217px`};

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 4px;

  // width: 637px;
  width: ${props => props.isOver ? `max(637px, 82.94270833333333vw)` : `max(255px, 79.6875vw)`};
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;
  
  margin: 0 0 0 0;
`;

const Go = styled.p`
  font-family: NotoSansKr-Medium;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #FF8B48;
  margin: 0 0 0 0;
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const SubText = styled.p`

  min-width: ${props => props.isOver ? `120px` : `75px`};
  font-family: NotoSansKr-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 22px 0 0 0;
`;

const MemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // width: 637px;
  width: ${props => props.isOver ? `max(637px, 82.94270833333333vw)` : `max(255px, 79.6875vw)`};
  height: ${props => props.isOver ? `165px` : `142px`};

  gap: ${props => props.isOver ? `52px` : `36px`};
  margin-top: ${props => props.isOver ? `24px` : `10px`};

  overflow-x: scroll;

  border-top: 2px solid #ECECEC;
  
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  // width: 348px;
  // min-width: ${props => props.isOver ? `120px` : `75px`};
  height: ${props => props.isOver ? `116px` : `103px`};
`;

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  align-items: center;

  margin-top: ${props => props.isOver ? `24px` : `14px`};
  gap: ${props => props.isOver ? `12px` : `10px`};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
`;

const MemberImage = styled.div`
  width: 48px;
  height: 48px;

  border-radius: 48px;

  overflow: hidden;
  
  :hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px #FF8B48);
  }

  transition: all 0.3s ease-in-out;
`;

const MemberName = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 10px;

  margin: 10px 0 0 0;

  overflow:hidden;
  white-space : nowrap;
  text-overflow: ellipsis;
`;

