import { forwardRef, React, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';

import AccountInput from '../../../Component/AccountComponent/AccountInput.js';
import SignUpSelectBox from '../../../Component/AccountComponent/SignUpSelectBox.js';

const SignUpInfo3 = forwardRef((props, ref) => {
	const { isOver } = props;

	const [storeClickCount, setStoreClickCount] = useState(0);
	const [typeClickCount, setTypeClickCount] = useState(0);
	const [telClickCount, setTelClickCount] = useState(0);

	const TopLevelRef = useRef();
	const StoreRef = useRef();
	const TypeRef = useRef();
	const TelRef = useRef();
	const StorePlanationRef = useRef();
	const TelPlanationRef = useRef();

	useImperativeHandle(ref, () => ({
		getStoreName: () => {
			return StoreRef.current.getValue();
		},
		getStoreType: () => {
			return TypeRef.current.getValue();
		},
		getStoreTel: () => {
			return TelRef.current.getValue();
		},
		isBlankStoreName: () => {
			StoreRef.current.setDefaultPlaceholder('센터의 공식명칭을 입력해주세요');
			StoreRef.current.plusClickCount();
			StorePlanationRef.current.style.opacity = 1;
		},
		isBlankStoreTel: () => {
			TelRef.current.setDefaultPlaceholder('센터 대표 전화 번호를 입력해주세요');
			TelRef.current.plusClickCount();
			TelPlanationRef.current.style.opacity = 1;
		},
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
		setLocation: (val) => {
			TopLevelRef.current.style.transform = `translateX(${val}%)`;
			setStoreClickCount(0);
			setTypeClickCount(0);
			setTelClickCount(0);
			StoreRef.current.reset('센터의 공식명칭을 입력해주세요');
			TelRef.current.reset('센터 대표 전화 번호를 입력해주세요');
		}
	}));

	useEffect(() => {
		if (storeClickCount === 0 && telClickCount === 0) return;
		
		if (StoreRef.current.getValue() === '' && storeClickCount > 0) StorePlanationRef.current.style.opacity = 1;
		else StorePlanationRef.current.style.opacity = 0;
		
		if (TelRef.current.getValue() === '' && telClickCount > 0) TelPlanationRef.current.style.opacity = 1;
		else TelPlanationRef.current.style.opacity = 0;
		
	}, [storeClickCount, typeClickCount, telClickCount])

	function clickCountFn(className, clickCount) {
		if (className === 'StoreInput') setStoreClickCount(clickCount);
		else if(className === 'TelInput') setTelClickCount(clickCount);
	}

	return (
		<TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
			<InputInfo isOver={isOver}>상호명</InputInfo>
			<AccountInput ref={StoreRef} className='StoreInput' placeholder='센터의 공식명칭을 입력해주세요' type='text' clickCountFn={clickCountFn}/>
			<AccountExplanation ref={StorePlanationRef}>필수항목을 입력해주세요</AccountExplanation>
			<InputInfo isOver={isOver}>센터 타입</InputInfo>
			<SignUpSelectBox ref={TypeRef} className='TypeInput' isOver={isOver}/>
			<InputInfo tel={true}>센터 전화 번호</InputInfo>
			<AccountInput ref={TelRef} className='TelInput' placeholder='센터 대표 전화 번호를 입력해주세요' type='text' clickCountFn={clickCountFn}/>
			<AccountExplanation ref={TelPlanationRef}>필수항목을 입력해주세요</AccountExplanation>
		</TopLevelWrapper>
	)
})

export default SignUpInfo3;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;

	flex-direction: column; 
	justify-content: flex-start;

	// width: 369px;
	width: ${props => props.isOver ? `max(369px, 48.046875vw)`: `max(250px, 78.125vw)`};

	// margin-top: calc(139.5px - 10.73px); 106
	margin-top: ${props => props.isOver ? `128.77px` : `99px`};

	background-color: rgba(255, 255, 255, 1);

	opacity: 0;
	z-index: 1;
	transform: translateX(150%);

  transition: all 0.3s ease-in-out;
`;

const InputInfo = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
 
	margin: ${props => props.isOver ? props.tel ? `19px 0 0 0` : `10.73px 0 0 0` : props.tel ? `24px 0 0 0` : `7px 0 0 0`};

	cursor: default;
`;

const AccountExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;

	color: #FF3131;

	margin: 4px 0 0 0;

	opacity: 0;
  
	transition: all 0.5s ease-in-out;
`;

