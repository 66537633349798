import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import CheckImagePath from '../../image/MiniCheck.png';

const MemberShipInfoCheckBox = (props) => {
  const { clickFn } = props;
  const [isClicked, setIsClicked] = useState(false);

  const CheckRef = useRef();
  const PlanationRef = useRef();

  useEffect(() => {
    if (isClicked) {
      CheckRef.current.style.backgroundColor = `#FF8B48`;
      PlanationRef.current.style.color = `#FF8B48`;
    }
    else {
      CheckRef.current.style.backgroundColor = `#FFFFFF`;
      PlanationRef.current.style.color = `#777777`;
    }
  }, [isClicked])

  function onClick() {
    setIsClicked(!isClicked);
    clickFn();
  }

  return (
    <TopLevelWrapper onClick={onClick}>
      <CheckBox ref={CheckRef} img={CheckImagePath}/>
      <Explanation ref={PlanationRef}>회원권 정보 입력하기</Explanation>
    </TopLevelWrapper>
  )
}

export default MemberShipInfoCheckBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
  margin-left: 16px;

  :hover {
    cursor: pointer;
  }
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 14px;
  height: 14px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;

  background: url(${props => props.img}) no-repeat center center;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
`;