import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import TimeDivisionLine from '../../../Component/MainComponent/TimeDivisionLine.js';

//modal
// import ClassDetailModal from '../ClassModel/ClassDetailModalModel/ClassDetailModal';
// import ReserveModal from '../ClassModel/ReserveModalModel/ReserveModal';

import ClassInfoBox from './ClassInfoBox';
import ClassDetailModal from './ClassManageModal/ClassDetailModal.js';
import ReservationHistoryModal from './ClassManageModal/ReservationHistoryModal.js';
import MemberReservationModal from './ClassManageModal/MemberReservationModal.js';

const DailyClass = forwardRef((props, ref) => {
	const { 
    isOver, 
    today, 
    useScheduleData, 
    reserveData, 
    reserveDataReLoad, 
    onClickScheduleDetail,
    setReserveDataReLoad, 
    openReserveLockModal } = props;
	const [index, setIndex] = useState(-1);

	const [detailModal, setDetailModal] = useState(false);
  const [reservationHistoryModal, setReservationHistoryModal] = useState(false);
	const [memberReservationModal, setMemberReservationModal] = useState(false);
	// const [useScheduleData, setUseScheduleData] = useState([]);

	const TopLevelRef = useRef();

	useImperativeHandle(ref, () => ({
		visible: () => {
			TopLevelRef.current.style.opacity = 1;
			TopLevelRef.current.style.zIndex = 1;
		},
		unVisible: () => {
			TopLevelRef.current.style.opacity = 0;
			TopLevelRef.current.style.zIndex = -3;
		}
	}));

	return (
    <TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
      {/* { detailModal && <ClassDetailModal data={data[index]} cancel={() => setDetailModal(false)} classDate={today.clone().format('YYYY.MM.DD')} />} */}
      {/* {reserveModal && <ReserveModal isOver={isOver} data={data[index]} cancel={() => setReserveModal(false)} />} */}
      {/* {reserveModal && <ReservationHistoryModal isOver={isOver} data={data[index]} cancel={() => setReserveModal(false)}/>} */}
      { detailModal && <ClassDetailModal isOver={isOver} data={useScheduleData[index]} date={today} cancel={() => setDetailModal(false)}/> }
      { memberReservationModal && <MemberReservationModal isOver={isOver} data={reserveData[index]} scheduleData={useScheduleData[index]} reserveDataReLoad={reserveDataReLoad} setReserveDataReLoad={setReserveDataReLoad} cancel={() => setMemberReservationModal(false)} historyOpen={() => setReservationHistoryModal(true)}/>}
      { reservationHistoryModal && <ReservationHistoryModal isOver={isOver} data={reserveData[index]} scheduleData={useScheduleData[index]} cancel={() => setReservationHistoryModal(false)} startFn={() => setMemberReservationModal(false)} closeFn={() => setMemberReservationModal(true)}/> }
      <TotalWrapper isOver={isOver}>
        <TimeDivisionLineWrapper>
          {
            useScheduleData.map((datas, index) => (
              <TimeDivisionLine key={index} scheduleData={datas} isOver={isOver}/>
            ))
          }
        </TimeDivisionLineWrapper>
        <ClassInfoBoxWrapper isOver={isOver}>
          {
            useScheduleData.map((data, index) => (
              data.classDate === today.clone().format('YYYY.MM.DD') ?
                <ClassInfoBox 
                  isOver={isOver} 
                  data={data} 
                  reserveData={reserveData[index]}
                  clickFn={(() => setIndex(index))} 
                  onClickScheduleDetail={() => onClickScheduleDetail(data.scheduleId)}
                  openDetailModal={() => setDetailModal(true)} 
                  openReservationModal={() => setMemberReservationModal(true)}
                  openReserveLockModal={() => openReserveLockModal(index)} key={index}/>
                :
                null
            ))
          }
        </ClassInfoBoxWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
	)
})

export default DailyClass;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(298px, 93.125vw)`};
  height: 448px;

  padding-top: 28px;
  padding-bottom: 28px;

  margin-top: 16px;
  margin-bottom: 96px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const TotalWrapper = styled.div`
	display: flex;
	flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isOver ? `max(654px, 85.15625vw)` : `max(282px, 88.125vw)`};
  height: 448px;

	overflow-y: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
    width: 2px;
	}

	&::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const TimeDivisionLineWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;
`;

const ClassInfoBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;

  width: ${props => props.isOver ? `max(593px, 77.21354166666667vw)` : `max(249px, 77.8125vw)`};
	heigth: 448px;

	gap: 8px;
`;