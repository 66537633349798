import { React } from 'react';
import styled from 'styled-components';

const ExerciseNoticeView = (props) => {
  const { isOver, notepad } = props;


  return (
    <TopLevelWrapper>
      <ViewWrapper>
        <TopLineWrapper>
          <Title isOver={isOver}>메모</Title>
        </TopLineWrapper>
        <TextSection isOver={isOver}>
          <Text isOver={isOver}>{notepad.contents}</Text>
        </TextSection>
      </ViewWrapper>
    </TopLevelWrapper>
  )
}

export default ExerciseNoticeView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  // width: 324px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  margin: 0 0 0 0;

  color: #232323;
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;

  gap: 5px;
  margin-top: ${props => props.isOver ? `8px` : `7px`};
  margin-bottom: ${props => props.isOver ? `0px` : `28px`};
  
  padding: ${props => props.isOver ? `8px 16px 0 16px` : `15px 15px 0 15px`};

  // width: 210px;
  // height: 466px;
  width: ${props => props.isOver ? `max(184px, 23.95833333333333vw)` : `max(225px, 70.3125vw)`};
  height: ${props => props.isOver ? `476px` : `45px`};

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  overflow-y: scroll;

  &::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const Text = styled.pre`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;
