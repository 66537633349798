import { React, forwardRef, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';


const LoginBottomComponent = forwardRef((props, ref) => {
	const { onClickLoginButton, isOver } = props;

	const TopLevelRef = useRef()

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
			if (val === 0) TopLevelRef.current.style.zIndex = -1;
			else TopLevelRef.current.style.zIndex = 1;
		},
	}));

	return (
		<TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
			<MoreDetailWrapper isOver={isOver}>
				<MoreDetails onClick={() => window.open("https://m.link-zone.io/rule/service")}>이용약관</MoreDetails>
				<MoreDetails onClick={() => window.open("https://m.link-zone.io/rule/privacy")}>개인정보 처리방침</MoreDetails>
			</MoreDetailWrapper>
			<LoginButton onClick={onClickLoginButton} isOver={isOver}>로그인</LoginButton>
		</TopLevelWrapper>
	)
})

export default LoginBottomComponent;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	align-items: center;

	// margin-top: max(397px, 26.77083333333333vw);
	// margin-top: 406.96px;
	margin-top: ${props => props.isOver ? `397px` : `367px`};

	width: ${props => props.isOver ? `max(369px, 48.046875vw)` : `max(250px, 78.125vw)`};

	opacity: 1;
	z-index: 1;
  transition: all 0.5s ease-in-out;
`;

const MoreDetailWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: center;
	align-items: center;

	gap: ${props => props.isOver ? `24px` : `10px`};
`;

const MoreDetails = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;

	margin: 0 0 0 0;

	color: rgba(119, 119, 119, 1);

	:hover {
		cursor: pointer;
	}
`;

const LoginButton = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	font-family: NotoSansKR-Bold;
	font-size: 12px;

	width: 92px;
	height: ${props => props.isOver ? `36px` : `34px`};
	
	border-radius: 18px;
	background-color: #FF8B48;
	color: #FFFFFF;

	cursor: default;
  transition: all 0.5s ease-in-out;

	:hover {
		cursor: pointer;
		background-color: #FF7322;
	}
`;
