import { React, forwardRef, useImperativeHandle, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AuthenticationInput from '../../../Component/StatisticsComponent/AuthenticationInput';

import FetchModule from '../../Share/Network/FetchModule';

const AuthenticationBox = forwardRef((props, ref) => {
  const { clickFn, isOver } = props;

  const history = useHistory();

  const InputRef = useRef ();

  function clickCount(className, clickCount) {

  }

  function onClickAccept() {
    var password = InputRef.current.getValue();

    if (password === '') {
      alert('비밀번호를 입력해주세요');
      return;
    }
    
    var data = {
      account: window.localStorage.getItem('AccountId'),
      password: password
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('account/check/authentication', 'POST', data);
        
        if (responseData.status === 200) {
          clickFn();
        }
        else if (responseData.status === 401) alert('올바르지 않은 비밀번호 입니다.');
        else if (responseData.status !== 204) alert("서버와 연결이 불안정합니다.\n잠시후 다시시도 해주세요");
      }
    )();
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      <InfoWrapper>
        <Title isOver={isOver}>비밀번호</Title>
        <AuthenticationInput ref={InputRef} isOver={isOver} className='Password' placeholder='현재 사용중인 비밀번호를 입력해주세요' type='password' clickCountFn={clickCount}/>
        <AcceptButton onClick={onClickAccept} isOver={isOver}>확인</AcceptButton>
      </InfoWrapper>
    </TopLevelWrapper>
  )
})

export default AuthenticationBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `510px` : `300px`};
  height: ${props => props.isOver ? `192px` : `140px`};

  border-radius: ${props => props.isOver ? `30px` : `15px`};
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;

  margin-top: ${props => props.isOver ? `38px` : `19px`};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #000000;

  margin: 0 0 0 0;
`;

const AcceptButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `60px`};
  height: ${props => props.isOver ? `40px` : `24px`};

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FFFFFF;

  margin: ${props => props.isOver ? `36px 0 0 354px` : `18px 0 0 216px`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;