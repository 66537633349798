import { forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';
// import logoImage from '../../../image/LinkLogo.png';
import styled, { keyframes } from 'styled-components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import NavLogoImage from '../../../image/NavigationBarLogo.png';
import NavExitImage from '../../../image/NavigationExitButton.png';

import HomeImagePath from '../../../image/Home.png';
import MemberImagePath from '../../../image/Member.png';
import ClassImagePath from '../../../image/Class.png';
import ChartImagePath from '../../../image/Chart.png';
import LockerImagePath from '../../../image/Locker.png';
import BoardImagePath from '../../../image/Board.png';
import NavNoticeImagePath from '../../../image/NavNotice.png';
import NoteImagePath from '../../../image/Note.png';
import NavSettingImagePath from '../../../image/NavSetting.png';

import HoverHomeImagePath from '../../../image/HoverHome.png';
import HoverMemberImagePath from '../../../image/HoverMember.png';
import HoverClassImagePath from '../../../image/HoverClass.png';
import HoverChartImagePath from '../../../image/HoverChart.png';
import HoverLockerImagePath from '../../../image/HoverLocker.png';
import HoverBoardImagePath from '../../../image/HoverBoard.png';
import HoverNavNoticeImagePath from '../../../image/HoverNavNotice.png';
import HoverNoteImagePath from '../../../image/HoverNote.png';
import HoverNavSettingImagePath from '../../../image/HoverNavSetting.png';

import ClickHomeImagePath from '../../../image/ClickHome.png';
import ClickMemberImagePath from '../../../image/ClickMember.png';
import ClickClassImagePath from '../../../image/ClickClass.png';
import ClickChartImagePath from '../../../image/ClickChart.png';
import ClickLockerImagePath from '../../../image/ClickLocker.png';
import ClickBoardImagePath from '../../../image/ClickBoard.png';
import ClickNavNoticeImagePath from '../../../image/ClickNavNotice.png';
import ClickNoteImagePath from '../../../image/ClickNote.png';
import ClickNavSettingImagePath from '../../../image/ClickNavSetting.png';

const NavigationBar = forwardRef((props, ref) => {
  const { cancelFn } = props;

  const nowPageURL = window.location.href;
  // const defaultURL = 'http://localhost:3000';
  // const defaultURL = 'http://172.30.1.6:3000';
  const defaultURL = 'https://m.link-zone.io';

  const history = useHistory();

  const [index, setIndex] = useState(0);
  const [nowPage, setNowPage] = useState(nowPageURL);
  const [nowPageKey, setNowPageKey] = useState('홈');
  const [unmount, setUnmount] = useState(false);

  const TopLevelRef = useRef();
  const NavRef = useRef();

  let location = useLocation(); // URL 감지용

  useImperativeHandle(ref, () => ({
    onView: () => {
      // TopLevelRef.current.style.display = `flex`;
      // TopLevelRef.current.style.zIndex = `1000`;
      // NavRef.current.style.width = `283px`;
      // TopLevelRef.current.style.width = `100vw`;
      TopLevelRef.current.style.translateX = `150%`;
    }
  }));

  var NavInfo = [{
    buttonRef: useRef(),
    img: HomeImagePath,
    hoverImg: HoverHomeImagePath,
    text: "홈",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/main'],
  },
  {
    buttonRef: useRef(),
    img: MemberImagePath,
    hoverImg: HoverMemberImagePath,
    text: "회원",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [{
      btnRef: useRef(),
      subText: "유효 회원"
    }, 
    {
      btnRef: useRef(),
      subText: "만료 회원"
    }, 
    {
      btnRef: useRef(),
      subText: "회원 등록"
    },
    { 
      btnRef: useRef(),
      subText: "등록 대기"
    }],
    destination: ['/member/valid', '/member/expired', '/member/registration', '/member/waitting'],
  },
  // {
  //   buttonRef: useRef(),
  //   img: ClassImagePath,
  //   hoverImg: HoverClassImagePath,
  //   text: "수업",
  //   imgRef: useRef(),
  //   textRef: useRef(),
  //   subRef: useRef(),
  //   children: [{
  //     btnRef: useRef(),
  //     subText: "수업 관리", 
  //   },
  //   {
  //     btnRef: useRef(),
  //     subText: "시간표 제작",
  //   },
  // ],
  //   destination: ['/class', '/schedule'],
  // },
  {
    buttonRef: useRef(),
    img: ClassImagePath,
    hoverImg: HoverClassImagePath,
    text: "수업",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/class'],
  },
  {
    buttonRef: useRef(),
    img: ChartImagePath,
    hoverImg: HoverChartImagePath,
    text: "통계",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/statistics'],
  },
  {
    buttonRef: useRef(),
    img: LockerImagePath,
    hoverImg: HoverLockerImagePath,
    text: "락커",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/locker'],
  },
  {
    buttonRef: useRef(),
    img: BoardImagePath,
    hoverImg: HoverBoardImagePath,
    text: "보드",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/board'],
  },
  // {
  //   buttonRef: useRef(),
  //   img: NoteImagePath,
  //   hoverImg: HoverNoteImagePath,
  //   text: "노트",
  //   imgRef: useRef(),
  //   textRef: useRef(),
  //   subRef: useRef(),
  //   children: [{
  //     btnRef: useRef(),
  //     subText: "1:1", 
  //   },
  //   {
  //     btnRef: useRef(),
  //     subText: "프로그램", 
  //   },
  //   {
  //     btnRef: useRef(),
  //     subText: "공유", 
  //   },
  //   {
  //     btnRef: useRef(),
  //     subText: "타임라인", 
  //   },
  // ],
  //   destination: ['/note/one-on-one', '/note/program', '/note/share', '/note/timeline'],
  // },
  {
    buttonRef: useRef(),
    img: NoteImagePath,
    hoverImg: HoverNoteImagePath,
    text: "노트",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [{
      btnRef: useRef(),
      subText: "1:1", 
    },
    {
      btnRef: useRef(),
      subText: "프로그램", 
    },
    {
      btnRef: useRef(),
      subText: "순위표"
    }
  ],
    destination: ['/note/one-on-one', '/note/program', '/note/ranking'],
  },
  {
    buttonRef: useRef(),
    img: NavNoticeImagePath,
    hoverImg: HoverNavNoticeImagePath,
    text: "공지",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/notice'],
  },
  {
    buttonRef: useRef(),
    img: NavSettingImagePath,
    hoverImg: HoverNavSettingImagePath,
    text: "설정",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/setting'],
  }] // 공지를 맨 아래로 내리고 그 밑에 설정 추가...

  
  useEffect(() => {
    var path = location.pathname;
    
    if (path === '/main') {
      setNowPageKey('홈');
      setIndex(0);
    }
    else if (path.includes('/member/valid') 
    || path.includes('/member/expired') 
    || path.includes('/member/registration')
    || path.includes('/member/waitting')) {
      setNowPageKey('회원');
      setIndex(1)
    }
    // else if (path.includes('/class') || path.includes('/schedule')) {
    //   setNowPageKey('수업');
    //   setIndex(2);
    // }
    else if (path.includes('/class')) {
      setNowPageKey('수업');
      setIndex(2);
    }
    else if (path.includes('/statistics')) {
      setNowPageKey('통계');
      setIndex(3);
    }
    else if (path.includes('/locker')) {
      setNowPageKey('락커');
      setIndex(4);
    }
    else if (path.includes('/board')) {
      setNowPageKey('보드');
      setIndex(5);
    }
    // else if (path.includes('/note/one-on-one') 
    // || path.includes('/note/program') 
    // || path.includes('/note/share')
    // || path.includes('/note/timeline')) {
    //   setNowPageKey('노트');
    //   setIndex(6)
    // }
    else if (path.includes('/note/one-on-one') 
    || path.includes('/note/program')
    || path.includes('/note/ranking') ) {
      setNowPageKey('노트');
      setIndex(6)
    }
    else if (path.includes('/notice')) {
      setNowPageKey('공지');
      setIndex(7);
    }
    else if (path.includes('/setting')) {
      setNowPageKey('설정');
      setIndex(8);
    }

    setNowPage(defaultURL + location.pathname);
  }, [location])

  useEffect(() => {

    if (nowPage === defaultURL + '/'
    || nowPage.includes(defaultURL + '/certification')
    || nowPage.includes(defaultURL + '/identifier')
    || nowPage.includes(defaultURL + '/permute')
    || nowPage.includes(defaultURL + '/rule/')
    || nowPage.includes(defaultURL + '/payment')
    || nowPage === defaultURL + '/full/board/'
    || nowPage.includes(defaultURL + '/ruler')) return null;

    for (var i = 0; i < NavInfo.length; i++) {
      if (index !== i) {
        NavInfo[i].buttonRef.current.style.backgroundColor = `#FDFCFB`;
        NavInfo[i].textRef.current.style.color = `#DCD4CF`;

        if (NavInfo[i].text === '홈') NavInfo[i].imgRef.current.style.background = `url(${HomeImagePath}) no-repeat center center`;
        if (NavInfo[i].text === '회원') {
          NavInfo[i].imgRef.current.style.background = `url(${MemberImagePath}) no-repeat center center`;
        }
        if (NavInfo[i].text === '수업') {
          NavInfo[i].imgRef.current.style.background = `url(${ClassImagePath}) no-repeat center center`;
        }  
        if (NavInfo[i].text === '통계') NavInfo[i].imgRef.current.style.background = `url(${ChartImagePath}) no-repeat center center`;
        if (NavInfo[i].text === '락커') NavInfo[i].imgRef.current.style.background = `url(${LockerImagePath}) no-repeat center center`;
        if (NavInfo[i].text === '보드') NavInfo[i].imgRef.current.style.background = `url(${BoardImagePath}) no-repeat center center`;
        if (NavInfo[i].text === '공지') NavInfo[i].imgRef.current.style.background = `url(${NavNoticeImagePath}) no-repeat center center`;
        if (NavInfo[i].text === '노트') NavInfo[i].imgRef.current.style.background = `url(${NoteImagePath}) no-repeat center center`;
        if (NavInfo[i].text === '설정') NavInfo[i].imgRef.current.style.background = `url(${NavSettingImagePath}) no-repeat center center`;
      }
    }

    NavInfo[index].textRef.current.style.display = `inline-block`;
    NavInfo[index].clicked = true;
    NavInfo[index].buttonRef.current.style.backgroundColor = `#FF8B48`;
    
    if (NavInfo[index].text === '홈') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickHomeImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '회원') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickMemberImagePath}) no-repeat center center`;
      NavInfo[index].subRef.current.style.height = `208px`;
    }
    else if (NavInfo[index].text === '수업') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickClassImagePath}) no-repeat center center`;
      // NavInfo[index].subRef.current.style.height = `108px`;
    }
    else if (NavInfo[index].text === '통계') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickChartImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '락커') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickLockerImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '보드') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickBoardImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '노트') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickNoteImagePath}) no-repeat center center`;
      NavInfo[index].subRef.current.style.height = `160px`;
      // NavInfo[index].subRef.current.style.height = `108px`;
    }
    else if (NavInfo[index].text === '공지') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickNavNoticeImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '설정') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickNavSettingImagePath}) no-repeat center center`;
    }
  }, [index, nowPage])

  if (nowPage === defaultURL + '/'
    || nowPage.includes(defaultURL + '/certification')
    || nowPage.includes(defaultURL + '/identifier')
    || nowPage.includes(defaultURL + '/permute')
    || nowPage.includes(defaultURL + '/rule/')
    || nowPage.includes(defaultURL + '/payment')
    || nowPage === defaultURL + '/full/board/'
    || nowPage === defaultURL + '/ruler') return null;


  function clickButton(index) {
    setNowPageKey(NavInfo[index].text);
    setIndex(index);
    if (NavInfo[index].children.length === 0) {
      history.push(NavInfo[index].destination[0]);
      cancelButtonClick();
    }
    // if (nowPageKey === NavInfo[index].text && nowPageKey !== '홈') {
    //   setNowPageKey('홈');
    //   setIndex(0);
    // }
    // else {
    //   setNowPageKey(NavInfo[index].text);
    //   setIndex(index);
    //   if (NavInfo[index].children.length === 0) history.push(NavInfo[index].destination[0]);
    // }
  }

  function focusButton(index) {

    if (NavInfo[index].text !== nowPageKey) {
      NavInfo[index].buttonRef.current.style.backgroundColor = `#FF8B48`;
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
    }

    if (NavInfo[index].text === '홈' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${ClickHomeImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '회원' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${ClickMemberImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '수업' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${ClickClassImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '통계' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${ClickChartImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '락커' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${ClickLockerImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '보드' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${ClickBoardImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '공지' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${ClickNavNoticeImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '노트' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${ClickNoteImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '설정' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${ClickNavSettingImagePath}) no-repeat center center`;
    }
  }

  function blurButton(index) {

    if (NavInfo[index].text !== nowPageKey) {
      NavInfo[index].buttonRef.current.style.backgroundColor = `#FFFFFF`;
      NavInfo[index].textRef.current.style.color = `#DCD4CF`;
    }

    if (NavInfo[index].text === '홈' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${HomeImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '회원' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${MemberImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '수업' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${ClassImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '통계' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${ChartImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '락커' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${LockerImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '보드' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${BoardImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '공지' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${NavNoticeImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '노트' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${NoteImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '설정' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].imgRef.current.style.background = `url(${NavSettingImagePath}) no-repeat center center`;
    }
  }

  function clickSubButton(index, idx) {
    history.push(NavInfo[index].destination[idx]);
    cancelButtonClick();
  }

  function cancelButtonClick() {
    setUnmount(true);

    setTimeout(function() {
      cancelFn();
    }, 300)
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} unmount={unmount}>
      <BoxWrapper ref={NavRef} unmount={unmount}>
        <LogoBox unmount={unmount}>
          <LogoWrapper>
            <NavLogo src={NavLogoImage} />
            <NavTitle>Link Zone</NavTitle>
          </LogoWrapper>
          <NavExitButton src={NavExitImage} onClick={() => cancelButtonClick()}/>
        </LogoBox>
        {
          NavInfo.map((data, index) => (
            <TotalWrapper key={index}>
              <Button ref={data.buttonRef} unmount={unmount} onClick={() => clickButton(index)} onMouseOver={() => focusButton(index)} onMouseLeave={() => blurButton(index)}>
                <Img ref={data.imgRef} img={data.img} hoverImg={data.hoverImg} index={index} /*onClick={() => clickButton(index)} onMouseOver={() => focusButton(index)} onMouseLeave={() => blurButton(index)}*/ />
                <ButtonText ref={data.textRef}>{data.text}</ButtonText>
              </Button>
              {data.children.length !== 0 && data.text === nowPageKey ?
                <SubButtonWrapper ref={data.subRef} unmount={unmount}>
                  {
                    data.children.map((childData, idx) => (
                      <SubButton key={idx} ref={childData.btnRef} isClicked={nowPage === (defaultURL + data.destination[idx])} onClick={() => clickSubButton(index, idx)}>
                        {/* <SubText onClick={() => clickSubButton(index, idx)}>{data.subText}</SubText> */}
                        {childData.subText}
                      </SubButton>
                    ))
                  }
                </SubButtonWrapper>
                :
                null
              }
            </TotalWrapper>
          ))
        }
      </BoxWrapper>
    </TopLevelWrapper>
  )
})

export default NavigationBar;

const TotalStartAnimation = keyframes`
	0% { opacity: 0; }
  10% { opacity: 0.1; }
  20% { opacity: 0.2; }
  30% { opacity: 0.3; }
  40% { opacity: 0.4; }
	50% { opacity: 0.5; }
	60% { opacity: 0.6; }
	70% { opacity: 0.7; }
	80% { opacity: 0.8; }
	90% { opacity: 0.9; }
	100% { opacity: 1; }
`;

const NavStartAnimation = keyframes`
	0% { width: 0px; }
  10% { width: 28.3px; }
  20% { width: 56.6px; }
  30% { width: 84.9px; }
  40% { width: 113.2px; }
	50% { width: 141.5px; }
	60% { width: 169.8px; }
	70% { width: 198.1px; }
	80% { width: 226.4px; }
	90% { width: 254.7px; }
	100% { width: 283px; }
`;

const TotalEndAnimation = keyframes`
	0% { opacity: 1; }
  10% { opacity: 0.9; }
  20% { opacity: 0.8; }
  30% { opacity: 0.7; }
  40% { opacity: 0.6; }
	50% { opacity: 0.5; }
	60% { opacity: 0.4; }
	70% { opacity: 0.3; }
	80% { opacity: 0.2; }
	90% { opacity: 0.1; }
	100% { opacity: 0; }
`;

const NavEndAnimation = keyframes`
  100% { width: 0px; }
  90% { width: 28.3px; }
  80% { width: 56.6px; }
  70% { width: 84.9px; }
  60% { width: 113.2px; }
  50% { width: 141.5px; }
  40% { width: 169.8px; }
  30% { width: 198.1px; }
  20% { width: 226.4px; }
  10% { width: 254.7px; }
	0% { width: 283px; }
`;

const TopLevelWrapper = styled.div`
  // position: fixed;
  position: absolute;
  display: flex;
  // display: none;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: flex-start;
  align-items: flex-start;

  z-index: 1000;

  width: 100vw;
  // height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);

	animation-name: ${props => props.unmount ? TotalEndAnimation : TotalStartAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;

  // display: flex;
  // flex-direction: column;

  // width: 120px;

  // margin-right: 20px;
  // background-color: #FDFCFB;
  // background-color: red;
`;

const LogoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 283px;
  height: 81px;

  // gap: 8px;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
  
	animation-name: ${props => props.unmount ? NavEndAnimation : NavStartAnimation};
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;

  margin-left: 20px;
`;

const NavLogo = styled.img`
  width: 36px;
  height: 36px;
`;

const NavTitle = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 24px;

  margin: 0 0 0 0;

  color: #FF8B48;
`;

const NavExitButton = styled.img`
  width: 24px;
  height: 24px;

  margin-right: 20px;

  :hover {
    cursor: pointer;
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 283px;
  // width: 0px;
  // min-height: 100vh;
  height: 100%;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
  
	animation-name: ${props => props.unmount ? NavEndAnimation : NavStartAnimation};
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: 120px;
  width: 283px;
  height: 64px;

  // margin-bottom: 8px;

  // border-radius: 30px;
  // background-color: #FDFCFB;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
  
	animation-name: ${props => props.unmount ? NavEndAnimation : NavStartAnimation};
	animation-duration: 0.3s;
	animation-timing-function: ease-out;

  :hover {
    cursor: pointer;
  }
`;

const Img = styled.div`
  display: flex;
  flex-direction: row;

  width: 24px;
  height: 24px;

  justify-content: center;
  align-items: center;

  margin-left: 24px;
  margin-right: ${props => props.index === 0 ? `25px` : `16px`};

  background: url(${props => props.img}) no-repeat center center;
  transition: all 0.3s ease-in-out;
            
  :hover {
    cursor: pointer;
    // background: url(${props => props.hoverImg}) no-repeat center center;
    // background-color: #FF8B48;
  }
`;

const ButtonText = styled.p`
  // display: none;
  font-family: NotoSansKR-Medium;
  font-size: 18px;

  // color: #FDFCFB;
  color: #DCD4CF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
`;

const SubButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 120px;
  width: 283px;
  height: 0px;

  gap: 8px;
  // background-color: #F6F4F2;
  background-color: #FFFFFF;
  overflow: hidden;

  transition: all 0.3s ease-in-out;
  
	animation-name: ${props => props.unmount ? NavEndAnimation : NavStartAnimation};
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
`;

const SubButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 120px;
  width: 261px;
  // height: 48px;
  height: 42px;

  border-radius: 8px;
  background-color: #F8F8F8;
  // background-color: ${props => props.isClicked ? `#FF8B48` : `#F6F4F2`};
  
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  // color: ${props => props.isClicked ? `#FFFFFF` : `#BEBEBE`};
  color: ${props => props.isClicked ? `#FF8B48` : `#BEBEBE`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    // color: ${props => props.isClicked ? `#FFFFFF` : `#FF8B48`};
    color: ${props => props.isClicked ? `#FF8B48` : `#FF8B48`};
  }
`;

// const SubText = styled.p`
//   font-family: NotoSansKR-Medium;
//   font-size: 14px;

//   color: #BEBEBE;

//   :hover {
//     cursor: pointer;
//     color: #FF8B48;
//   }
// `;