import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import moment from 'moment';

import PrintIcon from '../../../../image/PrintIcon.png';

import PhysicalGraphView from './PhysicalGraphView';

const BodyInfo = (props) => {
  const { physicalData, isOver } = props;

  const [data, setData] = useState([]);
  const [xColumn, setXColumn] = useState([]);
  const [yColumn, setYColumn] = useState([100, 80, 60, 40, 20, 0]);
  const [nowColumn, setNowColumn] = useState('Weight');
  const [nowDate, setNowDate] = useState('Day');

  useEffect(() => {
    
    if (physicalData.length === 0) return;

    var newXColumn = [];
    var newYColumn = [];
    var dataList = [];
    var totalData = 0;
    var dataCount = 0;

    if (nowColumn === 'Weight') {
      if (nowDate === 'Day') {
        for (var i = 0; i < physicalData.length; i++) {
          newXColumn.push(moment(physicalData[i].recordDate).format('MM.DD'));
          dataList.push((physicalData[i].weight).toFixed(1));
          newYColumn.push((physicalData[i].weight).toFixed(1));
        }
      }
      else if (nowDate === 'Week') {
        for (var i = 0 ; i < physicalData.length; i++) {
          var val = getWeekNumber(new Date(physicalData[0].recordDate), new Date(physicalData[i].recordDate));
         
          if (newXColumn.includes(val + "주차") || i === 0) {
            totalData += physicalData[i].weight;
            dataCount += 1;
            if (i === 0) newXColumn.push(val + '주차');
            else if (i === physicalData.length - 1) {
              newYColumn.push((totalData / dataCount).toFixed(1));
              dataList.push((totalData / dataCount).toFixed(1));
            }
          }
          else {
            newYColumn.push((totalData / dataCount).toFixed(1));
            dataList.push((totalData / dataCount).toFixed(1));
            newXColumn.push(val + '주차');

            totalData = 0;
            dataCount = 0;
          }
        }
      }
      else if (nowDate === 'Month') {
        newXColumn = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
        var totalDataList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        var dataCountList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (i = 0; i < physicalData.length; i++) {
          // 이번 년도만 계산
          if (moment(physicalData[i].recordDate).format('YYYY') === moment().format('YYYY')){
              totalDataList[parseInt(moment(physicalData[i].recordDate).format('MM')) - 1] += physicalData[i].weight;
              dataCountList[parseInt(moment(physicalData[i].recordDate).format('MM')) - 1] += 1;
          }
        }

        for (i = 0; i < 12; i++) {
          if (totalDataList[i] === 0 || dataCountList[i] === 0) dataList.push(0);
          else dataList.push((totalDataList[i] / dataCountList[i]).toFixed(1))
        }

        newYColumn = dataList;
      }
      else if (nowDate === 'Year') {
        for (var i = 0 ; i < physicalData.length; i++) {
          var val = getYearNumber(new Date(physicalData[0].recordDate), new Date(physicalData[i].recordDate));
         
          if (newXColumn.includes(val + "년차") || i === 0) {
            totalData += physicalData[i].weight;
            dataCount += 1;
            if (i === 0) newXColumn.push(val + '년차');
            else if (i === physicalData.length - 1) {
              newYColumn.push((totalData / dataCount).toFixed(1));
              dataList.push((totalData / dataCount).toFixed(1));
            }
          }
          else {
            newYColumn.push((totalData / dataCount).toFixed(1));
            dataList.push((totalData / dataCount).toFixed(1));
            newXColumn.push(val + '년차');

            totalData = 0;
            dataCount = 0;
          }
        }
      }
    }
    else if (nowColumn === 'BodyFat') {
      if (nowDate === 'Day') {
        for (var i = 0; i < physicalData.length; i++) {
          newXColumn.push(moment(physicalData[i].recordDate).format('MM.DD'));
          dataList.push((physicalData[i].bodyFat).toFixed(1));
          newYColumn.push((physicalData[i].bodyFat).toFixed(1));
        }
      }
      else if (nowDate === 'Week') {
        for (i = 0 ; i < physicalData.length; i++) {
          val = getWeekNumber(new Date(physicalData[0].recordDate), new Date(physicalData[i].recordDate));
         
          if (newXColumn.includes(val + "주차") || i === 0) {
            totalData += physicalData[i].bodyFat;
            dataCount += 1;
            if (i === 0) newXColumn.push(val + '주차');
            else if (i === physicalData.length - 1) {
              newYColumn.push((totalData / dataCount).toFixed(1));
              dataList.push((totalData / dataCount).toFixed(1));
            }
          }
          else {
            newYColumn.push((totalData / dataCount).toFixed(1));
            dataList.push((totalData / dataCount).toFixed(1));
            newXColumn.push(val + '주차');

            totalData = 0;
            dataCount = 0;
          }
        }
      }
      else if (nowDate === 'Month') {
        newXColumn = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
        var totalDataList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        var dataCountList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (i = 0; i < physicalData.length; i++) {
          // 이번 년도만 계산
          if (moment(physicalData[i].recordDate).format('YYYY') === moment().format('YYYY')){
              totalDataList[parseInt(moment(physicalData[i].recordDate).format('MM')) - 1] += physicalData[i].bodyFat;
              dataCountList[parseInt(moment(physicalData[i].recordDate).format('MM')) - 1] += 1;
          }
        }

        for (i = 0; i < 12; i++) {
          if (totalDataList[i] === 0 || dataCountList[i] === 0) dataList.push(0);
          else dataList.push((totalDataList[i] / dataCountList[i]).toFixed(1))
        }

        newYColumn = dataList;
      }
      else if (nowDate === 'Year') {
        for (var i = 0 ; i < physicalData.length; i++) {
          var val = getYearNumber(new Date(physicalData[0].recordDate), new Date(physicalData[i].recordDate));
         
          if (newXColumn.includes(val + "년차") || i === 0) {
            totalData += physicalData[i].bodyFat;
            dataCount += 1;
            if (i === 0) newXColumn.push(val + '년차');
            else if (i === physicalData.length - 1) {
              newYColumn.push((totalData / dataCount).toFixed(1));
              dataList.push((totalData / dataCount).toFixed(1));
            }
          }
          else {
            newYColumn.push((totalData / dataCount).toFixed(1));
            dataList.push((totalData / dataCount).toFixed(1));
            newXColumn.push(val + '년차');

            totalData = 0;
            dataCount = 0;
          }
        }
      }
    }
    else if (nowColumn === 'BodyFatPercent') {
      if (nowDate === 'Day') {
        for (var i = 0; i < physicalData.length; i++) {
          newXColumn.push(moment(physicalData[i].recordDate).format('MM.DD'));
          dataList.push((physicalData[i].bodyFatPercent).toFixed(1));
          newYColumn.push((physicalData[i].bodyFatPercent).toFixed(1));
        }
      }
      else if (nowDate === 'Week') {
        for (i = 0 ; i < physicalData.length; i++) {
          val = getWeekNumber(new Date(physicalData[0].recordDate), new Date(physicalData[i].recordDate));
         
          if (newXColumn.includes(val + "주차") || i === 0) {
            totalData += physicalData[i].bodyFatPercent;
            dataCount += 1;
            if (i === 0) newXColumn.push(val + '주차');
            else if (i === physicalData.length - 1) {
              newYColumn.push((totalData / dataCount).toFixed(1));
              dataList.push((totalData / dataCount).toFixed(1));
            }
          }
          else {
            newYColumn.push((totalData / dataCount).toFixed(1));
            dataList.push((totalData / dataCount).toFixed(1));
            newXColumn.push(val + '주차');

            totalData = 0;
            dataCount = 0;
          }
        }
      }
      else if (nowDate === 'Month') {
        newXColumn = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
        var totalDataList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        var dataCountList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (i = 0; i < physicalData.length; i++) {
          // 이번 년도만 계산
          if (moment(physicalData[i].recordDate).format('YYYY') === moment().format('YYYY')){
              totalDataList[parseInt(moment(physicalData[i].recordDate).format('MM')) - 1] += physicalData[i].bodyFatPercent;
              dataCountList[parseInt(moment(physicalData[i].recordDate).format('MM')) - 1] += 1;
          }
        }

        for (i = 0; i < 12; i++) {
          if (totalDataList[i] === 0 || dataCountList[i] === 0) dataList.push(0);
          else dataList.push((totalDataList[i] / dataCountList[i]).toFixed(1))
        }

        newYColumn = dataList;
      }
      else if (nowDate === 'Year') {
        for (var i = 0 ; i < physicalData.length; i++) {
          var val = getYearNumber(new Date(physicalData[0].recordDate), new Date(physicalData[i].recordDate));
         
          if (newXColumn.includes(val + "년차") || i === 0) {
            totalData += physicalData[i].bodyFatPercent;
            dataCount += 1;
            if (i === 0) newXColumn.push(val + '년차');
            else if (i === physicalData.length - 1) {
              newYColumn.push((totalData / dataCount).toFixed(1));
              dataList.push((totalData / dataCount).toFixed(1));
            }
          }
          else {
            newYColumn.push((totalData / dataCount).toFixed(1));
            dataList.push((totalData / dataCount).toFixed(1));
            newXColumn.push(val + '년차');

            totalData = 0;
            dataCount = 0;
          }
        }
      }
    }
    else if (nowColumn === 'Skeletal') {
      if (nowDate === 'Day') {
        for (var i = 0; i < physicalData.length; i++) {
          newXColumn.push(moment(physicalData[i].recordDate).format('MM.DD'));
          dataList.push((physicalData[i].skeletal).toFixed(1));
          newYColumn.push((physicalData[i].skeletal).toFixed(1));
        }
      }
      else if (nowDate === 'Week') {
        for (i = 0 ; i < physicalData.length; i++) {
          val = getWeekNumber(new Date(physicalData[0].recordDate), new Date(physicalData[i].recordDate));
         
          if (newXColumn.includes(val + "주차") || i === 0) {
            totalData += physicalData[i].skeletal;
            dataCount += 1;
            if (i === 0) newXColumn.push(val + '주차');
            else if (i === physicalData.length - 1) {
              newYColumn.push((totalData / dataCount).toFixed(1));
              dataList.push((totalData / dataCount).toFixed(1));
            }
          }
          else {
            newYColumn.push((totalData / dataCount).toFixed(1));
            dataList.push((totalData / dataCount).toFixed(1));
            newXColumn.push(val + '주차');

            totalData = 0;
            dataCount = 0;
          }
        }
      }
      else if (nowDate === 'Month') {
        newXColumn = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
        var totalDataList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        var dataCountList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (i = 0; i < physicalData.length; i++) {
          // 이번 년도만 계산
          if (moment(physicalData[i].recordDate).format('YYYY') === moment().format('YYYY')){
              totalDataList[parseInt(moment(physicalData[i].recordDate).format('MM')) - 1] += physicalData[i].skeletal;
              dataCountList[parseInt(moment(physicalData[i].recordDate).format('MM')) - 1] += 1;
          }
        }

        for (i = 0; i < 12; i++) {
          if (totalDataList[i] === 0 || dataCountList[i] === 0) dataList.push(0);
          else dataList.push((totalDataList[i] / dataCountList[i]).toFixed(1))
        }

        newYColumn = dataList;
      }
      else if (nowDate === 'Year') {
        for (var i = 0 ; i < physicalData.length; i++) {
          var val = getYearNumber(new Date(physicalData[0].recordDate), new Date(physicalData[i].recordDate));
         
          if (newXColumn.includes(val + "년차") || i === 0) {
            totalData += physicalData[i].skeletal;
            dataCount += 1;
            if (i === 0) newXColumn.push(val + '년차');
            else if (i === physicalData.length - 1) {
              newYColumn.push((totalData / dataCount).toFixed(1));
              dataList.push((totalData / dataCount).toFixed(1));
            }
          }
          else {
            newYColumn.push((totalData / dataCount).toFixed(1));
            dataList.push((totalData / dataCount).toFixed(1));
            newXColumn.push(val + '년차');

            totalData = 0;
            dataCount = 0;
          }
        }
      }
    }

    var yMax = Math.max(...newYColumn);
    var yMin = Math.min(...newYColumn);
    
    yMax = yMax + yMax * 0.1;
    yMin = yMin - yMin * 0.1;

    var gap = (yMax - yMin) / 5;

    var finalYColumn = [];

    for (i = 0; i < 5; i ++) {
      finalYColumn.push((yMax - gap * i).toFixed(1));
    }

    finalYColumn.push(yMin.toFixed(1));

    setData(dataList);
    setXColumn(newXColumn);
    setYColumn(finalYColumn); 

  }, [physicalData, nowColumn, nowDate])

  function getWeekNumber(standard, date) {
    const time = date.getTime() - standard.getTime();
    const day = time / 1000 / 60 / 60 / 24;
    const week = parseInt(day / 7) + 1;

    return week;
  }

  function getYearNumber(standard, date) {
    const time = date.getTime() - standard.getTime();
    const day = time / 1000 / 60 / 60 / 24;
    const year = parseInt(day / 365) + 1;

    return year;
  }
  return (
    <TopLevelWrapper isOver={isOver}>
      <TopColumnWrapper isOver={isOver}>
        <ColumnWrapper>
          <ContentWrapper onClick={() => setNowColumn('Weight')}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={nowColumn === 'Weight' ? '#232323' : '#777777'}>체중</Text>
            <UnderBar backgroundColor={nowColumn === 'Weight' ? '#FF8B48' : '#FFFFFF'} />
          </ContentWrapper>
          <ContentWrapper onClick={() => setNowColumn('BodyFat')}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={nowColumn === 'BodyFat' ? '#232323' : '#777777'}>체지방량</Text>
            <UnderBar backgroundColor={nowColumn === 'BodyFat' ? '#FF8B48' : '#FFFFFF'} />
          </ContentWrapper>
          <ContentWrapper onClick={() => setNowColumn('BodyFatPercent')}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={nowColumn === 'BodyFatPercent' ? '#232323' : '#777777'}>체지방률</Text>
            <UnderBar backgroundColor={nowColumn === 'BodyFatPercent' ? '#FF8B48' : '#FFFFFF'} />
          </ContentWrapper>
          <ContentWrapper onClick={() => setNowColumn('Skeletal')}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={nowColumn === 'Skeletal' ? '#232323' : '#777777'}>골격근</Text>
            <UnderBar backgroundColor={nowColumn === 'Skeletal' ? '#FF8B48' : '#FFFFFF'} />
          </ContentWrapper>
        </ColumnWrapper>
        {
          isOver &&
          <PrintWrapper>
            <PrintImg src={PrintIcon} />
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FF8B48'>인쇄하기</Text>
          </PrintWrapper>
        }
      </TopColumnWrapper>
      <DateTotalWrapper isOver={isOver}>
        <DateWrapper>
          <DateBox onClick={() => setNowDate('Day')} border={nowDate === 'Day' ? '1px solid #FF8B48' : '1px solid #DFDFDF'} backgroundColor={nowDate === 'Day' ? '#FF8B48' : '#FFFFFF'}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color={nowDate === 'Day' ? '#FFFFFF' : '#777777'}>일간</Text>
          </DateBox>
          <DateBox onClick={() => setNowDate('Week')} border={nowDate === 'Week' ? '1px solid #FF8B48' : '1px solid #DFDFDF'} backgroundColor={nowDate === 'Week' ? '#FF8B48' : '#FFFFFF'}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color={nowDate === 'Week' ? '#FFFFFF' : '#777777'}>주간</Text>
          </DateBox>
          <DateBox onClick={() => setNowDate('Month')} border={nowDate === 'Month' ? '1px solid #FF8B48' : '1px solid #DFDFDF'} backgroundColor={nowDate === 'Month' ? '#FF8B48' : '#FFFFFF'}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color={nowDate === 'Month' ? '#FFFFFF' : '#777777'}>월간</Text>
          </DateBox>
          <DateBox onClick={() => setNowDate('Year')} border={nowDate === 'Year' ? '1px solid #FF8B48' : '1px solid #DFDFDF'} backgroundColor={nowDate === 'Year' ? '#FF8B48' : '#FFFFFF'}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color={nowDate === 'Year' ? '#FFFFFF' : '#777777'}>연간</Text>
          </DateBox>
        </DateWrapper>
      </DateTotalWrapper> 
      <PhysicalGraphView isOver={isOver} data={data} xColumn={xColumn} yColumn={yColumn} unit={nowColumn === 'BodyFatPercent' ? '%' : 'kg'} nowDate={nowDate}/>
    </TopLevelWrapper>
  )
}

export default BodyInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(299px, 93.4375vw)`}
  // width: ${props => props.isOver ? `692px` : `299px`};
  // height: ${props => props.isOver ? `396px` : `273px`};
  height: 396px;

  border-radius: ${props => props.isOver ? `30px` : `17px`};
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const TopColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: ${props => props.isOver ? `max(624px, 81.25vw)` : `max(241px, 75.3125vw)`};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 241px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
`;

const UnderBar = styled.div`
  width: 100%;
  height: 2px;

  margin-top: 8px;
  border-radius: 1px;

  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;
`;

const PrintWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 81px;
  
  :hover {
    cursor: pointer;
  }
`;

const PrintImg = styled.img`
  width: 24px;
  height: 24px;
`;

const DateTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 624px;
  
  // width: ${props => props.isOver ? `624px` : `241px`};
  width: ${props => props.isOver ? `max(624px, 81.25vw)` : `max(241px, 75.3125vw)`};

  margin-top: 24px;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 149px;
  width: 204px;
`;

const DateBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 37px;
  height: 22px;

  border: ${props => props.border};
  border-radius: 5px;

  background-color: ${props => props.backgroundColor};
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;