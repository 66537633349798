import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

const SecondWaittingMemberInfo = (props) => {
  const { data, index, isIgnore, onClickedAgree, onClickedIgnore } = props;

  const [birth, setBirth] = useState('');
  const [memberShip, setMemberShip] = useState('-');

  const RegistrationRef = useRef();
  const ExitRef = useRef();

  useEffect(() => {
    var birth = '';

    if (data.birthDay[7] === '1') {
      birth = "19" + data.birthDay[0] + data.birthDay[1] + '.' + data.birthDay[2] + data.birthDay[3] + '.' + data.birthDay[4] + data.birthDay[5];
    }
    else if (data.birthDay[7] === '2') {
      birth = "19" + data.birthDay[0] + data.birthDay[1] + '.' + data.birthDay[2] + data.birthDay[3] + '.' + data.birthDay[4] + data.birthDay[5];
    }
    else if (data.birthDay[7] === '3') {
      birth = "20" + data.birthDay[0] + data.birthDay[1] + '.' + data.birthDay[2] + data.birthDay[3] + '.' + data.birthDay[4] + data.birthDay[5];
    }
    else if (data.birthDay[7] === '4') {
      birth = "20" + data.birthDay[0] + data.birthDay[1] + '.' + data.birthDay[2] + data.birthDay[3] + '.' + data.birthDay[4] + data.birthDay[5];
    }

    if (data.memberShip === undefined) setMemberShip('-');

    setBirth(birth);
  }, [data])

  function mouseOver() {
    RegistrationRef.current.style.color = `#FF8B48`;
    ExitRef.current.style.color = `#777777`;
  }

  function mouseLeave() {
    RegistrationRef.current.style.color = `#FFFFFF`;
    ExitRef.current.style.color = `#FFFFFF`;
  }
  
  return (
    <TopLevelWrapper onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      <Table>
        <Body>
          <Tr>
            <Td style={{ width: 'max(158px, 19vw)' }}>
              <TextWrapper style={{ maxWidth: 'max(150px, 18vw)' }}>
                <Text>{data.itemName}</Text>
              </TextWrapper>
            </Td>
            <Td style={{ width: '221px' }}>
              <TextWrapper style={{ maxWidth: '70px' }}>
                <Text>{data.createdDate.split('T')[0].replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr>
            <Td style={{ width: '46px' }}>
              <ExitButton ref={ExitRef} style={{ minWidth: '26px'}} onClick={() => onClickedIgnore(data.userId, isIgnore)}>{isIgnore ? `영구삭제` : `거절`}</ExitButton>
            </Td>
            <Td style={{ width: '46px' }}>
              <RegistrationButton ref={RegistrationRef} style={{ minWidth: '26px'}} onClick={() => onClickedAgree(data.userId, index)}>{isIgnore ? `재등록` : `등록`}</RegistrationButton>
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

export default SecondWaittingMemberInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: max(690px, 89.84375vw);
  height: 50px;

  background: #FFFFFF;
  
  margin-top: 8px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: center;

  margin-left: max(28px, 3.645833333333333vw);

  width: max(636px, 82.8125vw);
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const RegistrationButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Bold;
  }
`;

const ExitButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;