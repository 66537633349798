import { React, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';

import CalenderInput from '../../../../../Component/MemberComponent/MemberDetail/SmallHoldingCalenderInput';
import HoldingCalenderInput from '../../../../../Component/MemberComponent/MemberDetail/HoldingCalenderInput';
import ReasonInput from '../../../../../Component/MemberComponent/MemberDetail/ReasonInput';
import FetchModule from '../../../../Share/Network/FetchModule';

const MemberShipHoldingModal = (props) => {
  const { data, index, isOver, className, exitClickFn } = props;

  const history = useHistory();

  const [isViewAdminModal, setIsViewAdminModal] = useState(false);
  const [adminModalText, setAdminModalText] = useState('홀딩 가능 일수');
  const [compriseDate, setComprise] = useState([]);
  const [startValue, setStartValue] = useState(moment('2000-01-01'));
  const [endValue, setEndValue] = useState(moment('2000-01-01'));
  const [reasonValue, setReasonValue] = useState('');

  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();

  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  // 날짜 계산 //
  useEffect(() => {
    if (startValue.format('YYYY-MM-DD') === '2000-01-01' || isOver) return;

    var newDateList = [];
    var calcDate = startValue.clone();

    while (calcDate < endValue.clone().add(1, 'day')) {
      newDateList.push(calcDate.format('MM.DD'));
      calcDate = calcDate.add(1, 'day');
    }

    setComprise(newDateList);
  }, [startValue, endValue, isOver])

  function holdingDateSelect(compriseDateList) {
    setComprise(compriseDateList);
  }

  function exitClick() {
    exitClickFn(className);
  }

  function saveClick() {
    var startDate = startValue.format('YYYY-MM-DDTHH:mm:ss');
    var endDate = endValue.format('YYYY-MM-DDTHH:mm:ss');

    if (endDate < startDate) {
      alert('홀딩 종료날짜의 설정이 올바르지 않습니다.');
      return;
    }
    else if (data[index].availableHold < (moment(endDate).diff(moment(startDate), 'days'))) {
      setAdminModalText('홀딩 가능 일수');
      setIsViewAdminModal(true);
      return;
    }
    else if (data[index].holdCount === 0) {
      setAdminModalText('홀딩 신청 가능 일수');
      setIsViewAdminModal(true);
      return;
    }

    var requestData = {
      storeId: window.localStorage.getItem('StoreId'),
      usageId: data[0].usageId,
      startDate: startDate,
      endDate: endDate,
      reason: reasonValue
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('membership/create/holding', 'POST', requestData);

        if (responseData.status === 200) {
          alert("회원권 홀딩 내역을 저장하였습니다.");
          exitClickFn(className);
        }
        else if (responseData.status === 201) {
          alert('동일한 신청내역이 존재합니다!\n정보를 수정 후 다시시도 하세요!');
        }
        else if (responseData.status === 202) {
          alert('홀딩 종료날짜의 설정이 올바르지 않습니다.');
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
        else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();
  }

  // 홀딩 강제진행 클릭시 //
  function onClickAdminSave() {
    var startDate = startValue.format('YYYY-MM-DDTHH:mm:ss');
    var endDate = endValue.format('YYYY-MM-DDTHH:mm:ss');

    var requestData = {
      storeId: window.localStorage.getItem('StoreId'),
      usageId: data[index].usageId,
      startDate: startDate,
      endDate: endDate,
      reason: reasonValue
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('membership/create/holding/admin', 'POST', requestData);

        if (responseData.status === 200) {
          alert("회원권 홀딩 내역을 저장하였습니다.");
          exitClickFn(className);
        }
        else if (responseData.status === 201) {
          alert('동일한 신청내역이 존재합니다!\n정보를 수정 후 다시시도 하세요!');
        }
        else if (responseData.status === 202) {
          alert('홀딩 종료날짜의 설정이 올바르지 않습니다.');
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
        else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();
  }

  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  return (
    <TopLevelWrapper>
      {
        isViewAdminModal ?
          <AdminModalView isOver={isOver}>
            <AdminTotalWrapper>
              <Title>회원권 홀딩 진행</Title>
              <TextWrapper>
                <UnHoverText fontFamily='NotoSansKR-Regular' fontSize={isOver ? 14 : 10} color='#595959'>회원권의 {adminModalText}를 초과하였습니다.</UnHoverText>
                <UnHoverText fontFamily='NotoSansKR-Regular' fontSize={isOver ? 14 : 10} color='#595959'>계속 진행하시겠습니까?</UnHoverText>
              </TextWrapper>
              <AdminButtonWrapper isOver={isOver}>
                <ExitButton isOver={isOver} onClick={() => setIsViewAdminModal(false)}>취소</ExitButton>
                <AdminButton isOver={isOver} onClick={onClickAdminSave}>진행하기</AdminButton>
              </AdminButtonWrapper>
            </AdminTotalWrapper>
          </AdminModalView>
          :
          <ModalView isOver={isOver}>
            <TotalWrapper isOver={isOver}>
              <Title>회원권 홀딩</Title>
              {
                isOver ?
                  <ComponentWrapper isOver={isOver}>
                    <CalenderWrapper>
                      <HoldingCalenderInput
                        startValue={startValue}
                        setStartValue={setStartValue}
                        endValue={endValue}
                        setEndValue={setEndValue}
                        holdingDateSelect={holdingDateSelect} />
                    </CalenderWrapper>
                  </ComponentWrapper>
                  :
                  <ComponentWrapper isOver={isOver}>
                    <CalenderInputWrapper>
                      <CalenderTitle>홀딩 시작일</CalenderTitle>
                      <CalenderInput ref={StartCalenderRef} value={startValue} setValue={setStartValue} small={true} className='StartCalender' clickFn={CalenderClick} placeholder='홀딩 시작일' width='238px' />
                    </CalenderInputWrapper>
                    <CalenderInputWrapper>
                      <CalenderTitle>홀딩 종료일</CalenderTitle>
                      <CalenderInput ref={EndCalenderRef} value={endValue} setValue={setEndValue} small={true} className='EndCalender' clickFn={CalenderClick} placeholder='홀딩 종료일' width='238px' />
                    </CalenderInputWrapper>
                  </ComponentWrapper>
              }
              <BottomComponentWrapper>
                <BottomRowComponentWrapper isOver={isOver}>
                  <TotalTextBoxWrapper>
                    <TextBoxWrapper>
                      <Text>사용가능 홀딩일수</Text>
                      <InputBox leftMargin={16} isOver={isOver}>{data[0].availableHold <= 0 ? 0 : data[0].availableHold}일</InputBox>
                    </TextBoxWrapper>
                    <ExplanationWrapper>
                      <Explanation rightMargin={3} isOver={isOver}>*회원권 별 홀딩 가능 일수는 </Explanation>
                      <Explanation bold={true} isOver={isOver}>계약서</Explanation>
                      <Explanation isOver={isOver}>에 명시되어있습니다.</Explanation>
                    </ExplanationWrapper>
                    <TextBoxWrapper>
                      <Text>홀딩 최종 적용일</Text>
                      <InputBox leftMargin={26} isOver={isOver}>{compriseDate.length}일</InputBox>
                    </TextBoxWrapper>
                  </TotalTextBoxWrapper>
                  <ReasonInput value={reasonValue} setValue={setReasonValue} />
                </BottomRowComponentWrapper>
                <LayerWrapper isOver={isOver}>
                  {
                    isOver &&
                    <HoldingCountWrapper>
                      <HoldingCountText>홀딩 가능 횟수</HoldingCountText>
                      <HodingInput>{data[index].holdCount <= 0 ? 0 : data[index].holdCount}</HodingInput>
                      <HoldingCountText>회</HoldingCountText>
                    </HoldingCountWrapper>
                  }
                  <ButtonWrapper isOver={isOver}>
                    <ExitButton isOver={isOver} onClick={exitClick}>취소</ExitButton>
                    <SaveButton isOver={isOver} onClick={saveClick}>저장하기</SaveButton>
                  </ButtonWrapper>
                </LayerWrapper>
              </BottomComponentWrapper>
            </TotalWrapper>
          </ModalView>
      }

    </TopLevelWrapper>
  )
}

export default MemberShipHoldingModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `722px` : `290px`};
  height: ${props => props.isOver ? `756px` : `536px`};

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const AdminModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `371px` : `290px`};
  height: ${props => props.isOver ? `248px` : `218px`};

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: ${props => props.isOver ? `flex-start` : `center`};
`;

const AdminTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 84.9%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 16px 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `32px` : `18px`};
`;

const CalenderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const CalenderInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 5px;
`;

const CalenderTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const BottomComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  border-top: ${props => props.isOver ? `2px solid #ECECEC` : `0px`};

  width: 100%;
`;

const BottomRowComponentWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TotalTextBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 13px;

  margin-top: 24px;
`;

const TextBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;

  margin: 0 0 0 0;
  // margin-top: ${props => props.topMargin}px;
  // margin-right: 0px;
  // margin-bottom: 0px;
  // margin-left: 0px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: ${props => props.isOver ? `192px` : `57px`};
  height: ${props => props.isOver ? `30px` : `24px`};

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;
 
  color: #000000;

  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: ${props => props.leftMargin}px;

  padding: 0 0 0 8px;
  
  transition: all 0.2s ease-in-out;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin: 13px 0 13px 0;
  // margin: 13px 0 0 0;
`;

const Explanation = styled.p`
  font-family: ${props => props.bold ? `NotoSansKR-Bold` : `NotoSansKR-Regular`};
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #FF8B48;

  margin-top: 0px;
  margin-right: ${props => props.rightMargin}px;
  margin-bottom: 0px;
  margin-left: 0px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: ${props => props.isOver ? `space-between` : `flex-end`};
  align-items: center;

  width: 100%;
`;

const HoldingCountWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
`;

const HoldingCountText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const HodingInput = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 76px;
  height: 32px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  margin: 0 8px 0 8px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #000000;
  background-color: #FFFFFF;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: ${props => props.isOver ? `16px` : `13px`};

  margin: ${props => props.isOver ? `32px 0 0 0` : `44px 0 0 0`};
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `72px` : `55px`};
  height: ${props => props.isOver ? `38px` : `34px`};

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `76px`};
  height: ${props => props.isOver ? `40px` : `34px`};

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 10px;
`;

const UnHoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const AdminButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;
  
  width: 100%;

  margin-top: ${props => props.isOver ? `53px` : `41px`};

  gap: ${props => props.isOver ? `16px` : `13px`};
`;

const AdminButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `78px`};
  height: ${props => props.isOver ? `40px` : `36px`};

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #DD0000;
  }
`;