import { React } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import IdentifierPageLogoPath from '../../../image/SearchIdentifierLogo.png';
import SearchId from './SearchId.js';
import SearchPassword from './SearchPassword.js';

function SearchView(props) {
  const { isOver } = props; 

  const history = useHistory();

  return (
    <TopLevelWrapper>
      <TitleWrapper onClick={() => history.push('/')}>
        <Logo src={IdentifierPageLogoPath}/>
        <Title>Link Zone</Title>
      </TitleWrapper>
      <InfoBoxWrapper>
        <SearchId isOver={isOver}/>
        <SearchPassword isOver={isOver}/>
      </InfoBoxWrapper>
    </TopLevelWrapper>
  );
}

export default SearchView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 42px;
  margin-bottom: 102px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;

  margin-right: 8px;
`;

const Title = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 24px;

  color: #FF8B48;

  margin: 0 0 0 0;
`;

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 20px;

  gap: 24px;
`;