import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import LogoView from '../../../Component/MySettingPageComponent/LogoView';

import DefaultLogoPath from '../../../image/DefaultLogo.png'

function MyGymPicture(props) {
  const { isOver, isClicked, profiles, setProfiles } = props;

	const hiddenFileInput = useRef(null);
  const FileInputRef = useRef();

	const [imgBase64, setImgBase64] = useState([]);
  const [imgFile, setImgFile] = useState();
  const [imgLocalPath, setImgLocalPath] = useState('');

  // useEffect(() => {
    
  //   setImgBase64(profiles);
  // }, [profiles])

  const handleClick = e => {
    hiddenFileInput.current.click();
    FileInputRef.current.style.borderColor = '#FF8B48';
	};
	
  const handleChange = e => { 
    
    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우

    } 
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
      var parentObj = e.target.parentNode
      var node = parentObj.replaceChild(e.target.cloneNode(true), e.target);

      return false;
    }
    
		const reader = new FileReader();
    // setImgLocalPath(e.target.value);
    var tmp = e.target.value.split('\\');
    setImgLocalPath(tmp[tmp.length - 1]);
		reader.onloadend = () => {
			const base64 = reader.result;
      
      FileInputRef.current.style.borderColor = '#E8E8E8';
			if (base64){
        var newData = profiles.slice();
        newData.push(base64.toString());
				// setImgBase64(newData);
        setProfiles(newData);
        console.log(newData);
			}
		};
		if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImgFile(e.target.files[0]);
		}
	};

  function onClickDeleteButton(index) {
    var newData = profiles.slice();

    newData.splice(index, 1);

    setProfiles(newData);
  }

  return (
    <TopLevelWrapper>
      <InfoView isOver={isOver} isClicked={isClicked}>
        <TitleWrapper isOver={isOver}>
          <Title>센터 사진(최대 10장)</Title>
          <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }} />
          { isClicked && <PictureAddButton ref={FileInputRef} onClick={handleClick}>사진추가</PictureAddButton>}
        </TitleWrapper>
        <InfoWrapper isOver={isOver}>
          {
            isClicked ?
              <LayerWrapper isOver={isOver}>
                {
                  profiles.map((data, index) => (
                    <LogoView key={index} data={data} index={index} onClickDeleteButton={onClickDeleteButton}/>
                  ))
                }
              </LayerWrapper>
              :
              <LayerWrapper isOver={isOver}>
                {
                  profiles.map((data, index) => (
                    <Logo key={index} src={data ? data : DefaultLogoPath} />
                  ))
                }
              </LayerWrapper>
          }
        </InfoWrapper>
      </InfoView>
    </TopLevelWrapper>
  )
}

export default MyGymPicture;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 34px;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-bottom: ${props => props.isClicked ? `149px` : `0px`};

  // width: 1191px;
  width: ${props => props.isOver ? `max(692px, 36.04166666666667vw)` : `max(280px, 14.58333333333333vw)`};
  height: ${props => props.isOver ? `338px` : `211px`};

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  // width: 1135px;
  width: ${props => props.isOver ? `max(636px, 33.125vw)` : `max(256px, 13.33333333333333vw)`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const PictureAddButton = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #5BD98A;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
  border-top: 1px solid #ECECEC;

  // width: 1135px;
  width: ${props => props.isOver ? `max(636px, 33.125vw)` : `max(256px, 13.33333333333333vw)`};
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  flex-wrap: ${props => props.isOver ? `wrap` : `no-wrap`};

  overflow: ${props => props.isOver ? `hidden` : `scroll`};

  width: 100%;

  margin-top: 24px;
  gap: 16px;
`;

const Logo = styled.img`
  width: 99px;
  height: 99px;

  border-radius: 15px;

  object-fit: cover;
`;
