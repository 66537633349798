import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule.js';
import AccountInput from '../../../Component/AccountComponent/AccountInput.js';

function SearchId(props) {
  const { isOver } = props;

  const [ownerClickCount, setOwnerClickCount] = useState(0);
  const [emailClickCount, setEmailClickCount] = useState(0);
  const [invalidEmail, setInvalidEmail] = useState(true);
  const [planationText, setPlanationText] = useState('필수항목을 입력해주세요');
  const [sendExplanation, setSendExplanation] = useState('해당 주소로 이메일이 전송되었습니다');

  const OwnerRef = useRef();
  const EmailRef = useRef();

  const OwnerPlanationRef = useRef();
  const EmailPlanationRef = useRef();
  const TotalPlanationRef = useRef();

  useEffect(() => {
    if (invalidEmail) setPlanationText('필수항목을 입력해주세요');
    else setPlanationText('올바른 이메일 형식이 아닙니다');
  }, [invalidEmail])

  useEffect(() => {
		if (ownerClickCount === 0 && emailClickCount === 0) return;

		if (OwnerRef.current.getValue() === '' && ownerClickCount > 0) OwnerPlanationRef.current.style.opacity = 1;
    else OwnerPlanationRef.current.style.opacity = 0;

		if (EmailRef.current.getValue() === '' && emailClickCount > 0) EmailPlanationRef.current.style.opacity = 1;
		else EmailPlanationRef.current.style.opacity = 0;
    // else PlanationRef.current.style.color = '#FF8B48';
	}, [ownerClickCount, emailClickCount])

  // email형식 확인 정규식
	function isEmail(asValue) {
		var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,6}$/i;

		return regExp.test(asValue);
	}

  // Input 컴포넌트 클릭시
  function clickCountFn(className, clickCount) {
    if (className === 'OwnerInput') setOwnerClickCount(clickCount);
    else if (className === 'EmailInput') setEmailClickCount(clickCount);
  }

  // 이메일 전송 클릭시
  function sendingButtonClick() {	
    var ownerName = OwnerRef.current.getValue();
    var email = EmailRef.current.getValue();
    var invalidCount = 0;

		if (ownerName === '') { 
			OwnerRef.current.plusClickCount();
			OwnerRef.current.setDefaultPlaceholder('반드시 성함을 입력해주세요');
			OwnerPlanationRef.current.style.opacity = 1;
			
			invalidCount += 1;
		} 
	
		if (email === '') { 
			EmailRef.current.plusClickCount();
			EmailRef.current.setDefaultPlaceholder('가입시 입력하신 이메일 주소를 적어주세요');
			EmailPlanationRef.current.style.opacity = 1;
			
			invalidCount += 1;
		} 
    else if (!isEmail(email)) {
      setInvalidEmail(false);
			EmailRef.current.setDefaultPlaceholder('가입시 입력하신 이메일 주소를 적어주세요');
			EmailPlanationRef.current.style.opacity = 1;
			
			invalidCount += 1;
    }

    if (invalidCount > 0) return;

    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('identifier/asp/identified/' + ownerName + '/' + email, 'POST');
				 
				if (responseData.status === 200) setSendExplanation('해당 주소로 이메일이 전송되었습니다');
				else setSendExplanation('올바르지 않은 정보입니다.');
			}
		)();
    
    TotalPlanationRef.current.style.opacity = 1;
  }

  return (
    <TopLevelWrapper>
      <InfoBox isOver={isOver}>
        <InfoWrapper isOver={isOver}>
          <Title isOver={isOver}>아이디 찾기</Title>
          <InputInfo isOver={isOver}>관리자 성함</InputInfo>
          <AccountInput ref={OwnerRef} className='OwnerInput' placeholder='반드시 성함을 입력해주세요' type='text' clickCountFn={clickCountFn} />
          <AccountExplanation ref={OwnerPlanationRef}>필수항목을 입력해주세요</AccountExplanation>
          <InputInfo isOver={isOver}>이메일</InputInfo>
          <AccountInput ref={EmailRef} className='EmailInput' placeholder='가입시 입력하신 이메일 주소를 적어주세요' type='text' clickCountFn={clickCountFn} />
          <AccountExplanation ref={EmailPlanationRef}>{planationText}</AccountExplanation>
          <SendingButton onClick={sendingButtonClick} isOver={isOver}>
            <SendingButtonText>인증 메일 전송</SendingButtonText>
          </SendingButton>
          <TotalExplanationWrapper>
            <TotalExplanation ref={TotalPlanationRef} isOver={isOver}>{sendExplanation}</TotalExplanation>
          </TotalExplanationWrapper>
        </InfoWrapper>
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default SearchId;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(449px, 58.46354166666667vw)` : `max(297px, 92.8125vw)`};
  height: ${props => props.isOver ? `338px` : `286px`};

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: ${props => props.isOver ? `max(369px, 48.046875vw)`: `max(229px, 71.5625vw)`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 18px;

  color: #000000;

  margin: ${props => props.isOver ? `0 0 31px 0` : `0 0 27px 0`};
`;

const InputInfo = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #000000;

  margin: ${props => props.isOver ? `11px 0 0 0` : `7px 0 0 0`};
`;

const AccountExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;

	color: #FF3131;

	margin: 4px 0 0 0;

	opacity: 0;
  transition: all 0.5s ease-in-out;
`;

const SendingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(369px, 48.046875vw)` : `max(229px, 71.5625vw)`};
  height: ${props => props.isOver ? `28px` : `23px`};

  margin-top: ${props => props.isOver ? `13px` : `14px`};

  background-color: #FF8B48;
  border-radius: 23px;

  transition: all 0.5s ease-in-out;

	:hover {
		cursor: pointer;
		background-color: #FF7322;
	}
`;

const SendingButtonText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 12px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const TotalExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const TotalExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;

	color: #FF8B48;

	margin: ${props => props.isOver ? `8px 0 0 0` : `10px 0 0 0`};

	opacity: 0;
  transition: all 0.5s ease-in-out;
`;
