import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Share/Network/FetchModule';
import ExerciseBox from './ExerciseBox';
import ExerciseNoticeView from './ExerciseNotice';

function BoardView(props) {
  const { isOver } = props;

  const history = useHistory();

  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment());
  const [dateList, setDateList] = useState([]);
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [workoutId, setWorkoutId] = useState(0);
  const [lessonId, setLessonId] = useState(-1);
  const [scheduleId, setScheduleId] = useState(-1);
  const [classTime, setClassTime] = useState(undefined);
  const [rendering, setRendering] = useState(false);

  const [useData, setUseData] = useState([{
    workoutId: 0,
    scheduleId: 0,
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    classDate: moment(),
    title: '작성이 필요합니다.',
    sectionList: []
  }]);

  const [notepad, setNotepad] = useState({
    id: 0,
    workoutId: 0,
    contents: '',
  });

  const ExerciseRef = useRef();

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  // Calendar Date Setting //
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      } 
    }
    setClickDate(date.clone().format('YYYY.MM.DD'));
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek]) 

    // Workout API Call //
    useEffect(() => {
      if (scheduleId === -1) return;
  
      var data = {
        scheduleId: scheduleId,
        month: date.clone().format('MM')
      }
  
      const fetchModule = new FetchModule();
      (
        async () => {
          var responseData = await fetchModule.postDataOfExercise('workout/', 'POST', data);
          // console.log(responseData);
          if (responseData.status === 200) {
            var workoutData = responseData.data.slice();
  
            // 운동 데이터 정리 //
            for (var i = 0; i < workoutData.length; i++) {
  
              workoutData[i].sectionList.sort(function (a, b) {
                return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
              })
  
              for (var j = 0; j < workoutData[i].sectionList.length; j++) {
                workoutData[i].sectionList[j].buyinExerciseList = [];
                workoutData[i].sectionList[j].mainExerciseList = [];
                workoutData[i].sectionList[j].buyoutExerciseList = [];
                workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');
  
                for (var h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
                  workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
                  workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
                  workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;
  
                  if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
                    workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
                    workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
                    workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                  }
                }
  
                // exerciseIndex.asc 진행 //
                workoutData[i].sectionList[j].buyinExerciseList.sort(function (a, b) {
                  return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
                })
                // exerciseIndex.asc 진행 //
                workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
                  return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
                })
                // exerciseIndex.asc 진행 //
                workoutData[i].sectionList[j].buyoutExerciseList.sort(function (a, b) {
                  return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
                })
              }
            }
  
            setData(workoutData);
          }
          else if (responseData.status === 204) setData([]);
          // else if (responseData.status === 401) history.push('/');
        }
      )();
    }, [history, date, scheduleId, rendering])

  // Workout Data Setting //
  useEffect(() => {
    if (dateList.length === 0) return;

    var newData = data.slice();

    if (newData.length === 0) { // 데이터가 없으면 1개 넣고 시작
      var tmpData = {
        workoutId: 0,
        scheduleId: 0,
        storeId: parseInt(window.localStorage.getItem('StoreId')),
        classDate: dateList[0],
        title: '',
        sectionList: []
      }
    }

    for (var i = 0; i < dateList.length; i++) {
      var isNone = true;

      for (var j = 0; j < newData.length; j++) {
        if (dateList[i].format('MM.DD') === moment(newData[j].classDate).format('MM.DD')) {
          isNone = false;
          newData[j].classDate = moment(newData[j].classDate);
          break;
        }
      }
      
      if (isNone) {
        tmpData = {
          workoutId: 0,
          scheduleId: 0,
          storeId: parseInt(window.localStorage.getItem('StoreId')),
          classDate: dateList[i],
          title: '',
          sectionList: []
        }
        newData.push(tmpData);
      }
    }
    
		newData.sort(function (a, b) {
			return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
		})
    
    setUseData(newData);
  }, [dateList, data])

  // 운동 메모 불러오기 //
  useEffect(() => {
    if (workoutId === 0) return;

    var requestData = {
      workoutId: workoutId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.postDataOfExercise('notepad/', 'POST', requestData);
        
        if (responseData.status === 200) {
          if (responseData.data[0] !== null) setNotepad(responseData.data[0]);
        }
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [workoutId])

  return (
    <TopLevelWrapper isOver={isOver}>
      <TitleWrapper isOver={isOver}>
        <Title isOver={isOver}>보드</Title>
        <ExplanationWrapper isOver={isOver}>
          <Explanation isOver={isOver}>기록사항을 편하게 적어두세요</Explanation>
          <Go isOver={isOver} onClick={() => history.push('/board')}>자세히 보기</Go>
        </ExplanationWrapper>
      </TitleWrapper>
      <InfoBox isOver={isOver}>
        {/* <ExerciseBox date={date} clickDate={clickDate} useData={useData} lessonId={lessonId} setLessonId={setLessonId} setScheduleId={setScheduleId} setClassTime={setClassTime}  rendering={() => setRendering(!rendering)} isOver={isOver}/> */}
        <ExerciseBox
          ref={ExerciseRef} 
          clickDate={clickDate} 
          useData={useData} 
          date={date}
          classTime={classTime}
          workoutId={workoutId}
          setWorkoutId={setWorkoutId}
          setLessonId={setLessonId} 
          setScheduleId={setScheduleId} 
          setClassTime={setClassTime} 
          rendering={() => setRendering(!rendering)}/>
        <ExerciseNoticeView isOver={isOver} notepad={notepad}/>
      </InfoBox>
    </TopLevelWrapper>
  )
};

export default BoardView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 692px;
  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(297px, 92.8125vw)`};
  // height: 385px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: ${props => props.isOver ? `28px` : `20px`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: ${props => props.isOver ? `4px` : `0px`};

  // width: 636px;
  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(255px, 79.6875vw)`};
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};
  
  color: #777777;
  
  margin: 0 0 0 0;
`;

const Go = styled.p`
  font-family: NotoSansKr-Medium;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver? `row` : `column`};

  justify-content: flex-start;
  align-items: flex-start;

  gap: 16px;

  border-top: 1px solid #ECECEC;

  margin-top: 24px;
  padding-top: 24px;

  // width: 636px;
  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(255px, 79.6875vw)`};
`;

