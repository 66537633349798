import { React, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import MemberShipNameInput from '../../../../Component/SettingComponent/MemberShipNameInput';
import SettingCoachGenderInput from '../../../../Component/SettingComponent/SettingCoachGenderInput';
import CoachImageInput from '../../../../Component/SettingComponent/CoachImageInput';

import FetchModule from '../../../Share/Network/FetchModule';

const CoachAddModal = forwardRef((props, ref) => {
  const { isOver, data, index, exitClickFn, rendering } = props;

  const history = useHistory();

  const TopLevelRef = useRef();
  const NameRef = useRef();
  const PhoneRef = useRef();
  const LinkCoachRef = useRef();
  const GenderRef = useRef();
  const EmailRef = useRef();
  const ProfileRef = useRef();

  useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
      TopLevelRef.current.style.opacity = val;
    },
		setZindex: (val) => {
      TopLevelRef.current.style.zIndex = val;
    }
	}));
  
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  useEffect(() => {
    if (index === -1) return;

    NameRef.current.setValue(data[index].name);
    PhoneRef.current.setValue(data[index].phone);
    GenderRef.current.setValue(data[index].gender);
    LinkCoachRef.current.setValue(data[index].linkCoachId);
    ProfileRef.current.setImgBase64(data[index].profile);
  }, [data, index])

  function exitClick() {
    exitClickFn();
  }

  function SaveClick() {
    // var storeId = window.sessionStorage.getItem('StoreId');
    var storeId = window.localStorage.getItem('StoreId');
    var name = NameRef.current.getValue();
    var phone = PhoneRef.current.getValue();
    var linkCoachId = LinkCoachRef.current.getValue();
    var gender = GenderRef.current.getValue();
    var profile = ProfileRef.current.getImgBase64();

    var regexEn = /[a-zA-Z]/;
    var regexKr = /[ㄱ-ㅎ가-힣]/;
    var regexNum = /[0-9]/;

    // 이름이 공백이거나 숫자가 들어간 경우
    if (name === '' || regexNum.test(name)) {
      alert('이름을 정확히 입력하세요');
      return;
    }
    // 전화번호에 영어나 한글이 섞여있거나 길이가 부족할 경우
    else if (phone.length !== 11 || regexEn.test(phone) || regexKr.test(phone)) {
      alert('전화번호를 정확히 입력하세요');
      return;
    }
    // 링크 코치 아이디가 공백이거나 한글이 섞여있는 경우
    else if (linkCoachId === '' || regexKr.test(linkCoachId)) {
      alert('링크 코치 아이디를 정확히 입력하세요');
      return;
    }
    // 새로 생성일 경우에만 이미지가 선택되었는지 확인
    else if (profile === undefined && index === -1) {
      alert('프로필 사진을 업로드하세요');
      return;
    }

    var newData = {
      id: index === -1 ? '' : data[index].id,
      storeId: storeId,
      name: name,
      phone: phone,
      gender: gender,
      linkCoachId: linkCoachId,
      profile: profile
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData;
        if (index === -1) responseData = await fetchModule.postData('coach/create', 'POST', newData);
        else responseData = await fetchModule.patchData('coach/modify/', 'PATCH', newData);

        if (responseData.status === 200) {
          rendering();
          exitClickFn('Class');
        }
        else if (responseData.status === 401) history.push('/');
        else {}
      }
    )();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <Modal isOver={isOver}>
        { isOver && <Title isOver={isOver}>코치 정보 추가</Title> }
        <TotalWrapper isOver={isOver}>
        { !isOver && <Title isOver={isOver}>코치 정보 추가</Title> }
          <ModelWrapper>
            <ComponentWrapper isOver={isOver}>
              <ComponentTitle isOver={isOver}>이름</ComponentTitle>
              <MemberShipNameInput ref={NameRef} isOver={isOver} placeholder='코치 이름을 입력해주세요' />
            </ComponentWrapper>
            <ComponentWrapper isOver={isOver}>
              <ComponentTitle isOver={isOver}>핸드폰 번호</ComponentTitle>
              <MemberShipNameInput ref={PhoneRef} isOver={isOver} placeholder='-를 제외하고 입력해주세요' />
            </ComponentWrapper>
            <ComponentWrapper isOver={isOver} style={ isOver ? {marginBottom: `140px`} : {} }>
              <ComponentTitle isOver={isOver}>링크코치 아이디</ComponentTitle>
              <MemberShipNameInput ref={LinkCoachRef} isOver={isOver} placeholder='링크코치 아이디를 입력해주세요' />
            </ComponentWrapper>
          </ModelWrapper>
          <ModelWrapper>
            <ComponentWrapper isOver={isOver}>
              <ComponentTitle isOver={isOver}>성별</ComponentTitle>
              <SettingCoachGenderInput ref={GenderRef} isOver={isOver}/>
            </ComponentWrapper>
            <ComponentWrapper isOver={isOver}>
              <ComponentTitle isOver={isOver}>프로필 사진</ComponentTitle>
              <CoachImageInput ref={ProfileRef} isOver={isOver}/>
            </ComponentWrapper>
          </ModelWrapper>
        </TotalWrapper>
        <ButtonWrapper isOver={isOver}>
          <ExitButton isOver={isOver} onClick={exitClick}>취소</ExitButton>
          <DeleteButton isOver={isOver} onClick={SaveClick}>저장하기</DeleteButton>
        </ButtonWrapper>
      </Modal>
    </TopLevelWrapper>
  )
})

export default CoachAddModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.2);
  
  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: ${props => props.isOver ? `flex-start` : `center`};

  // width: 744px;
  width: ${props => props.isOver ? `692px` : `290px`};
  // height: 507px;
  height: ${props => props.isOver ? `468px` : `561px`};

  padding: ${props => props.isOver ? `0 0 0 28px` : `0 0 0 0`};

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `24px` : `0px`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `14px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const ModelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // margin-top: 24px;
  
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // height: 70px;
  margin-top: ${props => props.isOver ? `24px` : `12px`};
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #232323;

  margin: 0 0 0 0;
`;


const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: ${props => props.isOver ? `16px` : `12px`};

  margin: ${props => props.isOver ? `0 0 0 456px` : `14px 0 0 129px`};
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `72px` : `52px`};
  height: ${props => props.isOver ? `38px` : `28px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `8px`};
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `70px`};
  height: ${props => props.isOver ? `40px` : `30px`};

  border-radius: ${props => props.isOver ? `15px` : `8px`};
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;