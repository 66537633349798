import { React, useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { useReactToPrint } from 'react-to-print';

import TableView from './TableView';

import TitleLogo from '../../../image/printLogo.png';

const ScheduleView = forwardRef((props, ref) => {
  var htmlToImage = require('html-to-image');

  const [paperSetting, setPaperSetting] = useState('A4');
  const [startThisWeek, setStartThisWeek] = useState(moment());
  const [title, setTitle] = useState('이번주 피트니스 수업 시간표');
  const [logo, setLogo] = useState(TitleLogo);
  const [dateValue, setDateValue] = useState([true, true, true, true, true, true, true]);

  const TopLevelRef = useRef();
  const TopComponentRef = useRef();
  const InfoBoxRef = useRef();

  const TitleRef = useRef();
  const DateTextRef = useRef();
  const BottomRef = useRef();
  const NameRef = useRef();
  const AddrRef = useRef();
  const TelRef = useRef();
  const CompanyRef = useRef();

  const TableViewRef = useRef();

  useImperativeHandle(ref, () => ({
    setStartThisWeek: (val) => {
      setStartThisWeek(val);
    },
    setTitle: (val) => {
      if (val === '') setTitle('이번주 피트니스 수업 시간표');
      else setTitle(val);
    },
    setLogo: (val) => {
      setLogo(val);
    },
    setDateValue: (val) => {
      setDateValue(val);
      TableViewRef.current.setApplyWeek(val);
    },
    setBackgroundColor: (val) => {
      if (val === '#FFFFFF') {
        TitleRef.current.style.color = '#000000';
        DateTextRef.current.style.color = '#000000';
        NameRef.current.style.color = '#000000';
        AddrRef.current.style.color = '#000000';
        TelRef.current.style.color = '#000000';
        CompanyRef.current.style.color = '#000000';
      }
      else {
        TitleRef.current.style.color = '#FFFFFF';
        DateTextRef.current.style.color = '#FFFFFF';
        NameRef.current.style.color = '#FFFFFF';
        AddrRef.current.style.color = '#FFFFFF';
        TelRef.current.style.color = '#FFFFFF';
        CompanyRef.current.style.color = '#FFFFFF';
      }
      TopLevelRef.current.style.backgroundColor = val;
    },
    downloadScheduleJpeg: () => {
      alert('시간표 다운로드를 시작합니다. 잠시만 기다려주세요.');
      htmlToImage.toJpeg(document.getElementById('ScheduleTable'), { quality: 0.95 }).then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'ScheduleTable.jpeg';
        link.href = dataUrl;
        link.click();
      })
    },
    printSchedule: () => {
      handlePrint();
    },
    setPaperSetting: (val) => {
      setPaperSetting(val)
    }
  }))
  
  useEffect(() => {
    if (paperSetting === 'A4') {
      TopLevelRef.current.style.width = `661px`;
      TopLevelRef.current.style.height = `935.14px`;
      InfoBoxRef.current.style.width = `631.54px`;
      InfoBoxRef.current.style.height = `827.53px`;

      TopComponentRef.current.style.marginTop = `14.73px`;
      BottomRef.current.style.marginTop = `8.97px`;
    }
    else if (paperSetting === '1:1') {
      TopLevelRef.current.style.width = `661px`;
      TopLevelRef.current.style.height = `661px`;
      InfoBoxRef.current.style.width = `641.98px`;
      InfoBoxRef.current.style.height = `584.94px`;

      TopComponentRef.current.style.marginTop = `10.42px`;
      BottomRef.current.style.marginTop = `6.38px`;
    }
    else if (paperSetting === '16:9') {
      TopLevelRef.current.style.width = `661px`;
      TopLevelRef.current.style.height = `1175.97px`;
      InfoBoxRef.current.style.width = `631.54px`;
      InfoBoxRef.current.style.height = `1068.36px`;

      TopComponentRef.current.style.marginTop = `14.73px`;
      BottomRef.current.style.marginTop = `8.96px`;
    }
  }, [paperSetting])

  useEffect(() => {
    TableViewRef.current.setStartDay(startThisWeek);
  }, [startThisWeek])

  // 시간표 프린트 함수
  const handlePrint = useReactToPrint({
    content: () => TopLevelRef.current,
  })

  return (
    <TopLevelWrapper ref={TopLevelRef} id='ScheduleTable'>
      <TopComponentWrapper ref={TopComponentRef}>
        <TitleWrapper>
          <Logo src={logo} />
          <Title ref={TitleRef}>{title}</Title>
        </TitleWrapper>
        <DateText ref={DateTextRef}>{startThisWeek.clone().format('YYYY년 MM월 DD일')} - {startThisWeek.clone().add(6, 'day').format('DD일')}</DateText>
      </TopComponentWrapper>
      <InfoBox ref={InfoBoxRef}>
        <TableView ref={TableViewRef}/>
      </InfoBox>
      <BottomComponentWrapper ref={BottomRef}>
        <LeftWrapper>
          <BottomText ref={NameRef}>안산 보스짐 엔터테인먼트</BottomText>
          <BottomText ref={AddrRef}>안산시 단원구 초지동 원선1로 38</BottomText>
          <BottomText ref={TelRef}>TEL : 010-5519-2354</BottomText>
        </LeftWrapper>
        <BottomText ref={CompanyRef}>ⓒLink Manager</BottomText>
      </BottomComponentWrapper>
    </TopLevelWrapper>
  )
})

export default ScheduleView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  font-size: 19.21px;

  margin-bottom: 90px;

  border-radius: 15px;
  background-color: #6DB5CB;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  transition: all 0.3s ease-in-out;
  
`;

const TopComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: center;

  width: 95.54%;

  margin-top: 14.73px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 10.25px;
`;

const Logo = styled.img`
  width: 46.12px;
  height: 46.12px;

  margin: 0 0 0 0;

  object-fit: contain;
  
  border-radius: 46.12px;
  // background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  // font-size: 19.22px;
  font-size: 64.03%;

  color: #FFFFFF;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  // font-size: 12.81px;
  font-size: 42.7%;

  color: #FFFFFF;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 15.37px;

  width: 95.54%;
  height: 88.49%;

  font-size: 100%;
`;

const BottomComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95.54%;

  margin-top: 8.97px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 55.42%;
`;

const BottomText = styled.p`
  font-family: NotoSansKR-Medium;
  // font-size: 10px;
  font-size: 46.66%;
  
  color: #FFFFFF;

  margin: 0 0 0 0;
  // -webkit-transform: scale(0.897);
  
  transition: all 0.3s ease-in-out;
`;