import {React, useEffect, useState} from 'react';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule.js';
import CertificationLogoPath from '../../../image/CertificationLogo.png';


const CertificationView = () => {
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    var certificationString = window.sessionStorage.getItem("CertificationString");
    
    (
      async () => {
        const fetchModule = new FetchModule();
        const response = await fetchModule.postData('account/asp/certification/' + certificationString, 'POST');
        
        if (response.status === 200) setIsSuccess(true);
        else setIsSuccess(false);
      }
    )();
  }, [])

  function onExit() {
    window.open("about:blank", "_self");
    window.close();
  }

  return (
    <TopLevelWrapper>
      <LogoWrapper>
        <Logo src={CertificationLogoPath}/>
      </LogoWrapper>
      <Banner>Link Zone</Banner>
      <SemiBanner>쉽고 간편한 피트니스 회원 관리 프로그램</SemiBanner>
      {
        isSuccess ? 
        <Explanation>인증이 완료되었습니다! 회원 가입 페이지에서 진행해주세요</Explanation>
        :
        <Explanation>유효하지 않은 접근입니다!</Explanation>
      }
      <ExitButton onClick={onExit}>
        <Exit>나가기</Exit>
      </ExitButton>
    </TopLevelWrapper>
  )
};

export default CertificationView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  margin-bottom: 12px;
`;

const Logo = styled.img`
  width: 20vh;
  height: 20vh;
`;

const Banner = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 30px;
  color: #151617;

  margin: 0 0 0 0;
`;

const SemiBanner = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 18px;
  color: #151617;
  opacity: 0.7;
  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 15px;
  color: #000000;
  margin: 56px 0 0 0;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 90.33%;
  height: 40px;

  background-color: #FF8B48;
  border-radius: 25px;

  margin-top: 64px;

  :hover { 
    cursor: pointer;
    background-color: #FF7322;
  }
`;

const Exit = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;
  color: #FFFFFF;

  margin: 0 0 0 0;
`;