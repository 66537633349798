import { React, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import RegistrationTopView from './RegistrationTopView.js';
import MemberDefaultInfoView from './MemberDefaultInfoView.js';
import MemberShipInfoView from './MemberShipInfoView.js';

import PaymentModal from './PaymentModal.js';

import FetchModule from '../../Share/Network/FetchModule.js';
import SessionStorage from '../../Share/Network/SessionStorage.js';
import moment from 'moment';
import CustomAlert from '../../Share/Normal/CustomAlert.js';

const Registration = () => {
  const history = useHistory();

  const [isClicked, setIsClicked] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [productName, setProductName] = useState('');
  const [productCoach, setProductCoach] = useState('');
  const [productValidity, setProductValidity] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productCash, setProductCash] = useState(0);
  const [productMerchantUid, setProductMerchantUid] = useState('');

  const MemberDefaultInfoRef = useRef();
  const MemberShipInfoRef = useRef();

  function checkMemberInfo() {
    var name = MemberDefaultInfoRef.current.getName();
    var birthDay = MemberDefaultInfoRef.current.getBirth();
    var gender = MemberDefaultInfoRef.current.getGender();
    var phone = MemberDefaultInfoRef.current.getPhone();
    var id = MemberDefaultInfoRef.current.getId();
    var memberShipName = MemberShipInfoRef.current.getMemberShipName();
    var agreement = MemberDefaultInfoRef.current.getAgreement();
    var profile = MemberDefaultInfoRef.current.getProfile();

    var regexEn = /[a-zA-Z]/;
    var regexKr = /[ㄱ-ㅎ가-힣]/;
    var regexNum = /[0-9]/;

    // 이름이 공백일 경우
    if (name === '') {
      MemberDefaultInfoRef.current.plusNameClickCount();
      return false;
    }
    // 정규식 사용 (숫자 사용 검출용)
    else if (regexNum.test(name)) {
      MemberDefaultInfoRef.current.invalidName();
      return false;
    }

    // 생년월일이 공백인 경우
    if (birthDay === '') {
      MemberDefaultInfoRef.current.plusBirthClickCount();
      return false;
    }
    // 정규식 사용 (문자 검출용)
    else if (regexEn.test(birthDay) || regexKr.test(birthDay) || birthDay.length !== 8) {
      MemberDefaultInfoRef.current.invalidBirth();
      return false;
    }

    // Gender는 패스

    // 전화번호가 공백일 경우
    if (phone === '') {
      MemberDefaultInfoRef.current.plusPhoneClickCount();
      return false;
    }
    // 정규식 사용 (문자 검출용)
    else if (regexEn.test(phone) || regexKr.test(phone) || phone.length !== 11) {
      MemberDefaultInfoRef.current.invalidPhone();
      return false;
    }

    if (id === '') {
      MemberDefaultInfoRef.current.plusIdClickCount();
      return false;
    }
    // 유효성검사 진행

    // 개인정보 이용동의 하지 않았을 경우
    if (!agreement) {
      // MemberDefaultInfoRef.current.agreementAccent();
      alert('개인정보 이용약관를 동의해 주세요.');
      return false;
    }

    if (profile === undefined) {
      alert('회원님 프로필 사진을 업로드해주세요.');
      return false;
    }

    return true;
  }

  function checkMemberShipInfo() {
    var startDate = MemberShipInfoRef.current.getStartDate();
    var endDate = MemberShipInfoRef.current.getEndDate();
    var approvalDate = MemberShipInfoRef.current.getApprovalDate();
    var memberShipData = MemberShipInfoRef.current.getMemberShipData();

    if (startDate === '') {
      alert('회원권 시작날짜를 설정해주세요');
      return false;
    }

    if (endDate === '') {
      alert('회원권 종료날짜를 설정해주세요');
      return false;
    }

    if (approvalDate === '') {
      alert('회원권 결제 날짜를 설정해주세요');
      return false;
    }

    if (memberShipData === '') {
      alert('회원권을 선택해주세요');
      return false;
    }

    return true;
  }

  function onRegist() {

    if (!MemberDefaultInfoRef.current.getAgreement()) {
      alert("개인정보 처리방침에 동의해주세요");
      return;
    }

    if (MemberDefaultInfoRef.current.getName() === '') {
      alert("회원의 이름을 입력해주세요");
      return;
    }

    if (MemberDefaultInfoRef.current.getBirth() === '' || MemberDefaultInfoRef.current.getBirth().length !== 8) {
      alert("생년월일을 정확히 입력해주세요");
      return;
    }

    if (MemberDefaultInfoRef.current.getPhone() === '' || MemberDefaultInfoRef.current.getPhone().length !== 11) {
      alert("휴대전화 번호를 정확히 입력해주세요");
      return;
    }

    // if (MemberDefaultInfoRef.current.getId() === '') {
    //   alert("링크 코치 아이디를 정확히 입력해주세요");
    //   return;
    // }

    if (MemberDefaultInfoRef.current.getProfile() === '') {
      alert("회원 프로필 사진을 업로드해주세요");
      return;
    }

    if (checkMemberShipInfo()) {
      var memberShipData = MemberShipInfoRef.current.getMemberShipData();
      var cardPrice = MemberShipInfoRef.current.getCardPrice();
      var cashPrice = MemberShipInfoRef.current.getCashPrice();

      var requestData = {
        itemName: memberShipData.name,
        cashPrice: cashPrice,
        cardPrice: cardPrice,
        // storeId: SessionStorage.getStoreId(),
        storeId: window.localStorage.getItem('StoreId'),
        status: 0,
        userId: MemberDefaultInfoRef.current.getId() === '' ? 'None-' + MemberDefaultInfoRef.current.getPhone() : MemberDefaultInfoRef.current.getId()
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/merchant', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setProductName(responseData.data[0].itemName);
            setProductCoach('');
            setProductValidity(memberShipData.validity);
            setProductPrice(responseData.data[0].cardPrice);
            setProductCash(responseData.data[0].cashPrice);
            setProductMerchantUid(responseData.data[0].merchantUid);

            setIsClicked(true);
          }
          else if (responseData.status === 401) {
            alert('로그인 후 다시시도하세요');
            history.push('/');
          }
        }
      )();
    }
  }

  function cancelFunction(merchant_uid) {
    var name = MemberDefaultInfoRef.current.getName();
    var birthDay = MemberDefaultInfoRef.current.getBirth();
    var gender = MemberDefaultInfoRef.current.getGender();
    var phone = MemberDefaultInfoRef.current.getPhone();
    var id = MemberDefaultInfoRef.current.getId() === '' ? 'None-' + phone : MemberDefaultInfoRef.current.getId();
    var memberShipName = MemberShipInfoRef.current.getMemberShipName();
    var profile = MemberDefaultInfoRef.current.getProfile();

    var data = {
      // storeId: window.sessionStorage.getItem('StoreId'),
      storeId: window.localStorage.getItem('StoreId'),
      birthDay: birthDay,
      linkCoachId: id,
      name: name,
      phone: phone,
      gender: gender,
      profile: profile,
      memberDetail: MemberDefaultInfoRef.current.getMemberDetail(),
      approval: moment().format('YYYY-MM-DDTHH:mm:ss'),
      memberShipId: MemberShipInfoRef.current.getMemberShipId(),
      memberShipName: memberShipName,
      merchantUid: merchant_uid,
      courseTakeCount: 0,
      startDate: MemberShipInfoRef.current.getStartDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss'),
      endDate: MemberShipInfoRef.current.getEndDate().replaceAll('.', '-') + 'T23:59:59',
      cashPrice: MemberShipInfoRef.current.getCashPrice(),
      cardPrice: MemberShipInfoRef.current.getCardPrice(),
      notReceivePrice: MemberShipInfoRef.current.getNotReceivePrice(),
      approvalDate: MemberShipInfoRef.current.getApprovalDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('member/registration/all', 'POST', data);

        if (responseData.status === 200) {
          // const responseStoreData = await fetchModule.getData('store/', 'GET');

          // if (responseStoreData.status === 200) {
          //   var newData = {
          //     storeId: window.sessionStorage.getItem('StoreId'),
          //     storeName: responseStoreData.data[0].storeName,
          //     userId: id,
          //     merchantUid: merchant_uid,
          //     itemName: productName,
          //     approval: 1
          //   }

          //   const responseRegistData = await fetchModule.postDataOfCoach('registration/create', 'POST', newData);
          // }
          setIsSuccess(true);
          // alert("등록이 완료되었습니다.");
          // history.push('/main');
        }
        else if (responseData.status === 201) {
          alert("이미 존재하는 회원입니다.");
        }
        else if (responseData.status === 401) {
          alert("로그인 후 사용해주세요.");
          history.push('/');
        }
        else alert("회원등록에 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();

    setIsClicked(false);
  }

  function saveRegistData(merchantUid) {
    var name = MemberDefaultInfoRef.current.getName();
    var birthDay = MemberDefaultInfoRef.current.getBirth();
    var gender = MemberDefaultInfoRef.current.getGender();
    var phone = MemberDefaultInfoRef.current.getPhone();
    var id = MemberDefaultInfoRef.current.getId() === '' ? 'None-' + phone : MemberDefaultInfoRef.current.getId();
    var memberShipName = MemberShipInfoRef.current.getMemberShipName();
    var profile = MemberDefaultInfoRef.current.getProfile();

    var data = {
      // storeId: window.sessionStorage.getItem('StoreId'),
      storeId: window.localStorage.getItem('StoreId'),
      birthDay: birthDay,
      linkCoachId: id,
      name: name,
      phone: phone,
      gender: gender,
      profile: profile,
      memberDetail: MemberDefaultInfoRef.current.getMemberDetail(),
      approval: moment().format('YYYY-MM-DDTHH:mm:ss'),
      memberShipId: MemberShipInfoRef.current.getMemberShipId(),
      memberShipName: memberShipName,
      courseTakeCount: 0,
      merchantUid: merchantUid,
      startDate: MemberShipInfoRef.current.getStartDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss'),
      endDate: MemberShipInfoRef.current.getEndDate().replaceAll('.', '-') + 'T23:59:59',
      cashPrice: MemberShipInfoRef.current.getCashPrice(),
      cardPrice: MemberShipInfoRef.current.getCardPrice(),
      notReceivePrice: MemberShipInfoRef.current.getNotReceivePrice(),
      approvalDate: MemberShipInfoRef.current.getApprovalDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss')
    }

    window.sessionStorage.setItem("RegistData", JSON.stringify(data));
    window.sessionStorage.setItem("UsageData", 'None');
  }

  function onClickCloseCustomAlert() {
    setIsSuccess(false);
    history.push('/main');
  }

  return (
    <TopLevelWrapper>
      { isSuccess && <CustomAlert text="등록이 완료되었습니다" closeFn={onClickCloseCustomAlert}/> }
      {
        isClicked &&
        <PaymentModal
          name={productName}
          coach={productCoach}
          validity={productValidity}
          price={productPrice}
          cashPrice={productCash}
          merchantUid={productMerchantUid}
          onRegist={cancelFunction}
          cancel={() => setIsClicked(false)} 
          saveRegistData={saveRegistData}/>
      }
      <RegistrationTopView registerClick={onRegist}/>
      <MemberDefaultInfoView ref={MemberDefaultInfoRef}/>
      <MemberShipInfoView 
        ref={MemberShipInfoRef}
        registerClick={onRegist} />
    </TopLevelWrapper>
  )
}

export default Registration;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  // margin-left: 34px;
  // justify-content: center;
  // align-items: center;

  transition: all 0.3s ease-in-out;
`;