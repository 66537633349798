import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import { loadTossPayments } from '@tosspayments/payment-sdk';

import FetchModule from '../../Share/Network/FetchModule';
import useWindowSize from '../../Share/Normal/useWindowSize';
import SessionStorage from '../../Share/Network/SessionStorage';

import DeleteImg from '../../../image/BlackDeleteButton.png';
import TossLogoImg from '../../../image/TossLogo.png';
// import ZoneLogo from '../../Image/ZoneLogo.png';
// import NPayLogo from '../../Image/NPayImage.png';
// import TossLogo from '../../Image/TossImage.png';

function SubscribeModal(props) {
  const { name, validity, price, merchantUid, setIsClicked } = props;
  
  // 테스트 클라이언트 키 //
  // const clientKey = 'test_ck_dP9BRQmyarYAYvaBWd9rJ07KzLNk';
  // 라이브 클라이언트 키 //
  const clientKey = 'live_ck_4vZnjEJeQVxmOL22lRqrPmOoBN0k';

  const size = useWindowSize();
  const [scroll, setScroll] = useState(0);
  const [windowSize, setWindowSize] = useState(size);

  const [clickButton, setClickButton] = useState('jtnet');


  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  useEffect(() => {
    const jquery = document.createElement('script');
    jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";

    const iamport = document.createElement('script');
    iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.2.0.js";

    document.head.appendChild(jquery);
    document.head.appendChild(iamport);

    return () => {
      document.head.removeChild(jquery);
      document.head.removeChild(iamport);
    }
  }, []);

  useEffect(() => {
    // console.log('size.width : ' + size.width);
    // console.log('size.height : ' + size.height);
    setWindowSize(size);
    
  }, [size])

  function handleScroll(e) {
    // console.log(e.target.scrollTop);
    setScroll(e.target.scrollTop);
  }

  function onClickPayment(className) {
    (
      async () => {
        const fetchModule = new FetchModule();
        const response = await fetchModule.postData('sales/change/merchant/' + merchantUid + "/normal/" + price, 'POST');
        console.log(response.data);
        if (response.status === 200) {
          const { IMP } = window;
          IMP.init('imp22047599');

          setIsClicked(true);

          // Payment Data
          const data = {
            pg: className === 'toss' ? 'tosspay' : 'jtnet',
            pay_method: 'card',
            name: name,
            amount: price,
            // amount: '100',
            merchant_uid: merchantUid
          }

          IMP.request_pay(data, callback);
        }
        else if (response.status === 201) {
          // 데이터 없음
          alert("유효한 데이터가 아닙니다.");
          return;
        }
        else if (response.status === 202) {
          // 이미 처리된 데이터
          alert("이미 결제가 완료되었습니다.");
          return;
        }
        else if (response.status === 203) {
          // 현금결제가 같이있는 경우
          alert("복합결제( 현금결제가 포함된 경우 )는 정기결제 등록이 불가능합니다.");
          return;
        }
        else if (response.status === 204) {
          alert("정기결제는 31일 이하의 회원권에만 적용됩니다!");
          return;
        }
        else if (response.status === 205) {
          alert("이미 진행중인 정기결제 내역이 존재합니다.");
          return;
        }
        else {
          // 데이터 없음
          alert("유효한 데이터가 아닙니다.");
          return;
        }
      }
    )();
  }

  function onClickRegularPayment() {
    (
      async () => {
        const fetchModule = new FetchModule();
        const response = await fetchModule.postData('sales/change/merchant/' + merchantUid + "/regular/" + price, 'POST');
        console.log(response.data);
        if (response.status === 200) {
          const { IMP } = window;
          IMP.init('imp22047599');

          setIsClicked(true);
          // Payment Data
          const requestData = {
            pg: 'jtnet.tpaytest2m',
            pay_method: 'card',
            name: response.data[0].itemName,
            amount: price,
            // amount: '1000',
            merchant_uid: response.data[0].merchantUid,
            customer_uid: response.data[0].userId,
            // m_redirect_url: 'http://172.30.1.22:3001/complete/'
          }
          IMP.request_pay(requestData, callback);
        }
        else if (response.status === 201) {
          // 데이터 없음
          alert("유효한 데이터가 아닙니다.");
          return;
        }
        else if (response.status === 202) {
          // 이미 처리된 데이터
          alert("이미 결제가 완료되었습니다.");
          return;
        }
        else if (response.status === 203) {
          // 현금결제가 같이있는 경우
          alert("복합결제( 현금결제가 포함된 경우 )는 정기결제 등록이 불가능합니다.");
          return;
        }
        else if (response.status === 204) {
          alert("정기결제는 31일 이하의 회원권에만 적용됩니다!");
          return;
        }
        else if (response.status === 205) {
          alert("이미 진행중인 정기결제 내역이 존재합니다.");
          return;
        }
        else {
          // 데이터 없음
          alert("유효한 데이터가 아닙니다.");
          return;
        }
      }
    )();
  }

  function callback(response) {
    const { success, error_msg, imp_uid, merchant_uid, pay_method, paid_amount, status } = response;

    if (success) {
      console.log('error_msg : ' + error_msg);
      console.log('imp_uid : ' + imp_uid);
      console.log('merchant_uid : ' + merchant_uid);
      console.log('pay_method : ' + pay_method);
      console.log('paid_amount : ' + paid_amount);
      console.log('status : ' + status);

      var requestData = {
        imp_uid: imp_uid,
        merchant_uid: merchant_uid
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/effectiveness', 'POST', requestData);

          // console.log(responseData);
          if (responseData.status === 200) {
            // onRegist(merchant_uid);
            alert("서비스 결제를 완료되었습니다!");
            // cancel();
          }
          else {
            alert("서비스 결제를 실패하였습니다!");
            // cancel();
          }
        }
      )();
    }
    else {
      console.log('error_msg : ' + error_msg);
      console.log('imp_uid : ' + imp_uid);
      console.log('merchant_uid : ' + merchant_uid);
      console.log('pay_method : ' + pay_method);
      console.log('paid_amount : ' + paid_amount);
      console.log('status : ' + status);
    }

    setIsClicked(false);
  }

  async function onClickRegularPaymentTest() {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('zone_subscribe/customer', 'GET');

        // console.log(responseData);
        if (responseData.status === 200) {
          const tossPayments = await loadTossPayments(clientKey);
          // customerKey
          tossPayments.requestBillingAuth("카드", {
            // 결제 정보 파라미터
            customerKey: responseData.data[0].customerUid,
            amount: '77000',
            orderId: responseData.data[0].nextMerchantUid,
            successUrl: 'https://m.link-zone.io/payment/zone/subscribe/success',
            failUrl: 'https://m.link-zone.io/payment/zone/subscribe/fail',
          })
        }
        else if (responseData.status === 205){
          alert("이미 진행중인 결제건이 존재합니다!");
        }
      }
    )();
  }


  return (
    <TopLevelWrapper onScroll={handleScroll}>
      <ModalView>
        <InfoBox>
          <TitleWrapper>
            <SubTitleWrpper>
              <Title>결제하기</Title>
              <ExitButton src={DeleteImg} onClick={() => setIsClicked(false)} />
            </SubTitleWrpper>
            <SubTitle>합리적인 이용가격으로 링크존을 만나보세요!</SubTitle>
          </TitleWrapper>
          <LayerWrapper>
            <LayerTitle>상품 정보</LayerTitle>
            <ZoneView>
              <ViewLogo src='https://link-zone.org/api/account/logo' />
              <ViewTextWrapper>
                <ViewTextEng>Link Zone</ViewTextEng>
                <ViewTextKor>쉽고 간편한 피트니스 회원 관리 프로그램</ViewTextKor>
                <ViewTextKor>(28일)</ViewTextKor>
              </ViewTextWrapper>
            </ZoneView>
          </LayerWrapper>
          {/* <LayerWrapper>
            <LayerTitle>결제 방식</LayerTitle>
            <PaymentMethodWrapper>
              <PaymentMethodLayerWrapper>
                <PaymentMethod isClicked={clickButton === 'jtnet'} onClick={() => setClickButton('jtnet')}>정기 결제</PaymentMethod>
                <PaymentMethod isClicked={clickButton === 'toss'} img={TossLogoImg} onClick={() => setClickButton('toss')}></PaymentMethod>
              </PaymentMethodLayerWrapper>
            </PaymentMethodWrapper>
          </LayerWrapper> */}
          <LayerWrapper>
            <LayerTitle>최종 결제 금액</LayerTitle>
            <PriceWrapper>
              <PriceLayerWrapper>
                <PriceText>상품 금액</PriceText>
                <PriceLine width={184} />
                <PriceText>77,000원</PriceText>
              </PriceLayerWrapper>
              <PriceLayerWrapper>
                <TotalPriceText color='#232323'>최종 금액</TotalPriceText>
                <PriceLine width={190} />
                <TotalPriceText color='#FF8B48'>77,000원</TotalPriceText>
              </PriceLayerWrapper>
            </PriceWrapper>
            <PaymentButton onClick={() => onClickRegularPaymentTest()}>77,000원 정기 구독하기</PaymentButton>
          </LayerWrapper>
        </InfoBox>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default SubscribeModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;
  
  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 160px;
  margin-bottom: 74px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
  // margin-left: 28px;

  // width: 100%;
  width: 322px;
`;

const SubTitleWrpper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SubTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;
`;

const ExitButton = styled.img`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  :hover {
    cursor: pointer;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 378px;
  // height: 828px;
  // height: 600px;
  height: 500px;

  margin-top: 38px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 322px;

  // margin-bottom: 24px;

  border-bottom: 1px solid #ECECEC;
`;

const LayerTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 24px 0 0 0;
`;

const ZoneView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 322px;
  height: 100px;

  gap: 16px;
  margin-top: 24px;
  margin-bottom: 24px;

  border-radius: 15px;
  background-color: #FF8B48;
`;

const ViewLogo = styled.img`
  width: 60px;
  height: 60px;
`;

const ViewTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ViewTextEng = styled.p`
  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const ViewTextKor = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  margin-top: 24px;
`;

const PriceLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const PriceText = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.red ? `#FF3131` : `#595959`};

  margin: 0 0 0 0;
`;

const PriceLine = styled.div`
  width: ${props => props.width}px;
  height: 1px;

  border: 1px dashed #ECECEC;
`;

const CouponButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 320px;
  height: 38px;

  border: 1px solid #FF8B48;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FF8B48;

  margin-top: 24px;
  margin-bottom: 24px;

  :hover {
    cursor: pointer;
  }
`;

const PaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const PaymentMethodLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 155px;
  height: 38px;

  gap: 8px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #ECECEC`};
  border-radius: 15px;

  background: url(${props => props.img}) no-repeat center center;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #777777;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
    color: #FF8B48;
  }
`;

const TotalPriceText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;

const PaymentButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 322px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF8B48;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin-top: 24px;
  margin-bottom: 24px;

  :hover {
    cursor: pointer;
  }
`;