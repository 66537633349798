import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

const OpenTimeInput = forwardRef((props, ref) => {
	const { className, type, defaultVal, column, width, setVal } = props;
	const [value, setValue] = useState(defaultVal);
	const [changed, setChanged] = useState(false);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		}
	}));

	
	function handleClick(e) {
	}

	function handleChange(e) {
    const regex = /^[0-9:\-\b]{0,11}$/;
    
		
		if (regex.test(e.target.value)) {
			if (e.target.value.length === 2){
				setValue(e.target.value.replace(/(\d{2})/, '$1:'));
				setVal(e.target.value.replace(/(\d{2})/, '$1:'));
      }
			else if (e.target.value.length === 5){
				setValue(e.target.value.replace(/(\d{2}):(\d{2})/, '$1:$2-'));
				setVal(e.target.value.replace(/(\d{2}):(\d{2})/, '$1:$2-'));
      }
			else if (e.target.value.length === 8) {
				setValue(e.target.value.replace(/(\d{2}):(\d{2})-(\d{2})/, '$1:$2-$3:'));
				setVal(e.target.value.replace(/(\d{2}):(\d{2})-(\d{2})/, '$1:$2-$3:'));
      }
			else {
				setValue(e.target.value);
				setVal(e.target.value);
      }
		}
	
		setChanged(true);
	}

	function handleKeyDown(e) {
		if (e.key === 'Backspace') {
			if (value.length === 3 || value.length === 6 || value.length === 9) {
				setValue(value.substring(0, value.length - 1));
				setVal(value.substring(0, value.length - 1));
			}
		}
	}

	function handleFocus(e) {
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return (
    <TopLevelWrapper>
      <Column>{column}</Column>
      <Input
        ref={inputRef}
        type={type}
        width={width}
        value={value}
        className={`${className} ${changed ? "changed" : ""}`}
        onClick={handleClick}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  )
});

export default OpenTimeInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const Column = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  } 
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

  margin-top: 8px;
  padding: 0 8px 0 8px;

  // width: 272px;
  width: ${props => props.width};
  height: 30px;

	outline: none;
  border: 1px solid #CBCBCB;
  border-radius: 8px;
	color: #000000;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 14px;
    
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;