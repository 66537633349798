import { React, useRef } from 'react';
import styled from 'styled-components';

const WaittingMemberInfo = (props) => {
  const { data } = props;

  const RegistrationRef = useRef();
  const ExitRef = useRef();

  function mouseOver() {
    RegistrationRef.current.style.color = `#FF8B48`;
    ExitRef.current.style.color = `#777777`;
  }

  function mouseLeave() {
    RegistrationRef.current.style.color = `#FFFFFF`;
    ExitRef.current.style.color = `#FFFFFF`;
  }
  
  return (
    <TopLevelWrapper onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      <Table>
        <Body>
          <Tr style={{ width: '68px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '39px' }}>
                <Text>홍길동</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '51px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '26px' }}>
                <Text>남성</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '97px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '70px' }}>
                <Text>1997.04.21</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '115px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '86px' }}>
                <Text>01055192354</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '176px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '148px' }}>
                <Text>안산 보스짐 엔터테인먼트</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '158px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '129px' }}>
                <Text>필라테스 무제한 3개월</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '221px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '70x' }}>
                <Text>1997.04.21</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '46px' }}>
            <Td>
              <ExitButton ref={ExitRef} style={{ minWidth: '26px'}}>거절</ExitButton>
            </Td>
          </Tr>
          <Tr style={{ width: '46px' }}>
            <Td>
              <RegistrationButton ref={RegistrationRef} style={{ minWidth: '26px'}}>등록</RegistrationButton>
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

export default WaittingMemberInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-around;
  // justify-content: center;
  align-items: center;

  width: 1018px;
  height: 50px;

  background: #FFFFFF;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const RegistrationButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Bold;
  }
`;

const ExitButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;