import { React, useRef, useState } from 'react';
import styled from 'styled-components';

import FetchModule from '../../../Share/Network/FetchModule';
import CustomAlert from '../../../Share/Normal/CustomAlert';

const LockerHistoryInfoView = (props) => {
  const { data, isOver } = props;
  
  const [isViewCustomAlert, setIsViewCustomAlert] = useState(false);

  const [text, setText] = useState('');

  const ButtonRef = useRef();

  function mouseOver() {
    ButtonRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    ButtonRef.current.style.opacity = 0;
  }

  // 문자발송 버튼 클릭시
  function onClickSendMessageButton() {
    if (data.status === '사용 종료') {
      setText('이미 사용을 종료하였습니다!');
      setIsViewCustomAlert(true);
      return;
    }

    var text = data.userName + '님!, 지금까지 이용하셨던 락커 ' + data.lockerName + '이(가) 만료되었어요!\n빠른 결제로 Link Coach와 함께 더 건강한 하루를 만들어보아요! :)';

    var requestData = {
      userId: data.linkCoachId,
      data: text,
      location: '',
      jsonData: '',
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfCoach('push_notification/send', 'POST', requestData);

        if (responseData.status === 200) {
          setText('푸시알림을 전송하였습니다!');
          setIsViewCustomAlert(true);
        }
        else if (responseData.status === 204) {
          setText('Link Coach를 탈퇴한 회원입니다!');
          setIsViewCustomAlert(true);
        }
      }
    )();
  }

  return (
    <TopLevelWrapper onMouseOver={mouseOver} onMouseLeave={mouseLeave} isOver={isOver}>
      { isViewCustomAlert && <CustomAlert isOver={isOver} text={text} closeFn={() => setIsViewCustomAlert(false)}/> }
      {
        isOver ?
          <Table>
            <Body isOver={isOver}>
              <Tr>
                <Td style={{ width: 'max(108px, 12vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(108px, 12vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.lockerName}</Text>
                  </TextWrapper>
                </Td>
                {/* <Td style={{ width: 'max(180px, 23vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(180px, 23vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.startDate.split('T')[0].replaceAll('-', '.').slice(2, 11) + ' - ' + data.endDate.split('T')[0].replaceAll('-', '.').slice(2, 11)}</Text>
                  </TextWrapper>
                </Td> */}
                <Td style={{ width: 'max(210px, 27.34375vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(210px, 27.34375vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.startDate.split('T')[0].replaceAll('-', '.').slice(2, 11) + ' - ' + data.endDate.split('T')[0].replaceAll('-', '.').slice(2, 11)}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(120px, 15.625vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(120px, 15.625vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.status}</Text>
                  </TextWrapper>
                </Td>
                <Td /*style={{ width: 'max(100px, 30vw)' }}*/>
                  <TextWrapper /*style={{ maxWidth: 'max(62px, 8.072916666666667vw)' }} */isOver={isOver}>
                    <Text isOver={isOver}>{data.approvalDate.split('T')[0].replaceAll('-', '.').slice(2, 11)}</Text>
                  </TextWrapper>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Button ref={ButtonRef} isOver={isOver} onClick={onClickSendMessageButton}>문자발송</Button>
                </Td>
              </Tr>
            </Body>
          </Table>
          :
          <Table>
            <Body isOver={isOver}>
              <Tr>
                <Td style={{ width: 'max(56px, 16.5vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(56px, 16.5vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.lockerName}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(90px, 28.125vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(90px, 28.125vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.startDate.split('T')[0].replaceAll('-', '.').slice(2, 11) + ' - ' + data.endDate.split('T')[0].replaceAll('-', '.').slice(2, 11)}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(40px, 12vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(40px, 12vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.status}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(36px, 8vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(36px, 8vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.approvalDate.split('T')[0].replaceAll('-', '.').slice(2, 11)}</Text>
                  </TextWrapper>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Button ref={ButtonRef} isOver={isOver} onClick={onClickSendMessageButton}>문자발송</Button>
                </Td>
              </Tr>
            </Body>
          </Table>
      }
    </TopLevelWrapper>
  )
};

export default LockerHistoryInfoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: ${props => props.isOver ? `max(690px, 89.84375vw)` : `max(280px, 87.5vw)`};
  height: ${props => props.isOver ? `50px` : `17px`};

  background: #FFFFFF;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: center;
    
  margin-left: ${props => props.isOver ? `max(28px, 3.645833333333333vw)` : `max(10px, 3.125vw)`};

  width: ${props => props.isOver ? `max(640px, 83.33333333333333vw)` : `max(262px, 81.875vw)`};
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  padding: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: ${props => props.isOver ? `20px` : `15px`};
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;

  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};
`;

const Button = styled.p`
  opacity: 0;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;