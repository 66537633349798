import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

const FirstWaittingMemberInfo = (props) => {
  const { isOver, data, index, isIgnore, onClickedAgree, onClickedIgnore } = props;

  const [gender, setGender] = useState('');
  const [birth, setBirth] = useState('');
  const [memberShip, setMemberShip] = useState('-');

  const RegistrationRef = useRef();
  const ExitRef = useRef();

  useEffect(() => {
    var gender = '';
    var birth = '';

    if (data.birthDay[7] === '1') {
      gender = '남성';
      birth = "19" + data.birthDay[0] + data.birthDay[1] + '.' + data.birthDay[2] + data.birthDay[3] + '.' + data.birthDay[4] + data.birthDay[5];
    }
    else if (data.birthDay[7] === '2') {
      gender = '여성';
      birth = "19" + data.birthDay[0] + data.birthDay[1] + '.' + data.birthDay[2] + data.birthDay[3] + '.' + data.birthDay[4] + data.birthDay[5];
    }
    else if (data.birthDay[7] === '3') {
      gender = '남성';
      birth = "20" + data.birthDay[0] + data.birthDay[1] + '.' + data.birthDay[2] + data.birthDay[3] + '.' + data.birthDay[4] + data.birthDay[5];
    }
    else if (data.birthDay[7] === '4') {
      gender = '여성';
      birth = "20" + data.birthDay[0] + data.birthDay[1] + '.' + data.birthDay[2] + data.birthDay[3] + '.' + data.birthDay[4] + data.birthDay[5];
    }

    if (data.memberShip === undefined) setMemberShip('-');

    setGender(gender);
    setBirth(birth);
  }, [data])

  function mouseOver() {
    RegistrationRef.current.style.color = `#FF8B48`;
    ExitRef.current.style.color = `#777777`;
  }

  function mouseLeave() {
    RegistrationRef.current.style.color = `#FFFFFF`;
    ExitRef.current.style.color = `#FFFFFF`;
  }
  
  return (
    <TopLevelWrapper isOver={isOver} onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      {
        isOver ?
          <Table>
            <Body isOver={isOver}>
              <Tr>
                <Td style={{ width: 'max(67px, 7.7vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(67px, 7.7vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.userName}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(51px, 6vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(54px, 6vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{gender}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(96px, 10.5vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(96px, 10.5vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{birth}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(110px, 12vw)' }}>
                  <TextWrapper style={{ maxWidth: '86px' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.phone}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(160px, 20.83333333333333vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(160px, 20.83333333333333vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.storeName}</Text>
                  </TextWrapper>
                </Td>
              </Tr>
              <Tr>
                <Td style={{ width: '56px' }}>
                  <ExitButton ref={ExitRef} style={{ minWidth: '26px' }} isOver={isOver} onClick={() => onClickedIgnore(data.userId, index, isIgnore)}>{isIgnore ? `영구삭제` : `거절`}</ExitButton>
                </Td>
                <Td style={{ width: '56px' }}>
                  <RegistrationButton ref={RegistrationRef} style={{ minWidth: '26px' }} isOver={isOver} onClick={() => onClickedAgree(data.userId, index)}>{isIgnore ? `재등록` : `등록`}</RegistrationButton>
                </Td>
              </Tr>
            </Body>
          </Table>
          :
          <Table>
            <Body isOver={isOver}>
              <Tr>
                <Td style={{ width: 'max(39px, 12vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(39px, 12vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>홍길동</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(75px, 22.2vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(75px, 22.2vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>01055192354</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(90px, 20.83333333333333vw)' }} isOver={isOver}>
                  <TextWrapper style={{ maxWidth: 'max(90px, 20.83333333333333vw)' }}>
                    <Text isOver={isOver}>gudwls2354</Text>
                  </TextWrapper>
                </Td>
              </Tr>
              <Tr>
                <Td style={{ width: '27px' }}>
                  <ExitButton ref={ExitRef} style={{ minWidth: '27px' }} isOver={isOver}>{isIgnore ? `영구삭제` : `거절`}</ExitButton>
                </Td>
                <Td style={{ width: '27px' }}>
                  <RegistrationButton ref={RegistrationRef} style={{ minWidth: '27px' }} isOver={isOver}>{isIgnore ? `재등록` : `등록`}</RegistrationButton>
                </Td>
              </Tr>
            </Body>
          </Table>
      }
    </TopLevelWrapper>
  )
};

export default FirstWaittingMemberInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  margin-top: ${props => props.isOver ? `8px` : `4px`};

  width: ${props => props.isOver ? `max(690px, 89.84375vw)` : `max(281px, 87.8125vw)`};
  height: ${props => props.isOver ? `50px` : `17px`};

  background: #FFFFFF;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: center;
  
  margin-left: ${props => props.isOver ? `max(28px, 3.645833333333333vw)` : `max(10px, 3.125vw)`};

  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(263px, 82.1875vw)`};
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: ${props => props.isOver ? `20px` : `15px`};
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;

  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};
`;

const RegistrationButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Bold;
  }
`;

const ExitButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FFFFFF;

  margin: 0 0 0 0;
  
  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;