import { React } from 'react';
import styled from 'styled-components';

import PromotionModel from '../../Model/Individual/RulesModel/PromotionModel';

function PromotionPage() {
  
  return (
    <Container>
      <PromotionModel/>
    </Container>
  )
}

export default PromotionPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100vw;
`;