import { React, forwardRef, useState, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

const RepeatSelect = forwardRef((props, ref) => {
  const { isOver } = props;

	const [isClicked, setIsClicked] = useState(false);
	const [defaultVal, setDefaultVal] = useState({ value: '선택' });

	const [sunday, setSunday] = useState(false);
	const [monday, setMonday] = useState(false);
	const [tuesday, setTuesday] = useState(false);
	const [wednesday, setWednesday] = useState(false);
	const [thursday, setThursday] = useState(false);
	const [friday, setFriday] = useState(false);
	const [saturday, setSaturday] = useState(false);

	const SelectRef = useRef()

	const options = [
		{ value: '1주마다' },
		{ value: '2주마다' },
		{ value: '3주마다' },
		{ value: '4주마다' },
		{ value: '없음' },
	];

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			if (val === 'All') {
				setSunday(true);
				setMonday(true);
				setTuesday(true);
				setWednesday(true);
				setThursday(true);
				setFriday(true);
				setSaturday(true);
			}
			else {
				if (val.includes('Sun')) setSunday(true);
				if (val.includes('Mon')) setMonday(true);
				if (val.includes('Tue')) setTuesday(true);
				if (val.includes('Wed')) setWednesday(true);
				if (val.includes('Thu')) setThursday(true);
				if (val.includes('Fri')) setFriday(true);
				if (val.includes('Sat')) setSaturday(true);
			}
		},
		getValue: () => {
			// return defaultVal.value;
			var value = '';

			if (sunday && monday && tuesday && wednesday && thursday && friday && saturday) return 'All';

			if (sunday) value += 'Sun,';
			if (monday) value += 'Mon,';
			if (tuesday) value += 'Tue,';
			if (wednesday) value += 'Wed,';
			if (thursday) value += 'Thu,';
			if (friday) value += 'Fri,';
			if (saturday) value += 'Sat,';

			return value;
		}
	}))

	return (
		<TopLevelWrapper isOver={isOver}>
			<DailyButton isClicked={sunday} onClick={() => setSunday(!sunday)}>일</DailyButton>
			<DailyButton isClicked={monday} onClick={() => setMonday(!monday)}>월</DailyButton>
			<DailyButton isClicked={tuesday} onClick={() => setTuesday(!tuesday)}>화</DailyButton>
			<DailyButton isClicked={wednesday} onClick={() => setWednesday(!wednesday)}>수</DailyButton>
			<DailyButton isClicked={thursday} onClick={() => setThursday(!thursday)}>목</DailyButton>
			<DailyButton isClicked={friday} onClick={() => setFriday(!friday)}>금</DailyButton>
			<DailyButton isClicked={saturday} onClick={() => setSaturday(!saturday)}>토</DailyButton>
		</TopLevelWrapper>
	)
})

export default RepeatSelect;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: row;

  margin-top: 8px;

  width: ${props => props.isOver ? `282px` : `234px`};
  height: 32px;
	
	gap: 8px;
`;

const DailyButton = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	border: 1px solid #DFDFDF;
	border-radius: 8px;

	background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

	font-family: NotoSansKR-Regular;
	font-size: 14px;

	color: ${props => props.isClicked ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
		border: 1px solid #FF8B48;
		color: #FFFFFF;
		background-color: #FF8B48
	}
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;

	// justify-content: center;
	align-items: flex-start;

	width: ${props => props.isOver ? `280px` : `232px`};
	height: 30px;

	border: 1px solid #CBCBCB;
	border-radius: 8px;
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);

  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	margin: 6px 0 6px 8px;
`;