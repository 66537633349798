import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import CalenderModal from '../NoteModel/CalenderModal';
import FullMemberListView from './FullMemberListView';
import FullExerciseBox from './FullExerciseBox';
import FullNoticeView from './FullNoticeView';
import FullStopWatchView from './FullStopWatchView';
import BoardSettingModeSelect from '../../../Component/BoardComponent/BoardSettingModeSelect';

import FetchModule from '../../Share/Network/FetchModule';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import CalenderBasic from '../../../image/calendarB.png'//캘린더 기본
import CalenderHover from '../../../image/calendarH.png'

function FullBoardView() {

  const history = useHistory();

  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment());
  const [dateList, setDateList] = useState([]);
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [calenderClick, setCalenderClick] = useState(false);
  const [lessonData, setLessonData] = useState([]);
  const [lessonId, setLessonId] = useState(-1);
  const [rendering, setRendering] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [mode, setMode] = useState('수업');

  const [useData, setUseData] = useState([{
    id: 0,
    // storeId: window.sessionStorage.getItem('StoreId'),
    storeId: window.localStorage.getItem('StoreId'),
    classDate: moment(),
    title: '작성이 필요합니다.',
    warmupList: [],
    exerciseList: [],
    notice: {text: ''}
  }]);

  const CalenderButtonRef = useRef();
  const CalenderRef = useRef();

  const TopLevelRef = useRef();

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  useEffect(() => {
    (
      async () => {
        const fetchModule = new FetchModule();
        const data = await fetchModule.getData('lesson/', 'GET');
        
        if (data.status === 200) {
					setLessonData(data.data);
          if (data.data.length !== 0) setLessonId(data.data[0].id);
          else setLessonId(-1);
				}
        else if (data.status === 401) history.push('/');
        else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }, [history])
  
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      } 
    }
    setClickDate(date.clone().format('YYYY.MM.DD'));
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek]) 

  useEffect(() => {
    if (lessonId === -1) return;

    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.getIndividualData('workout/' 
        + date.clone().format('MM') 
        + '/' 
        + lessonId, 'GET');
        
        if (responseData.status === 200) {
          setData(responseData.data);
        }
        else if (responseData.status === 204) setData([]);
        else if (responseData.status === 401) history.push('/');
        else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }, [history, date, rendering, lessonId])

  useEffect(() => {
    if (dateList.length === 0) return;

    var newData = data.slice();

    if (newData.length === 0) { // 데이터가 없으면 1개 넣고 시작
      var tmpData = {
        id: 0,
        // storeId: window.sessionStorage.getItem('StoreId'),
        storeId: window.localStorage.getItem('StoreId'),
        classDate: dateList[0],
        title: '작성이 필요합니다.',
        warmupList: [],
        exerciseList: [],
        notice: {text: ''}
      }
    }

    for (var i = 0; i < dateList.length; i++) {
      var isNone = true;

      for (var j = 0; j < newData.length; j++) {
        if (dateList[i].format('MM.DD') === moment(newData[j].classDate).format('MM.DD')) {
          isNone = false;
          newData[j].classDate = moment(newData[j].classDate);

          for (var k = 0; k < newData[j].warmupList.length; k++) {
            newData[j].warmupList[k].linkList = newData[j].warmupList[k].link.split('/');
          }
          for (k = 0;  k < newData[j].exerciseList.length; k++) {
            newData[j].exerciseList[k].linkList = newData[j].exerciseList[k].link.split('/');
          }

          break;
        }
      }
      
      if (isNone) {
        tmpData = {
          id: 0,
          // storeId: parseInt(window.sessionStorage.getItem('StoreId')),
          storeId: parseInt(window.localStorage.getItem('StoreId')),
          classDate: dateList[i],
          title: '작성이 필요합니다.',
          warmupList: [],
          exerciseList: [],
          notice: {text: ''}
        }
        newData.push(tmpData);
      }
    }
    
		newData.sort(function (a, b) {
			return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
		})
    console.log(newData);

    setUseData(newData);
  }, [dateList, data])

  function onMouseOver() {
    CalenderButtonRef.current.style.border = `1px solid #777777`;
    CalenderButtonRef.current.style.background = `url(${CalenderHover}) no-repeat center center`;
  }

  function onMouseLeave() {
    CalenderButtonRef.current.style.border = `1px solid #DFDFDF`;
    CalenderButtonRef.current.style.background = `url(${CalenderBasic}) no-repeat center center`;
  }
  
  function dateClick(value) {
    setClickDate(value);

    // ScheduleRef.current.setValue(value);
    if (calenderClick) CalenderRef.current.setValue(value);
  }

  function changFullScreenMode() {

    if (fullScreen) {
      const docu = document;
      if (docu.exitFullscreen) docu.exitFullscreen();
      else if (docu.mozCancelFullScreen) docu.mozCancelFullScreen();
      else if (docu.webkitExitFullscreen) docu.webkitExitFullscreen();
      else if (docu.msExitFullscreen) docu.msExitFullscreen();
    }
    else {
      const docuElem = document.documentElement;

      if (docuElem.requestFullscreen) docuElem.requestFullscreen();
      else if (docuElem.webkitRequestFullscreen) docuElem.webkitRequestFullscreen();
      else if (docuElem.mozRequestFullScreen) docuElem.mozRequestFullScreen();
      else if (docuElem.msRequestFullScreen) docuElem.msRequestFullScreen();
    }
    setFullScreen(!fullScreen);
  }

  function changeSelecValue(value) {
    setMode(value);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {calenderClick && <CalenderModal ref={CalenderRef} clickDate={clickDate} date={date} dateClickFn={dateClick} exitFn={setCalenderClick}/>}
      <TopButtonWrapper>
        <LeftWrapper>
          <BoardSettingModeSelect changeValueFn={changeSelecValue}/>
          <DateButtonWrapper>
            <ArrowButton img={LeftArrow} onClick={() => setDate(date.clone().subtract(1, 'day'))} />
            <DateText>{date.clone().format('YYYY년 MM월 DD일')}</DateText>
            <ArrowButton img={RightArrow} onClick={() => setDate(date.clone().add(1, 'day'))} />
          </DateButtonWrapper>
          <TodayButton onClick={() => setDate(moment())}>오늘</TodayButton>
          <CalenderButton ref={CalenderButtonRef} img={CalenderBasic} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={() => setCalenderClick(!calenderClick)} />
        </LeftWrapper>
        <RightWrapper>
          <Button onClick={changFullScreenMode}>{fullScreen ? `일반화면 전환` : `전체화면 전환`}</Button>
          <Button onClick={() => window.close()}>페이지 닫기</Button>
        </RightWrapper>
      </TopButtonWrapper>
      <MainView>
        {
          mode === '수업' ?
            <ComponentWrapper style={{ marginBottom: `96px` }}>
              <FullMemberListView date={date} lessonId={lessonId}/>
              <FullExerciseBox useData={useData} clickDate={clickDate} lessonId={lessonId} setLessonId={setLessonId} rendering={() => setRendering(!rendering)} />
              <FullNoticeView useData={useData} clickDate={clickDate} rendering={() => setRendering(!rendering)} />
            </ComponentWrapper>
            :
            <ComponentWrapper style={{ marginBottom: `96px` }}>
              <FullExerciseBox useData={useData} clickDate={clickDate} lessonId={lessonId} setLessonId={setLessonId} rendering={() => setRendering(!rendering)} />
              <FullStopWatchView />
            </ComponentWrapper>
        }
      </MainView>
    </TopLevelWrapper>
  )
}

export default FullBoardView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // width: 100vw;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 100%;

  // margin-top: 38px;
  margin-top: 1.979166666666667vw;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 8px;
  gap: 0.4166666666666667vw;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 16px;
  gap: 0.8333333333333333vw;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 205px;
  width: 10.67708333333333vw;
  // height: 38px;
  height: 1.979166666666667vw;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  // gap: 20px;
  gap: 1.041666666666667vw;
`;

const ArrowButton = styled.div`
  display: flex;
  flex-direction: row;

  // width: 22px;
  width: 1.145833333333333vw;
  // height: 22px;
  height: 1.145833333333333vw;

  background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const TodayButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 52px;
  width: 2.708333333333333vw;
  // height: 38px;
  height: 1.979166666666667vw;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const CalenderButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 38px;
  width: 1.979166666666667vw;
  // height: 38px;
  height: 1.979166666666667vw;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #595959;

  margin: 0 0 0 0;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 127px;
  width: 6.614583333333333vw;
  // height: 38px;
  height: 1.979166666666667vw;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const MainView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // gap: 30px;
  gap: 1.5625vw;
  // margin-top: 16px;
  margin-top: 0.8333333333333333vw;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  // gap: 30px;
  gap: 1.5625vw;
`;