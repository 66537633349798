import { React, useRef, useEffect } from 'react';
import styled from 'styled-components';

import StopWatchModifyImg from '../../image/StopWatchModify.png';
import StopWatchDeleteImg from '../../image/StopWatchDelete.png';

const StopWatchSetting = (props) => {
  const { data, isClicked, onClick, addClick, modifyClick, deleteClick } = props;

  useEffect(() => {
    
    if (isClicked) {
      TopLevelRef.current.style.border = `1px solid #FF8B48`;
      TitleRef.current.style.fontFamily = `NotoSansKR-Medium`;
      TitleRef.current.style.color = `#595959`;
      if (data.id !== -1) ButtonRef.current.style.display = `flex`;
    }
    else {
      TopLevelRef.current.style.border = `1px solid #DFDFDF`;
      TitleRef.current.style.fontFamily = `NotoSansKR-Regular`;
      TitleRef.current.style.color = `#CBCBCB`;
      ButtonRef.current.style.display = `none`;
    }
  }, [isClicked])
  
  const TopLevelRef = useRef();
  const TitleRef = useRef();
  const ButtonRef = useRef();

  function onMouseOver() {

    if (isClicked) return;

    TopLevelRef.current.style.border = `1px solid #FF8B48`;
    TitleRef.current.style.fontFamily = `NotoSansKR-Medium`;
    TitleRef.current.style.color = `#595959`;
    if (data.id !== -1) ButtonRef.current.style.display = `flex`;
  }

  function onMouseLeave() {
    
    if (isClicked) return;

    TopLevelRef.current.style.border = `1px solid #DFDFDF`;
    TitleRef.current.style.fontFamily = `NotoSansKR-Regular`;
    TitleRef.current.style.color = `#CBCBCB`;
    ButtonRef.current.style.display = `none`;
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} isClicked={isClicked} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={onClick}>
      <TextWrapper>
        <Text ref={TitleRef} isClicked={isClicked}>{data.title}</Text>
      </TextWrapper>
      <ButtonWrapper ref={ButtonRef} isView={data.id !== -1} isClicked={isClicked}>
        {/* <Button src={StopWatchModifyImg}/> */}
        <Button src={StopWatchDeleteImg} onClick={deleteClick}/>
      </ButtonWrapper>
      <AddButton isView={data.id === -1} onClick={addClick}>추가</AddButton>
    </TopLevelWrapper>
  )
}

export default StopWatchSetting;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0 10px 0 10px;
  margin: 0 0 0 0;

  width: 198px;
  height: 22px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 160px;
  height: 17px;
  // background-color: red;

  overflow: hidden;
`;

const Text = styled.p`
  font-family:  ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 12px;

  margin: 0 0 0 0;

  color:  ${props => props.isClicked ? `#595959` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;
`;

const ButtonWrapper = styled.div`
  // display: ${props => props.isView ? `None` : `flex`};
  display:  ${props => props.isClicked ? props.isView ? `flex` : `none` : `none`};
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;

  transition: all 0.3s ease-in-out;
`;

const Button = styled.img`
  width: 16px;
  height: 16px;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const AddButton = styled.p`
  display: ${props => props.isView ? `flex` : `None`};
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #CBCBCB;
  
  :hover {
    cursor: pointer;
  }
`;