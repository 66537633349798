import { React, forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Model/Share/Network/FetchModule';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';
import AddImage from '../../image/CategoryAddImage.png';
import ModifyImage from '../../image/CategoryModifyButton.png';
import DeleteImage from '../../image/CategoryDeleteButton.png';

const ModalCategorySelectBox = forwardRef((props, ref) => {
	const { width, changeVal, selectClickFn } = props;

  const history = useHistory();

	const [isClicked, setIsClicked] = useState(false);
  const [options, setOptions] = useState([]);
	const [categoryId, setCategoryId] = useState('');
	const [defaultVal, setDefaultVal] = useState({ name: '전체' });

  const [rendering, setRendering] = useState(false);
  const [modifyIndex, setModifyIndex] = useState(-1);
  const [inputVal, setInputVal] = useState('');
  const [modifyVal, setModifyVal] = useState('');
  const [addCategory, setAddCategory] = useState(false);

  const TopLevelRef = useRef();
	const SelectRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setCategoryId(val);
		},
		getValue: () => {
			return defaultVal;
		},
		setIsClicked: () => {
			// if (defaultVal !== -1) return;
      if (isClicked) {
        SelectRef.current.style.border = '1px solid #CBCBCB';
        SelectRef.current.style.height = `30px`;
        SelectRef.current.style.overflow = 'hidden';
        setDefaultVal({name: '전체'});
        changeVal({name: '전체'});
        setIsClicked(false);
      }
		}
	}))

  useEffect(() => {
    setOptions([]);
    (
      async () => {
        const fetchModule = new FetchModule();
        const data = await fetchModule.getData('membership/category/', 'GET');
        
        if (data.status === 200) {
					var newData = data.data;
	
					newData.unshift({ name: '전체' });
					setOptions(newData);
				}
        else if (data.status === 401) history.push('/');
        else {};
      }
    )();
  }, [history, rendering])

  useEffect(() => {
		if (!isClicked) return;

    if (addCategory) {
      SelectRef.current.style.height = `${32 * (options.length + 2) + 8}px`; 
    }
    else {
      SelectRef.current.style.height = `${32 * (options.length + 1) + 8}px`; 
    }
  }, [addCategory, options])

  useEffect(() => {
    if (options.length === 0) return;

		if (options.findIndex(i => i.id === categoryId) === -1 ) setDefaultVal(options[0]);
		else setDefaultVal(options[options.findIndex(i => i.id === categoryId)]);
		// for (var i = 0; i < options.length; i++) {
		// 	if (options[i].id === categoryId) {
		// 		setDefaultVal(options[i]);
		// 	}
		// }
  }, [options, categoryId])
	
  useEffect(() => {
    if (modifyIndex !== -1) {
      setModifyVal(options[modifyIndex].name);
    }
  }, [modifyIndex, options])

  // useEffect(() => {
  //   document.addEventListener("mousedown", onClickOutSide);

  //   return () => {
  //     document.removeEventListener("mousedown", onClickOutSide);
  //   }
  // }, [])

  // function onClickOutSide(e) {
  //   if (TopLevelRef.current !== e.target) {
  //     console.log(e.target);
	// 		SelectRef.current.style.border = '1px solid #CBCBCB';
	// 		SelectRef.current.style.height = `30px`;
	// 		SelectRef.current.style.overflow = 'hidden';
	// 		setIsClicked(false);
  //   }
  // }

	function handleChange(e) {
		setInputVal(e.target.value);
	}

  function modifyHandleChange(e) {
		setModifyVal(e.target.value);
  }

	function onClick() {
		if (isClicked) {
			SelectRef.current.style.border = '1px solid #CBCBCB';
			SelectRef.current.style.height = `30px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = (32 * (options.length + 1 )) > 130 ? `130px` : ((32 * (options.length + 1)) + 'px');
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

  function deleteButtonClick(data) { 
    var id = data.id;

    (
      async () => {
        const fetchModule = new FetchModule();
        data = await fetchModule.deleteData('membership/category/delete/' + id, 'DELETE');

        if (data.status === 200) {
          setRendering(!rendering);
        }
        else if (data.status === 204) alert('해당 카테고리에 회원권이 존재합니다.\n회원권을 모두 삭제후 다시시도하세요');
        else if (data.status === 401) history.push('/');
        else alert('카테고리 삭제에 실패하였습니다.\n잠시후 다시시도해 주세요.');
      }
    )();
  }
	
  function addCompleteButtonClick(className) {

    if (className === 'Add') {
      const data = {
        // storeId: sessionStorage.getItem('StoreId'),
        storeId: window.localStorage.getItem('StoreId'),
        name: inputVal
      }

      const fetchModule = new FetchModule();
      (
        async () =>{ 
        const responseData = await fetchModule.postData('membership/category/', 'POST', data);
  
        if (responseData.status === 200) {
          setRendering(!rendering);
          setAddCategory(false);
        }
        else if (responseData.status === 401) history.push('/');
        else alert('카테고리 추가에 실패하였습니다.\n잠시후 다시시도해 주세요.');
  
      })();
    }
    else {
      const data = {
        // storeId: sessionStorage.getItem('StoreId'),
        storeId: window.localStorage.getItem('StoreId'),
        name: modifyVal,
        id: options[modifyIndex].id
      }

      const fetchModule = new FetchModule();
      (
        async () =>{ 
        const responseData = await fetchModule.postData('membership/category/modify', 'PATCH', data);
  
        if (responseData.status === 200) {
          setRendering(!rendering);
          setModifyIndex(-1);
        }
        else if (responseData.status === 401) history.push('/');
        else alert('카테고리 수정에 실패하였습니다.\n잠시후 다시시도해 주세요.');
  
      })();
    }
    
  }

	function optionalClick(data) {
		setDefaultVal(data);
    setAddCategory(false);
    setIsClicked(false);
    changeVal(data);
		onClick();
		selectClickFn();
	}

	return (
		<TopLevelWrapper ref={TopLevelRef} width={width}>
			<SelectBox
				ref={SelectRef} 
        width={width}
				isClicked={isClicked}
				buttonPath={isClicked ? SelectOnPath : SelectOffPath}>
				{
					isClicked ?
						options.map((data, index) =>
							index !== modifyIndex ?
								<OptionWrapper key={index}>
									<Option onClick={() => optionalClick(data)}>{data.name}</Option>
									{
										index !== 0 &&
										<CategoryButtonWrapper>
											<CategoryButton img={ModifyImage} onClick={() => setModifyIndex(index)} />
											<CategoryButton img={DeleteImage} onClick={() => deleteButtonClick(data)} />
										</CategoryButtonWrapper>
									}
								</OptionWrapper>
								:
								<InputWrapper>
									<CategoryInput placeholder='카테고리 이름을 입력해주세요' value={modifyVal} onChange={modifyHandleChange} />
									<ButtonWrapper>
										<AddExitButton onClick={() => setModifyIndex(-1)}>취소</AddExitButton>
										<AddCompleteButton onClick={() => addCompleteButtonClick('Modify')}>완료</AddCompleteButton>
									</ButtonWrapper>
								</InputWrapper>
						)
						:
						<OptionWrapper>
							<Option onClick={() => optionalClick(-1)}>
								{
									defaultVal.name
								}
							</Option>
						</OptionWrapper>
				}
				{
					addCategory ?
						<InputWrapper>
							<CategoryInput placeholder='카테고리 이름을 입력해주세요' onChange={handleChange} />
							<ButtonWrapper>
								<AddExitButton onClick={() => setAddCategory(false)}>취소</AddExitButton>
								<AddCompleteButton onClick={() => addCompleteButtonClick('Add')}>완료</AddCompleteButton>
							</ButtonWrapper>
						</InputWrapper>
						:
						null
				}
				<CategoryAddButton img={AddImage} onClick={() => setAddCategory(!addCategory)}>추가</CategoryAddButton>
			</SelectBox>
		</TopLevelWrapper>
	)
})

export default ModalCategorySelectBox;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	
	// width: 344px;
	// width: ${props => props.isModal ? `344px` : `452px`};
  width: ${props => props.width};
	height: 30px;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: flex-start;

	// width: 344px;
	// width: ${props => props.isModal ? `344px` : `452px`};
  width: ${props => props.width};
	height: 30px;

  z-index: ${props => props.isClicked ? 5 : 0 };

	border: 1px solid #CBCBCB;
	border-radius: 8px;
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	align-items: center;

	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

	margin: 6px 0 6px 8px;
	
	width: 100%;
	// background-color: red;
`;


const CategoryAddButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #DFDFDF;

  margin: 6px 0 0 0;
  padding: 0 0 0 27px;
  background: url(${props => props.img}) no-repeat 15%;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Medium;
    color: #FF8B48;
  }
`;

const CategoryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;

const CategoryButton = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  background: url(${props => props.img}) no-repeat center;
  
  :hover {
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

	width: 100%;

  margin: 5px 0 5px 6px;
  // gap: 170px;
`;

const CategoryInput = styled.input`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

	width: 100%;

  color: #595959;

  border: 0px;

  outline: none;

  ::placeholder {
    font-family: NotoSansKR-Medium;
    font-size: 14px;

    color: #DFDFDF;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

	// width: 100%;
	width: 100px;
  gap: 8px;
`;

const AddExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #DFDFDF;

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
  }
`;

const AddCompleteButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #5BD98A;

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
  }
`;