import { React, useState } from 'react';
import styled from 'styled-components';

const RangeTestInput = (props) => {
  const { defaultVal, changeFn } = props;

  const [value, setValue] = useState(defaultVal);
  const [isClickedInput, setIsClickedInput] = useState(false);

  function handleChange(e) {
    setValue(e.target.value);
    // changeFn(e.target.value);
  }

  function onMouseDown() {
    setIsClickedInput(true);
  }

  function handleMouseUp(e) {
    setIsClickedInput(false);
    changeFn(e.target.value);
  }

  return (
    <TopLevelWrapper>
      <Text>폰트 크기</Text>
      <InputWrapper>
        <SizeView isClicked={isClickedInput}>
          <SizeText>{value}%</SizeText>
        </SizeView>
        <Input 
          type='range'
          min={1}
          max={100}
          value={value || ''}
          onChange={handleChange}
          onMouseDown={onMouseDown}
          onMouseUp={handleMouseUp}/>
      </InputWrapper>
    </TopLevelWrapper>
  )
}

export default RangeTestInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const SizeView = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  opacity: ${props => props.isClicked ? 1 : 0};
  // z-index: ${props => props.isClicked ? 20 : -1};

  margin-top: -50px;

  width: 88px;
  height: 28px;

  border-radius: 20px;
  background-color: #FF8B48;

  transition: all 0.3s ease-in-out;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const SizeText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #FFFFFF;

  :hover {
    cursor: default;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #777777;
`;

const Input = styled.input`
  width: 88px;
  height: 8px;

  border-radius: 8px;
  background-color: #ECECEC;

  -webkit-appearance: none;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;

    width: 12px;
    height: 12px;
    border-radius: 12px;

    background-color: #FF8B48;

    :hover {
      cursor: pointer;
    }
  }
`;