import { forwardRef, React, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';

import AccountInput from '../../../Component/AccountComponent/AccountInput.js';
import LogoFileInput from '../../../Component/AccountComponent/LogoFileInput.js';
import KakaoAddressInput from '../../../Component/AccountComponent/KakaoAddressInput.js';

const SignUpInfo4 = forwardRef((props, ref) => {
	const { kakaoAddrClick, isOver } = props;

	const [addressClickCount, setAddressClickCount] = useState(0);
	const [logoClickCount, setLogoClickCount] = useState(0);

	const TopLevelRef = useRef();
	const AddressRef = useRef();
	const LogoRef = useRef();
	const AddressDetailRef = useRef();
	const AddressDetailPlanationRef = useRef();
	const AddressPlanationRef = useRef();
	const LogoPlanationRef = useRef();

	useImperativeHandle(ref, () => ({
		getAddress: () => {
			return AddressRef.current.getValue() + " " + AddressDetailRef.current.getValue();
		},
		getLogo: () => {
			return LogoRef.current.getImgBase64();
		},
		setAddressBold: () => {
			AddressRef.current.setDefaultPlaceholder('');
			AddressPlanationRef.current.style.color = '#FF3131';
			AddressPlanationRef.current.style.opacity = 1;
		},
		setLogoBold: () => {
			LogoRef.current.setBold();
			LogoPlanationRef.current.style.color = '#FF3131';
			LogoPlanationRef.current.style.opacity = 1;
		},
		setAddress: (val) => {
			AddressRef.current.setValue(val);
			setAddressClickCount(addressClickCount + 1);
		},
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
		setLocation: (val) => {
			TopLevelRef.current.style.transform = `translateX(${val}%)`;
		},
		reset: () => {
			// AddressRef.current.reset('');
			// AddressPlanationRef.current.style.opacity = 0;
			// LogoRef.current.reset();
			// LogoPlanationRef.current.style.opacity = 0;
			// setAddressClickCount(0);
			// setLogoClickCount(0);
		}
	}));

	// useEffect(() => {
	// 	if (addressClickCount === 0 && logoClickCount === 0) return;

	// 	if (AddressRef.current.getValue() === undefined && addressClickCount > 0) {
	// 		AddressPlanationRef.current.style.color = '#FF3131';
	// 		AddressPlanationRef.current.style.opacity = 1;
	// 	}
	// 	else {
	// 		AddressPlanationRef.current.style.opacity = 0;
	// 	}

	// 	if (LogoRef.current.getValue() === '' && logoClickCount > 0) {
	// 		LogoPlanationRef.current.style.color = '#FF3131';
	// 		LogoPlanationRef.current.style.opacity = 1;
	// 	}
	// 	else {
	// 		LogoPlanationRef.current.style.opacity = 0;
	// 	}
	// }, [addressClickCount, logoClickCount])

	function clickCountFn(className, clickCount) {
		if (className === 'AddressInput') {
			setAddressClickCount(clickCount);
		}
		else if (className === 'LogoInput') {
			setLogoClickCount(clickCount);
		}
	}

	return (
		<TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
			<InputInfo isOver={isOver}>센터 주소</InputInfo>
			<KakaoAddressInput ref={AddressRef} className='AddressInput' placeholder='' type='text' clickCountFn={clickCountFn} clicked={kakaoAddrClick} />
			{/* <AccountExplanation ref={AddressPlanationRef}>필수항목을 입력해주세요</AccountExplanation> */}
			<InputInfo>상세 주소</InputInfo>
			<AccountInput ref={AddressDetailRef} className='AddressDetailInput' placeholder='' type='text' clickCountFn={clickCountFn}/>
			{/* <AccountExplanation ref={AddressDetailPlanationRef}>필수항목을 입력해주세요</AccountExplanation> */}
			<InputInfo isOver={isOver}>센터 로고</InputInfo>
			<LogoFileInput ref={LogoRef} className='LogoInput' clickCountFn={clickCountFn} isOver={isOver}/>
			{/* <AccountExplanation ref={LogoPlanationRef}>필수항목을 입력해주세요</AccountExplanation> */}
		</TopLevelWrapper>
	)
})

export default SignUpInfo4;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;

	flex-direction: column; 
	justify-content: flex-start;

	// width: 369px;
	width: ${props => props.isOver ? `max(369px, 48.046875vw)` : `max(250px, 78.125vw)`};

	// margin-top: 140px;
	margin-top: ${props => props.isOver ? `108.5px` : `78px`};

	background-color: rgba(255, 255, 255, 1);
	
	opacity: 0;
	z-index: 1;
	transform: translateX(150%);

  transition: all 0.3s ease-in-out;
`;

const InputInfo = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
 
	// margin: ${props => props.isOver ? `31.5px 0 0 0` : `28px 0 0 0`};
	margin-top: 8px: 
	// margin-top: max(11.73px, 0.9895833333333333vw);
	// margin-right: 0px;
	// margin-bottom: 0px;
	// margin-left: 0px;

	cursor: default;
`;

const AccountExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;

	color: ${props => props.bold ? `#FF3131` : `#33C8FF`};

	margin: 4px 0 0 0;
	opacity: 0;
  transition: all 0.5s ease-in-out;
`;

