import { React } from 'react';
import styled from 'styled-components';

import OneOnOneView from '../../Model/Individual/NoteModel/OneOnOneView';

function OneOnOnePage () {

  return (
    <Container>
      <OneOnOneView/>
    </Container>  
  )
}

export default OneOnOnePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;