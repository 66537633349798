import { React } from 'react';
import styled from 'styled-components';

import NoticeCreateView from '../../Model/Individual/NoticeModel/NoticeCreateView';

function NoticeCreatePage() {

  return(
    <Container>
      <NoticeCreateView/>
    </Container>
  )
}

export default NoticeCreatePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  // width: 100%;
`;