import { React, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import CategorySelect from '../../../../Component/ClassManageComponent/CategorySelect.js';
import ClassSelect from '../../../../Component/ClassManageComponent/ClassSelect.js';
import CalenderInput from '../../../../Component/ClassManageComponent/CalenderInput.js';
import TimeInput from '../../../../Component/ClassManageComponent/TimeInput.js';
import CoachSelect from '../../../../Component/ClassManageComponent/CoachSelect.js';
import RepeatSelect from '../../../../Component/ClassManageComponent/RepeatSelect.js';

import FetchModule from '../../../Share/Network/FetchModule';

function ClassAddModal(props) {
  const { isOver, date, scheduleData, scheduleId, cancel } = props;

  const history = useHistory();

  const [isClicked, setIsClicked] = useState(false);
  const [nowCategoryId, setNowCategoryId] = useState(-1);
  
  const CategoryRef = useRef();
  const ClassRef = useRef();
  const StartCalenderRef = useRef();
  const TimeRef = useRef();
  const CoachSelectRef = useRef();
  const RepeatRef = useRef();
  const EndCalenderRef = useRef();

	useEffect(() => {
		document.body.style.cssText = `
            position: fixed; 
            top: 0px;
            left: 0px;
            overflow-y: hidden;
            width: 100vm;
            height: 100vm`
			;
		return () => {
			document.body.style.cssText = '';
			window.scrollTo(0, 0);
		};
	}, []);

  useEffect(() => {
    if (scheduleId === -1) return;
    console.log(scheduleId);
    console.log(scheduleData);

    for (var i = 0; i < scheduleData.length; i++) {
      if (scheduleData[i].scheduleId === scheduleId) {
        ClassRef.current.setValue({ id: scheduleData[i].lessonId, name: scheduleData[i].className });
        StartCalenderRef.current.setValue(scheduleData[i].classDate.split('T')[0]);
        TimeRef.current.setValue(scheduleData[i].classTime);
        RepeatRef.current.setValue(scheduleData[i].applyWeek)
        if (scheduleData[i].endDate === '9999-12-31T00:00:00') setIsClicked(true);
        else EndCalenderRef.current.setValue(scheduleData[i].endDate.split('T')[0]);
      }
    }

  }, [scheduleData, scheduleId])

  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  function onClickSaveButton() {
    // var storeId = window.sessionStorage.getItem('StoreId');
    var storeId = window.localStorage.getItem('StoreId');
    var lessonId = ClassRef.current.getValue().id;
    // var classTime = ClassRef.current.getValue();
    var classDate = StartCalenderRef.current.getValue();
    var classTime = TimeRef.current.getValue();
    var applyWeek = RepeatRef.current.getValue();
    var endDate = isClicked ? '9999-12-31' : EndCalenderRef.current.getValue();

		if (lessonId === undefined) {
			alert('수업을 선택해 주세요');
			return;
		}
		else if (classTime === "") {
			alert('수업 시간을 설정해주세요');
			return;
		}
		else if ((classTime.split('-')[0] > classTime.split('-')[1]) || classTime.length !== 11) {
			alert('잘못된 시간설정입니다.');
			return;
		}
		else if (classDate === '') {
			alert('수업 일시를 설정해주세요');
			return;
		}

    // 신규 등록
    if (scheduleId === -1) {
      var data = {
        storeId: storeId,
        lessonId: lessonId,
        classTime: classTime,
        classDate: classDate.replaceAll('.', '-') + 'T' + classTime.split('-')[0] + ':00',
        applyWeek: applyWeek,
        endDate: endDate.replaceAll('.', '-') + 'T00:00:00'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('schedule/create/', 'POST', data);

          // console.log(responseData);

          if (responseData.status === 200) cancel();
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    // 데이터 수정
    else {
      var data = {
        id: scheduleId,
        storeId: storeId,
        lessonId: lessonId,
        classTime: classTime,
        classDate: classDate.replaceAll('.', '-') + 'T' + classTime.split('-')[0] + ':00',
        applyWeek: applyWeek,
        endDate: endDate.replaceAll('.', '-') + 'T00:00:00'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.patchData('schedule/modify', 'PATCH', data);

          if (responseData.status === 200) cancel();
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
  }

  // 카테고리별 수업 소팅
  function classDataSortOFCategory(categoryId) {
    setNowCategoryId(categoryId);
  }
  
  // Select Box 클릭시 다른 Select Box 끄기
  function selectClickFn(className) {
   
    if (className === 'category') {
      ClassRef.current.setIsClicked();
      // CoachSelectRef.current.setIsClicked();
      StartCalenderRef.current.closeCalender();
      EndCalenderRef.current.closeCalender();
    }
    else if (className === 'lesson') {
      CategoryRef.current.setIsClicked();
      // CoachSelectRef.current.setIsClicked();
      StartCalenderRef.current.closeCalender();
      EndCalenderRef.current.closeCalender();
    }
    // else if (className === 'coach') {
    //   CategoryRef.current.setIsClicked();
    //   ClassRef.current.setIsClicked();
    //   StartCalenderRef.current.closeCalender();
    //   EndCalenderRef.current.closeCalender();
    // }
    else if (className === 'start') {
      CategoryRef.current.setIsClicked();
      ClassRef.current.setIsClicked();
      // CoachSelectRef.current.setIsClicked();
      EndCalenderRef.current.closeCalender();
    }
    else if (className === 'end') {
      CategoryRef.current.setIsClicked();
      ClassRef.current.setIsClicked();
      // CoachSelectRef.current.setIsClicked();
      StartCalenderRef.current.closeCalender();
    }
  }

  // 삭제버튼 클릭시
  function onClickDeletButton() {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('schedule/delete/' + scheduleId, 'DELETE');
        
        if (responseData.status === 200) cancel();
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView isOver={isOver}>
        <TitleWrapper isOver={isOver}>
          <Title isOver={isOver}>수업 추가</Title>
          {
            scheduleId === -1 ?
              <DateText isOver={isOver}>{date.format('YYYY.MM.DD')}</DateText>
              :
              <DeleteText isOver={isOver} onClick={onClickDeletButton}>수업 정보 삭제</DeleteText>
          }
        </TitleWrapper>
        <TotalWrapper isOver={isOver}>
          <LayerWrapper isOver={isOver}>
            <ComponentWrapper>
              <ComponentTitle>카테고리</ComponentTitle>
              <CategorySelect ref={CategoryRef} isOver={isOver} selectClickFn={() => selectClickFn('category')} selectFn={classDataSortOFCategory}/>
            </ComponentWrapper>
            <ComponentWrapper>
              <ComponentTitle>수업</ComponentTitle>
              <ClassSelect ref={ClassRef} isOver={isOver} selectClickFn={() => selectClickFn('lesson')} categoryId={nowCategoryId}/>
            </ComponentWrapper>
          </LayerWrapper>
          <LayerWrapper isOver={isOver}>
            <ComponentWrapper>
              <ComponentTitle>수업일시</ComponentTitle>
              <CalenderInput ref={StartCalenderRef} small={!isOver} selectClickFn={() => selectClickFn('start')} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width={isOver ? `280px` : `232px`}/>
            </ComponentWrapper>
            <ComponentWrapper>
              <ComponentTitle>시간</ComponentTitle>
              <TimeInput ref={TimeRef} isOver={isOver} placeholder="00:00 - 00:00"/>
            </ComponentWrapper>
          </LayerWrapper>
          {/* <LayerWrapper isOver={isOver}>
            <ComponentWrapper>
              <ComponentTitle>코치</ComponentTitle>
              <CoachSelect ref={CoachSelectRef} isOver={isOver} selectClickFn={() => selectClickFn('coach')}/>
            </ComponentWrapper>
          </LayerWrapper> */}
          <LayerWrapper isOver={isOver}>
            <ComponentWrapper>
              <ComponentTitle>반복 개설 일정</ComponentTitle>
              <RepeatSelect ref={RepeatRef} isOver={isOver}/>
            </ComponentWrapper>
            <ComponentWrapper>
              <LimitWrapper>
                <ComponentTitle>반복 개설 종료 일자</ComponentTitle>
                <LimitButtonWrapper>
                  <LimitButton isClicked={isClicked} onClick={() => setIsClicked(!isClicked)} />
                  <LimitText>제한없음</LimitText>
                </LimitButtonWrapper>
              </LimitWrapper>
              <CalenderInput ref={EndCalenderRef} small={!isOver} className='EndCalender' selectClickFn={() => selectClickFn('end')} clickFn={CalenderClick} disabled={isClicked} placeholder='종료일' width={isOver ? `280px` : `232px`} />
            </ComponentWrapper>
          </LayerWrapper>
        </TotalWrapper>
        <ButtonWrapper isOver={isOver}>
          <ExitButton isOver={isOver} onClick={cancel}>취소</ExitButton>
          <SaveButton isOver={isOver} onClick={onClickSaveButton}>저장하기</SaveButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default ClassAddModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `644px` : `290px`};
  height: ${props => props.isOver ? `467px` : `611px`};

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: ${props => props.isOver ? `588px` : `234px`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `14px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const DateText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const DeleteText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #FF3131;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `24px` : `12px`};
  margin-top: ${props => props.isOver ? `16px` : `12px`};
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: flex-start;
  align-items: center;

  gap: ${props => props.isOver ? `24px` : `12px`};
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: ${props => props.isOver ? `588px` : `234px`};

  gap: ${props => props.isOver ? `16px` : `8px`};

  margin: ${props => props.isOver ? `32px 0 0 0` : `24px 0 0 0`};
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `72px` : `54px`};
  height: ${props => props.isOver ? `38px` : `30px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `8px`};

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #595959;
    color: #595959;
  }
`;

const LimitWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const LimitButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const LimitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  transition: all 0.3s ease-in-out;

  :hover{
    cursor: pointer;
  }
`;

const LimitText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `80px`};
  height: ${props => props.isOver ? `40px` : `32px`};

  border-radius: ${props => props.isOver ? `15px` : `8px`};
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;