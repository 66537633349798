import { React } from 'react';
import styled from 'styled-components';

const BodyInfoBox = (props) => {
  const { isOver, bodyData } = props;
  
  return (
    <TopLevelWrapper isOver={isOver}>
      <ViewWrapper>
        <TopLineWrapper isOver={isOver}>
          <Title>신체 정보</Title>
          <Detail>자세히 보기</Detail>
        </TopLineWrapper>
        <ColumnWrapper isOver={isOver}>
          <Column isOver={isOver} style={ isOver ? {marginRight: `max(40px, 5.208333333333333vw)`} : {marginRight: `max(33px, 10.3125vw)`}}>날짜</Column>
          <Column isOver={isOver} style={ isOver ? {marginRight: `max(40px, 5.208333333333333vw)`} : {marginRight: `max(33px, 10.3125vw)`}}>체중</Column>
          <Column isOver={isOver} style={ isOver ? {marginRight: `max(40px, 5.208333333333333vw)`} : {marginRight: `max(33px, 10.3125vw)`}}>골격근</Column>
          <Column isOver={isOver} style={ isOver ? {marginRight: `max(16px, 2.083333333333333vw)`} : {marginRight: `max(12px, 3.75vw)`}}>체지방률</Column>
        </ColumnWrapper>
        <BodyInfo isOver={isOver}>
          {
            bodyData.map((data, index) => (
              <InfoBox key={index} isOver={isOver}>
              <Table>
                <Body>
                  <Tr>
                    <Td>
                      <TextWrapper style={ isOver ? { width: `max(66px, 8.59375vw)` } : { width: `max(57px, 17.8125vw)` }}>
                        <Text isOver={isOver}>{data.measurementDate.format('DD일 (dd)')}</Text>
                      </TextWrapper>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <TextWrapper style={ isOver ? { width: `max(66px, 8.59375vw)` } : { width: `max(57px, 17.8125vw)` }}>
                        <Text isOver={isOver}>{data.weight}</Text>
                      </TextWrapper>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <TextWrapper style={ isOver ? { width: `max(79px, 10.28645833333333vw)` } : { width: `max(66px, 20.625vw)` }}>
                        <Text isOver={isOver}>{data.skeletalMuscle}</Text>
                      </TextWrapper>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <TextWrapper style={ isOver ? { width: `max(52px, 6.770833333333333vw)` } : { width: `max(45px, 14.0625vw)` }}>
                        <Text isOver={isOver}>{data.bodyFat}</Text>
                      </TextWrapper>
                    </Td>
                  </Tr>
                </Body>
              </Table>
            </InfoBox>
            ))
          }
        </BodyInfo>
      </ViewWrapper>
    </TopLevelWrapper>
  )
}

export default BodyInfoBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 408px;
  width: ${props => props.isOver ? `max(343px, 44.66145833333333vw)` : `max(296px, 92.5vw)`};
  // height: 277px;
  height: 400px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  width: ${props => props.isOver ? `max(287px, 37.36979166666667vw)` : `max(244px, 76.25vw)`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;
  
  margin: 0 0 0 0;
`;

const Detail = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 344px;
  width: ${props => props.isOver ? `max(287px, 36.19791666666667vw)` : `max(244px, 76.25vw)`};
  height: 32px;

  margin-top: ${props => props.isOver ? `16px` : `15px`};
  padding-left: 8px;

  border-radius: 8px;
  background-color: #5BD98A;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const BodyInfo = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 287px;
  width: ${props => props.isOver ? `max(296px, 38.54166666666667vw)` : `max(244px, 76.25vw)`};
  // height: 124px;
  height: 264px;

  overflow-y: scroll;

  margin-top: 13px;

  &::-webkit-scrollbar {
    width: 9px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
    border: 3px solid transparent;
    height: 25px;
    // background-color: #FF8B48;
    border-radius: 200px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
    border: 4px solid transparent;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
`;


const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  // width: 335px;
  width: ${props => props.isOver ? `max(263px, 34.24479166666667vw)` : `max(225px, 70.3125vw)`};
  height: 32px;
  
  border-bottom: 1px solid #F5F5F5;
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;

  height: 32px;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const Date = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #CBCBCB;

  margin: 0 0 0 0;
`;
