import { React, useEffect, useRef } from 'react';
import styled from 'styled-components';

import LogoPath from '../../../image/CompleteSignUpLogo.png';

function SubscribePushModal(props) {
  const { isOver, yesFn } = props;

  const TopLevelRef = useRef();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

	return (
    <Background>
      <TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
        <LogoWrapper isOver={isOver}>
          <Logo src={LogoPath} isOver={isOver}/>
        </LogoWrapper>
        <ExplanationWrapper isOver={isOver}>
          <KorExplanation isOver={isOver}>Link Zone을 이용하시려면</KorExplanation>
          <KorExplanation isOver={isOver}>정기 구독을 진행해주세요</KorExplanation>
        </ExplanationWrapper>
        <ButtonWrapper isOver={isOver}>
          <YesButton onClick={yesFn}>
            <ButtonText>저장하기</ButtonText>
          </YesButton>
        </ButtonWrapper>
      </TopLevelWrapper>
    </Background>
  )
}
 
export default SubscribePushModal;

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

	width: ${props => props.isOver ? `476px` : `300px`};
	height: ${props => props.isOver ? `298px` : `200px`};
  
	border-radius: ${props => props.isOver ? `50px` : `20px`};
  background-color: #FFFFFF;

  transition: all 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  // margin-top: -300px;
  margin-top: ${props => props.isOver ? `-100px` : `-50px`};
`;

const Logo = styled.img`
  width: ${props => props.isOver ? `200px` : `100px`};
  height: ${props => props.isOver ? `200px` : `100px`};
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: flex-start;

  margin-top: ${props => props.isOver ? `109px` : `50px`};

  width: 90%;
  // height: 100px;
`;

const KorExplanation = styled.pre`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `25px` : `20px`};

  margin: 0 0 0 0;

  color: #000000;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 14px;

  margin-top: ${props => props.isOver ? `50px` : `20px`};
`;

const YesButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 155px;
  width: ${props => props.isOver ? `324px` : `260px`};
  height: 45px;

  border-radius: ${props => props.isOver ? `23px` : `16px`};
  background-color: #FF8B48;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #FF7322;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 18px;
  
  color: #FFFFFF;

  margin: 0 0 0 0;
`;