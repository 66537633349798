import { React, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import TopBarLogoPath from '../../../image/TopBarLogo.png';
import ProfilePath from '../../../image/Profile.png';
import HoverNoticePath from '../../../image/Notice.png';
import NotHoverNoticePath from '../../../image/NotHoverNotice.png';
import HoverSettingPath from '../../../image/Setting.png';
import NotHoverSettingPath from '../../../image/NotHoverSetting.png';
import NoticeBox from './NoticeBox';

import KeypadIcon from '../../../image/KeypadIcon.png';
import KeypadHoverIcon from '../../../image/KeypadHoverIcon.png';

function TopBar(props) {
  const { isOver, data } = props;

  const [keypadIcon, setKeypadIcon] = useState(KeypadIcon);

  const width = window?.screen?.width;
  const height = window?.screen?.height;

	const history = useHistory();

  function onClickLogout() {
    alert('로그아웃 되었습니다!');
    
    // window.localStorage.setItem('StoreId', null);
    // window.localStorage.setItem('Token', null);
    // window.localStorage.setItem('RefreshToken', null);
    // window.localStorage.setItem('AccountId', null);

    window.localStorage.removeItem('StoreId');
    window.localStorage.removeItem('Token');
    window.localStorage.removeItem('RefreshToken');
    window.localStorage.removeItem('AccountId');

    history.push('/');
  }

  function onClickProfile() {
    history.push('/mypage');
  }

  function onClickNotice() {
    alert("Click Profile");
  }

  function onClickSetting() {
    alert("Click Setting");
    // history.push('/setting');
  }

  function onClickKeypad() {
    // window.open('http://172.30.1.8:3001/keypad', "_blank", "noopener, noreferrer");
    // console.log('http://localhost:3001/ready/keypad/' + window.localStorage.getItem('StoreId') + '/' + window.localStorage.getItem('Token') + '/' + window.localStorage.getItem('RefreshToken'));
    let childWindow = window.open(
      'https://m.link-zone.io/ready/keypad/' + window.localStorage.getItem('StoreId') + '/' + window.localStorage.getItem('Token') + '/' + window.localStorage.getItem('RefreshToken'), 
      "_blank", 
      `width=${width}, height=${height}`
    );
    // let childWindow = window.open(
    //   'http://localhost:3000/ready/keypad/' + window.localStorage.getItem('StoreId') + '/' + window.localStorage.getItem('Token') + '/' + window.localStorage.getItem('RefreshToken'), 
    //   "_blank", 
    //   `width=${width}, height=${height}`
    // );

    childWindow.location.reload(true);
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      <TitleWrapper onClick={() => history.push('/main')}>
        <Logo src={TopBarLogoPath} isOver={isOver}/>
        <Title isOver={isOver}>Link Zone</Title>
      </TitleWrapper>
      <InfoWrapper isOver={isOver}>
        <LogoutButton isOver={isOver} onClick={onClickLogout}>로그아웃</LogoutButton>
        <KeypadButton
          onMouseOver={() => setKeypadIcon(KeypadHoverIcon)} 
          onMouseLeave={() => setKeypadIcon(KeypadIcon)}
          onClick={onClickKeypad}>
          <KeypadImage src={keypadIcon}/>
        </KeypadButton>
        <Profile src={data.storeLogo} isOver={isOver} onClick={onClickProfile}/>
        <NoticeBox isOver={isOver}/>
        {/* <Notice isOver={isOver} onClick={onClickNotice}/> */}
        {/* <Setting onClick={onClickSetting}/> */}
      </InfoWrapper>
    </TopLevelWrapper>
  );
}

export default TopBar;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  justify-content: space-between;
  align-items: center;

  padding: ${props => props.isOver ? `0 20px 0 17px` : `0 10px 0 10px`};

  width: ${props => props.isOver ? `calc(100vw - 37px)` : `calc(100vw - 20px)`};
  height: 56px;
  // height: 92px;

  // gap: 764px;
  
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  width: ${props => props.isOver ? `36px` : `31px`};
  height: ${props => props.isOver ? `36px` : `31px`};
`;

const Title = styled.p`
  font-family: Poppins-SemiBold;
  font-size: ${props => props.isOver ? `24px` : `15px`};
  color: #FF8B48;

  margin: ${props => props.isOver ? `0 0 0 8px` : `0 0 0 5px`};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center; 

  gap: ${props => props.isOver ? `16px` : `15px`};
`;

const LogoutButton = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `12px` : `11px`};

  color: #000000;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const Profile = styled.img`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `32px` : `28px`};
  height: ${props => props.isOver ? `32px` : `28px`};

  border-radius: ${props => props.isOver ? `32px` : `28px`};

  // background: url(${ProfilePath}) no-repeat center center;
  // background-color: #EBEBEB;
  
  transition: all 0.3s ease-in-out;

  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */

  backface-visibility: hidden;
  transform: translateZ(0);

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
  }
`;

// const KeypadButton = styled.div`
//   display: flex;
//   flex-direction: row;
  
//   width: 32px;
//   height: 32px;

//   border-radius: 32px;

//   justify-content: center;
//   align-items: center;

//   background: url(${KeypadIcon}) no-repeat center center;

//   transition: all 0.3s ease-in-out;

//   :hover {
//     cursor: pointer;
//     background: url(${KeypadHoverIcon}) no-repeat center center;
//     background-color: #FF8B48;
//   }
// `;

const KeypadButton = styled.div`
  display: flex;
  flex-direction: row;
  
  width: 32px;
  height: 32px;

  border-radius: 32px;

  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
  }
`;

const KeypadImage = styled.img`
  width: 24px;
  height: 24px;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Notice = styled.div`
  display: flex;
  flex-direction: row;
  
  width: ${props => props.isOver ? `32px` : `28px`};
  height: ${props => props.isOver ? `32px` : `28px`};

  border-radius: ${props => props.isOver ? `32px` : `28px`};

  justify-content: center;
  align-items: center;

  background: url(${NotHoverNoticePath}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: url(${HoverNoticePath}) no-repeat center center;
    background-color: #FF8B48;
  }
`;

const Setting = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  width: 32px;
  height: 32px;

  border-radius: 32px;

  justify-content: center;
  align-items: center;

  background: url(${NotHoverSettingPath}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: url(${HoverSettingPath}) no-repeat center center;
    background-color: #FF8B48;
  }
`;
