import { React, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import BoxImage from '../../../../image/PhysicalInfoHoverBox.png';

function PhysicalGraphView(props) {
  const { data, xColumn, yColumn, unit, nowDate, isOver } = props;

  const [startX, setStartX] = useState(57);
  const [xGap, setXGap] = useState(0);
  const [yRatio, setYRatio] = useState(0);
  const [hoverIndex, setHoverIndex] = useState([]);

  const [colorWidth, setColorWidth] = useState(598);

  const canvas = useRef();

  let ctx = null;
  
  // initialize the canvas context
  useEffect(() => {
    if (data.length === 0) return;
    // dynamically assign the width and height to canvas
    let canvasEle = canvas.current;
    
    var startX = 57;
    var endX = 545;
    // var endX = 660;

    var xLineGap = ((endX - startX) / (data.length === 1 ? data.length : data.length - 1));

    var result = (yColumn[0] - yColumn[yColumn.length - 1]) / (41 * 5);

    var onMouse = [];

    if (nowDate === 'Month') {
      canvasEle.width = 598;
      canvasEle.height = 203;
    }
    else {
      xLineGap = 122;
      endX = xLineGap * data.length - 1;

      canvasEle.width = endX + 53;
      canvasEle.height = 203;

      if (endX + 53 > 598) setColorWidth(endX + 53);
      else setColorWidth(598);
    }
    // console.log("canvasEle.clientWidth : " + canvasEle.clientWidth);
    // console.log("canvasEle.clientHeight : " + canvasEle.clientHeight);
 
    // get context of the canvas
    ctx = canvasEle.getContext("2d");
    
    for (var i = 0; i < data.length - 1; i++) {
      if (data[i] !== 0 && data[i + 1] !== 0) {
        ctx.strokeStyle = '#FF8B48';
        ctx.beginPath();
        ctx.setLineDash([5, 5]);
        ctx.moveTo(startX + (xLineGap * i), (yColumn[0] - data[i]) / result);
        ctx.lineTo(startX + (xLineGap * (i + 1)), (yColumn[0] - data[i + 1]) / result);
        ctx.stroke();
      }
      onMouse.push(0);
    }

    onMouse.push(0);
    setStartX(startX - 8);
    setXGap(xLineGap);
    setYRatio(result);
    setHoverIndex(onMouse);

  }, [data, nowDate]);

  function onMouseOver(index) {
    var newList = hoverIndex.slice();
    newList[index] = 1;
    setHoverIndex(newList);
  }

  function onMouseLeave(index) {
    var newList = hoverIndex.slice();
    newList[index] = 0;
    setHoverIndex(newList);
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      <YColumnWrapper>
        {
          yColumn.map((data, index) => (
            <Text key={index} fontFamily='NotoSansKR-Regular' fontSize={12} color='#CBCBCB'>{data}</Text>
          ))
        }
      </YColumnWrapper>
      <RightWrapper isOver={isOver}>
        <GraphBox>
          <ColorBox width={colorWidth} height={41} backgroundColor='#F5F5F5'/>
          <ColorBox width={colorWidth} height={41} backgroundColor='#FFFFFF'/>
          <ColorBox width={colorWidth} height={41} backgroundColor='#F5F5F5'/>
          <ColorBox width={colorWidth} height={41} backgroundColor='#FFFFFF'/>
          <ColorBox width={colorWidth} height={41} backgroundColor='#F5F5F5'/>
          <LineWrapper>
            <canvas ref={canvas} />
            <PointWrapper>
              {
                data.map((inData, index) => (
                  inData !== 0 &&
                  <TotalCircle onMouseOver={() => onMouseOver(index)} onMouseLeave={() => onMouseLeave(index)} key={index} x={startX + (xGap * index)} y={((yColumn[0] - inData) / yRatio) - 8}>
                    <InnerCircle/>
                  </TotalCircle>
                ))
              }
              {
                data.map((inData, index) => (
                  inData !== 0 &&
                  <ImageWrapper key={index} x={(startX - 26) + (xGap * index)} y={((yColumn[0] - inData) / yRatio) - 54} opacity={hoverIndex[index]}>
                    <Image src={BoxImage} />
                    <TextWrapper>
                      <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF'>{inData + unit}</Text>
                    </TextWrapper>
                  </ImageWrapper>
                ))
              }
            </PointWrapper>
          </LineWrapper>
          {/* <XColumnWrapper width={colorWidth - 43}> */}
          {
            xColumn.map((data, index) => (
              <XColumnWrapper key={index} x={(startX - 50) + (xGap * index)} y={200}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>{data}</Text>
              </XColumnWrapper>
            ))
          }
        {/* </XColumnWrapper> */}
        </GraphBox>
        {/* <XColumnWrapper width={colorWidth - 43}>
          {
            xColumn.map((data, index) => (
              <Text key={index} fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>{data}</Text>
            ))
          }
        </XColumnWrapper> */}
      </RightWrapper>
    </TopLevelWrapper>
  )
}

export default PhysicalGraphView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  // width: 628px;
  // width: 638px;
  width: ${props => props.isOver ? `max(638px, 83.07291666666667vw)` : `max(278px, 86.875vw)`};

  margin-top: 27px;
`;

const YColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-start;

  height: 219px;
`;

const XColumnWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  // justify-content: space-between;
  align-items: flex-start;

  // width: 513px;
  // width: ${props => props.width}px;

  margin-top: 8px;
  margin-left: 42px;

  // background-color: red;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
`;

const RightWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 598px;
  width: ${props => props.isOver ? `max(598px, 77.86458333333333vw)` : `max(242px, 75.625vw)`};
  // height: 228px;
  height: 238px;

  margin-top: 9px;

  // overflow-x: scroll;
  // overflow-y: visible;
  
	&::-webkit-scrollbar {
		width: 1px;
    height: 10px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
    width: 1px;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const GraphBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 598px;
  height: 203px;

  border-bottom: 1px solid #CBCBCB;
`;

const ColorBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 100%;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  background-color: ${props => props.backgroundColor};
`;

const LineWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 598px;
  height: 203px;

  // overflow: hidden;
  // background-color: red;
`;

const PointWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
`;

const TotalCircle = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  top: ${props => props.y}px;
  left: ${props => props.x}px;
  
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  border-radius: 16px;

  background-color: #FFD3B9;

  :hover {
    cursor: pointer;
  }
`;

const InnerCircle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 8px;
  height: 8px;
  
  border-radius: 8px;
  background-color: #FF8B48;
`;

const ImageWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  top: ${props => props.y}px;
  left: ${props => props.x}px;

  width: 68px;
  height: 38px;

  opacity: ${props => props.opacity};

  transition: all 0.3s ease-in-out;
`;

const Image = styled.img`
  position: absolute;
  width: 68px;
  height: 38px;
`;

const TextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 6px;
`;