import { React, useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import HistoryView from './HistoryView.js';
import IndividualInfo from './IndividualInfo.js';
import FetchModule from '../../../Share/Network/FetchModule.js';

const MemberDetailView = forwardRef((props, ref) => {
  const { isOver, memberId, division, deleteClickFn } = props;

  const history = useHistory();

  const [memberData, setMemberData] = useState([
                                                {name: '',
                                                birthDay: '',
                                                gender: '',
                                                linkCoachId: '',
                                                phone: '',
                                                profile: 'None'}
                                                ]);
  const [memberShipData, setMemberShipData] = useState([]);
  const [holdingData, setHoldingData] = useState([]);
  const [physicalData, setPhysicalData] = useState([]);
  const [lessonHistoryData, setLessonHistoryData] = useState([]);
  const [extendHistoryData, setExtendHistoryData] = useState([]);
  const [totalHistoryData, setTotalHistoryData] = useState([]);
  const [lockerData, setLockerData] = useState([]);
  const [rendering, setRendering] = useState(false);
  const [reserveRerendering, setReserveRerendering] = useState(false);

  const TopLevelRef = useRef();
  const IndividualRef = useRef();
  const HistoryRef = useRef();

  useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
    setZindex: (val) => {
			TopLevelRef.current.style.zIndex = val;
    }
	}))

  useEffect(() => {
    IndividualRef.current.setMemberData(memberData);
  }, [memberData])


  useEffect(() => {
    HistoryRef.current.setMemberShipData(memberShipData);
  }, [memberShipData])

  useEffect(() => {
    // if (index === -1) return;

    var requestData = {
      memberId: memberId
    }

    document.documentElement.scrollTop = 0;

    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.getIndividualData('member/detail/' + memberId, 'get');

				if (responseData.status === 200) {
          setMemberData(responseData.data[0]);
				}
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();

		(
			async () => {
				const responseData = await fetchModule.getIndividualData('membership/detail/' + memberId, 'get');

				if (responseData.status === 200) {
          setMemberShipData(responseData.data);
				}
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();

    (
      async () => {
        const responseData = await fetchModule.postData('membership/history', 'POST', requestData);

        if (responseData.status === 200) {
          var newList = responseData.data.slice();

          // desc 진행 //
          newList.sort(function (a, b) {
            return a.endDate > b.endDate ? -1 : a.endDate < b.endDate ? 1 : 0;
          })

          setTotalHistoryData(newList);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, rendering, memberId])
  

  useEffect(() => {
    const fetchModule = new FetchModule();
		(
			async () => {
        // const memberId = data[index].memberId;
				const responseData = await fetchModule.getDataOfCoach('physical/chart/all/' + memberData.linkCoachId, 'GET');

				if (responseData.status === 200) {
          // console.log(responseData.data);
          setPhysicalData(responseData.data);
				}
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }, [memberData])
  
	useEffect(() => {
    var requestData = {
      memberId: memberId
    }

		const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/history/', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          setLessonHistoryData(responseData.data);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
	}, [history, reserveRerendering])
  
	useEffect(() => {
		const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('locker/' + memberId + '/', 'GET');
        
        if (responseData.status === 200) setLockerData(responseData.data);
        else if (responseData.status === 401) history.push('/');
      }
    )();
	}, [history])


  // 홀딩 내역과 연장 내역 리스트 병합 //
  useEffect(() => {
    var newList = holdingData.concat(extendHistoryData);

    // desc 진행 //
    newList.sort(function (a, b) {
      return a.endDate > b.endDate ? -1 : a.endDate < b.endDate ? 1 : 0;
    })

    setTotalHistoryData(newList);
  }, [holdingData, extendHistoryData])

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <IndividualInfo ref={IndividualRef} isOver={isOver} memberData={memberData} rendering={() => setRendering(!rendering)}/>
      <HistoryView 
        ref={HistoryRef} 
        isOver={isOver} 
        data={memberShipData}
        holdingData={totalHistoryData}
        memberId={memberId}
        division={division} 
        lessonHistoryData={lessonHistoryData} 
        lockerData={lockerData}
        physicalData={physicalData} 
        rendering={() => setRendering(!rendering)}
        reserveRerendering={() => setReserveRerendering(!reserveRerendering)} 
        deleteClickFn={deleteClickFn}/>
    </TopLevelWrapper>
  )
})

export default MemberDetailView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  transition: all 0.3s ease-in-out;
`;