import { React } from 'react';
import styled from 'styled-components';

import NoticeView from '../../Model/Individual/NoticeModel/NoticeView';

function NoticePage({match}) {

  return (
    <Container>
      <NoticeView noticeId={match.params.noticeId}/>
    </Container>
  )
}

export default NoticePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  // width: 100%;
`;