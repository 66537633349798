import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';

const FullMemberListView = (props) => {
  const { date, lessonId } = props;

  const history = useHistory();

  const [memberData, setMemberData] = useState([]);

  useEffect(() => {
    if (lessonId === -1) return;

    (
      async () => {
        const fetchModule = new FetchModule();
        const responseData = await fetchModule.getIndividualData('reservation/'
                                              + lessonId + '/'
                                              + date.clone().format('YYYY-MM-DD'), 'GET');
        
        if (responseData.status === 200) {
					setMemberData(responseData.data);
				}
        else if (responseData.status === 204) setMemberData([]);
        else if (responseData.status === 401) history.push('/');
        else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }, [history, date, lessonId])

  return (
    <TopLevelWrapper>
      <ViewWrapper>
        <TopLineWrapper>
          <LeftWrapper>
            <Title>회원 명단</Title>
            <Count>{memberData.length}</Count>
          </LeftWrapper>
          <AddButton>추가</AddButton>
        </TopLineWrapper>
        {
          memberData.map((data, index) => {
            <MemberWrapper key={index}>
              <Profile src={data.profile}/>
              <InfoWrapper>
                <Text color='#232323'>{data.name}</Text>
                <Text color='#777777'>{data.phone[0] 
                                      + data.phone[1]
                                      + data.phone[2]
                                      + '-'
                                      + data.phone[3]
                                      + data.phone[4]
                                      + data.phone[5]
                                      + data.phone[6]
                                      + '-'
                                      + '****'}</Text>
              </InfoWrapper>
            </MemberWrapper>
          })
        }
      </ViewWrapper>
    </TopLevelWrapper>
  )
}

export default FullMemberListView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 204px;
  width: 10.625vw;
  // height: 364px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // width: 148px;
  width: 7.708333333333333vw;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  // margin-top: 28px;
  margin-top: 1.458333333333333vw;
  // margin-bottom: 24px;
  margin-bottom: 1.25vw;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 3px;
  gap: 0.15625vw;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #232323;

  margin: 0 0 0 0;
`;

const Count = styled.p`
  font-family: NotoSansKR-Medium;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #FF8B48;

  margin: 0 0 0 0;
`;

const AddButton = styled.p`
  font-family: NotoSansKR-Regular;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 16px;
  gap: 0.8333333333333333vw;

  // margin-bottom: 28px;
  margin-bottom: 1.458333333333333vw;
`;

const Profile = styled.img`
  // width: 40px;
  width: 2.083333333333333vw;
  // height: 40px;
  height: 2.083333333333333vw;

  border-radius: 15px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // gap: 2px;
  gap: 0.1041666666666667vw;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  margin: 0 0 0 0;

  color: ${props => props.color};
`;