import { React, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Email Link전송시 session storage에 토큰을 저장후 redirection을 진행하기 위한 페이지

const EmailCertificationPage = ({match}) => {
  const history = useHistory();

  useEffect(() => {
    window.sessionStorage.setItem("CertificationString", match.params.certificationString);
    history.push('/certification');
  }, [])

  return <div/>
}

export default EmailCertificationPage;