import { React, useState, useRef } from 'react';
import styled from 'styled-components';
import CenterTypeSelect from '../../../Component/MySettingPageComponent/CenterTypeSelect';
import InfoInput from '../../../Component/MySettingPageComponent/InfoInput';
import OpenTimeInput from '../../../Component/MySettingPageComponent/OpenTimeInput';
import UsePaymentSystemButton from '../../../Component/MySettingPageComponent/UsePaymentSystemButton';

import DefaultLogoPath from '../../../image/DefaultLogo.png'

function MyGymIntroductionEdit(props) {
  const {
    isOver,
    storeLogo,
    setStoreLogo,
    storeName,
    setStoreName,
    tel,
    setTel,
    type,
    setType,
    address,
    setAddress,
    openingHour,
    setOpeningHour,
    isUsePaymentSystem,
    setIsUsePaymentSystem } = props;

  const hiddenFileInput = useRef(null);
  const FileInputRef = useRef();
  const [imgBase64, setImgBase64] = useState();
  // const [imgFile, setImgFile] = useState();
  const [imgLocalPath, setImgLocalPath] = useState('');

  const handleClick = e => {
    hiddenFileInput.current.click();
    FileInputRef.current.style.borderColor = '#FF8B48';
  };

  const handleChange = e => {

    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우
      const reader = new FileReader();
      // setImgLocalPath(e.target.value);
      var tmp = e.target.value.split('\\');
      setImgLocalPath(tmp[tmp.length - 1]);
      reader.onloadend = () => {
        const base64 = reader.result;

        FileInputRef.current.style.borderColor = '#E8E8E8';
        if (base64) {
          setImgBase64(base64.toString());
        }
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
        // setImgFile(e.target.files[0]);
        setStoreLogo(e.target.files[0]);
      }
    }
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
    }
  };

  return (
    <TopLevelWrapper>
      <InfoView isOver={isOver}>
        <TitleWrapper isOver={isOver}>
          <Title>센터 정보</Title>
        </TitleWrapper>
        <InfoWrapper>
          <LayerWrapper isOver={isOver}>
            <LogoWrapper>
              <Logo src={storeLogo ? storeLogo : DefaultLogoPath} />
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }} />
              <UploadButton ref={FileInputRef} onClick={handleClick}>사진 변경</UploadButton>
            </LogoWrapper>
            <TextWrapper>
              <InfoInput column='상호명' defaultVal={storeName} setVal={setStoreName} width={isOver ? 'max(478px, 24.89583333333333vw)' : `max(220px, 11.45833333333333vw)`} />
              <InputWrapper isOver={isOver} topMargin={28}>
                <InfoInput column='전화번호' defaultVal={tel} setVal={setTel} width='max(220px, 11.45833333333333vw)' />
                <CenterTypeSelect defaultVal={type} setVal={setType} />
              </InputWrapper>
              <InputWrapper topMargin={28}>
                <InfoInput column='센터 주소' defaultVal={address} setVal={setAddress} width={isOver ? 'max(478px, 24.89583333333333vw)' : `max(220px, 11.45833333333333vw)`} />
              </InputWrapper>
              <InputWrapper topMargin={28}>
                <OpenTimeInput column='영업시간' defaultVal={openingHour} setVal={setOpeningHour} width={isOver ? 'max(478px, 24.89583333333333vw)' : `max(220px, 11.45833333333333vw)`} />
              </InputWrapper>
              <InputWrapper topMargin={28}>
                <UsePaymentSystemButton isUsePaymentSystem={isUsePaymentSystem} setIsUsePaymentSystem={setIsUsePaymentSystem} width={isOver ? 'max(478px, 24.89583333333333vw)' : `max(256px, 13.33333333333333vw)`}/>
               </InputWrapper>
            </TextWrapper>
          </LayerWrapper>
        </InfoWrapper>
      </InfoView>
    </TopLevelWrapper>
  )
}

export default MyGymIntroductionEdit;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 34px;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 1191px;
  width: ${props => props.isOver ? `max(692px, 36.04166666666667vw)` : `max(280px, 14.58333333333333vw)`};
  // height: ${props => props.isOver ? `436px` : `766px`};
  height: ${props => props.isOver ? `521px` : ``};

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 1135px;
  width: ${props => props.isOver ? `max(636px, 33.125vw)` : `max(256px, 13.33333333333333vw)`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
  border-top: 1px solid #ECECEC;

  // width: 1135px;
  width: max(636px, 33.125vw);
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  margin-top: 24px;
  gap: 20px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px
`;

const Logo = styled.img`
  width: 120px;
  height: 120px;

  border-radius: 15px;

  object-fit: cover;
`;

const UploadButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 120px;
  height: 32px;

  border-radius: 8px;

  background-color: #33C8FF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 8px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: space-between;
  align-items: center;

  gap: ${props => props.isOver ? `0px` : `24px`};
  margin-top: ${props => props.topMargin}px;

  width: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const None = styled.div`
  width: ${props => props.width}px;
  height: 56.8px;
`;