import { React, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const DataBar = (props) => {
  const { data, maximum, isOver, size } = props;

  // const size = useWindowSize();

  const [barSize, setBarSize] = useState(0);

  const HoverRef = useRef();

  // useEffect(() => {
  //   console.log("Maximum : " + maximum);
  //   console.log("카드 : " + (data.cardPrice * 450) / maximum);
  //   console.log("현금 : " + (data.cashPrice * 450) / maximum);
  //   console.log("미수금 : " + (data.notReceived * 450) / maximum);
  // }, [maximum])

  useEffect(() => {
    // console.log((565 * size.width) / 768); 
    if (size.width === 0) return;

    setBarSize(parseInt((565 * size.width) / 768));
  }, [size])

  function mouseOver() {
    if (data.totalPrice === 0) return;

    HoverRef.current.style.zIndex = 5;
    HoverRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    if (data.totalPrice === 0) return;

    HoverRef.current.style.zIndex = -5;
    HoverRef.current.style.opacity = 0;
  }

  return (
    <TopLevelWrapper onMouseLeave={mouseLeave} onMouseOver={mouseOver}>
      {
        ((data.cardPrice * barSize) / maximum) > 71 ?
          <DataShape width={maximum === 0 ? 0 : (data.cardPrice * barSize) / maximum} color='#5BD98A' isOver={isOver}>
            <DataText isOver={isOver}>카드</DataText>
            <DataPercent isOver={isOver}>({Math.ceil((data.cardPrice / (data.totalPrice)) * 100)}%)</DataPercent>
          </DataShape>
          :
          <DataShape width={maximum === 0 ? 0 : (data.cardPrice * barSize) / maximum} color='#5BD98A' isOver={isOver}/>
      }
      {
        ((data.cashPrice * barSize) / maximum) > 71 ?
          <DataShape width={maximum === 0 ? 0 : (data.cashPrice * barSize) / maximum} color='#FFCF71' isOver={isOver}>
            <DataText isOver={isOver}>현금</DataText>
            <DataPercent isOver={isOver}>({Math.ceil((data.cashPrice / (data.totalPrice)) * 100)}%)</DataPercent>
          </DataShape>
          :
          <DataShape width={maximum === 0 ? 0 : (data.cashPrice * barSize) / maximum} color='#FFCF71' isOver={isOver}/>
      }
      {
        ((data.notReceived * barSize) / maximum) > 71 ?
          <DataShape width={maximum === 0 ? 0 : (data.notReceived * barSize) / maximum} color='#FF3131' isOver={isOver}>
            <DataText isOver={isOver}>미수금</DataText>
            <DataPercent isOver={isOver}>({Math.ceil((data.notReceived / (data.totalPrice)) * 100)}%)</DataPercent>
          </DataShape>
          :
          <DataShape width={maximum === 0 ? 0 : (data.notReceived * barSize) / maximum} color='#FF3131' isOver={isOver}/>
      }
      {
        data.totalPrice === 0 ?
          <DataShape width={maximum === 0 ? 0 : ((maximum / 2) * barSize) / maximum} color='#ECECEC' isOver={isOver}/>
          :
          null
      }
      <HoverBox ref={HoverRef} isOver={isOver}>
        <InfoBox isOver={isOver}>
          <ColumnWrapper isOver={isOver}>
            <Column isOver={isOver}>총 매출</Column>
            <Column isOver={isOver}>현금</Column>
            <Column isOver={isOver}>카드</Column>
            <Column isOver={isOver}>미수금</Column>
          </ColumnWrapper>
          <ColumnWrapper isOver={isOver}>
            <Content isOver={isOver}>{data.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Content>
            <Content isOver={isOver}>{data.cashPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Content>
            <Content isOver={isOver}>{data.cardPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Content>
            <Content isOver={isOver}>{data.notReceived.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Content>
          </ColumnWrapper>
          <ColumnWrapper isOver={isOver}>
            <Unit isOver={isOver}>원</Unit>
            <Unit isOver={isOver}>원</Unit>
            <Unit isOver={isOver}>원</Unit>
            <Unit isOver={isOver}>원</Unit>
          </ColumnWrapper>
        </InfoBox>
      </HoverBox>
    </TopLevelWrapper>
  )
}

export default DataBar;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  overflow: hidden;
  border-radius: 0px 5px 5px 0px;
  
  transition: all 0.3s ease-in-out;
`;

const DataShape = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.isOver ? `22px` : `13px`};

  background-color: ${props => props.color};

  font-family: NotoSansKR-Bold;
  font-size: 10px;

  overflow: hidden;
  color: #FFFFFF;
`;

const DataText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 10px;

  color: #FFFFFF;
  
  margin: 0 0 0 ${props => props.isOver ? `8px` : `4px`};
  
  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};
`;

const DataPercent = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 10px;

  color: #FFFFFF;

  margin: 0 0 0 3px;
  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};
`;

const HoverBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `182px` : `132px`};
  height: ${props => props.isOver ? `128px` : `93px`};

  z-index: -5;
  opacity: 0;

  margin-top: ${props => props.isOver ? `161px` : `110px`};
  margin-left: ${props => props.isOver ? `161px` : `80px`};

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `8px` : `6px`};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `8px` : `5px`};
`;

const Column = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const Content = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const Unit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #BABABA;

  margin: 0 0 0 0;
`;