import { React } from 'react';
import styled from 'styled-components';

const TimeDivisionLine = (props) => {
  const { isOver, scheduleData, isMain } = props;

  return (
    <TopLevelWrapper isOver={isOver}>
      {
          scheduleData.isNeedNewDivide ?
          <TextWrapper>
            <TimeText isOver={isOver}>{scheduleData.viewTime}</TimeText>
            <TextTimeLine isOver={isOver} isNeedNewDivide={scheduleData.isNeedNewDivide} isMain={isMain}/>
          </TextWrapper>
          :
          <TimeLine />
      }
    </TopLevelWrapper>
  )
}

export default TimeDivisionLine;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: center;

  margin: ${props => props.isOver ? `0 max(18px, 2.34375vw) 0 0` : `0 max(4px, 1.25vw) 0 0`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
`;

const TimeText = styled.p`
  font-family: Poppins-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #777777;
  
  margin: 0 0 0 0;
`;

const TextTimeLine = styled.div`
  width: 2px;
  // height: ${props => props.isOver ? `55px` : `71px`};
  // height: 66px;
  height: ${props => props.isOver ? props.isMain ? `60px` : `54px` : props.isMain ? `66px` : `72px`};
  border-right: 2px solid #ECECEC;
`;


const TimeLine = styled.div`
  width: 2px;
  height: 80px;

  border-right: 2px solid #ECECEC;
`;
