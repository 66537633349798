import { React, useState, useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import MemberList from './MemberList';

import LeaveMemberImagePath from '../../../image/LeaveMember.png';
import ReRegistrationImagePath from '../../../image/ReRegistration.png';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

const MemberMovementHistoryBox = (props) => {
  const { today, leaveData, aliveData, isOver, isAliveLoading, setIsAliveLoading, isExpiredLoading, setIsExpiredLoading } = props;

  const [newMember, setNewMember] = useState([]);
  const [leaveMember, setLeaveMember] = useState([]);

  useEffect(() => {
    var newMemberData = [];

    for (var i = 0; i < aliveData.length; i++) {
      if (today.clone().format('YYYY.MM.00') < aliveData[i].startDate.split('T')[0].replaceAll('-', '.')) {
        newMemberData.push(aliveData[i]);
      }
    }
    setNewMember(newMemberData);

    setTimeout(() => {
      setIsAliveLoading(false)
    }, 1000)
  }, [today, aliveData])

  useEffect(() => {
    var newLeaveData = [];

    for (var i = 0; i < leaveData.length; i++) {
      if (today.clone().format('YYYY.MM.00') < leaveData[i].endDate.split('T')[0].replaceAll('-', '.')) {
        newLeaveData.push(leaveData[i]);
      }
    }
    setLeaveMember(newLeaveData);

    setTimeout(() => {
      setIsExpiredLoading(false)
    }, 1000)
  }, [today, leaveData])

  return (
    <TopLevelWrapper isOver={isOver}>
      <InfoBox isOver={isOver}>
        <TotalWrapper>
          <Title isOver={isOver}>{today.clone().format('YYYY년 MM월 이탈 회원')}</Title>
          {
            isExpiredLoading ?
              <AnimationBox>
                <LottieWrapper>
                  <Lottie options={{ animationData: LoadingAnimation }} />
                </LottieWrapper>
              </AnimationBox>
              :
              <InfoView>
                <TopUIWrapper isOver={isOver}>
                  <LeaveIcon src={LeaveMemberImagePath} isOver={isOver} />
                  <LeavePersent isOver={isOver}>
                    <PersentText isOver={isOver}>회원 이탈율</PersentText>
                    <PersentNumber isOver={isOver}>{Math.round((((aliveData.length - newMember.length) / aliveData.length)) * 100)}%</PersentNumber>
                  </LeavePersent>
                  <DivisionLine isOver={isOver} />
                  <ResultBox isOver={isOver}>
                    <ResultText style={{ marginRight: `3px` }} isOver={isOver}>총</ResultText>
                    <ResultNumber style={{ marginRight: `3px` }} isOver={isOver}>{leaveData.length}명</ResultNumber>
                    <ResultText isOver={isOver}>이탈</ResultText>
                  </ResultBox>
                </TopUIWrapper>
                <ListTitle isOver={isOver}>이탈 회원 목록</ListTitle>
                <MemberList column='이탈 날짜' data={leaveMember} isOver={isOver} />
              </InfoView>
          }
        </TotalWrapper>
      </InfoBox>
      <InfoBox isOver={isOver}>
        <TotalWrapper>
          <Title isOver={isOver}>{today.clone().format('YYYY년 MM월 신규등록 회원')}</Title>
          {
            isAliveLoading ?
              <AnimationBox>
                <LottieWrapper>
                  <Lottie options={{ animationData: LoadingAnimation }} />
                </LottieWrapper>
              </AnimationBox>
              :
              <InfoView>
                <TopUIWrapper isOver={isOver}>
                  <LeaveIcon src={ReRegistrationImagePath} isOver={isOver} />
                  <ReRegistrationPersent isOver={isOver}>
                    <PersentText isOver={isOver}>신규 등록율</PersentText>
                    <PersentNumber isOver={isOver}>{Math.round((newMember.length / aliveData.length) * 100)}%</PersentNumber>
                  </ReRegistrationPersent>
                  <DivisionLine isOver={isOver} />
                  <ResultBox isOver={isOver}>
                    <ResultText style={{ marginRight: `3px` }} isOver={isOver}>총</ResultText>
                    <ResultNumber style={{ marginRight: `3px` }} isOver={isOver}>{newMember.length}명</ResultNumber>
                    <ResultText isOver={isOver}>신규등록</ResultText>
                  </ResultBox>
                </TopUIWrapper>
                <ListTitle isOver={isOver}>신규등록 회원 목록</ListTitle>
                <MemberList column='등록 날짜' data={newMember} isOver={isOver} />
              </InfoView>
          }
        </TotalWrapper>
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default MemberMovementHistoryBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `30px` : `15px`};
  margin-top: ${props => props.isOver ? `38px` : `15px`};
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(300px, 93.75vw)`};
  height: ${props => props.isOver ? `448px` : `270px`};

  border-radius: 30px;
  box-shadow: 0 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  height: 100%;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const TopUIWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: ${props => props.isOver ? `24px` : `16px`};
`;

const LeaveIcon = styled.img`
  width: ${props => props.isOver ? `48px` : `31px`};
  height: ${props => props.isOver ? `48px` : `31px`};

  margin-right: ${props => props.isOver ? `8px` : `3px`};
`;

const LeavePersent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(133px, 17.31770833333333vw)` : `max(81px, 25.3125vw)`};
  height: ${props => props.isOver ? `28px` : `16px`};

  border-radius: ${props => props.isOver ? `8px` : `4px`};
  background-color: #FF8B48;
`;

const ReRegistrationPersent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(133px, 17.31770833333333vw)` : `max(81px, 25.3125vw)`};
  height: ${props => props.isOver ? `28px` : `16px`};

  border-radius: ${props => props.isOver ? `8px` : `4px`};
  background-color: #33C8FF;
`;

const PersentText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FFFFFF;

  margin: 0 3px 0 0;
`;

const PersentNumber = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: row;

  width: ${props => props.isOver ? `max(302px, 39.32291666666667vw)` : `max(83px, 25.9375vw)`};
  height: 1px;

  // margin: 0 max(16px, 2.083333333333333vw) 0 max(16px, 2.083333333333333vw);
  margin-top: 0px;
  margin-right: ${props => props.isOver ? `max(16px, 2.083333333333333vw)` : `max(3px, 0.9375vw)`};
  margin-bottom: 0px;
  margin-left: ${props => props.isOver ? `max(16px, 2.083333333333333vw)` : `max(3px, 0.9375vw)`};

  border: 1px solid #ECECEC;
`;

const ResultBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(113px, 14.71354166666667vw)` : `max(68px, 21.25vw)`};
  height: ${props => props.isOver ? `28px` : `16px`};

  border-radius: ${props => props.isOver? `8px` : `4px`};
  background-color: #ECECEC;
`;

const ResultText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};
`;

const ResultNumber = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `14px` : `10px`};


  color: #777777;

  margin: 0 0 0 0;
  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};
`;

const ListTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: ${props => props.isOver ? `24px` : `6px`} 0 0 0;
  
`;