import { React, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ClassInfoBox = (props) => {
  const { isOver, data, index, coachList, modifyClickFn, deleteClickFn } = props;

  const [teacherIdList, setTeacherIdList] = useState([]);

  const TopLevelRef = useRef();
  const ButtonRef = useRef();
  const CoachRef = useRef([]);
  const TimeRef = useRef();

  useEffect(() => {
    var newTeacherIdList = data.teacherIds.split(',');

    setTeacherIdList(newTeacherIdList);
  }, [data])

  function mouseOver() {
    TopLevelRef.current.style.backgroundColor = data.color;
    TimeRef.current.style.backgroundColor = data.color;

    for (var i = 0; i < CoachRef.current.length; i++) {
      if (i === 3) break;
      CoachRef.current[i].style.backgroundColor = data.color;
    }

    ButtonRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    TopLevelRef.current.style.backgroundColor = `#DFDFDF`;
    TimeRef.current.style.backgroundColor = `#CBCBCB`;

    for (var i = 0; i < CoachRef.current.length; i++) {
      if (i === 3) break;
      CoachRef.current[i].style.backgroundColor = `#CBCBCB`;
    }

    ButtonRef.current.style.opacity = 0;
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} isOver={isOver} onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      <TotalWrapper isOver={isOver}>
      <TextWrapper isOver={isOver}>
        <Text isOver={isOver}>{data.name}</Text>
        <ButtonWrapper ref={ButtonRef}>
          <Button isOver={isOver} color='#595959' onClick={() => modifyClickFn(index)}>수정</Button>
          <Button isOver={isOver} color='#FF3131' onClick={() => deleteClickFn(index)}>삭제</Button>
        </ButtonWrapper>
        </TextWrapper>
        <InfoWrapper isOver={isOver}>
          {
            teacherIdList.map((teacherData, idx) => (
              coachList.length !== 0 && idx < 3 &&
              <InfoBox ref={element => (CoachRef.current[idx] = element)} isOver={isOver}>
                <InfoText isOver={isOver}>
                  {idx === 0 || idx === 1 ? coachList[coachList.findIndex(i => i.id === parseInt(teacherData))].name + '코치' : "외 " + (teacherIdList.length - 2) + "명"}
                </InfoText>
              </InfoBox>
            ))
          }
          <InfoBox ref={TimeRef} isOver={isOver}>
            <InfoText isOver={isOver}>{data.time}분</InfoText>
          </InfoBox>
        </InfoWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

export default ClassInfoBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  width: ${props => props.isOver ? `max(634px, 82.55208333333333vw)` : `max(240px, 75vw)`};
  height: ${props => props.isOver ? `69px` : `59px`};

  border-radius: 15px;
  background-color: #DFDFDF;

  margin: 8px 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(614px, 79.94791666666667vw)` : `max(220px, 68.75vw)`};
  height: ${props => props.isOver ? `67px` : `57px`};

  margin: 1px 1px 1px 0;
  // margin-right: 1px;
  border-radius: 0 15px 15px 0;

  background-color: #FFFFFF;
`;


const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 95%;
  width: ${props => props.isOver ? `max(583px, 75.91145833333333vw)` : `max(187px, 58.4375vw)`};

  margin-top: ${props => props.isOver ? `10px` : `8px`};
`;

const Text = styled.div`
  display: flex;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;

  opacity: 0;

  transition: all 0.3s ease-in-out;
`;

const Button = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
  
  gap: 4px;
  margin: 5px 0 10px 0;

  // width: 95%;
  width: ${props => props.isOver ? `max(583px, 75.91145833333333vw)` : `max(187px, 58.4375vw)`};
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  border-radius: ${props => props.isOver ? `8px` : `4px`};
  background-color: #CBCBCB;

  transition: all 0.3s ease-in-out;
`;

const InfoText = styled.div`
  font-fmaily: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #FFFFFF;

  margin: ${props => props.isOver ? `3px 8px 4px 8px` : `2px 4px 2px 4px`};
`;