import React, { useState } from "react";
import styled from "styled-components";

export default function Image(props) {
  const { src, defaultSrc } = props;

  const [isErrorSrc, setIsErrorSrc] = useState(false);
  
  // 사진 불러오기 에러시 //
  function onError() {
    setIsErrorSrc(true);
  }

  // 화면에 표시할 사진 결정 //
  function decideImageSrc() {
    if (isErrorSrc || src === undefined || src === '') return defaultSrc;
    else return src;
  }

  return (
    <ImageBox src={decideImageSrc()} onError={onError}/>
  );
}


const ImageBox = styled.img`
  width: 100%;
  height: 100%;

  object-fit: contain;
`;