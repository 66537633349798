import { React, useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import CheckBox from '../../../Component/MemberComponent/MemberShipInfoCheckBox.js';
import MemberShipCategorySelectBox from '../../../Component/MemberComponent/MemberShipCategorySelectBox.js';
import MemberShipSelectBox from '../../../Component/MemberComponent/MemberShipSelectBox.js';
import CalenderInput from '../../../Component/MemberComponent/CalenderInput.js';
import PaymentInput from '../../../Component/MemberComponent/PaymentInput.js';

import FetchModule from '../../Share/Network/FetchModule.js';
import PaymentModal from './PaymentModal.js';
import SessionStorage from '../../Share/Network/SessionStorage.js';
// import PaymentButton from '../../../Component/MemberComponent/PaymentButton.js';

const OnSitePaymentMemberShipInfoView = forwardRef((props, ref) => {
  const { data, registerClick, closeFn } = props;
  const history = useHistory();

  const [isUsing, setIsUsing] = useState(false);
  const [categoryData, setCategoryData] = useState(false);
  const [memberShipData, setMemberShipData] = useState(false);
  const [divisionMemberShipData, setDivisionMemberShipData] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const [calcComplete, setCalcComplete] = useState(false);
  const [validity, setValidity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [notReceivedPrice, setNotReceivedPrice] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [approvalDate, setApprovalDate] = useState('');

  const CategorySelectRef = useRef();
  const MemberShipSelectRef = useRef();
  const CardPaymentRef = useRef();
  const CashPaymentRef = useRef();
  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();
  const ApprovalCalenderRef = useRef();

  useImperativeHandle(ref, () => ({
    getIsUsing: () => {
      return isUsing;
    },
    getMemberShipId: () => {
      return MemberShipSelectRef.current.getMemberShipId();
    },
    getMemberShipName: () => {
      return MemberShipSelectRef.current.getValue();
    },
    getStartDate: () => {
      return startDate;
    },
    getEndDate: () => {
      return endDate;
    },
    getMemberShipData: () => {
      return MemberShipSelectRef.current.getObjectVal();
    },
    getCardPrice: () => {
      return CardPaymentRef.current.getValue();
    },
    getCashPrice: () => {
      return CashPaymentRef.current.getValue();
    },
    getNotReceivePrice: () => {
      return (notReceivedPrice - totalPrice) > 0 ? 0 : (notReceivedPrice - totalPrice);
    },
    getApprovalDate: () => {
      return approvalDate;
    }
  }))

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('membership/category/', 'GET');
        
        if (responseData.status === 200) {
          setCategoryData(responseData.data);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();
    (
      async () => {
        const responseData = await fetchModule.getData('membership/', 'GET');
        
        if (responseData.status === 200) {
          setMemberShipData(responseData.data);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();
  }, [history])

  // useEffect(() => {
  //   CategorySelectRef.current.setData(categoryData);
  // }, [categoryData])

  useEffect(() => {
    if (startDate === '' || endDate === '') return;
    else if (calcComplete) return;

    setValidity(moment(endDate).diff(moment(startDate), 'days') - 1);
    setCalcComplete(true);
  }, [endDate, startDate, calcComplete])

  useEffect(() => {
    if (data === undefined) return;

    if (categoryData) CategorySelectRef.current.setValue({id: data.categoryId, name: data.categoryName});
    if (memberShipData) MemberShipSelectRef.current.setValue({id: data.membershipId, name: data.membershipName, validity: data.validity});
    
    setMaxCount(data.totalMax);
    setTotalPrice(data.totalPrice);
    setNotReceivedPrice(data.totalPrice);
    
    CardPaymentRef.current.setValue(data.cardPrice);
    CashPaymentRef.current.setValue(data.cashPrice);
    setEndDate(moment().add(data.validity, 'days').format('YYYY.MM.DD'));

  }, [data, categoryData, memberShipData])

  useEffect(() => {
    if (isUsing) {
      CategorySelectRef.current.setValue({ name: '선택' });
      MemberShipSelectRef.current.setValue({ name: '선택' });
      setStartDate('');
      setEndDate('');
      setApprovalDate('');
    }
    else {
      setStartDate(moment().format('YYYY.MM.DD'));
      setApprovalDate(moment().format('YYYY.MM.DD'));
    }
  }, [isUsing])

  // 시작날짜가 변경될때마다 회원권 종료일 자동 계산 ( 유효기간이 0이거나 시작날짜가 정해지지 않으면 무시 )
  useEffect(() => {
    if (startDate === '' || validity === 0) return;

    setEndDate(moment(startDate, 'YYYY.MM.DD').add(validity, 'days').format('YYYY.MM.DD'));
  }, [startDate, validity])

  function changeValue() {
    var card = CardPaymentRef.current.getValue();
    var cash = CashPaymentRef.current.getValue();

    setTotalPrice(parseInt(card) + parseInt(cash));
  }

  function changeCategorySelectVal(value) {

    var newDataList = [];

    for (var i = 0; i < memberShipData.length; i++) {
      if (memberShipData[i].categoryId === value.id) newDataList.push(memberShipData[i]);
    }

    setDivisionMemberShipData(newDataList);
    MemberShipSelectRef.current.setData(newDataList);
  }

  function changeMemberShipSelectVal(value) {
    setMaxCount(value.totalMax);
    setNotReceivedPrice(value.price);
    setValidity(value.validity - 1);
    CardPaymentRef.current.setValue(value.price);
  }

  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  // 이전 결제예약 내역이 현재 신청내역과 일치하는지 확인 //
  function checkSamePurchasePlan() {

    // 이전에 넣었던 데이터와 현재 결제데이터가 변경점이 있는지 확인
    if (MemberShipSelectRef.current.getObjectVal().id !== data.membershipId) {
      var requestData = {
        merchantUid: data.merchantUid,
        itemName: MemberShipSelectRef.current.getObjectVal().name
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/change/item', 'POST', requestData);
          
          if (responseData.status === 200) {
            registerClick();
          }
          else if (responseData.status === 401) {
            alert('로그인 후 다시시도하세요');
            history.push('/');
          }
          else {
            alert('결제내역 변경에 실패하였습니다.\n잠시후 다시시도해주세요.');
            return;
          }
        }
      )();
    }
    else {
      registerClick();
    }
  }

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <MemberShipTitle>회원권 정보</MemberShipTitle>
        <ExitButton onClick={closeFn}>닫기</ExitButton>
      </TitleWrapper>
      <InfoBox>
        <FirstLayerWrapper>
          <ComponentWrapper>
            <ComponentTitle>카테고리</ComponentTitle>
            <MemberShipCategorySelectBox ref={CategorySelectRef} disabled={isUsing} data={categoryData} changeFn={changeCategorySelectVal}/>
          </ComponentWrapper>
          <ComponentWrapper>
            <ComponentTitle>회원권</ComponentTitle>
            <MemberShipSelectBox ref={MemberShipSelectRef} disabled={isUsing} data={divisionMemberShipData} changeFn={changeMemberShipSelectVal}/>
          </ComponentWrapper>
        </FirstLayerWrapper>
        <SecondLayerWrapper>
          <ComponentWrapper style={{marginRight: `max(24px, 3.125vw)`}}>
            <ComponentTitle>회원권 시작일</ComponentTitle>
            <CalenderInput ref={StartCalenderRef} disabled={isUsing} value={startDate} setValue={setStartDate} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width='max(194px, 25.26041666666667vw)'/>
          </ComponentWrapper>
          <ComponentWrapper style={{marginRight: `max(24px, 3.125vw)`}}>
            <ComponentTitle>회원권 종료일</ComponentTitle>
            <CalenderInput ref={EndCalenderRef} disabled={isUsing} value={endDate} setValue={setEndDate} className='EndCalender' clickFn={CalenderClick} placeholder='종료일' width='max(194px, 25.26041666666667vw)'/>
          </ComponentWrapper>
          <ComponentWrapper>
            <ComponentTitle>최대 수강 횟수</ComponentTitle>
            <FixedInputWrapper>
              <FixedInput>{maxCount === -1 ? '무제한' : maxCount}</FixedInput>
              <FixedUnit>회</FixedUnit>
            </FixedInputWrapper>
          </ComponentWrapper>
        </SecondLayerWrapper>
      </InfoBox>
      <TitleWrapper style={{marginTop: `24px`}}>
        <MemberShipTitle>결제 정보</MemberShipTitle>
      </TitleWrapper>
      <InfoBox style={{ width: `max(636px, 82.8125vw)` }}>
        <SecondLayerWrapper>
          <ComponentWrapper style={{marginRight: `max(24px, 3.125vw)`}}>
            <ComponentTitle>총 결제 금액</ComponentTitle>
            <CalcPaymentInputWrapper>
              <CalcPaymentInput>{totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</CalcPaymentInput>
              <CalcPaymentUnit>원</CalcPaymentUnit>
            </CalcPaymentInputWrapper>
          </ComponentWrapper>
          <ComponentWrapper style={{marginRight: `max(24px, 3.125vw)`}}>
            <ComponentTitle>카드 결제 금액</ComponentTitle>
            <PaymentInput ref={CardPaymentRef} changeFn={changeValue} width='max(166px, 21.61458333333333vw)' placeholder={0} disabled={isUsing}/>
          </ComponentWrapper>
          <ComponentWrapper>
            <ComponentTitle>현금 결제 금액</ComponentTitle>
            <PaymentInput ref={CashPaymentRef} changeFn={changeValue} width='max(166px, 21.61458333333333vw)' placeholder={0} disabled={isUsing}/>
          </ComponentWrapper>
        </SecondLayerWrapper>
        <SecondLayerWrapper>
          <ComponentWrapper style={{ marginRight: `max(24px, 3.125vw)` }}>
            <ComponentTitle>미수금 금액</ComponentTitle>
            {/* <PaymentInput ref={NotReceivedPaymentRef} width={252} placeholder={0} disabled={isUsing} /> */}
            <CalcPaymentInputWrapper>
              <CalcPaymentInput>{(notReceivedPrice - totalPrice) > 0 ? (notReceivedPrice - totalPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</CalcPaymentInput>
              <CalcPaymentUnit>원</CalcPaymentUnit>
            </CalcPaymentInputWrapper>
          </ComponentWrapper>
          <ComponentWrapper style={{marginRight: `max(24px, 3.125vw)`}}>
            <ComponentTitle>결제일</ComponentTitle>
            <CalenderInput ref={ApprovalCalenderRef} disabled={isUsing} value={approvalDate} setValue={setApprovalDate} className='PaymentCalender' clickFn={CalenderClick} placeholder='결제일' width='max(194px, 25.26041666666667vw)'/>
          </ComponentWrapper>
          <ComponentWrapper style={{marginTop: `26px`}}>
            <DirectDebitButton onClick={checkSamePurchasePlan}>
              <DirectDebitText>등록하기</DirectDebitText>
            </DirectDebitButton>
          </ComponentWrapper>
        </SecondLayerWrapper>
      </InfoBox>
    </TopLevelWrapper>
  )
})

export default OnSitePaymentMemberShipInfoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: max(692px, 90.10416666666667vw);
  height: 492px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  margin-top: 38px;
  margin-bottom: 96px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: center;

  // gap: 16px;

  width: max(636px, 82.8125vw);
`;

const MemberShipTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ExitButton = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  // align-items: center;

  border-top: 2px solid #ECECEC;

  margin-top: 24px;
  // margin-left: 28px;
`;

const FirstLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 24px;
  margin-top: 24px;
`;

const SecondLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // gap: 24px;
  margin-top: 24px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const CalcPaymentInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
  margin-top: 8px;
`;

const CalcPaymentInput = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 8px;
  margin: 0 0 0 0;

  width: max(166px, 21.61458333333333vw);
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const CalcPaymentUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #777777;
`;

const DirectDebitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 168px;
  height: 32px;

  border-radius: 8px;
  // background-color: #33C8FF;
  background-color: #5BD98A;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const DirectDebitText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const FixedInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const FixedInput = styled.div`
  display: flex;
  flex-direction: column;

  width: max(166px, 21.61458333333333vw);
  height: 30px;

  justify-content: center;
  align-items: flex-start;

  margin: 8px 0 0 0;
  padding: 0 0 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const FixedUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 8px;

  color: #777777;
`;