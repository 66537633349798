import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import NormalSortButtonPath from '../../../image/NormalSortButton.png';
import AscSortButtonPath from '../../../image/AscSortButton.png';
import DescSortButtonPath from '../../../image/DescSortButton.png';

import NextButtonImage from '../../../image/NormalNextInfoButton.png';
import ColorPrevButtonImage from '../../../image/ColorPrevInfoButton.png';

const SecondExpriedColumnView = (props) => {
  const { prevFn, startSort, setStartSort, endSort, setEndSort, classSort, setClassSort } = props;

  const [startButtonImg, setStartButtonImg] = useState(NormalSortButtonPath);
  const [endButtonImg, setEndButtonImg] = useState(NormalSortButtonPath);
  const [classButtonImg, setClassButtonImg] = useState(NormalSortButtonPath);

  // const [startDateSort, setStartDateSort] = useState('Normal');
  // const [endDateSort, setEndDateSort] = useState('Normal');
  // const [classSort, setClassSort] = useState('Normal');

  // 시작일 버튼 클릭시 이미지 변경 //
  useEffect(() => {
    if (startSort === 'normal') setStartButtonImg(NormalSortButtonPath);
    else if (startSort === 'asc') setStartButtonImg(AscSortButtonPath);
    else if (startSort === 'desc') setStartButtonImg(DescSortButtonPath);
  }, [startSort])

  // 종료일 버튼 클릭시 이미지 변경 //
  useEffect(() => {
    if (endSort === 'normal') setEndButtonImg(NormalSortButtonPath);
    else if (endSort === 'asc') setEndButtonImg(AscSortButtonPath);
    else if (endSort === 'desc') setEndButtonImg(DescSortButtonPath);
  }, [endSort])

  // 수업 버튼 클릭시 이미지 변경 //
  useEffect(() => {
    if (classSort === 'normal') setClassButtonImg(NormalSortButtonPath);
    else if (classSort === 'asc') setClassButtonImg(AscSortButtonPath);
    else if (classSort === 'desc') setClassButtonImg(DescSortButtonPath);
  }, [classSort])

  // 시작일 정렬 버튼 클릭시 //
  function onClickStartSort() {
    setEndSort('normal');
    setClassSort('normal');

    if (startSort === 'normal' || startSort === 'desc') setStartSort('asc');
    else if (startSort === 'asc') setStartSort('desc');
  }

  // 종료일 정렬 버튼 클릭시 //
  function onClickEndSort() {
    setStartSort('normal');
    setClassSort('normal');

    if (endSort === 'normal' || endSort === 'desc') setEndSort('asc');
    else if (endSort === 'asc') setEndSort('desc');
  }

  // 수업 정렬버튼 클릭시 //
  function onClickClassSort() {
    setStartSort('normal');
    setEndSort('normal');

    if (classSort === 'normal' || classSort === 'desc') setClassSort('asc');
    else if (classSort === 'asc') setClassSort('desc');
  }

  return (
    <TopLevelWrapper>
      <ColumnWrapper>
        <SortingWrapper>
          <Column style={{ marginRight: '4px', marginLeft: 'max(15px, 1.953125vw)' }}>시작일</Column>
          <SortingButton img={startButtonImg} onClick={onClickStartSort} style={{ marginRight: 'max(35px, 4.557291666666667vw)' }} />
        </SortingWrapper>
        <SortingWrapper>
          <Column style={{ marginRight: '4px' }}>종료일</Column>
          <SortingButton img={endButtonImg} onClick={onClickEndSort} style={{ marginRight: 'max(35px, 4.557291666666667vw)' }} />
        </SortingWrapper>
        <SortingWrapper>
          <Column style={{ marginRight: '4px' }}>수업</Column>
          <SortingButton img={classButtonImg} onClick={onClickClassSort} style={{ marginRight: 'max(147px, 19.140625vw)' }} />
        </SortingWrapper>
      </ColumnWrapper>
      <ButtonWrapper>
        <Button src={ColorPrevButtonImage} onClick={() => prevFn()}/>
        <Column>이전 정보</Column>
        <Button src={NextButtonImage}/>
      </ButtonWrapper>
    </TopLevelWrapper>
  )
}

export default SecondExpriedColumnView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: max(664px, 86.45833333333333vw);
  margin-top: 38px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SortingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const SortingButton = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Button = styled.img`
  width: 24px;
  height: 24px;

  border-radius: 24px;

  :hover {
    cursor: pointer;
  }
`;