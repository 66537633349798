import { React } from 'react';
import styled from 'styled-components';

const LockerHistoryColumnView = (props) => {
  const { isOver } = props;

  return (
    <TopLevelWrapper isOver={isOver}>
      {
        isOver ?
          <ColumnWrapper>
            <Column style={{ marginRight: 'max(53px, 6.901041666666667vw)', marginLeft: 'max(28px, 3.645833333333333vw)' }} isOver={isOver}>락커번호</Column>
            {/* <Column style={{ marginRight: 'max(247px, 32.16145833333333vw)' }} isOver={isOver}>기간</Column> */}
            <Column style={{ marginRight: 'max(188px, 24.4791666vw)' }} isOver={isOver}>기간</Column>
            <Column style={{ marginRight: 'max(95px, 12.3697916666vw)'}} isOver={isOver}>상태</Column>
            <Column isOver={isOver}>승인날짜</Column>
          </ColumnWrapper>
          :
          <ColumnWrapper>
            <Column style={{ marginRight: 'max(13px, 6.901041666666667vw)', marginLeft: 'max(3px, 0.9375vw)' }} isOver={isOver}>락커번호</Column>
            {/* <Column style={{ marginRight: 'max(74px, 23.125vw)' }} isOver={isOver}>기간</Column> */}
            <Column style={{ marginRight: 'max(67px, 20.9375vw)' }} isOver={isOver}>기간</Column>
            <Column style={{ marginRight: 'max(19px, 5.9375vw)'}} isOver={isOver}>상태</Column>
            <Column isOver={isOver}>승인날짜</Column>
          </ColumnWrapper>
      }
    </TopLevelWrapper>
  )
}

export default LockerHistoryColumnView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: ${props => props.isOver ? `center` : `flex-start`};

  margin-top: ${props => props.isOver ? `34px` : `23px`};
  margin-bottom: ${props => props.isOver ? `18px` : `15px`};

  width: ${props => props.isOver ? `max(690px, 89.84375vw)` : `max(262px, 81.875vw)`};
  
  transition: all 0.3s ease-in-out;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  // align-items: center;

  justify-content: flex-start;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;
