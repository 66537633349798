import { React, forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import TableSetting from './TableSetting';
import TableView from './ScheduleView';
import FetchModule from '../../Share/Network/FetchModule';

const ScheduleSettingView = forwardRef((props, ref) => {
  const history = useHistory();

  const [classData, setClassData] = useState([]);

  const ViewRef = useRef();

  useImperativeHandle(ref, () => ({
    setStartThisWeek: (val) => {
      ViewRef.current.setStartThisWeek(val);
    }
  }))

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('lesson/', 'GET');
       
        if (responseData.status === 200) {
          setClassData(responseData.data);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
        else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();
  }, [history])

  function changeTitleInput(value) {
    ViewRef.current.setTitle(value);
  }

  function changeLogoInput(value) {
    ViewRef.current.setLogo(value);
  }

  function changeSelectWeek(value) {
    ViewRef.current.setDateValue(value);
  }
  
  function changeExcludedClass(value) {
    console.log(value);
  }

  function changeBackground(value) {
    ViewRef.current.setBackgroundColor(value);
  }
  
  function ratioSelectClick(value) {
    ViewRef.current.setPaperSetting(value);
  }

  function saveJpegClick() {
    ViewRef.current.downloadScheduleJpeg();
  }
  
  function printJpegClick() {
    ViewRef.current.printSchedule();
  }

  return (
    <TopLevelWrapper>
      <TableView ref={ViewRef}/>
      <TableSetting
        classData={classData}
        titleChangeFn={changeTitleInput}
        logoChangeFn={changeLogoInput} 
        weekChangeFn={changeSelectWeek}
        excludedClassChangeFn={changeExcludedClass}
        backgroundChangeFn={changeBackground}
        ratioSelectClickFn={ratioSelectClick}
        saveClickFn={saveJpegClick}
        printClickFn={printJpegClick}/>
    </TopLevelWrapper>
  )
})

export default ScheduleSettingView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 51px;
  margin-top: 21px;
`;