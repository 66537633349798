import { React, useState, useEffect } from 'react';
import styled from 'styled-components'; 

import { loadTossPayments } from '@tosspayments/payment-sdk';

import FetchModule from '../../Share/Network/FetchModule.js';

import ProductLogo from '../../../image/ProductLogo.png';
import ExitButtonImg from '../../../image/BlackDeleteButton.png';
import TossLogoImg from '../../../image/TossLogo.png';

function PaymentModal(props) {
  const { name, validity, price, cashPrice, merchantUid, onRegist, cancel, saveRegistData } = props;

  const [isClicked, setIsClicked] = useState(false);
  const [normalPaymentClick, setNormalPaymentClick] = useState(false);

  // 테스트 클라이언트 키 //
  // const clientKey = 'test_ck_dP9BRQmyarYAYvaBWd9rJ07KzLNk';
  // 라이브 클라이언트 키 (일반 결제) //
  const normalClientKey = 'live_ck_dP9BRQmyarYOKL40JyW3J07KzLNk';
  // 라이브 클라이언트 키 (빌링키)//
  const billingClientKey = 'live_ck_4vZnjEJeQVxmOL22lRqrPmOoBN0k';

  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  useEffect(() => {
    const jquery = document.createElement('script');
    jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";

    const iamport = document.createElement('script');
    iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.2.0.js";

    document.head.appendChild(jquery);
    document.head.appendChild(iamport);

    return () => {
      document.head.removeChild(jquery);
      document.head.removeChild(iamport);
    }
  }, []);

  async function onClickPayment(className) {
    
    // if (className === 'toss') {
    // (
    //   async () => {
    //     const fetchModule = new FetchModule();
    //     const response = await fetchModule.postData('sales/change/merchant/' + merchantUid + "/normal/" + price, 'POST');
    //     // console.log(response.data);
    //     if (response.status === 200) {
    //       const { IMP } = window;
    //       IMP.init('imp22047599');

    //       setIsClicked(true);

    //       // Payment Data
    //       const data = {
    //         pg: 'tosspay',
    //         pay_method: 'card',
    //         name: name,
    //         amount: price,
    //         // amount: '100',
    //         merchant_uid: merchantUid
    //       }

    //       IMP.request_pay(data, callback);
    //     }
    //     else if (response.status === 201) {
    //       // 데이터 없음
    //       alert("유효한 데이터가 아닙니다.");
    //       return;
    //     }
    //     else if (response.status === 202) {
    //       // 이미 처리된 데이터
    //       alert("이미 결제가 완료되었습니다.");
    //       return;
    //     }
    //     else if (response.status === 203) {
    //       // 현금결제가 같이있는 경우
    //       alert("복합결제( 현금결제가 포함된 경우 )는 결제 서비스 이용이 불가능합니다.");
    //       return;
    //     }
    //     else if (response.status === 204) {
    //       alert("결제서비스는 31일 이하의 회원권에만 적용됩니다!");
    //       return;
    //     }
    //     else if (response.status === 205) {
    //       alert("이미 진행중인 정기결제 내역이 존재합니다.");
    //       return;
    //     }
    //     else if (response.status === 207) {
    //       alert("결제 시스템을 지원하지 않습니다!\n결제시스템을 이용하시려면 마이페이지에서 설정해주세요!");
    //       return;
    //     }
    //     else {
    //       // 데이터 없음
    //       alert("유효한 데이터가 아닙니다.");
    //       return;
    //     }
    //   }
    // )();
    // }
    // else {
      // var data = window.sessionStorage.getItem("RegistData");
      // console.log(JSON.parse(data));
      const tossPayments = await loadTossPayments(normalClientKey);

      (
        async () => {
          const fetchModule = new FetchModule();
          const response = await fetchModule.postData('sales/change/merchant/' + merchantUid + "/normal/" + price, 'POST');
          console.log(response.data);
          if (response.status === 200) {
            saveRegistData(merchantUid);

            tossPayments.requestPayment('카드', { // 결제 수단 파라미터
              // 결제 정보 파라미터
              amount: price,
              orderId: merchantUid,
              orderName: name,
              successUrl: 'https://m.link-zone.io/payment/complete/success',
              failUrl: 'https://m.link-zone.io/payment/complete/fail',
            }).catch(function (error) {
              if (error.code === 'USER_CANCEL') {
                alert("결제 진행중에 취소하셨습니다.");
              }
            })
          }
          else if (response.status === 201) {
            // 데이터 없음
            alert("유효한 데이터가 아닙니다.");
            return;
          }
          else if (response.status === 202) {
            // 이미 처리된 데이터
            alert("이미 결제가 완료되었습니다.");
            return;
          }
          else if (response.status === 203) {
            // 현금결제가 같이있는 경우
            alert("복합결제( 현금결제가 포함된 경우 )는 결제 서비스 이용이 불가능합니다.");
            return;
          }
          else if (response.status === 204) {
            alert("결제서비스는 31일 이하의 회원권에만 적용됩니다!");
            return;
          }
          else if (response.status === 205) {
            alert("이미 진행중인 정기결제 내역이 존재합니다.");
            return;
          }
          else if (response.status === 207) {
            alert("결제 시스템을 지원하지 않습니다!\n결제시스템을 이용하시려면 마이페이지에서 설정해주세요!");
            return;
          }
          else {
            // 데이터 없음
            alert("유효한 데이터가 아닙니다.");
            return;
          }
        }
      )();
    // }
  }

  async function onClickRegularPayment() {
    // var data = window.sessionStorage.getItem("RegistData");
    // console.log(JSON.parse(data));
    const tossPayments = await loadTossPayments(billingClientKey);

    (
      async () => {
        const fetchModule = new FetchModule();
        const response = await fetchModule.postData('sales/change/merchant/' + merchantUid + "/regular/" + price, 'POST');
        // console.log(response.data);
        if (response.status === 200) {
          saveRegistData(response.data[0].merchantUid);
            
          tossPayments.requestBillingAuth('카드', { // 결제 수단 파라미터
            // 결제 정보 파라미터
            customerKey: response.data[0].userId,
            successUrl: 'https://m.link-zone.io/payment/subscribe/success',
            failUrl: 'https://m.link-zone.io/payment/subscribe/fail',
          }).catch(function (error) {
            if (error.code === 'USER_CANCEL') {
              alert("결제 진행중에 취소하셨습니다.");
            }
          })
        }
        else if (response.status === 201) {
          // 데이터 없음
          alert("유효한 데이터가 아닙니다.");
          return;
        }
        else if (response.status === 202) {
          // 이미 처리된 데이터
          alert("이미 결제가 완료되었습니다.");
          return;
        }
        else if (response.status === 203) {
          // 현금결제가 같이있는 경우
          alert("복합결제( 현금결제가 포함된 경우 )는 결제 서비스 이용이 불가능합니다.");
          return;
        }
        else if (response.status === 204) {
          alert("정기결제는 31일 이하의 회원권에만 적용됩니다!");
          return;
        }
        else if (response.status === 205) {
          alert("이미 진행중인 정기결제 내역이 존재합니다.");
          return;
        }
        else if (response.status === 206) {
          alert("정기결제를 지원하지 않는 회원권 입니다!");
          return;
        }
        else if (response.status === 207) {
          alert("결제 시스템을 지원하지 않습니다!\n결제시스템을 이용하시려면 마이페이지에서 설정해주세요!");
          return;
        }
        else {
          // 데이터 없음
          alert("유효한 데이터가 아닙니다.");
          return;
        }
      }
    )();
  }

  function onClickOnSitePayment() {
    const fetchModule = new FetchModule();
    (
      async () => {
        const changeResponseData = await fetchModule.postData('sales/change/merchant/' + merchantUid + "/onSite/" + price, 'POST');
       
        if (changeResponseData.status === 200) {
          const responseData = await fetchModule.postData('sales/onSite/effectiveness/' + changeResponseData.data[0].merchantUid + '/' + price + '/' + cashPrice, 'POST');

          // console.log(responseData);
          if (responseData.status === 200) {
            onRegist(changeResponseData.data[0].merchantUid);
          }
          else {
            cancel();
          }
        }
      }
    )();
  }

  function callback(response) {
    const { success, error_msg, imp_uid, merchant_uid, pay_method, paid_amount, status } = response;

    if (success) {
      console.log('error_msg : ' + error_msg);
      console.log('imp_uid : ' + imp_uid);
      console.log('merchant_uid : ' + merchant_uid);
      console.log('pay_method : ' + pay_method);
      console.log('paid_amount : ' + paid_amount);
      console.log('status : ' + status);

      var requestData = {
        imp_uid: imp_uid,
        merchant_uid: merchant_uid
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/effectiveness', 'POST', requestData);

          // console.log(responseData);
          if (responseData.status === 200) {
            onRegist(merchant_uid);
          }
          else {
            cancel();
          }
        }
      )();
    }
    else {
      console.log('error_msg : ' + error_msg);
      console.log('imp_uid : ' + imp_uid);
      console.log('merchant_uid : ' + merchant_uid);
      console.log('pay_method : ' + pay_method);
      console.log('paid_amount : ' + paid_amount);
      console.log('status : ' + status);
    }

    setIsClicked(false);
  }

  return (
    <TopLevelWrapper>
      <ModalView isClicked={isClicked}>
        <TotalWrapper>
          <TitleLineWrapper>
            <Title>상품 정보</Title>
            <ExitButton src={ExitButtonImg} onClick={() => cancel()}/>
          </TitleLineWrapper>
          <ProductInfoBox>
            <Logo src={ProductLogo}/>
            <TextWrapper>
              <TextEng>Link Zone</TextEng>
              <TextKor>{name}</TextKor>
              <TextExplanation>{validity} 일</TextExplanation>
            </TextWrapper>
          </ProductInfoBox>
          <PriceWrapper>
            <Title>최종 결제 금액</Title>
            <LayerWrapper style={{ marginTop: `24px` }}>
              <TextNormal style={{ color: `#595959` }}>결제 금액</TextNormal>
              <DivisionLine/>
              <TextNormal style={{ color: `#595959` }}>{(parseInt(price) + parseInt(cashPrice)).toLocaleString('ko-KR')}원</TextNormal>
            </LayerWrapper>
            <LayerWrapper style={{ marginTop: `8px` }}>
              <TextBold style={{ color: `#232323` }}>최종 금액</TextBold>
              <DivisionLine/>
              <TextBold style={{ color: `#FF8B48` }}>{(parseInt(price) + parseInt(cashPrice)).toLocaleString('ko-KR')}원</TextBold>
            </LayerWrapper>
          </PriceWrapper>
          {
            normalPaymentClick ?
              <ButtonWrapper>
                <NormalPayment onClick={() => onClickPayment('jtnet')}>카드결제</NormalPayment>
                <NormalPayment onClick={() => onClickPayment('toss')}>
                  <TossLogo src={TossLogoImg} />
                </NormalPayment>
              </ButtonWrapper>
              :
              <ButtonWrapper>
                <NormalPayment onClick={() => setNormalPaymentClick(true)}>일반 결제</NormalPayment>
                <RegularPayment onClick={onClickRegularPayment}>정기 결제</RegularPayment>
              </ButtonWrapper>
          }
          <OnSiteWrapper>
            <OnSitePayment onClick={onClickOnSitePayment}>현장 결제</OnSitePayment>
          </OnSiteWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default PaymentModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);

  transition: all 0.3s ease-in-out;
`;

const ModalView = styled.div`
  display: ${props => props.isClicked ? `none` : `flex`};
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 378px;
  // height: 416px;
  height: 454px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 322px;
`;

const TitleLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ExitButton = styled.img`
  width: 16px;
  height: 16px;

  :hover {
    cursor: pointer;
  }
`;

const ProductInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 16px;
  margin: 24px 0 0 0;
  padding: 0 16px 0 16px;

  width: 290px;
  height: 100px;

  border-radius: 15px;
  background-color: #FF8B48;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TextEng = styled.p`
  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const TextKor = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const TextExplanation = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: rgba(255, 255, 255, 0.5);

  margin: 0 0 0 0;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin: 24px 0 0 0;
  padding: 24px 0 0 0;

  width: 322px;

  border-top: 1px solid #ECECEC;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TextBold = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  // color: #232323;

  margin: 0 0 0 0;
`;

const TextNormal = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #232323;

  margin: 0 0 0 0;
`;

const DivisionLine = styled.div`
  width: 180px;
  height: 1px;

  border-top: 1px dashed #ECECEC;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
  margin-top: 36px;
`;

const OnSiteWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
`;

const OnSitePayment = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 290px;
  width: 100%;
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #BDBDBD;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #232323;
    color: #232323;
  }
`;

const NormalPayment = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 153px;
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #BDBDBD;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #232323;
    color: #232323;
  }
`;

const RegularPayment = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 155px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const TossLogo = styled.img`
  width: 55.38px;
  height: 32px;
`;