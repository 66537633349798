import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import PrevButtonImage from '../../../../image/NormalPrevInfoButton.png';
import ColorNextButtonImage from '../../../../image/ColorNextInfoButton.png';

const FirstCourseHistoryColumnView = (props) => {
  const { isOver, nextFn } = props;

  return (
    <TopLevelWrapper isOver={isOver}>
      {
        isOver ?
          <ColumnWrapper>
            <Column style={{ marginRight: 'max(28px, 3.645833333333333vw)', marginLeft: 'max(28px, 3.645833333333333vw)'}} isOver={isOver}>번호</Column>
            <Column style={{ marginRight: 'max(63px, 8.203125vw)' }} isOver={isOver}>일시</Column>
            <Column style={{ marginRight: 'max(218px, 28.38541666666667vw)' }} isOver={isOver}>수업</Column>
            <Column style={{ marginRight: 'max(112px, 14.58333333333333vw)' }} isOver={isOver}>시간</Column>
          </ColumnWrapper>
          :
          <ColumnWrapper>
            <Column style={{ marginRight: 'max(24px, 7.5vw)' }} isOver={isOver}>번호</Column>
            <Column style={{ marginRight: 'max(34px, 10.625vw)' }} isOver={isOver}>일시</Column>
            <Column style={{ marginRight: 'max(44px, 13.75vw)' }} isOver={isOver}>수업</Column>
            <Column isOver={isOver}>시간</Column>
          </ColumnWrapper>
      }
      {
        isOver ?
          <ButtonWrapper isOver={isOver}>
            <Button src={PrevButtonImage} isOver={isOver}/>
            <Column isOver={isOver}>다음 정보</Column>
            <Button src={ColorNextButtonImage} isOver={isOver} onClick={nextFn} />
          </ButtonWrapper>
          :
          <ButtonWrapper isOver={isOver}>
            <Button src={PrevButtonImage} isOver={isOver}/>
            <Button src={ColorNextButtonImage} isOver={isOver} onClick={nextFn} />
          </ButtonWrapper>
      }
    </TopLevelWrapper>
  )
}

export default FirstCourseHistoryColumnView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: ${props => props.isOver ? `34px` : `23px`};
  margin-bottom: ${props => props.isOver ? `18px` : `15px`};

  width: ${props => props.isOver ? `max(664px, 86.45833333333333vw)` : `max(262px, 81.875vw)`};
  
  transition: all 0.3s ease-in-out;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `4px` : `6px`};
`;

const Button = styled.img`
  width: ${props => props.isOver ? `24px` : `15px`};
  height: ${props => props.isOver ? `24px` : `15px`};

  border-radius: ${props => props.isOver ? `24px` : `15px`};

  :hover {
    cursor: pointer;
  }
`;