import { React, useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

import moment from 'moment';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import LeftArrowNotHover from '../../../image/LeftArrowNotHover.png'; // 왼쪽 화살표 호버 아닌경우
import RightArrowNotHover from '../../../image/RightArrowNotHover.png'; // 왼쪽 화살표 호버 아닌경우
import CalenderBasic from '../../../image/calendarB.png'//캘린더 기본
import CalenderClick from '../../../image/calendarC.png'
import CalenderHover from '../../../image/calendarH.png'

const DateControlModel = forwardRef((props, ref) => {
  const { clickFn, onClickLeft, onClickRight } = props;

  const [today, setToday] = useState(moment());
  const [startThisWeek, setStartThisWeek] = useState(moment());

  const CalenderButtonRef = useRef();
  const LeftMoveRef = useRef();
  const RightMoveRef = useRef();

  useEffect(() => {
    for (var i = 0; i < 7; i++) {
      if(today.clone().subtract(i, 'day').format('dd') === '일') {
        setStartThisWeek(today.clone().subtract(i, 'day'));
        break;
      }
    }
    clickFn(today.clone().subtract(i, 'day'));
  }, [today])

  function onMouseOver() {
    CalenderButtonRef.current.style.border = `1px solid #777777`;
    CalenderButtonRef.current.style.background = `url(${CalenderHover}) no-repeat center center`;
  }

  function onMouseLeave() {
    CalenderButtonRef.current.style.border = `1px solid #DFDFDF`;
    CalenderButtonRef.current.style.background = `url(${CalenderBasic}) no-repeat center center`;
  }
  
  function leftMouseOver() {
    LeftMoveRef.current.style.border = `1px solid #777777`;
    LeftMoveRef.current.style.background = `url(${LeftArrow}) no-repeat center center`;
  }

  function leftMouseLeave() {
    LeftMoveRef.current.style.border = `1px solid #DFDFDF`;
    LeftMoveRef.current.style.background = `url(${LeftArrowNotHover}) no-repeat center center`;
  }
  
  function rightMouseOver() {
    RightMoveRef.current.style.border = `1px solid #777777`;
    RightMoveRef.current.style.background = `url(${RightArrow}) no-repeat center center`;
  }

  function rightMouseLeave() {
    RightMoveRef.current.style.border = `1px solid #DFDFDF`;
    RightMoveRef.current.style.background = `url(${RightArrowNotHover}) no-repeat center center`;
  }

  return (
    <TopLevelWrapper>
      <DateButtonWrapper>
        <ArrowButton img={LeftArrow} onClick={() => setToday(today.clone().subtract(1, 'week'))}/>
        <DateText>{startThisWeek.clone().format('YYYY년 MM월 DD일')} - {startThisWeek.clone().add(6, 'day').format('DD일')}</DateText>
        <ArrowButton img={RightArrow} onClick={() => setToday(today.clone().add(1, 'week'))}/>
      </DateButtonWrapper>
      <ThisWeekButton onClick={() => setToday(moment())}>이번주</ThisWeekButton>
      <CalenderButton ref={CalenderButtonRef} img={CalenderBasic} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}/>
      <PageMoveButtonWrapper>
        <MoveButton ref={LeftMoveRef} img={LeftArrowNotHover} onClick={onClickLeft} onMouseOver={leftMouseOver} onMouseLeave={leftMouseLeave}/>
        <MoveButton ref={RightMoveRef} img={RightArrowNotHover} onClick={onClickRight} onMouseOver={rightMouseOver} onMouseLeave={rightMouseLeave}/>
      </PageMoveButtonWrapper>
    </TopLevelWrapper>
  )
})

export default DateControlModel;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;
  margin-top: 38px;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 219px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  gap: 8px;
`;

const ArrowButton = styled.div`
  display: flex;
  flex-direction: row;

  width: 22px;
  height: 22px;

  background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ThisWeekButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 65px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const CalenderButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const PageMoveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;

  margin-left: 216px;
`;

const MoveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;

  background-color: #FFFFFF;
  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;
