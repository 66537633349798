import { React } from 'react';
import styled from 'styled-components';

const RegistrationTopView = (props) => {
  const { registerClick } = props;

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <Title>회원 등록</Title>
        <Explanation>신규 회원을 등록할 수 있어요</Explanation>
      </TitleWrapper>
      <ButtonWrapper>
        <RegisterButton onClick={() => registerClick()}>
          등록하기
        </RegisterButton>
      </ButtonWrapper>
    </TopLevelWrapper>
  )
}

export default RegistrationTopView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: center;

  margin-left: max(28px, 3vw);
  margin-bottom: 28px;
  
  width: max(664px, 85.6vw);
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const RegisterButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;
