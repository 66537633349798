import { React, forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';

const WeekClick = forwardRef((props, ref) => {
  const { isOver } = props;
  const [weekData, setWeekData] = useState(
    [
      {
        name: `일`,
        isClicked: false
      },
      {
        name: `월`,
        isClicked: false
      },
      {
        name: `화`,
        isClicked: false
      },
      {
        name: `수`,
        isClicked: false
      },
      {
        name: `목`,
        isClicked: false
      },
      {
        name: `금`,
        isClicked: false
      },
      {
        name: `토`,
        isClicked: false
      },
    ]
  )

  useImperativeHandle(ref, () => ({
		getValue: () => {
      var cnt = 0;
      var value = '';

      for (var i = 0; i < 7; i++) {
        if (weekData[i].isClicked) {
          cnt += 1;

          if (i === 0) value += 'Sun,';
          else if (i === 1) value += 'Mon,'
          else if (i === 2) value += 'Tue,'
          else if (i === 3) value += 'Wed,'
          else if (i === 4) value += 'Thu,'
          else if (i === 5) value += 'Fri,'
          else if (i === 6) value += 'Sat,'
        }
      }

      if (cnt === 7) return 'All';
      else return value;
		},
	}));

  function onClickWeekButton(index) {
    var newData = weekData.slice();

    if (newData[index].isClicked) newData[index].isClicked = false;
    else newData[index].isClicked = true;

    setWeekData(newData);
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      {
        weekData.map((data, index) => (
          <WeekButton key={index} isOver={isOver} isClicked={data.isClicked} onClick={() => onClickWeekButton(index)}>{data.name}</WeekButton>
        ))
      }
    </TopLevelWrapper>
  )
})

export default WeekClick;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `9px` : `6px`};
  margin-top: 8px;
`;

const WeekButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `30px` : `26px`};
  height: ${props => props.isOver ? `30px` : `26px`};

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF` };
  border-radius: ${props => props.isOver ? `8px` : `6px`};
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF` };

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: ${props => props.isClicked ?  `#FFFFFF` : `#CBCBCB` };
  
  transition: all 0.3s ease-in-out;

  // :hover {
  //   border: 1px solid #FF8B48;
  //   background-color: #FF8B48;
  //   color: #FFFFFF;
  // }
`;