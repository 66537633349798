import { React, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import FetchModule from '../../../Share/Network/FetchModule';

function ReservationHistoryModal(props) {
  const { isOver, data, cancel, startFn, closeFn, scheduleData } = props;

  const history = useHistory();

  const [memberList, setMemberList] = useState([]);
  const [useMemberList, setUseMemberList] = useState([]);


  const [state, setState] = useState('예약중');
  const [color, setColor] = useState('#FF8B48');

  useEffect(() => {
    // 회원 예약창 종료
    startFn();

    // 스크롤 고정
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  })
  
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('member/time-line/', 'GET');
        
        if (responseData.status === 200) setMemberList(responseData.data);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [])

  useEffect(() => {
    var newReserveData = [];

    for (var i = 0; i < memberList.length; i++) {

      for (var j = 0; j < data.length; j++) {
        if (memberList[i].userId === data[j].userId) {
          // console.log(data[j]);
          memberList[i].lessonReserveId = data[j].id;
          newReserveData.push(memberList[i]);
          break;
        }
      }
    }

    setUseMemberList(newReserveData);
  }, [memberList, data])

  useEffect(() => {
    console.log(scheduleData);
    var start = moment(scheduleData.classDate.replaceAll('.', '-') + 'T' + scheduleData.classTime.split('-')[0] + '00');
    var end = moment(scheduleData.classDate.replaceAll('.', '-') + 'T' + scheduleData.classTime.split('-')[1] + '00');
    var now = moment();

    if (start.diff(now) > 0) setState('예약중');
    else if (now.diff(end) > 0) setState('수업중');
    else setState('수업 종료');
  }, [scheduleData])

  useEffect(() => {
    if (state === '예약중') setColor('#FF8B48');
    else if (state === '수강중') setColor('#5BD98A');
    else if (state === '수업 종료') setColor('#FF3131');
  }, [state])

  function onClickMemberReserveButton() {
    closeFn();
    cancel();
  }

  return (
    <TopLevelWrapper>
      <ModalView isOver={isOver}>
        <TotalWrapper>
          <TitleWrapper isOver={isOver}>
            <Title isOver={isOver}>예약내역</Title>
            <MemberReserveButton isOver={isOver} onClick={onClickMemberReserveButton}>회원예약</MemberReserveButton>
          </TitleWrapper>
          <StateWrapper isOver={isOver}>
            <Text>현재 상태</Text>
            <State color={color}>{state}</State>
          </StateWrapper>
          <InfoBox isOver={isOver}>
            {
              useMemberList.map((data, index) => (
                <InfoWrapper key={index} isOver={isOver}>
                  <Name isOver={isOver}>{data.name}</Name>
                  <Phone isOver={isOver}>{data.phone.slice(0,3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11)}</Phone>
                </InfoWrapper>
              ))
            }
          </InfoBox>
          <ExitWrapper>
            <ExitButton isOver={isOver} onClick={cancel}>닫기</ExitButton>
          </ExitWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default ReservationHistoryModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `322px` : `290px`};
  height: 354px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: ${props => props.isOver ? `266px` : `240px`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `14px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const MemberReserveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `83px` : `74px`};
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #D0D0D0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #232323;
    border: 1px solid #232323;
  }
`;

const StateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `8px` : `4px`};

  margin-top: 15px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const State = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 12px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  width: ${props => props.isOver ? `267px` : `238px`};
  height: 124px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: ${props => props.isOver ? `252px` : `223px`};
  height: 32px;

  border-bottom: 1px solid #F5F5F5;
`;

const Name = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: ${props => props.isOver ? `57px` : `53px`};
  height: 32px;

  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #595959;

  overflow: hidden;
`;

const Phone = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: ${props => props.isOver ? `124px` : `105px`};
  height: 32px;

  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;
  
  overflow: hidden;
`;

const ExitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  width: 100%;

  margin-top: 46px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `72px` : `54px`};
  height: ${props => props.isOver ? `38px` : `30px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `8px`};
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #CBCBCB;

  :hover {
    cursor: pointer;
    border: 1px solid #232323;
    color: #232323;
  }
`;