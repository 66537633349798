import { React } from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';

function LocationButton(props) {
  const { nowPage } = props;

  const history = useHistory();

  return (
    <TopLevelWrapper>
      <Button isClicked={nowPage === 'Service'} onClick={() => history.push('/rule/service')}>이용 약관</Button>
      <Button isClicked={nowPage === 'Location'} onClick={() => history.push('/rule/location')}>위치정보</Button>
      <Button isClicked={nowPage === 'Privacy'} onClick={() => history.push('/rule/privacy')}>개인정보</Button>
      <Button isClicked={nowPage === 'Promotion'} onClick={() => history.push('/rule/promotion')}>프로모션</Button>
      <Button isClicked={nowPage === 'Refund'} onClick={() => history.push('/rule/refund')}>환불약관</Button>
    </TopLevelWrapper>
  )
}

export default LocationButton;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  flex-wrap: wrap;

  gap: 38px;
  margin-top: 38px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  font-family: ${props => props.isClicked ? `NotoSansKR-Bold` : `NotoSansKR-Medium`};
  font-size: 16px;

  color: ${props => props.isClicked ? `#232323` : `#CFCFCF`};

  padding-bottom: 7px;

  border-bottom: ${props => props.isClicked ? `2px solid #FF8B48` : `0px`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    
    font-family: NotoSansKR-Bold;
    color: #232323;

    border-bottom: 2px solid #FF8B48;
  }
`;