import { React, useState, useRef, useImperativeHandle, forwardRef, useEffect} from 'react';
import styled from 'styled-components';

const OneOnOneScheduleBox = forwardRef((props, ref) => {
  const { isOver, useData, clickDate, dateClickFn } = props;

  const [value, setValue] = useState(clickDate);
  const [rendering, setRendering] = useState(false);

  const TotalRef = useRef([]);
  const ColumnRef = useRef([]);
  const ColumnDayRef = useRef([]);
  const ExerciseRef = useRef([]);

  useImperativeHandle(ref, () => ({
    setValue: (val) => {
      setValue(val);
      setRendering(!rendering);
    },
    getValue: () => {
      return value;
    }
  }));

  useEffect(() => {
    for (var i = 0; i < useData.length; i++) {
      if (useData[i].classDate.format('YYYY.MM.DD') !== value){
        ColumnRef.current[i].style.fontFamily = 'NotoSansKR-Regular';
        ColumnRef.current[i].style.color = '#777777';
    
        ColumnDayRef.current[i].style.fontFamily = 'NotoSansKR-Regular';
        ColumnDayRef.current[i].style.color = '#CBCBCB';
    
        ExerciseRef.current[i].style.borderColor = '#DFDFDF';
        ExerciseRef.current[i].style.fontFamily = 'NotoSansKR-Regular';
        ExerciseRef.current[i].style.color = '#595959';
      }
      else {
        ColumnRef.current[i].style.fontFamily = `NotoSansKR-Medium`;
        ColumnRef.current[i].style.color = `#FF8B48`;

        ColumnDayRef.current[i].style.fontFamily = 'NotoSansKR-Medium';
        ColumnDayRef.current[i].style.color = '#FF8B48';

        ExerciseRef.current[i].style.borderColor = '#FF8B48';
        ExerciseRef.current[i].style.fontFamily = 'NotoSansKR-Medium';
        ExerciseRef.current[i].style.color = '#232323';
      }
    }
  }, [value, useData])
  
  useEffect(() => {
    for (var i = 0; i < useData.length; i++) {
      if (useData[i].classDate.format('YYYY.MM.DD') === clickDate) {
        TotalRef.current[i].scrollIntoView({behavior: 'smooth'});
        break;
      }
    }

  }, [useData, clickDate])

  function onMouseOver(index) {
    if (useData[index].classDate.format('YYYY.MM.DD') === value) return;

    ColumnRef.current[index].style.fontFamily = `NotoSansKR-Medium`;
    ColumnRef.current[index].style.color = `#FF8B48`;

    ColumnDayRef.current[index].style.fontFamily = 'NotoSansKR-Medium';
    ColumnDayRef.current[index].style.color = '#FF8B48';

    ExerciseRef.current[index].style.borderColor = '#FF8B48';
    ExerciseRef.current[index].style.fontFamily = 'NotoSansKR-Medium';
    ExerciseRef.current[index].style.color = '#232323';
  }

  function onMouseLeave(index) {
    if (useData[index].classDate.format('YYYY.MM.DD') === value) return;

    ColumnRef.current[index].style.fontFamily = 'NotoSansKR-Regular';
    ColumnRef.current[index].style.color = '#777777';

    ColumnDayRef.current[index].style.fontFamily = 'NotoSansKR-Regular';
    ColumnDayRef.current[index].style.color = '#CBCBCB';

    ExerciseRef.current[index].style.borderColor = '#DFDFDF';
    ExerciseRef.current[index].style.fontFamily = 'NotoSansKR-Regular';
    ExerciseRef.current[index].style.color = '#595959';
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      <InfoBox>
        <Title>일정표</Title>
        <ListBox isOver={isOver}>
          {
            useData.map((data, index) => (
              <ComponentWrapper
                id={data.classDate.format('YYYY.MM.DD')}
                ref={element => (TotalRef.current[index] = element)}
                key={index}
                onMouseOver={() => onMouseOver(index)}
                onMouseLeave={() => onMouseLeave(index)}
                onClick={() => dateClickFn(data.classDate.format('YYYY.MM.DD'))}>
                <ColumnWrapper>
                  <State isOver={isOver} state={data.isSucceed} />
                  <Column
                    ref={element => (ColumnRef.current[index] = element)}
                    isClicked={data.classDate.format('YYYY.MM.DD') === value}>
                    {data.classDate.format('DD일')}
                  </Column>
                  <ColumnDay
                    ref={element => (ColumnDayRef.current[index] = element)}
                    isClicked={data.classDate.format('YYYY.MM.DD') === value}>
                    {data.classDate.format('(dd)')}
                  </ColumnDay>
                </ColumnWrapper>
                <Exercise
                  ref={element => (ExerciseRef.current[index] = element)}
                  isOver={isOver}
                  isClicked={data.classDate.format('YYYY.MM.DD') === value}>
                  {data.title === '' ? '작성이 필요합니다.' : data.title}
                </Exercise>
              </ComponentWrapper>
            ))
          }
        </ListBox>
      </InfoBox>
    </TopLevelWrapper>
  )
})

export default OneOnOneScheduleBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 408px;
  width: ${props => props.isOver ? `max(319px, 41.53645833333333vw)` : `max(296px, 92.5vw)`};
  // height: 421px;
  height: ${props => props.isOver ? `400px` : `371px`};

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  gap: 8px;

  // width: 360px;
  width: ${props => props.isOver ? `max(271px, 35.28645833333333vw)` : `max(262px, 81.875vw)`};
  // height: 301px;
  height: 264px;

  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #ECECEC;

  overflow-y: scroll;
  overflow-x: none;

  transition: all 0.3s ease-in-out;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  :hover {
    cursor: pointer;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 3px;
`;

const State = styled.div`
  width: 8px;
  height: 8px;

  margin-right: 8px;
  
  border-radius: 8px;
  background-color: ${props => props.state ? '#5BD98A' : '#FF3131'};
  
  transition: all 0.3s ease-in-out;
`;

const Column = styled.p`
  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 12px;

  color: ${props => props.isClicked ? `#FF8B48` : `#777777`};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
`;

const ColumnDay = styled.p`
  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 12px;

  color: ${props => props.isClicked ? `#FF8B48` : `#CBCBCB`};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
`;

const Exercise = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: ${props => props.isOver ? `max(173px, 22.52604166666667vw)` : `max(160px, 50vw)`};
  height: ${props => props.isOver ? `36px` : `33px`};

  padding-left: ${props => props.isOver ? `16px` : `15px`};
  margin-right: 4px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 14px;
  color: ${props => props.isClicked ? `#232323` : `#595959`};

  transition: all 0.3s ease-in-out;
`;