import { React, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SelectBox from '../../../../Component/SettingComponent/ModalCategorySelectBox';
import ClassSelectBox from '../../../../Component/SettingComponent/ClassSelectBox.js';
import MemberShipValidity from '../../../../Component/SettingComponent/MemberShipValidityInput';
import MemberShipMaxCountInput from '../../../../Component/SettingComponent/MemberShipMaxCountInput';
import MemberShipNameInput from '../../../../Component/SettingComponent/MemberShipNameInput';
import PriceSettingInput from '../../../../Component/SettingComponent/PriceSettingInput';

import FetchModule from '../../../Share/Network/FetchModule';
import ClassTypeInput from '../../../../Component/SettingComponent/ClassTypeInput';

const MemberShipAddModal = forwardRef((props, ref) => {
  const { isOver, data, index, exitClickFn, rendering } = props;

  const history = useHistory();

  const TopLevelRef = useRef();
  const SelectRef = useRef();
  const ClassRef = useRef();
  const ValidyRef = useRef();
  const NameRef = useRef();
  const PriceRef = useRef();
  const TotalMaxRef = useRef();
  const WeekMaxRef = useRef();
  const DayMaxRef = useRef();
  const HoldRef = useRef();
  const HoldCountRef = useRef();
  const AutoRef = useRef();
  
  useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
      TopLevelRef.current.style.opacity = val;
    },
		setZindex: (val) => {
      TopLevelRef.current.style.zIndex = val;
    }
	}));

  useEffect(() => {
    // setNowSelectWeek(oneWeekData);
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  useEffect(() => {
    if (index === -1) return;

    NameRef.current.setValue(data[index].name);
    SelectRef.current.setValue(data[index].categoryId);
    PriceRef.current.setValue(data[index].price);
    ClassRef.current.setValue(data[index].lessonId);
    ValidyRef.current.setValue(data[index].validity);
    TotalMaxRef.current.setValue(data[index].totalMax);
    WeekMaxRef.current.setValue(data[index].weekMax);
    DayMaxRef.current.setValue(data[index].dayMax);
    HoldRef.current.setValue(data[index].availableHold);
    HoldCountRef.current.setValue(data[index].holdCount);
    AutoRef.current.setValue(data[index].autoPayment);
  }, [data, index])

  function exitClick() {
    exitClickFn('MemberShip');
  }

  function saveClick() {
    var name = NameRef.current.getValue();
    var categoryId = SelectRef.current.getValue().id;
    var lessonId = ClassRef.current.getValue().id;
    // console.log(ClassRef.current.getValue());
    // var lessonType = MemberShipTypeRef.current.getValue();
    var price = PriceRef.current.getValue();
    var validity = ValidyRef.current.getValue();
    var totalMax = TotalMaxRef.current.getValue();
    var weekMax = WeekMaxRef.current.getValue();
    var dayMax = DayMaxRef.current.getValue();
    var availableHold = HoldRef.current.getValue();
    var holdCount = HoldCountRef.current.getValue();
    var autoPayment = AutoRef.current.getValue();
    
    var regexEn = /[a-zA-Z]/;
    var regexKr = /[ㄱ-ㅎ가-힣]/;

    if (name === '') {
      alert('회원권 명을 입력해주세요.');
      return;
    }
    else if (price === '' || regexKr.test(price) || regexEn.test(price)) {
      alert('회원권 가격을 올바르게 입력해주세요.');
      return;
    }
    else if (validity > 31 && (autoPayment > 0 || autoPayment === -1)) {
      alert('31일 이상의 상품은 자동결제를 설정할 수 없습니다.');
      return;
    }
    else if (autoPayment > 12) {
      alert('자동결제 유지기간은 12개월을 초과할 수 없습니다.');
      return;
    }

    var newData = {
      id: index === -1 ? '' : data[index].id,
      // storeId: window.sessionStorage.getItem('StoreId'),
      storeId: window.localStorage.getItem('StoreId'),
      categoryId: categoryId,
      lessonId: lessonId,
      name: name,
      price: price,
      validity: validity,
      totalMax: totalMax,
      weekMax: weekMax,
      dayMax: dayMax,
      availableHold: availableHold,
      holdCount: holdCount,
      autoPayment: autoPayment
    };

    // console.log(newData);
    (
      async () => {
        const fetchModule = new FetchModule();
        var data;
        if (index === -1) data = await fetchModule.postData('membership/create', 'POST', newData);
        else data = await fetchModule.patchData('membership/modify', 'PATCH', newData);
        
        if (data.status === 200) {
          alert("저장이 완료되었습니다.");
          rendering();
          exitClickFn('MemberShip');
        }
        else if (data.status === 401) history.push('/');
        else {}
      }
    )();
  }

  function selectClickFn(className) {
    if (className === 'category') {
      ClassRef.current.setIsClicked();
      ValidyRef.current.setIsClicked();
    }
    else if (className === 'lessonName') {
      SelectRef.current.setIsClicked();
      ValidyRef.current.setIsClicked();
    }
    else {
      SelectRef.current.setIsClicked();
      ClassRef.current.setIsClicked();
    }
  }

  function noneFunction() {

  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <Modal isOver={isOver}>
        <InfoWrapper>
          <Title isOver={isOver}>회원권 추가</Title>
          <TotalWrapper isOver={isOver}>
            <SectionDivisionWrapper isOver={isOver}>
              <SectionTitle>회원권 정보</SectionTitle>
              <ModelWrapper isOver={isOver}>
                <ComponentWrapper isOver={isOver}>
                  <ComponentTitle>카테고리 선택</ComponentTitle>
                  <SelectBox ref={SelectRef} isOver={isOver} width={isOver ? `306px` : `242px`} changeVal={noneFunction} selectClickFn={() => selectClickFn('category')}/>
                </ComponentWrapper>
                <ComponentWrapper isOver={isOver}>
                  <ComponentTitle>회원권 명</ComponentTitle>
                  <MemberShipNameInput ref={NameRef} isOver={isOver} placeholder='회원권 명을 입력해주세요' />
                </ComponentWrapper>
                <ComponentWrapper isOver={isOver}>
                  <ComponentTitle>수업명</ComponentTitle>
                  <ClassSelectBox ref={ClassRef} isOver={isOver} selectClickFn={() => selectClickFn('lessonName')}/>
                </ComponentWrapper>
                <ComponentWrapper isOver={isOver}>
                  <ComponentTitle>회원권 유효기간</ComponentTitle>
                  <MemberShipValidity ref={ValidyRef} isOver={isOver} selectClickFn={() => selectClickFn('validity')}/>
                </ComponentWrapper>
                <ComponentWrapper isOver={isOver}>
                  <ComponentTitle>가격 설정</ComponentTitle>
                  <PriceSettingInput ref={PriceRef} isOver={isOver}/>
                </ComponentWrapper>
              </ModelWrapper>
            </SectionDivisionWrapper>
            <SectionDivisionWrapper isOver={isOver}>
              <SectionTitle>수강 정보</SectionTitle>
              <ModelWrapper isOver={isOver}>
                <ComponentWrapper isOver={isOver}>
                  <MemberShipMaxCountInput ref={TotalMaxRef} isOver={isOver} explanation='총 예약 가능 횟수' />
                </ComponentWrapper>
                <ComponentWrapper isOver={isOver}>
                  <MemberShipMaxCountInput ref={DayMaxRef} isOver={isOver} explanation='일일 사용 가능 횟수' />
                </ComponentWrapper>
                <ComponentWrapper isOver={isOver}>
                  <MemberShipMaxCountInput ref={WeekMaxRef} isOver={isOver} explanation='주간 수업 가능 한도' />
                </ComponentWrapper>
                <ComponentWrapper isOver={isOver}>
                  <MemberShipMaxCountInput ref={HoldRef} isOver={isOver} explanation='최대 홀딩 가능 일수' />
                </ComponentWrapper>
                <ComponentWrapper isOver={isOver}>
                  <MemberShipMaxCountInput ref={HoldCountRef} isOver={isOver} explanation='홀딩 신청 가능 횟수' />
                </ComponentWrapper>
                <ComponentWrapper isOver={isOver}>
                  <MemberShipMaxCountInput ref={AutoRef} isOver={isOver} explanation='자동결제 유지기간' isRegularPayment={true}/>
                </ComponentWrapper>
              </ModelWrapper>
            </SectionDivisionWrapper>
          </TotalWrapper>
        </InfoWrapper>
        <ButtonWrapper isOver={isOver}>
          <ExitButton isOver={isOver} onClick={exitClick}>취소</ExitButton>
          <DeleteButton isOver={isOver} onClick={saveClick}>저장하기</DeleteButton>
        </ButtonWrapper>
      </Modal>
    </TopLevelWrapper>
  )
})

export default MemberShipAddModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  // opacity: 0;
  z-index: 1000;

  // width: 100vw;
  // height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);

  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 772px;
  width: ${props => props.isOver ? `692px` : `290px`};
  // height: 627px;
  // height: ${props => props.isOver ? `623px` : `827px`};
  height: ${props => props.isOver ? `704px` : `1000px`};

  // padding: 0 0 0 28px;
  
  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `24px` : `12px`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `14px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const SectionDivisionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: ${props => props.isOver ? `24px` : `12px`};
`;

const SectionTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ModelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  border-top: 1px solid #ECECEC;

  margin-top: ${props => props.isOver ? `24px` : `12px`};
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.isOver ? `24px` : `12px`};
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;


const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: ${props => props.isOver ? `16px` : `12px`};

  margin: ${props => props.isOver ? `26px 0 0 456px` : `24px 0 0 129px`};
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `72px` : `52px`};
  height: ${props => props.isOver ? `38px` : `28px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `8px`};
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `70px`};
  height: ${props => props.isOver ? `40px` : `30px`};

  border-radius: ${props => props.isOver ? `15px` : `8px`};
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;