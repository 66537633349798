import { React, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';

import SettingTimeSelectBox from './SettingTimeSelectBox';

import DownImage from '../../image/down.png';
import UpImage from '../../image/up.png';

const SettingCountInput = forwardRef((props, ref) => {
  const { isOver, unit } = props;
  const [dateVal, setDateVal] = useState(0); 
	const [changed, setChanged] = useState(false);

  const InputRef = useRef();
  const SelectRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
      setDateVal(val);
      SelectRef.current.reSet();
		},
		getValue: () => {
      var units = SelectRef.current.getValue();
      // 일, 시간, 분을 서버에 올리기 위해 분으로 환산하기 위한 곱수
      var multiplier = 1; 
      if (units === '일') multiplier = 60 * 24;
      else if (units === '시간') multiplier = 60;
      else if (units === '분') multiplier = 1;

			return (dateVal * multiplier);
		}
	}))
  
	function handleChange(e) {
    var regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    var result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    // console.log(result);	
		setDateVal(result === '' ? 0 : parseInt(result));
		setChanged(true);
	}

	function handleFocus(e) {

	}

  function upButtonClick() {
    setDateVal(dateVal + 1);
  }

  function downButtonClick(){
    if (dateVal > 0) setDateVal(dateVal - 1);
  }

  return (
    <TopLevelWrapper>
      <Input 
        isOver={isOver}
      	ref={InputRef}
        placeholder={0}
        value={dateVal}
        onChange={handleChange}
        onFocus={handleFocus}/>
      <ButtonWrapper>
        <Button img={UpImage} onClick={upButtonClick}/>
        <Button img={DownImage} onClick={downButtonClick}/>
      </ButtonWrapper>
      <SettingTimeSelectBox ref={SelectRef}/>
      <Text isOver={isOver}>{unit}</Text>
    </TopLevelWrapper>
  )
})

export default SettingCountInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  margin-top: 8px;

  transition: all 0.3s ease-in-out;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: ${props => props.isOver ? `75px` : `64px`};
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  padding: 0 0 0 8px;
  margin: 0 5px 0 0;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #CBCBCB;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
  margin-right: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background: url(${props => props.img}) no-repeat center;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 79px;
`;