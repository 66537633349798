import { React } from 'react';
import styled from 'styled-components';

import DietInfo from './DietInfo.js';
import BodyInfo from './BodyInfo.js';

const CoachHistoryView = (props) => {
  const { physicalData, isOver } = props;

  return (
    <TopLevelWrapper isOver={isOver}>
      <DietInfo isOver={isOver}/>
      <BodyInfo physicalData={physicalData} isOver={isOver}/>
    </TopLevelWrapper>
  )
}

export default CoachHistoryView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;

  margin-top: ${props => props.isOver ? `34px` : `19px`};
`;