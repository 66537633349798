import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Share/Network/FetchModule';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import ClassSelectBox from '../../../Component/NoteComponent/ClassSelectBox';
import ScheduleBox from './ScheduleBox';
import CalenderView from './CalenderView';
import ExerciseBox from './ExerciseBox';
import useWindowSize from '../../Share/Normal/useWindowSize';
import ExerciseSaveModal from './ExerciseSaveModal';
import ExerciseDeleteModal from './ExerciseDeleteModal';
import ScheduleEmptyModal from './ScheduleEmptyModal';

function ProgramView() {
  const history = useHistory();
  const size = useWindowSize();

  const [isOver, setIsOver] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment());
  const [rendering, setRendering] = useState(false);
  const [scheduleId, setScheduleId] = useState(-1);

  const [workoutId, setWorkoutId] = useState(0);
  const [buttonText, setButtonText] = useState('');
  const [lessonName, setLessonName] = useState('');
  const [isClickedModify, setIsClickedModify] = useState(false);
  const [isViewConfirm, setIsViewConfirm] = useState(false);
  const [isViewGoClass, setIsViewGoClass] = useState(false);
  const [isClickedDelete, setIsClickedDelete] = useState(false);
  const [isUseSameLessonAllSave, setIsUseSameLessonAllSave] = useState(true);
  const [isUseSameLessonAllDelete, setIsUseSameLessonAllDelete] = useState(true);
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [dateList, setDateList] = useState([]);
  const [useData, setUseData] = useState([{
    workoutId: 0,
    scheduleId: 0,
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    classDate: moment(),
    title: '작성이 필요합니다.',
    sectionList: []
  }]);

  const [scheduleHeight, setScheduleHeight] = useState(421);

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  const TopLevelRef = useRef();
  const ExerciseRef = useRef();
  const ClassSelectRef = useRef();
  const ScheduleRef = useRef();
  const CalenderRef = useRef();

  // View에 띄울 컴포넌트 크기 확인 //
  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  // workout Data에 따라 버튼 텍스트 변경 //
  useEffect(() => {
    for (var i = 0; i < useData.length; i++) {
      if (useData[i].classDate.format('YYYY.MM.DD') === clickDate) {
        if (isClickedModify) setButtonText('저장');
        else {
          if (useData[i].workoutId === 0) setButtonText('추가');
          else setButtonText('수정');
        }
        break;
      }
    }
  }, [isClickedModify, clickDate, useData])

  // 날짜 데이터 세팅 //
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');

        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      }
    }
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek])

  // 운동 데이터 API Call, 데이터 정리 //
  useEffect(() => {

    if (scheduleId === -1) return;

    var data = {
      scheduleId: scheduleId,
      month: date.clone().format('MM')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.postDataOfExercise('workout/', 'POST', data);

        if (responseData.status === 200) {
          // console.log(responseData.data);
          var workoutData = responseData.data.slice();

          // 운동 데이터 정리 //
          for (var i = 0; i < workoutData.length; i++) {

            workoutData[i].sectionList.sort(function (a, b) {
              return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
            })

            for (var j = 0; j < workoutData[i].sectionList.length; j++) {
              workoutData[i].sectionList[j].buyinExerciseList = [];
              workoutData[i].sectionList[j].mainExerciseList = [];
              workoutData[i].sectionList[j].buyoutExerciseList = [];
              workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');

              for (var h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
                workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
                workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
                workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;

                if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
                  workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
                  workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
                  workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
              }

              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyinExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyoutExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
            }
          }

          setData(workoutData);
        }
        else if (responseData.status === 204) setData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, date, scheduleId, rendering])

  // 데이터 공백 부분 채우기 //
  useEffect(() => {
    if (dateList.length === 0) return;

    var newData = data.slice();

    if (newData.length === 0) { // 데이터가 없으면 1개 넣고 시작
      var tmpData = {
        workoutId: 0,
        scheduleId: 0,
        storeId: parseInt(window.localStorage.getItem('StoreId')),
        classDate: dateList[0],
        title: '',
        sectionList: []
      }
    }

    for (var i = 0; i < dateList.length; i++) {
      var isNone = true;

      for (var j = 0; j < newData.length; j++) {
        if (dateList[i].format('MM.DD') === moment(newData[j].classDate).format('MM.DD')) {
          isNone = false;
          newData[j].classDate = moment(newData[j].classDate);
          break;
        }
      }

      if (isNone) {
        tmpData = {
          workoutId: 0,
          scheduleId: 0,
          storeId: parseInt(window.localStorage.getItem('StoreId')),
          classDate: dateList[i],
          title: '',
          sectionList: []
        }
        newData.push(tmpData);
      }
    }

    newData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    setUseData(newData);
  }, [dateList, data])

  // 저장 모달에 띄울 수업 이름 저장 //
  useEffect(() => {
    setLessonName(ClassSelectRef.current.getValue().className);
    setWorkoutId(ExerciseRef.current.getWorkoutId());
  }, [isViewConfirm, ClassSelectRef, ExerciseRef])

  // 현재 클릭된 Workout Id, Lesson Name 추출 //
  useEffect(() => {
    var tmpData = useData.slice();

    for (var i = 0; i < tmpData.length; i++) {
      if (tmpData[i].classDate.clone().format('YYYY.MM.DD') === clickDate) {
        setWorkoutId(tmpData[i].workoutId);
        setLessonName(tmpData[i].lessonName);
      }
    }
  }, [useData, clickDate])

  // 일정표내의 날짜 클릭시 //
  function dateClick(value) {
    // 운동 내용 변경중인지 확인 //
    if (isClickedModify) {
      var exerciseData = ExerciseRef.current.getValue();

      if (exerciseData.length === 0) {
        setIsClickedModify(false);

        setClickDate(value);

        ScheduleRef.current.setValue(value);
        CalenderRef.current.setValue(value);
      }
      else setIsViewConfirm(true);
    }
    else {
      setClickDate(value);

      ScheduleRef.current.setValue(value);
      CalenderRef.current.setValue(value);
    }
  }

  // 스케줄 변경 가능여부 확인 //
  function checkAvailableChangeScheduleData() {
    if (isClickedModify) {
      var exerciseData = ExerciseRef.current.getValue();

      if (exerciseData.length === 0) {
        setIsClickedModify(false);
        return true;
      }
      else return false;
    }
    else return true;
  }

  // 월 단위 날짜 변경 클릭시 //
  function onClickDateArrow(division) {
    if (isClickedModify) setIsViewConfirm(true);
    else {
      if (division === 'Prev') setDate(date.clone().subtract(1, 'month'));
      else if (division === 'Next') setDate(date.clone().add(1, 'month'));
    }
  }

  // 스크롤 애니메이션를 위한 UI제어 //
  function changeTopLevelWrapperPosition() {
    if (TopLevelRef.current.style.position === 'fixed') {
      TopLevelRef.current.style.position = 'relative';
    }
    else {
      TopLevelRef.current.style.position = 'fixed';
      TopLevelRef.current.style.top = '0px';
    }
  }

  // 수정하기 클릭시 //
  function onClickModifyButton() {
    if (isClickedModify) {
      setIsViewConfirm(true);
    }
    else {
      setIsClickedModify(true);
    }
  }

  function settingHeight(height) {
    setScheduleHeight(height);
  }

   // 수정 저장하기 클릭시 //
   function onClickConfirmYes() {
    var title = ExerciseRef.current.getTotalTitle();
    var exerciseData = ExerciseRef.current.getValue();
    var workoutId = ExerciseRef.current.getWorkoutId();
    var classInfo = ClassSelectRef.current.getValue();

    // 운동 타이틀 작성 여부 확인 //
    if (title === '작성이 필요합니다.' || title === '') {
      alert('운동의 타이틀을 작성해주세요');
      setIsViewConfirm(false);
      return;
    }
    else if (exerciseData.length === 0) {
      alert('디자인된 운동이 없습니다!');
      setIsViewConfirm(false);
      return;
    }

    for (var i = 0; i < exerciseData.length; i++) {
      // Buy in 운동 디자인 여부 확인 //
      if (exerciseData[i].isUseBuyin && exerciseData[i].buyinExerciseList.length === 0) {
        alert('Buy in 운동 디자인을 마무리해주세요!\nBuy in을 사용하지 않을 경우 체크를 해제해주세요!');
        setIsViewConfirm(false);
        return;
      }
      // Buy out 운동 디자인 여부 확인 //
      else if (exerciseData[i].isUseBuyout && exerciseData[i].buyoutExerciseList.length === 0) {
        alert('Buy out 운동 디자인을 마무리해주세요!\nBuy out을 사용하지 않을 경우 체크를 해제해주세요!');
        setIsViewConfirm(false);
        return;
      }
      // Main 운동 디자인 여부 확인 //
      else if (exerciseData[i].mainExerciseList.length === 0) {
        alert('메인 운동 디자인을 마무리해주세요!');
        setIsViewConfirm(false);
        return;
      }
    }

    var requestData = {
      isUseSameLessonAllSave: isUseSameLessonAllSave,
      workoutId: workoutId === 0 ? undefined : workoutId,
      lessonId: classInfo.lessonId,
      scheduleId: scheduleId,
      lessonName: classInfo.className,
      classDate: clickDate.replaceAll('.', '-') + 'T00:00:00',
      storeId: 0,
      title: title,
      sectionList: exerciseData
    }
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.postDataOfExercise('workout/save', 'POST', requestData);
        
        if (responseData.status === 200) {
          setRendering(!rendering);
          // var workoutData = data.slice();
          // var isChanged = false;

          // for (var i = 0; i < workoutData.length; i++) {
          //   if (workoutData[i].classDate.clone().format('YYYY-MM-DDT00:00:00') === responseData.data[0].classDate) {
          //     workoutData[i] = responseData.data[0];
          //     isChanged = true;
          //     break;
          //   }
          // }

          // if (!isChanged) {
          //   workoutData.push(responseData.data[0]);

          //   workoutData.sort(function (a, b) {
          //     return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
          //   })
          // }
          // setData(workoutData);
        }
      }
    )();

    setIsViewConfirm(false);
    setIsClickedModify(false);
  }

  // 삭제버튼 클릭시 //
  function onClickDeleteWorkout() {
    // console.log(workoutId);
    setIsClickedDelete(true);
  }

  // 삭제 확인 버튼 클릭시 //
  function onClickDelete() {
    if (workoutId === 0) {
      alert("유효한 데이터가 아닙니다.");
      setIsClickedDelete(false);

      return;
    }

    var requestData = {
      isUseSameLessonAllDelete: isUseSameLessonAllDelete,
      workoutId: workoutId
    }
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.postDataOfExercise('workout/delete', 'POST', requestData);
        
        if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedDelete(false);
          return;
        }
        else if (responseData.status === 201) {
          alert('이미 삭제되었거나 존재하지 않는 데이터입니다.');
          setIsClickedDelete(false);
          return;
        }
        else if (requestData.status === 401) {
          alert('올바르지 않은 권한의 접근입니다.');
          setIsClickedDelete(false);
          return;
        }
      }
    )();
  }



  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {
        isViewConfirm &&
        <ExerciseSaveModal
          lessonName={lessonName}
          isUseSameLessonAllSave={isUseSameLessonAllSave}
          setIsUseSameLessonAllSave={setIsUseSameLessonAllSave}
          yesFn={onClickConfirmYes}
          noFn={() => setIsViewConfirm(false)} />
      }
      {
        isClickedDelete &&
        <ExerciseDeleteModal
          lessonName={lessonName}
          isUseSameLessonAllDelete={isUseSameLessonAllDelete}
          setIsUseSameLessonAllDelete={setIsUseSameLessonAllDelete}
          yesFn={onClickDelete}
          noFn={() => setIsClickedDelete(false)}/>
      }
      {
        isViewGoClass &&
        <ScheduleEmptyModal isOver={isOver}/>
      }
      <TopBarWrapper isOver={isOver}>
        <TextWrapper>
          <Title isOver={isOver}>프로그램</Title>
          <Explanation isOver={isOver}>
            {
              isOver ?
                `링크코치와 연동하여 회원 별 맞춤형 피트니스 프로그램을 계획할 수 있어요`
                :
                `링크코치와 연동하여 맞춤형 피트니스 프로그램을 계획할 수 있어요`
            }
          </Explanation>
        </TextWrapper>
      </TopBarWrapper>
      <TopButtonWrapper isOver={isOver}>
        <LeftWrapper isOver={isOver}>
          <ClassSelectBox 
            ref={ClassSelectRef} 
            isOver={isOver} 
            date={date} 
            setScheduleId={setScheduleId} 
            checkAvailableChangeScheduleData={checkAvailableChangeScheduleData}
            rendering={() => setRendering(!rendering)} />
          <DateButtonWrapper isOver={isOver}>
            <ArrowWrapper isOver={isOver} onClick={() => onClickDateArrow('Prev')}>
              <ArrowImage src={LeftArrow} />
            </ArrowWrapper>
            <DateText isOver={isOver}>{date.clone().format('YYYY년 MM월')}</DateText>
            <ArrowWrapper isOver={isOver} onClick={() => onClickDateArrow('Next')} >
              <ArrowImage src={RightArrow} />
            </ArrowWrapper>
          </DateButtonWrapper>
        </LeftWrapper>
        <ButtonWrapper>
          {
            isOver && workoutId !== 0 &&
            <DeleteButton onClick={onClickDeleteWorkout}>삭제</DeleteButton> 
          }
          {
            isOver && <ModifyButton isClickedModify={isClickedModify} onClick={onClickModifyButton}>{buttonText}</ModifyButton>
          }
        </ButtonWrapper>
      </TopButtonWrapper>
      <MainView isOver={isOver}>
        {
          isOver ?
            <LayerWrapper isOver={isOver}>
              <ScheduleBox 
                ref={ScheduleRef} 
                isOver={isOver} 
                clickDate={clickDate} 
                useData={useData} 
                dateClickFn={dateClick} 
                height={scheduleHeight}
                changeTopLevelWrapperPosition={changeTopLevelWrapperPosition} />
              <CalenderView ref={CalenderRef} isOver={isOver} clickDate={clickDate} date={date} dateClickFn={dateClick} heightFn={settingHeight} />
            </LayerWrapper>
            :
            <LayerWrapper isOver={isOver}>
              <CalenderView ref={CalenderRef} isOver={isOver} clickDate={clickDate} date={date} dateClickFn={dateClick} heightFn={settingHeight} />
              <ScheduleBox 
                ref={ScheduleRef} 
                isOver={isOver} 
                clickDate={clickDate} 
                useData={useData} 
                dateClickFn={dateClick} 
                height={scheduleHeight}
                changeTopLevelWrapperPosition={changeTopLevelWrapperPosition} />
            </LayerWrapper>
        }
        <ExerciseBox
          ref={ExerciseRef}
          isOver={isOver}
          isClickedModify={isClickedModify} 
          useData={useData}
          clickDate={clickDate}
          changeTopLevelWrapperPosition={changeTopLevelWrapperPosition} />
      </MainView>
    </TopLevelWrapper>
  )
}

export default ProgramView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: ${props => props.isOver ? `28px` : `14px`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: ${props => props.isOver ? `space-between` : `flex-start`};
  align-items: ${props => props.isOver ? `center` : `flex-start`};

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(296px, 92.5vw)`};

  gap: ${props => props.isOver ? `0px` : `10px`};
  margin-top: ${props => props.isOver ? `32px` : `13px`};
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `max(19px, 2.473958333333333vw)` : `17px`};
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `205px` : `101px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: #FFFFFF;

  gap: ${props => props.isOver ? `37px` : `6px`};
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `22px` : `17px`};
  height: ${props => props.isOver ? `22px` : `17px`};

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `98px` : `52px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const MainView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // gap: ${props => props.isOver ? `30px` : `17px`};
  gap: ${props => props.isOver ? `19px` : `17px`};
  margin-top: 16px;
  margin-bottom: 255px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `max(19px, 2.473958333333333vw)` : `17px`};
  
  min-height: 421px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #DD0000;
  }
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50px;
  height: 40px;

  border-radius: 15px;
  background-color: ${props => props.isClickedModify ? `#5BD98A` : `#FF8B48`};

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${props => props.isClickedModify ? `#2BCB67` : `#FF7322`};
  }
`;