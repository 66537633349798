import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

const CourseHistoryColumnView = () => {

  return (
    <TopLevelWrapper>
      <Column style={{marginRight: '28px', marginLeft: '28px'}}>번호</Column>
      <Column style={{marginRight: '63px'}}>일시</Column>
      <Column style={{marginRight: '218px'}}>수업</Column>
      <Column style={{marginRight: '62px'}}>시간</Column>
      <Column style={{marginRight: '53px'}}>강사</Column>
      <Column style={{marginRight: '118px'}}>사용 회원권</Column>
      <Column style={{marginRight: '28px'}}>차감 횟수</Column>
      <Column >상태</Column>
    </TopLevelWrapper>
  )
}

export default CourseHistoryColumnView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  
  // justify-content: center;
  align-items: center;

  width: 1020px;
  margin-top: 34px;
  margin-bottom: 20px;
  
  transition: all 0.3s ease-in-out;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;
