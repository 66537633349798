import { React } from 'react';
import styled from 'styled-components';

export default function ClassInfoBox(props) {
  const {
    data,
    reserveData,
    onClickScheduleData 
  } = props;

  return (
    <TopLevelWrapper color={data.color} onClick={() => onClickScheduleData(data)} /*onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}*/ >
      <TextWrapper>
        <ClassName>{data.className}</ClassName>
        {
          data.classId === -1 ?
            <InfoWrapper>
              <InfoBox>
                <TimeText>{data.startTime} ~ {data.endTime}</TimeText>
              </InfoBox>
            </InfoWrapper>
            :
            <InfoWrapper>
              <InfoBox>
                <TimeText>{data.startTime} ~ {data.endTime}</TimeText>
                <Division />
                <Number>예약 {reserveData.count}명</Number>
                {/* <Division /> */}
                {/* <TrainerName>{data.teacherName}</TrainerName> */}
              </InfoBox>
            </InfoWrapper>
        }
      </TextWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: flex-end;
	align-items: center;

  padding: 0px 8px 0px 8px;

  width: calc(100% - 26px);
  height: 68px;
  min-height: 68px;

	border: 1px solid #ECECEC;
	border-radius: 15px;
  
  background-color: #FFFFFF;
	
  transition: all 0.3s ease-in-out;
  
	:hover {
    cursor: pointer;
		border: 1px solid ${props => props.color};
	}	
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: flex-start;

	gap: 4px;

  width: 100%;
  height: 100%;

  overflow: hidden;
`;

const ClassName = styled.p`
	font-family: NotoSansKR-Medium;
  font-size: 14px;

	color: #232323;

	margin: 0 0 0 0;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	
	justify-content: space-between;
  align-items: center;

  width: 862px;
`;

const InfoBox = styled.div`
	display: flex;
  flex-direction: row;

	justify-content: center;
  align-items: center;
`;

const TimeText = styled.p`
	font-family: Poppins-Regular;
  font-size: 12px;

	color: #777777;

	margin: 0 0 0 0;
`;

const Division = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	width: 1px;
	height: 9px;

	margin: 0 8px 0 8px;

	border-right: 1px solid #969696;
`;

const Number = styled.p`
	font-family: NotoSansKR-Regular;
  font-size: 12px;

	color: #777777;
	
	margin: 0 0 0 0;
`;

const TrainerName = styled.p`
	font-family: NotoSansKR-Regular;
  font-size: 12px;

	color: #777777;
	
	margin: 0 0 0 0;
`;