import { React, useRef } from 'react';
import styled from 'styled-components';

const CoachSettingInfoBox = (props) => {
  const { isOver, data, index, modifyClickFn, deleteClickFn } = props;

  const ModifyButtonRef = useRef();
  const DeleteButtonRef = useRef();

  function onMouseOver() {
    ModifyButtonRef.current.style.fontFamily = `NotoSansKR-Medium`;
    ModifyButtonRef.current.style.color = `#FF8B48`;
    DeleteButtonRef.current.style.fontFamily = `NotoSansKR-Medium`;
    DeleteButtonRef.current.style.color = `#FF8B48`;
  }

  function onMouseLeave() {
    ModifyButtonRef.current.style.fontFamily = `NotoSansKR-Regular`;
    ModifyButtonRef.current.style.color = `#CBCBCB`;
    DeleteButtonRef.current.style.fontFamily = `NotoSansKR-Regular`;
    DeleteButtonRef.current.style.color = `#CBCBCB`;
  }
  
  return (
    <TopLevelWrapper isOver={isOver}>
      <ImageWrapper isOver={isOver}>
        <ImageView src={data.profile} isOver={isOver}/>
      </ImageWrapper>
      <InfoBox isOver={isOver} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        <TopColumnWrapper isOver={isOver}>
          <TitleWrapper isOver={isOver}>
            <Name isOver={isOver}>{data.name}</Name>
            <Title isOver={isOver}>코치</Title>
          </TitleWrapper>
          <ButtonWrapper isOver={isOver}>
            <Button ref={ModifyButtonRef} isOver={isOver} onClick={() => modifyClickFn(index)}>수정</Button>
            <Button ref={DeleteButtonRef} isOver={isOver} onClick={() => deleteClickFn(index)}>삭제</Button>
          </ButtonWrapper>
        </TopColumnWrapper>
        <TotalInfoWrapper>
          <InfoWrapper isOver={isOver}>
            <Column isOver={isOver}>성별</Column>
            {/* <Column isOver={isOver}>이메일</Column> */}
            <Column isOver={isOver}>전화번호</Column>
            <Column isOver={isOver}>{ isOver ? `링크코치 아이디` : `링크코치` }</Column>
          </InfoWrapper>
          <InfoWrapper isOver={isOver}>
            <Info isOver={isOver}>{data.gender === 'Male' ? '남성' : '여성'}</Info>
            {/* <Info isOver={isOver}>{data.email}</Info> */}
            <Info isOver={isOver}>{data.phone}</Info>
            <Info isOver={isOver}>{data.linkCoachId}</Info>
          </InfoWrapper>
        </TotalInfoWrapper>
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default CoachSettingInfoBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `16px` : `8px`};
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  overflow: hidden;
  
  border-radius: ${props => props.isOver ? `24px` : `12px`};
  // filter: drop-shadow(0px 40px 50px rgba(240, 232, 227, 0.5));
`;

const ImageView = styled.img`
  width: ${props => props.isOver ? `199px` : `90px`};
  height: ${props => props.isOver ? `199px` : `90px`};
  
  border-radius: ${props => props.isOver ? `24px` : `12px`};

  object-fit: cover;
  // object-fit: contain;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: ${props => props.isOver ? `417px` : `200px`};
  height: ${props => props.isOver ? `197px` : `90px`};

  padding: ${props => props.isOver ? `0 0 0 28px` : `0 0 0 14px`};

  border: 1px solid #FFFFFF;
  border-radius: ${props => props.isOver ? `30px` : `12px`};
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const TopColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 80%;
  width: ${props => props.isOver ? `390px` : `180px`};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `4px` : `3px`};
`;

const Name = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #000000;
  
  margin: 0 0 0 0;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #777777;
  
  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `8px` : `6px`};
`;

const Button = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #CBCBCB;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;
`;

const TotalInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: ${props => props.isOver ? `110px` : `64px`};

  gap: ${props => props.isOver ? `8px` : `2px`};
  margin-top: ${props => props.isOver ? `16px` : `4px`};

  overflow: hidden;
`;

const Column = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #CBCBCB;

  margin: 0 0 0 0;
`;

const Info = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;