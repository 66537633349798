import { React, forwardRef, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';


const SignUpThirdBottomComponent = forwardRef((props, ref) => {
	const { onClickSignUp, onClickPrevButton, isOver } = props;

	const TopLevelRef = useRef()

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
			if (val === 0) TopLevelRef.current.style.zIndex = -1;
			else TopLevelRef.current.style.zIndex = 1;
		},
	}));

	return (
		<TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
			<MoreDetails onClick={onClickPrevButton}>이전</MoreDetails>
			<LoginButton onClick={onClickSignUp} isOver={isOver}>회원 가입</LoginButton>
		</TopLevelWrapper>
	)
})

export default SignUpThirdBottomComponent;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	align-items: center;

	margin-top: ${props => props.isOver ? `396px` : `428px`};
	// gap: 251px;
	
	width: ${props => props.isOver ? `max(369px, 48.046875vw)` : `max(250px, 78.125vw)`};

	opacity: 0;
	z-index: -1;
  transition: all 0.3s ease-in-out;
`;

const MoreDetails = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;

	margin: 0 0 0 0;

	color: #FF8B48;

	:hover {
		cursor: pointer;
	}
`;

const LoginButton = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	font-family: NotoSansKR-Bold;
	font-size: ${props => props.isOver ? `12px` : `10px`};

	width: ${props => props.isOver ? `92px` : `61px`};
	height: ${props => props.isOver ? `36px` : '25px'};

	border-radius: 18px;
	background-color: #FF8B48;
	
	color: #FFFFFF;

	cursor: default;

	:hover {
		cursor: pointer;
		background-color: #FF7322;
	}
`;
