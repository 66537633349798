import { React, useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

const StartCountView = forwardRef((props, ref) => {
  const { startCount, startCountEndFn } = props;

  const [count, setCount] = useState(0);
  const [sec, setSec] = useState(0);
  const [clicked, setClicked] = useState(false);


  const TopLevelRef = useRef();
  const time = useRef();
  const CountRef1 = useRef();
  const CountRef2 = useRef();
  const CountRef3 = useRef();
  const CountRef4 = useRef();
  const CountRef5 = useRef();
  const CountRef6 = useRef();
  const CountRef7 = useRef();
  const CountRef8 = useRef();

  useImperativeHandle(ref, () => ({
    startCount: () => {
      TopLevelRef.current.style.opacity = 1;

      var startPoint = (startCount + 1) * 100;

      setClicked(true);
      setCount(startCount);

      time.current = setInterval(() => {
        startPoint -= 1;
        setCount(startPoint - 1);
        setSec(Math.floor(startPoint / 100));
      }, 10)
    }
  }))

  useEffect(() => {
    
    var tmp = count % 6000;
    setSec(Math.floor(tmp / 100));

    if (startCount === 3) {
      if (count === 398) CountRef1.current.style.backgroundColor = `#FF8B48`;
      else if (count === 300) CountRef2.current.style.backgroundColor = `#FF8B48`;
      else if (count === 200) CountRef3.current.style.backgroundColor = `#FF8B48`;
    }
    else if (startCount === 5) {
      if (Math.floor(tmp / 100) === 5 && count > 500) CountRef4.current.style.backgroundColor = `#FF8B48`;
      else if (count === 500) CountRef5.current.style.backgroundColor = `#FF8B48`;
      else if (count === 400) CountRef6.current.style.backgroundColor = `#FF8B48`;
      else if (count === 300) CountRef7.current.style.backgroundColor = `#FF8B48`;
      else if (count === 200) CountRef8.current.style.backgroundColor = `#FF8B48`;
    }

    if (count === 0) {
      clearInterval(time.current);
      TopLevelRef.current.style.opacity = 0;

      if (startCount === 3) {
        CountRef1.current.style.backgroundColor = `#FFFFFF`;
        CountRef2.current.style.backgroundColor = `#FFFFFF`;
        CountRef3.current.style.backgroundColor = `#FFFFFF`;
      }
      else if (startCount === 5) {
        CountRef4.current.style.backgroundColor = `#FFFFFF`;
        CountRef5.current.style.backgroundColor = `#FFFFFF`;
        CountRef6.current.style.backgroundColor = `#FFFFFF`;
        CountRef7.current.style.backgroundColor = `#FFFFFF`;
        CountRef8.current.style.backgroundColor = `#FFFFFF`;
      }

      if(clicked) {
        setClicked(false);
        startCountEndFn();
      }
    }
  }, [startCount, count, clicked, startCountEndFn])


  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {startCount === 3 && 
        <CountBox>
          <Count ref={CountRef1}/>  
          <Count ref={CountRef2}/>  
          <Count ref={CountRef3}/>
          <Start>{sec === 0 ? `GO` : sec}</Start>  
        </CountBox>}
      {startCount === 5 && 
        <CountBox>
          <Count ref={CountRef4}/>  
          <Count ref={CountRef5}/>  
          <Count ref={CountRef6}/>  
          <Count ref={CountRef7}/>  
          <Count ref={CountRef8}/>
          <Start>{sec === 0 ? `GO` : sec}</Start> 
        </CountBox>}
    </TopLevelWrapper>
  )
})

export default StartCountView;

const TopLevelWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  opacity: 0;

  margin-top: 55px;
  transition: all 0.3s ease-in-out;
  // background-color: red;
`;

const CountBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Count = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid #FF8B48;
  border-radius: 32px;
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const Start = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 32px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;