import { max } from 'lodash';
import { React } from 'react';
import styled from 'styled-components';
import CenterIntroTextArea from '../../../Component/MySettingPageComponent/CenterIntroTextArea';

function MyGymIntroductionText(props) {
  const { isOver, isClicked, storeIntro, setStoreIntro } = props;

  return (
    <TopLevelWrapper>
      <InfoView isOver={isOver}>
        <TitleWrapper isOver={isOver}>
          <Title>센터 소개</Title>
        </TitleWrapper>
        <InfoWrapper isOver={isOver}>
          <TextWrapper>
            {
              isClicked ?
                <CenterIntroTextArea isOver={isOver} placeholder='센터 소개글을 입력해주세요' defaultValue={storeIntro} setVal={setStoreIntro}/>
                :
                <Text>{storeIntro}</Text>
            }
          </TextWrapper>
        </InfoWrapper>
      </InfoView>
    </TopLevelWrapper>
  )
}

export default MyGymIntroductionText;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 34px;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 36.04166666666667vw)` : `max(280px, 14.58333333333333vw)`};

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: ${props => props.isOver ? `max(636px, 33.125vw)` : `max(256px, 13.33333333333333vw)`};

  margin-top: 28px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;
  border-top: 1px solid #ECECEC;

  width: ${props => props.isOver ? `max(636px, 33.125vw)` : `max(256px, 13.33333333333333vw)`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  flex-wrap: wrap;

  margin-top: 24px;
  margin-bottom: 28px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;