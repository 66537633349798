import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import MemberShipCategorySelectBox from '../../../../../Component/MemberComponent/MemberShipCategorySelectBox.js';
import MemberShipSelectBox from '../../../../../Component/MemberComponent/MemberShipSelectBox.js';
import CalenderInput from '../../../../../Component/MemberComponent/CalenderInput.js';
import PaymentInput from '../../../../../Component/MemberComponent/PaymentInput.js';

import FetchModule from '../../../../Share/Network/FetchModule.js';

const MemberShipInfoEditView = forwardRef((props, ref) => {
  const { data, index } = props;

  const history = useHistory();

  const [categoryData, setCategoryData] = useState([]);
  const [memberShipData, setMemberShipData] = useState([]);
  const [divisionMemberShipData, setDivisionMemberShipData] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const [validity, setValidity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [notReceivedPrice, setNotReceivedPrice] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [approvalDate, setApprovalDate] = useState('');
  
  const CategorySelectRef = useRef();
  const MemberShipSelectRef = useRef();
  const CardPaymentRef = useRef();
  const CashPaymentRef = useRef();
  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();
  const ApprovolCalenderRef = useRef();

  useImperativeHandle(ref, () => ({
    getMemberShipId: () => {
      return MemberShipSelectRef.current.getMemberShipId();
    },
    getStartDate: () => {
      return startDate;
    },
    getEndDate: () => {
      return endDate;
    },
    getApprovalDate: () => {
      return approvalDate;
    },
    getCardPrice: () => {
      return CardPaymentRef.current.getValue();
    },
    getCashPrice: () => {
      return CashPaymentRef.current.getValue();
    }
  }))

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('membership/category/', 'GET');
       
        if (responseData.status === 200) {
          setCategoryData(responseData.data);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
        else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();
    (
      async () => {
        const responseData = await fetchModule.getData('membership/', 'GET');
        
        if (responseData.status === 200) {
          setMemberShipData(responseData.data);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
        else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();
  }, [history])

  useEffect(() => {
    if (index === -1) return;
    
    CategorySelectRef.current.setValue({id: data[index].categoryId, name: data[index].categoryName});
    MemberShipSelectRef.current.setValue({id: data[index].memberShipId, name: data[index].memberShipName, validity: data[index].validity});
    
    CardPaymentRef.current.setValue(data[index].cardPrice);
    CashPaymentRef.current.setValue(data[index].cashPrice);

    setMaxCount(data[index].totalMax);
    setValidity(data[index].validity - 1);
    setTotalPrice(data[index].totalPrice);
    // setNotReceivedPrice(data[index].totalPrice);
    setNotReceivedPrice(data[index].notReceivedPrice);

    setStartDate(moment(data[index].startDate).format('YYYY.MM.DD'));
    setEndDate(moment(data[index].endDate).format('YYYY.MM.DD'));
    setApprovalDate(moment(data[index].approvalDate).format('YYYY.MM.DD'));
    // MemberShip
  }, [data, index, memberShipData, categoryData])

  useEffect(() => {
    CategorySelectRef.current.setData(categoryData);
  }, [categoryData])

  // 시작날짜가 변경될때마다 회원권 종료일 자동 계산 ( 유효기간이 0이거나 시작날짜가 정해지지 않으면 무시 )
  useEffect(() => {
    if (startDate === '' || validity === 0) return;

    setEndDate(moment(startDate, 'YYYY.MM.DD').add(validity, 'days').format('YYYY.MM.DD'));
  }, [startDate, validity])

  function changeCategorySelectVal(value){
    
    var newDataList = [];

    for (var i = 0; i < memberShipData.length; i++) {
      if (memberShipData[i].categoryId === value.id) newDataList.push(memberShipData[i]);
    }
    
    setDivisionMemberShipData(newDataList);
    MemberShipSelectRef.current.setData(newDataList);
  }

  function changeMemberShipSelectVal(value) {
    setMaxCount(value.totalMax);
    setNotReceivedPrice(value.price);
  }

  function changeValue() {
    var card = CardPaymentRef.current.getValue();
    var cash = CashPaymentRef.current.getValue();

    setTotalPrice(parseInt(card) + parseInt(cash));
    // console.log(card, cash);
  }

  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <MemberShipTitle>회원권 · 수업 정보 수정</MemberShipTitle>
      </TitleWrapper>
      <InfoBox>
        <FirstLayerWrapper>
          <ComponentWrapper>
            <ComponentTitle>카테고리</ComponentTitle>
            <MemberShipCategorySelectBox ref={CategorySelectRef} data={categoryData} changeFn={changeCategorySelectVal}/>
          </ComponentWrapper>
          <ComponentWrapper>
            <ComponentTitle>회원권</ComponentTitle>
            <MemberShipSelectBox ref={MemberShipSelectRef} data={divisionMemberShipData} changeFn={changeMemberShipSelectVal}/>
          </ComponentWrapper>
        </FirstLayerWrapper>
        <SecondLayerWrapper>
          <ComponentWrapper style={{marginRight: `24px`}}>
            <ComponentTitle>회원권 시작일</ComponentTitle>
            <CalenderInput ref={StartCalenderRef} className='StartCalender' value={startDate} setValue={setStartDate} clickFn={CalenderClick} placeholder='시작일' width='max(194px, 25.26041666666667vw)'/>
          </ComponentWrapper>
          <ComponentWrapper style={{marginRight: `24px`}}>
            <ComponentTitle>회원권 종료일</ComponentTitle>
            <CalenderInput ref={EndCalenderRef} className='EndCalender' value={endDate} setValue={setEndDate} clickFn={CalenderClick} placeholder='종료일' width='max(194px, 25.26041666666667vw)'/>
          </ComponentWrapper>
          <ComponentWrapper>
            <ComponentTitle>최대 수강 횟수</ComponentTitle>
            <FixedInputWrapper>
              <FixedInput>{maxCount === -1 ? '무제한' : maxCount}</FixedInput>
              <FixedUnit>회</FixedUnit>
            </FixedInputWrapper>
          </ComponentWrapper>
        </SecondLayerWrapper>
      </InfoBox>
      <TitleWrapper style={{marginTop: `30px`}}>
        <MemberShipTitle>결제 정보</MemberShipTitle>
      </TitleWrapper>
      <InfoBox>
        <SecondLayerWrapper>
          <ComponentWrapper style={{marginRight: `24px`}}>
            <ComponentTitle>총 결제 금액</ComponentTitle>
            {/* <PaymentInput width={252} placeholder={0} disabled={true}/> */}
            <CalcPaymentInputWrapper>
              <CalcPaymentInput>{totalPrice}</CalcPaymentInput>
              <CalcPaymentUnit>원</CalcPaymentUnit>
            </CalcPaymentInputWrapper>
          </ComponentWrapper>
          <ComponentWrapper style={{marginRight: `24px`}}>
            <ComponentTitle>카드 결제 금액</ComponentTitle>
            <PaymentInput ref={CardPaymentRef} changeFn={changeValue} disabled={false} width={287} /*width='max(168px, 21.875vw)'*/ placeholder={0}/>
          </ComponentWrapper>
          <ComponentWrapper style={{marginRight: `24px`}}>
            <ComponentTitle>현금 결제 금액</ComponentTitle>
            <PaymentInput ref={CashPaymentRef} changeFn={changeValue} disabled={false} width={287} /*width='max(168px, 21.875vw)'*/ placeholder={0}/>
          </ComponentWrapper>
        </SecondLayerWrapper>
        <SecondLayerWrapper>
          <ComponentWrapper style={{ marginRight: `24px` }}>
            <ComponentTitle>미수금 금액</ComponentTitle>
            <CalcPaymentInputWrapper>
              {/* <CalcPaymentInput>{(notReceivedPrice - totalPrice) > 0 ? (notReceivedPrice - totalPrice) : 0}</CalcPaymentInput> */}
              <CalcPaymentInput>{notReceivedPrice}</CalcPaymentInput>
              <CalcPaymentUnit>원</CalcPaymentUnit>
            </CalcPaymentInputWrapper>
          </ComponentWrapper>
          <ComponentWrapper style={{marginRight: `24px`}}>
            <ComponentTitle>결제일</ComponentTitle>
            <CalenderInput ref={ApprovolCalenderRef} className='PaymentCalender' disabled={false} value={approvalDate} setValue={setApprovalDate} clickFn={CalenderClick} placeholder='결제일' width='315px' /*width='max(194px, 25.26041666666667vw)'*//>
          </ComponentWrapper>
        </SecondLayerWrapper>
      </InfoBox>
    </TopLevelWrapper>
  )
})

export default MemberShipInfoEditView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 1020px;
  // height: 593px;

  // border-radius: 30px;
  // background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;

  margin-top: 28px;
  // margin-left: 28px;
`;

const MemberShipTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  border-top: 2px solid #ECECEC;

  margin-top: 24px;
  // margin-left: 28px;
`;

const FirstLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 24px;
  margin-top: 24px;
`;

const SecondLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // gap: 24px;
  margin-top: 24px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const CalcPaymentInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
  margin-top: 8px;
`;

const CalcPaymentInput = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 8px;
  margin: 0 0 0 0;

  // width: 252px;
  width: max(168px, 21.875vw);
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const CalcPaymentUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #777777;
`;

const DirectDebitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 168px;
  height: 32px;

  border-radius: 8px;
  background-color: #33C8FF;

  :hover {
    cursor: pointer;
  }
`;

const DirectDebitText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const FixedInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const FixedInput = styled.div`
  display: flex;
  flex-direction: column;

  // width: 320px;
  width: max(168px, 21.875vw);
  height: 30px;

  justify-content: center;
  align-items: flex-start;

  margin: 8px 0 0 0;
  padding: 0 0 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const FixedUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 8px;

  color: #777777;
`;