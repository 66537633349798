import { React, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import FetchModule from '../Model/Share/Network/FetchModule.js';
import MyAccountView from '../Model/Individual/MySettingModel/MyAccountView';
import MyGymIntroductionText from '../Model/Individual/MySettingModel/MyGymIntroduceText';
import MyGymIntroduction from '../Model/Individual/MySettingModel/MyGymIntroduction';
import MyGymIntroductionEdit from '../Model/Individual/MySettingModel/MyGymIntroductionEdit';
import MyGymPicture from '../Model/Individual/MySettingModel/MyGymPicture';
import MyZoneMemberPaymentView from '../Model/Individual/MySettingModel/MyMemberPaymentView';
import MyPageTopModel from '../Model/Individual/MySettingModel/MyPageTopModel';
import MyZoneSubscribeInfo from '../Model/Individual/MySettingModel/MyZoneSubscribeInfo';
import RefundModal from '../Model/Individual/MySettingModel/RefundModal.js';
import useWindowSize from '../Model/Share/Normal/useWindowSize';
import SubscribeModal from '../Model/Individual/MySettingModel/SubscribeModal.js';

import SessionStorage from '../Model/Share/Network/SessionStorage.js';


function MySettingPage() {

  const history = useHistory();

  const [isClickedSubscribe, setIsClickedSubscribe] = useState(false);
  const [isClickedModify, setIsClickedModify] = useState(false);
  const [isClickedRefund, setIsClickedRefund] = useState(false);
  const [refundData, setRefundData] = useState();

  const [productName, setProductName] = useState('');
  const [productCoach, setProductCoach] = useState('');
  const [productValidity, setProductValidity] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productMerchantUid, setProductMerchantUid] = useState('');

  const [data, setData] = useState(undefined);
  const [salesList, setSalesList] = useState(new Array);
  const [subscribeList, setSubscribeList] = useState(new Array);
  const [rendering, setRendering] = useState(false);

  const [id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [phone, setPhone] = useState('');
  const [storeLogo, setStoreLogo] = useState('');
  const [storeName, setStoreName] = useState('');
  const [tel, setTel] = useState('');
  const [type, setType] = useState('');
  const [address, setAddress] = useState('');
  const [openingHour, setOpeningHour] = useState('');
  const [storeIntro, setStoreIntro] = useState('');
  const [profiles, setProfiles] = useState(new Array);
  const [isUsePaymentSystem, setIsUsePaymentSystem] = useState('No');
  

  const size = useWindowSize();

  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])
  

  useEffect(() => {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.getIndividualData('account/find', 'GET');
        
        if (responseData.status === 200) setData(responseData.data[0]);
        else if (responseData.status === 401) history.push('/');

        const subscribeResponseData = await fetchModule.getIndividualData('zone_subscribe/list');
        if (subscribeResponseData.status === 200) setSubscribeList(subscribeResponseData.data);

        const salesResponseData = await fetchModule.getIndividualData('sales/list', 'GET');
        if (salesResponseData.status === 200) setSalesList(salesResponseData.data);
			}
		)();
  }, [history, rendering])

  useEffect(() => {
    if (data === undefined) return;

    setId(data.loginId);
    setEmail(data.email);
    setOwnerName(data.ownerName);
    setPhone(data.phone);
    setStoreLogo(data.storeLogo);
    setStoreName(data.storeName);
    setAddress(data.storeAddress);
    setTel(data.storeTel);
    setType(data.storeType);
    setOpeningHour(data.openingHour);
    setStoreIntro(data.introduce);
    setIsUsePaymentSystem(data.isUsePaymentSystem);
    // setProfiles(data.profiles.split(','));

    var dates = data.profiles.split(',');
    var newData = [];
    for (var i = 0; i < dates.length; i++) {
      if (dates[i] === '') break;
      newData.push(dates[i]);      
    }
  
    setProfiles(newData);
  }, [data])

  // useEffect(() => {
  //   console.log(isClickedModify);
  //   console.log(ownerName);
  //   console.log(phone);

  // }, [isClickedModify])

  function onClickModify() {
    if (isClickedModify) {
      
    var newData = '';

    for (var i = 0; i < profiles.length; i++) {
      if (i === 0) newData = profiles[i];
      else newData = newData + '<-@->' + profiles[i];  
    }

      var requestData = {
        email: data.email,
        loginId: data.loginId,
        openingHour: openingHour,
        ownerName: ownerName,
        ownerSocialSecurityNul: '',
        phone: phone,
        introduce: storeIntro,
        profiles : newData,
        storeAddress: address,
        storeLogo: storeLogo,
        storeName: storeName,
        storeTel: tel,
        storeType: type,
        isUsePaymentSystem: isUsePaymentSystem,
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('account/modify', 'POST', requestData);
          
          if (responseData.status === 200) setData(responseData.data[0]);
          else if (responseData.status === 401) history.push('/');
        }
      )();
      setIsClickedModify(false);
    }
    else {
      setIsClickedModify(true);
    }
  }

  function onClickRefundButton(data) {
    
    // console.log(data);
    if (data.status === 0) {
      alert("아직 승인되지 않은 결제 내역입니다.");
      return;
    }
    else if (data.status === 2) {
      alert("옳바르지 않은 결제 내역입니다.");
      return;
    }
    else if (data.status === 3) {
      alert("이미 환불이 진행된 결제 내역입니다.");
      return;
    }

    setRefundData(data);
    setIsClickedRefund(true);
  }

  function onClickSubscribeButton() {
    setIsClickedSubscribe(true);
    
  }

  return (
    <Container>
      {isClickedSubscribe && <SubscribeModal 
          name={productName}
          validity={productValidity}
          price={productPrice}
          merchantUid={productMerchantUid} 
          setIsClicked={setIsClickedSubscribe}/>}
      {isClickedRefund && <RefundModal refundData={refundData} rendering={() => setRendering(!rendering)} exitClick={() => setIsClickedRefund(false)}/> }
      <MyPageTopModel isOver={isOver} isClicked={isClickedModify} onClickModifyButton={onClickModify}/>
      <MyAccountView isOver={isOver} isClicked={isClickedModify} onClickSubscribeButton={onClickSubscribeButton} id={id} email={email} owenrName={ownerName} setOwnerName={setOwnerName} phone={phone} setPhone={setPhone}/>
      {
        isClickedModify ?
          <MyGymIntroductionEdit
            isOver={isOver} 
            data={data} 
            storeLogo={storeLogo}
            setStoreLogo={setStoreLogo}
            storeName={storeName}
            setStoreName={setStoreName} 
            tel={tel} 
            setTel={setTel}
            type={type} 
            setType={setType}
            address={address}
            setAddress={setAddress} 
            openingHour={openingHour}
            setOpeningHour={setOpeningHour}
            isUsePaymentSystem={isUsePaymentSystem}
            setIsUsePaymentSystem={setIsUsePaymentSystem}/>
          :
          <MyGymIntroduction 
            isOver={isOver}
            storeLogo={storeLogo}
            storeName={storeName}
            tel={tel} 
            type={type} 
            address={address}
            openingHour={openingHour}
            isUsePaymentSystem={isUsePaymentSystem}/>
      }
      <MyGymIntroductionText isOver={isOver} isClicked={isClickedModify} storeIntro={storeIntro} setStoreIntro={setStoreIntro}/>
      <MyGymPicture isOver={isOver} isClicked={isClickedModify} profiles={profiles} setProfiles={setProfiles}/>
      { (isOver && !isClickedModify) && <MyZoneSubscribeInfo subscribeList={subscribeList}/> }
      { (isOver && !isClickedModify) && <MyZoneMemberPaymentView salesList={salesList} onClickRefundButton={onClickRefundButton}/> }
    </Container>
  )
}

export default MySettingPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;