import { React, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import Lottie from 'lottie-react-web';

import FetchModule from "../../Model/Share/Network/FetchModule";

import LoadingAnimation from '../../Animation/LoadingAnimation.json';

export default function CardRegistrationRequestPage({match}) {
  const history = useHistory();

  // 카드 등록을 위한 토클 발행 후 카드 등록 실행 //
  useEffect(() => {
    window.localStorage.setItem('Token', 'Bearer ' + match.params.token);

    const fetchModule = new FetchModule();
    (
      async() => {
        const responseData = await fetchModule.getDataOfCoachVersion2('card/auth_publication', 'GET');
        
        if (responseData.status === 200) {
          let token = responseData.data.token;
          let userName = responseData.data.name;
          let phone = responseData.data.phone;

          const obj = {};

          obj.PCD_PAY_TYPE = 'card';
          obj.PCD_PAY_WORK = 'AUTH';
          // obj.PCD_PAY_WORK = 'PAY'
          obj.PCD_CARD_VER = '01';
          obj.PCD_PAYER_AUTHTYPE = 'sms';
          obj.PCD_PAYER_NAME = userName;
          obj.PCD_PAYER_HP = phone;
          // obj.PCD_RST_URL = '/failed/registration_card';
          obj.PCD_RST_URL = 'https://coach.link-zone.org/api2/card/direct/save/' + token;
          // obj.PCD_RST_URL = 'http://172.30.1.6:8080/api2/card/direct/save/' + token;


          // obj.callbackFunction = getResult;
          obj.clientKey = '0F44C92BFB07F1BE60C5235A7254316D';
          // obj.clientKey = 'test_DF55F29DA654A8CBC0F0A9DD4B556486';

          setTimeout(() => {
            window.PaypleCpayAuthCheck(obj);
          }, 1500)
        }
        else {
          history.push('/400/카드등록 절차가 실패하였습니다');
        }
      }
    )();
  }, []);

  // // 결제 이후 콜백 //
  // function getResult(res) {
  //   if (res.PCD_PAY_RST === 'success' && (res.PCD_PAY_CODE === '0000' || res.PCD_PAY_CODE === 'CDAU1000')) {
  //     let requestData = {
  //       payerKey: res.PCD_PAYER_ID,
  //       cardName: res.PCD_PAY_CARDNAME,
  //       cardNum: res.PCD_PAY_CARDNUM
  //     }

  //     const fetchModule = new FetchModule();
  //     (
  //       async () => {
  //         const responseData = await fetchModule.postDataOfCoachVersion2('card/save', 'POST', requestData);

  //         if (responseData.status === 200) {
  //           window.location.href = "linkcoach://";
            
  //           // setTimeout(() => {
  //           //   window.open("about:blank", "_self");
  //           //   window.close();
  //           // }, 3000)
  //         }
  //         else {
  //           history.push('/fail/registration_card');
  //         }
  //       }
  //     )();
  //   }
  //   else {
  //     if (res.PCD_PAY_CODE === undefined) {
  //       console.log(res.PCD_PAY_MSG);
  //       alert(res.PCD_PAY_MSG);
  //     }
  //     else {
  //       console.log('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
  //       alert('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
  //     }
  //   }
  // }

              // <Lottie options={{ animationData: LoadingAnimation }} />
  // return <></>

  return (
    <TopLevelWrapper>
      <LottieWrapper>
        <LottieBox>
          <Lottie options={{ animationData: LoadingAnimation }} />
        </LottieBox>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000'>결제 모듈 로딩중...</Text>
      </LottieWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const LottieBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};

  color: ${props => props.color};

  margin: 0 0 0 0;
`;