import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

const TimeInput = forwardRef((props, ref) => {
	const { className, placeholder, type, clickCountFn, isOver } = props;
	const [value, setValue] = useState('');
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			setChangedPlaceholderColor(true);
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#CBCBCB';
		}
	}));

	// useEffect(() => {
	// 	clickCountFn(className, clickCount);
	// }, [clickCount, value])

	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';
		// setDefaultPlaceholder('');
	}

	function handleChange(e) {
    const regex = /^[0-9:\-\b]{0,11}$/;
    
		// if (regex.test(e.target.value)) {
		// 	setValue(e.target.value);
		// }

		
		if (regex.test(e.target.value)) {
			if (e.target.value.length === 2)
				setValue(e.target.value.replace(/(\d{2})/, '$1:'));
			else if (e.target.value.length === 5)
				setValue(e.target.value.replace(/(\d{2}):(\d{2})/, '$1:$2-'));
			else if (e.target.value.length === 8)
				setValue(e.target.value.replace(/(\d{2}):(\d{2})-(\d{2})/, '$1:$2-$3:'));
			else
				setValue(e.target.value);
		}
		
	
		// for (let i = 0 ; i < e.target.value.length ; i++){
		// 	if (i !== 2 && i !== 5 && i !== 8)
		// 	{
		// 		if (e.target.value[i] === ':' || e.target.value[i] === '-'){
		// 			setValue(e.target.value.substr(0, e.target.value.length-1));	
		// 		}
		// 	}	
		// }
		setChanged(true);
	}

	function handleKeyDown(e) {
		if (e.key === 'Backspace') {
			if (value.length === 3 || value.length === 6 || value.length === 9) {
				console.log(e.key);
				console.log(value.substring(0, value.length - 1));
				setValue(value.substring(0, value.length - 1));
			}
		}
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#CBCBCB';
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return <Input
		ref={inputRef}
		isOver={isOver}
		clickCount={clickCount}
		type={type}
		placeholder={defaultPlaceholder}
		value={value}
		changedColor={changedPlaceholderColor}
		className={`${className} ${changed ? "changed" : ""}`}
		onClick={handleClick}
		onChange={handleChange}
		onKeyDown={handleKeyDown}
		onFocus={handleFocus}
		onBlur={handleBlur}/>
});

export default TimeInput;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

  // padding: 0 0 0 40px;
  margin: 8px 0 0 0;
  padding: 0 0 0 8px;

  width: ${props => props.isOver ? `272px` : `224px`};
  height: 30px;

	outline: none;
  border: 1px solid #CBCBCB;
  border-radius: 8px;
	color: #000000;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 14px;
    
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.3s ease-in-out;
`;