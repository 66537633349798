import { React, forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Model/Share/Network/FetchModule';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

export default function WodLoadingMemberSelectBox(props) {
	const { options, setMemberIndex, rendering} = props;

	const [isClicked, setIsClicked] = useState(false);
	const [categoryId, setCategoryId] = useState('');
	const [defaultVal, setDefaultVal] = useState({ name: '', phone: '01000000000' });

	const SelectRef = useRef();
	const OptionWrapperRef = useRef([]);

  useEffect(() => {
    if (options.length === 0) return;

		if (options.findIndex(i => i.id === categoryId) === -1 ) {
      setDefaultVal(options[0]);
      setMemberIndex(0);
    }
		else {
      setDefaultVal(options[options.findIndex(i => i.id === categoryId)]);
      setMemberIndex(options.findIndex(i => i.id === categoryId));
    }
  }, [options, categoryId])

	function onClick() {
		if (options.length === 0) return;
		
		if (isClicked) {
			SelectRef.current.style.border = '1px solid #CBCBCB';
			SelectRef.current.style.height = `38px`;
			// SelectRef.current.style.height = `26px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = (38 * (options.length)) > 400 ? `400px` : ((38 * (options.length)) + 'px');
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
		setDefaultVal(options[index]);
    setMemberIndex(index);
		// setScheduleId(options[index].scheduleId);
		rendering();
	}

	return (
		<TopLevelWrapper>
			<SelectBox
				ref={SelectRef}
				isClicked={isClicked}
				buttonPath={isClicked ? SelectOnPath : SelectOffPath}
				onClick={onClick}>
				{
					isClicked ?
						options.map((data, index) =>
							<OptionWrapper 
								key={index} 
								ref={element => (OptionWrapperRef.current[index] = element)}
								onClick={() => optionalClick(index)}>
								<ClassNameOption 
									isClicked={isClicked}>
										{data.name}
								</ClassNameOption>
								<ClassTimeOption 
									isClicked={isClicked}>
										{data.phone.slice(0, 3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11)}
								</ClassTimeOption>
							</OptionWrapper>
						)
						:
						<OptionWrapper>
							<ClassNameOption isClicked={isClicked}>{options.length === 0 ? '저장 내역 없음' : defaultVal.name}</ClassNameOption>
							<ClassTimeOption isClicked={isClicked}>{options.length === 0 ? '' : defaultVal.phone.slice(0, 3) + '-' + defaultVal.phone.slice(3, 7) + '-' + defaultVal.phone.slice(7, 11)}</ClassTimeOption>
						</OptionWrapper>
				}
			</SelectBox>
		</TopLevelWrapper>
	)
}

const TopLevelWrapper = styled.div`
  position: relative;
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
	width: 100%;
	height: 40px;
`;

const SelectBox = styled.div`
	position: absolute;

	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

	// padding: 6px 0px 6px 16px;
  padding: 0px 0px 0px 16px;

	// width: 392px;
	// height: 26px;
  width: calc(100% - 18px);
  height: 38px;

  z-index: 3;

	border: 1px solid #CBCBCB;
	// border-radius: 15px;
  border-radius: 8px;
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `1` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: flex-start;
	align-items: center;

	width: 100%;
	min-height: 38px;
	// height: 100%;

	:hover {
		cursor: pointer;
		color: #FF8B48;
	}
`;

const ClassNameOption = styled.p`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

	font-family: NotoSansKR-Medium;
	font-size: 14px;

	margin: 0 0 0 0;

	// width: calc(96% - 16px);
	width: calc(30% - 16px);
	// height: 38px;

	overflow: hidden;

  transition: all 0.3s ease-in-out;

`;


const ClassTimeOption = styled.p`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

	font-family: NotoSansKR-Medium;
	font-size: 14px;

	margin: 0 0 0 0;

	// width: calc(96% - 16px);
	width: calc(60% - 16px);
	// height: 38px;

	overflow: hidden;

  transition: all 0.3s ease-in-out;

`;