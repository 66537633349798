import { React, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const TableColumn = forwardRef((props, ref) => {
  const { applyWeek } = props;
  const [startDay, setStartDay] = useState(moment());
  const [dateList, setDateList] = useState([startDay]);

  useImperativeHandle(ref, () => ({
    setStartDay: (val) => {
      setStartDay(val)
    }
  }));
 
  useEffect(() => {
    var newDateList = [];

    for (var i = 0; i < 7; i++) {
      newDateList.push(startDay.clone().add(i, 'day'));
    }
    setDateList(newDateList);
  }, [startDay])

  return (
    <TopLevelWrapper>
      <ColumnWrapper>
        {
          dateList.map((data, index) => (
            applyWeek[index] ?
              <Column key={index}>{data.format('DD (일)')}</Column>
              :
              null
          ))
        }
      </ColumnWrapper>
    </TopLevelWrapper>
  )
})

export default TableColumn;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-bottom: 20px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  // justify-content: space-evenly;
  align-items: center;

  gap: 49px;
  width: 100%;
  margin: 14.09px 0 0 40px;
`;

const Column = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 10px;

  color: #595959;

  margin: 0 0 0 0;

  -webkit-transform: scale(0.897);
  
  transition: all 0.3s ease-in-out;
`;