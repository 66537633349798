import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import PrevButtonImage from '../../../../image/NormalPrevInfoButton.png';
import ColorNextButtonImage from '../../../../image/ColorNextInfoButton.png';

const FirstMemberShipHistoryColumn = (props) => {
  const { isOver, nextFn } = props;

  return (
    <TopLevelWrapper isOver={isOver}>
      {
        isOver ?
          <ColumnWrapper>
            <Column style={{ marginRight: 'max(65px, 8.463541666666667vw)', marginLeft: 'max(28px, 3.645833333333333vw)' }} isOver={isOver}>회원권</Column>
            <Column style={{ marginRight: 'max(132px, 17.1875vw)' }} isOver={isOver}>기간</Column>
            <Column style={{ marginRight: 'max(28px, 3.645833333333333vw)' }} isOver={isOver}>최대 수강</Column>
            <Column style={{ marginRight: 'max(28px, 3.645833333333333vw)' }} isOver={isOver}>수강 횟수</Column>
            <Column style={{ marginRight: 'max(45px, 5.859375vw)' }} isOver={isOver}>결제금액</Column>
          </ColumnWrapper>
          :
          <ColumnWrapper>
            <Column style={{ marginRight: 'max(33px, 10.3125vw)', marginLeft: 'max(5px, 1.5625vw)' }} isOver={isOver}>회원권</Column>
            <Column style={{ marginRight: 'max(76px, 23.75vw)' }} isOver={isOver}>기간</Column>
            <Column style={{ marginRight: 'max(25px, 7.8125vw)' }} isOver={isOver}>최대 수강</Column>
          </ColumnWrapper>
      }
      {
        isOver ?
          <ButtonWrapper isOver={isOver}>
            <Button src={PrevButtonImage}  isOver={isOver}/>
            <Column isOver={isOver}>다음 정보</Column>
            <Button src={ColorNextButtonImage} isOver={isOver} onClick={nextFn} />
          </ButtonWrapper>
          :
          <ButtonWrapper isOver={isOver}>
            <Button src={PrevButtonImage} isOver={isOver}/>
            <Button src={ColorNextButtonImage} isOver={isOver} onClick={nextFn} />
          </ButtonWrapper>
      }
    </TopLevelWrapper>
  )
}

export default FirstMemberShipHistoryColumn;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: ${props => props.isOver ? `34px` : `23px`};
  margin-bottom: ${props => props.isOver ? `18px` : `15px`};

  // width: ${props => props.isOver ? `max(664px, 86.45833333333333vw)` : `max(262px, 81.875vw)`};
  width: ${props => props.isOver ? `max(664px, 86.45833333333333vw)` : `max(268px, 83.75vw)`};
  
  transition: all 0.3s ease-in-out;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `4px` : `6px`};
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Button = styled.img`
  width: ${props => props.isOver ? `24px` : `15px`};
  height: ${props => props.isOver ? `24px` : `15px`};

  border-radius: ${props => props.isOver ? `24px` : `15px`};

  :hover {
    cursor: pointer;
  }
`;