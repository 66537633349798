import { React, useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../Share/Network/FetchModule.js';

import LoginSubject from '../../Individual/AccountModel/LoginSubject.js';
import SignUpSubject from './SignUpSubject.js';

import LoginInfo from '../../Individual/AccountModel/LoginInfo.js';
import SignUpInfo1 from '../../Individual/AccountModel/SignUpInfo1.js';
import SignUpInfo2 from '../../Individual/AccountModel/SignUpInfo2.js';
import SignUpInfo3 from '../../Individual/AccountModel/SignUpInfo3.js';
import SignUpInfo4 from '../../Individual/AccountModel/SignUpInfo4.js';

import LoginBottomComponent from './LoginBottomComponent.js';
import SignUpFirstBottomComponent from './SignUpFirstBottomComponent.js';
import SignUpSecondBottomComponent from './SignUpSecondBottomComponent.js';
import SignUpThirdBottomComponent from './SignUpThirdBottomComponent.js';

const InfoView = forwardRef((props, ref) => {
	const { kakaoAddrClick, signUpClick, isOver } = props;

	// const [nowPage, setNowPage] = useState(-1);
	const [isView, setIsView] = useState(false);
	let page = -1;

  const history = useHistory();

	const TopLevelRef = useRef();
	const LoginSubjectRef = useRef();
	const SignUpSubjectRef = useRef();

	const LoginInfoRef = useRef(null);
	const SignUpInfoFirstRef = useRef();
	const SignUpInfoSecondRef = useRef();
	const SignUpInfoThirdRef = useRef();
	const SignUpInfoFourthRef = useRef();

	const LoginBottomRef = useRef();
	const SignUpFirstBottomRef = useRef();
	const SignUpSecondBottomRef = useRef();
	const SignUpThirdBottomRef = useRef();

	useImperativeHandle(ref, () => ({
		setAddress: (val) => {
			SignUpInfoFourthRef.current.setAddress(val);
		},
		getUserName: () => {
			return SignUpInfoFirstRef.current.getOwnerName();
		},
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		}
	}))

	useEffect(() => {
    setTimeout(function() {
			// LoginInfoRef.current.focus();
			setIsView(true);
    }, 1000)
	}, [LoginInfoRef])

	// email형식 확인 정규식
	function isEmail(asValue) {
		var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

		return regExp.test(asValue);
	}

	function onClickLoginCategory() {
		page = 1;
		LoginSubjectRef.current.setOpacity(0);
		SignUpSubjectRef.current.setOpacity(1);
		SignUpSubjectRef.current.setNowPage(1);

		// LoginInfoRef.current.setOpacity(0);
		LoginInfoRef.current.setLocation(-150);
		SignUpInfoFirstRef.current.setOpacity(1);
		SignUpInfoFirstRef.current.setLocation(0);

		LoginBottomRef.current.setOpacity(0);
		SignUpFirstBottomRef.current.setOpacity(1);
	}

	function onClickSignUpCategory() {
		LoginSubjectRef.current.setOpacity(1);
		SignUpSubjectRef.current.setOpacity(0);
		SignUpSubjectRef.current.setNowPage(-1);

		// Login window and first sign up init
		LoginInfoRef.current.setLocation(0);
		SignUpInfoFirstRef.current.setLocation(150);
		SignUpInfoFirstRef.current.setOpacity(0);
		// second sign up window init
		SignUpInfoSecondRef.current.setLocation(150);
		SignUpInfoSecondRef.current.setOpacity(0);
		// third sign up window init
		SignUpInfoThirdRef.current.setLocation(150);
		SignUpInfoThirdRef.current.setOpacity(0);
		// forth sign up window init
		SignUpInfoFourthRef.current.setLocation(150);
		SignUpInfoFourthRef.current.setOpacity(0);

		SignUpThirdBottomRef.current.setOpacity(0);
		SignUpSecondBottomRef.current.setOpacity(0);
		SignUpFirstBottomRef.current.setOpacity(0);
		LoginBottomRef.current.setOpacity(1);
	}

	function onClickNextButton() {
		var nowPage = SignUpSubjectRef.current.getNowPage();
		var undefinedCount = 0;
		
		if (nowPage === 1) {
			// 관리자 이름을 입력하지 않았을 경우
			if (SignUpInfoFirstRef.current.getOwnerName() === '') {
				SignUpInfoFirstRef.current.isBlankOwnerName();
				undefinedCount++;
			}
			// 아이디를 입력하지 않았을 경우
			if (SignUpInfoFirstRef.current.getUserId() === '') {
				SignUpInfoFirstRef.current.isBlankId();
				undefinedCount++;
			}
			// 아이디 중복검사를 진행하지 않았을 경우
			if (!SignUpInfoFirstRef.current.getAvailableOfId() || SignUpInfoFirstRef.current.getUserId() !== SignUpInfoFirstRef.current.getCertifiedId()) {
				SignUpInfoFirstRef.current.didNotCheckAvailableId();
				undefinedCount++;
			}
			// 패스워드를 입력하지 않았을 경우
			if (SignUpInfoFirstRef.current.getUserPw() === '') {
				SignUpInfoFirstRef.current.isBlankPassword();
				undefinedCount++;
			}

			// 1개라도 걸리면 진행하지 않음.
			if (undefinedCount > 0) return;

			SignUpSubjectRef.current.setNowPage(2);
			SignUpInfoFirstRef.current.setLocation(-150);
			SignUpInfoFirstRef.current.setOpacity(0);

			SignUpFirstBottomRef.current.setOpacity(0);

			SignUpInfoSecondRef.current.setLocation(0);
			SignUpInfoSecondRef.current.setOpacity(1);

			SignUpSecondBottomRef.current.setOpacity(1);
		}
		else if (nowPage === 2) {
			// 주민등록번호를 입력하지 않았을때
			if (SignUpInfoSecondRef.current.getSocialSecurityNumber() === '-******') {
				SignUpInfoSecondRef.current.isBlankSocialSecurityNumber();
				undefinedCount++;
			}
			// 올바르지 않은 주민등록번호를 입력했을때
			else if (SignUpInfoSecondRef.current.getSocialSecurityNumber().length !== 14) {
				SignUpInfoSecondRef.current.isInvalidSocialSecurityNumber();
				undefinedCount++;
			}

			// 휴대폰번호를 입력하지 않았을때
			if (SignUpInfoSecondRef.current.getPhoneNumber() === '') {
				SignUpInfoSecondRef.current.isBlankPhoneNumber();
				undefinedCount++;
			}
			// 올바르지 않은 휴대폰번호를 입력했을때
			else if (SignUpInfoSecondRef.current.getPhoneNumber().length < 11) {
				SignUpInfoSecondRef.current.isInvalidPhoneNumber();
				undefinedCount++;
			}

			// 이메일을 입력하지 않았을때
			if (SignUpInfoSecondRef.current.getEmail() === '') {
				SignUpInfoSecondRef.current.isBlankEmail();
				undefinedCount++;
			}
			// 올바르지 않은 이메일을 입력했을때
			else if (!isEmail(SignUpInfoSecondRef.current.getEmail())) {
				SignUpInfoSecondRef.current.isInvalidEmail();
				undefinedCount++;
			}
			// 이메일 계정확인을 하지 않았을 경우
			else if (!SignUpInfoSecondRef.current.getIsCheckEmail()) {
				SignUpInfoSecondRef.current.didNotCheckEmail();
				undefinedCount++;
			}

			// 1개라도 걸리면 진행하지 않음.
			if (undefinedCount > 0) return;

			SignUpSubjectRef.current.setNowPage(3);
			SignUpInfoSecondRef.current.setLocation(-150);
			SignUpInfoSecondRef.current.setOpacity(0);

			SignUpInfoThirdRef.current.setLocation(0);
			SignUpInfoThirdRef.current.setOpacity(1);
		}
		else if (nowPage === 3) {
			if (SignUpInfoThirdRef.current.getStoreName() === '') {
				SignUpInfoThirdRef.current.isBlankStoreName();
				undefinedCount++;
			}

			if (SignUpInfoThirdRef.current.getStoreTel() === '') {
				SignUpInfoThirdRef.current.isBlankStoreTel();
				undefinedCount++;
			}

			// 1개라도 걸리면 진행하지 않음.
			if (undefinedCount > 0) return;

			SignUpSubjectRef.current.setNowPage(4);
			SignUpInfoThirdRef.current.setLocation(-150);
			SignUpInfoThirdRef.current.setOpacity(0);

			SignUpSecondBottomRef.current.setOpacity(0);

			SignUpInfoFourthRef.current.setLocation(0);
			SignUpInfoFourthRef.current.setOpacity(1);

			SignUpThirdBottomRef.current.setOpacity(1);
		}
	}

	function onClickPrevButton() {
		var nowPage = SignUpSubjectRef.current.getNowPage();

		if (nowPage === 2) {
			SignUpSubjectRef.current.setNowPage(1);
			SignUpInfoSecondRef.current.setLocation(150);
			SignUpInfoSecondRef.current.setOpacity(0);

			SignUpSecondBottomRef.current.setOpacity(0);

			SignUpInfoFirstRef.current.setLocation(0);
			SignUpInfoFirstRef.current.setOpacity(1);

			SignUpFirstBottomRef.current.setOpacity(1);
		}
		else if (nowPage === 3) {
			SignUpSubjectRef.current.setNowPage(2);
			SignUpInfoThirdRef.current.setLocation(150);
			SignUpInfoThirdRef.current.setOpacity(0);

			SignUpInfoSecondRef.current.setLocation(0);
			SignUpInfoSecondRef.current.setOpacity(1);
		}
		else if (nowPage === 4) {
			SignUpSubjectRef.current.setNowPage(3);
			SignUpInfoFourthRef.current.setLocation(150);
			SignUpInfoFourthRef.current.setOpacity(0);
			SignUpInfoFourthRef.current.reset();

			SignUpThirdBottomRef.current.setOpacity(0);

			SignUpInfoThirdRef.current.setLocation(0);
			SignUpInfoThirdRef.current.setOpacity(1);

			SignUpSecondBottomRef.current.setOpacity(1);
		}
	}

	function onClickLoginButton() {
		var userId = LoginInfoRef.current.getUserId();
		var userPW = LoginInfoRef.current.getUserPw();
		var blankCount = 0;

		if (userId === '') {
			LoginInfoRef.current.isBlankId();
			blankCount++;
		}

		if (userPW === '') {
			LoginInfoRef.current.isBlankPw();
			blankCount++;
		}

		if (blankCount > 0) return; 

		const data = {
			account: userId,
			password: userPW
		}

		const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('account/asp/login', 'POST', data);

				if (responseData.status === 200) {
					window.localStorage.setItem('Token', 'Bearer ' + responseData.token);
					window.localStorage.setItem('RefreshToken', 'Bearer ' + responseData.refreshToken);
					window.localStorage.setItem('StoreId', responseData.storeId);
					window.localStorage.setItem('AccountId', userId);
					history.push('/main');
				}
				else LoginInfoRef.current.failLogin();
			}
		)();
	}

	function onClickSignUp() {
		var ownerName = SignUpInfoFirstRef.current.getOwnerName();
		var userId = SignUpInfoFirstRef.current.getUserId();
		var userPW = SignUpInfoFirstRef.current.getUserPw();
		var socialSecurityNumber = SignUpInfoSecondRef.current.getSocialSecurityNumber();
		var phone = SignUpInfoSecondRef.current.getPhoneNumber();
		var email = SignUpInfoSecondRef.current.getEmail();
		var storeName = SignUpInfoThirdRef.current.getStoreName();
		var type = SignUpInfoThirdRef.current.getStoreType();
		var tel = SignUpInfoThirdRef.current.getStoreTel();
		var address = SignUpInfoFourthRef.current.getAddress();
		var logo = SignUpInfoFourthRef.current.getLogo();

		const data = {
			loginId: userId,
			password: userPW,
			storeName: storeName,
			storeType: type,
			storeLogo: logo,
			storeAddress: address,
			storeTel: tel,
			ownerSocialSecurityNum: socialSecurityNumber,
			ownerName: ownerName,
			phone: phone,
			email: email
		} 
		
		const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('account/asp/sign-up', 'POST', data);

				if (responseData.status === 200) signUpClick();
				else alert("회원가입에 실패하였습니다. 잠시후 다시시도해 주세요.");
			}
		)();
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<DisplayWrapper>
				<InfoBox isOver={isOver}>
					<InfoWrapper isOver={isOver}>
						<TopInfo isOver={isOver}>
							<LoginSubject ref={LoginSubjectRef} onClickLoginCategory={onClickLoginCategory} isOver={isOver}/>
							<SignUpSubject ref={SignUpSubjectRef} onClickSignUpCategory={onClickSignUpCategory} isOver={isOver}/>
						</TopInfo>
						
						<LoginInfo ref={LoginInfoRef} tabIndex={-1} isOver={isOver}/>
						{ isView && <SignUpInfo1 ref={SignUpInfoFirstRef} tabIndex={-1} isOver={isOver}/> } 
						{ isView && <SignUpInfo2 ref={SignUpInfoSecondRef} tabIndex={-1} isOver={isOver}/> }
						{ isView && <SignUpInfo3 ref={SignUpInfoThirdRef} tabIndex={-1} isOver={isOver}/> }
						{ isView && <SignUpInfo4 ref={SignUpInfoFourthRef} tabIndex={-1} kakaoAddrClick={kakaoAddrClick} isOver={isOver}/> }
						
						<LoginBottomComponent ref={LoginBottomRef} tabIndex={-1} onClickLoginButton={onClickLoginButton} isOver={isOver}/>
						<SignUpFirstBottomComponent ref={SignUpFirstBottomRef} tabIndex={-1} onClickNextButton={onClickNextButton} isOver={isOver}/>
						<SignUpSecondBottomComponent ref={SignUpSecondBottomRef} tabIndex={-1} onClickNextButton={onClickNextButton} onClickPrevButton={onClickPrevButton} isOver={isOver}/>
						<SignUpThirdBottomComponent ref={SignUpThirdBottomRef} tabIndex={-1} onClickSignUp={onClickSignUp} onClickPrevButton={onClickPrevButton} isOver={isOver}/>
					</InfoWrapper>
				</InfoBox>
			</DisplayWrapper>
		</TopLevelWrapper>
	)
})

export default InfoView;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;

	// box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
	// border-radius: 30px;
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const DisplayWrapper = styled.div`
	// position: relative;
	display: flex;
	flex-direction: column;

	// overflow: hidden;
`;

const InfoBox = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: center;

	// width: 449px;
	width: ${props => props.isOver ? `max(449px, 58.46354166666667vw)` : `max(290px, 90.625vw)`};
	height: 472px;
	// height: max(472px, 61.45833333333333vw);
	overflow: hidden;

	border-radius: 30px;
	background-color: rgba(255, 255, 255, 1);
	// background-color: red;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;

	// width: 369px;

	width: ${props => props.isOver ? `max(369px, 48.046875vw)` : `max(248px, 77.5vw)`};
	height: ${props => props.isOver ? `393px` : `368px`};

	
`;

const TopInfo = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;

	margin-top: ${props => props.isOver ? `40px` : `33px`};
`;