import { React } from 'react';
import styled from 'styled-components';

function InputView(props) {
  const { isOver, column, content } = props;

  return (
    <TopLevelWrpper>
      <Column>{column}</Column>
      <InputBox isOver={isOver}>
        <Content>{content}</Content>
      </InputBox>
    </TopLevelWrpper>
  )
}

export default InputView;

const TopLevelWrpper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 8px;

  // width: 363px;
  // width: 90%;
  width: ${props => props.isOver ? `max(306px, 15.9375vw)` : `max(254px, 13.22916666666667vw)`};
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;
`;

const Column = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  } 
`;

const Content = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 8px;

  :hover {
    cursor: pointer;
  } 
`;