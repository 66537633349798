import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import TimeDivisionLine from '../../../Component/MainComponent/TimeDivisionLine.js';
import ClassInfoBox from '../../../Component/MainComponent/ClassInfoBox.js';

import FetchModule from '../../Share/Network/FetchModule.js';

function TodayClass(props) {
  const { isOver } = props;

  const history = useHistory();

  const [today, setToday] = useState(moment());
  const [scheduleData, setScheduleData] = useState([]);
  const [breakTimeData, setBreakTimeData] = useState([]);
  const [useScheduleData, setUseScheduleData] = useState([]);
  const [reserveData, setReserveData] = useState([]);
  const [reserveRendering, setReserveRendering] = useState(false);

  // Schedule Data API호출
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        // const responseData = await fetchModule.getData('schedule/', 'GET');
        const responseData = await fetchModule.postData('schedule/', 'POST');
        // console.log(responseData);
        if (responseData.status === 200) setScheduleData(responseData.data);
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history])

  // BreakTime Data API호출
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('breaktime/', 'GET');

        if (responseData.status === 200) setBreakTimeData(responseData.data);
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history])

  // Schedule Data를 사용 가능한 Data로 가공
  useEffect(() => {
    var tmp = scheduleData.slice();
    var newScheduleData = [];

    for (var i = 0; i < tmp.length; i++) {

      var endPoint = moment(tmp[i].classDate.split('T')[0]);

      while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {

        if (endPoint.diff(today, 'days') >= 30) break;

        if (tmp[i].applyWeek.includes(endPoint.format('ddd')) || tmp[i].applyWeek === 'All') {
          var colSpan = 1;
          var rowSpan = 1;

          // 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
          colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);

          var newData = {
            classId: tmp[i].classId,
            classDate: endPoint.format('YYYY.MM.DD'),
            className: tmp[i].className,
            classTime: tmp[i].classTime,
            color: tmp[i].color,
            endDate: tmp[i].endDate,
            lessonId: tmp[i].lessonId,
            scheduleId: tmp[i].scheduleId,
            teacherName: tmp[i].teacherName,
            profile: tmp[i].profile,
            applyWeek: tmp[i].applyWeek,
            rowSpan: rowSpan,
            colSpan: colSpan,
            maxBookedPeople: tmp[i].maxBookedPeople,
            deduction: tmp[i].deduction
          }
          newScheduleData.push(newData);
        }
        endPoint = endPoint.clone().add(1, 'day');
      }
    }

    newScheduleData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    var newData = [];

    for (i = 0; i < newScheduleData.length; i++) {

      if (newScheduleData[i].classDate === today.clone().format('YYYY.MM.DD')) {
        newData.push(newScheduleData[i]);
      }
    }

    // breaktime 데이터 가공
    for (var z = 0; z < breakTimeData.length; z++) {
      if (breakTimeData[z].applyWeek.includes(today.clone().format('ddd')) || breakTimeData[z].applyWeek === 'All') {

        var breakData = {
          classId: -1,
          lessonId: -1,
          classDate: today.clone().format('YYYY.MM.DD'),
          color: '#ECECEC',
          classTime: breakTimeData[z].startTime + '-' + breakTimeData[z].endTime,
          // className: '브레이크 타임',
          className: 'Open Gym',
          applyWeek: breakTimeData[z].applyWeek
        }

        newData.push(breakData);
      }
    }

    newData.sort(function (a, b) {
      return a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0;
    })

    // TimeDivisionLine에서 사용할 데이터 가공
    for (i = 0; i < newData.length; i++) {
      tmp = newData[i].classTime.split('-');
      var splitTmp = tmp[0].split(':');
      var Time = '';

      if (parseInt(splitTmp[0]) > 12) {
        newData[i].startTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
        Time = (parseInt(splitTmp[0]) - 12) + ' PM';
      }
      else {
        newData[i].startTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
        Time = parseInt(splitTmp[0]) + ' AM';
      }

      tmp = newData[i].classTime.split('-');
      splitTmp = tmp[1].split(':');

      if (parseInt(splitTmp[0]) > 12) {
        newData[i].endTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
      }

      else {
        newData[i].endTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
      }

      if (i !== 0 && newData[i - 1].startTime.split(':')[0] === newData[i].startTime.split(':')[0]) {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: false })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
      else {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: true })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
    }

    var newReserve = [];

    for (var h = 0; h < newData.length; h++) {
      newReserve.push(new Array());
    }

    setReserveData(newReserve);
    setUseScheduleData(newData);
  }, [scheduleData, breakTimeData, today])
  
  // // ReservationData
  // useEffect(() => {
  //   // if (useScheduleData.length === 0) return;
  //   var dataList = [];

  //   for (var i = 0; i < useScheduleData.length; i++) {
  //     var data = {
  //       lessonId: useScheduleData[i].lessonId,
  //       classDate: useScheduleData[i].classDate.replaceAll('.', '-') + 'T00:00:00',
  //       classTime: useScheduleData[i].classTime
  //     }
  //     dataList.push(data);
  //   }
  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       const responseData = await fetchModule.postData('reserve/list', 'POST', dataList);

  //       if (responseData.status === 200) setReserveData(responseData.data);
  //       // else if (responseData.status === 401) history.push('/');
  //     }
  //   )();
  // }, [useScheduleData, reserveRendering])

  return (
    <TopLevelWrapper isOver={isOver}>
      <TitleWrapper>
        <Title isOver={isOver}>오늘의 수업 일정</Title>
        <ExplanationWrapper isOver={isOver}>
          <Explanation isOver={isOver}>{today.clone().format('YYYY.MM.DD')} 수업 일정을 확인하세요</Explanation>
          <Go isOver={isOver} onClick={() => history.push('/class')}>자세히 보기</Go>
        </ExplanationWrapper>
        <ViewBox isOver={isOver}>
          <TimeDivisionLineWrapper>
            {
              useScheduleData.map((data, index) => (
                <TimeDivisionLine key={index} scheduleData={data} isOver={isOver} isMain={true}/>
              ))
            }
          </TimeDivisionLineWrapper>
          <ClassInfoBoxWrapper>
            {
              useScheduleData.map((data, index) => (
                <ClassInfoBox 
                  key={index} 
                  data={data} 
                  isOver={isOver} 
                  reserveData={reserveData[index]}
                  reserveRendering={reserveRendering}
                  setReserveRendering={setReserveRendering}/>
              ))
            }
          </ClassInfoBoxWrapper>
        </ViewBox>
      </TitleWrapper>
    </TopLevelWrapper>
  )
}

export default TodayClass;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 692px;
  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(298px, 93.125vw)`};
  height: ${props => props.isOver ? `600px` : `593px`};

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  margin-top: 4px;

  // width: 636px;
  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(255px, 79.6875vw)`};
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};
  
  color: #777777;
  
  margin: 0 0 0 0;
`;

const Go = styled.p`
  font-family: NotoSansKr-Medium;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #FF8B48;
  margin: 0 0 0 0;
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  // align-items: center;

  overflow-y: scroll;

  // width: 636px;
  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(255px, 79.6875vw)`};
  height: ${props => props.isOver ? `474px` : `487px`};

  margin: 24px 0 0 0;
  padding: 24px 0 0 0;
  
  border-top: 2px solid #ECECEC;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const TimeDivisionLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClassInfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
