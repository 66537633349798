import { React, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import CountSetting from '../../../Component/BoardComponent/CountSetting';
import StartCountInput from '../../../Component/BoardComponent/StartCountInput';
import TitleInput from '../../../Component/BoardComponent/TitleInput';

import FetchModule from '../../Share/Network/FetchModule';

import URLInput from '../../../Component/NoteComponent/URLInput';

import DeleteButtonImg from '../../../image/DeleteButton.png';

const StopWatchAddModal = (props) => {
  const { rendering, exitClickFn } = props;

  const history = useHistory();

  const TitleRef = useRef();
  const StartRef = useRef();
  const SettingRef = useRef();

  function exitClick() {
    exitClickFn();
  }

  function saveClick() {
    var data = SettingRef.current.getValue();
    
    data.title = TitleRef.current.getValue();
    data.startCount = StartRef.current.getValue();

    if (data.title === undefined || data.title === '') {
      alert('타이틀을 입력해주세요');
      return;
    }
    else if (data.round === undefined || data.round === '') {
      alert('정확한 값을 설정해주세요');
      return;
    }
    else if (data.exerciseTime === undefined || data.exerciseTime === '') {
      alert('정확한 값을 설정해주세요');
      return;
    }
    else if (data.restTime === undefined || data.restTime === '') {
      alert('정확한 값을 설정해주세요')
      return;
    }
    
    (
      async () => {
        const fetchModule = new FetchModule();
        const responseData = await fetchModule.postData('stop_watch/create/', 'POST', data);
        console.log(responseData);
        if (responseData.status === 200) {
          rendering();
          exitClickFn();
				}
        else if (responseData.status === 205) {}
        else if (responseData.status === 401) history.push('/');
        else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalView>
        <Title>셋팅</Title>
        <ViewWrapper>
          <ComponentWrapper>
            <Text>셋팅 타이틀</Text>
            <TitleInput ref={TitleRef} placeholder='셋팅의 타이틀을 입력해주세요'/>
          </ComponentWrapper>
          <ComponentWrapper>
            <Text>시작 카운트다운</Text>
            <StartCountInput ref={StartRef}/>
          </ComponentWrapper>
          <ComponentWrapper>
            <CountSetting ref={SettingRef}/>
          </ComponentWrapper>
        </ViewWrapper>
        <ButtonWrapper>
          <ExitButton onClick={exitClick}>취소</ExitButton>
          <SaveButton onClick={saveClick}>저장하기</SaveButton>
        </ButtonWrapper>
        </TotalView>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default StopWatchAddModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 336px;
  // height: 613px;
  height: 666px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const TotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 24px;
  margin-top: 24px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
  // margin-top: 24px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 16px;

  margin: 36px 0 0 104px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;
