import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../Model/Share/Network/FetchModule';

function PaymentSubscribeCompleteToss({match}) {

  const history = useHistory();

  useEffect(() => {
    var status = match.params.status;

    if (status === 'success') {
      var customerKey = new URLSearchParams(window.location.search).get("customerKey");
      var authKey = new URLSearchParams(window.location.search).get("authKey");
      console.log("customerKey : " + customerKey);
      console.log("authKey : " + authKey);

      var data = { 
        customerKey: customerKey,
        authKey: authKey,
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/subscribe/tosspayments/membership', 'POST', data);
  
          console.log(responseData);
          if (responseData.status === 200) {
            var registData = JSON.parse(window.sessionStorage.getItem("RegistData"));

            console.log(registData);

            const responseData = await fetchModule.postData('member/registration/all', 'POST', registData);

            if (responseData.status === 200) {
              const responseStoreData = await fetchModule.getData('store/', 'GET');

              if (responseStoreData.status === 200) {
                var newData = {
                  storeId: window.sessionStorage.getItem('StoreId'),
                  storeName: responseStoreData.data[0].storeName,
                  userId: registData.linkCoachId,
                  merchantUid: registData.merchantUid,
                  itemName: registData.memberShipName,
                  approval: 1
                }

                const responseRegistData = await fetchModule.postDataOfCoach('registration/create', 'POST', newData);
              }
              alert("등록이 완료되었습니다.");
              history.push('/main');
            }
            else if (responseData.status === 201) {
              alert("이미 존재하는 회원입니다.");
            }
            else if (responseData.status === 401) {
              alert("로그인 후 사용해주세요.");
              history.push('/');
            }
            else alert("회원등록에 실패하였습니다. 잠시후 다시시도해 주세요.");
          }
        }
      )();
    }
    else if (status === 'fail') {
      alert("결제에 실패하였습니다.");
    }

  }, [])

  return <div/>
}

export default PaymentSubscribeCompleteToss;

