import { React, useRef, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import URLInput from '../../../Component/NoteComponent/URLInput';

import DeleteButtonImg from '../../../image/DeleteButton.png';

const VideoEditModal = (props) => {
  const { data, saveClickFn, exitClickFn } = props;

  const [useData, setUseData] = useState([]);

  const InputRef = useRef();

  useEffect(() => {
    var newCopyData = data.slice();
    setUseData(newCopyData);
  }, [data])

  // useEffect(() => {

  //   console.log(useData);
  // }, [useData])

  function addClick() {
    var inputData = InputRef.current.getValue();
    // console.log(inputData);
    if (inputData === undefined) {
      alert('주소를 입력해주세요!');
      return;
    }

    var newData = useData.slice();
    var splitData = inputData.split('/');
    newData.push(splitData[splitData.length - 1]);
    setUseData(newData);
  }

  function deleteClick(idx) {
    var newData = useData.slice();
    // console.log('-----');
    // console.log(newData);
    // console.log(idx);
    newData.splice(idx, 1);
    // console.log(newData);
    // console.log(newData.splice(idx, 1));
    setUseData(newData);
  }

  function exitClick() {
    exitClickFn();
  }

  function saveClick() {
    saveClickFn(useData);
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalView>
        <Title>영상 추가</Title>
        <ViewWrapper>
          <InputWrapper>
            <URLInput ref={InputRef} placeholder='영상 링크를 여기에 붙여넣어주세요'/>
            <AddButton onClick={addClick}>추가</AddButton>
          </InputWrapper>
          <URLInfoBox>
              {
                useData.map((data, idx) => (
                  data !== '' &&
                  <URLBox key={idx}>
                    <URL>https://youtu.be/{data}</URL>
                    <DeleteButton src={DeleteButtonImg} onClick={() => deleteClick(idx)}/>
                  </URLBox>
                ))
              }
          </URLInfoBox>
        </ViewWrapper>
        <ButtonWrapper>
          <ExitButton onClick={exitClick}>취소</ExitButton>
          <SaveButton onClick={saveClick}>저장하기</SaveButton>
        </ButtonWrapper>
        </TotalView>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default VideoEditModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 332px;
  height: 366px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const TotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 27px 0;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 16px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const AddButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const URLInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 267px;
  height: 136px;

  gap: 4px;

  overflow-x: none;
  overflow-y: scroll;
  
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const URLBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 224px;
  height: 24px;

  padding: 0 4px 0 8px;
  border-radius: 5px;
  background-color: #FF8B48;

  overflow: hidden;
`;

const URL = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const DeleteButton = styled.img`
  width: 16px;
  height: 16px;

  :hover {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 16px;

  margin: 36px 0 0 104px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;
