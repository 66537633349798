import { React, useEffect, useState, forwardRef } from 'react';
import styled from 'styled-components';

import SearchInput from '../../../Component/MemberComponent/SearchInput.js';
import InfoPagination from '../../../Component/MemberComponent/InfoPagination.js';

import FirstExpiredColumnView from './FirstExpiredColumnView.js';
import FirstExpiredMemberInfo from './FirstExpiredMemberInfo.js';
import SecondExpriedColumnView from './SecondExpiredColumnView.js';
import SecondExpiredMemberInfo from './SecondExpiredMemberInfo.js';

import SortingModule from '../../Share/Normal/SortingModule.js';

const ExpiredMemberView = forwardRef((props, ref) => {
  const { data, isOver } = props;

  const [totalData, setTotalData] = useState(data);
  const [sortingData, setSortingData] = useState([]);
  const [dividedData, setDividedData] = useState([]);
  const [nowPage, setNowPage] = useState(0);
  const [value, setValue] = useState('');
  const [first, setFirst] = useState(true);

  const [startSort, setStartSort] = useState('normal');
  const [endSort, setEndSort] = useState('desc');
  const [classSort, setClassSort] = useState('normal');

  useEffect(() => {
    setTotalData(data);
  }, [data])

  useEffect(() => {
    var newData = totalData.slice();

    if (startSort === 'asc') newData = SortingModule.dataSort(newData, 'startDate', 'asc', 'startDate');
    else if (startSort === 'desc') newData = SortingModule.dataSort(newData, 'startDate', 'desc', 'startDate');
    else if (endSort === 'asc') newData = SortingModule.dataSort(newData, 'endDate', 'asc', 'endDate');
    else if (endSort === 'desc') newData = SortingModule.dataSort(newData, 'endDate', 'desc', 'endDate');
    else if (classSort === 'asc') newData = SortingModule.dataSort(newData, 'memberShipName', 'asc', 'memberShipName');
    else if (classSort === 'desc') newData = SortingModule.dataSort(newData, 'memberShipName', 'desc', 'memberShipName');
    
    setSortingData(newData);
  }, [totalData, startSort, endSort, classSort])

  // 검색 기능 포함
  useEffect(() => {
    var nameList = [];
    var phoneList = [];

    for (var i = 0; i < sortingData.length; i++) {
      if (sortingData[i].name.toLowerCase().includes(value.toLowerCase())) nameList.push(sortingData[i]);
      else if (sortingData[i].phone.includes(value)) phoneList.push(sortingData[i]);
    }

    // console.log(totalData.findIndex(i => i.name.includes(value)))
    var totalList = nameList.concat(phoneList);
    
    // 페이지 분리 //
    if (totalList.length !== 0)  var newDataList = totalList.slice((nowPage * 20), (nowPage * 20) + 19);
    else var newDataList = sortingData.slice((nowPage * 20), (nowPage * 20) + 19);

    setDividedData(newDataList);
  }, [nowPage, sortingData, value])


  function sortButtonFn(division, sort) {
    if (division === 'start') {
      setStartSort(sort);
      setEndSort('normal');
      setClassSort('normal');
    }
    else if (division === 'end') {
      setStartSort('normal');
      setEndSort(sort);
      setClassSort('normal');
    }
    else if (division === 'class') {
      setStartSort('normal');
      setEndSort('normal');
      setClassSort(sort);
    }
  }

  function onClickNextPage() {
    if (nowPage + 1 < Math.ceil(totalData.length/20)) setNowPage(nowPage + 1);
  }

  function onClickPrevPage() {
    if ((nowPage - 1) >= 0) setNowPage(nowPage - 1);  
  }

  return (
    <TopLevelWrapper>
       <TopBarWrapper isOver={isOver}>
         <TextWrapper isOver={isOver}>
          <TitleWrapper>
            <Title isOver={isOver}>만료 회원</Title>
            <CountText isOver={isOver}>{'(총 ' + data.length + '명)'}</CountText>
          </TitleWrapper>
           <Explanation isOver={isOver}>이용권 사용기간이 만료된 회원의 정보를 확인할 수 있어요</Explanation>
         </TextWrapper>
         <SearchInput isOver={isOver} value={value} setValue={setValue} placeholder='회원의 이름이나 전화번호를 검색해주세요'/>
      </TopBarWrapper>
      {
        isOver ?
          <TotalWrapper>
            {
              first ?
                <FirstExpiredColumnView isOver={isOver} nextFn={() => setFirst(false)} />
                :
                <SecondExpriedColumnView 
                  prevFn={() => setFirst(true)} 
                  startSort={startSort}
                  setStartSort={setStartSort}
                  endSort={endSort}
                  setEndSort={setEndSort}
                  classSort={classSort}
                  setClassSort={setClassSort} />
            }
            {
              first ?
                <InfoBox isOver={isOver}>
                  {
                    dividedData.map((data, index) => (
                      <FirstExpiredMemberInfo key={index} data={data} isOver={isOver} nowPage={nowPage}/>
                    ))
                  }
                </InfoBox>
                :
                <InfoBox isOver={isOver}>
                  {
                    dividedData.map((data, index) => (
                      <SecondExpiredMemberInfo key={index} data={data} isOver={isOver} nowPage={nowPage}/>
                    ))
                  }
                </InfoBox>
            }
          </TotalWrapper>
          :
          <TotalWrapper>
            <FirstExpiredColumnView nextFn={() => setFirst(false)} />
            <InfoBox isOver={isOver}>
                  {
                    dividedData.map((data, index) => (
                      <FirstExpiredMemberInfo key={index} data={data} isOver={isOver} nowPage={nowPage}/>
                    ))
                  }
                </InfoBox>
          </TotalWrapper>
      }
      <PaginationWrapper>
        <InfoPagination 
          isOver={isOver}
          nowPage={nowPage + 1}
          totalPage={Math.ceil(totalData.length / 20)}
          nextClickFn={onClickNextPage}
          prevClickFn={onClickPrevPage} />
      </PaginationWrapper>
    </TopLevelWrapper>
  )
})

export default ExpiredMemberView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: ${props => props.isOver ? `space-between` : `center`};
  align-items: ${props => props.isOver ? `center` : `flex-start`};

  gap: ${props => props.isOver ? `0px` : `6px`};
  margin-left: ${props => props.isOver ? `max(28px, 3vw)` : `0px`};

  width: ${props => props.isOver ? `max(664px, 85.6vw)` : `max(268px, 83.75vw)`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: ${props => props.isOver ? `0px` : `max(8px, 2.5vw)`};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 4px;
`;

const CountText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #4F4F4F;

  margin: 0 0 0 0;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: ${props => props.isOver ? `20px` : `10px`};

  min-height: calc(100vh - 671px);
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 44px;
  margin-bottom: 96px;
`;