import { React, useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import MorePath from '../../image/More.png';
import HoverMorePath from '../../image/HoverMore.png';
import ExpireMorePath from '../../image/ExpireMore.png';
import CalcDate from '../../Model/Share/Normal/CalcDate';
import moment from 'moment';

const LockerInfo = forwardRef((props, ref) => {
  const { 
    index, 
    isOver, 
    data, 
    clickFunctionFn, 
    clickUsageHistoryFn, 
    clickChangeStateFn, 
    clickDeleteLockerFn, 
    clickChangeNameFn,
    clickSendMessageFn,
    clickExtendFn
  } = props;

  // const [data, setData] = useState(data);
  const [clickedMore, setClickedMore] = useState(false);
  const [individualData, setIndividualData] = useState();

  const MoreButtonRef = useRef();
  const MoreBoxRef = useRef();
  const HistoryRef = useRef();
  const TextRef = useRef();
  const LockRef = useRef();
  const DeleteRef = useRef();
  const ChangeRef = useRef();
  const ExtendRef = useRef();

  const history = useHistory();

  useImperativeHandle(ref, () => ({
		setClickedMore: (val) => {
      setClickedMore(val);
    },
    getMoreButtonRef: () => {
      return MoreButtonRef.current;
    },
    getMoreBoxRef: () => {
      return MoreBoxRef.current;
    }
	}));

  useEffect(() => {

    if (data.state === 'Empty') {
      data.isAvailable = true;
      data.isUsed = false;
      data.state = '비어있음';
      data.days = '';
      data.btnText = '사용 시작';
      data.border = '1px solid #777777';
      // data = Object.assign(data, {days: dDay, btnText: '사용 시작'});
    }
    else if (data.state === 'Using') {
      data.isAvailable = true;
      data.isUsed = true;
      data.state = '사용중'
      data.days = CalcDate.getDday(data.endDate);
      data.btnText = '사용 종료';
      data.border = (moment(data.endDate).isAfter(moment()) || moment(data.endDate).format('YYYY.MM.DD') === moment().format('YYYY.MM.DD')) ? '1px solid #FF8B48' : `2px solid #FF3131`;
      
    }
    else if (data.state === 'Invalid') {
      data.isAvailable = false;
      data.isUsed = false;
      data.state = '사용불가'
      data.days = '';
      data.btnText = '잠금 해제';
      data.border = '1px solid #DEDEDE';
      // data = Object.assign(data, {days: '', btnText: '잠금 해제'});
    }

    setIndividualData(data);
  }, [data])

  useEffect(() => {
    if (clickedMore) {
      MoreBoxRef.current.style.border = `1px solid #FF8B48`;
      if (data.state === '비어있음') MoreBoxRef.current.style.height = isOver ? `180px` : `108px`;
      else if (data.state === '사용중') {
        // MoreBoxRef.current.style.height = isOver ? `144px` : `104px`;
        MoreBoxRef.current.style.height = isOver ? `180px` : `144px`;
      }
      else MoreBoxRef.current.style.height = isOver ? `108px` : `78px`;
      // if (isOver) {
      //   if (data.state === '비어있음') MoreBoxRef.current.style.height = `180px`;
      //   else MoreBoxRef.current.style.height = `108px`;
      //   // MoreBoxRef.current.style.height = `180px`;
      // }
      // else {
      //   if (data.state === '')
      //   MoreBoxRef.current.style.height = `130px`;
      // }
    }
    else {
      MoreBoxRef.current.style.border = `0px solid #FF8B48`;
      MoreBoxRef.current.style.height = `0px`;
    }
  }, [clickedMore, isOver, data])

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  function onClickOutSide(e) {
    if (MoreBoxRef.current !== e.target 
      && MoreButtonRef.current !== e.target
      && HistoryRef.current !== e.target
      && TextRef.current !== e.target
      && LockRef.current !== e.target
      && DeleteRef.current !== e.target
      && ChangeRef.current !== e.target
      && ExtendRef.current !== e.target) {
      setClickedMore(false);
    }
  }

  function MoreButtonClick() {
    if (clickedMore) setClickedMore(false);
    else setClickedMore(true);
  }

  // 사용자 이름 및 D-day클릭시
  function onClickMidLineWrapper() {
    if (data.memberId === 0) return;

    history.push('/member/valid/detail/' + data.memberId + '/locker');
  }

  return (
    <TopLevelWrapper>
      <InfoBox isOver={isOver} border={data.border}>
        <TopLineWrapper>
          <Number isOver={isOver}>{data.lockerName}</Number>
          <More ref={MoreButtonRef} isOver={isOver} isAvailable={data.isAvailable} onClick={MoreButtonClick}/>
        </TopLineWrapper>
        <MidLineWrapper onClick={onClickMidLineWrapper}>
          <Name isOver={isOver}>{data.userName}</Name>
          <Dday isOver={isOver}>{data.days === '' ? '' : moment(data.endDate).isAfter(moment()) ? 'D-' + data.days : moment(data.endDate).format('YYYY.MM.DD') === moment().format('YYYY.MM.DD') ? 'D-Day' : 'D+' + data.days}</Dday>
        </MidLineWrapper>
        <BottomLineWrapper days={data.days} isOver={isOver}>
          <State isOver={isOver} isAvailable={data.isAvailable} isUsed={data.isUsed}>{data.state}</State>
        </BottomLineWrapper>
      </InfoBox>
      <MoreBox ref={MoreBoxRef} isOver={isOver}>
        <MoreButton ref={HistoryRef} isOver={isOver} onClick={() => clickUsageHistoryFn(index)}>이용 내역</MoreButton>
        <MoreButton ref={TextRef} isOver={isOver} onClick={() => clickFunctionFn(index)}>{data.btnText}</MoreButton>
        { data.state === '비어있음' && <MoreButton ref={LockRef} isOver={isOver} onClick={() => clickChangeStateFn(index)}>락커 잠금</MoreButton> }
        { data.state === '비어있음' && <MoreButton ref={DeleteRef} isOver={isOver} onClick={() => clickDeleteLockerFn(index)}>락커 삭제</MoreButton> }
        <MoreButton ref={ChangeRef} isOver={isOver} onClick={() => clickChangeNameFn(index)}>이름 변경</MoreButton>
        { data.state === '사용중' && <MoreButton ref={DeleteRef} isOver={isOver} onClick={() => clickSendMessageFn(index)}>문자 발송</MoreButton> }
        { data.state === '사용중' && <MoreButton onClick={() => clickExtendFn(index)}>사용 연장</MoreButton> }
      </MoreBox>
    </TopLevelWrapper>
  )
});

export default LockerInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: ${props => props.isOver ? `120px` : `85px`};
  // height: ${props => props.isOver ? `120px` : `85px`};

  width: ${props => props.isOver ? `118px` : `83px`};
  height: ${props => props.isOver ? `118px` : `83px`};

  // border: ${props => props.isAvailable ? props.isUsed ? `1px solid #FF8B48` : `1px solid #777777` : `1px solid #DEDEDE`};
  border: ${props => props.border};
  border-radius: 12px;
  
  transition: all 0.3s ease-in-out;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;
  
  margin-left: 8px;

  width: 90%;
  height: 25px;

  overflow: hidden;
`;

const Number = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const More = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `24px` : `17px`};
  height: ${props => props.isOver ? `24px` : `17px`};

  border-radius: 32px;

  background: ${props => props.isAvailable ? `url(${MorePath}) no-repeat center center` : `url(${ExpireMorePath}) no-repeat center center`};
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: url(${HoverMorePath}) no-repeat center center;
  }
`;

const MidLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
  
  width: 100%;

  margin-top: 4px;
  margin-left: 9px;

  :hover {
    cursor: pointer;
  }
`;

const Name = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Dday = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #A6A6A6;

  margin: 0 0 0 0;
`;

const BottomLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;

  // margin-top: ${props => props.days === '' ? '48px' : '18px'};
  margin-top: ${props => props.isOver ? props.days === '' ? '67px' : '24px' : props.days === '' ? `48px` : `18px`};
  margin-right: 10px;
`;


const State = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: ${props => props.isAvailable ? props.isUsed ? `#FF8B48` : `#A6A6A6` : `#FF3131`};
  
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const MoreBox = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `78px` : `50px`};
  height: 0px;

  margin-top: ${props => props.isOver ? `35px` : `20px`};
  margin-left: ${props => props.isOver ? `60px` : `50px`};

  border-radius: 8px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const MoreButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `78px` : `50px`};
  height: ${props => props.isOver ? `36px` : `26px`};

  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
    color: #FFFFFF;
  }
`;