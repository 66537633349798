import { React } from 'react';
import styled from 'styled-components';

import ServiceModel from '../../Model/Individual/RulesModel/ServiceModel';

function ServicePage() {
  
  return (
    <Container>
      <ServiceModel/>
    </Container>
  )
}

export default ServicePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  width: 100vw;
`;