import { React, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

const TableTimeDivisionLine = forwardRef((props, ref) => {
  const { scheduleData } = props;

  const [data, setData] = useState(scheduleData);

  useImperativeHandle(ref, () => ({
    setData: (val) => {
      setData(val);
    }
  }));

  // useEffect(() => {
  //   console.log(data);
  // }, [data])

  return (
    <TopLevelWrapper>
      {
        data.map((innerData, index) => (
          innerData.map((individualData, idx) => (
            <TextWrapper key={idx}>
              <TimeText>{individualData.classTime.split('-')[0]}</TimeText>
              {/* <TextTimeLine/> */}
            </TextWrapper>
          ))
        ))
      }
    </TopLevelWrapper>
  )
});

export default TableTimeDivisionLine;
  
const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  // justify-content: center;
  align-items: center;

  // width: 35px;
  // background-color: red;

  // margin-right: 14px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  
  // width: 33px;
`;

const TimeText = styled.p`
  font-family: Poppins-Regular;
  font-size: 12px;

  color: #777777;
  margin: 0 0 0 0;
`;

const TextTimeLine = styled.div`
  width: 2px;
  // height: ${props => props.index === 1 ? `50px` : `62px`};
  height: 22px;

  border-right: 2px solid #ECECEC;
`;


const TimeLine = styled.div`
  width: 2px;
  height: ${props => props.isNeedNewDivide ? `50px` : `80px`};

  border-right: 2px solid #ECECEC;
`;
