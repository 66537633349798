import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import CalenderModal from '../NoteModel/CalenderModal';
import ClassAddModal from './ClassManageModal/ClassAddModal';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import CalenderBasic from '../../../image/calendarB.png'//캘린더 기본
import CalenderHover from '../../../image/calendarH.png'
import ContractionButtonImg from '../../../image/ContractionButton.png';
import TodayButton from '../../../image/TodayButton.png'

import DailyClass from './DailyClass';
import useWindowSize from '../../Share/Normal/useWindowSize';

import FetchModule from '../../Share/Network/FetchModule';
import WeeklyClass from './WeeklyClass';
import MonthlyClass from './MonthlyClass';
import BreakTimeSettingModal from './ClassManageModal/BreakTimeSettingModal';
import ReservationLockModal from './ClassManageModal/ReservationLockModal';
import LessonAllLockModal from './LessonAllLockModal';

function ClassManageView() {
	const history = useHistory();
  const size = useWindowSize();

  const [date, setDate] = useState(moment());
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [rendering, setRendering] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [calenderClick, setCalenderClick] = useState(false);
  const [selectFormat, setSelectFormat] = useState('day');
  const [reserveClick, setReserveClick] = useState(false);
  const [useScheduleIndex, setUseScheduleIndex] = useState('-1');
  const [breakClick, setBreakClick] = useState(false);
  const [addClick, setAddClick] = useState(false);
  const [isContractionClick, setIsContractionClick] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [allReserveLockClick, setAllReserveLockClick] = useState(false);

  const [allReserveLock, setAllReserveLock] = useState(false);
  const [allReserveLockText, setAllReserveLockText] = useState('예약 전체 잠금');
	const [scheduleData, setScheduleData] = useState([]);
	const [useScheduleData, setUseScheduleData] = useState([]);
  const [monthlyScheduleData, setMonthlyScheduleData] = useState([]);
  const [breakTimeData, setBreakTimeData] = useState([]);
  const [reserveData, setReserveData] = useState([]);

  const [reserveDataReLoad, setReserveDataReLoad] = useState(false);

  // 스케줄 수정 및 삭제를 위한 변수
  const [scheduleId, setScheduleId] = useState(-1);

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  const CalenderButtonRef = useRef();
  const CalenderRef = useRef();
  const ContractionRef = useRef();
  const DailyRef = useRef();
  const WeeklyRef = useRef();
  const MonthlyRef = useRef();

  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      } 
    }
    // setClickDate(newDateList[0].format('YYYY.MM.DD'));
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek]) 

  
	// Schedule Data API호출
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('schedule/', 'GET');
        
        if (responseData.status === 200) setScheduleData(responseData.data);
        else if (responseData.status === 401) history.push('/');
        // else if (responseData.status !== 204) alert("회원권 정보를 가져오는데 실패하였습니다.\n잠시후 다시시도 해주세요");
      }
    )();
  }, [history, rendering])

  // BreakTime Data API호출
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('breaktime/', 'GET');
        
        if (responseData.status === 200) setBreakTimeData(responseData.data);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, rendering])

 // Schedule Data를 사용 가능한 Data로 가공
 useEffect(() => {
  var tmp = scheduleData.slice();
  var newScheduleData = [];

  for (var i = 0; i < tmp.length; i++) {
      var endPoint = moment(tmp[i].classDate.split('T')[0]);

      while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {
        // console.log("endPoint : " + endPoint.format('YYYY-MM-DD'));
        // console.log("today : " + date.format("YYYY-MM-DD"));
        // console.log(endPoint.diff(date, 'days') >= 30);

        if (endPoint.diff(date, 'days') >= 30) break;

        if (tmp[i].applyWeek.includes(endPoint.format('ddd')) || tmp[i].applyWeek === 'All') {
          var colSpan = 1;
          var rowSpan = 1;

          // 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
          colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);

          var newData = {
            classId: tmp[i].classId,
            classDate: endPoint.format('YYYY.MM.DD'),
            className: tmp[i].className,
            classTime: tmp[i].classTime,
            color: tmp[i].color,
            endDate: tmp[i].endDate,
            lessonId: tmp[i].lessonId,
            scheduleId: tmp[i].scheduleId,
            teacherName: tmp[i].teacherName,
            applyWeek: tmp[i].applyWeek,
            rowSpan: rowSpan,
            colSpan: colSpan,
            maxBookedPeople: tmp[i].maxBookedPeople,
            deduction: tmp[i].deduction
          }
          newScheduleData.push(newData);
        }
        // endPoint = endPoint.clone().add(7 * tmp[i].term, 'day');
        endPoint = endPoint.clone().add(1, 'day');
      }
  }

  newScheduleData.sort(function (a, b) {
    return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
  })

  setMonthlyScheduleData(newScheduleData);

  var newData = [];

  for (i = 0; i < newScheduleData.length; i++) {

    if (newScheduleData[i].classDate === date.clone().format('YYYY.MM.DD')) {
      newData.push(newScheduleData[i]);
    }
  }

  // breaktime 데이터 가공
  for (var z = 0; z < breakTimeData.length; z++) {
    if (breakTimeData[z].applyWeek.includes(date.clone().format('ddd')) || breakTimeData[z].applyWeek === 'All') {

      var breakData = {
        classId: -1,
        lessonId: -1,
        classDate: date.clone().format('YYYY.MM.DD'),
        color: '#ECECEC',
        classTime: breakTimeData[z].startTime + '-' + breakTimeData[z].endTime,
        // className: '브레이크 타임',
        className: 'Open Gym',
        applyWeek: breakTimeData[z].applyWeek
      }

      newData.push(breakData);
    }
  }
  
  newData.sort(function (a, b) {
    return a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0;
  })

  // TimeDivisionLine에서 사용할 데이터 가공
  for (i = 0; i < newData.length; i++) {
    tmp = newData[i].classTime.split('-');
    var splitTmp = tmp[0].split(':');
    var Time = '';

    if (parseInt(splitTmp[0]) > 12) {
      newData[i].startTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
      Time = (parseInt(splitTmp[0]) - 12) + ' PM';
    }
    else {
      newData[i].startTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
      Time = parseInt(splitTmp[0]) + ' AM';
    }

    tmp = newData[i].classTime.split('-');
    splitTmp = tmp[1].split(':');

    if (parseInt(splitTmp[0]) > 12) {
      newData[i].endTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
    }

    else {
      newData[i].endTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
    }

    if (i !== 0 && newData[i - 1].startTime.split(':')[0] === newData[i].startTime.split(':')[0]) {
      newData[i] = Object.assign(newData[i], { isNeedNewDivide: false })
      newData[i] = Object.assign(newData[i], { viewTime: Time })
    }
    else {
      newData[i] = Object.assign(newData[i], { isNeedNewDivide: true })
      newData[i] = Object.assign(newData[i], { viewTime: Time })
    }
  }
  
  var newReserve = [];

  for (var h = 0; h < newData.length; h++) {
    newReserve.push(new Array());
  }
  
  setReserveData(newReserve);
  setUseScheduleData(newData);
}, [scheduleData, breakTimeData, date])


  // ReservationData
  useEffect(() => {
    // if (useScheduleData.length === 0) return;
    var dataList = [];

    for (var i = 0; i < useScheduleData.length; i++) {
      var data = {
        lessonId: useScheduleData[i].lessonId,
        classDate: useScheduleData[i].classDate.replaceAll('.', '-') + 'T00:00:00',
        classTime: useScheduleData[i].classTime
      }
      dataList.push(data);
    }
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/list', 'POST', dataList);
        
        if (responseData.status === 200) setReserveData(responseData.data);
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [useScheduleData, reserveDataReLoad])
  
	// Schedule 예약이 전체 잠금 상태인지 확인
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('reserve_lock/lock_all/' + moment().format('YYYY-MM-DDT') + '00:00:00/' + moment().format('YYYY-MM-DDT') + '23:59:59/', 'GET');
        // console.log(responseData.status);
        if (responseData.status === 200) setAllReserveLock(true);
        else if (responseData.status === 201) setAllReserveLock(false);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, rendering])

  // Schedule 예약 전체 잠금상태에 따라 버튼 텍스트 변경
  useEffect(() => {
    if (allReserveLock) setAllReserveLockText('예약 전체 잠금');
    else setAllReserveLockText('전체 잠금 해제')
  }, [allReserveLock])

  useEffect(() => {
    if(isContractionClick) {
      ContractionRef.current.style.height = `105px`;
      ContractionRef.current.style.border = `1px solid #FF8B48`;
    }
    else {
      ContractionRef.current.style.height = `0px`;
      ContractionRef.current.style.border = `0px solid #FF8B48`;
    }
  }, [isContractionClick])

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  function onMouseOver() {
    CalenderButtonRef.current.style.border = `1px solid #777777`;
    CalenderButtonRef.current.style.background = `url(${CalenderHover}) no-repeat center center`;
  }

  function onMouseLeave() {
    CalenderButtonRef.current.style.border = `1px solid #DFDFDF`;
    CalenderButtonRef.current.style.background = `url(${CalenderBasic}) no-repeat center center`;
  }

  function dateClick(value) {
    // setClickDate(value.format('YYYY.MM.DD'));
    setClickDate(value);
    setDate(moment(value));

    // ScheduleRef.current.setValue(value);
    if (calenderClick) CalenderRef.current.setValue(value);
  }

  function openReserveLockModal(index) {
    setUseScheduleIndex(index);
    setReserveClick(true);
  }

  function addButtonClick() {
    setAddClick(false);
    setRendering(!rendering);
    setScheduleId(-1);
  }

  function breakButtonClick() {
    setBreakClick(false);
    setRendering(!rendering);
  }

  function reservationButtonClick() {
    setReserveClick(false);
    setRendering(!rendering);
  }

  // 수업 상세정보 또는 주간 스케줄표 클릭시
  function onClickScheduleDetail(scheduleId) {
    setScheduleId(scheduleId);
    setAddClick(true);
  }

  return (
    <TopLevelWrapper>
      { calenderClick && <CalenderModal ref={CalenderRef} isOver={isOver} clickDate={clickDate} date={date} dateClickFn={dateClick} exitFn={setCalenderClick}/> }
      {/* { reserveClick && <ReserveLockModal cancel={() => reservationButtonClick()}/> } */}
      { reserveClick && <ReservationLockModal isOver={isOver} data={useScheduleData[useScheduleIndex]} cancel={() => reservationButtonClick()}/> }
      { breakClick && <BreakTimeSettingModal isOver={isOver} date={date} cancel={() => breakButtonClick()}/> }
      { addClick && <ClassAddModal isOver={isOver} scheduleData={scheduleData} scheduleId={scheduleId} date={date} cancel={() => addButtonClick()}/> }
      { allReserveLockClick && <LessonAllLockModal date={date} allReserveLock={allReserveLock} setAllReserveLock={setAllReserveLock} cancel={() => setAllReserveLockClick(false)}/> }
      <TopBarWrapper isOver={isOver}>
        <TextWrapper>
          <Title isOver={isOver}>수업 관리</Title>
          <Explanation isOver={isOver}>수업 일정을 확인하고 추가할 수 있어요</Explanation>
        </TextWrapper>
      </TopBarWrapper>
      <TopButtonWrapper isOver={isOver}>
        <LeftWrapper>
          <DateButtonWrapper isOver={isOver}>
            <ArrowWrapper isOver={isOver} onClick={() => setDate(date.clone().subtract(1, selectFormat))} >
              <ArrowImage src={LeftArrow}/>
            </ArrowWrapper>
            {
              isOver ?
              <DateText isOver={isOver}>{selectFormat === 'day' ? date.clone().format('YYYY년 MM월 DD일 (dd)') : date.clone().format('YYYY년 MM월') }</DateText>
              :
              <DateText isOver={isOver}>{selectFormat === 'day' ? date.clone().format('MM월 DD일 (dd)') : date.clone().format('YYYY년 MM월') }</DateText>
            }
            <ArrowWrapper isOver={isOver} onClick={() => setDate(date.clone().add(1, selectFormat))} >
              <ArrowImage src={RightArrow}/>
            </ArrowWrapper>
          </DateButtonWrapper>
          <CalenderButton ref={CalenderButtonRef} img={CalenderBasic} isOver={isOver} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={() => setCalenderClick(!calenderClick)}/>
          {
            isOver &&
            <Button isOver={isOver} onClick={() => setDate(moment())}>
              <TodayImage src={TodayButton} />
            </Button>
          }
          <Button isOver={isOver} isClicked={selectFormat === 'day'} onClick={() => setSelectFormat('day')}>일</Button>
          <Button isOver={isOver} isClicked={selectFormat === 'week'} onClick={() => setSelectFormat('week')}>주</Button>
          <Button isOver={isOver} isClicked={selectFormat === 'month'} onClick={() => setSelectFormat('month')}>월</Button>
        </LeftWrapper>
        <ContractionButtonWrapper>
          <ContractionButton isOver={isOver} img={ContractionButtonImg} onClick={() => setIsContractionClick(!isContractionClick)}/>
          <ContractionList ref={ContractionRef} isOver={isOver}>
            <Contraction isOver={isOver} onClick={() => setAddClick(true)}>수업 추가</Contraction>
            <Contraction isOver={isOver} onClick={() => setAllReserveLockClick(true)}>{allReserveLockText}</Contraction>
            <Contraction isOver={isOver} onClick={() => setBreakClick(true)}>브레이크타임 추가</Contraction>
          </ContractionList>
        </ContractionButtonWrapper>
      </TopButtonWrapper>
        { selectFormat === 'day' && 
          <DailyClass 
            ref={DailyRef} 
            isOver={isOver} 
            useScheduleData={useScheduleData} 
            breakTimeData={breakTimeData} 
            reserveData={reserveData} 
            reserveDataReLoad={reserveDataReLoad} 
            onClickScheduleDetail={onClickScheduleDetail}
            setReserveDataReLoad={setReserveDataReLoad}
            openReserveLockModal={openReserveLockModal} 
            today={date}/> }
        { selectFormat === 'week' && 
          <WeeklyClass 
            ref={WeeklyRef} 
            isOver={isOver} 
            scheduleData={scheduleData} 
            breakTimeData={breakTimeData} 
            today={date}
            onClickScheduleDetail={onClickScheduleDetail}/> }
        { selectFormat === 'month' && <MonthlyClass ref={MonthlyRef} isOver={isOver} useScheduleData={monthlyScheduleData} breakTimeData={breakTimeData} date={date}/> }
    </TopLevelWrapper>
  )
}

export default ClassManageView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: ${props => props.isOver ? `28px` : `14px`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: ${props => props.isOver ? `space-between` : `center`};
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(298px, 93.125vw)`};

  gap: ${props => props.isOver ? `0px` : `8px`};
  margin-top: ${props => props.isOver ? `32px` : `11px`};
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const ContractionButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: ${props => props.isOver ? `189px` : `101px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  padding: ${props => props.isOver ? `0 8px 0 8px` : `0 0 0 0`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `8px`};
  background-color: #FFFFFF;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `22px` : `19px`};
  height: ${props => props.isOver ? `22px` : `19px`};

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;
`;

const CalenderButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `38px` : `29px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `8px`};
  background-color: #FFFFFF;
  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ContractionButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `38px` : `29px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: 1px solid #FF8B48;
  border-radius: ${props => props.isOver ? `15px` : `8px`};
  background: url(${props => props.img}) no-repeat center center;
  background-color: #FF8B48;

  transition: all 0.3s ease-in-out;

  :hover {
    border: 1px solid #FF8B48;
    background-color: #FF8B48;
    cursor: pointer;
  }
`;

const ContractionList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `125px` : `100px`};
  height: 0px;

  margin-top: 44px;

  border: px solid #FF8B48;
  border-radius: 8px;
  
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const Contraction = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `125px` : `100px`};
  height: 36px;

  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #FF8B48;

  :hover { 
    background-color: #FF8B48;
    color: #FFFFFF;
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `38px` : `29px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: ${props => props.isOver ? `15px` : `8px`};
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: ${props => props.isClicked ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #777777`};
    color: ${props => props.isClicked ? `#FFFFFF` : `#777777`};
  }
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const TodayImage = styled.img`
  width: 20px;
  height: 20px;
`;
