import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import PrevButtonImage from '../../../image/NormalPrevInfoButton.png';
import ColorNextButtonImage from '../../../image/ColorNextInfoButton.png';

import AscSortButtonPath from '../../../image/AscSortButton.png';
import DescSortButtonPath from '../../../image/DescSortButton.png';

function MyMemberPaymentColumnFirst(props) {
  const { nextFn, approvalDateButtonImg, onClickApprovalDateSort } = props;

  return (
    <TopLevelWrapper>
      <SortingWrapper>
        <Column style={{ marginRight: '4px', marginLeft: '28px' }}>승인 날짜</Column>
        <SortingButton img={approvalDateButtonImg} onClick={() => onClickApprovalDateSort()} style={{marginRight: '49px'}}/>
      </SortingWrapper>
      <Column style={{marginRight: '43px'}}>결제자 성함</Column>
      <Column style={{marginRight: '36px'}}>결제 구분</Column>
      <Column style={{marginRight: '160px'}}>결제 방법</Column>
      {/* <Column style={{marginRight: '263px'}}>결제 내역</Column>
      <Column style={{marginRight: '64px'}}>최종 결제 금액</Column>
      <SortingWrapper>
        <Column style={{ marginRight: '4px' }}>승인 여부</Column>
        <SortingButton img={startButtonImg} onClick={() => setStartDateSort(!startDateSort)}/>
      </SortingWrapper> */}

      
      <ButtonWrapper>
        <Button src={PrevButtonImage} />
        <Column>다음 정보</Column>
        <Button src={ColorNextButtonImage} onClick={nextFn}/>
      </ButtonWrapper>
    </TopLevelWrapper>
  )
}

export default  MyMemberPaymentColumnFirst;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  
  // justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 38px;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SortingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const SortingButton = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `4px` : `6px`};
`;

const Button = styled.img`
  width: 24px;
  height: 24px;

  border-radius: 24px;

  :hover {
    cursor: pointer;
  }
`;