import { React, useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';
import MovementSearchInput from '../../../Component/NoteComponent/MovementSearchInput';

import ExitButtonImage from '../../../image/ExitButton.png';
import CheckImage from '../../../image/CheckImage.png';
import WhiteCheck from '../../../image/WhiteCheck.png';
import TextInput from '../../../Component/NoteComponent/TextInput';
import MovementDetailCommentInput from '../../../Component/NoteComponent/MovementDetailCommentInput';

import ProgramVideoIcon from '../../../image/ProgramVideoIcon.png';

function AddMovementModal(props) {
  const { successAddMovement, close } = props;

  const [movementList, setMovementList] = useState([]);
  const [divideData, setDivideData] = useState([]);
  const [value, setValue] = useState({ movementName: '' });
  const [myMovementName, setMyMovementName] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [movementDetailComment, setMovementDetailComment] = useState('');
  const [recentlySearchHistory, setRecentlySearchHistory] = useState([]);
  const [isClickedPrevVideo, setIsClickedPrevVideo] = useState(false);
  const [prevVideoLinkId, setPrevVideoLinkId] = useState('');
  const [isClickedMyMovement, setIsClickedMyMovement] = useState(false);

  // MyMovement Option //
  const [isUseWeight, setIsUseWeight] = useState(false);
  const [isUseCount, setIsUseCount] = useState(false);
  const [isUseTime, setIsUseTime] = useState(false);
  const [isUseTempo, setIsUseTempo] = useState(false);
  const [isUseDistance, setIsUseDistance] = useState(false);
  const [isUseHeight, setIsUseHeight] = useState(false);
  const [isUseCalories, setIsUseCalories] = useState(false);
  // MyMovement Option //

  const RecentlySearchRef = useRef();
  const ViewBoxRef = useRef();
  const VideoPrevBoxRef = useRef();
  const MyMovementRef = useRef();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  // Movement Data Setting //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfExercise('movement/list', 'GET');
        // console.log(responseData.data)
        if (responseData.status === 200) setMovementList(responseData.data);
      }
    )();
  }, [])

  // 저장된 최근 검색어 불러오기 //
  useEffect(() => {
    var resentlyText = window.localStorage.getItem('RecentlySearchText');
    var searchData = [];

    if (resentlyText === null || resentlyText === undefined || resentlyText === '') searchData = [];
    else searchData = resentlyText.split('!@!');

    searchData = searchData.reverse();

    setRecentlySearchHistory(searchData);
  }, [])

  // 동작 검색 //
  useEffect(() => {
    var newDivideData = [];
    var newMovementList = movementList.slice();
    
    for (var i = 0; i < movementList.length; i++) {
      if (newMovementList[i].movementName.toLowerCase().replaceAll(' ', '') === value.movementName.toLocaleLowerCase().replaceAll(' ', '')) {
        newDivideData.unshift(newMovementList[i]);
      }
      else if (newMovementList[i].movementName.toLowerCase().replaceAll(' ', '').includes(value.movementName.toLowerCase().replaceAll(' ', ''))) {
        newDivideData.push(newMovementList[i]);
      }
      else if (newMovementList[i].keyword.includes(value.movementName)) {
        newDivideData.push(newMovementList[i]);
      }
    }
    
    setMyMovementName(value.movementName);
    setDivideData(newDivideData);
  }, [value, movementList])

  // 나만의 동작 영상 링크 작성시 //
  useEffect(() => {
    var newVideoLinkId = '';

    if (videoLink.includes('https://youtu.be/')) {
      newVideoLinkId = videoLink.replace('https://youtu.be/', '');
    }
    else if (videoLink.includes('https://www.youtube.com/watch?v=')) {
      newVideoLinkId = videoLink.replace('https://www.youtube.com/watch?v=', '');
    }
    // console.log(videoLink);
    // console.log(newVideoLinkId);
    setPrevVideoLinkId(newVideoLinkId);
  }, [videoLink])

  /// 동작 움직임 에니메이션 //
  useEffect(() => {
    if (isClickedMyMovement) {
      RecentlySearchRef.current.style.height = `0px`;

      VideoPrevBoxRef.current.style.marginTop = `0px`;
      VideoPrevBoxRef.current.style.height = `0px`;

      MyMovementRef.current.style.height = `632px`;
    }
    else {
      if (!isClickedPrevVideo) {
        RecentlySearchRef.current.style.height = `618px`;

        VideoPrevBoxRef.current.style.marginTop = `0px`;
        VideoPrevBoxRef.current.style.height = `0px`;
      }
      else {
        RecentlySearchRef.current.style.height = `334px`;

        VideoPrevBoxRef.current.style.marginTop = `16px`;
        VideoPrevBoxRef.current.style.height = `268px`;
      }

      MyMovementRef.current.style.height = `0px`;
    }
  }, [isClickedMyMovement, value, isClickedPrevVideo, prevVideoLinkId, RecentlySearchRef, ViewBoxRef])

  // 최근 검색어 클릭시 //
  function onClickRecentlyData(data) {
    if (isClickedMyMovement) return;

    var newMovementList = movementList.slice();

    for (var i = 0; i < newMovementList.length; i++) {
      if (newMovementList[i].movementName === data) {
        setValue(newMovementList[i]);

        // 검색 내역 세팅
        var settingData = {
          // Movement Data Setting //
          movementId: newMovementList[i].movementId,
          detailComment: newMovementList[i].detailComment,
          movementOptions: newMovementList[i].movementOptions,
          isUseAvailable: newMovementList[i].isUseAvailable,
          linkCoachId: newMovementList[i].linkCoachId,
          movementName: newMovementList[i].movementName,
          keyword: newMovementList[i].keyword,
          storeId: newMovementList[i].storeId,
          videoLinkId: newMovementList[i].videoLinkId,
          // Workout Exercise Setting //
          isUseWeight: newMovementList[i].movementOptions.includes('WEIGHT') ? true : false,
          isUseCount: newMovementList[i].movementOptions.includes('REPS') ? true : false,
          isUseTime: newMovementList[i].movementOptions.includes('TIME') ? true : false,
          isUseDistance: newMovementList[i].movementOptions.includes('DISTANCE') ? true : false,
          isUseTempo: false,
          isUseHeight: newMovementList[i].movementOptions.includes('HEIGHT') ? true : false,
          isUseCalories: newMovementList[i].movementOptions.includes('CALORIES') ? true : false,
          isUseRest: false,
          weight: 0,
          count: 0,
          time: 0,
          tempo: 0,
          distance: 0,
          height: 0,
          calories: 0,
          rest: 0,
          sets: 0,
          weightUnit: 'Pounds',
          heightUnit: 'm',
          caloriesUnit: 'Cal',
          distanceUnit: 'm'
        }

        setValue(settingData);
        break;
      }
    }
  }

  // 최근 검색어 삭제 버튼 클릭시 //
  function onClickRecentlyDataDelete(index) {
    var newRecentlyData = recentlySearchHistory.slice();

    newRecentlyData.splice(index, 1);
    setRecentlySearchHistory(newRecentlyData);

    var newString = '';

    for (var i = newRecentlyData.length - 1; i >= 0; i--) {
      if (i === newRecentlyData.length - 1) newString = newRecentlyData[i];
      else newString += '!@!' + newRecentlyData[i];
    }

    window.localStorage.setItem('RecentlySearchText', newString);
  }

  // 검색된 내역 클릭시 //
  function onClickDivideData(data) {
    if (isClickedMyMovement) return;
    // console.log(data);
    // console.log(value);
    if (value.movementName === data.movementName && value.movementId !== undefined) setValue({ movementName: '' });
    else {
      // 검색 내역 세팅
      var settingData = {
        // Movement Data Setting //
        id: data.id,
        detailComment: data.detailComment,
        movementId: data.movementId,
        movementOptions: data.movementOptions,
        isUseAvailable: data.isUseAvailable,
        linkCoachId: data.linkCoachId,
        movementName: data.movementName,
        keyword: data.keyword,
        storeId: data.storeId,
        videoLinkId: data.videoLinkId,
        // Workout Exercise Setting //
        isUseWeight: data.movementOptions.includes('WEIGHT') ? true : false,
        isUseCount: data.movementOptions.includes('REPS') ? true : false,
        isUseTime: data.movementOptions.includes('TIME') ? true : false,
        isUseDistance: data.movementOptions.includes('DISTANCE') ? true : false,
        isUseTempo: data.movementOptions.includes('TEMPO') ? true : false,
        isUseHeight: data.movementOptions.includes('HEIGHT') ? true : false,
        isUseCalories: data.movementOptions.includes('CALORIES') ? true : false,
        isUseRest: false,
        weight: 0,
        count: 0,
        time: 0,
        tempo: 0,
        distance: 0,
        height: 0,
        calories: 0,
        rest: 0,
        sets: 0,
        weightUnit: 'Pounds',
        heightUnit: 'm',
        caloriesUnit: 'Cal',
        distanceUnit: 'm'
      }

      setValue(settingData);
      // 최근 검색 내역 최신화
      var newRecentlyData = recentlySearchHistory.slice();

      var isDuplicate = false;

      for (var i = 0; i < newRecentlyData.length; i++) {
        if (newRecentlyData[i] === data.movementName) {
          isDuplicate = true;
          break;
        }
      }

      if (!isDuplicate) {
        var newString = '';

        newRecentlyData = newRecentlyData.reverse();
        newRecentlyData.push(data.movementName);

        for (i = 0; i < newRecentlyData.length; i++) {
          if (i === 0) newString = newRecentlyData[i];
          else newString += '!@!' + newRecentlyData[i];
        }

        window.localStorage.setItem('RecentlySearchText', newString);

        newRecentlyData = newRecentlyData.reverse();
        setRecentlySearchHistory(newRecentlyData);
      }
    }
  }

  // 최근 검색내역에서 영상 미리보기 버튼 클릭시 //
  function onClickMovementVideoViewOfRecentlyList(data) {
    var newMovementList = movementList.slice();

    for (var i = 0; i < newMovementList.length; i++) {
      if (newMovementList[i].movementName === data) {
        setPrevVideoLinkId(newMovementList[i].videoLinkId);
        break;
      }
    }

    setIsClickedPrevVideo(true);
  }

  // 전체 리스트에서 영상 미리보기 버튼 클릭시 //
  function onClickMovementVideoViewOfAllList(data) {
    setPrevVideoLinkId(data.videoLinkId);
    setIsClickedPrevVideo(true);
  }

  // 영상 미리보기 닫기 버튼 클릭시 //
  function onClickMovementVideoViewExit() {
    // console.log(isClickedPrevVideo);
    // console.log(prevVideoLinkId);
    setIsClickedPrevVideo(false);
    setPrevVideoLinkId('');
  }

  // 나만의 동작 추가하기 체크박스 클릭시 //
  function onClickAdditionMyMovement() {
    setPrevVideoLinkId('');
    setIsClickedMyMovement(!isClickedMyMovement);
  }

  // 데이터 추가 완료 클릭시 //
  function onClickSuccess() {

    if (!isClickedMyMovement) {
      if (value.movementId === undefined) alert('추가할 동작을 선택해주세요!');
      else successAddMovement(value);
    }
    else {
      var movementName = value.movementName;
      var videoLinkId = '';
      var movementOptions = '';

      if (movementName === '') {
        alert('동작 이름을 설정해주세요');
        return;
      }

      if (videoLink.includes('https://www.youtube.com/watch?v=')) videoLinkId = videoLink.replace('https://www.youtube.com/watch?v=', '');
      else if (videoLink.includes('https://youtu.be/')) videoLinkId = videoLink.replace('https://youtu.be/', '');
      
      if (isUseWeight) movementOptions += ',WEIGHT';
      if (isUseCount) movementOptions += ',REPS';
      if (isUseTime) movementOptions += ',TIME';
      if (isUseTempo) movementOptions += ',TEMPO';
      if (isUseDistance) movementOptions += ',DISTANCE';
      if (isUseHeight) movementOptions += ',HEIGHT';
      if (isUseCalories) movementOptions += ',CALORIES';

      if (movementOptions.length !== 0) movementOptions = movementOptions.slice(1);

      var data = {
        detailComment: movementDetailComment,
        isUseAvailable: false,
        userId: 'None',
        movementName: myMovementName,
        movementOptions: movementOptions,
        keyword: '',
        storeId: 0,
        videoLinkId: videoLinkId,
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          var responseData = await fetchModule.postDataOfExercise('movement/save', 'POST', data);

          if (responseData.status === 200) {
            var settingData = {
              // Movement Data Setting //
              // id: responseData.data[0].id,
              id: 0,
              detailComment: responseData.data[0].detailComment,
              movementOptions: responseData.data[0].movementOptions,
              isUseAvailable: responseData.data[0].isUseAvailable,
              linkCoachId: responseData.data[0].linkCoachId,
              movementId: responseData.data[0].movementId,
              movementName: responseData.data[0].movementName,
              keyword: responseData.data[0].keyword,
              storeId: responseData.data[0].storeId,
              videoLinkId: responseData.data[0].videoLinkId,
              // Workout Exercise Setting //
              isUseWeight: isUseWeight,
              isUseCount: isUseCount,
              isUseTime: isUseTime,
              isUseDistance: isUseDistance,
              isUseTempo: isUseTempo,
              isUseHeight: isUseHeight,
              isUseCalories: isUseCalories,
              isUseRest: false,
              weight: 0,
              count: 0,
              time: 0,
              tempo: 0,
              distance: 0,
              height: 0,
              calories: 0,
              rest: 0,
              sets: 0,
              weightUnit: 'Pounds',
              heightUnit: 'm',
              caloriesUnit: 'Cal',
              distanceUnit: 'm'
            }

            successAddMovement(settingData);
            // 최근 검색 내역 최신화
            var newRecentlyData = recentlySearchHistory.slice();

            var isDuplicate = false;

            for (var i = 0; i < newRecentlyData.length; i++) {
              if (newRecentlyData[i] === data.movementName) {
                isDuplicate = true;
                break;
              }
            }

            if (!isDuplicate) {
              var newString = '';

              newRecentlyData = newRecentlyData.reverse();
              newRecentlyData.push(data.movementName);

              for (i = 0; i < newRecentlyData.length; i++) {
                if (i === 0) newString = newRecentlyData[i];
                else newString += '!@!' + newRecentlyData[i];
              }

              window.localStorage.setItem('RecentlySearchText', newString);

              newRecentlyData = newRecentlyData.reverse();
              setRecentlySearchHistory(newRecentlyData);
            }
          }
          else if (responseData.status === 401) {
            alert("잘못된 권한 접근입니다.");
          }
        }
      )();
    }
  }

  return (
    <TopLevelWrapper>
      <Modal isClickedMyMovement={isClickedMyMovement}>
        <TotalWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>동작 추가하기</Text>
          <MovementSearchInput value={value} setValue={setValue} disabled={isClickedMyMovement} />
          {
            <RecentlySearchWrapper ref={RecentlySearchRef} isClickedMyMovement={isClickedMyMovement}>
              {
                value.movementName === '' ?
                  <DataWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E'>최근 검색어({recentlySearchHistory.length})</Text>
                    {
                      recentlySearchHistory.map((data, index) => (
                        <RecentlyBox key={index}>
                          <RecentlyTextWrapper onClick={() => onClickRecentlyData(data)}>
                            <RecentlyText>{data}</RecentlyText>
                          </RecentlyTextWrapper>
                          <RecentlySearchTextButtonWrapper>
                            { data.videoLinkId !== '' && <Icon src={ProgramVideoIcon} onClick={() => onClickMovementVideoViewOfRecentlyList(data)} /> }
                            <DeleteButton src={ExitButtonImage} onClick={() => onClickRecentlyDataDelete(index)} />
                          </RecentlySearchTextButtonWrapper>
                        </RecentlyBox>
                      ))
                    }
                  </DataWrapper>
                  :
                  <DataWrapper>
                    {
                      divideData.length === 0 ?
                        <EmptyDataWrapper>
                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#D0D0D0'>찾으시는 동작의 정보가 없습니다</Text>
                        </EmptyDataWrapper>
                        :
                        divideData.map((data, index) => (
                          <RecentlyBox key={index}>
                            <RecentlyTextWrapper onClick={() => onClickDivideData(data)}>
                              <RecentlyText isClicked={(value.movementName === data.movementName && value.movementId !== undefined)}>{data.movementName}</RecentlyText>
                            </RecentlyTextWrapper>
                            <DataSearchButtonWrapper>
                              { data.videoLinkId !== '' && <Icon src={ProgramVideoIcon} onClick={() => onClickMovementVideoViewOfAllList(data)} /> }
                              {(value.movementName === data.movementName && value.movementId !== undefined) && <Check src={CheckImage} />}
                            </DataSearchButtonWrapper>
                          </RecentlyBox>
                        ))
                    }
                  </DataWrapper>
              }
            </RecentlySearchWrapper>
          }
          <VideoPrevBoxWrapper ref={VideoPrevBoxRef}>
            <PrevVideoExitButton onClick={onClickMovementVideoViewExit}>닫기</PrevVideoExitButton>
            {
              prevVideoLinkId === '' ?
                <VideoPrevBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#232323'>동영상이 존재하지 않습니다.</Text>
                </VideoPrevBox>
                :
                <VideoPrevBox>
                  <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/` + prevVideoLinkId}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube" />
                </VideoPrevBox>
            }
          </VideoPrevBoxWrapper>
          <MyMovementTextWrapper isClicked={isClickedMyMovement}>
            <CheckBox isClicked={isClickedMyMovement} onClick={onClickAdditionMyMovement}>
              <Check src={WhiteCheck} />
            </CheckBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>나만의 동작 추가하기</Text>
          </MyMovementTextWrapper>
          <MovementWrapper ref={MyMovementRef}>
            <DivisionLine />
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 옵션</Text>
              <MovementLayerWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>무게</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseWeight} onClick={() => setIsUseWeight(!isUseWeight)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>횟수</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseCount} onClick={() => setIsUseCount(!isUseCount)} >
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>시간</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseTime} onClick={() => setIsUseTime(!isUseTime)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>템포</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseTempo} onClick={() => setIsUseTempo(!isUseTempo)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>거리</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseDistance} onClick={() => setIsUseDistance(!isUseDistance)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>높이</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseHeight} onClick={() => setIsUseHeight(!isUseHeight)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>열량</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseCalories} onClick={() => setIsUseCalories(!isUseCalories)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
              </MovementLayerWrapper>
            </ComponentWrapper>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 이름</Text>
              <TextInput value={myMovementName} setValue={setMyMovementName} disabled={!isClickedMyMovement} placeholder={value.movementName} />
            </ComponentWrapper>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 영상</Text>
              <TextInput value={videoLink} setValue={setVideoLink} disabled={!isClickedMyMovement} placeholder='유튜브 영상 링크를 붙여넣어 주세요' />
            </ComponentWrapper>
            <VideoComponentWrapper>
              {
                prevVideoLinkId === '' ?
                  <VideoPrevBox>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#232323'>동영상이 존재하지 않습니다.</Text>
                  </VideoPrevBox>
                  :
                  <VideoPrevBox>
                    <iframe
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/` + prevVideoLinkId}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube" />
                  </VideoPrevBox>
              }
            </VideoComponentWrapper>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 상세 코멘트</Text>
              <MovementDetailCommentInput value={movementDetailComment} setValue={setMovementDetailComment} disabled={!isClickedMyMovement} />
            </ComponentWrapper>
          </MovementWrapper>
          <ButtonWrapper isClickedMyMovement={isClickedMyMovement}>
            <ExitButton onClick={() => close()}>취소</ExitButton>
            <SaveButton onClick={onClickSuccess}>완료</SaveButton>
          </ButtonWrapper>
        </TotalWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

export default AddMovementModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  // overflow-y: scroll;
  overflow-y: hidden;

  background-color: rgba(0, 0, 0, 0.2);

	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 480px;
  height: 869px;
  // height: ${props => props.isClickedMyMovement ? `769px` : `869px`};

  border-radius: 30px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin-top: 28px;

  width: 88.33%;

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RecentlySearchWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  // height: ${props => props.isClickedMyMovement ? `0px` : `572px`};
  // height: 572px;

  overflow-x: hidden;
  overflow-y: scroll;

  margin-top: 16px;

  transition: all 0.3s ease-in-out;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

const EmptyDataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const RecentlyBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 98%;
  min-height: 33px;

  border-bottom: 1px solid #F5F5F5;
`;

const RecentlyTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }
`;

const RecentlyText = styled.p`
  font-family: Poppins-Regular;
  font-size: 12px;

  color: ${props => props.isClicked ? `#FF8B48` : `#4F4F4F`};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }
`;

const RecentlySearchTextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const DataSearchButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;


const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const DeleteButton = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 10px;
  height: 9px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const MyMovementTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 6px;
  margin-top: ${props => props.isClicked ? `0px` : `33px`};

  width: 100%;
`;

const VideoPrevBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  width: 414px;

  margin-top: 16px;

  overflow: hidden;
  
  transition: all 0.3s ease-in-out;
`;

const PrevVideoExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 44px;
  height: 36px;

  margin: 0 0 0 0;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  border-radius: 15px 15px 0px 0px;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;

const VideoPrevBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 414px;
  height: 232px;

  background-color: #E0E0E0;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 11px;

  border: 1px solid #ECECEC;
`;

const MovementLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 26px;

  width: 100%;
`;

const MovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 8px;
  margin-top: 16px;
`;

const VideoComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 8px;
  margin-top: 16px;
`;

const MovementOptionCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 7px;
`;

const MovementOptionCheckText = styled.div`
  font-family: Poppins-Medium;
  font-size: 14px;

  color: #4F4F4F;

  margin: 0 0 0 0;
`;

const MovementOptionCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const MovementOptionCheck = styled.img`
  width: 15.75px;
  height: 13.5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  width: 100%;

  gap: 16px;

  margin-top: ${props => props.isClickedMyMovement ? `33px` : `12px`};
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    color: #777777;
    border: 1px solid #777777;
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 74px;
  height: 40px;

  border: 0px;
  border-radius: 15px;
  background-color: #6DD49E;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    background-color: #2BCB67;
    cursor: pointer;
  }
`;