import { React } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const NoticeInfo = (props) => {
  const { data, index, clickFn, isOver } = props;

  return (
    <TopLevelWrapper isOver={isOver} onClick={() => clickFn(index)}>
      <TextWrapper>
        <Title isOver={isOver}>{data.title}</Title>
      </TextWrapper>
      <TextWrapper>
        <CreatedDate isOver={isOver}>{moment(data.createdDate).format('YYYY.MM.DD')}</CreatedDate>
      </TextWrapper>
    </TopLevelWrapper>
  )
}

export default NoticeInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-itmes: center;

  // width: 636px;
  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(255px, 79.6875vw)`};
  height: ${props => props.isOver ? `28px` : `19px`};

  margin: 0 0 0 0;
  // padding: 0 8px 0 8px;
  // padding: 0 8px 0 8px;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #F4F2F0;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  margin: 0 0 0 0;

  color: #595959;
`;

const CreatedDate = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  margin: 0 0 0 0;

  color: #777777;
`;