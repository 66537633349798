import { React, forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

function ScoreDetailSelectBox(props) {
	const { value, setValue, disabled } = props;

	const [isClicked, setIsClicked] = useState(false);
  const [options, setOptions] = useState(['높을 수록', '낮을 수록']);

  const TopLevelRef = useRef();
	const SelectRef = useRef();

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
      SelectRef.current.style.border = '1px solid #E0E0E0';
			SelectRef.current.style.height = `36px`;
			SelectRef.current.style.zIndex = `0`;
      SelectRef.current.style.overflow = 'hidden';
      setIsClicked(false);
    }
  }

	function onClick() {
    if (disabled) return;
		
		if (isClicked) {
			SelectRef.current.style.border = '1px solid #E0E0E0';
			SelectRef.current.style.height = `36px`;
			SelectRef.current.style.zIndex = `0`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = `${36 * (options.length)}px`;
			SelectRef.current.style.maxHeight = '130px';
			SelectRef.current.style.zIndex = `5`;
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
		setValue(options[index]);
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<SelectBox
				ref={SelectRef} 
				isClicked={isClicked}
        disabled={disabled}
				buttonPath={isClicked ? SelectOnPath : SelectOffPath}
				onClick={onClick}>
				{
					isClicked ?
						options.map((data, index) =>
							<OptionWrapper key={index} onClick={() => optionalClick(index)}>
								<Option>{data}</Option>
							</OptionWrapper>
						)
						:
						<OptionWrapper>
							<Option>
								{
									disabled ? `-` : value
								}
							</Option>
						</OptionWrapper>
				}
			</SelectBox>
		</TopLevelWrapper>
	)
}

export default ScoreDetailSelectBox;

const TopLevelWrapper = styled.div`
  position: relative;
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
	width: 245px;
	height: 36px;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

	width: 243px;
	height: 34px;

  z-index: 0;

	border: 1px solid #E0E0E0;
	border-radius: 15px;
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `3` : `55`}%;
	background-color: ${props => props.disabled ? `#ECECEC` : `rgba(255, 255, 255, 1)`};
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: Poppins-Medium;
	font-size: 14px;

  color: #4F4F4F;

	margin: 8px 0 6px 16px;
`;