import { React, useRef, forwardRef } from 'react';
import styled from 'styled-components';

import BackgroundSetting from '../../../Component/ScheduleComponent/BackgroundSetting';
import ExcludedClassSelect from '../../../Component/ScheduleComponent/ExcludedClassSelect';
import LogoSettingInput from '../../../Component/ScheduleComponent/LogoSettingInput';
import ReflectionWeek from '../../../Component/ScheduleComponent/ReflectionWeek';
import TableRatioSettingSelect from '../../../Component/ScheduleComponent/TableRatioSettingSelect';
import TitleSettingInput from '../../../Component/ScheduleComponent/TitleSettingInput';


import SaveImg from '../../../image/saveImage.png';

const TableSetting = (props) => {
  const { classData, titleChangeFn, logoChangeFn, weekChangeFn, backgroundChangeFn, saveClickFn, printClickFn, ratioSelectClickFn, excludedClassChangeFn } = props;

  const TitleRef = useRef();
  const LogoRef = useRef();
  const WeekRef = useRef();
  const BackgroundRef = useRef();
  const SelectRef = useRef();

  return (
    <TopLevelWrapper>
      <ComponentWrapper>
        <Title>타이틀 설정</Title>
        <TitleSettingInput ref={TitleRef} className='Title' placeholder='스케줄표 타이틀을 입력해주세요' type='text' changeFn={titleChangeFn}/>
      </ComponentWrapper>
      <ComponentWrapper style={{marginTop: `24px`}}>
        <Title>로고 업로드</Title>
        <LogoSettingInput ref={LogoRef} changeFn={logoChangeFn}/>
      </ComponentWrapper>
      <ComponentWrapper style={{marginTop: `24px`}}>
        <Title>반영 요일 선택</Title>
        <ReflectionWeek ref={WeekRef} changeFn={weekChangeFn}/>
      </ComponentWrapper>
      <ComponentWrapper style={{marginTop: `24px`}}>
        <Title>제외 수업 선택</Title>
        <ExcludedClassSelect ref={SelectRef} classData={classData} changeFn={excludedClassChangeFn}/>
      </ComponentWrapper>
      <ComponentWrapper style={{marginTop: `24px`}}>
        <Title>배경 색상 설정</Title>
        <BackgroundSetting ref={BackgroundRef} changeFn={backgroundChangeFn}/>
      </ComponentWrapper>
      <ComponentWrapper style={{marginTop: `24px`}}>
        <Title>시간표 비율 설정</Title>
        <TableRatioSettingSelect changeFn={ratioSelectClickFn}/>
      </ComponentWrapper>
      <ButtonWrapper>
        <SaveButton img={SaveImg} onClick={saveClickFn}/>
        <PrintButton onClick={printClickFn}>시간표 출력하기</PrintButton>
      </ButtonWrapper>
    </TopLevelWrapper>
  )
}

export default TableSetting;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 321px;
  height: 594px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 8px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 16px;
  margin-top: 36px;
  margin-left: 71px;
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const PrintButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 136px;
  height: 38px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;