import { React, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import SettingInput from './SettingInput';

const CountSetting = forwardRef((props, ref) => {
  const [nowClick, setNowClick] = useState('Up');

  const UpRoundRef = useRef();
  const UpMinRef = useRef();
  const UpSecRef = useRef();

  const DownMinRef = useRef();
  const DownSecRef = useRef();

  const IntervalRoundRef = useRef();
  const IntervalExerciseMinRef = useRef();
  const IntervalExerciseSecRef = useRef();
  const IntervalRestMinRef = useRef();
  const IntervalRestSecRef = useRef();

  useImperativeHandle(ref, () => ({
		getValue: () => {
      var round = 0;
      var exerciseTime = 0;
      var restTime = 0;
      var min = 0;
      var sec = 0;

      if (nowClick === 'Up') {
        min = UpMinRef.current.getValue() === undefined ? 0 : UpMinRef.current.getValue();
        sec = UpSecRef.current.getValue() === undefined ? 0 : UpSecRef.current.getValue();

        round = UpRoundRef.current.getValue() === undefined ? 0 : UpRoundRef.current.getValue();
        exerciseTime = parseInt(min * 60) + parseInt(sec);
        restTime = 0;
      }
      else if (nowClick === 'Down') {
        min = DownMinRef.current.getValue() === undefined ? 0 : DownMinRef.current.getValue();
        sec = DownSecRef.current.getValue() === undefined ? 0 : DownSecRef.current.getValue();

        round = 0;
        exerciseTime = parseInt(min * 60) + parseInt(sec);
        restTime = 0;
      }
      else if (nowClick === 'Interval') {
        min = IntervalExerciseMinRef.current.getValue() === undefined ? 0 : IntervalExerciseMinRef.current.getValue();
        sec = IntervalExerciseSecRef.current.getValue() === undefined ? 0 : IntervalExerciseSecRef.current.getValue();
        
        var restMin = IntervalRestMinRef.current.getValue() === undefined ? 0 : IntervalRestMinRef.current.getValue();
        var restSec = IntervalRestSecRef.current.getValue() === undefined ? 0 : IntervalRestSecRef.current.getValue();

        round = IntervalRoundRef.current.getValue() === undefined ? 0 : IntervalRoundRef.current.getValue();

        exerciseTime = parseInt(min * 60) + parseInt(sec);
        restTime = parseInt(restMin * 60) + parseInt(restSec);
      }

      var data = {
        title: '',
        // storeId: parseInt(window.sessionStorage.getItem('StoreId')),
        storeId: parseInt(window.localStorage.getItem('StoreId')),
        startCount: 0,
        type: nowClick,
        round: round,
        exerciseTime: exerciseTime,
        restTime: restTime
      }

      return data;
    }
	}));

  return (
    <TopLevelWrapper>
      <ComponentWrapper>
        <TitleWrapper>
          <RoundBox onClick={() => setNowClick('Up')}>
            <RoundColor isClicked={nowClick === 'Up'}/>
          </RoundBox>
          <Title>카운트 업</Title>
        </TitleWrapper>
        <InfoWrapper>
          <Info style={{marginRight: `16px`}}>
            <SettingInput ref={UpRoundRef} isClicked={nowClick !== 'Up'}/>
            <SubTitle isClicked={nowClick === 'Up'}>라운드</SubTitle>
          </Info>
          <Info style={{marginRight: `38px`}}>
            <SettingInput ref={UpMinRef} isClicked={nowClick !== 'Up'}/>
            <SubTitle isClicked={nowClick === 'Up'}>분</SubTitle>
          </Info>
          <Info>
            <SettingInput ref={UpSecRef} isClicked={nowClick !== 'Up'}/>
            <SubTitle isClicked={nowClick === 'Up'}>초</SubTitle>
          </Info>
        </InfoWrapper>
      </ComponentWrapper>
      <ComponentWrapper>
        <TitleWrapper>
          <RoundBox onClick={() => setNowClick('Down')}>
            <RoundColor isClicked={nowClick === 'Down'}/>
          </RoundBox>
          <Title>카운트 다운</Title>
        </TitleWrapper>
        <InfoWrapper>
          <Info style={{marginRight: `39px`}}>
            <SettingInput ref={DownMinRef} isClicked={nowClick !== 'Down'}/>
            <SubTitle isClicked={nowClick === 'Down'}>분</SubTitle>
          </Info>
          <Info>
            <SettingInput ref={DownSecRef} isClicked={nowClick !== 'Down'}/>
            <SubTitle isClicked={nowClick === 'Down'}>초</SubTitle>
          </Info>
        </InfoWrapper>
      </ComponentWrapper>
      <ComponentWrapper>
        <TitleWrapper>
          <RoundBox onClick={() => setNowClick('Interval')}>
            <RoundColor isClicked={nowClick === 'Interval'}/>
          </RoundBox>
          <Title>인터벌 트레이닝</Title>
        </TitleWrapper>
        <InfoWrapper>
          <Info style={{marginRight: `16px`}}>
            <SettingInput ref={IntervalRoundRef} isClicked={nowClick !== 'Interval'}/>
            <SubTitle isClicked={nowClick === 'Interval'}>라운드</SubTitle>
          </Info>
        </InfoWrapper>
        <InfoWrapper style={{marginTop: `9px`}}>
          <Info style={{ marginRight: `38px` }}>
            <SettingInput ref={IntervalExerciseMinRef} isClicked={nowClick !== 'Interval'} />
            <SubTitle isClicked={nowClick === 'Interval'}>분</SubTitle>
          </Info>
          <Info>
            <SettingInput ref={IntervalExerciseSecRef} isClicked={nowClick !== 'Interval'} />
            <SubTitle isClicked={nowClick === 'Interval'}>초 운동</SubTitle>
          </Info>
        </InfoWrapper>
        <InfoWrapper style={{marginTop: `9px`}}>
          <Info style={{marginRight: `38px`}}>
            <SettingInput ref={IntervalRestMinRef} isClicked={nowClick !== 'Interval'} />
            <SubTitle isClicked={nowClick === 'Interval'}>분</SubTitle>
          </Info>
          <Info>
            <SettingInput ref={IntervalRestSecRef} isClicked={nowClick !== 'Interval'} />
            <SubTitle isClicked={nowClick === 'Interval'}>초 휴식</SubTitle>
          </Info>
        </InfoWrapper>
      </ComponentWrapper>
    </TopLevelWrapper>
  )
})

export default CountSetting;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 16px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const RoundBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 14px;
  height: 14px;

  border: 1px solid #ECECEC;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const RoundColor = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 8px;
  height: 8px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};
  
  transition: all 0.3s ease-in-out;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #595959;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 16px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const SubTitle = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: ${props => props.isClicked ? `#777777` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;
`;