import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import moment from 'moment';

import MemberShipHistoryInfoButtonImagePath from '../../../../image/MemberShipHistoryInfoButton.png';

const ThirdMemberShipHistoryInfoView = forwardRef((props, ref) => {
  const { data, index, modifyClick, holdingClick, extensionClick, deleteClick } = props;
  const [isClicked, setIsClicked] = useState(false);

  const OptionBoxRef = useRef();
  const OptionButtonRef = useRef();
  const OptionEditRef = useRef();
  const OptionHoldRef = useRef();
  const OptionExtendRef = useRef();
  const OptionDeleteRef = useRef();

  useImperativeHandle(ref, () => ({
    setIsClicked: () => {
      setIsClicked(false);
    }
  }))

  useEffect(() => {
    if (isClicked) {
      OptionBoxRef.current.style.height = `60px`;
      OptionBoxRef.current.style.border = `1px solid #FF8B48`;
    }
    else {
      OptionBoxRef.current.style.height = `0px`;
      OptionBoxRef.current.style.border = `0px solid #FF8B48`;
    }
  }, [isClicked])
  
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  function onClickOutSide(e) {
    if (OptionBoxRef.current !== e.target 
      && OptionButtonRef.current !== e.target
      && OptionHoldRef.current !== e.target
      && OptionExtendRef.current !== e.target
      && OptionDeleteRef.current !== e.target) {
      setIsClicked(false);
    }
  }

  function mouseOver() {
    OptionButtonRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    OptionButtonRef.current.style.opacity = 0;
  }

  return (
    <TopLevelWrapper onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      <Table>
        <Body>
          <Tr>
            <Td style={{ width: 'max(60px, 16.8vw)' }}>
              <TextWrapper style={{ maxWidth: 'max(60px, 16.8vw)' }}>
                <Text>{data.notReceivedPrice}</Text>
              </TextWrapper>
            </Td>
            <Td style={{ width: 'max(54px, 15.6vw)' }}>
              <TextWrapper style={{ maxWidth: 'max(54px, 15.6vw)' }}>
                <Text>{data.approvalDate.slice(2, 10).replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
            <Td style={{ width: 'max(53px, 16.5625vw)' }}>
              <TextWrapper style={{ maxWidth: 'max(53px, 16.5625vw)' }}>
                <Text>{ moment(data.endDate).diff(moment(new Date()), 'days') > 0 ? moment(data.endDate).diff(moment(new Date()), 'days') + '일' : moment(data.endDate).diff(moment(new Date()), 'days') === 0 ? 'D-Day' : '만료' }</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <OptionButton ref={OptionButtonRef} src={MemberShipHistoryInfoButtonImagePath} onClick={() => setIsClicked(!isClicked)} />
              <OptionWrapper ref={OptionBoxRef}>
                <Option ref={OptionEditRef} onMouseDown={() => modifyClick(index)}>수정</Option>
                <Option ref={OptionHoldRef} onMouseDown={() => holdingClick(index)}>홀딩</Option>
                <Option ref={OptionExtendRef} onMouseDown={() => extensionClick(index)}>연장</Option>
                {/* <Option ref={OptionExtendRef} onMouseDown={() => refundClick(index)}>환불</Option> */}
                <Option ref={OptionDeleteRef} onMouseDown={() => deleteClick(index)}>삭제</Option>
              </OptionWrapper>
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
});

export default ThirdMemberShipHistoryInfoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: max(280px, 87.5vw);
  height: 17px;

  background: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
  `;

const Table = styled.table`
  display: flex;
  flex-direction: row;
  `;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
    
  margin-left: max(10px, 3.125vw);

  width: max(262px, 81.875vw);
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
    
  padding: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 15px;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 10px;

  color: #595959;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
    
  -webkit-transform: scale(0.9);
`;

const OptionButton = styled.img`
  width: 9px;
  height: 9px;

  opacity: 0;
    
  transition: all 0.3s ease-in-out;
`;

const OptionWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  overflow: hidden;

  height: 0px;
  
  z-index: 5;

  margin-top: 16px;
  margin-left: -22px;

  border: 0px solid #FF8B48;
  border-radius: 8px;

  transition: all 0.2s ease-in-out;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 20px;

  margin: 0 0 0 0;

  font-family: NotoSansKR-Regular;
  font-size: 10px;

  color: #FF8B48;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FFFFFF;
    background-color: #FF8B48;
  }
`;