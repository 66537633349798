import React, { useState } from 'react';
import styled from 'styled-components';

function UsePaymentSystemButton(props) {
  const { isUsePaymentSystem, setIsUsePaymentSystem, width } = props;

  return (
    <TopLevelWrapper>
      <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959'>결제 시스템 사용 여부</Text>
      <ButtonWrapper width={width}>
        <Button isClicked={isUsePaymentSystem === 'Yes'} onClick={() => setIsUsePaymentSystem('Yes')}>사용</Button>
        <Button isClicked={isUsePaymentSystem === 'No'} onClick={() => setIsUsePaymentSystem('No')}>미사용</Button>
      </ButtonWrapper>
    </TopLevelWrapper>
  )
}

export default UsePaymentSystemButton;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const Text = styled.p`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: ${props => props.width};

  margin-top: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.58%;
  height: 30px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 14px;
  color: ${props => props.isClicked ? `#FFFFFF` : `#CBCBCB`};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;