import { React, useRef, forwardRef, useState, useEffect, useImperativeHandle} from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CustomAlert from '../../Share/Normal/CustomAlert';

import SettingCountInput from '../../../Component/SettingComponent/SettingCountInput';

import FetchModule from '../../Share/Network/FetchModule';

const SystemSettingView = forwardRef((props, ref) => {
  const { isOver } = props;

  const history = useHistory();
  const [rendering, setRendering] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [data, setData] = useState([{id: -1,
                                      reservationOpenTime: 0,
                                      reservationAvailableTime: 0,
                                      reservationChangeTime: 0,
                                      reservationCancelTime: 0,
                                      attendanceDeadLine: 0}]);

  const TopLevelRef = useRef();
  const ReservationOpenTimeRef = useRef();
  const ReservationCancelTimeRef = useRef();
  const ReservationAvailableTimeRef = useRef();
  const AttendanceDeadLineRef = useRef();
  const ReservationChangeTimeRef = useRef();

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
    setZindex: (val) => {
      TopLevelRef.current.style.zIndex = val;
    },
    save: () => {
      save();
    }
	}))

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('system/', 'GET');

        if (responseData.status === 200) {
          // 아무런 데이터가 없을경우 //
          if (responseData.data[0] === null) {
            setData([{
              id: -1,
              reservationOpenTime: 0,
              reservationChangeTime: 0
            }])
          }
          // 데이터가 존재하는 경우
          else setData(responseData.data);
        }
        else if (responseData.status === 401) history.push('/');
       
        // if (responseData.status === 200) setData(responseData.data);
        // else if (responseData.status === 401) history.push('/');
        // else if (responseData.status !== 204) alert("시스템 정보를 불러오는데 실패하였습니다.\n잠시후 다시시도 해주세요");
      }
    )();
  }, [history, rendering])

  useEffect(() => {
    ReservationOpenTimeRef.current.setValue(data[0].reservationOpenTime);
    // ReservationCancelTimeRef.current.setValue(data[0].reservationCancelTime);
    // ReservationAvailableTimeRef.current.setValue(data[0].reservationAvailableTime);
    // AttendanceDeadLineRef.current.setValue(data[0].attendanceDeadLine);
    ReservationChangeTimeRef.current.setValue(data[0].reservationChangeTime);
  }, [data])

  function save() {
      
    var reservationOpenTime = ReservationOpenTimeRef.current.getValue();
    // var reservationCancelTime = ReservationCancelTimeRef.current.getValue();
    // var reservationAvailableTime = ReservationAvailableTimeRef.current.getValue();
    // var attendanceDeadLine = AttendanceDeadLineRef.current.getValue();
    var reservationChangeTime = ReservationChangeTimeRef.current.getValue();

    var newData = {
      id: data[0].id,
      // storeId: window.sessionStorage.getItem('StoreId'),
      // storeId: window.localStorage.getItem('StoreId'),
      reservationOpenTime: reservationOpenTime,
      // reservationCancelTime: reservationCancelTime,
      // reservationAvailableTime: reservationAvailableTime,
      // attendanceDeadLine: attendanceDeadLine,
      reservationChangeTime: reservationChangeTime
    } 

    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     var responseData = null

    //     if (data[0].id === -1) responseData = await fetchModule.postData('system/create', 'POST', newData);
    //     else {
    //       newData.id = data[0].id;
    //       responseData = await fetchModule.patchData('system/modify', 'PATCH', newData);
    //     }
        
    //     if (responseData.status === 200) {
    //       // alert("정보 저장을 완료하였습니다!");
    //       setIsSaved(true);
    //       setRendering(!rendering);
    //     }
    //     else if (responseData.status === 401) history.push('/');
    //     else if (responseData.status !== 204) alert("시스템 정보를 저장하는데 실패하였습니다.\n잠시후 다시시도 해주세요");
    //   }
    // )();
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.patchData('system/save', 'POST', newData);
        console.log(responseData);
        if (responseData.status === 200) {
          // alert("정보를 저장하였습니다.");
          setIsSaved(true);
          // setRendering(!rendering);
          setData(responseData.data);
        }
        else if (responseData.status === 401) history.push('/');
        else if (responseData.status !== 204) alert("시스템 정보를 저장하는데 실패하였습니다.\n잠시후 다시시도 해주세요");
      }
    )();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
      { 
        isSaved && <CustomAlert isOver={isOver} text='저장을 완료하였습니다' closeFn={() => setIsSaved(false)}/>
      }
      <ComponentColumnWrapper isOver={isOver}>
        <ComponentWrapper>
          <Title isOver={isOver}>수업 예약 오픈 시간</Title>
          <Explanation isOver={isOver}>수업일 기준 며칠 전부터 예약을 오픈할지 설정하세요</Explanation>
          <SettingCountInput ref={ReservationOpenTimeRef} isOver={isOver} unit='전부터'/>
        </ComponentWrapper>
        <ComponentWrapper>
          <Title isOver={isOver}>수업 예약 변경 가능 시간</Title>
          <Explanation isOver={isOver}>수업 시작 전 예약 변경 가능한 시간을 설정해주세요</Explanation>
          <SettingCountInput ref={ReservationChangeTimeRef} isOver={isOver} unit='전까지'/>
        </ComponentWrapper>
        {/* <ComponentWrapper>
          <Title isOver={isOver}>수업 예약 취소 가능 시간</Title>
          <Explanation isOver={isOver}>수업 시작 전 예약 취소 가능한 시간을 설정해주세요</Explanation>
          <SettingCountInput ref={ReservationCancelTimeRef} isOver={isOver} unit='전까지'/>
        </ComponentWrapper>
        <ComponentWrapper>
          <Title isOver={isOver}>수업 예약 허용 시간</Title>
          <Explanation isOver={isOver}>수업 시작 전 예약 가능한 시간을 설정해주세요</Explanation>
          <SettingCountInput ref={ReservationAvailableTimeRef} isOver={isOver} unit='전까지'/>
        </ComponentWrapper> */}
      </ComponentColumnWrapper>
      <ComponentColumnWrapper isOver={isOver} style={isOver ? {} : {marginTop: `12px`}}>
        {/* <ComponentWrapper>
          <Title isOver={isOver}>출석체크 마감 시간</Title>
          <Explanation isOver={isOver}>수업 시작 후 출석 체크 마감 시간을 설정해주세요</Explanation>
          <SettingCountInput ref={AttendanceDeadLineRef} isOver={isOver} unit='까지'/>
        </ComponentWrapper> */}
        {/* <ComponentWrapper>
          <Title isOver={isOver}>수업 예약 변경 가능 시간</Title>
          <Explanation isOver={isOver}>수업 시작 전 예약 변경 가능한 시간을 설정해주세요</Explanation>
          <SettingCountInput ref={ReservationChangeTimeRef} isOver={isOver} unit='전까지'/>
        </ComponentWrapper> */}
      </ComponentColumnWrapper>
    </TopLevelWrapper>
  )
})

export default SystemSettingView;

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: flex-start;
  align-items: ${props => props.isOver ? `flex-start` : `center`};

  // width: 1020px;
  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(296px, 92.5vw)`};
  // height: 258px;
  // height: ${props => props.isOver ? `375px` : `510px`};

  // opacity: 0;
  // z-index: -3000;

  // margin: ${props => props.isOver ? `-1300px 0 545px 0` : `-1300px 0 700px 0`};
  margin: 16px 0 96px 0;

  gap: ${props => props.isOver ? `max(80px, 10.41666666666667vw)` : `0px`};

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0);
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const ComponentColumnWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: flex-start;
  align-items: flex-start;

  gap: ${props => props.isOver ? `32px` : `12px`};

  // margin-top: ${props => props.isOver ? `28px` : `24px`};
  margin: 28px 28px 28px 28px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // gap: 8px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #CBCBCB;

  margin: 8px 0 0 0;
`;