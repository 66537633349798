import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import lodash from 'lodash';

// import TimeDivisionLine from './TimeDivisionLine';
import TimeDivisionLine from '../../../Component/MainComponent/TimeDivisionLine.js';
import WeeklyColumn from './WeeklyColumn';

const WeeklyClass = forwardRef((props, ref) => {
  const { today, isOver, scheduleData, breakTimeData, onClickScheduleDetail } = props;

  const [useScheduleData, setUseScheduleData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [startDay, setStartDay] = useState(moment());

  const TopLevelRef = useRef();

  useImperativeHandle(ref, () => ({
    visible: () => {
			TopLevelRef.current.style.opacity = 1;
			TopLevelRef.current.style.zIndex = 1;
		},
		unVisible: () => {
			TopLevelRef.current.style.opacity = 0;
			TopLevelRef.current.style.zIndex = -3;
		}
  }))
  
  
  useEffect(() => {
    for (var i = 0; i < 7; i++) {
      if(today.clone().subtract(i, 'day').format('dd') === 'Su') {
        setStartDay(today.clone().subtract(i, 'day'));
        break;
      }
    }
  }, [today])

  // Schedule Data를 사용 가능한 Data로 가공
  useEffect(() => {
    var tmp = scheduleData.slice();
    var newScheduleData = [];

    for (var i = 0; i < tmp.length; i++) {

        var endPoint = moment(tmp[i].classDate.split('T')[0]);

        while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {
          if (endPoint.diff(startDay, 'days') >= 6) break;

          if (tmp[i].applyWeek.includes(endPoint.format('ddd')) || tmp[i].applyWeek === 'All') {
            var colSpan = 1;
            var rowSpan = 1;

            // 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
            colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);

            var newData = {
              classId: tmp[i].classId,
              classDate: endPoint.format('YYYY.MM.DD'),
              className: tmp[i].className,
              classTime: tmp[i].classTime,
              color: tmp[i].color,
              endDate: tmp[i].endDate,
              lessonId: tmp[i].lessonId,
              scheduleId: tmp[i].scheduleId,
              teacherName: tmp[i].teacherName,
              applyWeek: tmp[i].applyWeek,
              rowSpan: rowSpan,
              colSpan: colSpan,
              maxBookedPeople: tmp[i].maxBookedPeople,
              deduction: tmp[i].deduction
            }
            newScheduleData.push(newData);
          }
          endPoint = endPoint.clone().add(1, 'day');
        }
    }

    newScheduleData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    var newData = [];

    for (i = 0; i < newScheduleData.length; i++) {
      for (var j = 0; j < 7; j++) {
        if (newScheduleData[i].classDate === startDay.clone().add(j, 'day').format('YYYY.MM.DD')) {
          newData.push(newScheduleData[i]);
        }
      }
    }

    // breaktime 데이터 가공
    for (var z = 0; z < breakTimeData.length; z++) {
      for (var y = 0; y < 7; y++) {
        if (breakTimeData[z].applyWeek.includes(startDay.clone().add(y, 'day').format('ddd')) || breakTimeData[z].applyWeek === 'All') {

          var breakData = {
            classId: -1,
            lessonId: -1,
            classDate: startDay.clone().add(y, 'day').format('YYYY.MM.DD'),
            color: '#ECECEC',
            classTime: breakTimeData[z].startTime + '-' + breakTimeData[z].endTime,
            // className: '브레이크 타임',
            className: 'Open Gym',
            applyWeek: breakTimeData[z].applyWeek
          }

          newData.push(breakData);
        }
      }
    }
    
    newData.sort(function (a, b) {
      return a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0;
    })

    // TimeDivisionLine에서 사용할 데이터 가공
    for (i = 0; i < newData.length; i++) {
      tmp = newData[i].classTime.split('-');
      var splitTmp = tmp[0].split(':');
      var Time = '';

      if (parseInt(splitTmp[0]) > 12) {
        newData[i].startTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
        Time = (parseInt(splitTmp[0]) - 12) + ' PM';
      }
      else if (parseInt(splitTmp[0]) === 12) {
        newData[i].startTime = (parseInt(splitTmp[0])) + ':' + splitTmp[1] + ' PM';
        Time = (parseInt(splitTmp[0])) + ' PM';
      }
      else {
        newData[i].startTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
        Time = parseInt(splitTmp[0]) + ' AM';
      }

      tmp = newData[i].classTime.split('-');
      splitTmp = tmp[1].split(':');

      if (parseInt(splitTmp[0]) > 12) {
        newData[i].endTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
      }
      else if (parseInt(splitTmp[0]) === 12) {
        newData[i].endTime = (parseInt(splitTmp[0])) + ':' + splitTmp[1] + ' PM';
      }
      else {
        newData[i].endTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
      }

      if (i !== 0 && newData[i - 1].startTime.split(':')[0] === newData[i].startTime.split(':')[0]) {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: true })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
      else {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: true })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
    }

    var newReserve = [];

    for (var h = 0; h < newData.length; h++) {
      newReserve.push(new Array());
    }
    
    setUseScheduleData(newData);
  }, [scheduleData, breakTimeData, startDay])

  useEffect(() => {
    var tmpData = lodash.cloneDeep(useScheduleData);
    var newTotalData = [];
    var startTimeList = [];

    tmpData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    for (var i = 0; i < tmpData.length; i++) {
      startTimeList.push(tmpData[i].startTime);
    }

    const set = new Set(startTimeList);

    var finalStartTime = [...set];

    finalStartTime.sort(function (a, b) {
      return a < b ? -1 : a > b ? 1 : 0;
    })

    for (var i = 0; i < finalStartTime.length; i++) {
      var flag = false;
      var newDataList = [];
      for (var j = 0; j < tmpData.length; j++) {
        if (finalStartTime[i] === tmpData[j].startTime) {
          newDataList.push(tmpData[j]);
        }
      } 
      newTotalData.push(newDataList);
    }

    for (i = 0; i < newTotalData.length; i++) {
      var count = 0;
      for (j = 0; j < 7; j++) {
        if (newTotalData[i].length === 7) break;

        var newData = {
          classId: -2,
          classDate: startDay.clone().add(j, 'day').format('YYYY.MM.DD'),
          className: '수업없음',
          classTime: ' ',
          teacherName: ' ',
          isNeedNewDivide: true,
          viewTime: newTotalData[i][0].viewTime
        }


        if (newTotalData[i].length === 1) {
          // console.log(i + "번째 " + startDay.clone().add(j, 'day').format('YYYY.MM.DD') + " Push 0")
          newTotalData[i].push(newData);
        }
        else if (newTotalData[i].length === 6 && j === 6) {
          newTotalData[i].push(newData);
        }
        else if (newTotalData[i][j].classDate !== startDay.clone().add(j, 'day').format('YYYY.MM.DD')) {
          newTotalData[i].splice(j, 0, newData);
        }

        newTotalData[i].sort(function (a, b) {
           return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
        })
      }
    }
    setTotalData(newTotalData);
  }, [useScheduleData, startDay])

  // 주간 데이터 클릭시
  function onClickWeeklyScheduleBox(data) {
    if (data.className !== '브레이크 타임' && data.className !== '수업없음') {
      onClickScheduleDetail(data.scheduleId);
    }
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TableWrapper>
        <WeeklyColumn today={startDay} />
        <LayerWrapper>
          <TimeDivisionLineWrapper>
            {
              totalData.map((data, index) => (
                <TimeDivisionLine key={index} scheduleData={data[0]} isOver={isOver} isMain={false}/>
              ))
            }
          </TimeDivisionLineWrapper>
          <Table>
            <Tbody>
              {
                totalData.map((data, index) => (
                  <Tr key={index}>
                    {
                      data.map((innerData, idx) => (
                        <Td key={idx} onClick={() => onClickWeeklyScheduleBox(innerData)} isValid={innerData.className !== '수업없음'} color={innerData.className === '수업없음' ? '#ECECEC' : innerData.color}>
                          <Text isValid={innerData.className !== '수업없음'}>
                            <ClassName color={innerData.className === '수업없음' ? '#BDBDBD' : '#232323'}>{innerData.className}</ClassName>
                            <ClassTime>{innerData.classTime}</ClassTime>
                            <CoachName>{innerData.teacherName}</CoachName>
                          </Text>
                        </Td>
                      ))
                    }
                  </Tr>
                ))
              }
            </Tbody>
          </Table>
        </LayerWrapper>
      </TableWrapper>
    </TopLevelWrapper>
  )
})

export default WeeklyClass;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(298px, 93.125vw)`};
  // height: 528px;
  height: 472px;

  padding-top: 28px;
  padding-bottom: 28px;

  margin-top: 16px;
  margin-bottom: 96px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  width: ${props => props.isOver ? `max(638px, 83.07291666666667vw)` : `max(282px, 88.125vw)`};
  height: 472px;
  
  overflow-y: scroll;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-color: #DADADA;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    height: 439px;
    //border: 4px solid transparent;
    background-color: #FFFFFF;
    border-radius: 2px;
  }
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 18px;
  margin-top: 28px;
`;

const TimeDivisionLineWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const Tbody = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  gap: 8px;
`;

const Tr = styled.div` 
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 911px;
  height: 68px;

  gap: 6px;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const Td = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 123px;
  height: 66px;

  border: 1px solid #ECECEC;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.isValid ? `pointer` : `default`};
    border-color: ${props => props.isValid ? props.color : '#ECECEC'};
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: ${props => props.isValid ? `flex-start` : `center`};

  width: 103px;
  height: 50px;

  margin: 0 0 0 0;
`;

const ClassName = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;

const ClassTime = styled.p`
  font-family: Poppins-Regular;
  font-size: 10px;

  color: #777777;

  margin: 0 0 0 0;
`;

const CoachName = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 10px;

  color: #777777;

  margin: 0 0 0 0;
`;