import { React } from 'react';
import styled from 'styled-components';

const QuillCustomToolBar = () => {

  return (
    <TopLevelWrapper id="toolbar">
      <Select className="ql-size">
        <Option value="small">10</Option>
        <Option value="nomal">12</Option>
        <Option value="large">18</Option>
        <Option value="huge">32</Option>
        {/* <Option value="18">18</Option> */}
        {/* <Option value="24">24</Option>
        <Option value="28">28</Option> */}
      </Select>
      <Button className="ql-underline"/>
      <Button className="ql-italic"/>
      <Button className="ql-bold"/>
      <Select className="ql-align"/>
      <Select className="ql-color"/>
      <Select className="ql-background"/>
      <Button className="ql-image"/>
    </TopLevelWrapper>
  )
}

// "size",
// "underline",
// "italic",
// "bold",
// "align",
// "color",
// "background",
// "image"

export default QuillCustomToolBar;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  border: none !important;
`;

const Select = styled.select`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const Option = styled.option`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const Button = styled.button`
  
`;