import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import CalenderImagePath from '../../../image/Calender-gray.png';
import CalenderRightArrowImagePath from '../../../image/CalenderRightArrow.png';
import CalenderLeftArrowImagePath from '../../../image/CalenderLeftArrow.png';
import CalenderLeftArrow2ImagePath from '../../../image/CalenderLeftArrow2.png';
import CalenderRightArrow2ImagePath from '../../../image/CalenderRightArrow2.png';
import BlackExitButton from '../../../image/BlackExitButton.png';

const SmallHoldingCalenderInput = forwardRef((props, ref) => {
  const { value, setValue, small, className, placeholder, clickFn, disabled, width } = props;

  // const [today, setToday] = useState(moment(value, 'YYYY.MM.DD'));
  const [today, setToday] = useState(moment());
  const [isClicked, setIsClicked] = useState(false);
  const [dateList, setDateList] = useState([]);
  // const [value, setValue] = useState('');

  const TopLevelRef = useRef();
  const CalenderRef = useRef();

  const firstWeek = today.clone().startOf('month').week();
  const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

  useImperativeHandle(ref, () => ({
    setValue: (val) => {
      setValue(val)
    },
    getValue: () => {
      return value;
    },
    closeCalender: () => {
      setIsClicked(false);
    }
  }));
  
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = today.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        newDateList.push(days);
      } 
    }
    setDateList(newDateList);

    if (isClicked) {
      // CalenderRef.current.style.height = `405px`;
      CalenderRef.current.style.height = `${118 + 44 * (newDateList.length / 7)}px`;
      CalenderRef.current.style.border = `1px solid #FF8B48`;
      CalenderRef.current.style.zIndex = `2`;
      CalenderRef.current.style.opacity = `1`;
    }
    else {
      CalenderRef.current.style.height = `0px`;
      CalenderRef.current.style.border = `0px solid #FF8B48`;
      CalenderRef.current.style.zIndex = `-3`;
      CalenderRef.current.style.opacity = `0`;
    }
  }, [isClicked, today, firstWeek, lastWeek]) 

  // 컴포넌트 외부 클릭시 이벤트를 받기위한 리스너 연결
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 외부 클릭시 실행
  function onClickOutSide(e) {
    if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
        setIsClicked(false);
    }
  }
  
  function inputClick() {
    if (disabled) return;
    
    setIsClicked(!isClicked);
    clickFn(className);
  }

  // 날짜 변경 클릭시 //
  function dateClick(index) {
    setValue(dateList[index]);
    setIsClicked(false);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} small={small}>
      <Input img={CalenderImagePath} onClick={inputClick} disabled={disabled} width={width}>
        <Text black={value.format('YYYY.MM.DD') !== '' ? true : false}>
          {
            value.format('YYYY.MM.DD') !== '' ? value.format('YYYY.MM.DD') : placeholder
          }
        </Text>
      </Input>
      <CalenderView ref={CalenderRef}>
        <ButtonWrapper>
          <TitleWrapper>
            <Arrow img={CalenderLeftArrow2ImagePath} onClick={() => setToday(today.clone().subtract(1, 'year'))} style={{ margin: `0 -7px 0 0` }} />
            <Arrow img={CalenderLeftArrowImagePath} onClick={() => setToday(today.clone().subtract(1, 'month'))} />
            <Title>{today.format('YYYY년 MM월')}</Title>
            <Arrow img={CalenderRightArrowImagePath} onClick={() => setToday(today.clone().add(1, 'month'))} />
            <Arrow img={CalenderRightArrow2ImagePath} onClick={() => setToday(today.clone().add(1, 'year'))} style={{ margin: `0 0 0 -7px` }} />
          </TitleWrapper>
          <ExitButton src={BlackExitButton} onClick={() => setIsClicked(false)} />
        </ButtonWrapper>
        <DateColumnWrapper small={small}>
          <DateColumn style={{ width: `25px`, marginRight: `16px` }}>SUN</DateColumn>
          <DateColumn style={{ width: `30px`, marginRight: `15px` }}>MON</DateColumn>
          <DateColumn style={{ width: `23px`, marginRight: `16px` }}>TUE</DateColumn>
          <DateColumn style={{ width: `28px`, marginRight: `16px` }}>WED</DateColumn>
          <DateColumn style={{ width: `25px`, marginRight: `21px` }}>THU</DateColumn>
          <DateColumn style={{ width: `18px`, marginRight: `21px` }}>FRI</DateColumn>
          <DateColumn style={{ width: `24px` }}>SAT</DateColumn>
        </DateColumnWrapper>
        <DateWrapper small={small}>
          {
            dateList.map((data, index) => (
              data.format('MM') === today.format('MM') ?
              <Date key={index} onClick={() => dateClick(index)}>
                {data.format('D')}
              </Date>
              :
              <WithoutHoverDate key={index}>
                {data.format('D')}
              </WithoutHoverDate>
            ))
          }
        </DateWrapper>
      </CalenderView>
    </TopLevelWrapper>
  )
})

export default SmallHoldingCalenderInput;

const TopLevelWrapper = styled.div`
  display: ${props => props.small ? `flex` : ``};
  flex-direction: ${props => props.small ? `column` : ``};

  justify-content: ${props => props.small ? `flex-start` : ``};
  align-items: ${props => props.small ? `center` : ``};
`;

const Input = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: ${props => props.width};
  height: 30px;

  margin-top: ${props => props.small ? `0px` : `8px`};

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  background: url(${props => props.img}) no-repeat 10px center;
  background-color: ${props => props.disabled ? '#ECECEC' : '#FFFFFF'};

  transition: all 0.2s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.black ? `#000000` : `#CBCBCB`};

  margin: 0 0 0 34px;
`;

const CalenderView = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  border: 0px solid #FF8B48;
  border-radius: 8px;
  background-color: #FFFFFF;

  z-index: -30;
  opacity: 0;

  overflow: hidden;
  
  transition: all 0.3s ease-in-out;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(100% - 56px);

  margin-top: 28px;
  padding: 0 28px 0 28px;
`;

const ExitButton = styled.img`
  width: 24px;
  height: 24px;

  :hover {
    cursor: pointer;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // margin: 28px 0 0 22px;
`;

const Arrow = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  
  background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const DateColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 29px;
  margin-left: ${props => props.small ? `8px` : `28px`};
  margin-bottom: 19px;
`;

const DateColumn = styled.p`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: Poppins-bold;
  font-size: 12px;

  color: #FF8B48;
  
  margin: 0 0 0 0;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  // width: 448px;
  // width: 321px;
  width: ${props => props.small ? `300px` : `321px`};
  // margin-left: 8px;
  margin-left: ${props => props.small ? `0px` : `20px`};
`;

const Date = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  // width: 48px;
  width: 40px;
  // height: 48px;
  height: 40px;

  // border-radius: 48px;
  border-radius: 40px;

  // margin: 0 7px 5px 7px;
  margin: 0 1px 2px 1px;

  background-color: #FFFFFF;
  
  font-family: Poppins-Medium;
  font-size: 14px;

  color: #595959;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF7322;
    color: #FFFFFF;

    font-fmaily: Poppins-Bold;
    // font-size: 14px;
  }
`;

const WithoutHoverDate = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  // width: 48px;
  width: 40px;
  // height: 48px;
  height: 40px;

  // border-radius: 48px;
  border-radius: 40px;

  // margin: 0 7px 5px 7px;
  margin: 0 1px 2px 1px;

  background-color: #FFFFFF;

  font-family: Poppins-Regular;
  font-size: 12px;

  color: #777777;

  :hover {
    cursor: default;
  }
`;