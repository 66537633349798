import { useState, useImperativeHandle, forwardRef, useRef, React } from 'react';
import styled from 'styled-components';

const LockerChangeNameInput = forwardRef((props, ref) => {
	const { className, placeholder, type } = props;
	const [value, setValue] = useState('');
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
		},
		getValue: () => {
			return value;
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			setChangedPlaceholderColor(true);
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#E8E8E8';
		}
	}));

	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';
		// setDefaultPlaceholder('');
	}

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#E8E8E8';
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return (
    <TopLevelWrapper>
      <Title>변경할 락커 이름</Title>
      <Input
        ref={inputRef}
        clickCount={clickCount}
        type={type}
        placeholder={defaultPlaceholder}
        value={value}
        changedColor={changedPlaceholderColor}
        className={`${className} ${changed ? "changed" : ""}`}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  ) 
});

export default LockerChangeNameInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 16px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
	padding: 5px 0 7px 8px;
	margin-top: 8px;

  width: 200px;
  height: 18px;

	outline: none;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

	color: #000000;

	::placeholder {
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.3s ease-in-out;
`;