import { React, useEffect, useState } from 'react';
import styled from 'styled-components';

import WaittingView from '../../Model/Individual/MemberModel/WaittingView.js';
import UnSupportedDeviceModal from '../../Model/Individual/MemberModel/MemberDetail/Modal/UnsupportedDeviceModal.js';

import useWindowSize from '../../Model/Share/Normal/useWindowSize.js';

function WaittingMemberPage() {
  const size = useWindowSize();

  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  return (
    <Container>
      { !isOver && <UnSupportedDeviceModal isModal={false}/>}
      <WaittingView isOver={isOver}/>
    </Container>
  )
}

export default WaittingMemberPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;