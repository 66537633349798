import { React } from 'react';
import styled from 'styled-components';

import NextButtonImage from '../../../image/NormalNextInfoButton.png';
import ColorPrevButtonImage from '../../../image/ColorPrevInfoButton.png';

const ThirdValidColumnView = (props) => {
  const { prevFn } = props;

  return (
    <TopLevelWrapper>
      <ColumnWrapper>
        <Column style={{ marginRight: 'max(28px, 8.75vw)', marginLeft: 'max(5px, 1.5625vw)' }}>이름</Column>
        <Column style={{ marginRight: 'max(28px, 8.75vw)' }}>성별</Column>
        <Column>회원권</Column>
      </ColumnWrapper>
      <ButtonWrapper>
        <Button src={ColorPrevButtonImage} onClick={() => prevFn()}/>
        <Button src={NextButtonImage}/>
      </ButtonWrapper>
    </TopLevelWrapper>
  )
}

export default ThirdValidColumnView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: max(268px, 83.75vw);
  margin-top: 9px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 10px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;

const Button = styled.img`
  width: 15px;
  height: 15px;

  border-radius: 15px;

  :hover {
    cursor: pointer;
  }
`;