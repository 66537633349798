import { React } from 'react';
import styled from 'styled-components';
import RefundModel from '../../Model/Individual/RulesModel/RefundModel';

function RefundPage() {
  
  return (
    <Container>
      <RefundModel/>
    </Container>
  )
}

export default RefundPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100vw;
`;