import { React, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

const DayApplyInput = forwardRef((props, ref) => {
  const { isOver } = props;

  const [value, setValue] = useState(0);

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
		},
    getValue: () => {
      return value;
    }
	}))

  useEffect(() => {
    if (value === '') setValue(0);
    
  }, [value])

  return (
    <TopLevelWrapper isOver={isOver}>
      <Title isOver={isOver}>적용 일수</Title>
      <Wrapper isOver={isOver}>
        <Input isOver={isOver}>{value}</Input>
        <Text>일</Text>
      </Wrapper>
    </TopLevelWrapper>
  )
})

export default DayApplyInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: ${props => props.isOver ? `center` : `flex-start`};
  align-items: ${props => props.isOver ? `center` : `flex-start`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: ${props => props.isOver ? `0 16px 0 0` : `0 0 4px 0`};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Input = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: ${props => props.isOver ? `61px` : `71px`};
  height: ${props => props.isOver ? `30px` : `24px`};

  margin-right: 8px;
  padding: 0 0 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;



