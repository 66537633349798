import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';

import TopBar from './TopBar.js';
import CenterInfo from './CenterInfo.js';
import useWindowSize from './useWindowSize.js';

import FetchModule from '../Network/FetchModule';
import SubscribePushModal from './SubscribePushModal';

import { useLocation } from 'react-router-dom';
import NotRegistrationCardModal from './NotRegistrationCardModal.js';
import ReceiveStartCouponModal from './ReceiveStartCouponModal.js';

function DefaultView({match}) {
  const size = useWindowSize();
  const location = useLocation();

  const history = useHistory();

  const nowPage = window.location.href;
 
  // const defaultURL = 'http://localhost:3000';
  // const defaultURL = 'http://172.30.1.8:3001';
  const defaultURL = 'https://m.link-zone.io';

  const [isOver, setIsOver] = useState(false);
  const [data, setData] = useState([]);
  const [isViewSubscribeModal, setIsViewSubscribeModal] = useState(false);
  const [isViewReceiveModal, setIsViewReceiveModal] = useState(false);
  const [isViewNotRegistrationCardModal, setIsViewNotRegistrationCardModal] = useState(false);
  
  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  
  useEffect(() => {

    // if (nowPage === defaultURL + '/'
    // || nowPage.includes(defaultURL + '/certification')
    // || nowPage.includes(defaultURL + '/identifier')
    // || nowPage.includes(defaultURL + '/permute')
    // || nowPage.includes(defaultURL + '/rule/')
    // || nowPage.includes(defaultURL + '/complete')
    // || nowPage.includes(defaultURL + '/payment')
    // || nowPage === defaultURL + '/full/board/'
    // || nowPage.includes(defaultURL + '/ruler')
    // || nowPage.includes(defaultURL + '/keypad')) return null;
  
    if (nowPage === defaultURL + '/'
    || nowPage.includes('/certification')
    || nowPage.includes('/identifier')
    || nowPage.includes('/permute')
    || nowPage.includes('/rule/')
    || nowPage.includes('/complete')
    || nowPage.includes('/payment')
    || nowPage === defaultURL + '/full/board/'
    || nowPage.includes('/ruler')
    || nowPage.includes('/keypad')
    || nowPage.includes('/view')
    || nowPage.includes('/user/registration_card')
    || nowPage.includes('/complete/registration_card')
    || nowPage.includes('/user/registration_billing')
    || nowPage.includes('/complete/registration_billing')) return null;

    if (window.localStorage.getItem('StoreId') === null 
    || window.localStorage.getItem('Token') === null) {
      // window.localStorage.setItem('StoreId', null);
      // window.localStorage.setItem('Token', null);
      // window.localStorage.setItem('RefreshToken', null);
      // window.localStorage.setItem('AccountId', null);
      window.localStorage.removeItem('StoreId');
      window.localStorage.removeItem('Token');
      window.localStorage.removeItem('RefreshToken');
      window.localStorage.removeItem('AccountId');
      history.push('/');

      return;
    }

    if (!nowPage.includes('/member/valid') 
        && window.localStorage.getItem('ValidMemberPage') !== null) window.localStorage.removeItem('ValidMemberPage');

    if (!nowPage.includes('/member/valid')
        && !nowPage !== defaultURL + '/member/valid'
        && !nowPage.includes('/member/expired')
        && window.localStorage.getItem('InvalidMemberPage') !== null) window.localStorage.removeItem('InvalidMemberPage');

    if (window.localStorage.getItem('StoreId') === 'null') return null;



    let lastUpdateDate = window.localStorage.getItem('LastUpdateDate');

    // 마지막 업데이트 내역이 없을경우 //
    if (lastUpdateDate === null) window.localStorage.setItem('LastUpdateDate', moment().format('YYYY-MM-DDTHH:mm:ss'));
    else if (moment.duration(moment().diff(moment(lastUpdateDate))).asHours() > 1) {
      window.location.reload(true);
      window.localStorage.setItem('LastUpdateDate', moment().format('YYYY-MM-DDTHH:mm:ss'));
    }
    
    (
      async () => {
        const fetchModule = new FetchModule();
        const responseData = await fetchModule.getData('store/', 'GET');
        
        if (responseData.status === 200) {
          setData(responseData.data[0]);
				}   
        // 201 : 구독권이 존재하지 않을시, 202 : 구독권 기간이 만료되었을시 //
        else if (responseData.status === 201 || responseData.status === 202) {
          setData(responseData.data[0]);
          // console.log(nowPage);
          // if (nowPage !== defaultURL + '/mypage') history.push('/mypage');
          // if (nowPage !== defaultURL + '/mypage') setIsViewSubscribeModal(true);
          setIsViewNotRegistrationCardModal(true);
          setData(responseData.data[0]);
        }
        // 203 : 시작 기념 쿠폰 (3개월 혹은 6개월)을 수령하지 않았을 경우 //
        else if (responseData.status === 203) {
          // setIsViewReceiveModal(true);
          setIsViewNotRegistrationCardModal(true);
          setData(responseData.data[0]);

        }
        else if (responseData.status === 401) {
          // window.localStorage.setItem('StoreId', null);
          // window.localStorage.setItem('Token', null);
          // window.localStorage.setItem('RefreshToken', null);
          // window.localStorage.setItem('AccountId', null);
          window.localStorage.removeItem('StoreId');
          window.localStorage.removeItem('Token');
          window.localStorage.removeItem('RefreshToken');
          window.localStorage.removeItem('AccountId');
          history.push('/');
          return;
        }
      }
    )();
  }, [history, nowPage])


  function onClickSubscribeButton() {
    setIsViewSubscribeModal(false);
    history.push('/mypage');
  }


  // if (nowPage === defaultURL + '/'
  // || nowPage.includes(defaultURL + '/certification')
  // || nowPage.includes(defaultURL + '/identifier')
  // || nowPage.includes(defaultURL + '/permute')
  // || nowPage.includes(defaultURL + '/rule/')
  // || nowPage.includes(defaultURL + '/complete')
  // || nowPage.includes(defaultURL + '/payment')
  // || nowPage === defaultURL + '/full/board/'
  // || nowPage.includes(defaultURL + '/ruler')
  // || nowPage.includes(defaultURL + '/keypad')) return null;

  if (nowPage === defaultURL + '/'
  || nowPage.includes('/certification')
  || nowPage.includes('/identifier')
  || nowPage.includes('/permute')
  || nowPage.includes('/rule/')
  || nowPage.includes('/complete')
  || nowPage.includes('/payment')
  || nowPage === defaultURL + '/full/board/'
  || nowPage.includes('/ruler')
  || nowPage.includes('/keypad')
  || nowPage.includes('/view')
  || nowPage.includes('/user/registration_card')
  || nowPage.includes('/complete/registration_card')
  || nowPage.includes('/user/registration_billing')
  || nowPage.includes('/complete/registration_billing')) return null;


  // 쿠폰 확인하러 가기 클릭시 //
  function onClickConfirmCoupon() {
    setIsViewReceiveModal(false);
    history.push('/mypage');
  }

  // 입력하기 버튼 클릭시 //
  function onClickRegistrationCard() {
    const obj = {};

    obj.PCD_PAY_TYPE = 'card'
    obj.PCD_PAY_WORK = 'AUTH'
    // obj.PCD_PAY_WORK = 'PAY'
    obj.PCD_CARD_VER = '01';
    obj.PCD_PAYER_AUTHTYPE = 'sms';
    // obj.PCD_RST_URL = process.env.REACT_APP_TEST_HOSTNAME + '/subscribe/success';
    // obj.PCD_RST_URL = 'https://link-soft.io/';

    // obj.PCD_PAY_GOODS = 'Link-Zone 정기 구독권 (1개월)';
    // obj.PCD_PAY_TOTAL = 100;
    // obj.PCD_PAY_OID = responseData.data[0].nextMerchantUid;


    obj.callbackFunction = getResult;
    obj.clientKey = '0F44C92BFB07F1BE60C5235A7254316D';
    // obj.clientKey = 'test_DF55F29DA654A8CBC0F0A9DD4B556486';
    
    window.PaypleCpayAuthCheck(obj);
  }
  
  // 결제 이후 콜백 //
  function getResult(res) {
    if (res.PCD_PAY_RST === 'success' && (res.PCD_PAY_CODE === '0000' || res.PCD_PAY_CODE === 'CDAU1000')) {
      let requestData = {
        billingKey: res.PCD_PAYER_ID
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('billing/registry_zone', 'POST', requestData);

          if (responseData.status === 200) {
            // alert('카드번호 등록이 완료되었습니다!');
            setIsViewNotRegistrationCardModal(false);
            setIsViewReceiveModal(true);
          }
        }
      )();
    }
    else {
      if (res.PCD_PAY_CODE === undefined) {
        console.log(res.PCD_PAY_MSG);
        alert(res.PCD_PAY_MSG);
      }
      else {
        console.log('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
        alert('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
      }
    }
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      { isViewSubscribeModal && <SubscribePushModal isOver={isOver} yesFn={onClickSubscribeButton}/> }
      { isViewReceiveModal && <ReceiveStartCouponModal setIsViewReceiveModal={setIsViewReceiveModal} onClickConfirmCoupon={onClickConfirmCoupon}/> }
      { isViewNotRegistrationCardModal && <NotRegistrationCardModal setIsViewModal={setIsViewNotRegistrationCardModal} onClickRegistCard={onClickRegistrationCard}/> }
      <TopBar isOver={isOver} data={data}/>
      <CenterInfo isOver={isOver} data={data}/>
    </TopLevelWrapper>
  )
}

export default DefaultView;

const startAnimation = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0.5; } 
  100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  align-items: center;
  vertical-align: bottom;
  // background-color: #FDFCFB;
  
  // width: 100vw;

  padding-bottom: ${props => props.isOver ? `38px` : `13px`};

  animation-name: ${startAnimation};
  animation-duration: 0.3s;
`;