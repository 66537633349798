import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import lodash from 'lodash';

import MonthlyColumn from './MonthlyColumn';

const MonthlyClass = forwardRef((props, ref) => {
  const { isOver, date, useScheduleData } = props;

  const [today, setToday] = useState(date);
  const [dateList, setDateList] = useState([]);
  // const [isClicked, setIsClicked] = useState(false);

  const [useDateList, setUseDateList] = useState([]);
  // const [useScheduleData, setUseScheduleData] = useState([]);

  const firstWeek = today.clone().startOf('month').week();
  const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

  const TopLevelRef = useRef();

  useImperativeHandle(ref, () => ({
    visible: () => {
			TopLevelRef.current.style.opacity = 1;
			TopLevelRef.current.style.zIndex = 1;
		},
		unVisible: () => {
			TopLevelRef.current.style.opacity = 0;
			TopLevelRef.current.style.zIndex = -3;
		}
  }))

  useEffect(() => {
    setToday(date);
  }, [date])
  
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = today.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        var newData = {
          date: days,
          className: '',
          count: 0
        }

        newDateList.push(newData);
      } 
    }
    setDateList(newDateList);
  }, [today, firstWeek, lastWeek]) 
  
  useEffect(() => {
    var newDateList = lodash.cloneDeep(dateList);

    for (var i = 0; i < useScheduleData.length; i++) {
      for (var j = 0; j < newDateList.length; j++) {
        if (useScheduleData[i].classDate === newDateList[j].date.format('YYYY.MM.DD')) {
          // console.log(newDateList[j]);
          if (newDateList[j].className === '') {
            newDateList[j].className = useScheduleData[i].className;
          }
          else {
            newDateList[j].count += 1;
          }
        }
      }
    }

    setUseDateList(newDateList);
  }, [useScheduleData, dateList])

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TotalWrapper>
        <MonthlyColumn />
        <DateWrapper>
          {
            useDateList.map((data, index) => (
              data.date.format('MM') === today.format('MM') ?
                <Date key={index} notHover={false}>
                  <TopComponent>
                    {data.date.format('D 일')}
                  </TopComponent>
                  <BottomComponent>
                    <TextWrapper>
                      {
                        data.className === '' ?
                          <ClassName>수업 없음</ClassName>
                          :
                          <ClassName>{data.className}</ClassName>
                      }
                      { data.className !== '' && <Count isView={data.count > 0}>외 {data.count}개 수업</Count> }
                    </TextWrapper>
                  </BottomComponent>
                </Date>
                :
                <Date key={index} notHover={true}>
                  <TopComponent>
                    {data.date.format('D 일')}
                  </TopComponent>
                  <BottomComponent>
                    <TextWrapper>
                      {
                        data.className === '' ?
                        <ClassName>수업 없음</ClassName>
                        :
                        <ClassName>{data.className}</ClassName>
                      }
                      { data.className !== '' && <Count isView={data.count > 0}>외 {data.count}개 수업</Count> }
                    </TextWrapper>
                  </BottomComponent>
                </Date>
            ))
          }
        </DateWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
})

export default MonthlyClass;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(298px, 93.125vw)`};

  padding-top: 28px;
  padding-bottom: 28px;

  margin-top: 16px;
  margin-bottom: 96px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(282px, 88.125vw)`};
  height: 472px;

  overflow-y: scroll;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-color: #DADADA;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    height: 439px;
    //border: 4px solid transparent;
    background-color: #FFFFFF;
    border-radius: 2px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 964px;

  gap: 9px;
`;

const Date = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 128px;
  height: 71px;

  border: 1px solid #ECECEC;
  border-radius: 15px;

  background-color: #FFFFFF;
  
  font-family: Poppins-Medium;
  font-size: 14px;

  color: #595959;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.notHover ? `default` : `pointer`};
    border: ${props => props.notHover ? `1px solid #ECECEC` : `1px solid #FF8B48`};
  }
`;

const TopComponent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 112px;
  height: 20px;

  padding-left: 14px;

  border: 1px solid #ECECEC;
  border-radius: 15px 15px 0px 0px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Medium;
  font-size: 10px;

  color: #777777;
`;

const BottomComponent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 112px;
  height: 51px;

  padding-left: 14px;

  border: 1px solid #ECECEC;
  border-radius: 0px 0px 15px 15px;
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 103px;
  height: 35px;

  gap: 4px;
`;

const ClassName = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Count = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 10px;

  color: #777777;

  margin: 0 0 0 0;
`;