import { React, useRef, forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';

import LogoPath from '../../../image/CompleteSignUpLogo.png';

const CompleteSignUp= forwardRef((props, ref) => {
  const { isOver } = props;

  const [userName, setUserName] = useState('');

  const TopLevelRef = useRef();

	useImperativeHandle(ref, () => ({
    setUserName: (val) => {
      setUserName(val)
    },
    setOpacity: (val) => {
      if (val === 0) {
        TopLevelRef.current.style.zIndex = -10;
        TopLevelRef.current.style.opacity = val;
      }
      else {
        TopLevelRef.current.style.zIndex = 2;
        TopLevelRef.current.style.opacity = val;
      }
    }
	}))

  function onClickGoHomeButton() {
    window.location.reload();
  }

	return (
		<TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
      <LogoWrapper isOver={isOver}>
        <Logo src={LogoPath} isOver={isOver}/>
      </LogoWrapper>
      <Name isOver={isOver}>{userName}님!</Name>
      <ExplanationWrapper isOver={isOver}>
        <EngExplanation>Link Zone</EngExplanation>
        <KorExplanation>가입을</KorExplanation>
        <KorExplanation>진심으로 환영합니다!</KorExplanation>
      </ExplanationWrapper>
      <GoHomeButton isOver={isOver}>
        <ButtonText onClick={onClickGoHomeButton}>홈페이지로 돌아가기</ButtonText>
      </GoHomeButton>
		</TopLevelWrapper>
	)
})
 
export default CompleteSignUp;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

	width: ${props => props.isOver ? `356px` : `264px`};
	height: ${props => props.isOver ? `278px` : `206px`};

	border-radius: 30px;
	box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;

  z-index: -10;
  opacity: 0;

  // z-index: 10;
  // opacity: 1;

  transition: all 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: ${props => props.isOver ? `-110px` : `-80px`};
`;

const Logo = styled.img`
  width: ${props => props.isOver ? `152px` : `112px`};
  height: ${props => props.isOver ? `152px` : `112px`};
`;

const Name = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 22px;

  margin: ${props => props.isOver ? `24px 0 0 0` : `0 0 0 0`};
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  width: 141px;

  margin-top: ${props => props.isOver ? `10px` : `13px`};
`;

const EngExplanation = styled.p`
  font-family: Poppins-Regular;
  font-size: 14px;

  margin: 0 0 0 0;
`;

const KorExplanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;
`;

const GoHomeButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `215px` : `159px`};
  height: ${props => props.isOver ? `28px` : `20px`};

  border-radius: 14px;
  background-color: #FF8B48;

  margin-top: ${props => props.isOver ? `34px` : `23px`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #FF7322;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;
  
  color: #FFFFFF;

  margin: 0 0 0 0;
`;