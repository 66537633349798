import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

const ClassTypeInput = forwardRef((props, ref) => {
  const { isOver } = props;
  const [nowClickedType, setNowClickedType] = useState('Offline');

  const OnlineButtonRef = useRef();
  const OnlineTextRef = useRef();
  const OfflineButtonRef = useRef();
  const OfflineTextRef = useRef();

  useImperativeHandle(ref, () => ({
    setValue: (val) => {
      setNowClickedType(val);
    },
		getValue: () => {
			return nowClickedType;
		}
	}));

  useEffect(() => {
    if (nowClickedType === 'Online') {
      OnlineButtonRef.current.style.backgroundColor = `#FF8B48`;
      OnlineTextRef.current.style.color = `#FFFFFF`;
      OfflineButtonRef.current.style.backgroundColor = `#FFFFFF`;
      OfflineTextRef.current.style.color = `#CBCBCB`;
    }
    else if (nowClickedType === 'Offline') {
      OnlineButtonRef.current.style.backgroundColor = `#FFFFFF`;
      OnlineTextRef.current.style.color = `#CBCBCB`;
      OfflineButtonRef.current.style.backgroundColor = `#FF8B48`;
      OfflineTextRef.current.style.color = `#FFFFFF`;
    }
  }, [nowClickedType])

  return(
    <TopLevelWrapper isOver={isOver}>
      <Button ref={OfflineButtonRef} isOver={isOver} onClick={() => setNowClickedType('Offline')}>
        <ButtonText ref={OfflineTextRef} isOver={isOver}>오프라인</ButtonText>
      </Button>
      <Button ref={OnlineButtonRef} isOver={isOver} onClick={() => setNowClickedType('Online')}>
        <ButtonText ref={OnlineTextRef} isOver={isOver}>온라인</ButtonText>
      </Button>
    </TopLevelWrapper>
  )
})

export default ClassTypeInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direcion: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `8px` : `6px`};
  margin-top: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `147px` : `116px`};
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #CBCBCB;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;
`;