import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import StopWatchSetting from '../../../Component/BoardComponent/StopWatchSetting';
import StartCountView from './StartCountView';

import FetchModule from '../../Share/Network/FetchModule';
import StopWatchAddModal from './StopWatchSettingAddModal';
import StartCountInput from '../../../Component/BoardComponent/StartCountInput';

const StopWatchView = (props) => {
  const { isOver } = props;
  const history = useHistory();

  const [count, setCount] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [milisec, setMilisec] = useState(0);

  const [availableClick, setAvailableClick] = useState(true);
  const [start, setStart] = useState(false);
  const [startCount, setStartCount] = useState(false);
  const [round, setRound] = useState(0);
  const [nowTime, setNowTime] = useState('');
  const [fontColor, setFontColor] = useState('#595959');

  const [settingData, setSettingData] = useState([]);
  const [useSettingData, setUseSettingData] = useState([{
    id: -1,
    // storeId: window.sessionStorage.getItem('StoreId'),
    storeId: window.localStorage.getItem('StoreId'),
    title: '비어있는 셋팅',
    startCount: 0,
    type: 'None',
    round: 0,
    exerciseTime: 0,
    restTime: 0
  }]);

  const [addClick, setAddClick] = useState(false);

  const [index, setIndex] = useState(0);

  const [rendering, setRendering] = useState(false);

  const time = useRef();
  const CountRef = useRef();
  const StartCountRef = useRef();

  useEffect(() => {
    (
      async () => {
        const fetchModule = new FetchModule();
        const responseData = await fetchModule.getData('stop_watch/', 'GET');
        
        if (responseData.status === 200) {
          setSettingData(responseData.data);
				}
        else if (responseData.status === 204) setSettingData([]);
        else if (responseData.status === 401) history.push('/');
        else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }, [history, rendering])

  useEffect(() => {
    var newData = settingData;

    for (var i = settingData.length; i < 5; i++) {
      var data = {
        id: -1,
        // storeId: window.sessionStorage.getItem('StoreId'),
        storeId: window.localStorage.getItem('StoreId'),
        title: '비어있는 셋팅',
        startCount: 0,
        type: 'None',
        round: 0,
        exerciseTime: 0,
        restTime: 0
      }

      newData.push(data);
    }
    setIndex(0);
    setUseSettingData(newData);
  }, [settingData])

  useEffect(() => {
    
    if (useSettingData[index].type === 'Up') setCount(0);
    else if (useSettingData[index].type === 'Down') setCount(useSettingData[index].exerciseTime * 100);

  }, [useSettingData, index])
  // useEffect()

  useEffect(() => {
    if (!start) return;

    setMin(Math.floor(count / 6000));
    // console.log(count);

    var tmp = count % 6000;
    setSec(Math.floor(tmp / 100));

    tmp = tmp % 100;
    setMilisec(tmp);

    if (useSettingData[index].type === 'Up') {
      if ((count / 100) === useSettingData[index].exerciseTime) {
        if (round < useSettingData[index].round) {
          clearInterval(time.current);
          setRound(round + 1);
          setCount(0);
          countUpStart();
        }
        else {
          clearInterval(time.current);
          setStart(false);
          setFontColor('#595959');
        }
      }
    }
    else if (useSettingData[index].type === 'Down') {
      if (count === 0) {
        clearInterval(time.current);
        setStart(false);
        setFontColor('#595959');
      }
    }
    else if (useSettingData[index].type === 'Interval') {
      if (count === 0) {
        if (round < useSettingData[index].round) {
          clearInterval(time.current);
          // setCount(0);

          if (nowTime === 'exercise') { 
            restCountDownStart();
            setNowTime('rest');
            setFontColor('#5BD98A');
          }
          else if (nowTime === 'rest') {
            setRound(round + 1);
            countDownStart();
            setNowTime('exercise');
            setFontColor('#FF8B48');
          }
        }
        else {
          clearInterval(time.current);
          setStart(false);
          setFontColor('#595959');
        }
      }
    }
    
  }, [count, useSettingData, start, index, round, nowTime])

  function countUpStart() {
    let startPoint = 0;

    time.current = setInterval(() => {
      startPoint += 1;
      setCount(startPoint + 1);
    }, 10)

    setStart(true);
    setAvailableClick(true);
  }

  function countDownStart() {
    var startPoint = useSettingData[index].exerciseTime * 100;
    setCount(startPoint);

    time.current = setInterval(() => {
      startPoint -= 1;
      setCount(startPoint);
    }, 10)

    setStart(true);
    setAvailableClick(true);
  }

  function restCountDownStart() {
    var startPoint = useSettingData[index].restTime * 100;
    setCount(startPoint);

    time.current = setInterval(() => {
      startPoint -= 1;
      setCount(startPoint);
    }, 10)

    setStart(true);
    setAvailableClick(true);
  }

  function totalStartControl() {
    CountRef.current.style.opacity = 1;
    
    setStartCount(false);
    setFontColor('#FF8B48');

    if (useSettingData[index].type === 'Up') {
      setRound(1);
      countUpStart();
    }
    else if (useSettingData[index].type === 'Down') {
        setRound(1);
        countDownStart();
    }
    else if (useSettingData[index].type === 'Interval') {
      setRound(1);
      countDownStart();
      setNowTime('exercise');
    }
  }

  function startClick() {
    
    if (start) {
      clearInterval(time.current);
      setStart(false);
    }
    else if (availableClick) {
      setAvailableClick(false);
      if (useSettingData[index].startCount !== 0) {
        CountRef.current.style.opacity = 0.1;
        StartCountRef.current.startCount();
        setStartCount(true);
      }
      else {
        totalStartControl();
      }
    }
  }

  function modalExitClick() {
    setAddClick(false);
  }

  function settingClick(index) {
    if (!start && !startCount) setIndex(index);
  }

  function deleteClick(index) {
    if (start) return;

    (
      async () => {
        const fetchModule = new FetchModule();
        const responseData = await fetchModule.deleteData('stop_watch/delete/' + useSettingData[index].id + '/', 'DELETE');
        
        if (responseData.status === 200) {
          setRendering(!rendering);
				}
        else if (responseData.status === 204) setRendering(!rendering);
        else if (responseData.status === 401) history.push('/');
        else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }

  async function resetClick() {
    if (useSettingData[index].type === 'Up') {
      await clearInterval(time.current);
      await setStart(false);
      
      setMin(0);
      setSec(0);
      setMilisec(0);
    }
    else if (useSettingData[index].type === 'Down') {
      await clearInterval(time.current);
      await setStart(false);
      // 타이머 원상태로 정리
      setCount(0);
      setMin(0);
      setSec(0);
      setMilisec(0);
    }
    else if (useSettingData[index].type === 'Interval') {
      await clearInterval(time.current);
      await setStart(false);
      
      setMin(0);
      setSec(0);
      setMilisec(0);
    }
  }

  return (
    <TopLevelWrapper>
      {addClick && <StopWatchAddModal rendering={() => setRendering(!rendering)} exitClickFn={modalExitClick}/>}
      <TitleWrapper>
        <Title>스탑워치</Title>
        <Reset onClick={resetClick}>초기화</Reset>
      </TitleWrapper>
      <MainView>
        <Round>ROUND {round}</Round>
        <CountWrapper ref={CountRef}>
          <Count fontColor={fontColor}>{(min >= 10 ? min : '0' + min)}</Count>
          <Count fontColor={fontColor}>:</Count>
          <Count fontColor={fontColor}>{(sec >= 10 ? sec : '0' + sec)}</Count>
          <Count fontColor={fontColor}>.</Count>
          <Count fontColor={fontColor}>{(milisec >= 10 ? milisec : '0' + milisec)}</Count>
        </CountWrapper>
        <StartCountView ref={StartCountRef} startCount={useSettingData[index].startCount} startCountEndFn={totalStartControl}/>
      </MainView>
      <ButtonWrapper>
        <StartButton isStart={start} onClick={startClick}>{start ? `종료` : `시작`}</StartButton>
        <SettingButton>{useSettingData[index].type === 'Up' ? `카운트 업` : useSettingData[index].type === 'Down' ? `카운트 다운` : useSettingData[index].type === 'Interval' ? '인터벌' : '카운트 업'}</SettingButton>
      </ButtonWrapper>
      <OptionWrapper>
        {
          useSettingData.map((data, idx) => (
            <StopWatchSetting 
              key={idx} 
              data={data} 
              isClicked={index === idx} 
              onClick={() => settingClick(idx)} 
              addClick={() => setAddClick(true)}
              deleteClick={() => deleteClick(idx)}/>
          ))
        }
      </OptionWrapper>
    </TopLevelWrapper>
  )
}

export default StopWatchView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 612px;
  width: max(692px, 90.10416666666667vw);
  height: 468px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 556px;
  width: max(636px, 82.8125vw);
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Reset = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const MainView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const Round = styled.p`
  font-family: Poppins-Bold;
  font-size: 28px;

  margin: 0 0 0 0;

  color: #FF8B48;
`;

const CountWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
  
  transition: all 0.3s ease-in-out;
  // width: 420px;
`;

const Count = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 100px;

  margin: 0 0 0 0;

  color: ${props => props.fontColor};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const StartButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 106px;
  height: 36px;

  border-radius: 15px;
  background-color: ${props => props.isStart ? `#FF3131` : `#5BD98A`};

  font-fmaily: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${props => props.isStart ? `#DD0000` : `#2BCB67`};
  }
`;

const SettingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 104px;
  height: 34px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-fmaily: NotoSansKR-Medium;
  font-size: 14px;

  color: #CBCBCB;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 4px;
  margin-top: 24px;
`;