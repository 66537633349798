import { React, forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import TableColumn from './TableColumn';
import TimeDivisionLine from './TableTimeDivisionLine';

import FetchModule from '../../Share/Network/FetchModule';
import SortingModule from '../../Share/Normal/SortingModule';

const TableView = forwardRef((props, ref) => {
  const history = useHistory();

  const [scheduleData, setScheduleData] = useState([]);
  const [useScheduleData, setUseScheduleData] = useState([]);
  const [breakTimeData, setBreakTimeData] = useState([]);
  const [useBreakTimeData, setUseBreakTimeData] = useState([]);
  const [totalData, setTotalData] = useState([[]]);
  const [startDay, setStartDay] = useState();
  const [applyWeek, setApplyWeek] = useState([true, true, true, true, true, true, true]);

  const ColumnRef = useRef();
  const TimeLineRef = useRef();

  useImperativeHandle(ref, () => ({
    setStartDay: (val) => {
      setStartDay(val);
      ColumnRef.current.setStartDay(val);
    },
    setApplyWeek: (val) => {
      setApplyWeek(val);
    }
  }));

  // 스케줄 및 브레이크 타임 정보 받아오기
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('schedule/', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) setScheduleData(responseData.data);
        else if (responseData.status === 401) history.push('/');
        else if (responseData.status !== 204) alert("회원권 정보를 가져오는데 실패하였습니다.\n잠시후 다시시도 해주세요");
      }
    )();
    (
      async () => {
        const responseData = await fetchModule.getData('breaktime/', 'GET');
        console.log(responseData);
        if (responseData.status === 200) setBreakTimeData(responseData.data);
        else if (responseData.status === 401) history.push('/');
        else if (responseData.status !== 204) alert("서버와 연결이 불안정합니다.\n잠시후 다시시도 해주세요");
      }
    )();
  }, [history])

  // 스케줄 정보 시간 정리
  useEffect(() => {
    var tmp = scheduleData.slice();
    var newScheduleData = [];

    for (var i = 0; i < tmp.length; i++) {
      if (tmp[i].term !== -1) {
        var endPoint = moment(tmp[i].classDate.split('T')[0]);
        while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {
          var colSpan = 1;

          // 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
          // colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);
         
          var newData = {
            classId: tmp[i].classId,
            classDate: endPoint.format('YYYY.MM.DD'),
            className: tmp[i].className,
            classTime: tmp[i].classTime,
            color: tmp[i].color,
            endDate: tmp[i].endDate,
            lessonId: tmp[i].lessonId,
            scheduleId: tmp[i].scheduleId,
            teacherName: tmp[i].teacherName,
            term: tmp[i].term,
            colSpan: colSpan
          }
          newScheduleData.push(newData);
          endPoint = endPoint.clone().add(7 * tmp[i].term, 'day');
        }
      }
    }

    setUseScheduleData(newScheduleData);
  }, [scheduleData])

  // 브레이크 타임 시간 정리
  useEffect(() => {
    var tmp = breakTimeData.slice();
    var newBreakData = [];
    var colSpan = 1;

    for (var i = 0; i < tmp.length; i++) {
      for (var j = 0; j < tmp[i].applyWeek.split(',').length; j++) {
        if (tmp[i].applyWeek === 'all') {
         

          for (var k = 0; k < 7; k++) {
            var newData = {
              classId: -1,
              classDate: startDay.clone().add(k, 'day').format('YYYY.MM.DD'),
              classTime: tmp[i].startTime + '-' + tmp[i].endTime,
              className: '브레이크 타임',
              subName: 'Breaktime',
              colSpan: colSpan
            }
            newBreakData.push(newData);
          }
          break;
        }
        else if (tmp[i].applyWeek.split(',')[j] === 'sun') {
          var newData = {
            classId: -1,
            classDate: startDay.clone().format('YYYY.MM.DD'),
            classTime: tmp[i].startTime + '-' + tmp[i].endTime,
            className: '브레이크 타임',
            subName: 'Breaktime',
            colSpan: colSpan
          }

          newBreakData.push(newData);
        }
        else if (tmp[i].applyWeek.split(',')[j] === 'mon') {
          var newData = {
            classId: -1,
            classDate: startDay.clone().add(1, 'day').format('YYYY.MM.DD'),
            classTime: tmp[i].startTime + '-' + tmp[i].endTime,
            className: '브레이크 타임',
            subName: 'Breaktime',
            colSpan: colSpan
          }

          newBreakData.push(newData);
        }
        else if (tmp[i].applyWeek.split(',')[j] === 'tue') {
          var newData = {
            classId: -1,
            classDate: startDay.clone().add(2, 'day').format('YYYY.MM.DD'),
            classTime: tmp[i].startTime + '-' + tmp[i].endTime,
            className: '브레이크 타임',
            subName: 'Breaktime',
            colSpan: colSpan
          }

          newBreakData.push(newData);
        }
        else if (tmp[i].applyWeek.split(',')[j] === 'wed') {
          var newData = {
            classId: -1,
            classDate: startDay.clone().add(3, 'day').format('YYYY.MM.DD'),
            classTime: tmp[i].startTime + '-' + tmp[i].endTime,
            className: '브레이크 타임',
            subName: 'Breaktime',
            colSpan: colSpan
          }

          newBreakData.push(newData);
        }
        else if (tmp[i].applyWeek.split(',')[j] === 'thu') {
          var newData = {
            classId: -1,
            classDate: startDay.clone().add(4, 'day').format('YYYY.MM.DD'),
            classTime: tmp[i].startTime + '-' + tmp[i].endTime,
            className: '브레이크 타임',
            subName: 'Breaktime',
            colSpan: colSpan
          }

          newBreakData.push(newData);
        }
        else if (tmp[i].applyWeek.split(',')[j] === 'fri') {
          var newData = {
            classId: -1,
            classDate: startDay.clone().add(5, 'day').format('YYYY.MM.DD'),
            classTime: tmp[i].startTime + '-' + tmp[i].endTime,
            className: '브레이크 타임',
            subName: 'Breaktime',
            colSpan: colSpan
          }

          newBreakData.push(newData);
        }
        else if (tmp[i].applyWeek.split(',')[j] === 'sat') {
          var newData = {
            classId: -1,
            classDate: startDay.clone().add(6, 'day').format('YYYY.MM.DD'),
            classTime: tmp[i].startTime + '-' + tmp[i].endTime,
            className: '브레이크 타임',
            subName: 'Breaktime',
            colSpan: colSpan
          }

          newBreakData.push(newData);
        }
      }
    }
    
    setUseBreakTimeData(newBreakData);

  }, [breakTimeData, startDay])

  // 각 시간대별 데이터 merge ( 1시간 단위 )
  useEffect(() => {
    var scheduleTmp = useScheduleData.slice();
    var breakTmp = useBreakTimeData.slice();
    var newData = [];
    // Data 같은 시간대끼리 merge (일주일치)
    for (var i = 0; i < 24; i++) {
      
    console.log('----start');
      var newTimeLine = [];

      // Schedule Data 추가
      for (var j = 0; j < scheduleTmp.length; j++) {
        if (parseInt(scheduleTmp[j].classTime.split('-')[0]) === i) {
          for (var k = 0; k < 7; k++) {
            if (scheduleTmp[j].classDate === startDay.clone().add(k, 'day').format('YYYY.MM.DD')) {
              newTimeLine.push(scheduleTmp[j]);
              break;
            }
          }
        }
      }

      // Break Time Data 추가
      for (j = 0; j < breakTmp.length; j++) {
        if (parseInt(breakTmp[j].classTime.split('-')[0]) === i) {
          for (k = 0; k < 7; k++) {
            if (breakTmp[j].classDate === startDay.clone().add(k, 'day').format('YYYY.MM.DD')) {
              newTimeLine.push(breakTmp[j]);
              // break;
            }
          }
        }
      }

      // 스케줄이 존재하지만 7칸 다 안채워졌을 경우 빈칸 수업없음으로 정리
      if (newTimeLine.length !== 7 && newTimeLine.length !== 0) {
        for (j = 0; j < 7; j++) {
          var count = 0;

          for (k = 0; k < newTimeLine.length; k++) {
            if (newTimeLine[k].classDate === startDay.clone().add(j, 'day').format('YYYY.MM.DD')) {
             count += 1;
            }
          }

          if (count === 0) {
            var emptyData = {
              classId: -2,
              classDate: startDay.clone().add(j, 'day').format('YYYY.MM.DD'),
              className: '수업없음',
              classTime: ' ',
              teacherName: ' ',
              colSpan: 1
            }
            newTimeLine.push(emptyData);
          }
        }

        // 날짜 별로 소팅
        newTimeLine.sort(function (a, b) {
          return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
        })

        for (j = 0; j < newTimeLine.length - 1; j++) {
          for (k = j + 1; k < newTimeLine.length; k++) {
            if (newTimeLine[j].classId === newTimeLine[k].classId) {
              newTimeLine[j].colSpan += 1;
              newTimeLine[k].colSpan = 0;
            }
            else break;
          }
          
          if (newTimeLine[j].colSpan > 1) j += newTimeLine[j].colSpan;
        }
        newData.push(newTimeLine);
      }
      
      else if (newTimeLine.length === 0 && newData.length !== 0) {
        for (j = 0; j < 7; j++) {
          emptyData = {
            classId: -2,
            classDate: moment().add(j, 'day').format('YYYY.MM.DD'),
            className: '수업없음',
            classTime: ' ',
            teacherName: ' ',
            colSpan: 1
          }
          newTimeLine.push(emptyData);
        }

        for (j = 0; j < newTimeLine.length - 1; j++) {
          for (k = j + 1; k < newTimeLine.length; k++) {
            if (newTimeLine[j].classId === newTimeLine[k].classId) {
              newTimeLine[j].colSpan += 1;
              newTimeLine[k].colSpan = 0;
            }
            else break;
          }
          
          if (newTimeLine[j].colSpan > 1) j += newTimeLine[j].colSpan;
        }

        newData.push(newTimeLine);
      }
      else if (newTimeLine.length === 7) {

        newTimeLine.sort(function (a, b) {
          return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
        })
        

        for (j = 0; j < newTimeLine.length - 1; j++) {
          for (k = j + 1; k < newTimeLine.length; k++) {
            if (newTimeLine[j].classId === newTimeLine[k].classId) {
              newTimeLine[j].colSpan += 1;
              newTimeLine[k].colSpan = 0;
            }
            else break;
          }
          
          if (newTimeLine[j].colSpan > 1) j += newTimeLine[j].colSpan;
        }

        newData.push(newTimeLine);
      }
      if (newData.length === 10) break;
    }

    setTotalData(newData);
    // TimeLineRef.current.setData(newData);
  }, [useScheduleData, useBreakTimeData, startDay, applyWeek])

  return (
    <TopLevelWrapper>
      <TableColumn ref={ColumnRef} applyWeek={applyWeek} />
      <TableWrapper>
        {/* <TimeDivisionLine ref={TimeLineRef} scheduleData={totalData}/> */}
        <Table>
          <Tbody>
            {
              totalData.map((data, index) => (
                <Tr key={index}>
                  {
                    data.map((innerData, idx) => (
                      applyWeek[idx] && innerData.colSpan > 0 ? 
                      <Td key={idx} width={innerData.colSpan === 1 ? 13.64 : innerData.colSpan * 14.2} color={innerData.className === '수업없음' ? '#ECECEC' : innerData.color}>
                        <Text>
                          <ClassName>{innerData.className}</ClassName>
                          <ClassTime>{innerData.classTime}</ClassTime>
                          <CoachName>{innerData.teacherName}</CoachName>
                        </Text>
                      </Td>
                      :
                      null
                    ))
                  }
                </Tr>
              ))
            }
          </Tbody>
        </Table>
      </TableWrapper>
    </TopLevelWrapper>
  )
})

export default TableView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  width: 100%;
  height: 100%;

  font-size: 100%;

  border-radius: 15px;
  background-color: #FDFCFB;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  width: 95.54%;
  height: 88.49%;

  gap: 10.25px;
  
  margin-right: 14.09px;
  margin-bottom: 14.09px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 94.36%;
  height: 100%;
`;

const Tbody = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const Tr = styled.div` 
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 10.04%;
  
  gap: 0.48%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const Td = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;


  // width: 13.64%;
  width: ${props => props.width}%;
  height: 94.21%;
  border: 0.640504px solid #ECECEC;;
  
  // border: 0.640504px solid #ECECEC;
  border-radius: 9.60756px;
  background-color: ${props => props.color};
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 100%;
  // height: 100%;

  margin: 0 0 0 0;
`;

const ClassName = styled.p`
  font-family: NotoSansKR-Medium;
  // font-size: 10px;
  font-size: 40%;

  color: #232323;

  margin: 0 0 0 0;

  -webkit-text-size-adjust: none;
`;

const ClassTime = styled.p`
  font-family: Poppins-Regular;
  // font-size: 10px;
  font-size: 40%;

  color: #777777;

  margin: 0 0 0 -1px;

  -webkit-text-size-adjust: none;
`;

const CoachName = styled.p`
  font-family: NotoSansKR-Regular;
  // font-size: 10px;
  font-size: 40%;

  color: #777777;

  margin: 0 0 0 2px;

  -webkit-text-size-adjust: none;
`;
