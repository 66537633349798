import { React, useRef } from 'react';
import styled from 'styled-components';

const ClassInfoBox = (props) => {
	const { 
		isOver, 
		data, 
		openDetailModal, 
		reserveData, 
		openReservationModal, 
		openReserveLockModal, 
		onClickScheduleDetail, 
		clickFn 
	} = props;

	const DetailRef = useRef();
	const ReserveRef = useRef();
	const ReserveLockRef = useRef();

	function onMouseOver() {
		if (data.classId === -1) return;

		DetailRef.current.style.color = `#FF8B48`;
		ReserveRef.current.style.color = `#FF8B48`;
		ReserveLockRef.current.style.color = `#FF8B48`;
	}

	function onMouseLeave() {
		if (data.classId === -1) return;

		DetailRef.current.style.color = `#FFFFFF`;
		ReserveRef.current.style.color = `#FFFFFF`;
		ReserveLockRef.current.style.color = `#FFFFFF`;
	}


	return (
		<TopLevelWrapper isOver={isOver} color={data.color} onClick={clickFn} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} >
				<WhiteBox isOver={isOver}>
				<TextWrapper isOver={isOver}>
					<ClassName isOver={isOver}>{data.className}</ClassName>
					{
						data.classId === -1 ?
							<InfoWrapper isOver={isOver}>
								<InfoBox isOver={isOver}>
									<TimeText isOver={isOver}>{data.startTime} ~ {data.endTime}</TimeText>
								</InfoBox>
							</InfoWrapper>
							:
							<InfoWrapper isOver={isOver}>
								<InfoBox isOver={isOver}>
									<TimeText isOver={isOver}>{data.startTime} ~ {data.endTime}</TimeText>
									{isOver && <Division />}
									<Number isOver={isOver}>예약 {reserveData && reserveData.length}명</Number>
									{isOver && <Division />}
									<TrainerName isOver={isOver}>{data.teacherName}</TrainerName>
								</InfoBox>
								<ButtonWrapper isOver={isOver}>
									<Button ref={DetailRef} isOver={isOver} onClick={onClickScheduleDetail}>수업 상세정보</Button>
									<Button ref={ReserveRef} isOver={isOver} onClick={openReservationModal}>예약</Button>
									<Button ref={ReserveLockRef} isOver={isOver} onClick={openReserveLockModal}>예약 잠금</Button>
								</ButtonWrapper>
							</InfoWrapper>
					}
					</TextWrapper>
				</WhiteBox>
		</TopLevelWrapper>
	)
}

export default ClassInfoBox;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: flex-end;
	align-items: flex-start;

	width: ${props => props.isOver ? `max(583px, 75.91145833333333vw)` : `max(238px, 74.375vw)`};
	height: ${props => props.isOver ? `66px` : `76px`};

	border: 1px solid #ECECEC;
	border-radius: 15px;
	background-color: #ECECEC;
	
  transition: all 0.3s ease-in-out;
  
	:hover {
		// background-color: #5BD98A;
		// border: 1px solid #5BD98A;
		
		background-color: ${props => props.color};
		border: 1px solid ${props => props.color};
	}	
`;

const WhiteBox = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: flex-start;

	width: ${props => props.isOver ? `max(563px, 73.30729166666667vw)` : `max(218px, 68.125vw)`};
	height: ${props => props.isOver ? `66px` : `76px`};

	border-radius: 0 15px 15px 0;
	background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: flex-start;

	gap:4px;

	margin-left: ${props => props.isOver ? `15px` : `7px`};
`;

const ClassName = styled.p`
	font-family: NotoSansKR-Medium;
	font-size: ${props => props.isOver ? `14px` : `12px`};

	color: #232323;

	margin: 0 0 0 0;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	
	justify-content: space-between;
	align-items: ${props => props.isOver ? `center` : `flex-end`};

	width: ${props => props.isOver ? `max(534px, 69.53125vw)` : `max(205px, 64.0625vw)`};
`;

const InfoBox = styled.div`
	display: flex;
	flex-direction: ${props => props.isOver ? `row` : `column`};

	justify-content: center;
	align-items: ${props => props.isOver ? `center` : `flex-start`};
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: center;
	align-items: center;

	gap: ${props => props.isOver ? `16px` : `8px`};

	:hover {
		cursor: pointer;
	}
`;

const TimeText = styled.p`
	font-family: Poppins-Regular;
	font-size: ${props => props.isOver ? `12px` : `10px`};

	color: #777777;

	margin: 0 0 0 0;
`;

const Division = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	width: 1px;
	height: 9px;

	margin: 0 8px 0 8px;

	border-right: 1px solid #969696;
`;

const Number = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: ${props => props.isOver ? `12px` : `10px`};

	color: #777777;
	
	margin: 0 0 0 0;
`;


const TrainerName = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: ${props => props.isOver ? `12px` : `10px`};

	color: #777777;
	
	margin: 0 0 0 0;
`;

const Button = styled.p`
	font-family: NotoSansKR-Medium;	
	font-size: ${props => props.isOver ? `12px` : `10px`};

	color: #FFFFFF;

	margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
  
	:hover{
		cursor: pointer;
		color: #FF7322;
	}
`;