import { React, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import DefaultLogo from '../../../image/DefaultLogo.png';
import ReplyBox from './ReplyBox';
import CommentDeleteModal from './CommentDeleteModal';

const CommentBox = (props) => {
  const { isOver, data, rendering, commentRef, infoRef } = props;

  const [deleteClick, setDeleteClick] = useState(false);

  const TopLevelRef = useRef();
  const RightRef = useRef();
  const LineRef = useRef();

  useEffect(() => {
    console.log(data);
    // console.log( RightRef.current.clientHeight);
    // console.log( RightRef.current.clientHeight- 24);
    LineRef.current.style.height = `${ RightRef.current.clientHeight - 8}px`;
    // console.log(LineRef.current.clientHeight);
  }, [data])

  function scrollToMove() {
    commentRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    infoRef.current.setName(data.name);
    infoRef.current.setCommentId(data.id);
    // commentRef.current.setName(data.name);
    // commentRef.current.setCommentId(data.id);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
      {deleteClick && <CommentDeleteModal commentId={data.id} rendering={rendering} exitClickFn={() => setDeleteClick(false)}/>}
      <TotalWrapper>
      <LeftWrapper>
        <Profile src={data.profile === '' ? DefaultLogo : data.profile}/>
        <LineWrapper>
          <EmptyComponent/>
          <Line ref={LineRef} isView={data.replyList.length === 0}/>
        </LineWrapper>
      </LeftWrapper>
      <MemberInfoWrapper ref={RightRef}>
        <NameWrapper>
          <Name isOver={isOver}>{data.name}</Name>
            <Info isOver={isOver}>{data.memberId === -1 ? '코치' : '회원'}</Info>
            {data.memberId === -1 && <State isOver={isOver}>작성자</State>}
        </NameWrapper>
        <TextWrapper>
          <Text isOver={isOver}>{data.text}</Text>
          {data.image && <Image src={data.image}/>}
        </TextWrapper>
        <BottomWrapper>
          <Date isOver={isOver}>{moment(data.createdDate).format('YYYY.MM.DD HH:mm')}</Date>
          <ReplyButton isOver={isOver} onClick={scrollToMove}>답글 달기</ReplyButton>
          {data.memberId === -1 && <ReplyDeleteButton isOver={isOver} onClick={() => setDeleteClick(true)}>댓글 삭제</ReplyDeleteButton>}
          {/* <ReplyDeleteButton isView={data.memberId === -1}>답글 삭제</ReplyDeleteButton> */}
        </BottomWrapper>
      </MemberInfoWrapper>
      </TotalWrapper>
      {
        data.replyList.map((replyData, index) => (
          <ReplyBox key={index} isOver={isOver} data={replyData} commentId={data.id} isLast={data.replyList.length === index + 1} rendering={rendering} commentRef={commentRef} infoRef={infoRef}/>
        ))
      }
    </TopLevelWrapper>
  )
}

export default CommentBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(262px, 81.875vw)`};
  padding: 16px 0 0 0;

  border-bottom: 1px solid #DFDFDF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
  
  gap: 10px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 100%;
  height: 100%;

  // background-color: red;
`;

const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  // background-color: red;
`;

const EmptyComponent = styled.div`
  width: 50%;
  height: 100%;
`;

const Line = styled.div`
  width: 50%;
  height: 100%;

  border-left: ${props => props.isView ? `2px solid #FFFFFF` : `2px solid #DFDFDF`};
`;

const MemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const Profile = styled.img`
  width: 24px;
  height: 24px;

  border-radius: 4px;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Name = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const Info = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #CBCBCB;

  margin: 0 0 0 0;
`;

const State = styled.p`
  display: flex;
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #FF8B48;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 12px;
`;

const Text = styled.pre`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const Image = styled.img`
  width: 260px;
  height: 260px;
  
  object-fit: cover;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
  margin-top: 4px;
`;

const Date = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver? `12px` : `10px`};

  color: #CBCBCB;

  margin: 0 0 0 0;
`;

const ReplyButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const ReplyDeleteButton = styled.p`
  // display: ${props => props.isView ? `flex` : `none`};
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #FF3131;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;