import { React, useRef, useImperativeHandle, forwardRef, useState } from 'react';
import styled from 'styled-components';

const SignUpSubject = forwardRef((props, ref) => {
	const { onClickSignUpCategory, isOver } = props;

	const TopLevelRef = useRef();
	const FirstPageRef = useRef();
	const SecondPageRef = useRef();
	const ThirdPageRef = useRef();
	const FourthPageRef = useRef();

	const [nowPage, setNowPage] = useState(0);

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
			if (val === 0) TopLevelRef.current.style.zIndex = -1;
			else TopLevelRef.current.style.zIndex = 1;
		},
		setNowPage: (val) => { // refectoring...
			if (val === 1) {
				FirstPageRef.current.style.backgroundColor = '#FF8B48';
				SecondPageRef.current.style.backgroundColor = '#FDFCFB';
				ThirdPageRef.current.style.backgroundColor = '#FDFCFB';
				FourthPageRef.current.style.backgroundColor = '#FDFCFB';
			}
			else if (val === 2) {
				FirstPageRef.current.style.backgroundColor = '#FF8B48';
				SecondPageRef.current.style.backgroundColor = '#FF8B48';
				ThirdPageRef.current.style.backgroundColor = '#FDFCFB';
				FourthPageRef.current.style.backgroundColor = '#FDFCFB';
			}
			else if (val === 3) {
				FirstPageRef.current.style.backgroundColor = '#FF8B48';
				SecondPageRef.current.style.backgroundColor = '#FF8B48';
				ThirdPageRef.current.style.backgroundColor = '#FF8B48';
				FourthPageRef.current.style.backgroundColor = '#FDFCFB';
			}
			else if (val === 4) {
				FirstPageRef.current.style.backgroundColor = '#FF8B48';
				SecondPageRef.current.style.backgroundColor = '#FF8B48';
				ThirdPageRef.current.style.backgroundColor = '#FF8B48';
				FourthPageRef.current.style.backgroundColor = '#FF8B48';
			}
			setNowPage(val);
		},
		getNowPage: () => {
			return nowPage;
		}
	}));

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<CategoryWrapper isOver={isOver}>
				<Category>회원 가입</Category>
				<PaginationWrapper isOver={isOver}>
					<Pagination ref={FirstPageRef} isOver={isOver}/>
					<Pagination ref={SecondPageRef} isOver={isOver}/>
					<Pagination ref={ThirdPageRef} isOver={isOver}/>
					<Pagination ref={FourthPageRef} isOver={isOver}/>
				</PaginationWrapper>
			</CategoryWrapper>
			<CategoryExplanationWrapper isOver={isOver}>
				<CategoryExplanation>이미 계정이 있으신가요?</CategoryExplanation>
				<CategoryExplanation className='SignUp' onClick={onClickSignUpCategory}>로그인</CategoryExplanation>
			</CategoryExplanationWrapper>
		</TopLevelWrapper>
	)
})

export default SignUpSubject;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	
	opacity: 0;
	z-index: -1;

  transition: all 0.3s ease-in-out;
`;

const CategoryWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	align-items: center;

	width: ${props => props.isOver ? `max(369px, 48.046875vw)`: `max(250px, 78.125vw)`};

  transition: all 0.3s ease-in-out;
`;

const Category = styled.p`
	font-family: NotoSansKR-Bold;
	font-size: 18px;
 
	margin: 0 0 0 0;

	cursor: default;
`;

const PaginationWrapper = styled.div`
	display: flex;
	flex-direction: row;

	gap: ${props => props.isOver ? `4px` : `3px`};
`;

const Pagination = styled.div`
	width: ${props => props.isOver ? `25px` : `19px`};
	height: ${props => props.isOver ? `8px` : `6px`};

	border-radius: 2px;
	background-color: #FDFCFB;

  transition: all 0.3s ease-in-out;
`;

const CategoryExplanationWrapper = styled.div`
	display: flex;
	flex-direction: row;

	margin-top: ${props => props.isOver ? `14.05px` : `2px`};
`;

const CategoryExplanation = styled.p`
	font-family: ${props => props.className === 'SignUp' ? `NotoSansKR-Medium` : `NotoSansKR-Regular`}; 
	font-size: 12px;
	
	color: ${props => props.className === 'SignUp' ? `#FF8B48` : `#777777`};

	margin: 0 8px 0 0;

	:hover {
		cursor: ${props => props.className === 'SignUp' ? `pointer` : `default`};
	}
`;