import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import NoticeViewBox from './NoticeViewBox';

import FetchModule from '../../Share/Network/FetchModule';
import NoticeDeleteModal from './NoticeDeleteModal';
import useWindowSize from '../../Share/Normal/useWindowSize';

function NoticeView(props) {
  const { noticeId } = props;

  const history = useHistory();
  const size = useWindowSize();

  const [data, setData] = useState({commentList: []});
  const [deleteClick, setDeleteClick] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rendering, setRendering] = useState(false);
  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    
    var requestData = {
      noticeId: noticeId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('notice/detail/zone', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) setData(responseData.data[0]);
        else if (responseData.status === 401) history.push('/');

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    )();
  }, [history, noticeId, rendering])

  useEffect(() => {
    if (size >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  function modifyNoticeClick() {
    history.push('/notice/modify/' + noticeId);
  }

  return (
    <TopLevelWrapper>
      {deleteClick && <NoticeDeleteModal noticeId={noticeId} exitClickFn={() => setDeleteClick(false)}/>}
      <TopBarWrapper isOver={isOver}>
        <TextWrapper>
          <Title isOver={isOver}>공지</Title>
          <Explanation isOver={isOver}>일정, 회원관리 등을 공지하고 확인할 수 있어요</Explanation>
        </TextWrapper>
        <ButtonWrapper>
          <ExitButton isOver={isOver} onClick={() => setDeleteClick(true)}>삭제</ExitButton>
          <AddButton isOver={isOver} onClick={modifyNoticeClick}>수정하기</AddButton>
        </ButtonWrapper>
      </TopBarWrapper>
      {/* <NoticeViewBox data={data}/> */}
      <NoticeViewBox 
        isOver={isOver} 
        data={data} 
        title={data.title}
        defaultVal={data.details} 
        noticeId={noticeId} 
        rendering={() => setRendering(!rendering)}
        isLoading={isLoading}/>
    </TopLevelWrapper>
  )
}

export default NoticeView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // margin-left: 34px;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: ${props => props.isOver ? `space-between` : `center`};
  align-items: ${props => props.isOver ? `center` : `space-between`};

  width: ${props => props.isOver ? `max(664px, 86.45833333333333vw)` : `max(282px, 88.125vw)`};

  gap: ${props => props.isOver ? `0px` : `8px`};
  margin-left: ${props => props.isOver ? `28px` : `14px`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `72px` : `45px`};
  height: ${props => props.isOver ? `38px` : `27px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `5px`};
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #CBCBCB;

  transition: all 0.2s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const AddButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `63px`};
  height: ${props => props.isOver ? `40px` : `29px`};

  border-radius: ${props => props.isOver ? `15px` : `5px`};
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;
