import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
// import lodash from '.lodash';

import FetchModule from '../../Share/Network/FetchModule';
import useWindowSize from '../../Share/Normal/useWindowSize';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import ScheduleBox from './OneOnOneScheduleBox';
import CalenderModal from './CalenderModal';
import ExerciseBox from './OneOnOneExerciseBoxLegacy';
import MemberListViewModal from './MemberListViewModal';

import CalenderBasic from '../../../image/calendarB.png'//캘린더 기본
import CalenderClick from '../../../image/calendarC.png'
import CalenderHover from '../../../image/calendarH.png'
import BodyInfoBox from './BodyInfoBox';

import DefaultProfile from '../../../image/DefaultProfile.png';
import OneOnOneExerciseBox from './OneOnOneExerciseBox';

function OneOnOneView() {
  const history = useHistory();
  const size = useWindowSize();

  const [isOver, setIsOver] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment());
  const [rendering, setRendering] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [memberBodyInfo, setMemberBodyInfo] = useState([]);
  const [clickMemberList, setClickMemberList] = useState(false);
  const [calenderClick, setCalenderClick] = useState(false);
  const [isClickedModify, setIsClickedModify] = useState(false);
  const [isViewConfirm, setIsViewConfirm] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [memberIndex, setMemberIndex] = useState(-1);
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [dateList, setDateList] = useState([]);
  const [useData, setUseData] = useState([{
    workoutId: 0,
    scheduleId: 0,
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    classDate: moment(),
    title: '작성이 필요합니다.',
    sectionList: []
  }]);
  const [useBodyInfo, setUseBodyInfo] = useState([{
    id: 0,
    // storeId: parseInt(window.sessionStorage.getItem('StoreId')),
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    memberId: 0,
    measurementDate: moment(),
    weight: '',
    skeletalMuscle: '',
    bodyFat: '',
  }]);

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  const TopLevelRef = useRef();
  const CalenderButtonRef = useRef();
  const MemberListRef = useRef();
  const ExerciseRef = useRef();
  const ScheduleRef = useRef();
  const CalenderRef = useRef();

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  // 1:1 회원권 소지자 조회 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.getDataOfExercise('one-on-one/member_list');
        // console.log(responseData);
        if (responseData.status === 200) {
          setMemberList(responseData.data);
          if (responseData.data.length !== 0) setMemberIndex(0);
        }
        else if (responseData.status === 401) history.push('/');
        // else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }, [history])

  // 날짜 데이터 세팅 //
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      } 
    }
    
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek]) 


  // workout Data에 따라 버튼 텍스트 변경 //
  useEffect(() => {
    for (var i = 0; i < useData.length; i++) {
      if (useData[i].classDate.format('YYYY.MM.DD') === clickDate) {
        if (isClickedModify) setButtonText('저장하기');
        else {
          if (useData[i].workoutId === 0) setButtonText('추가하기');
          else setButtonText('수정하기'); 
        }
        break;
      }
    }
  }, [isClickedModify, clickDate, useData])

  // 1:1 운동 데이터 조회 //
  useEffect(() => {

    if ( memberIndex === -1 ) return;

    var requestData = {
      month: date.clone().format('MM'),
      userId: memberList[memberIndex].linkCoachId,
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.postDataOfExercise('one-on-one/exercise_list', 'POST', requestData);
        
        if (responseData.status === 200) { 
          var workoutData = responseData.data.slice();

          // 운동 데이터 정리 //
          for (var i = 0; i < workoutData.length; i++) {

            workoutData[i].sectionList.sort(function (a, b) {
              return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
            })

            for (var j = 0; j < workoutData[i].sectionList.length; j++) {
              workoutData[i].sectionList[j].buyinExerciseList = [];
              workoutData[i].sectionList[j].mainExerciseList = [];
              workoutData[i].sectionList[j].buyoutExerciseList = [];
              workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');

              if (workoutData[i].sectionList[j].sectionRecord === undefined) {
                for (var h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
                  workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
                  workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
                  workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;

                  if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
                    workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
                    workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
                    workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                  }
                }
              }
              else {
                for (var h = 0; h < workoutData[i].sectionList[j].sectionRecord.exerciseList.length; h++) {
                  workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].sectionRecord.exerciseList[h].movement.movementId;
                  workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].sectionRecord.exerciseList[h].movement.movementName;
                  workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].sectionRecord.exerciseList[h].movement.videoLinkId;

                  if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
                    workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].sectionRecord.exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
                    workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].sectionRecord.exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
                    workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].sectionRecord.exerciseList[h]);
                  }
                }
              }

              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyinExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyoutExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
            }
          }

          setData(workoutData);
        }
      }
    )();

    (
      async () => {
        var responseData = await fetchModule.getIndividualData('body/' 
        + date.clone().format('MM') 
        + '/' 
        + memberList[memberIndex].id, 'GET');
        
        if (responseData.status === 200) {
          // console.log(responseData.data);
          setMemberBodyInfo(responseData.data);
        }
        else if (responseData.status === 204) setMemberBodyInfo([]);
        else if (responseData.status === 401) history.push('/');
        // else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }, [history, date, memberIndex, rendering])

  // 운동데이터 세팅 //
  useEffect(() => {
    if (dateList.length === 0) return;

    var newData = data.slice();

    if (newData.length === 0) { // 데이터가 없으면 1개 넣고 시작
      var tmpData = {
        workoutId: 0,
        scheduleId: 0,
        isSuccess: false,
        storeId: parseInt(window.localStorage.getItem('StoreId')),
        classDate: dateList[0],
        title: '',
        sectionList: []
      }
    }

    for (var i = 0; i < dateList.length; i++) {
      var isNone = true;

      for (var j = 0; j < newData.length; j++) {
        if (dateList[i].format('MM.DD') === moment(newData[j].classDate).format('MM.DD')) {
          isNone = false;
          newData[j].classDate = moment(newData[j].classDate);
          break;
        }
      }
      
      if (isNone) {
        tmpData = {
          workoutId: 0,
          scheduleId: 0,
          isSuccess: false,
          storeId: parseInt(window.localStorage.getItem('StoreId')),
          classDate: dateList[i],
          title: '',
          sectionList: []
        }
        newData.push(tmpData);
      }
    }
    
		newData.sort(function (a, b) {
			return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
		})
    
    setUseData(newData);
  }, [dateList, data])

  // 체중 데이터 세팅 //
  useEffect(() => {

    if (memberList.length === 0) return;

    var newData = memberBodyInfo.slice();

    if (newData.length === 0) { // 데이터가 없으면 1개 넣고 시작
      var tmpData = {
        id: 0,
        // storeId: parseInt(window.sessionStorage.getItem('StoreId')),
        storeId: parseInt(window.localStorage.getItem('StoreId')),
        memberId: memberList[memberIndex].memberId,
        measurementDate: dateList[0],
        weight: '',
        skeletalMuscle: '',
        bodyFat: '',
      }
    }

    for (var i = 0; i < dateList.length; i++) {
      var isNone = true;

      for (var j = 0; j < newData.length; j++) {
        if (dateList[i].format('MM.DD') === moment(newData[j].measurementDate).format('MM.DD')) {
          isNone = false;
          newData[j].measurementDate = moment(newData[j].measurementDate);
          break;
        }
      }
      
      if (isNone) {
        tmpData = {
          id: 0,
          // storeId: parseInt(window.sessionStorage.getItem('StoreId')),
          storeId: parseInt(window.localStorage.getItem('StoreId')),
          memberId: memberList[memberIndex].memberId,
          measurementDate: dateList[i],
          weight: '',
          skeletalMuscle: '',
          bodyFat: '',
        }
        newData.push(tmpData);
      }
    }
    
		newData.sort(function (a, b) {
			return a.measurementDate < b.measurementDate ? -1 : a.measurementDate > b.measurementDate ? 1 : 0;
		})

    setUseBodyInfo(newData);
  }, [memberBodyInfo, dateList])

  // 일정표내의 날짜 클릭시 //
  function dateClick(value) {
    // 운동 내용 변경중인지 확인 //
    if (isClickedModify) {
      var exerciseData = ExerciseRef.current.getValue();

      if (exerciseData.length === 0) {
        setIsClickedModify(false);

        setClickDate(value);
  
        ScheduleRef.current.setValue(value);
        if (calenderClick) CalenderRef.current.setValue(value);
      }
      else setIsViewConfirm(true);
    }
    else {
      setClickDate(value);

      ScheduleRef.current.setValue(value);
      if (calenderClick) CalenderRef.current.setValue(value);
    }
  }

  // 월 단위 날짜 변경 클릭시 //
  function onClickDateArrow(division) {
    if (isClickedModify) setIsViewConfirm(true);
    else {
      if (division === 'Prev') setDate(date.clone().subtract(1, 'month'));
      else if (division === 'Next') setDate(date.clone().add(1, 'month'));
    }
  }
  // 회원 변경 클릭시 //
  function changeMemberInfo(memberId) {
    var index = -1;

    for (var i = 0; i < memberList.length; i++) {
      if (memberList[i].memberId === memberId) index = i;
    }

    setMemberIndex(index);
    setClickMemberList(false)
  }

  // 회원 목록 클릭시 //
  function onClickOneOnOneUseMemberList() {
    if (clickMemberList) {
      setClickMemberList(false);
    }
    else {
      if (isClickedModify) {
        var exerciseData = ExerciseRef.current.getValue();

        if (exerciseData.length === 0) {
          setIsClickedModify(false);
          setClickMemberList(true);
        }
        else setIsViewConfirm(true);
      }
      else setClickMemberList(true);
    }
  }

  // 스크롤 애니메이션를 위한 UI제어 //
  function changeTopLevelWrapperPosition() {
    if (TopLevelRef.current.style.position === 'absolute') TopLevelRef.current.style.position = 'relative';
    else {
      TopLevelRef.current.style.position = 'absolute';
      TopLevelRef.current.style.top = 0;
    }
  }

  // 수정하기 클릭시 //
  function onClickModifyButton() {
    if (isClickedModify) {
      setIsViewConfirm(true);
      // setIsClickedModify(false);
    }
    else {
      setIsClickedModify(true);
    }
  }
  
  // 회원 정보 호버링 //
  function onMouseOver() {
    CalenderButtonRef.current.style.border = `1px solid #777777`;
    CalenderButtonRef.current.style.background = `url(${CalenderHover}) no-repeat center center`;
  }

  // 회원 정보 호버링 //
  function onMouseLeave() {
    CalenderButtonRef.current.style.border = `1px solid #DFDFDF`;
    CalenderButtonRef.current.style.background = `url(${CalenderBasic}) no-repeat center center`;
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {clickMemberList && <MemberListViewModal ref={MemberListRef} memberData={memberList} exitClick={() => setClickMemberList(false)} saveClick={changeMemberInfo}/>}
      {calenderClick && <CalenderModal ref={CalenderRef} isOver={isOver} clickDate={clickDate} date={date} dateClickFn={dateClick} exitFn={setCalenderClick}/>}
      <TopBarWrapper isOver={isOver}>
        <TextWrapper>
          <Title isOver={isOver}>1:1</Title>
          <Explanation isOver={isOver}>
            {
              isOver ? 
                `링크코치와 연동하여 회원 별 맞춤형 피트니스 프로그램을 계획할 수 있어요`
                :
                `링크코치와 연동하여 맞춤형 피트니스 프로그램을 계획할 수 있어요`
            }
          </Explanation>
        </TextWrapper>
      </TopBarWrapper>
      <TopButtonWrapper isOver={isOver}>
        <LeftWrapper>
          <ImageWrapper isOver={isOver} onClick={onClickOneOnOneUseMemberList}>
            <ImageView src={memberIndex !== -1 ? memberList[memberIndex].profile === '' ? DefaultProfile : memberList[memberIndex].profile : null } isOver={isOver}/>
            <InfoWrapper isOver={isOver}>
              <MemberName isOver={isOver}>{memberIndex !== -1 ? memberList[memberIndex].name : ''}</MemberName>
              <MemberExplanation isOver={isOver}>회원</MemberExplanation>
            </InfoWrapper>
          </ImageWrapper>
          <DateButtonWrapper isOver={isOver}>
            <ArrowWrapper isOver={isOver} onClick={() => onClickDateArrow('Prev')} >
              <ArrowImage src={LeftArrow}/>
            </ArrowWrapper>
            <DateText isOver={isOver}>{date.clone().format('YYYY년 MM월')}</DateText>
            <ArrowWrapper isOver={isOver} onClick={() => onClickDateArrow('Next')} >
              <ArrowImage src={RightArrow}/>
            </ArrowWrapper>
          </DateButtonWrapper>
          <CalenderButton ref={CalenderButtonRef} isOver={isOver} img={CalenderBasic} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={() => setCalenderClick(!calenderClick)}/>
        </LeftWrapper>
        <RightWrapper>
          <Button isOver={isOver} onClick={onClickOneOnOneUseMemberList}>회원 목록</Button>
          { isOver && <ModifyButton isClickedModify={isClickedModify} onClick={onClickModifyButton}>{buttonText}</ModifyButton> }
        </RightWrapper>
      </TopButtonWrapper>
      {
        isOver ?
          <MainView isOver={isOver}>
            <LayerWrapper isOver={isOver}>
              <ScheduleBox ref={ScheduleRef} isOver={isOver} clickDate={clickDate} useData={useData} dateClickFn={dateClick} />
              <BodyInfoBox isOver={isOver} bodyData={useBodyInfo} />
            </LayerWrapper>
            <OneOnOneExerciseBox
              ref={ExerciseRef} 
              isOver={isOver}
              isClickedModify={isClickedModify} 
              clickDate={clickDate} 
              useData={useData} 
              onClickModifyButton={onClickModifyButton}
              changeTopLevelWrapperPosition={changeTopLevelWrapperPosition}/>
          </MainView>
          :
          <MainView isOver={isOver}>
            <LayerWrapper isOver={isOver}>
              <ScheduleBox ref={ScheduleRef} isOver={isOver} clickDate={clickDate} useData={useData} dateClickFn={dateClick} />
              <OneOnOneExerciseBox
                ref={ExerciseRef}
                isOver={isOver} 
                isClickedModify={isClickedModify} 
                clickDate={clickDate} 
                useData={useData} 
                onClickModifyButton={onClickModifyButton}
                changeTopLevelWrapperPosition={changeTopLevelWrapperPosition}/>
            </LayerWrapper>
            <BodyInfoBox isOver={isOver} bodyData={useBodyInfo} />
          </MainView>
      }
    </TopLevelWrapper>
  )
}

export default OneOnOneView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: ${props => props.isOver ? `28px` : `14px`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(296px, 92.5vw)`};
  // width: ${props => props.isOver ? `max(664px, 86.45833333333333vw)` : `max(296px, 92.5vw)`};
  width: ${props => props.isOver ? `max(664px, 86.45833333333333vw)` : `max(282px, 88.125vw)`};

  margin-top: ${props => props.isOver ? `32px` : `13px`};
  margin-left: ${props => props.isOver ? `28px` : `14px`};
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `205px` : `101px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: #FFFFFF;

  gap: ${props => props.isOver ? `37px` : `6px`};
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `22px` : `17px`};
  height: ${props => props.isOver ? `22px` : `17px`};

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;
`;

const CalenderButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `38px` : `28px`};
  height: ${props => props.isOver ? `38px` : `28px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: #FFFFFF;
  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `98px` : `52px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const MainView = styled.div`
  display: flex;
  // flex-direction: row;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `30px` : `17px`};
  margin-top: 16px;
  margin-bottom: 96px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `max(30px, 3.90625vw)` : `17px`};
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-itmes: center;

  gap: ${props => props.isOver ? `12px` : `5px`};
`;

const ImageView = styled.img`
  width: ${props => props.isOver ? `40px` : `30px`};
  height: ${props => props.isOver ? `40px` : `30px`};
  
  border-radius: ${props => props.isOver ? `15px` : `9px`};

  object-fit: cover;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `3px` : `0px`};
  margin-right: ${props => props.isOver ? `20px` : `13px`};
`;

const MemberName = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const MemberExplanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: ${props => props.isClickedModify ? `#5BD98A` : `#FF8B48`};

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${props => props.isClickedModify ? `#2BCB67` : `#FF7322`};
  }
`;