import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import CheckImagePath from '../../image/Check.png';

const AgreementCheckBox = forwardRef((props, ref) => {
  const { checkFn } = props;
  const [isChecked, setIsChecked] = useState(false);

  const CheckRef = useRef();

  useImperativeHandle(ref, () => ({
		getValue: () => {
			return isChecked;
		}
	}));

  useEffect(() => {
    if (isChecked) CheckRef.current.style.backgroundColor = `#FF8B48`;
    else CheckRef.current.style.backgroundColor = `#FFFFFF`;
  }, [isChecked])

  function onClick() {
    setIsChecked(!isChecked);
    checkFn();
  }

  return (
    <TopLevelWrapper>
      <CheckBox ref={CheckRef} onClick={onClick} img={CheckImagePath}/>
      <ExplanationWrapper>
        <LayerWrapper>
          <NormalExplanation>등록 회원으로부터</NormalExplanation>
          <AccentExplanation style={{marginLeft: `4px`}}>서비스 이용약관</AccentExplanation>
          <NormalExplanation>과</NormalExplanation>
          <AccentExplanation>개인정보 처리방침</AccentExplanation>
          <NormalExplanation>에 대한 동의를 받았습니다.</NormalExplanation>
        </LayerWrapper>
      </ExplanationWrapper>
    </TopLevelWrapper>
  )
})

export default AgreementCheckBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
  // margin-left: 444px;
  // margin-top: 22px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 14px;
  height: 14px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;
  
  background: url(${props => props.img}) no-repeat center center;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const NormalExplanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const AccentExplanation =styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;