import { React } from 'react';
import styled from 'styled-components';

import DefaultLogoPath from '../../../image/DefaultLogo.png'

function MyGymIntroduction(props) {
  const { isOver, storeLogo, storeName, tel, type, address, openingHour, isUsePaymentSystem } = props;

  return (
    <TopLevelWrapper>
      <InfoView isOver={isOver}>
        <TitleWrapper isOver={isOver}>
          <Title>센터 정보</Title>
          <UsePaymentWrapper>
            <UsePaymentBracketText>(</UsePaymentBracketText>
            <UsePaymentText isUsePaymentSystem={isUsePaymentSystem === 'Yes'}>{isUsePaymentSystem === 'Yes' ? '결제 사용' : '결제 미사용'}</UsePaymentText>
            <UsePaymentBracketText>)</UsePaymentBracketText>
          </UsePaymentWrapper>
        </TitleWrapper>
        <InfoWrapper isOver={isOver}>
          <LayerWrapper isOver={isOver}>
            <Logo isOver={isOver} src={storeLogo ? storeLogo : DefaultLogoPath}/>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#232323'>{storeName}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{address}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{tel}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{openingHour}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{type}</Text>
            </TextWrapper>
          </LayerWrapper>
        </InfoWrapper>
      </InfoView>
    </TopLevelWrapper>
  )
}

export default MyGymIntroduction;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 34px;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 1191px;
  width: ${props => props.isOver ? `max(692px, 36.04166666666667vw)` : `max(280px, 14.58333333333333vw)`};
  height: ${props => props.isOver ? `264px` : `500px`};

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: 1135px;
  width: ${props => props.isOver ? `max(636px, 33.125vw)` : `max(256px, 13.33333333333333vw)`};

  gap: 8px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const UsePaymentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const UsePaymentBracketText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const UsePaymentText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.isUsePaymentSystem ? `#87C8A6` : `#FF3131`};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
  border-top: 1px solid #ECECEC;

  // width: 1135px;
  width: ${props => props.isOver ? `max(636px, 33.125vw)` : `max(256px, 13.33333333333333vw)`};
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  margin-top: 24px;
  gap: 20px;
`;

const Logo = styled.img`
  width: ${props => props.isOver ? `140px` : `256px`};
  height: ${props => props.isOver ? `140px` : `256px`};

  border-radius: 15px;

  object-fit: cover;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 8px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;