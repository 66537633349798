import { React, useState, useEffect, forwardRef } from 'react';
import styled from 'styled-components';

const ReflectionWeek = forwardRef((props, ref) => {
  const { changeFn } = props;

  const [sunday, setSunday] = useState(true);
  const [monday, setMonday] = useState(true);
  const [tuesday, setTuesday] = useState(true);
  const [wednesday, setWednesday] = useState(true);
  const [thursday, setThursday] = useState(true);
  const [friday, setFriday] = useState(true);
  const [saturday, setSaturday] = useState(true);

  useEffect(() => {
    changeFn([sunday, monday, tuesday, wednesday, thursday, friday, saturday]);
  }, [sunday, monday, tuesday, wednesday, thursday, friday, saturday])

  return (
    <TopLevelWrapper>
      <Button isClicked={sunday} onClick={() => setSunday(!sunday)}>일</Button>
      <Button isClicked={monday} onClick={() => setMonday(!monday)}>월</Button>
      <Button isClicked={tuesday} onClick={() => setTuesday(!tuesday)}>화</Button>
      <Button isClicked={wednesday} onClick={() => setWednesday(!wednesday)}>수</Button>
      <Button isClicked={thursday} onClick={() => setThursday(!thursday)}>목</Button>
      <Button isClicked={friday} onClick={() => setFriday(!friday)}>금</Button>
      <Button isClicked={saturday} onClick={() => setSaturday(!saturday)}>토</Button>
    </TopLevelWrapper>
  )
})

export default ReflectionWeek;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  gap: 7px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  font-family: ${props => props.isClicked ? `NotoSansKR-Regular` : `NotoSansKR-Regular`};
  font-size: 14px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Regular;
    color: #FFFFFF;
    border: 1px solid #FF8B48;
    background-color: #FF8B48;
  }
`;