import { React } from 'react';
import styled from 'styled-components';

import NextButtonImage from '../../../../image/NormalNextInfoButton.png';
import ColorPrevButtonImage from '../../../../image/ColorPrevInfoButton.png';

const ThirdMemberShipHistoryColumn = (props) => {
  const { prevFn } = props;

  return (
    <TopLevelWrapper>
      <ColumnWrapper>
        <Column style={{ marginRight: 'max(31px, 9.6875vw)', marginLeft: 'max(4px, 1.25vw)' }}>미수금</Column>
        <Column style={{ marginRight: 'max(25px, 7.8125vw)' }}>결제일</Column>
        <Column>남은 일수</Column>
      </ColumnWrapper>
      <ButtonWrapper>
        <Button src={ColorPrevButtonImage} onClick={prevFn} />
        <Button src={NextButtonImage} />
      </ButtonWrapper>
    </TopLevelWrapper>
  )
}

export default ThirdMemberShipHistoryColumn;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 23px;
  margin-bottom: 15px;

  width: max(268px, 83.75vw);
  
  transition: all 0.3s ease-in-out;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 10px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Button = styled.img`
  width: 15px;
  height: 15px;

  border-radius: 15px;

  :hover {
    cursor: pointer;
  }
`;