import { React, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const UnSupportedDeviceModal = (props) => {
  const { isModal, closeFn } = props;

  const history = useHistory();

  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  function onClickExit() {

    if (!isModal) history.goBack();
    else closeFn();
  }

  return (
    <TopLevelWrapper isModal={isModal}>
      <ModalView>
        <Title>지원하지 않는 디바이스입니다.</Title>
        <TextWrapper>
          <Explanation>디바이스의 디스플레이가 너무 작습니다.</Explanation>
          <Explanation>더 큰 사이즈의 디스플레이로 이용해주세요.</Explanation>
        </TextWrapper>
        <ButtonWrapper>
          <ExitButton onClick={onClickExit}>돌아가기</ExitButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper> 
  )
}

export default UnSupportedDeviceModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: ${props => props.isModal ? `rgba(0, 0, 0, 0.2)` : `rgba(0, 0, 0, 0.85)`};
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 302px;
  height: 248px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 26px 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 24px;
  
  gap: 14px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
  
  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 13px;

  margin: 56px 0 0 194px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 82px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 82px
  height: 38px;

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;