import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

const PriceSettingInput = forwardRef((props, ref) => {
	const { isOver, placeholder, type } = props;
	const [value, setValue] = useState(0);
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
		},
		getValue: () => {
      return value;
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			setChangedPlaceholderColor(true);
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#E8E8E8';
		}
	}));

	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';
		// setDefaultPlaceholder('');
	}

	function handleChange(e) {
    const curValue = e.target.value;
		const newValue = curValue.replace(/[^0-9]/g, '');
		const finalValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

		setValue(finalValue);
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
		// if (changed) {
		// 	// do nothing
		// } else {
		// 	setValue("");
		// }
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#E8E8E8';
	}

	return (
    <TopLevelWrapper>
      <Input
        ref={inputRef}
				isOver={isOver}
        clickCount={clickCount}
        type={type}
        placeholder={defaultPlaceholder}
        value={value}
        changedColor={changedPlaceholderColor}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
        <Unit>원</Unit>
    </TopLevelWrapper>
  ) 
});

export default PriceSettingInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
  margin-top: 8px;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
	padding: 0 0 0 8px;
	// margin-top: 16px;

  width: ${props => props.isOver ? `276px` : `211px`};
  height: 30px;

	outline: none;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

	color: #000000;

	::placeholder {
		// color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.5s ease-in-out;
`;

const Unit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;