import { React, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import LockerInfo from '../../../Component/MainComponent/LockerInfo.js';
import LockerAddModal from '../LockerModel/LockerAddModal.js';
import LockerStartModal from '../LockerModel/LockerStartModal.js';
import LockerChangeNameModal from '../LockerModel/LockerChangeNameModal.js';
import LockerUsingStopModal from '../LockerModel/LockerUsingStopModal.js';
import LockerHistoryModal from '../LockerModel/LockerHistoryModal.js';

import FetchModule from '../../Share/Network/FetchModule.js';

function LockerView(props) {
  const { isOver } = props;
  const history = useHistory();

  const [isClickedAdd, setIsClickedAdd] = useState(false);
  const [isClickedChangeName, setIsClickedChangeName] = useState(false);
  const [isClickedStart, setIsClickedStart] = useState(false);
  const [isClickedStop, setIsClickedStop] = useState(false);
  const [isClickedHistory, setIsClickedHistory] = useState(false);
  const [lockerData, setLockerData] = useState([]);
  const [index, setIndex] = useState(-1);
  const [rendering, setRendering] = useState(false);

  const AddModalRef = useRef();
  const NameModalRef = useRef();
  const StartModalRef = useRef();
  const LockerRef = useRef([]);
  const ViewBoxRef = useRef();

  useEffect(() => {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('locker/', 'POST');
        
				if (responseData.status === 200) {
          setLockerData(responseData.data);
				}
				else if (responseData.status === 401) {
          // alert("로그인 후 사용하세요");
          // history.push('/');
        }
			}
		)();
  }, [history, rendering])

  function modalExitClick() {
   setIsClickedAdd(false);
  }

  function addModalSaveClick() {
    var value = AddModalRef.current.getValue();

    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('locker/create/' 
                                                        // + window.sessionStorage.getItem('StoreId') 
                                                        + window.localStorage.getItem('StoreId')
                                                        + '/' 
                                                        + value, 'POST');
        
				if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedAdd(false);
				}
        else if (responseData.status === 204) {}
				// else if (responseData.status === 401) {
        //   alert("로그인 후 사용하세요");
        //   history.push('/');
        // }
			}
		)();
  }

  function changeModalSaveClick(name) {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.patchData('locker/modify/name/' 
                                                        + lockerData[index].id
                                                        + '/' 
                                                        + name, 'PATCH');
        
				if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedChangeName(false);
          setIndex(-1);
          clickMoreButton();
				}
        else if (responseData.status === 204) {}
				// else if (responseData.status === 401) {
        //   alert("로그인 후 사용하세요");
        //   history.push('/');
        // }
			}
		)();
  }
  
  function clickMoreButton() {
    for (var i = 0; i < lockerData.length; i++) {
      LockerRef.current[i].setClickedMore(false);
    }
  }

  function clickUsageHistory(index) {
    setIndex(index);
    setIsClickedHistory(true);
  }
  
  //
  function clickChangeState(index) {
    if (lockerData[index].state === '사용중') {
      alert('사용 종료후 다시시도하세요!');
      return;
    }
    else if (lockerData[index].state === '사용불가') {
      alert('이미 잠금 상태입니다.');
      return;
    }

    var state = '';

    if (lockerData[index].state === '비어있음') state = 'Invalid';

    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.patchData('locker/modify/' 
                                                        + lockerData[index].id
                                                        + '/'
                                                        + state, 'PATCH');
        // console.log(responseData);
				if (responseData.status === 200) {
          setRendering(!rendering);
          clickMoreButton();
				}
        else if (responseData.status === 204) {}
				// else if (responseData.status === 401) {
        //   alert("로그인 후 사용하세요");
        // history.push('/');
        // }
			}
		)();
  }


  function clickDeleteLocker(index) {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.deleteData('locker/delete/'
          + lockerData[index].id, 'DELETE');
          
        if (responseData.status === 200) {
          setRendering(!rendering);
          clickMoreButton();
        }
        else if (responseData.status === 205) { 
          alert("사용중인 락커입니다. 사용을 종료 후 다시시도해 주세요.");
        }
        // else if (responseData.status === 401) {
        //   alert("로그인 후 사용하세요");
        //   history.push('/');
        // }
      }
    )();
  }

  function clickChangeName(index) {
    setIndex(index);
    setIsClickedChangeName(true);
  }

  function clickUsingStartOrEndOrChangeValid(index) {
    setIndex(index);
    
    var state = '';

    if (lockerData[index].state === '사용불가') {
      state = 'Empty';

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.patchData('locker/modify/'
                                                          + lockerData[index].id
                                                          + '/'
                                                          + state, 'PATCH');
                                                          
          if (responseData.status === 200) {
            setRendering(!rendering);
            clickMoreButton();
          }
          else if (responseData.status === 204) { }
          // else if (responseData.status === 401) {
          //   alert("로그인 후 사용하세요");
          //   history.push('/');
          // }
        }
      )();
      return;
    }

    if (lockerData[index].state === '사용중') setIsClickedStop(true);
    else if (lockerData[index].state === '비어있음') setIsClickedStart(true);
  }

  function startModalSaveClick() {

    // var storeId = parseInt(window.sessionStorage.getItem('StoreId'));
    var storeId = parseInt(window.localStorage.getItem('StoreId'));
    var lockerId = lockerData[index].id;
    var memberId = StartModalRef.current.getMemberData().memberId;
    var approvalDate = moment().format('YYYY-MM-DDTHH:mm:ss');
    var startDate = StartModalRef.current.getStartDate() === '' ? '' : StartModalRef.current.getStartDate().format('YYYY-MM-DD') + 'T' + approvalDate.split('T')[1];
    var endDate = StartModalRef.current.getEndDate() === '' ? '' : StartModalRef.current.getEndDate().format('YYYY-MM-DD') + 'T' + approvalDate.split('T')[1];

    if (startDate === '') {
      alert('시작일을 선택해 주세요');
      return;
    }
    else if (endDate === '') {
      alert('종료일을 선택해 주세요');
      return;
    }

    var data = {
      storeId: storeId,
      lockerId: lockerId,
      memberId: memberId,
      startDate: startDate,
      endDate: endDate,
      approvalDate: approvalDate
    } 

    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('locker/start', 'POST', data);
        
				if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedStart(false);
          setIndex(-1);
          clickMoreButton();
				}
        else if (responseData.status === 204) {}
				// else if (responseData.status === 401) {
        //   alert("로그인 후 사용하세요");
        //   history.push('/');
        // }
			}
		)();
  }

  function stopModalSaveClick() {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('locker/stop/' + lockerData[index].id, 'POST');
        
				if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedStop(false);
          setIndex(-1);
          clickMoreButton();
				}
        else if (responseData.status === 204) {}
				// else if (responseData.status === 401) {
        //   alert("로그인 후 사용하세요");
        //   history.push('/');
        // }
			}
		)();
  }
  return (
    <TopLevelWrapper isOver={isOver}>
      {isClickedAdd && <LockerAddModal ref={AddModalRef} exitClick={modalExitClick} saveClick={addModalSaveClick} />}
      {isClickedChangeName && <LockerChangeNameModal ref={NameModalRef} data={lockerData} index={index} exitClick={() => setIsClickedChangeName(false)} saveClick={changeModalSaveClick} />}
      {isClickedStop && <LockerUsingStopModal data={lockerData[index]} exitClick={() => setIsClickedStop(false)} saveClick={stopModalSaveClick} />}
      {isClickedStart && <LockerStartModal ref={StartModalRef} exitClick={() => setIsClickedStart(false)} saveClick={startModalSaveClick} />}
      {isClickedHistory && <LockerHistoryModal isOver={false} data={lockerData[index]} exitClick={() => setIsClickedHistory(false)} />}
      <TitleWrapper>
        <Title isOver={isOver}>락커</Title>
        <ExplanationWrapper isOver={isOver}>
          <Explanation isOver={isOver}>{isOver ? `전체 락커 현황과 회원들의 락커를 관리하세요` : `전체 락커 현황을 관리하세요`}</Explanation>
          <Go  isOver={isOver} onClick={() => history.push('/locker')}>자세히 보기</Go> 
        </ExplanationWrapper>
      </TitleWrapper>
      <ViewBox ref={ViewBoxRef} isOver={isOver}>
        <ComponentWrapper isOver={isOver}>
          {
            lockerData.map((data, index) => (
              <LockerInfo
                key={index}
                data={data}
                index={index}
                ref={element => (LockerRef.current[index] = element)}
                clickMoreButtonFn={clickMoreButton}
                clickUsageHistoryFn={clickUsageHistory}
                clickFunctionFn={clickUsingStartOrEndOrChangeValid}
                clickChangeStateFn={clickChangeState}
                clickDeleteLockerFn={clickDeleteLocker}
                clickChangeNameFn={clickChangeName} />
            ))
          }
        </ComponentWrapper>
      </ViewBox>
    </TopLevelWrapper>
  )
};

export default LockerView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 692px;
  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(297px, 92.8125vw)`};
  // height: 385px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: ${props => props.isOver ? `28px 0 0 0` : `18px 0 0 0`};
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: ${props => props.isOver ? `4px` : `0px`};

  // width: 636px;
  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(255px, 79.6875vw)`};
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};
  
  color: #777777;
  
  margin: 0 0 0 0;
`;

const Go = styled.p`
  font-family: NotoSansKr-Medium;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  // width: 636px;
  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(255px, 79.6875vw)`};
  // height: 287px;
  max-height: ${props => props.isOver ? `` : `260px`};
 
  margin-top: ${props => props.isOver ? `24px` : `10px`};
  margin-bottom: ${props => props.isOver ? `28px` : `19px`};

  overflow-x: ${props => props.isOver ? `scroll` : `none`};
  overflow-y: ${props => props.isOver ? `none` : `scroll`};

  border-top: 2px solid #ECECEC; 

  &::-webkit-scrollbar {
    height: 8px;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `column` : `row`};
  flex-wrap: wrap;

  width: ${props => props.isOver ? `` : `max(255px, 79.6875vw)`};
  height: ${props => props.isOver ? `263px` : ``};

  gap: ${props => props.isOver ? `16px` : `7px`};

  margin-top: ${props => props.isOver ? `24px` : `14px`};
`;