import { React, useRef } from 'react';
import styled from 'styled-components';

import LogoPath from '../../../image/CompleteSignUpLogo.png';
import CheckImage from '../../../image/WhiteCheck.png';

function ExerciseDeleteModal(props) {
  const { isUseSameLessonAllDelete, setIsUseSameLessonAllDelete, lessonName, yesFn, noFn } = props;

  const TopLevelRef = useRef();

	return (
    <Background>
      <TopLevelWrapper ref={TopLevelRef}>
        <LogoWrapper>
          <Logo src={LogoPath} />
        </LogoWrapper>
        <ExplanationWrapper>
          <KorExplanation>디자인한 운동을 삭제하시겠습니까?</KorExplanation>
        </ExplanationWrapper>
          <TextWrapper>
            <CheckBox isClicked={isUseSameLessonAllDelete} onClick={() => setIsUseSameLessonAllDelete(!isUseSameLessonAllDelete)}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='NotoSansKR-Bold' size={12} color='#000000'>금일 다른시간</Text>
            <Text fontFamily='NotoSansKR-Bold' size={12} color='#FF8B48'>"{lessonName}"</Text>
            <Text fontFamily='NotoSansKR-Bold' size={12} color='#000000'>수업을 공통 적용하시겠습니까?</Text>
          </TextWrapper>
        <ButtonWrapper>
          <NoButton onClick={noFn}>
            <ButtonText>돌아가기</ButtonText>
          </NoButton>
          <YesButton onClick={yesFn}>
            <ButtonText>삭제하기</ButtonText>
          </YesButton>
        </ButtonWrapper>    
      </TopLevelWrapper>
    </Background>
  )
}
 
export default ExerciseDeleteModal;

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

	width: 476px;
	height: 268px;
  
	border-radius: 50px;
  background-color: #FFFFFF;

  transition: all 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  margin-top: -270px;
`;

const Logo = styled.img`
  width: 200px;
  height: 200px;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  // margin-top: 40px;
  margin-top: 60px;

  width: 90%;
`;

const KorExplanation = styled.pre`
  font-family: NotoSansKR-Bold;
  font-size: 25px;

  margin: 0 0 0 0;

  color: #000000;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  margin-top: 17px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.size}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 10px;
  height: 8.5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 14px;

  margin-top: 47px;
`;

const YesButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 155px;
  height: 45px;

  border-radius: 23px;
  background-color: #FF3131;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #DD0000;
  }
`;

const NoButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 155px;
  height: 45px;

  border-radius: 23px;
  background-color: #6DD49E;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #2BCB67;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 18px;
  
  color: #FFFFFF;

  margin: 0 0 0 0;
`;