import { React, useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

import SelectOffPath from '../../image/SelectOff.png';
import SearchInputCheck from '../../image/MemberShipSearchInputCheck.png';
import SelectExitButton from '../../image/SelectExitButton.png';

const ExcludedClassSelect = forwardRef((props, ref) => {
	const { changeFn, classData } = props;

	const [value, setValue] = useState('');
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState(classData);
	const [changed, setChanged] = useState(false);
	const [clickCount, setClickCount] = useState(0);

	const [isClicked, setIsClicked] = useState(false);

	const InputRef = useRef();
	const SelectRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		},
    getDataList: () => {
      return dataList;
    },
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
	}));
  
  useEffect(() => {
    var tmpList = classData.slice();

    for (var i = 0; i < tmpList.length; i++) {
      tmpList[i] = Object.assign(tmpList[i], {isChecked: false})
    }
    setData(tmpList);
  }, [classData])

  useEffect(() => {
    changeFn(dataList);
  }, [dataList, changeFn])

	function handleClick(e) {

    if (isClicked) {
      InputRef.current.style.borderColor = '#DFDFDF';
			SelectRef.current.style.border = '0px solid #CBCBCB';
			SelectRef.current.style.height = `0px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
      InputRef.current.style.borderColor = '#FF8B48';
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = `146px`;
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
    var newValue = dataList.slice();
    var newData = data.slice();

    if (dataList.includes(newData[index])){
      newData[index].isChecked = false;
      var idx = newValue.indexOf(data[index]);
      
      newValue.splice(idx, 1);
      setDataList(newValue);
      setData(newData);
    }
    else {
      newData[index].isChecked = true;
      newValue.push(data[index]);
      setDataList(newValue);
      setData(newData);
    }
	}

  function historyClick(index) {
    var newValue = dataList.slice();
    var newData = data.slice();

    var idx = data.indexOf(dataList[index]);
    newData[idx].isChecked = false;
    newValue.splice(index, 1);
    setDataList(newValue);
  }

  return (
    <TopLevelWrapper>
      <SearchWrapper>
          <Input 
            ref={InputRef}
            img={SelectOffPath}
            onClick={handleClick}>
              {
              dataList.map((data, index) => (
                index === 0 ?
                  <SelectedHistory key={index}>
                    <HistoryText>
                      {data.name}
                    </HistoryText>
                    <ExitButton src={SelectExitButton} onClick={() => historyClick(index)}/>
                  </SelectedHistory>
                :
                index === 1 ?
                  <SelectedHistory key={index}>
                    <HistoryText>
                      + {dataList.length - 1}
                    </HistoryText>
                  </SelectedHistory>
                  :
                  null
                ))
              }
            </Input>
          <SelectBox ref={SelectRef}>
            {
              data.map((data, index) => (
                <OptionWrapper key={index} onClick={() => optionalClick(index)}>
                  <Option isComprise={data.isChecked}>{data.name}</Option>
                  <CheckImg src={SearchInputCheck} opacity={data.isChecked ? 1 : 0}/>
                </OptionWrapper>
              ))
            }
          </SelectBox>
      </SearchWrapper>
    </TopLevelWrapper>
  )
})

export default ExcludedClassSelect;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 0 0 0 0;
`;

const Input = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 263px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;
  background: url(${props => props.img}) no-repeat 230px center;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const SelectedHistory = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin: 0 0 0 4px;

  border-radius: 5px;
  background-color: #FF8B48;
`;

const HistoryText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FFFFFF;

  margin: 3px 8px 4px 8px;
`;

const ExitButton = styled.img`
  width: 16px;
  height: 16px;

  margin: 0 4px 0 0;
`;

const CheckImg = styled.img`
  width: 12px;
  height: 9px;

  margin: 0 0 0 67px;

  opacity: ${props => props.opacity};

  transition: all 0.2s ease-in-out;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;

	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	width: 263px;
	height: 0px;

	border: 0px solid #CBCBCB;
	border-radius: 8px;
	overflow: hidden;

  z-index: 5;
  margin-top: 190px;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: row;

  justify-content: space-between;
  align-items: center;

	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

  // color: #777777;
  color: ${props => props.isComprise ? `#FF8B48` : `#777777`};

	margin: 8px 0 8px 8px;
`;