import { react, useEffect } from 'react';

import FetchModule from '../../Model/Share/Network/FetchModule';

function PaymentCompletePage() {
  useEffect(() => {
    var imp_uid = new URLSearchParams(window.location.search).get("imp_uid")
    var merchant_uid = new URLSearchParams(window.location.search).get("merchant_uid")
    var status = new URLSearchParams(window.location.search).get("status")

    // alert(window.sessionStorage.getItem('Token'));
    // window.location.href = "linkcoach://membershipPurchase/" + imp_uid + "/" + merchant_uid;
    
    var requestData = {
      imp_uid: imp_uid,
      merchant_uid: merchant_uid
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('sales/effectiveness', 'POST', requestData);

        if (responseData.status === 200) {

          var extend = window.sessionStorage.getItem("Extend");

          if (extend === 'true') {
            const extendResponseData = await fetchModule.postData('membership/extend_coach/' + merchant_uid, 'POST');

            if (extendResponseData.status === 200) {
              alert("결제가 완료되었습니다!\n앱에서 등록을 마무리해주세요!");
            }
          }
          else {
            var registData = {
              storeId: parseInt(window.sessionStorage.getItem("StoreId")),
              storeName: window.sessionStorage.getItem('StoreName'),
              merchantUid: merchant_uid,
              itemName: window.sessionStorage.getItem('ItemName'),
              userId: '',
              approval: 0
            }

            const registResponseData = await fetchModule.postDataOfCoach('registration/create', 'POST', registData);
            
            if (registResponseData.status === 200) {
              alert("결제가 완료되었습니다!\n앱에서 등록을 마무리해주세요!");
            }
          }
        }
        else {
          alert("결제에 실패하였습니다!\n잠시후 다시시도해주세요!");
        }


        window.location.href = "linkcoach://membershipPurchase/";

        setTimeout(() => (
          window.open("about:blank", "_self"),
          window.close()
        ), 5000)
      }
    )();
  }, [])

  return (
    <div />
  )
}

export default PaymentCompletePage;