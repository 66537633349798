import { React, useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

import SearchImgPath from '../../../image/SearchIcon.png';
import SearchExitImgPath from '../../../image/MemberShipInfoSearchExitButton.png';
import SearchInputCheck from '../../../image/MemberShipSearchInputCheck.png';

const MemberShipSearchInput = forwardRef((props, ref) => {
	const { isOver, className, placeholder, type, clickCountFn, memberData } = props;

  // const [options, setOptions] = useState(memberData);
  const testData = [
    {
      id: 1,
      name: '안형진',
      phone: '010-5519-2354'
    },
    {
      id: 1,
      name: '안형진',
      phone: '010-5519-2354'
    },
    {
      id: 1,
      name: '안형진',
      phone: '010-5519-2354'
    },
    {
      id: 1,
      name: '안형진',
      phone: '010-5519-2354'
    },
    {
      id: 1,
      name: '안형진',
      phone: '010-5519-2354'
    },
    {
      id: 1,
      name: '안형진',
      phone: '010-5519-2354'
    },
    {
      id: 1,
      name: '안형진',
      phone: '010-5519-2354'
    },
    {
      id: 1,
      name: '안형진',
      phone: '010-5519-2354'
    }
  ];

	const [value, setValue] = useState('');
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState(memberData);
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const [isClicked, setIsClicked] = useState(false);

	const inputRef = useRef();
	const SelectRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		},
    getDataList: () => {
      return dataList;
    },
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			setChangedPlaceholderColor(true);
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#E8E8E8';
		}
	}));

  
  useEffect(() => {
    var tmpList = memberData.slice();

    for (var i = 0; i < tmpList.length; i++) {
      tmpList[i] = Object.assign(tmpList[i], {isChecked: false})
    }
    setData(tmpList);

    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [memberData])

	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';

    if (isClicked) {
			SelectRef.current.style.border = '0px solid #CBCBCB';
			SelectRef.current.style.height = `0px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = `146px`;
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#E8E8E8';
	}
  
	function optionalClick(index) {
    var newValue = dataList.slice();
    var newData = data.slice();

    newData[index].isChecked = true;
    // newValue.push(Object.assign({name: data[index].name, phone: data[index].phone}));
    newValue.push(data[index]);

    setDataList(newValue);
    setData(newData);
	}

  return (
    <TopLevelWrapper>
      <Text>연장회원 추가</Text>
      <SearchWrapper>
        <Input
          ref={inputRef}
          img={SearchImgPath}
          clickCount={clickCount}
          type={type}
          placeholder={defaultPlaceholder}
          value={'' || value}
          changedColor={changedPlaceholderColor}
          className={`${className} ${changed ? "changed" : ""}`}
          onClick={handleClick}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur} />
          <SelectBox ref={SelectRef}>
            {
              data.map((data, index) => (
                <OptionWrapper key={index} onClick={() => optionalClick(index)}>
                  <Option isComprise={data.isChecked}>{data.name} ({data.phone})</Option>
                  <CheckImg src={SearchInputCheck} opacity={data.isChecked ? 1 : 0}/>
                </OptionWrapper>
              ))
            }
          </SelectBox>
        <InfoWrapper>
          {
            dataList.map((data, index) => (
              <InfoBox key={index}>
                <Table>
                  <Body>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `57px` }}>
                          <Name>{data.name}</Name>
                        </TextWrapper>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `83px` }}>
                          <Phone>{data.phone}</Phone>
                        </TextWrapper>
                      </Td>
                    </Tr>
                  </Body>
                </Table>
                <ExitButton src={SearchExitImgPath} />
              </InfoBox>
            ))
          }
        </InfoWrapper>
      </SearchWrapper>
    </TopLevelWrapper>
  )
})

export default MemberShipSearchInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `0px` : `4px`};
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: ${props => props.isOver ? `0 0 0 16px` : `0 0 0 0`};
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	line-height: 20px;

  padding: 0 0 0 40px;
  width: 223px;
  height: 30px;

	outline: none;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
	color: #000000;

  background: url(${props => props.img}) no-repeat 8px center;

	::placeholder {
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.3s ease-in-out;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 265px;
  height: 124px;

  overflow-y: scroll;

  margin-top: 16px;

  &::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  // align-items: center;

  width: 253px;
  height: 32px;
  
  border-bottom: 1px solid #F5F5F5;
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;

  height: 32px;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Name = styled.span`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const Phone = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const CheckImg = styled.img`
  width: 12px;
  height: 9px;

  margin: 0 0 0 67px;

  opacity: ${props => props.opacity};

  transition: all 0.2s ease-in-out;
`;

const ExitButton = styled.img`
  width: 16px;
  height: 16px;

  margin: 2px 0 0 97px;

  :hover {
    cursor: pointer;
  }
`;

const SelectBox = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	width: 263px;
	height: 0px;

	border: 0px solid #CBCBCB;
	border-radius: 8px;
	overflow: hidden;

  z-index: 5;
  margin-top: 45px;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: row;

  // justify-content: space-between;
  align-items: center;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

  // color: #777777;
  color: ${props => props.isComprise ? `#FF8B48` : `#777777`};

	margin: 8px 0 8px 8px;
`;