import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../Model/Share/Network/FetchModule';

function PaymentCoachCompletePage({match}) {

  const history = useHistory();

  useEffect(() => {
    window.sessionStorage.setItem('Token', "Bearer " + match.params.token);
    var status = match.params.status;
    
    if (status === 'success') {
      var paymentKey = new URLSearchParams(window.location.search).get("paymentKey");
      var orderId = new URLSearchParams(window.location.search).get("orderId");
      var amount = new URLSearchParams(window.location.search).get("amount");

      console.log("paymentKey : " + paymentKey);
      console.log("orderId : " + orderId);
      console.log("amount : " + amount);

      var data = { 
        paymentKey: paymentKey,
        merchant_uid: orderId,
        price: amount
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/tosspayments', 'POST', data);
  
          if (responseData.status === 200) {
            var extend = window.sessionStorage.getItem("Extend");

            if (extend === 'true') {
              const extendResponseData = await fetchModule.postData('membership/extend_coach/' + orderId, 'POST');
              
              if (extendResponseData.status === 200) {
                alert("결제가 완료되었습니다!\n앱에서 등록을 마무리해주세요!");
              }
            }
            else {
              var registData = {
                storeId: parseInt(window.sessionStorage.getItem("StoreId")),
                storeName: window.sessionStorage.getItem('StoreName'),
                merchantUid: orderId,
                itemName: window.sessionStorage.getItem('ItemName'),
                userId: '',
                approval: 0
              }
              // alert(registData.storeId);
              // alert(registData.storeName);
              // alert(registData.merchantUid);
              // alert(registData.itemName);
              // alert(registData.userId);
              // alert(registData.approval);
              const registResponseData = await fetchModule.postDataOfCoach('registration/create', 'POST', registData);
              
              // alert(registResponseData.status);
              if (registResponseData.status === 200) {
                alert("결제가 완료되었습니다!\n앱에서 등록을 마무리해주세요!");
              }
            }
          }
        }
      )();
    }
    else if (status === 'fail') {
      alert("결제에 실패하였습니다.");
    }


    window.location.href = "linkcoach://membershipPurchase/";

    setTimeout(() => (
      window.open("about:blank", "_self"),
      window.close()
    ), 5000)
    
  }, [])

  return <div/>
}

export default PaymentCoachCompletePage;

