import { React, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';

const NotReceivedInfo = (props) => {
  const { data, isOver } = props;

  const history = useHistory();

  const TextRef = useRef();

  function onMouseOver() {
    TextRef.current.style.color = `#FF8B48`;
  }

  function onMouseLeave() {
    TextRef.current.style.color = `#CBCBCB`;
  }

  function onClickNotReceive(e) {
    if (e.target !== TextRef.current) history.push('/member/valid/detail/' + data.memberId);
  }
  
  function onClickPushNotification() {
    var text = data.name 
              + '님!, ' 
              + data.item 
              + '에 아직 지불하지 않으신 금액이 ' 
              + (parseInt(data.totalPrice) - (parseInt(data.cardPrice) + parseInt(data.cashPrice))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              + '원 있어요!\n'
              + '센터를 방문해서 지불해주세요!';
              
    var requestData = {
      userId: data.userId,
      data: text,
      location: 'Reservation',
      jsonData: ''
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfCoach('push_notification/send', 'POST', requestData);
        
        if (responseData.status === 200) {
          alert("푸시알림을 전송하였습니다!");
        }
        else if (responseData.status === 204) {
          alert("Link Coach를 탈퇴한 회원입니다!");
        }
      }
    )();
  }

  return (
    // <TopLevelWrapper>
    //   <Table>
    //     <Body>
          <BodyTr isOver={isOver} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={onClickNotReceive}>
            <Td style={{ width: `60px`, marginLeft: '8px', overflow: 'hidden' , whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} isOver={isOver}>
              {data.name}
            </Td>
            <Td style={{ width: `66px`, marginLeft: '7px' }} isOver={isOver}>
              {data.division}
            </Td>
            <Td style={{ width: `276px` }} isOver={isOver}>
              {data.item}
            </Td>
            <Td style={{ width: `91px` }} isOver={isOver}>
              {data.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Td>
            <Td style={{ width: `91px` }} isOver={isOver}>
              {data.cardPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Td>
            <Td style={{ width: `91px` }} isOver={isOver}>
              {data.cashPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Td>
            <Td style={{ width: `91px` }} isOver={isOver}>
              {data.totalPrice - (data.cashPrice + data.cardPrice) > 0 ? (data.totalPrice - (data.cashPrice + data.cardPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
            </Td>
            <Td style={{ width: `90px` }} isOver={isOver}>
              {data.approvalDate.split('T')[0][2]
                + data.approvalDate.split('T')[0][3]
                + '.'
                + data.approvalDate.split('T')[0][5]
                + data.approvalDate.split('T')[0][6]
                + '.'
                + data.approvalDate.split('T')[0][8]
                + data.approvalDate.split('T')[0][9]}
            </Td>
            <Td style={{ width: `74px` }} isOver={isOver}>
              <Text ref={TextRef} isOver={isOver} onClick={onClickPushNotification}>문자발송</Text>
            </Td>
          </BodyTr>
    //     </Body>
    //   </Table>
    // </TopLevelWrapper>
  )
}

export default NotReceivedInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Table = styled.table`
  display: flex;
  flex-direction: column;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 992px;
`;

const BodyTr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 992px;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #F4F2F0;
  }
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  height: ${props => props.isOver ? `36px` : `15px`};

  color: #595959;
`;

const Text = styled.span`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #CBCBCB;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;