import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { throttle } from 'lodash';

// Component Import //
import TotalTitleInputBox from '../../../Component/NoteComponent/TotalTitleInputBox';
import ModifySectionBox from './ModifySectionBox';
import NormalSectionBox from './NormalSectionBox';
// Component Import //

// Image Import //
import PlusImage from '../../../image/PlusButton.png';
import TrashImage from '../../../image/TrashIcon.png';
import WodLoadingModal from './WodLoadingModal';
// Image Import //

const OneOnOneExerciseBox = forwardRef((props, ref) => {
  const { isClickedModify, onClickModifyButton, clickDate, useData, changeTopLevelWrapperPosition } = props;

  const [workoutId, setWorkoutId] = useState(0);
  const [title, setTitle] = useState('');
  const [sectionList, setSectionList] = useState([]);
  const [scroll, setScroll] = useState(0);
  const [nowSection, setNowSection] = useState(0);
  const [lastBottomMargin, setLastBottomMargin] = useState(0);
  const [isClickedWodLoading, setIsClickedWodLoading] = useState(false);

  const ScrollRef = useRef(null);
  const SectionRef = useRef([]);

  const EmptySection = {
    title: '',
    isUseTimeLimit: false,
    timeLimit: 0,
    isUseBuyin: false,
    buyinExerciseList: [],
    tag: 'ForTime-Rounds',
    round: 0,
    reps: [0],
    exerciseTime: 0,
    restTime: 0,
    mainExerciseList: [],
    isUseBuyout: false,
    buyoutExerciseList: [],
    customTitle: '',
    scoreKeyword: '점수 없음',
    scorePriority: '높을 수록',
    description: '',
  }

  useImperativeHandle(ref, () => ({
    getValue: () => {
      for (var i = 0; i < sectionList.length; i++) {
        // '점수 없음', '시간', '횟수', '라운드 + 횟수', '거리', '무게', '포인트', '피크와트', '최고 속도' //
        if (sectionList[i].scoreKeyword === '점수 없음') sectionList[i].scoreKeyword = 'Not Scored';
        else if (sectionList[i].scoreKeyword === '시간') sectionList[i].scoreKeyword = 'Time';
        else if (sectionList[i].scoreKeyword === '횟수') sectionList[i].scoreKeyword = 'Reps';
        else if (sectionList[i].scoreKeyword === '라운드 + 횟수') sectionList[i].scoreKeyword = 'Rounds And Reps';
        else if (sectionList[i].scoreKeyword === '거리') sectionList[i].scoreKeyword = 'Distance';
        else if (sectionList[i].scoreKeyword === '무게') sectionList[i].scoreKeyword = 'Weight';
        else if (sectionList[i].scoreKeyword === '포인트') sectionList[i].scoreKeyword = 'Points';
        else if (sectionList[i].scoreKeyword === '피크와트') sectionList[i].scoreKeyword = 'Peak Watts';
        else if (sectionList[i].scoreKeyword === '최고 속도') sectionList[i].scoreKeyword = 'Max Speed';

        if (sectionList[i].scorePriority === '높을 수록') sectionList[i].scorePriority = 'heigher';
        else if (sectionList[i].scorePriority === '낮을 수록') sectionList[i].scorePriority = 'lower';
      }

      return sectionList;
    },
    getTotalTitle: () => {
      return title;
    },
    getWorkoutId: () => {
      return workoutId;
    }
  }));

  // 날짜에 맞게 데이터 세팅 //
  useEffect(() => {
    for (var i = 0; i < useData.length; i++) {
      if (useData[i].classDate.clone().format('YYYY.MM.DD') === clickDate) {
        setTitle(useData[i].title);
        setWorkoutId(useData[i].workoutId);
        setSectionList(useData[i].sectionList);
      }
    }
  }, [useData, clickDate])

  useEffect(() => {
    if (sectionList.length === 0) return;

    var newIndex = 0;
    for (var i = 0; i < sectionList.length; i++) {
      if (SectionRef.current[i]?.offsetTop <= ( scroll + 348 + 24 )) {
        newIndex = i;
      }
    }
    setNowSection(newIndex);
    
    var height = SectionRef.current[sectionList.length - 1]?.clientHeight === undefined ? 0 : SectionRef.current[sectionList.length - 1]?.clientHeight;

    if (height >= 894) setLastBottomMargin(0);
    else setLastBottomMargin(894 - height);
  }, [SectionRef, sectionList, scroll])
  
  // 스크롤 움직임 이벤트 확인 // 
  function onScrollExerciseView(e) {
    setScroll(e.target.scrollTop);
  }

  // 섹션 이동 버튼 클릭시 //
  async function onClickSectionButton(index) {
     changeTopLevelWrapperPosition();
     SectionRef.current[index].scrollIntoView({ block: 'start', behavior: 'smooth' });
     changeTopLevelWrapperPosition();
  }

  // 섹션 추가 버튼 클릭시 //
  function onClickSectionPlus() {
    var newSectionList = sectionList.slice();

    newSectionList.push(EmptySection);
    
    setSectionList(newSectionList);
  }

  // 섹션 삭제 버튼 클릭시 //
  function onClickSectionDelete() {
    var newSectionList = sectionList.slice();
    newSectionList.splice(nowSection, 1);
    
    setSectionList(newSectionList);
  }

  // 섹션 데이터 최신화 메서드 //
  function refreshSectionList() {
    var newSectionList = sectionList.slice();

    setSectionList(newSectionList);
  }

  // 프로그램 가져오기 완료 버튼 클릭시 //
  function onClickCompleteWodLoading(data) {
    setTitle(data.title);
    setWorkoutId(data.workoutId);
    setSectionList(data.sectionList);
    setIsClickedWodLoading(false);
  }

  return (
    <TopLevelWrapper>
      { isClickedWodLoading && <WodLoadingModal standardDate={clickDate} completeFn={onClickCompleteWodLoading} closeFn={() => setIsClickedWodLoading(false)}/> }
      {
        isClickedModify ?
          <TotalWrapper>
            <DateWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>{clickDate}</Text>
            </DateWrapper>
            <TitleWrapper topMargin={24}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>제목</Text>
              <GetProgramButton onClick={() => setIsClickedWodLoading(true)}>프로그램 가져오기</GetProgramButton>
            </TitleWrapper>
            <TotalTitleInputBox value={title} setValue={setTitle} />
            <SectionWrapper>
              {
                sectionList.map((data, index) => (
                  <SectionBox key={index} nowSection={nowSection === index} onClick={() => onClickSectionButton(index)}>{String.fromCharCode(index + 65)}</SectionBox>
                ))
              }
              {
                sectionList.length < 26 &&
                <SectionFunctionBox backgroundColor='#FF8B48' hoverBackgroundColor='#FF7322' onClick={onClickSectionPlus}>
                  <Icon src={PlusImage} />
                </SectionFunctionBox>
              }
              {
                sectionList.length > 0 &&
                <SectionFunctionBox backgroundColor='#FF3131' hoverBackgroundColor='#DD0000' onClick={onClickSectionDelete}>
                  <Icon src={TrashImage} />
                </SectionFunctionBox>
              }
            </SectionWrapper>
            <ScrollWrapper ref={ScrollRef} onScroll={onScrollExerciseView} isModify={isClickedModify}>
              {
                sectionList.map((data, index) => (
                  <ModifySectionBoxWrapper ref={element => (SectionRef.current[index] = element)} key={index}>
                    <SectionDivisionBoxWrapper>
                      <SectionDivisionBox>
                        <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>{String.fromCharCode(index + 65)}</Text>
                        <Text fontFamily='Poppins-Bold' fontSize={12} color='#FFFFFF'>Session</Text>
                      </SectionDivisionBox>
                    </SectionDivisionBoxWrapper>
                    <ModifySectionBox
                      sectionData={data}
                      refreshSectionList={refreshSectionList} />
                  </ModifySectionBoxWrapper>
                ))
              }
              <LastBottomMarginComponent height={lastBottomMargin}/>
            </ScrollWrapper>
          </TotalWrapper>
          :
          <TotalWrapper>
            <DateWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>{clickDate}</Text>
              {/* <ModifyButton onClick={onClickModifyButton} color='#FF8B48'>수정</ModifyButton> */}
            </DateWrapper>
            <ScrollWrapper isModify={isClickedModify}>
              <TitleWrapper topMargin={0}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>제목</Text>
              </TitleWrapper>
              <TotalTitleBox>{title === '' ? '작성이 필요합니다' : title}</TotalTitleBox>
              {
                sectionList.map((data, index) => (
                  <NormalSectionBox
                    key={index}
                    index={index}
                    sectionData={data}/>
                ))
              }
            </ScrollWrapper>
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
})

export default OneOnOneExerciseBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // width: 582px;
  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(296px, 92.5vw)`};
  min-height: 707px;
  max-height: 1083px;
  
  padding-top: 28px;
  padding-bottom: 28px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 97.71%;
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 97.71%;

  margin-top: ${props => props.topMargin}px;
`;

const GetProgramButton = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const TotalTitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(97.71% - 34px);
  min-height: 34px;

  margin-top: 8px;
  padding-right: 16px;
  padding-left: 16px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  flex-wrap: wrap;

  width: 100%;

  margin-top: 24px;
  // margin-bottom: 24px;

  gap: 8px;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.nowSection ? `#6DD49E` : `#E0E0E0`};

  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${props => props.nowSection ? `#6DD49E` : `#D7D7D7`};
  }
`;

const SectionFunctionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${props => props.hoverBackgroundColor};
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  max-height: ${props => props.isModify ? `894px` : `1039px`};
  
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const ModifySectionBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  margin-bottom: ${props => props.lastBottomMargin}px;
`;

const SectionDivisionBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  margin-bottom: 24px;
`;

const SectionDivisionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90px;
  height: 32px;

  gap: 19px;
  padding: 0 11px 0 11px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const LastBottomMarginComponent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: ${props => props.height}px;

  background-color: #FFFFFF;
`;