import { React, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

import DownImage from '../../image/down.png';
import UpImage from '../../image/up.png';

const MemberShipMaxCountInput = forwardRef((props, ref) => {
  const { isOver, explanation, isRegularPayment } = props;
  const [dateVal, setDateVal] = useState(0);
	const [changed, setChanged] = useState(false);
  const [noLimit, setNoLimit] = useState(false);

  const InputRef = useRef();

  useImperativeHandle(ref, () => ({
		setValue: (val) => {
      if (val === -1) {
        setDateVal(0);
        setNoLimit(true);
      }
      else{
        setDateVal(val);
      }
		},
		getValue: () => {
      if (noLimit) return -1;
      else return dateVal;
		}
	}))

	function handleChange(e) {
    var regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    var result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    
    if (e.target.value === '') setDateVal(0);
		else setDateVal(parseInt(result));
		setChanged(true);
	}

	function handleFocus(e) {

	}

  function upButtonClick() {
    if (noLimit) return;
    setDateVal(dateVal + 1);
  }

  function downButtonClick(){
    if (noLimit) return;
    if (dateVal > 0) setDateVal(dateVal - 1);
  }

  return (
    <TopLevelWrapper>
      <ExplanationWrapper>
        <Explanation>{explanation}</Explanation>
        {
          !isRegularPayment &&
          <LimitWrapper>
            <LimitCheckBox noLimit={noLimit} onClick={() => setNoLimit(!noLimit)} />
            <LimitText>제한 없음</LimitText>
          </LimitWrapper>
        }
      </ExplanationWrapper>
      <InputWrapper>
        <Input 
          isOver={isOver} 
          disabled={noLimit}
          ref={InputRef}
          placeholder={0}
          value={dateVal}
          onChange={handleChange}
          onFocus={handleFocus}/>
        <ButtonWrapper>
          <Button img={UpImage} onClick={upButtonClick} />
          <Button img={DownImage} onClick={downButtonClick} />
        </ButtonWrapper>
        <Text>회</Text>
      </InputWrapper>
    </TopLevelWrapper>
  )
})

export default MemberShipMaxCountInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  transition: all 0.3s ease-in-out;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%
`;

const Explanation = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
  
  // font-family: NotoSansKR-Regular;
  // font-size: 12px;

  // color: #CBCBCB;

  // margin: 8px 17px 8px 0;
`;

const LimitWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const LimitCheckBox = styled.div`
  display: flex;
  flex-direction: row;

  width: 14px;
  height: 14px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;

  margin-right: 4px;

  background-color: ${props => props.noLimit ? `#FF8B48` : `#FFFFFF`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const LimitText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 8px;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: ${props => props.isOver ? `204px` : `139px`};
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  // background-color: ${props => props.noLimit ? `#ECECEC` : `#FFFFFF`};

  padding: 0 0 0 8px;
  margin: 0 5px 0 0;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background: url(${props => props.img}) no-repeat center;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 8px;
`;