import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import MemberDetailView from '../../Model/Individual/MemberModel/MemberDetail/MemberDetailView';
import useWindowSize from '../../Model/Share/Normal/useWindowSize';

function MemberDetailPage({match}) {
  const size = useWindowSize();

  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  return (
    <Container>
      <MemberDetailView memberId={match.params.memberId} division={match.params.division} isOver={isOver}/>
    </Container>
  )
}

export default MemberDetailPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  // width: 100%;
`;