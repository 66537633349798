import { React, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import FetchModule from '../../../../Share/Network/FetchModule';

const MemberShipDeleteModal = (props) => {
  const { data, index, className, isOver, exitClickFn, rendering } = props;

  const history = useHistory();

  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  function exitClick() {
    exitClickFn(className);
  }

  function deleteClick() {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.deleteData('membership/usage/delete/' + data[index].usageId, 'DELETE');
       
        if (responseData.status === 200) {
          alert('회원권을 삭제하였습니다.');
          exitClickFn(className);
          rendering();
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView isOver={isOver}>
        <Title isOver={isOver}>회원권 삭제</Title>
        <TextWrapper isOver={isOver}>
          <Explanation isOver={isOver}>해당 회원권으로 예약이나 수강한 내역이 모두 삭제되며</Explanation>
          <Explanation isOver={isOver}>복구가 불가능합니다. 정말 삭제하시겠습니까?</Explanation>
        </TextWrapper>
        <ButtonWrapper isOver={isOver}>
          <ExitButton isOver={isOver} onClick={exitClick}>취소</ExitButton>
          <DeleteButton isOver={isOver} onClick={deleteClick}>삭제하기</DeleteButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper> 
  )
}

export default MemberShipDeleteModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: ${props => props.isOver ? `371px` : `290px`};
  height: ${props => props.isOver ? `248px` : `218px`};

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: ${props => props.isOver ? `0 0 13px 28px` : `0 0 26px 22px`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: ${props => props.isOver ? `28px` : `22px`};
  
  gap: 14px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;
  
  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: ${props => props.isOver ? `14px` : `13px`};

  margin: ${props => props.isOver ? `53px 0 0 153px` : `33px 0 0 120px`};
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `72px` : `55px`};
  height: ${props => props.isOver ? `38px` : `34px`};

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `76px`};
  height: ${props => props.isOver ? `40px` : `34px`};

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;