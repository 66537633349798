import { React, forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Model/Share/Network/FetchModule';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

const CoachSelect = forwardRef((props, ref) => {
	const { isOver, selectClickFn } = props;
  const history = useHistory();

	const [isClicked, setIsClicked] = useState(false);
  const [options, setOptions] = useState([]);
	const [teacherId, setTeacherId] = useState(-1);
	const [defaultVal, setDefaultVal] = useState({ name: '' });

	const SelectRef = useRef();

	useImperativeHandle(ref, () => ({
		getValue: (val) => {
			return defaultVal;
		},
		setValue: (val) => {
			setTeacherId(val);
		},
		setIsClicked: () => {
			SelectRef.current.style.border = '1px solid #CBCBCB';
			SelectRef.current.style.height = `30px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
	}))

  useEffect(() => {
    setOptions([]);

    (
      async () => {
        const fetchModule = new FetchModule();
        const data = await fetchModule.getData('coach/select/', 'GET');
				
        if (data.status === 200) setOptions(data.data);
        else if (data.status === 401) history.push('/');
        else {};
      }
    )();
  }, [history])

	useEffect(() => {
		if (options.length === 0) return;

		if (teacherId === -1) setDefaultVal(options[0]);
		else setDefaultVal(options[options.findIndex(i => i.id === teacherId)]);
	}, [options, teacherId])

  // useEffect(() => {
  //   if (options.length === 0) return;
  //   setDefaultVal(options[0]);
  // }, [options])

	function onClick() {
		selectClickFn();
		if (isClicked) {
			SelectRef.current.style.border = '1px solid #CBCBCB';
			SelectRef.current.style.height = `30px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = `${32 * (options.length)}px`;
			SelectRef.current.style.maxHeight = '240px';
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
		setDefaultVal(options[index]);
		setTeacherId(-1);
	}

	return (
		<TopLevelWrapper isOver={isOver}>
			<SelectBox
				ref={SelectRef}
				isOver={isOver}
				isClicked={isClicked}
				buttonPath={isClicked ? SelectOnPath : SelectOffPath}
				onClick={onClick}>
				{
					isClicked ?
						options.map((data, index) =>
							<OptionWrapper key={index} onClick={() => optionalClick(index)}>
								<Option>{data.name}</Option>
							</OptionWrapper>
						)
						:
						<OptionWrapper>
							<Option>
								{
									defaultVal.name
								}
							</Option>
						</OptionWrapper>
				}
			</SelectBox>
		</TopLevelWrapper>
	)
})

export default CoachSelect;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	
	width: ${props => props.isOver ? `588px` : `234px`};
	height: 32px;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;

	// justify-content: center;
	align-items: flex-start;

	width: ${props => props.isOver ? `586px` : `232px`};
	height: 30px;

  // z-index: 2;

	border: 1px solid #CBCBCB;
	border-radius: 8px;
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

	margin: 6px 0 6px 8px;
`;