import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import styled from "styled-components";

import HomeMain from './View/Home.js'

// account Page //
import AccountPage from './View/AccountPage/AccountPage.js';
import CertificationPage from './View/AccountPage/CertificationPage.js';
import EmailCertificationPage from './View/AccountPage/EmailCertificationPage.js';
import SearchIdentifierPage from './View/AccountPage/SearchIdentifierPage.js';
import PermutePage from './View/AccountPage/PermutePage.js';
import PasswordPermutePage from './View/AccountPage/PasswordPermutePage.js';

// Member Page //
import MemberRegistrationPage from './View/MemberPage/MemberRegistrationPage.js'
import ValidMemberInfoPage from "./View/MemberPage/ValidMemberInfoPage.js";
import ExpiredMemberInfo from './View/MemberPage/ExpiredMemberInfoPage.js';
import WaittingMemberPage from "./View/MemberPage/WaittingMemberPage";

// Schedule Page //
import ClassManagePage from "./View/ClassPage/ClassManagePage";
import SchedulePage from './View/SchedulePage/SchedulePage.js';

// Note Page //
import OneOnOnePage from "./View/NotePage/OneOnOnePage";
import ProgramPage from "./View/NotePage/ProgramPage";
import RankingPage from "./View/NotePage/RankingPage";
import SharePage from "./View/NotePage/SharePage";
import TimeLinePage from "./View/NotePage/TimeLinePage";

// Board Page //
import BoardPage from "./View/BoardPage/Board";
import FullScreenBoardPage from './View/BoardPage/FullScreenBoard';

// Setting Page //
import SettingPage from './View/SettingPage.js';

// Statistics Page //
import StatisticsPage from './View/StatisticsPage.js';

// Locker Page //
import LockerPage from './View/LockerPage.js';

// NoticePage //
import NoticeListPage from './View/NoticePage/NoticeListPage.js';
import NoticeCreatePage from "./View/NoticePage/NoticeCreatePage";
import NoticePage from './View/NoticePage/NoticePage';
import NoticeModifyPage from "./View/NoticePage/NoticeModifyPage";

// PaymentPage //
import PaymentRequestPage from "./View/PaymentPage/PaymentRequestPage";
import PaymentPage from "./View/PaymentPage/PaymentPage";
import PaymentCompletePage from "./View/PaymentPage/PaymentCompletePage";
import PaymentCoachCompletePage from "./View/PaymentPage/PaymentCoachCompletePage";
import PaymentCompleteToss from "./View/PaymentPage/PaymentCompleteToss";
import PaymentSubscribeCompleteToss from "./View/PaymentPage/PaymentSubscribeCompleteToss";
import ZonePaymentSubscribeCompleteToss from "./View/PaymentPage/ZonePaymentSubscribeCompleteToss";

// Ruler Page //
import RulerPage from "./View/RulerPage";

// Channel Talk Component //
import ChannelTalk from "./Model/Share/Network/ChannelTalk";

import DefaultView from './Model/Share/Normal/DefaultView.js';
import BottomInfo from './Model/Share/Normal/BottomInfo.js';
import ServicePage from "./View/RulesPage/ServicePage";
import LocationPage from "./View/RulesPage/LocationPage";
import PrivacyPage from "./View/RulesPage/PrivacyPage";
import PromotionPage from "./View/RulesPage/PromotionPage";
import MemberDetailPage from "./View/MemberPage/MemberDetailPage";
import MySettingPage from "./View/MySettingPage";
import RefundPage from "./View/RulesPage/RefundPage";
import PaymentCoachSubscribeCompleteToss from "./View/PaymentPage/PaymentCoachSubscribeCompleteToss";
import KeypadForAttendancePage from "./View/KeypadPage/KeypadForAttendancePage";
import TempKeypadPage from "./View/KeypadPage/TempKeypadPage";
import CoachPasswordPassPage from "./View/AccountPage/CoachPassPage";
import DeepLinkNotification from "./View/DeepLink/DeepLinkNotification.js";
import CardRegistrationRequestPage from "./CardRegistrationPage/View/CardRegistrationRequestPage.js";
// import CardRegistrationFailedPage from "./CardRegistrationPage/View/CardRegistrationFailedPage.js";
import CardRegistrationCompletePage from "./CardRegistrationPage/View/CardRegistrationCompletePage.js";
// import BillingRegistrationRequestPage from "./BillingRegistrationPage/View/BillingRegistrationRequestPage.js";
// import BillingRegistrationCompletePage from "./BillingRegistrationPage/View/BillingRegistrationCompletePage.js";
import BillingRegistrationRequestPage from './BillingRegistrationPage/View/BillingRegistrationRequestPage.js';
import BillingRegistrationCompletePage from './BillingRegistrationPage/View/BillingRegistrationCompletePage.js';


function App() {

  // Channel Talk Commponent Building //
  useEffect(() => {
    ChannelTalk.boot({ "pluginKey": "d2f4dfce-95e8-498e-aed3-84804b9d3772" });
  }, [])

  return (
    <Container>
      <Router>
        <DefaultView />
        <Switch>
          {/* Account Page */}
          <Route path="/" exact component={AccountPage} />
          <Route path="/main" exact component={HomeMain} />
          <Route path="/certification/:certificationString" exact component={EmailCertificationPage} />
          <Route path="/certification" exact component={CertificationPage} />
          <Route path="/identifier" exact component={SearchIdentifierPage} />
          <Route path="/permute/:token/:name" exact component={PasswordPermutePage} />
          <Route path="/permute/" exact component={PermutePage} />
          {/* MemberPage */}
          <Route path="/member/valid" exact component={ValidMemberInfoPage} />
          <Route path="/member/valid/detail/:memberId" exact component={MemberDetailPage} />
          <Route path="/member/valid/detail/:memberId/:division" exact component={MemberDetailPage} />
          <Route path="/member/expired" exact component={ExpiredMemberInfo} />
          <Route path="/member/registration" exact component={MemberRegistrationPage} />
          <Route path="/member/waitting" exact component={WaittingMemberPage} />
          <Route path="/mypage" exact component={MySettingPage} />
          {/* SchedulePage */}
          <Route path="/class" exact component={ClassManagePage} />
          <Route path="/schedule" exact component={SchedulePage} />
          {/* Note Page */}
          <Route path="/note/one-on-one" exact component={OneOnOnePage} />
          <Route path="/note/program" exact component={ProgramPage} />
          <Route path="/note/ranking" exact component={RankingPage} />
          <Route path="/note/share" exact component={SharePage} />
          <Route path="/note/timeline" exact component={TimeLinePage} />
          {/* BoardPage */}
          <Route path="/board" exact component={BoardPage} />
          <Route path="/full/board" exact component={FullScreenBoardPage} />
          {/* SettingPage */}
          <Route path="/setting" exact component={SettingPage} />
          {/* StatisticsPage */}
          <Route path="/statistics" exact component={StatisticsPage} />
          {/* Locker Page */}
          <Route path="/locker" exact component={LockerPage} />
          {/* Notice Page */}
          <Route path="/notice" exact component={NoticeListPage} />
          <Route path="/notice/details/:noticeId" exact component={NoticePage} />
          <Route path="/notice/create" exact component={NoticeCreatePage} />
          <Route path="/notice/modify/:noticeId" exact component={NoticeModifyPage} />
          {/* Rules Page */}
          <Route path="/rule/service" exact component={ServicePage} />
          <Route path="/rule/location" exact component={LocationPage} />
          <Route path="/rule/privacy" exact component={PrivacyPage} />
          <Route path="/rule/promotion" exact component={PromotionPage} />
          <Route path="/rule/refund" exact component={RefundPage} />
          {/* Payment Page */}
          {/* <Route path="/payment/:merchantUid/:access/:extend/:membershipId/:validity/:existingEndDate" exact component={PaymentRequestPage}/> */}
          <Route path="/payment/complete/:status" exact component={PaymentCompleteToss} />
          <Route path="/payment/subscribe/:status" exact component={PaymentSubscribeCompleteToss} />
          <Route path="/payment/:merchantUid/:access/:extend" exact component={PaymentRequestPage} />
          <Route path="/payment" exact component={PaymentPage} />
          <Route path="/complete" exact component={PaymentCompletePage} />
          <Route path="/payment/coach/complete/:token/:status" exact component={PaymentCoachCompletePage} />
          <Route path="/payment/coach/subscribe/:token/:status" exact component={PaymentCoachSubscribeCompleteToss} />
          <Route path="/payment/zone/subscribe/:status" exact component={ZonePaymentSubscribeCompleteToss} />
          {/* Coach Ruler */}
          <Route path="/ruler/:screenWidth" exact component={RulerPage} />
          <Route path="/permute/:token" exact component={PasswordPermutePage} />
          <Route path="/permute/" exact component={PermutePage} />
          <Route path="/coach/pass/:token" exact component={CoachPasswordPassPage} />

          {/* Coach Payment */}
          {/* <Route path="/user/registration_card/:token" exact component={CardRegistrationRequestPage} /> */}
          <Route path="/user/registration_card/:token" exact component={CardRegistrationRequestPage} />
          {/* <Route path="/fail/registration_card" exact component={CardRegistrationFailedPage} /> */}
          <Route path="/complete/card_registration/:status/:error" exact component={CardRegistrationCompletePage} />
          {/* Coach Payment */}

          {/* Zone Payment */}
          <Route path="/user/registration_billing/:storeId/:token" exact component={BillingRegistrationRequestPage} />
          <Route path="/complete/billing_registration/:status/:error" exact component={BillingRegistrationCompletePage} />
          {/* Zone Payment */}

          {/* Coach Deep Link */}
          <Route path="/view/notification/:noticeId/:storeId" exact component={DeepLinkNotification} />
          {/* Coach Deep Link */}
          <Route path="/ready/keypad/:storeId/:accessToken/:refreshToken" exact component={TempKeypadPage} />
          <Route path="/keypad" exact component={KeypadForAttendancePage} />
          <Redirect to="/main"></Redirect>
        </Switch>
        <BottomInfo />
      </Router>
    </Container>
  );
  // }
}

export default App;

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #FDFCFB;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;
`;