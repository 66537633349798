class CalcDate {

  // 일단위로 날짜 더하기
  static getAddDay(days) {
    
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);

    return date;
  }

  // 오늘날짜 주는 함수 format = "YYYY-MM-DD"
  static getToday() { 
  
    var date = new Date();
    var year = date.getFullYear();
    var month = ("0" + (1 + date.getMonth())).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);

    return year + "-" + month + "-" + day;
  }

  // 금일 기준 D-Day계산하기
  static getDday(date) {
    
    var today = new Date();
    var endDate = new Date(date);
    var diffDays = Math.round(Math.abs(endDate - today) / 8.64e7);

    return diffDays;
  }

  // 날짜 포멧 맞추기 format = "YYYY-MM-DD"
  static getFormatDate(date) {

    var year = date.getFullYear();
    var month = ("0" + (1 + date.getMonth())).slice(-2);
    var day = ("0" + date.getDate()).slice(-2)

    return year + '-' + month + '-' + day;
  }

  // 시간 포멧 맞추기 format = "HH:mm:00"
  static getFormatTime(date) {
    var hour = ("0" + date.getHours()).slice(-2)
    var minite = ("0" + date.getMinutes()).slice(-2)

    return (hour + ":" + minite + ":" + "00");
  } 
  
  // Date를 서버형식에 맞추는 함수
  static getFormatFullDateInIOS(date) {
    
    var year = date.getFullYear();
    var month = ("0" + (1 + date.getMonth())).slice(-2);
    var day = ("0" + date.getDate()).slice(-2)
    var hour = ("0" + date.getHours()).slice(-2)
    var minite = ("0" + date.getMinutes()).slice(-2)

    return (year + "-" + month + "-" + day + "T" + hour + ":" + minite + ":00");
  }
  
  // Date를 한글형식에 맞추는 함수
  static getFormatFullDateInKR(date) {
    
    var year = date.getFullYear();
    var month = ("0" + (1 + date.getMonth())).slice(-2);
    var day = ("0" + date.getDate()).slice(-2)
    var hour = ("0" + date.getHours()).slice(-2)
    var minite = ("0" + date.getMinutes()).slice(-2)

    return (year + "년 " + month + "월 " + day + "일 " + hour + ":" + minite);
  }

  // 시작날짜를 기준으로 유효날짜를 계산해주는 함수 
  // startDate format = "YYYY-MM-DD"
  // validity format = int
  static calculationValidity(startDate, validity) { 

    var date = new Date(startDate);
    date.setDate(date.getDate() + validity);

    return date;
  }

  // 날짜 받아서 분 단위로 더하기
  // format = YYYY-MM-DDTHH:mm:ss
  static additionMinute(date, minute) {
    var newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + minute);

    return newDate;
  }
}

export default CalcDate;