import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled, { keyframes } from 'styled-components';

// import CalenderImagePath from '../../image/Calender-gray.png';
import CalenderRightArrowImagePath from '../../../image/CalenderRightArrow.png';
import CalenderLeftArrowImagePath from '../../../image/CalenderLeftArrow.png';
import CalenderLeftArrow2ImagePath from '../../../image/CalenderLeftArrow2.png';
import CalenderRightArrow2ImagePath from '../../../image/CalenderRightArrow2.png';

import DeleteButton from '../../../image/CategoryDeleteButton.png';

const CalenderModal = forwardRef((props, ref) => {
  const { isOver, date, clickDate, dateClickFn, exitFn } = props;

  const [today, setToday] = useState(date);
  const [isClicked, setIsClicked] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [value, setValue] = useState(clickDate);

  const firstWeek = today.clone().startOf('month').week();
  const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

  useImperativeHandle(ref, () => ({
    setValue: (val) => {
      setValue(val)
    },
    getValue: () => {
      return value;
    },
    closeCalender: () => {
      setIsClicked(false);
    }
  }));
  
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  useEffect(() => {
    setToday(date);
  }, [date])

  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = today.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        newDateList.push(days);
      } 
    }
    setDateList(newDateList);
  }, [isClicked, today, firstWeek, lastWeek]) 

  function dateClick(index) {
    setValue(dateList[index].format('YYYY.MM.DD'));
    dateClickFn(dateList[index].format('YYYY.MM.DD'));
    // dateClickFn(dateList[index]);
    exitFn(false);
  }

  return (
    <TopLevelWrapper>
      <ModalView isOver={isOver}>
        <TotalWrapper>
          <TitleWrapper>
            <LayerWrapper>
              <Arrow img={CalenderLeftArrow2ImagePath} onClick={() => setToday(today.clone().subtract(1, 'year'))} style={{ margin: `0 -7px 0 0` }} />
              <Arrow img={CalenderLeftArrowImagePath} onClick={() => setToday(today.clone().subtract(1, 'month'))} />
              <Title>{today.format('YYYY년 MM월')}</Title>
              <Arrow img={CalenderRightArrowImagePath} onClick={() => setToday(today.clone().add(1, 'month'))} />
              <Arrow img={CalenderRightArrow2ImagePath} onClick={() => setToday(today.clone().add(1, 'year'))} style={{ margin: `0 0 0 -7px` }} />
            </LayerWrapper>
            <ExitButton src={DeleteButton} onClick={() => exitFn(false)}/>
          </TitleWrapper>
          <DateColumnWrapper isOver={isOver}>
            <DateColumn style={{ width: `25px`, marginRight: isOver ? `28px` : `11px` }}>SUN</DateColumn>
            <DateColumn style={{ width: `30px`, marginRight: isOver ? `27px` : `12px` }}>MON</DateColumn>
            <DateColumn style={{ width: `23px`, marginRight: isOver ? `29px` : `12px` }}>TUE</DateColumn>
            <DateColumn style={{ width: `28px`, marginRight: isOver ? `28px` : `11px` }}>WED</DateColumn>
            <DateColumn style={{ width: `25px`, marginRight: isOver ? `31px` : `16px` }}>THU</DateColumn>
            <DateColumn style={{ width: `18px`, marginRight: isOver ? `33px` : `17px` }}>FRI</DateColumn>
            <DateColumn style={{ width: `24px`/*, marginRight: `28px` */}}>SAT</DateColumn>
          </DateColumnWrapper>
          <DateWrapper isOver={isOver}>
            {
              dateList.map((data, index) => (
                data.format('MM') === today.format('MM') ?
                  <Date key={index} isOver={isOver} isClicked={data.format('YYYY.MM.DD') === value} onClick={() => dateClick(index)}>
                    {data.format('D')}
                  </Date>
                  :
                  <WithoutHoverDate key={index} isOver={isOver}>
                    {data.format('D')}
                  </WithoutHoverDate>
              ))
            }
          </DateWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
})

export default CalenderModal;

const startAnimation = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);

  animation-name: ${startAnimation};
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `408px` : `296px`};
  // height: 418px;

  padding-bottom: 28px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const ExitButton = styled.img`
  width: 16px;
  height: 16px;

  :hover {
    cursor: pointer;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  // margin: 28px 0 0 22px;
  margin-top: 28px;
`;

const Arrow = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  
  background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const DateColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 29px;
  margin-left: ${props => props.isOver ? `8px` : `4px`};
  margin-bottom: 23px;
`;

const DateColumn = styled.p`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: Poppins-bold;
  font-size: 12px;

  color: #FF8B48;
  
  margin: 0 0 0 0;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  // width: 408px;
  width: ${props => props.isOver ? `375px` : `263px`};

  gap: ${props => props.isOver ? `10px` : `3px`};
  // margin-left: 8px;
`;

const Date = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `44px` : `35px`};
  height: ${props => props.isOver ? `44px` : `35px`};

  border-radius: ${props => props.isOver ? `44px` : `35px`};

  // margin: 5.5px 5.5px 5.5px 5.5px;

  background-color: ${props => props.isClicked ? `#FF7322` : `#FFFFFF`};
  
  font-family: Poppins-Medium;
  font-size: 14px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#595959`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF7322;
    color: #FFFFFF;

    font-fmaily: Poppins-Bold;
  }
`;

const WithoutHoverDate = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `44px` : `35px`};
  height: ${props => props.isOver ? `44px` : `35px`};

  border-radius: ${props => props.isOver ? `44px` : `35px`};

  // margin: 5.5px 5.5px 5.5px 5.5px;

  background-color: #FFFFFF;

  font-family: Poppins-Regular;
  font-size: 14px;

  color: #777777;

  :hover {
    cursor: default;
  }
`;