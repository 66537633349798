import { React } from 'react';
import styled from 'styled-components';

import ShareView from '../../Model/Individual/NoteModel/ShareView';
import MakingView from '../../Model/Share/Normal/MakingView';

function SharePage () {

  return (
    <Container>
      {/* <ShareView/> */}
      <MakingView/>
    </Container>
  )
}

export default SharePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;