import { React } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import NoticeInfo from './NoticeInfo';

const Notice = (props) => {
  const { data, isOver } = props;

  const history = useHistory();

  function clickDetailsFn(index) {
    // console.log(data[index]);
    history.push('/notice/details/' + data[index].noticeId);
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      <TotalView isOver={isOver}>
        {
          data.map((data, index) => (
            <NoticeInfo key={index} data={data} index={index} clickFn={clickDetailsFn} isOver={isOver}/>
          ))
        }
      </TotalView>
    </TopLevelWrapper>
  )
}

export default Notice;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  // width: 652px;
  width: ${props => props.isOver ? `max(652px, 84.89583333333333vw)` : `max(255px, 79.6875vw)`};

  transition: all 0.3s ease-in-out;
`;

const TotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: ${props => props.isOver ? `4px` : `2px`};

  // width: 636px;
  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(255px, 79.6875vw)`};
  max-height: 376px;
`;