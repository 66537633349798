import { React } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const FirstValidMemberInfo = (props) => {
  const { data, isOver, onClick } = props;
  
  return (
    <TopLevelWrapper isOver={isOver} onClick={onClick}>
      {
        isOver ?
          <Table>
            <Body isOver={isOver}>
              <Tr>
                <Td style={{ width: 'max(67px, 7.8vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(67px, 7.8vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.name}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(54px, 6.3vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(54px, 6.3vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.gender === 'male' ? '남성' : '여성'}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(98px, 10.9vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(98px, 10.8vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.birthDay[0]
                      + data.birthDay[1]
                      + data.birthDay[2]
                      + data.birthDay[3]
                      + '.'
                      + data.birthDay[4]
                      + data.birthDay[5]
                      + '.'
                      + data.birthDay[6]
                      + data.birthDay[7]}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(114px, 12.7vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(114px, 12.7vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.phone[0]
                      + data.phone[1]
                      + data.phone[2]
                      + '-'
                      + data.phone[3]
                      + data.phone[4]
                      + data.phone[5]
                      + data.phone[6]
                      + '-' +
                      + data.phone[7]
                      + data.phone[8]
                      + data.phone[9]
                      + data.phone[10]}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(307px, 39.97395833333333vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(307px, 39.97395833333333vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.memberShipName}</Text>
                  </TextWrapper>
                </Td>
                {
                  moment(data.endDate).diff(moment(), 'day') <= 5 &&
                  <Td>
                    <TextWrapper style={{ maxWidth: '45px' }}>
                      <Dday>{moment(data.endDate).diff(moment(), 'day') === 0 ? 'D-day' : 'D-' + moment(data.endDate).diff(moment(), 'day')}</Dday>
                    </TextWrapper>
                  </Td>
                }
              </Tr>
            </Body>
          </Table>
          :
          <Table>
            <Body isOver={isOver}>
              <Tr>
                <Td style={{ width: 'max(47px, 14vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(47px, 14.6875vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.name}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(46px, 13.6vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(46px, 14.6875vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.gender === 'male' ? '남성' : '여성'}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(61px, 17.4vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(61px, 19.0625vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{
                      + data.birthDay[2]
                      + data.birthDay[3]
                      + '.'
                      + data.birthDay[4]
                      + data.birthDay[5]
                      + '.'
                      + data.birthDay[6]
                      + data.birthDay[7]}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(98px, 30.625vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(98px, 30.625vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.phone[0]
                      + data.phone[1]
                      + data.phone[2]
                      + data.phone[3]
                      + data.phone[4]
                      + data.phone[5]
                      + data.phone[6]
                      + data.phone[7]
                      + data.phone[8]
                      + data.phone[9]
                      + data.phone[10]}</Text>
                  </TextWrapper>
                </Td>
                {
                  moment(data.endDate).diff(moment(), 'day') <= 5 &&
                  <Td>
                    <TextWrapper style={{ maxWidth: '45px' }}>
                      <Dday>{moment(data.endDate).diff(moment(), 'day') === 0 ? 'D-day' : 'D-' + moment(data.endDate).diff(moment(), 'day')}</Dday>
                    </TextWrapper>
                  </Td>
                }
              </Tr>
            </Body>
          </Table>
      }
    </TopLevelWrapper>
  )
};

export default FirstValidMemberInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(281px, 87.8125vw)`};
  height: ${props => props.isOver ? `50px` : `17px`};

  background: #FFFFFF;
  // background-color: red;

  margin-top: ${props => props.isOver ? `8px` : `4px`};

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: ${props => props.isOver ? `max(28px, 3.645833333333333vw)` : `max(10px, 3.125vw)`};
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  padding: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: ${props => props.isOver ? `20px` : `15px`};
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;

  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};
`;

const Dday = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 45px;
  height: 15px;

  border-radius: 30px;
  background-color: #FF8B48;

  font-family: NotoSansKR-Regular;
  font-size: 11px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;