import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './NoticeCreateBox.css';

import QuillCustomToolbar from './QuillCustomToolbar';
import TitleInput from '../../../Component/NoticeComponent/TitleInput';

const NoticeCreateBox = forwardRef((props, ref) => {
  const { isOver } = props;

  const [value, setValue] = useState('');

  const TitleRef = useRef();

  const modules = {
    toolbar: {
      container: "#toolbar",
    }
  };

  const formats = [
    "size",
    "underline",
    "italic",
    "bold",
    "align",
    "color",
    "background",
    "image"
  ];

  useImperativeHandle(ref, () => ({
    getTitle: () => {
      return TitleRef.current.getValue();
    },
    getValue: () => {
      return value;
    }
  }))

  function handleChange(value) {
    setValue(value);
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      <ViewWrapper>
        <ComponentWrapper>
          <Title isOver={isOver}>제목</Title>
          <TitleInput ref={TitleRef} isOver={isOver} placeholder="50자 이내의 제목을 입력해주세요"/>
        </ComponentWrapper>
        <ComponentWrapper style={{marginTop: `24px`}}>
          <Title isOver={isOver}>내용</Title>
          <QuillWrapper isOver={isOver} className="text-editor">
            <QuillCustomToolbar />
            <ReactQuill
              modules={modules}
              formats={formats}
              theme='snow'
              placeholder='내용을 입력해주세요'
              style={{
                width: isOver ? `max(634px, 82.55208333333333vw)` : `max(260px, 81.25vw)`,
                minHeight: `287px`,
                border: `1px solid #DFDFDF !Important`,
                borderRadius: `30px`,
                fontFamily: 'NotoSansKR-Regular',
                fontSize: '14px',
                color: '#232323'
              }}
              value={value}
              onChange={handleChange} />
          </QuillWrapper>
        </ComponentWrapper>
      </ViewWrapper>
    </TopLevelWrapper>
  )
})

export default NoticeCreateBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(298px, 93.125vw)`};
  // height: ${props => props.isOver ? `535px` : `460px`};
  
  margin-top: 19px;
  margin-bottom: 96px;
  padding-bottom: 68px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  diplay: flex;
  flex-direction: column;

  justify-content: flex-start
  align-items: flex-start;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver? `14px` : `10px`};

  margin: 0 0 0 0;

  color: #232323;
`;

const QuillWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: ${props => props.isOver ? `16px` : `8px`};

  // border: none !Important;
  // width: 964px;
  // height: 320px;
`;