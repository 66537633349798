
import { forwardRef, useImperativeHandle, useState, useRef, useEffect } from "react";
import styled from 'styled-components';

const SocialSecurityInput = forwardRef((props, ref) => {
	const { className, onChangeValue, isOver } = props;
	const [value, setValue] = useState('');
	const [clickCount, setClickCount] = useState(0);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
		},
		getValue: () => {
			return value;
		},
		focus: () => {
			inputRef.current.focus();
		},
		isBold: () => {
			inputRef.current.style.borderColor = '#FF3131';
		}
	}));

	useEffect(() => {
		if (value === '' && clickCount > 0) {
			inputRef.current.style.borderColor = '#FF3131';
		}
		else if (clickCount > 0) {
			inputRef.current.style.borderColor = '#FF8B48';
		}
	}, [value, clickCount])

	function handleChange(e) {
		const curValue = e.target.value
		const newValue = curValue.replace(/[^0-9]/g, '')

		if (newValue.length < 2) {
			setValue(newValue);
			if (newValue !== '') onChangeValue(className)
		}
	}

	function handleFocus(e) {
		inputRef.current.style.borderColor = '#FF8B48';
		setValue('');
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		if (value === "") {
		}
		else { }// do nothing
	}

	return (
		<CustomInput
			ref={inputRef}
			tabIndex={-1}
			isOver={isOver}
			type='text' 
			value={value || ''}
			onChange={handleChange}
			onFocus={handleFocus}
			onBlur={handleBlur} />
	)
})

export default SocialSecurityInput;

const CustomInput = styled.input`
	width: ${props => props.isOver ? `20px` : `17.78px`};

	margin: 0 0 0 0;
	padding: ${props => props.isOver ? `5px 0 5px 0` : `4.44px 0 4.44px 0`};

	font-family: Poppins-Medium;
	font-size: 14px;

  color: #000000;
  text-align: center;

  border: 1px solid #CBCBCB;
  border-radius: 5px;
	
  transition: all 0.3s ease-in-out;

	:focus {
		outline: none;
		border: 1px solid #FF8B48;
	}
`;