import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

import ViewOnPath from '../../image/ViewOn.png';
import ViewOffPath from '../../image/ViewOff.png';

const AuthenticationInput = forwardRef((props, ref) => {
	const { className, placeholder, isOver, clickCountFn } = props;
	const [value, setValue] = useState('');
	const [changed, setChanged] = useState(false);
	const [clickView, setClickView] = useState(true);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const TopLevelRef = useRef();
	const inputRef = useRef();
	const ViewButtonRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#FF3131';
			ViewButtonRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#E8E8E8';
			ViewButtonRef.current.style.borderColor = '#E8E8E8';
		} 
	}));

	useEffect(() => {
		// if (value === '') clickCountFn(className, clickCount);
		clickCountFn(className, clickCount);
	}, [clickCount, value])

	function handleClick(e) {
		// inputRef.current.style.borderColor = '#FF8B48';
		// ViewButtonRef.current.style.borderColor = '#FF8B48';
		TopLevelRef.current.style.borderColor = '#FF8B48';
		setDefaultPlaceholder('');
	}

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		// inputRef.current.style.borderColor = '#E8E8E8';
		// ViewButtonRef.current.style.borderColor = '#E8E8E8';
		TopLevelRef.current.style.borderColor = '#E8E8E8';
		// if (value === "") {
		// 	setDefaultPlaceholder(placeholder);
		// 	inputRef.current.style.borderColor = '#FF3131';
		// 	ViewButtonRef.current.style.borderColor = '#FF3131';
		// }
		// else {
		// 	inputRef.current.style.borderColor = '#E8E8E8';
		// 	ViewButtonRef.current.style.borderColor = '#E8E8E8';
		// }
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<Input
				ref={inputRef}
				isOver={isOver}
				clickCount={clickCount}
				type={clickView ? 'password' : 'text'}
				placeholder={defaultPlaceholder}
				value={value}
				className={`${className} ${changed ? "changed" : ""}`}
				onClick={handleClick}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur} />
			<ViewButtonWrapper ref={ViewButtonRef} isOver={isOver} onClick={() => (clickView ? setClickView(false) : setClickView(true))}>
				<ViewIcon src={clickView ? ViewOnPath : ViewOffPath} isOver={isOver}/>
				<ViewButton clickView={clickView} isOver={isOver}>{clickView ? '비밀번호 보기' : '비밀번호 끄기'}</ViewButton>
			</ViewButtonWrapper>
		</TopLevelWrapper>
	)
});

export default AuthenticationInput;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: row;

	align-items: flex-end;

	border: solid;
	border-width: 0 0 1px 0;
	border-color: #E8E8E8;
  transition: all 0.5s ease-in-out;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: ${props => props.isOver ? `14px` : `10px`};
	// line-height: 20px;
	
	padding: 0 0 4px 0;
	margin-top: 16px;

	width: ${props => props.isOver ? `345px` : `200px`};

	outline: none;
	
	border: 0px;
	color: #000000;

	::placeholder {
		color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
	}
	
  transition: all 0.5s ease-in-out;
`;

const ViewButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: center;
	align-items: center;

	width: ${props => props.isOver ? `105px` : `76px`};
	
	padding: 0 0 4px 0;

	border: 0px;
	// border: solid;
	// border-width: 0 0 1px 0;
	// border-color: #E8E8E8;

  transition: all 0.5s ease-in-out;

	:hover {
		cursor: pointer;
	}
`;

const ViewIcon = styled.img`
	width: ${props => props.isOver ? `20px` : `14px`};
	height: ${props => props.isOver ? `20px` : `14px`};

	margin: 0 4px 0 0;
`;

const ViewButton = styled.p`
	font-family: ${props => props.clickView ? `NotoSansKR-Regular` : `NotoSansKR-Bold`};
	font-size: ${props => props.isOver ? `14px` : `10px`};
	// line-height: 20px;

	margin: 0 0 0 0;

	color: ${props => props.clickView ? `#FF8B48` : `#000000`};
`;