import { React, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

import CalcDate from '../../Share/Normal/CalcDate';
import moment from 'moment';

const LockerUsingStopModal = forwardRef((props, ref) => {
  const { isOver, exitClick, saveClick, data } = props;

  const InputRef = useRef();

  useImperativeHandle(ref, () => ({
		getValue: () => {
      return InputRef.current.getValue();
    }
	}));

  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  useEffect(() => {

    if (data.state === 'Empty') {
      data.isAvailable = true;
      data.isUsed = false;
      data.state = '비어있음';
      data.days = '';
      data.btnText = '사용 시작';
      // data = Object.assign(data, {days: dDay, btnText: '사용 시작'});
    }
    else if (data.state === 'Using') {
      data.isAvailable = true;
      data.isUsed = true;
      data.state = '사용중'
      data.days = CalcDate.getDday(data.endDate);
      data.btnText = '사용 종료';
      // data = Object.assign(data, {days: dDay, btnText: '사용 종료'});
    }
    else if (data.state === 'Invalid') {
      data.isAvailable = false;
      data.isUsed = false;
      data.state = '사용불가'
      data.days = '';
      data.btnText = '잠금 해제';
      // data = Object.assign(data, {days: '', btnText: '잠금 해제'});
    }

  }, [data])


  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
        <Title>사용 종료</Title>
        <ExplanationWrapper>
          <Explanation>현재 {data.userName} 회원님이 사용중인 락커 사용을 종료할까요?</Explanation>
          <Explanation>현재 {moment(data.endDate).isAfter(moment()) ? data.days + '일 남았습니다.' : data.days + '일 초과 사용중입니다.'}</Explanation>
        </ExplanationWrapper>
        <ButtonWrapper>
          <ExitButton onClick={exitClick}>취소</ExitButton>
          <SaveButton onClick={saveClick}>종료하기</SaveButton>
        </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
})

export default LockerUsingStopModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `401px` : `290px`};
  height: ${props => props.isOver ? `217px` : `190px`};

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 7px;
  margin-top: 13px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
  margin-top: 25px;
  margin-left: ${props => props.isOver ? `157px` : `120px`};
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `72px` : `55px`};
  height: ${props => props.isOver ? `38px` : `34px`};

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #CBCBCB;

  transition: all 0.2s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `78px`};
  height: ${props => props.isOver ? `40px` : `36px`};

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;