import { React, useEffect, useRef } from 'react';
import styled from 'styled-components';

import NotFoundRingRingPath from '../../../image/NotFoundRingRing.png';

export default function NotRegistrationCardModal(props) {
  const { division, setIsViewModal, onClickRegistCard } = props;

  const TopLevelRef = useRef();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

	return (
    <Background>
      <TopLevelWrapper ref={TopLevelRef}>
        <LogoWrapper>
          <NotFoundRingRing src={NotFoundRingRingPath}/>
        </LogoWrapper>
        <ExplanationWrapper>
          <TextWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#000000'>카드 정보를 입력해 주세요</Text>
          </TextWrapper>
          <TextWrapper style={{ marginTop: `4px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#000000'>아직 카드정보를 입력하지 않았습니다</Text>
          </TextWrapper>
          <TextWrapper style={{ marginTop: `4px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#000000'>구독은 언제든지 취소할 수 있습니다!</Text>
          </TextWrapper>
        </ExplanationWrapper>
        <ButtonWrapper isNotice={division === 'Notice'}>
          {
            division === 'Notice' &&
              <ExitButton onClick={() => setIsViewModal(false)}>
                <ButtonText>돌아가기</ButtonText>
              </ExitButton>
          }
          <GoSettingButton isNotice={division === 'Notice'} onClick={onClickRegistCard}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#FFFFFF'>입력하기</Text>
          </GoSettingButton>
        </ButtonWrapper>
      </TopLevelWrapper>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 36px 0px 36px 0px;
  width: 80%;
	max-width: 476px;
  // height: 30%;
	// max-height: 298px;
  
	// border-radius: 50px;
	border-radius: 14px;
  background-color: #FFFFFF;

  transition: all 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  // margin-top: -118px;
  margin-top: -128px;

`;

const NotFoundRingRing = styled.img`
  // width: 280px;
  // height: 178px;

  // width: 473px;
  // height: 313px;
  width: 208px;
  height: 138px;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  // margin-top: 109px;
  // margin-top: 84px;

  width: 90%;
  // height: 100px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // margin-top: 24px;
  margin-top: ${props => props.isNotice ? `68px` : `38px`};

  width: 80%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 47%;
  height: 45px;

  border-radius: 23px;
  background-color: #6DD49E;

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
    background: #2BCB67;
  }
`;

const GoSettingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: ${props => props.isNotice ? `47%` : `100%`};
  width: 100%;
  height: 45px;

  border-radius: 23px;
  background-color: #FF8B48;

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
    background: #FF7322;
  }

  @media (min-height: 320px) {
    height: 28px;

    border-radius: 8px;
  }
  @media (min-height: 640px) {
    margin-top: 0px;
  }
  @media (min-height: 680px) {
    margin-top: -4px;
  }
  @media (min-height: 720px) {
    margin-top: 0px;
  }
  @media (min-height: 800px) {
    margin-top: -16px;
  }
  @media (min-height: 1000px) {
    margin-top: -18px;
  }
  @media (min-height: 1200px) {
    margin-top: -18px;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 18px;
  
  color: #FFFFFF;

  margin: 0 0 0 0;
`;