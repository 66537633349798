import { React, useRef } from 'react';
import styled from 'styled-components';

function TotalTitleInputBox(props) {
  const { value, setValue } = props;

  const InputRef = useRef();

  function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
    
	}

	function handleBlur(e) {

	}

  return (
    <TopLevelWrapper>
      <Input
        ref={InputRef}
        type='text'
        placeholder='작성이 필요합니다.'
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  )
}

export default TotalTitleInputBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: calc(97.71% - 34px);
  height: 34px;

  margin-top: 8px;

  padding-right: 16px;
  padding-left: 16px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const Input = styled.input`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
  
  border: 0px;

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  outline: none;

  ::placeholder {
    color: #CBCBCB;
  }

  transition: all 0.3s ease-in-out;
`;