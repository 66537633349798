import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import NoticeList from './NoticeList';
import InfoPagination from '../../../Component/MemberComponent/InfoPagination';

import FetchModule from '../../Share/Network/FetchModule';
import useWindowSize from '../../Share/Normal/useWindowSize';

function NoticeListView() {
  const history = useHistory();
  const size = useWindowSize();

  const [data, setData] = useState([]);
  const [nowPage, setNowPage] = useState(0);
  const [useData, setUseData] = useState([]);
  const [isOver , setIsOver] = useState(false);

  useEffect(() => {

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('notice/', 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) setData(responseData.data);
        else if (responseData.status === 401) history.push('/');
        else if (responseData.status !== 204) {}
      }
    )();
  }, [history])

  useEffect(() => {
    var newDataList = data.slice((nowPage * 10), (nowPage * 10) + 9);
    setUseData(newDataList);
  }, [nowPage, data])

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  function addNoticeClick() {
    history.push('/notice/create');
  }
  
  function onClickNextPage() {
    if (nowPage + 1 < Math.ceil(data.length/20)) setNowPage(nowPage + 1);
  }

  function onClickPrevPage() {
    if ((nowPage - 1) >= 0) setNowPage(nowPage - 1);  
  }

  return (
    <TopLevelWrapper>
      <TopBarWrapper isOver={isOver}>
        <TextWrapper>
          <Title isOver={isOver}>공지</Title>
          <Explanation isOver={isOver}>일정, 회원관리 등을 공지하고 확인할 수 있어요</Explanation>
        </TextWrapper>
        <ButtonWrapper>
          <AddButton isOver={isOver} onClick={addNoticeClick}>공지 추가</AddButton>
        </ButtonWrapper>
      </TopBarWrapper>
      <NoticeList data={useData} isOver={isOver}/>
      <PaginationWrapper>
        <InfoPagination 
          nowPage={nowPage + 1} 
          totalPage={Math.ceil(data.length/20)}
          nextClickFn={onClickNextPage}
          prevClickFn={onClickPrevPage}/>
      </PaginationWrapper>
    </TopLevelWrapper>
  )
}

export default NoticeListView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: ${props => props.isOver ? `max(664px, 86.45833333333333vw)` : `max(282px, 88.125vw)`};

  margin-left: ${props => props.isOver ? `28px` : `14px`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const AddButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `63px`};
  height: ${props => props.isOver ? `40px` : `29px`};

  border-radius: ${props => props.isOver ? `15px` : `5px`};
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-bottom: 96px;
`;