import { React, useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import styled from 'styled-components';

export default function LoadingWodList(props) {
  const { useData, clickDate, clickWorkout, setClickWorkout } = props;

  const TotalRef = useRef([]);
  const ColumnRef = useRef([]);
  const ColumnDayRef = useRef([]);
  const ExerciseRef = useRef([]);

  // 컴포넌트 클릭 컬러 변경 //
  useEffect(() => {
    console.log(useData);
    (
      async () => {
        for (var i = 0; i < useData.length; i++) {
          if (useData[i].workoutId !== clickWorkout.workoutId) {
            ColumnRef.current[i].style.fontFamily = 'NotoSansKR-Regular';
            ColumnRef.current[i].style.color = '#777777';

            ColumnDayRef.current[i].style.fontFamily = 'NotoSansKR-Regular';
            ColumnDayRef.current[i].style.color = '#CBCBCB';

            ExerciseRef.current[i].style.borderColor = '#DFDFDF';
            ExerciseRef.current[i].style.fontFamily = 'NotoSansKR-Regular';
            ExerciseRef.current[i].style.color = '#595959';
          }
          else {

            ColumnRef.current[i].style.fontFamily = `NotoSansKR-Medium`;
            ColumnRef.current[i].style.color = `#FF8B48`;

            ColumnDayRef.current[i].style.fontFamily = 'NotoSansKR-Medium';
            ColumnDayRef.current[i].style.color = '#FF8B48';

            ExerciseRef.current[i].style.borderColor = '#FF8B48';
            ExerciseRef.current[i].style.fontFamily = 'NotoSansKR-Medium';
            ExerciseRef.current[i].style.color = '#232323';
          }
        }
      }
    )();

  }, [clickWorkout, useData])

  // 컴포넌트 호버링 //
  function onMouseOver(index) {
    if (useData[index].workoutId === clickWorkout.workoutId) return;

    ColumnRef.current[index].style.fontFamily = `NotoSansKR-Medium`;
    ColumnRef.current[index].style.color = `#FF8B48`;

    ColumnDayRef.current[index].style.fontFamily = 'NotoSansKR-Medium';
    ColumnDayRef.current[index].style.color = '#FF8B48';

    ExerciseRef.current[index].style.borderColor = '#FF8B48';
    ExerciseRef.current[index].style.fontFamily = 'NotoSansKR-Medium';
    ExerciseRef.current[index].style.color = '#232323';
  }

  // 컴포넌트 호버링 //
  function onMouseLeave(index) {
    if (useData[index].workoutId === clickWorkout.workoutId) return;

    ColumnRef.current[index].style.fontFamily = 'NotoSansKR-Regular';
    ColumnRef.current[index].style.color = '#777777';

    ColumnDayRef.current[index].style.fontFamily = 'NotoSansKR-Regular';
    ColumnDayRef.current[index].style.color = '#CBCBCB';

    ExerciseRef.current[index].style.borderColor = '#DFDFDF';
    ExerciseRef.current[index].style.fontFamily = 'NotoSansKR-Regular';
    ExerciseRef.current[index].style.color = '#595959';
  }

  return (
    <TopLevelWrapper>
      <ListBox>
        {
          useData.map((data, index) => (
            <ComponentWrapper
              key={index}
              ref={element => (TotalRef.current[index] = element)}
              onMouseOver={() => onMouseOver(index)}
              onMouseLeave={() => onMouseLeave(index)}
              onClick={() => setClickWorkout(data)}>
              <ColumnWrapper>
                <Column
                  ref={element => (ColumnRef.current[index] = element)}
                  isClicked={data.workoutId === clickWorkout.workoutId}>
                  {data.classDate.format('DD일')}
                </Column>
                <ColumnDay
                  ref={element => (ColumnDayRef.current[index] = element)}
                  isClicked={data.workoutId === clickWorkout.workoutId}>
                  {data.classDate.format('(dd)')}
                </ColumnDay>
              </ColumnWrapper>
              <Exercise
                ref={element => (ExerciseRef.current[index] = element)}
                isClicked={data.workoutId === clickWorkout.workoutId}>
                <ExerciseTextBox>
                  {data.title === '' ? '작성이 필요합니다.' : data.title}
                </ExerciseTextBox>
              </Exercise>
            </ComponentWrapper>
          ))
        }
      </ListBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  gap: 8px;

  // width: 96.31%;
  width: 100%;
  height: 320px;

  margin-top: 25px;
  padding-top: 20px;

  border-top: 1px solid #ECECEC;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  :hover {
    cursor: pointer;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 3px;
`;

const Column = styled.p`
  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 12px;

  color: ${props => props.isClicked ? `#FF8B48` : `#777777`};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
`;

const ColumnDay = styled.p`
  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 12px;

  color: ${props => props.isClicked ? `#FF8B48` : `#CBCBCB`};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
`;

const Exercise = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: calc(83.52% - 34px);
  width: calc(80% - 34px);
  height: 36px;

  padding: 0px 16px 0px 16px;
  margin-right: 4px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 14px;
  color: ${props => props.isClicked ? `#232323` : `#595959`};

  transition: all 0.3s ease-in-out;
`;

const ExerciseTextBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
  
  width: 100%;
  height: 20px;

  overflow: hidden;
`;