import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

const SignUpIdInput = forwardRef((props, ref) => {
	const { className, placeholder, type, clickCountFn, clickFn, text, isClicked, setIsClicked } = props;

	const [value, setValue] = useState('');
	const [changed, setChanged] = useState(false);
	const [message, setMessage] = useState(text);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const TopLevelRef = useRef();
	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			// inputRef.current.style.borderColor = '#FF3131';
			TopLevelRef.current.style.borderBottom = `1px solid #FF3131`;
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			// inputRef.current.style.borderColor = '#E8E8E8';
			TopLevelRef.current.style.borderBottom = `1px solid #E8E8E8`;
		}
	}));

	useEffect(() => {
		clickCountFn(className, clickCount);
	}, [clickCount, value])

	function handleClick(e) {
		// inputRef.current.style.borderColor = '#FF8B48';
		TopLevelRef.current.style.borderBottom = `1px solid #FF8B48`;
		setDefaultPlaceholder('');
	}

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
		setIsClicked(false);
	}

	function handleFocus(e) {
		// if (changed) {
		// 	// do nothing
		// } else {
		// 	setValue("");
		// }
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		// inputRef.current.style.borderColor = '#E8E8E8';
		TopLevelRef.current.style.borderBottom = `1px solid #E8E8E8`;
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<Input
				ref={inputRef}
				clickCount={clickCount}
				type={type}
				tabIndex={-1}
				placeholder={defaultPlaceholder}
				value={value}
				className={`${className} ${changed ? "changed" : ""}`}
				onClick={handleClick}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur} />
				<CheckButton onClick={clickFn} isClicked={isClicked}>
					<CheckMessage>
						{ isClicked ? `확인완료` : `중복확인`}
					</CheckMessage>
				</CheckButton>
		</TopLevelWrapper>
	)
});

export default SignUpIdInput;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: row;

	align-items: flex-end;
	
	border-bottom: 1px solid #E8E8E8;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: max(12px, 0.7291666666666667vw);
	
	padding: 0 0 4px 0;
	margin-top: max(12px, 0.8333333333333333vw);

	width: 80%;

	outline: none;
	border: 0px;
	color: #000000;

	::placeholder {
		color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
	}
	
  transition: all 0.5s ease-in-out;
`;

const CheckButton = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: center;
	align-items: center;

	width: 20%;
	height: 32px;

	border-radius: 8px 8px 0 0;
	background-color: ${props => props.isClicked ? `#E8E8E8` : `#FF8B48`};

	:hover {
		cursor: pointer;
	}
`;

const CheckMessage = styled.p`
	font-family: NotoSansKR-Bold;
	font-size: 12px;
	
	color: #FFFFFF;
`;