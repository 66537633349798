import { React, useEffect, useState } from 'react';
import styled from 'styled-components';

import RegistrationView from '../../Model/Individual/MemberModel/RegistrationView.js';
import UnSupportedDeviceModal from '../../Model/Individual/MemberModel/MemberDetail/Modal/UnsupportedDeviceModal.js';

import useWindowSize from '../../Model/Share/Normal/useWindowSize.js';

function MemberRegistrationPage() {
  const size = useWindowSize();

  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    if (size.width >= 768) setIsOver(false);
    else setIsOver(true);
  }, [size])

  return (
    <Container>
      { isOver && <UnSupportedDeviceModal isModal={false}/>}
      <RegistrationView/>
    </Container>
  )
}

export default MemberRegistrationPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  // width: 100%;
`;