import { React, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

const RejectTopView = forwardRef((props, ref) => {
  const { isOver, settingWaittingView, waittingCnt, ignoreCnt } = props;

  const TopLevelRef = useRef();
  const RejectDisplayRef = useRef();
  const WaitterDisplayRef = useRef();

  useImperativeHandle(ref, () => ({
    setZindex: (val) => {
      TopLevelRef.current.style.zIndex = val;
    }
  }));

  // 마우스 호버링 이벤트
  function onMouseOver(className) {
    if (className === 'Reject') {
      RejectDisplayRef.current.style.color = `#FFFFFF`;
      RejectDisplayRef.current.style.backgroundColor = `#777777`;
    }
    else if (className === 'Wait') {
      WaitterDisplayRef.current.style.color = `#FFFFFF`;
      WaitterDisplayRef.current.style.backgroundColor = `#FF8B48`;
    }
  } 
  
  // 마우스 호버링 이벤트
  function onMouseLeave(className) {
    if (className === 'Reject') {
      RejectDisplayRef.current.style.color = `#FFFFFF`;
      RejectDisplayRef.current.style.backgroundColor = `#DFDFDF`;
    }
    else if (className === 'Wait') {
      WaitterDisplayRef.current.style.color = `#FFFFFF`;
      WaitterDisplayRef.current.style.backgroundColor = `#5BD98A`;
    }
  }

  function onClick(className) {
    settingWaittingView(className);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
      <TitleWrapper isOver={isOver}>
        <Title isOver={isOver}>등록 거절</Title>
        <Explanation isOver={isOver}>등록 거절중인 상태의 회원정보를 볼 수 있어요</Explanation>
      </TitleWrapper>
      <TotalButtonWrapper isOver={isOver}>
        <ButtonWrapper className='Reject' onClick={() => onClick('Reject')} onMouseOver={() => onMouseOver('Reject')} onMouseLeave={() => onMouseLeave('Reject')}>
          <CountDisplay ref={RejectDisplayRef} backgroundColor='#DFDFDF' color='#A8A8A8' isOver={isOver}>{ignoreCnt}</CountDisplay>
          <RejectButton isOver={isOver}>
            <ButtonText isOver={isOver}>등록 거절 회원</ButtonText>
          </RejectButton>
        </ButtonWrapper>
        <ButtonWrapper className='Wait' onClick={() => onClick('Wait')} onMouseOver={() => onMouseOver('Wait')} onMouseLeave={() => onMouseLeave('Wait')}>
          <CountDisplay ref={WaitterDisplayRef} backgroundColor='#5BD98A' color='#FFFFFF' isOver={isOver}>{waittingCnt}</CountDisplay>
          <WaitterButton isOver={isOver}>
            <ButtonText isOver={isOver}>대기중인 회원</ButtonText>
          </WaitterButton>
        </ButtonWrapper>
      </TotalButtonWrapper>
    </TopLevelWrapper>
  )
})

export default RejectTopView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};
  
  justify-content: ${props => props.isOver ? `space-between` : `center`};
  align-items: center;

  margin-left: ${props => props.isOver ? `max(28px, 3vw)` : `0px`};
  margin-bottom: 36px;

  width: ${props => props.isOver ? `max(664px, 86.45833333333333vw)` : `max(270px, 84.375vw)`};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: ${props => props.isOver ? `` : `100%`};

  margin-left: ${props => props.isOver ? `0px` : `max(8px, 2.5px)`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const TotalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `22px` : `14px`};
  margin-top: ${props => props.isOver ? `0px` : `15px`};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const CountDisplay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `28px` : `15px`};
  height: ${props => props.isOver ? `28px` : `15px`};

  z-index: 3;

  margin-top: ${props => props.isOver ? `-13px` : `-8px`};
  margin-left: ${props => props.isOver ? `-13px` : `-8px`};

  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: ${props => props.color};

  border-radius: ${props => props.isOver ? `28px` : `15px`};
  background-color: ${props => props.backgroundColor};
  
  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: default;
  }
`;

const RejectButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `99px` : `81px`};
  height: ${props => props.isOver ? `38px` : `25px`};

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    // background-color: #777777;
  }
`;

const WaitterButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `99px` : `81px`};
  height: ${props => props.isOver ? `38px` : `25px`};

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
    // background-color: #FF8B48;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;