import { React, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

function MyZonePaymentInfo(props) {
  const { data } = props;

  return (
    <TopLevelWrapper>
      <Table>
        <Body>
          <Tr style={{ width: '128px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '128px' }}>
                <Text>1997.04.21</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '110px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '110px' }}>
                <Text>안형진</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '138px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '138px' }}>
                <Text>정기</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '221px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '221px' }}>
                <Text>크로스핏 1개월</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '188px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '188px' }}>
                <Text>1,000,000</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '286px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '286px' }}>
                <Text>D+28</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '40px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '40x' }}>
                <Text>미승인</Text>
              </TextWrapper>
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

export default MyZonePaymentInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 1189px;
  height: 50px;

  background: #FFFFFF;

  margin-top: 20px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;

  width: 100%;

  margin-left: 28px;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;
