import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import CalenderInput from '../../../Component/LockerComponent/LockerCalenderInput';

export default function LockerExtendsModal(props) {
  const { data, saveFn, closeFn } = props;

  // const [days, setDays] = useState(0);
  const [endDate, setEndDate] = useState(moment());

  // const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();

  useEffect(() => {
    // setDays(data.days);
    setEndDate(moment(data.endDate));
  }, [data])

  // 달력 클릭시 //
  function CalenderClick(className) {
    // if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    // else StartCalenderRef.current.closeCalender();
  }

  return (
    <TopLevelWrapper>
      <Modal>
        <ComponentWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>사용 연장</Text>
          <LayerWrapper style={{ marginTop: `15px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>락커 번호</Text>
            <InputBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>{data.lockerName}</Text>
            </InputBox>
          </LayerWrapper>
          <LayerWrapper style={{ marginTop: `13px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>회원 이름</Text>
            <InputBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>{data.userName}</Text>
            </InputBox>
          </LayerWrapper>
          <LayerWrapper style={{ marginTop: `13px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>남은 일수</Text>
            <DdayInputWrapper>
              <DdayInputBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>{moment(endDate).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD') ? (moment(endDate).diff(moment(), 'days') + 1) : '일수 초과'}</Text>
              </DdayInputBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>일</Text>
            </DdayInputWrapper>
          </LayerWrapper>
          <LayerWrapper style={{ marginTop: `13px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>종료일</Text>
            <CalenderInput ref={EndCalenderRef} value={endDate} setValue={setEndDate} disabled={false} className='EndCalender' clickFn={CalenderClick} placeholder='종료일' width={181} />
          </LayerWrapper>
          <ButtonWrapper>
            <ExitButton onClick={closeFn}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' hover='pointer'>취소</Text>
            </ExitButton>
            <SaveButton onClick={() => saveFn(endDate)}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>저장하기</Text>
            </SaveButton>
          </ButtonWrapper>
        </ComponentWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);

  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 323px;
  height: 337px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 82.66%;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 8px 0px 8px;

  width: calc(68.53% - 18px);
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;
`;

const DdayInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 68.53%;

  // gap: 5px;
`;

const DdayInputBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 8px 0px 8px;

  width: calc(90.71% - 18px);
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;

  gap: 16px;

  margin-top: 36px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }

  transition: all 0.3s ease-in-out;
`;



const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;