import { React } from 'react';
import styled from 'styled-components';

function HoldingHistoryColumn(props) {
  const { isOver } = props;

  return (
    <TopLevelWrapper isOver={isOver}>
      <Column isOver={isOver} style={isOver ? { marginRight: 'max(115px, 14.9739583333vw)', marginLeft: '28px' } : { marginRight: 'max(71px, 22.1875vw)', marginLeft: '2px' }}>기간</Column>
      <Column isOver={isOver} style={isOver ? { marginRight: 'max(42px, 5.46875vw)' } : { marginRight: 'max(9px, 2.8125vw)' }}>적용일수</Column>
      <Column isOver={isOver} style={isOver ? { marginRight: 'max(192px, 25vw)' } : { marginRight: 'max(24px, 7.5vw)' }}>회원권</Column>
      <Column isOver={isOver} >상태</Column>
    </TopLevelWrapper>
  )
}

export default HoldingHistoryColumn;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  
  // justify-content: center;
  align-items: center;

  // width: 1020px;

  width: ${props => props.isOver ? `max(690px, 89.84375vw)` : `max(262px, 81.875vw)`};

  margin-top: 34px;
  margin-bottom: 20px;
  
  transition: all 0.3s ease-in-out;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;
