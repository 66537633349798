import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';

export default function DeepLinkNotification({match}) {
  useEffect(() => {

    window.localStorage.setItem("Token", match.params.accessToken);

    let launchAppUrl = "linkcoach://notification/" + match.params.noticeId + '/' + match.params.storeId;
    let timer;
    let interval;
    let userAgent = navigator.userAgent.toLowerCase();
    let isAndroid = userAgent.search('android') > -1;
    let isIOS = !isAndroid && (/iphone|ipad|ipod/i.test(userAgent));
    let os;

    let mobile = (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));

      // 인터벌, 타이머 삭제
      function clearTimer() {
        clearInterval(interval);
        clearTimeout(timer);
      }

      window.addEventListener('visibilitychange', clearTimer);

    // if (mobile) {
    //   if (userAgent.search("android") > -1) os = "android";
    //   else if ((userAgent.search("iphone") > -1) || (userAgent.search("ipod") > -1) || (userAgent.search("ipad") > -1)) os = "ios";
    //   else os = "otehr";
    // }
    // else os = "pc";

    // if (os === 'pc') {
    //   alert('모바일에서 이용해주세요');

    //   setTimeout(() => (
    //     window.open("about:blank", "_self"),
    //     window.close()
    //   ), 5000)
    // }
    // else {

    //   // 인터벌 마다 동작할 기능
    //   function intervalSch() {
    //     // 매 인터벌 마다 웹뷰가 활성화 인지 체크
    //     if (document.webkitHidden || document.hidden) { // 웹뷰 비활성화
    //       clearTimer(); // 앱이 설치되어있을 경우 타이머 제거
    //     } else {	// 웹뷰 활성화
    //       console.log("타이머 동작");
    //     }
    //   }

    //   // 앱 실행(iOS인 경우)
    //   window.location.href = launchAppUrl;

    //   // 앱이 설치 되어있는지 체크
    //   interval = setInterval(intervalSch, 500);

    //   timer = setTimeout(function () {
    //     if (isAndroid) window.location.href = "https://play.google.com/store/apps/details?id=com.linkcoach&pli=1";
    //     else if (isIOS) window.location.href = "https://apps.apple.com/kr/app/link-coach/id1601345842";

    //     clearInterval(interval);
    //   }, 2000);

    //   setTimeout(() => (
    //     window.open("about:blank", "_self"),
    //     window.close()
    //   ), 5000)
    // }

      // 인터벌 마다 동작할 기능
      function intervalSch() {
        // 매 인터벌 마다 웹뷰가 활성화 인지 체크
        if (document.webkitHidden || document.hidden) { // 웹뷰 비활성화
          clearTimer(); // 앱이 설치되어있을 경우 타이머 제거
        } else {	// 웹뷰 활성화
          console.log("타이머 동작");
        }
      }

      // 앱 실행(iOS인 경우)
      window.location.href = launchAppUrl;

      // 앱이 설치 되어있는지 체크
      interval = setInterval(intervalSch, 500);

      timer = setTimeout(function () {
        if (isAndroid) window.location.href = "https://play.google.com/store/apps/details?id=com.linkcoach&pli=1";
        else if (isIOS) window.location.href = "https://apps.apple.com/kr/app/link-coach/id1601345842";
        else window.location.href = "https://play.google.com/store/apps/details?id=com.linkcoach&pli=1";
        
        clearInterval(interval);
      }, 2000);

      setTimeout(() => (
        window.open("about:blank", "_self"),
        window.close()
      ), 5000)

    return () => {
      window.removeEventListener('visibilitychange', clearTimer);
    }
  }, [])

  return <></>;
}