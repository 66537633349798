import { React } from 'react';
import styled from 'styled-components';

function UserNameChangeInput(props) {
  const { value, setValue, placeholder } = props;
  
  function handleChange(e) {
		setValue(e.target.value);
  }

  return (
    <TopLevelWrapper>
      <Input
        placeholder={placeholder}
        value={value || ''}
        onChange={handleChange} />
    </TopLevelWrapper>
  )
}

export default UserNameChangeInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justfy-content: center;
  align-items: center;

  width: 100%;
  height: 32px;
`;

const Input = styled.input`
  width: calc(100% - 18px);
  height: calc(100% - 2px);

  font-family: NotoSansKR-Regular;
  font-size: 14px;
  color: #232323;

  padding: 0 8px 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  outline: none;

  background-color: ${props => props.disabled ? '#ECECEC' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  ::placeholder {
    color: #CBCBCB;
  }
`;
