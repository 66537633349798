import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import UpButtonArrowImagePath from '../../../image/UpButtonArrow.png';
import DownButtonArrowImagePath from '../../../image/DownButtonArrow.png';

const DayCountInput = forwardRef((props, ref) => {
  const { isOver, disabled, changeFn } = props;

  const [value, setValue] = useState(0);
  
  const InputRef = useRef();

  useImperativeHandle(ref, () => ({
		getValue: () => {
			return value;
		}
	}));

  useEffect(() => {
    changeFn(value);
  }, [value])

  function handleChange(e) {
    const curValue = e.target.value
		const newValue = curValue.replace(/[^0-9]/g, '')

    if (newValue < 9999999)
		  setValue(newValue);
  }

  function handleFocus() {
    InputRef.current.style.border = `1px solid #FF8B48`;
  }

  function handleBlur() {
    InputRef.current.style.border = `1px solid #DFDFDF`;
  }

  function upClick() {
    if (disabled) return;

    if (value < 9999999) setValue(parseInt(value) + 1)
    else setValue(0);
  }

  function downClick() {
    if (disabled) return;

    if (value > 0) setValue(parseInt(value) - 1)
  }

  return (
    <TopLevelWrapper>
      {
        isOver ?
          <TotalWrapper isOver={isOver}>
            <LayerWrapper>
              <Title isOver={isOver}>연장 일수</Title>
              <Input
                ref={InputRef}
                placeholder='0'
                value={value || ''}
                isOver={isOver}
                disabled={disabled}
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur} />
            </LayerWrapper>
            <LayerWrapper>
              <Text isOver={isOver}>일</Text>
            </LayerWrapper>
            <LayerWrapper>
              <ButtonWrapper>
                <Button img={UpButtonArrowImagePath} isOver={isOver} onClick={upClick} />
                <Button img={DownButtonArrowImagePath} isOver={isOver} onClick={downClick} />
              </ButtonWrapper>
            </LayerWrapper>
          </TotalWrapper>
          :
          <TotalWrapper isOver={isOver}>
            <Title isOver={isOver}>연장 일수</Title>
            <LayerWrapper>
              <Input
                ref={InputRef}
                placeholder='0'
                value={value || ''} 
                isOver={isOver}
                disabled={disabled}
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur} />
              <Text isOver={isOver}>일</Text>
              <ButtonWrapper>
                <Button img={UpButtonArrowImagePath} isOver={isOver} onClick={upClick} />
                <Button img={DownButtonArrowImagePath} isOver={isOver} onClick={downClick} />
              </ButtonWrapper>
            </LayerWrapper>
          </TotalWrapper>
      }
      
    </TopLevelWrapper>
  )
})

export default DayCountInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // margin: 0 0 0 28px;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: ${props => props.isOver ? `0 16px 0 0` : `0 0 4px 0`};
`;

const Input = styled.input`
  width: ${props => props.isOver ? `61px` : `71px`};
  height: ${props => props.isOver ? `30px` : `24px`};

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 8px 0 0;
  padding: 0 0 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  outline: none;

  color: #CBCBCB;
  background-color: ${props => props.disabled ? '#ECECEC' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  ::placeholder {
    color: #CBCBCB;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: ${props => props.isOver ? `0 8px 0 0` : `0 22px 0 4px`};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
  // margin-top: 24px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `30px` : `24px`};
  height: ${props => props.isOver ? `30px` : `24px`};

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  background: url(${props => props.img}) no-repeat center center;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;