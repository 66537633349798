import { React } from 'react';
import styled from 'styled-components';

import Input from '../../../Component/MySettingPageComponent/InfoInput';
import InputView from '../../../Component/MySettingPageComponent/InputView';
import PasswordInputView from '../../../Component/MySettingPageComponent/PasswordInputView';

function MyAccountView(props) {
  const { isOver, onClickSubscribeButton, isClicked, id, email, owenrName, setOwnerName, phone, setPhone } = props;

  return (
    <TopLevelWrapper>
      <InfoView isOver={isOver}>
        <TitleWrapper isOver={isOver}>
          <Title>계정 정보</Title>
        </TitleWrapper>
        {
          isClicked ?
            <InfoWrapper isOver={isOver}>
              <LayerWrapper isOver={isOver} topMargin={24}>
                <Input isOver={isOver} column='관리자 성함' defaultVal={owenrName} setVal={setOwnerName} width={isOver ? 'max(290px, 15.10416666666667vw)' : 'max(238px, 12.39583333333333vw)'}/>
                <InputView isOver={isOver} column='아이디' content={id} />
                <PasswordInputView  isOver={isOver} column='패스워드' content='●●●●●●●●●●' /> 
              </LayerWrapper>
              <LayerWrapper isOver={isOver}  topMargin={24}>
                <InputView column='이메일 계정' content={email} />
                <Input column='휴대전화 번호' defaultVal={phone} setVal={setPhone} width={isOver ? 'max(290px, 15.10416666666667vw)' : 'max(238px, 12.39583333333333vw)'}/>
                { isOver && <SubscribeWrapper/> }
              </LayerWrapper>
            </InfoWrapper>
            :
            <InfoWrapper isOver={isOver}>
              <LayerWrapper isOver={isOver}  topMargin={24}>
                <InputView column='관리자 성함' content={owenrName}/>
                <InputView column='아이디' content={id} />
                <InputView column='패스워드' content='●●●●●●●●●●' />
              </LayerWrapper>
              <LayerWrapper isOver={isOver}  topMargin={24}>
                <InputView column='이메일 계정' content={email} />
                <InputView column='휴대전화 번호' content={phone} />
                {
                  isOver &&
                  <SubscribeWrapper>
                    <SubscribeButton onClick={onClickSubscribeButton}>구독하기</SubscribeButton>
                  </SubscribeWrapper>
                }
                </LayerWrapper>
            </InfoWrapper>
        }
      </InfoView>
    </TopLevelWrapper>
  )
}

export default MyAccountView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 34px;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 1191px;
  width: ${props => props.isOver ? `max(692px, 36.0416666vw)` : `max(280px, 14.58333333333333vw)`};
  height: ${props => props.isOver ? `351px` : `480px`};

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 1135px;
  width: ${props => props.isOver ? `max(636px, 33.125vw)` : `max(256px, 13.33333333333333vw)`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: center;
  align-items: center;

  margin-top: 24px;
  border-top: 1px solid #ECECEC;

  // width: 1135px;
  width: ${props => props.isOver ? `max(636px, 33.125vw)` : `max(256px, 13.33333333333333vw)`};
  height: ${props => props.isOver ? `251px` : `393px`};
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: ${props => props.isOver ? `space-between` : `flex-start`};
  align-items: center;

  width: 100%;
  height: 100%;

  gap: ${props => props.isOver ? `0px` : `24px`};
  margin-top: ${props => props.topMargin}px;
`;

const SubscribeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  // margin-top: 25px;
  margin-top: 8px;

  // width: 365px;
  width: max(290px, 15.10416666666667vw);
  height: 50px;

`;

const SubscribeButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 99px;
  height: 32px;

  border-radius: 8px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;