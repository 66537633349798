import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';

const LogoSettingInput = forwardRef((props, ref) => {
  const { changeFn } = props;

	const hiddenFileInput = useRef(null);
  const FileInputRef = useRef();
	const [imgBase64, setImgBase64] = useState();
  const [imgFile, setImgFile] = useState();
  const [imgLocalPath, setImgLocalPath] = useState('');
  
  useImperativeHandle(ref, () => ({
    getImgBase64: () => {
      return imgBase64;
    },
    getValue: () => {
      return imgLocalPath;
    },
    reset: () => {
      setImgBase64();
      setImgFile();
      setImgLocalPath();
    }
  }));

  const handleClick = e => {
    hiddenFileInput.current.click();
    FileInputRef.current.style.borderColor = '#FF8B48';
	};
	
  const handleChange = e => { 
		const reader = new FileReader();
    // setImgLocalPath(e.target.value);
    var tmp = e.target.value.split('\\');
    setImgLocalPath(tmp[tmp.length - 1]);
		reader.onloadend = () => {
			const base64 = reader.result;
      
      FileInputRef.current.style.borderColor = '#E8E8E8';
			if (base64) {
				setImgBase64(base64.toString());
        changeFn(base64.toString());
			}
		};
		if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImgFile(e.target.files[0]);
		}
	};
  
  return (
    <TopLevelWrapper>
			<InputWrapper>
        <input type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{display: 'none'}}/>
        <FileInput ref={FileInputRef}>{imgLocalPath === '' ? 'jpg, png 지원 최대 3MB' : imgLocalPath}</FileInput>
        <InputButton onClick={handleClick}>찾아보기</InputButton>
      </InputWrapper>
    </TopLevelWrapper>
  );
})

export default LogoSettingInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: flex-end;
`;

const FileInput = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 14px;
  line-height: 20px;

  width: 214px;
  height: 25px;

  border: 1px solid;
  border-width: 0 0 1px 0;
  border-color: #E8E8E8;

  overflow: hidden;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;
`;

const InputButton = styled.div`
  display: flex;
  flex-direction: row;

  width: 45px;
  margin-left: 9px;

  font-family: NotoSansKR-Medium;
  font-size: 12px;
  color: #777777;

  :hover {
    cursor: pointer;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: 61px;
`;

const Logo = styled.img`
  width: 80px;
  height: 80px;
`;