import { React, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';

function HoldingHistoryInfoView(props) {
  const { isOver, data, onClickHoldingCancel, onClickDeleteHoldingHistory } = props;

  const CancelRef = useRef();
  const DeleteRef = useRef();

  function mouseOver() {
    if (data.status !== '연장 추가') {
      CancelRef.current.style.opacity = 1;
      DeleteRef.current.style.opacity = 1;
    }
  }

  function mouseLeave() {
    if (data.status !== '연장 추가') {
      CancelRef.current.style.opacity = 0;
      DeleteRef.current.style.opacity = 0;
    }
  }

  return (
    <TopLevelWrapper isOver={isOver} onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      <Table>
        <Body isOver={isOver}>
          <Tr style={isOver ? { width: 'max(133px, 17.3177083333vw)', marginRight: `max(8px, 1.04166666vw)` } : { width: 'max(78px, 24.375vw)', marginRight: `max(5px, 1.5625vw)` }}>
            <Td>
              <TextWrapper style={isOver ? { maxWidth: 'max(133px, 17.3177083333vw)' } : { maxWidth: 'max(78px, 24.375vw)' }}>
                <Text isOver={isOver}>{moment(data.startDate).format('YY.MM.DD') + " - " + moment(data.endDate).format('YY.MM.DD')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={isOver ? { width: 'max(77px, 10.0260416666vw)', marginRight: `max(8px, 1.04166666vw)` } : { width: 'max(41px, 12.8125vw)', marginRight: `max(5px, 1.5625vw)` }}>
            <Td>
              <TextWrapper style={isOver ? { maxWidth: 'max(77px, 10.0260416666vw)' } : { maxWidth: 'max(41px, 12.8125vw)' }}>
                <Text isOver={isOver}>{(moment(data.endDate).diff(data.startDate, 'day') + 1)}일</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={isOver ? { width: 'max(222px, 28.90625vw)', marginRight: 'max(8px, 1.04166666vw)' } : { width: 'max(48px, 15vw)', marginRight: 'max(5px, 1.5625vw)' }}>
            <Td>
              <TextWrapper style={isOver ? { maxWidth: 'max(222px, 28.90625vw)' } : { width: 'max(48px, 15vw)' }}>
                <Text isOver={isOver}>{data.memberShipName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {
            data.status === '연장 추가' ?
              <Tr style={isOver ? { width: 'max(117px, 15.234375vw)', marginRight: `max(8px, 1.04166666vw)` } : { width: 'max(38px, 1.1875vw)', marginRight: `max(5px, 1.5625vw)` }}>
                <Td>
                  <TextWrapper style={{ maxWidth: 'max(117px, 15.234375vw)' }}>
                    <Text isOver={isOver}>{isOver ? '연장 추가' : '연장'}</Text>
                  </TextWrapper>
                </Td>
              </Tr>
              :
              <Tr style={isOver ? { width: 'max(117px, 15.234375vw)', marginRight: `max(8px, 1.04166666vw)` } : { width: 'max(38px, 1.1875vw)', marginRight: `max(5px, 1.5625vw)` }}>
                <Td>
                  <TextWrapper style={{ maxWidth: 'max(117px, 15.234375vw)' }}>
                    {
                      isOver ?
                        <Text isOver={isOver}>{moment(data.startDate).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD') ? '홀딩 예정' : moment(data.endDate).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD') ? '홀딩 진행중' : '홀딩 종료'}</Text>
                        :
                        <Text isOver={isOver}>{moment(data.startDate).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD') ? '예정' : moment(data.endDate).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD') ? '진행중' : '종료'}</Text>
                    }
                  </TextWrapper>
                </Td>
              </Tr>
          }
          {
            data.status !== '연장 추가' &&
            <Tr style={isOver ? { width: '30px', marginRight: '8px'} : { width: '30px'}}>
              <Td>
                <TextWrapper style={{ maxWidth: '30px' }}>
                  <CancelButton ref={CancelRef} isOver={isOver} onClick={() => onClickHoldingCancel(data)}>취소</CancelButton>
                </TextWrapper>
              </Td>
            </Tr>
          }
          {
            data.status !== '연장 추가' &&
            <Tr style={{ width: '30px' }}>
              <Td>
                <TextWrapper style={{ maxWidth: '30px' }}>
                  <CancelButton ref={DeleteRef} isOver={isOver} onClick={() => onClickDeleteHoldingHistory(data)}>삭제</CancelButton>
                </TextWrapper>
              </Td>
            </Tr>
          }
        </Body>
      </Table>
    </TopLevelWrapper>
  )
}

export default HoldingHistoryInfoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-around;
  // justify-content: center;
  align-items: center;

  // width: 1018px;
  // height: 50px;

  width: ${props => props.isOver ? `max(690px, 89.84375vw)` : `max(280px, 87.5vw)`};
  height: ${props => props.isOver ? `50px` : `17px`};

  background: #FFFFFF;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: ${props => props.isOver ? `28px` : `10px`};
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 18px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `9px`};

  color: #595959;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const OptionButton = styled.img`
  opacity: 0;
  
  transition: all 0.3s ease-in-out;
`;

const OptionWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  overflow: hidden;

  // margin-top: 200px;
  // margin-left: 960px;
  margin-top: 40px;
  margin-left: -20px;
  
  z-index: 5;

  // border: 1px solid #FF8B48;
  border-radius: 8px;
  // background-color: #FFFFFF;

  transition: all 0.2s ease-in-out;
`;

const CancelButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `9px`};

  color: #FF8B48;
  background-color: #FFFFFF;

  opacity: 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;