import { React, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

function MyMemberPaymentInfoSecond(props) {
  const { data, onClickRefundButton } = props;

  const RegistrationRef = useRef();

  const history = useHistory();

  function onClickTopLevelWrapper(e) {
    if (e.target !== RegistrationRef.current) {
      if (data.memberId === -1) {
        alert('탈퇴하였거나 올바르지 않은 회원 입니다');
        return;
      }
      else {
        history.push('/member/valid/detail/' + data.memberId);
      }
    } 
  }

  function mouseOver() {
    RegistrationRef.current.style.color = `#FF8B48`;
  }

  function mouseLeave() {
    RegistrationRef.current.style.color = `#FFFFFF`;
  }
  

  return (
    <TopLevelWrapper onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={onClickTopLevelWrapper}>
      <Table>
        <Body>
          {/* <Tr style={{ width: '128px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '128px' }}>
                <Text>{data.approvalDate.replaceAll('-', '.').split('T')[0].slice(2, 10)}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '110px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '110px' }}>
                <Text>안형진</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '91px' }}>
                <Text>{data.merchantUid.split('-')[2] === '1' ? '일반' : data.merchantUid.split('-')[2] === '2' ? '정기' : '현장'}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '115px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '115px' }}>
                <Text>{data.cardPrice === 0 ? '카드결제' : data.cashPrice === 0 ? '현금결제' : '복합결제'}</Text>
              </TextWrapper>
            </Td>
          </Tr> */}
          <Tr style={{ width: '260px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '260px' }}>
                <Text>{data.itemName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '150px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '150px' }}>
                <Text>{(data.cardPrice + data.cashPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '156px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '156px' }}>
                <Text>{data.status === 0 ? '미승인' : data.status === 1 ? '승인' : data.status === 3 ? '환불 처리중' : data.status === 4 ? '환불' : '결제 오류'}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '56px' }}>
            <Td>
              <RegistrationButton ref={RegistrationRef} style={{ minWidth: '56px'}} onClick={() => onClickRefundButton(data)}>환불하기</RegistrationButton>
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

export default MyMemberPaymentInfoSecond;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 1189px;
  
  width: max(692px, 36.04166666666667vw);
  height: 50px;

  background: #FFFFFF;

  margin-top: 20px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;

  width: 100%;

  margin-left: 28px;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const RegistrationButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Bold;
  }
`;

const ExitButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;