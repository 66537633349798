import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';

import DefaultLogoPath from '../../image/DefaultLogo.png'
import ImageButton from '../../image/ImageButton.png';

const CommentImageInput = forwardRef((props, ref) => {
  const { changeFn } = props;

	const hiddenFileInput = useRef(null);
  const FileInputRef = useRef();
	const [imgBase64, setImgBase64] = useState();
  const [imgFile, setImgFile] = useState();
  const [imgLocalPath, setImgLocalPath] = useState('');
  
  useImperativeHandle(ref, () => ({
    getImgBase64: () => {
      return imgBase64;
    },
    getValue: () => {
      return imgLocalPath;
    },
    setBold: () => {

    },
    reset: () => {
      setImgBase64();
      setImgFile();
      setImgLocalPath();
    }
  }));

  const handleClick = e => {
    hiddenFileInput.current.click();
	};
	
  const handleChange = e => { 
		const reader = new FileReader();
    // setImgLocalPath(e.target.value);
    var tmp = e.target.value.split('\\');
    setImgLocalPath(tmp[tmp.length - 1]);
		reader.onloadend = () => {
			const base64 = reader.result;
      
			if (base64){
				setImgBase64(base64.toString());
        // changeFn(base64.toString());
			}
		};
		if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImgFile(e.target.files[0]);
      
      changeFn(e.target.files[0]);
		}
	};
  
  return (
    <TopLevelWrapper>
			<InputWrapper>
        <input type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{display: 'none'}}/>
        {/* <FileInput ref={FileInputRef}>{imgLocalPath}</FileInput> */}
        <InputButton Img={ImageButton} onClick={handleClick}/>
      </InputWrapper>
    </TopLevelWrapper>
  );
})

export default CommentImageInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

`;

const LogoExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	line-height: 17.38px;

	margin: 8px 0 0 0;

	color: rgba(203, 203, 203, 1);
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FileInput = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 14px;
  line-height: 20px;

  padding: 0 0 4px 0;
  margin-top: 16px;

  width: 273px;
  height: 25px;
  // background-color: red;
  // outline: none;
  border: 1px solid;
  border-width: 0 0 1px 0;
  border-color: #E8E8E8;
  color: #000000;

  ::placeholder {
    color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
  }

  transition: all 0.5s ease-in-out;
`;

const InputButton = styled.div`
  display: flex;
  flex-direction: row;

  width: 38px;
  height: 38px;
  // margin-left: 9px

  font-family: NotoSansKR-Medium;
  font-size: 12px;
  color: #777777;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background: url(${props => props.Img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: 61px;
`;

const Logo = styled.img`
  width: 80px;
  height: 80px;
`;