import { React } from 'react';
import styled from 'styled-components';

function MyPageTopModel(props) {
  const { isOver, isClicked, onClickModifyButton } = props;

  return (
    <TopLevelWrapper>
      <LayerWrapper isOver={isOver}>
        <TextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={isOver ? 16 : 12} color='#232323'>마이페이지</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={isOver ? 12 : 10} color='#777777'>센터 관련 정보를 확인하고 변경할 수 있어요</Text>
        </TextWrapper>
        <ModifyButton isOver={isOver} isClicked={isClicked} onClick={onClickModifyButton}>{isClicked ? `수정완료` : `수정하기`}</ModifyButton>
      </LayerWrapper>
    </TopLevelWrapper>
  )
}

export default MyPageTopModel;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  // background-color: red;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 1191px;
  width: ${props => props.isOver ? `692px` : `320px`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 28px;

  gap: 4px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: default;
  }
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `99px` : `61px`};
  height: ${props => props.isOver ? `38px` : `27px`};

  border: ${props => props.isClicked ? `1px solid #5BD98A` : `1px solid #DFDFDF`};
  border-radius: ${props => props.isOver ? `15px` : `5px`};

  background-color: ${props => props.isClicked ? `#5BD98A` : `#FFFFFF`};
  
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  margin: 0 0 0 0;

  color: ${props => props.isClicked ? `#FFFFFF` : `#777777`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;