import { React } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';

const CommentDeleteModal = (props) => {
  const { commentId, rendering, exitClickFn } = props;

  const history = useHistory();

  function exitClick() {
    exitClickFn();
  }

  function deleteClick() {
    (
      async () => {
        const fetchModule = new FetchModule();
        const responseData = await fetchModule.deleteData('notice/comment/delete/' + commentId, 'DELETE');
        
        if (responseData.status === 200) {
          // history.push('/notice');
          rendering();
          exitClickFn();
        }
        else if (responseData.status === 401) history.push('/');
        else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <Modal>
        <ViewWrapper>
          <Title>댓글 삭제</Title>
          <TextWrapper>
            <Text>해당 댓글을 삭제하면 다시 복구하는 것이</Text>
            <Text>불가능 합니다. 정말 삭제하시겠습니까?</Text>
          </TextWrapper>
        </ViewWrapper>
        <ButtonWrapper>
          <ExitButton onClick={exitClick}>취소</ExitButton>
          <DeleteButton onClick={deleteClick}>삭제하기</DeleteButton>
        </ButtonWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

export default CommentDeleteModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  // opacity: 0;
  z-index: 1000;

  // width: 100vw;
  // height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);

  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 772px;
  width: 300px;
  height: 248px;

  // padding: 0 0 0 28px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 315px;

  gap: 16px;

  margin-bottom: 53px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 9px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 16px;
  margin-left: 50px;
  // margin-bottom: 28px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #DD0000;
  }
`;