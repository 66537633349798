import { forwardRef, React, useRef, useImperativeHandle, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import SearchInput from '../../../Component/MemberComponent/SearchInput.js';
import InfoPagination from '../../../Component/MemberComponent/InfoPagination.js';

import FirstValidColumnView from './FirstValidColumnView.js';
import FirstValidMemberInfo from './FirstValidMemberInfo.js';
import SecondValidColumnView from './SecondValidColumnView.js';
import SecondValidMemberInfo from './SecondValidMemberInfo.js';
import ThirdValidColumnView from './ThirdValidColumnView.js';
import ThirdValidMemberInfo from './ThirdValidMemberInfo.js';

import SortingModule from '../../Share/Normal/SortingModule.js';

import EmptyValueLogo from '../../../image/EmptyValueLogo.png';

const ValidMemberView = forwardRef((props, ref) => {
  const { data, isOver } = props;

  const history = useHistory();

  const [totalData, setTotalData] = useState(data);
  const [sortingData, setSortingData] = useState([]);
  const [dividedData, setDividedData] = useState([]);
  const [nowPage, setNowPage] = useState(0);
  const [value, setValue] = useState('');
  const [first, setFirst] = useState(true);
  const [nowSlide, setNowSlide] = useState(1);

  const [startSort, setStartSort] = useState('normal');
  const [endSort, setEndSort] = useState('asc');
  const [classSort, setClassSort] = useState('normal');

  const TopLevelRef = useRef();
  
  useEffect(() => {
    setTotalData(data);
  }, [data])

  // 회원 자세히 보기에서 뒤로 나온경우 //
  useEffect(() => {
    setNowPage(window.localStorage.getItem('ValidMemberPage') === null ? 0 : parseInt(window.localStorage.getItem('ValidMemberPage')));
  }, [])

  // 회원 정보 소팅
  useEffect(() => {
    var newData = totalData.slice();

    if (startSort === 'asc') newData = SortingModule.dataSort(newData, 'startDate', 'asc', 'startDate');
    else if (startSort === 'desc') newData = SortingModule.dataSort(newData, 'startDate', 'desc', 'startDate');
    else if (endSort === 'asc') newData = SortingModule.dataSort(newData, 'endDate', 'asc', 'endDate');
    else if (endSort === 'desc') newData = SortingModule.dataSort(newData, 'endDate', 'desc', 'endDate');
    else if (classSort === 'asc') newData = SortingModule.dataSort(newData, 'memberShipName', 'asc', 'memberShipName');
    else if (classSort === 'desc') newData = SortingModule.dataSort(newData, 'memberShipName', 'desc', 'memberShipName');
    
    setSortingData(newData);
  }, [totalData, startSort, endSort, classSort])

  // 검색 기능 포함
  useEffect(() => {
    var nameList = [];
    var phoneList = [];

    for (var i = 0; i < sortingData.length; i++) {
      if (sortingData[i].name.toLowerCase().includes(value.toLowerCase())) nameList.push(sortingData[i]);
      else if (sortingData[i].phone.includes(value)) phoneList.push(sortingData[i]);
    }

    // console.log(totalData.findIndex(i => i.name.includes(value)))
    var totalList = nameList.concat(phoneList);
    var newDataList = [];

    // 페이지 분리 //
    if (totalList.length !== 0) {
      if (totalList.length > 20) newDataList = totalList.slice((nowPage * 20), (nowPage * 20) + 20);
      else newDataList = totalList.slice();
    }

    setDividedData(newDataList);
  }, [nowPage, sortingData, value])

  // 유효회원 정보 클릭시 //
  function onClickValidMember(data) {
    window.localStorage.setItem('ValidMemberPage', nowPage);
    history.push('/member/valid/detail/' + data.memberId);
  }

  // 다음 페이지 클릭시 //
  function onClickNextPage() {
    if (nowPage + 1 < Math.ceil(totalData.length/20)) setNowPage(nowPage + 1);
  }

  // 이전 페이지 클릭시 //
  function onClickPrevPage() {
    if ((nowPage - 1) >= 0) setNowPage(nowPage - 1);  
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TopBarWrapper isOver={isOver}>
         <TextWrapper isOver={isOver}>
          <TitleWrapper>
            <Title isOver={isOver}>유효 회원</Title>
            <CountText isOver={isOver}>{'(총 ' + data.length + '명)'}</CountText>
          </TitleWrapper>
           <Explanation isOver={isOver}>등록된 모든 회원을 볼 수 있어요</Explanation>
         </TextWrapper>
         <SearchInput isOver={isOver} value={value} setValue={setValue} placeholder='회원의 이름이나 전화번호를 검색해주세요'/>
      </TopBarWrapper>
      {
        isOver ?
          <TotalWrapper>
            {
              first ?
                <FirstValidColumnView isOver={isOver} nextFn={() => setFirst(false)} />
                :
                <SecondValidColumnView 
                  isOver={isOver} 
                  prevFn={() => setFirst(true)} 
                  startSort={startSort}
                  setStartSort={setStartSort}
                  endSort={endSort}
                  setEndSort={setEndSort}
                  classSort={classSort}
                  setClassSort={setClassSort}/>
            }
            {
              dividedData.length === 0 && 
              <EmptyLogoWrapper isOver={isOver}>
                <EmptyLogoView isOver={isOver} src={EmptyValueLogo}/>
                <EmptyText isOver={isOver}>회원정보가 존재하지 않습니다.</EmptyText>
              </EmptyLogoWrapper>
            }
            {
              dividedData.length !== 0 && first ?
                <InfoBox isOver={isOver}>
                  {
                    dividedData.map((data, index) => (
                      <FirstValidMemberInfo key={index} isOver={isOver} data={data} onClick={() => onClickValidMember(data)} />
                    ))
                  }
                </InfoBox>
                :
                <InfoBox isOver={isOver}>
                  {
                    dividedData.map((data, index) => (
                      <SecondValidMemberInfo key={index} isOver={isOver} data={data} onClick={() => onClickValidMember(data)} />
                    ))
                  }
                </InfoBox>
            }
          </TotalWrapper>
          :
          <TotalWrapper>
            { nowSlide === 1 && <FirstValidColumnView isOver={isOver} nextFn={() => setNowSlide(2)}/>}
            { nowSlide === 2 && <SecondValidColumnView 
                                  isOver={isOver} 
                                  prevFn={() => setNowSlide(1)} 
                                  nextFn={() => setNowSlide(3)} 
                                  startSort={startSort}
                                  setStartSort={setStartSort}
                                  endSort={endSort}
                                  setEndSort={setEndSort}
                                  classSort={classSort}
                                  setClassSort={setClassSort} />}
            { nowSlide === 3 && <ThirdValidColumnView prevFn={() => setNowSlide(2)} /> }
            {
              dividedData.length === 0 && 
              <EmptyLogoWrapper isOver={isOver}>
                <EmptyLogoView isOver={isOver} src={EmptyValueLogo}/>
                <EmptyText isOver={isOver}>회원정보가 존재하지 않습니다.</EmptyText>
              </EmptyLogoWrapper>
            }
            {
              dividedData.length !== 0 && nowSlide === 1 &&
              <InfoBox isOver={isOver}>
                {
                  dividedData.map((data, index) => (
                    <FirstValidMemberInfo key={index} data={data} isOver={isOver} onClick={() => onClickValidMember(data)} />
                  ))
                }
              </InfoBox>
            }
            {
              dividedData.length !== 0 && nowSlide === 2 &&
              <InfoBox isOver={isOver}>
                {
                  dividedData.map((data, index) => (
                    <SecondValidMemberInfo key={index} data={data} isOver={isOver} onClick={() => onClickValidMember(data)} />
                  ))
                }
              </InfoBox>
            }
            {
              dividedData.length !== 0 && nowSlide === 3 &&
              <InfoBox isOver={isOver}>
                {
                  dividedData.map((data, index) => (
                    <ThirdValidMemberInfo key={index} data={data} onClick={() => onClickValidMember(data)} />
                  ))
                }
              </InfoBox>
            }
          </TotalWrapper>
      }
      <PaginationWrapper>
        <InfoPagination 
          isOver={isOver}
          nowPage={nowPage + 1} 
          totalPage={Math.ceil(totalData.length/20)}
          nextClickFn={onClickNextPage}
          prevClickFn={onClickPrevPage}/>
      </PaginationWrapper>
    </TopLevelWrapper>
  )
})

export default ValidMemberView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: ${props => props.isOver ? `space-between` : `center`};
  align-items: ${props => props.isOver ? `center` : `flex-start`};

  gap: ${props => props.isOver ? `0px` : `6px`};
  margin-left: ${props => props.isOver ? `max(28px, 3vw)` : `0px`};

  width: ${props => props.isOver ? `max(664px, 85.6vw)` : `max(268px, 83.75vw)`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: ${props => props.isOver ? `0px` : `max(8px, 2.5vw)`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 4px;
`;

const CountText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #4F4F4F;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: ${props => props.isOver ? `20px` : `10px`};
  
  // min-height: 40vh;
  min-height: calc(100vh - 671px);
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 44px;
  margin-bottom: 96px;
`;

const EmptyLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: ${props => props.isOver ? `54px` : `34px`};
  margin-bottom: ${props => props.isOver ? `54px` : `34px`};
`;

const EmptyLogoView = styled.img`
  width: ${props => props.isOver ? `120px` : `80px`};
  height: ${props => props.isOver ? `120px` : `80px`};

  margin-bottom: ${props => props.isOver ? `17px` : `9px`};
`;

const EmptyText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #DFDFDF;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;