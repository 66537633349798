import { React, forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';

// import SelectOnPath from '../../image/SelectOn.png';
// import SelectOffPath from '../../image/SelectOff.png';

const FullListSelectBox = forwardRef((props, ref) => {
	const { setLessonId, rendering } = props;
  const history = useHistory();

	const [isClicked, setIsClicked] = useState(false);
  const [options, setOptions] = useState([]);
	const [categoryId, setCategoryId] = useState('');
	const [defaultVal, setDefaultVal] = useState({ name: '' });
  const [clickIndex, setClickIndex] = useState(-1);

	const SelectRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setCategoryId(val);
		},
		getValue: () => {
			return defaultVal;
		}
	}))

  useEffect(() => {
    setOptions([]);
    (
      async () => {
        const fetchModule = new FetchModule();
        const data = await fetchModule.getData('lesson/', 'GET');
        
        if (data.status === 200) {
					setOptions(data.data);
          if (data.data.length !== 0) setLessonId(data.data[0].id);
					rendering();
				}
        else if (data.status === 401) history.push('/');
        else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }, [history])

  useEffect(() => {
    if (options.length === 0) return;

		if (options.findIndex(i => i.id === categoryId) === -1 ) setDefaultVal(options[0]);
		else setDefaultVal(options[options.findIndex(i => i.id === categoryId)]);
		// for (var i = 0; i < options.length; i++) {
		// 	if (options[i].id === categoryId) {
		// 		setDefaultVal(options[i]);
		// 	}
		// }
  }, [options, categoryId])

	function onClick() {
		if (isClicked) {
			SelectRef.current.style.border = '0px solid #CBCBCB';
			SelectRef.current.style.height = `0px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			// SelectRef.current.style.height = `240px`;
      SelectRef.current.style.height = `12.5vw`;
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
		setDefaultVal(options[index]);
    setClickIndex(index);
		rendering();
	}

	return (
		<TopLevelWrapper>
      <ListButton onClick={onClick}>목록</ListButton>
			<SelectBox
				ref={SelectRef}
				isClicked={isClicked}
        onClick={onClick}>
				{
					isClicked &&
						options.map((data, index) =>
							<OptionWrapper key={index} onClick={() => optionalClick(index)}>
								<Option isClicked={clickIndex === index}>{data.name}</Option>
							</OptionWrapper>
						)
						// :
						// <OptionWrapper>
						// 	<Option>
						// 		{
						// 			defaultVal.name
						// 		}
						// 	</Option>
						// </OptionWrapper>
				}
			</SelectBox>
		</TopLevelWrapper>
	)
})

export default FullListSelectBox;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-end;
	// width: 143px;
	// height: 38px;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: flex-start;

	// width: 234px;
  width: 12.1875vw;
	height: 0px;
  // margin-top: 20px;
  margin-top: 1.041666666666667vw;

  z-index: 3;

	border: 0px solid #CBCBCB;
	border-radius: 15px;
	overflow: hidden;

	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Medium;
	// font-size: 14px;
  font-size: 0.7291666666666667vw;

	// margin: 10px 0 10px 16px;
  maring-top: 0.5208333333333333vw;
  margin-right: 0px;
  margin-bottom: 0.5208333333333333vw;
  margin-left: 0.8333333333333333vw;

  color: ${props => props.isClicked ? '#FF8B48' : '#595959'};
`;

const ListButton = styled.p`
  font-family: NotoSansKR-Regular;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;