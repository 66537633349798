import { React, useRef, useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import CompletePermuteLogoPath from '../../../image/CompletePermuteLogo.png';

const CompletePermuteView = forwardRef((props, ref) => {
  const { isOver } = props;

  const [name, setName] = useState('');

  const TopLevelRef = useRef();

  const history = useHistory();

  useImperativeHandle(ref, () => ({
		setOpacity: () => {
      TopLevelRef.current.style.zIndex = 3;
      TopLevelRef.current.style.opacity = 1;
      setName(sessionStorage.getItem('name'));
		}
	}));

  // useEffect(() => {
  //   alert(sessionStorage.getItem('name'));
  //   setName(sessionStorage.getItem('name'));
  // }, [])

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <InfoBox isOver={isOver}>
        <Logo src={CompletePermuteLogoPath} isOver={isOver}/>
        <InfoWrapper>
          <Title>{name} 님의 비밀번호가</Title>
          <Title>재설정 되었습니다.</Title>
          <LoginButton onClick={() => history.push('/')} isOver={isOver}>
            <LoginButtonText>로그인하기</LoginButtonText>
          </LoginButton>
        </InfoWrapper>
      </InfoBox>
    </TopLevelWrapper>
  )
});

export default CompletePermuteView;

const TopLevelWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  opacity: 0;
  z-index: -1;

  transition: all 0.5s ease-in-out;
`;

const Logo = styled.img`
  width: ${props => props.isOver ? `120px` : `112px`};
  height: ${props => props.isOver ? `120px` : `112px`};

  margin-top: ${props => props.isOver ? `-115px` : `-100px`};
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `351px` : `264px`};
  height: ${props => props.isOver ? `286px` : `206px`};

  border-radius: ${props => props.isOver ? `50px` : `30px`};
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 468px;

  margin-top: 32px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #000000;

  margin: 0 0 0 0;
`;

const LoginButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `136px` : `159px`};
  height: ${props => props.isOver ? `36px` : `20px`};

  margin-top: ${props => props.isOver ? `64px` : `23px`};

  border-radius: 23px;
  background-color: #FF8B48;

  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
		background-color: #FF7322;
	}
`;

const LoginButtonText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 12px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;
