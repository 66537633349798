import { React, useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import styled from 'styled-components';

import UpButtonArrow from '../../image/UpButtonArrow.png';
import DownButtonArrow from '../../image/DownButtonArrow.png'

const LockerCountInput = forwardRef((props, ref) => {
  const [value, setValue] = useState(0);

  const InputRef = useRef();

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return value;
    },
  }))

  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  function upClick() {
    setValue(value + 1);
  }

  function downClick() {
    if (value > 0) setValue(value - 1);
  }

	function handleChange(e) {
    var regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    var result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    
    if (result === '') setValue(0);
    else setValue(parseInt(result));
	}

  return (
    <TopLevelWrapper>
      <Text>추가 갯수</Text>
      <ComponentWrapper>
        <Input
          ref={InputRef}
          placeholder={0}
          value={value}
          onChange={handleChange}/>
        <Text>개</Text>
        <ButtonWrapper>
          <Button img={UpButtonArrow} onClick={upClick}/>
          <Button img={DownButtonArrow} onClick={downClick}/>
        </ButtonWrapper>
      </ComponentWrapper>
    </TopLevelWrapper>
  )
});

export default LockerCountInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  margin-top: 16px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Input = styled.input`
  display: flex;
  flex-direcion: row;

  justify-content: flex-start;
  align-items: center;

  margin: 0 0 0 0;
  padding: 0 0 0 8px;

  width: 104px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background: url(${props => props.img}) no-repeat center center;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;