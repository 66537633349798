import { React, forwardRef, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

const SignUpFirstBottomComponent = forwardRef((props, ref) => {
	const { onClickNextButton, isOver } = props;

	const TopLevelRef = useRef();

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
			if (val === 0) TopLevelRef.current.style.zIndex = -1;
			else TopLevelRef.current.style.zIndex = 1;
		},
	}));

	return (
		<TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
			<MoreDetailsTotalWrapper>
				<MoreDetailsWrapper>
					<MoreDetailsNormal>사용자의 동의 없이 정보를 이용하지 않습니다.</MoreDetailsNormal>
				</MoreDetailsWrapper>
				<MoreDetailsWrapper>
					<MoreDetailsNormal>링크매니저의</MoreDetailsNormal>
					<MoreDetailsLink>이용약관</MoreDetailsLink>
					<MoreDetailsNormal>및</MoreDetailsNormal>
					<MoreDetailsLink>개인정보 처리방침</MoreDetailsLink>
					<MoreDetailsNormal>에 동의합니다. </MoreDetailsNormal>
				</MoreDetailsWrapper>
			</MoreDetailsTotalWrapper>
			<LoginButton onClick={onClickNextButton} isOver={isOver}>다음</LoginButton>
		</TopLevelWrapper>
	)
})

export default SignUpFirstBottomComponent;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: ${props => props.isOver ? `row` : `column`};
	// flex-direction: column;

	justify-content: ${props => props.isOver ? `space-between` : `flex-start`};
	// justify-content: flex-start;
	align-items: ${props => props.isOver ? `center` : `flex-end`};
	// align-items: flex-end;

	// margin-top: -122px;
	margin-top: ${props => props.isOver ? `396px` : `371px`};
	
	width: ${props => props.isOver ? `max(369px, 48.046875vw)`: `max(250px, 78.125vw)`};

	gap: ${props => props.isOver ? `0px` : `29px`};

	z-index: -1;
  transition: all 0.5s ease-in-out;
`;

const MoreDetailsTotalWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const MoreDetailsWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const MoreDetailsLink = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 10px;

	margin: 0 3px 0 3px;

	color: #FF8B48;

	:hover {
		cursor: pointer;
	}
`;

const MoreDetailsNormal = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 10px;

	margin: 0 0 0 0;

	color: rgba(119, 119, 119, 1);
`;

const LoginButton = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font-family: NotoSansKR-Bold;
	font-size: ${props => props.isOver ? `12px` : `10px`};

	width: ${props => props.isOver ? `92px` : `61px`};
	height: ${props => props.isOver ? `36px` : `25px`};
	
	// margin: ${props => props.isOver ? `0 0 0 30px` : `0 0 0 0`};
	margin: 0 0 0 0;

	border-radius: 18px;
	background-color: #FF8B48;
	color: #FFFFFF;

	cursor: default;
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
		background-color: #FF7322;
	}
`;
