import { React, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../Share/Network/FetchModule';

const FirstExpiredMemberInfo = (props) => {
  const { data, isOver, nowPage } = props;

  const history = useHistory();

  const SendRef = useRef();
  const DeleteRef = useRef();

  function mouseOver() {
    SendRef.current.style.color = `#FF8B48`;
    DeleteRef.current.style.color = `#FF8B48`;
  }

  function mouseLeave() {
    SendRef.current.style.color = `#FFFFFF`;
    DeleteRef.current.style.color = `#FFFFFF`;
  }

  function onClickMemberInfo(e) {
    if (SendRef.current !== e.target && DeleteRef.current !== e.target) {
      window.localStorage.setItem('InvalidMemberPage', nowPage);
      history.push('/member/valid/detail/' + data.memberId);
    }
  }
  
  function onClickSendMessageButton() {
    var text = data.name + '님!, 지금까지 이용하셨던 ' + data.memberShipName + '이(가) 만료되었어요!\n빠른 결제로 Link Coach와 함께 더 건강한 하루를 만들어보아요! :)';

    var requestData = {
      userId: data.linkCoachId,
      data: text,
      location: 'Reservation',
      jsonData: ''
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfCoach('push_notification/send', 'POST', requestData);
        
        if (responseData.status === 200) {
          alert("푸시알림을 전송하였습니다!");
        }
        else if (responseData.status === 204) {
          alert("Link Coach를 탈퇴한 회원입니다!");
        }
      }
    )();
  }

  // 회원권 삭제 클릭시
  function onClickMemberInfoDelete() {

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('member/delete/' + data.memberId, 'POST');
        // console.log(responseData);
        if (responseData.status === 200) {
          const coachResponseData = await fetchModule.postDataOfCoach('registration/zone/delete/' + window.localStorage.getItem('StoreId') + '/' + data.linkCoachId, 'POST');
          // console.log(coachResponseData);
          if (coachResponseData.status === 200) {
            alert("회원 정보가 삭제되었습니다.");
            history.push('/member/valid');
          }
        }
        else if (responseData.status === 201) {
          alert("유효한 회원정보가 아닙니다.");
          history.push('/member/valid');
        }
        else if (responseData.status === 202) {
          alert("현재 유효한 회원권이 존재합니다.\n삭제후 다시시도해 주세요.");
        }
        else if (responseData.status === 204) {
          alert("회원정보가 존재하지 않습니다.");
          history.push('/member/valid');
        }
        else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
      }
    )();
  }

  return (
    <TopLevelWrapper isOver={isOver} onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={onClickMemberInfo}>
      {
        isOver ? 
          <Table>
            <Body isOver={isOver}>
              <Tr>
                <Td style={{ width: 'max(67px, 8vw)' }}>
                  <TextWrapper style={{ maxWidth: '52px' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.name}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(55px, 6.2vw)' }}>
                  <TextWrapper style={{ maxWidth: '26px' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.gender === 'male' ? '남성' : '여성'}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(98px, 10.9vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(98px, 10.5vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.endDate.split('T')[0].replaceAll('-', '.')}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(114px, 12.4vw)' }}>
                  <TextWrapper style={{ maxWidth: '114px' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.phone[0]
                      + data.phone[1]
                      + data.phone[2]
                      + '-'
                      + data.phone[3]
                      + data.phone[4]
                      + data.phone[5]
                      + data.phone[6]
                      + '-' +
                      + data.phone[7]
                      + data.phone[8]
                      + data.phone[9]
                      + data.phone[10]}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(194px, 21.35416666666667vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(194px, 21.35416666666667vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.memberShipName}</Text>
                  </TextWrapper>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <SendButton ref={SendRef} isOver={isOver} onClick={() => onClickSendMessageButton()}>문자발송</SendButton>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <SendButton ref={DeleteRef} isOver={isOver} onClick={() => onClickMemberInfoDelete()}>삭제</SendButton>
                </Td>
              </Tr>
            </Body>
          </Table>
          :
          <Table>
            <Body isOver={isOver}>
              <Tr>
                <Td style={{ width: 'max(42px, 12.4vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(42px, 12.4vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.name}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(38px, 10.8vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(38px, 10.8vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.gender === 'male' ? '남성' : '여성'}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(58px, 17.2vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(58px, 17.2vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.endDate.split('T')[0].replaceAll('-', '.')}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(70px, 21.875vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(70px, 21.875vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.phone[0]
                      + data.phone[1]
                      + data.phone[2]
                      + data.phone[3]
                      + data.phone[4]
                      + data.phone[5]
                      + data.phone[6]
                      + data.phone[7]
                      + data.phone[8]
                      + data.phone[9]
                      + data.phone[10]}</Text>
                  </TextWrapper>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <SendButton ref={SendRef} isOver={isOver} onClick={() => onClickSendMessageButton()}>문자발송</SendButton>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <SendButton ref={DeleteRef} isOver={isOver} onClick={() => onClickMemberInfoDelete()}>삭제</SendButton>
                </Td>
              </Tr>
            </Body>
          </Table>
      }
    </TopLevelWrapper>
  )
};

export default FirstExpiredMemberInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(281px, 87.8125vw)`};
  height: ${props => props.isOver ? `50px` : `17px`};

  background: #FFFFFF;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  margin-top: ${props => props.isOver ? `8px` : `4px`};
  // margin-bottom: 8px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: center;

  // margin-left: max(28px, 3.645833333333333vw);
  margin-left: ${props => props.isOver ? `max(28px, 3.645833333333333vw)` : `max(10px, 3.125vw)`};

  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(263px, 82.1875vw)`};
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  padding: 0 0 0 0;
  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: ${props => props.isOver ? `20px` : `15px`};
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;

  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};
`;

const SendButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FFFFFF;

  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Bold;
  }
`;