import { React, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import lodash from 'lodash';

// Component Import //
import AddMovementModal from './AddMovementModal';
import MovementOptionModal from './MovementOptionModal';
import TagExplanationModal from './TagExplanationModal';
import TagSelectBox from '../../../Component/NoteComponent/TagSelectBox';
import TagDetailSelectBox from '../../../Component/NoteComponent/TagDetailSelectBox';
import MainExerciseRoundInput from '../../../Component/NoteComponent/MainExerciseRoundInput';
import RepsInput from '../../../Component/NoteComponent/RepsInput';
import TimeInput from '../../../Component/NoteComponent/TimeInput';
import MovementBox from './MovementBox';
import VideoViewModal from './VideoViewModal';
import TimeLimitInput from '../../../Component/NoteComponent/TimeLimitInput';
// Component Import //

// Image Import //
import CheckImage from '../../../image/WhiteCheck.png';
import MovementPlusImage from '../../../image/MovementAdd.png';
import InfoIcon from '../../../image/InfomationIcon.png';
import RepMinusImage from '../../../image/RepMinusImage.png';
import RepPlusImage from '../../../image/RepPlusImage.png';
import NextRepsImage from '../../../image/NextRepsImage.png';
import TotalTitleInputBox from '../../../Component/NoteComponent/TotalTitleInputBox';
import ScoreSelectBox from '../../../Component/NoteComponent/ScoreSelectBox';
import ScoreDetailSelectBox from '../../../Component/NoteComponent/ScoreDetailSelectBox';
import DescriptionTextArea from '../../../Component/NoteComponent/DescriptionTextArea';
// Image Import //

function ModifySectionBox(props) {
  const { sectionData, refreshSectionList } = props;

  const [isClickedMovement, setIsClickedMovement] = useState(false);
  const [isClickedMovementOptions, setIsClickedMovementOptions] = useState(false);
  const [isClickedTagExplanation, setIsClickedTagExplanation] = useState(false);
  const [isClickedVideoView, setIsClickedVideoView] = useState(false);

  const [movementOptionData, setMovementOptionData] = useState(undefined);
  const [videoLink, setVideoLink] = useState('');
  const [division, setDivision] = useState('main');

  const [dataReps, setDataReps] = useState('');

  const [target, setTarget] = useState(undefined);
  const [targetStartY, setTargetStartY] = useState(0);
  const [targetEndY, setTargetEndY] = useState(0);
  const [clickDivision, setClickDivision] = useState(undefined);
  const [clickIndex, setClickIndex] = useState(undefined);
  const [changedIndex, setChangedIndex] = useState(undefined);
  const [emptyHeight, setEmptyHeight] = useState(0);

  const BuyinWrapRef = useRef([]);
  const MainWrapRef = useRef([]);
  const BuyoutWrapRef = useRef([]);

  useEffect(() => {
    if (sectionData.tag === 'ForTime-Different Reps') changeDifferentRepsData();
    else if (sectionData.tag === 'Custom') {
      // '점수 없음', '시간', '횟수', '라운드 + 횟수', '거리', '무게', '포인트', '피크와트', '최고 속도' //
      if (sectionData.scoreKeyword === 'Not Scored') sectionData.scoreKeyword = '점수 없음';
      else if (sectionData.scoreKeyword === 'Time') sectionData.scoreKeyword = '시간';
      else if (sectionData.scoreKeyword === 'Reps') sectionData.scoreKeyword = '횟수';
      else if (sectionData.scoreKeyword === 'Rounds And Reps') sectionData.scoreKeyword = '라운드 + 횟수';
      else if (sectionData.scoreKeyword === 'Distance') sectionData.scoreKeyword = '거리';
      else if (sectionData.scoreKeyword === 'Weight') sectionData.scoreKeyword = '무게';
      else if (sectionData.scoreKeyword === 'Points') sectionData.scoreKeyword = '포인트';
      else if (sectionData.scoreKeyword === 'Peak Watts') sectionData.scoreKeyword = '피크와트';
      else if (sectionData.scoreKeyword === 'Max Speed') sectionData.scoreKeyword = '최고 속도';

      if (sectionData.scorePriority === 'heigher') sectionData.scorePriority  = '높을 수록';
      else if (sectionData.scorePriority === 'lower') sectionData.scorePriority  = '낮을 수록';

      refreshSectionList();
    }
  }, [sectionData])

  // Buyin 사용 여부 체크 //
  function onClickBuyin() {
    if (sectionData.isUseBuyin) sectionData.isUseBuyin = false;
    else sectionData.isUseBuyin = true;

    refreshSectionList();
  }

  // Buyout 사용 여부 체크 //
  function onClickBuyout() {
    if (sectionData.isUseBuyout) sectionData.isUseBuyout = false;
    else sectionData.isUseBuyout = true;

    refreshSectionList();
  }

  // Custom Exercise Title 변경시 //
  function onChangeCustomTitle(customTitle) {
    sectionData.customTitle = customTitle;

    refreshSectionList();
  }

  // Custom Exercise ScoreKeyword 변경시 //
  function onChangeScoreKeyword(scoreKeyword) {
    sectionData.scoreKeyword = scoreKeyword;

    refreshSectionList();
  }

  // Custom Exercise ScorePriority 변경시 //
  function onChangeScorePriority(scorePriority) {
    sectionData.scorePriority = scorePriority;

    refreshSectionList();
  }

  // Custom Exercise Description 변경시 //
  function onChangeDescription(description) {
    sectionData.description = description;

    refreshSectionList();
  }

  // Time Limit 사용 여부 //
  function onClickTimeLimit() {
    if (sectionData.isUseTimeLimit) sectionData.isUseTimeLimit = false;
    else sectionData.isUseTimeLimit = true;

    refreshSectionList();
  }

  // Main 운동 SINGLE인 경우 쉬는 시간 사용 여부 클릭시 //
  function onClickRest(index) {
    if (sectionData.mainExerciseList[index].isUseRest) sectionData.mainExerciseList[index].isUseRest = false;
    else sectionData.mainExerciseList[index].isUseRest = true;

    refreshSectionList();
  }

  // 동작 추가하기 클릭시 //
  function onClickAddMovement(division) {
    setDivision(division);
    setIsClickedMovement(true);
  }

  // 모달 동작 추가 완료 버튼 클릭시 //
  function successAddMovement(data) {
    if (data.movementName === '') {
      alert('추가할 운동을 선택해주세요!');
      return;
    }

    if (division === 'buyin') sectionData.buyinExerciseList.push(data);
    else if (division === 'main') sectionData.mainExerciseList.push(data);
    else if (division === 'buyout') sectionData.buyoutExerciseList.push(data);

    refreshSectionList();
    setIsClickedMovement(false);
  }

  // 무게, 길이, 높이, 열량, 횟수, 시간, 템포 데이터 변경시 //
  function onChangeData(index, division, column, value) {
    var second = 0;
    var minute = 0;

    if (division === 'buyin') {
      if (column === 'weight') sectionData.buyinExerciseList[index].weight = value;
      else if (column === 'distance') sectionData.buyinExerciseList[index].distance = value;
      else if (column === 'height') sectionData.buyinExerciseList[index].height = value;
      else if (column === 'calories') sectionData.buyinExerciseList[index].calories = value;
      else if (column === 'count') sectionData.buyinExerciseList[index].count = value;
      else if (column === 'tempo') sectionData.buyinExerciseList[index].tempo = value;
      else if (column === 'minute') {
        second = parseInt(sectionData.buyinExerciseList[index].time % 60);
  
        sectionData.buyinExerciseList[index].time = parseInt((value * 60) + second);
      }
      else if (column === 'second') {
        minute = sectionData.buyinExerciseList[index].time - parseInt(sectionData.buyinExerciseList[index].time % 60);
  
        sectionData.buyinExerciseList[index].time = parseInt(minute) + parseInt(value);
      }
    }
    else if (division === 'main') {
      if (column === 'weight') sectionData.mainExerciseList[index].weight = value;
      else if (column === 'distance') sectionData.mainExerciseList[index].distance = value;
      else if (column === 'height') sectionData.mainExerciseList[index].height = value;
      else if (column === 'calories') sectionData.mainExerciseList[index].calories = value;
      else if (column === 'count') sectionData.mainExerciseList[index].count = value;
      else if (column === 'tempo') sectionData.mainExerciseList[index].tempo = value;
      else if (column === 'sets') sectionData.mainExerciseList[index].sets = value;
      else if (column === 'rest') sectionData.mainExerciseList[index].rest = value;
      else if (column === 'minute') {
        second = parseInt(sectionData.mainExerciseList[index].time % 60);
  
        sectionData.mainExerciseList[index].time = parseInt((value * 60) + second);
      }
      else if (column === 'second') {
        minute = sectionData.mainExerciseList[index].time - parseInt(sectionData.mainExerciseList[index].time % 60);
  
        sectionData.mainExerciseList[index].time = parseInt(minute) + parseInt(value);
      }
    }
    else if (division === 'buyout') {
      if (column === 'weight') sectionData.buyoutExerciseList[index].weight = value;
      else if (column === 'distance') sectionData.buyoutExerciseList[index].distance = value;
      else if (column === 'height') sectionData.buyoutExerciseList[index].height = value;
      else if (column === 'calories') sectionData.buyoutExerciseList[index].calories = value;
      else if (column === 'count') sectionData.buyoutExerciseList[index].count = value;
      else if (column === 'tempo') sectionData.buyoutExerciseList[index].tempo = value;
      else if (column === 'minute') {
        second = parseInt(sectionData.buyoutExerciseList[index].time % 60);
  
        sectionData.buyoutExerciseList[index].time = parseInt((value * 60) + second);
      }
      else if (column === 'second') {
        minute = sectionData.buyoutExerciseList[index].time - parseInt(sectionData.buyoutExerciseList[index].time % 60);
  
        sectionData.buyoutExerciseList[index].time = parseInt(minute) + parseInt(value);
      }
    }

    refreshSectionList();
  }

  // 무게, 길이, 높이, 열량 데이터 단위 변경시 //
  function onChangeUnit(index, division, column, value) {
    if (division === 'buyin') {
      if (column === 'weight') sectionData.buyinExerciseList[index].weightUnit = value;
      else if (column === 'distance') sectionData.buyinExerciseList[index].distanceUnit = value;
      else if (column === 'height') sectionData.buyinExerciseList[index].heightUnit = value;
      else if (column === 'calories') sectionData.buyinExerciseList[index].caloriesUnit = value;
    }
    else if (division === 'main') {
      if (column === 'weight') sectionData.mainExerciseList[index].weightUnit = value;
      else if (column === 'distance') sectionData.mainExerciseList[index].distanceUnit = value;
      else if (column === 'height') sectionData.mainExerciseList[index].heightUnit = value;
      else if (column === 'calories') sectionData.mainExerciseList[index].caloriesUnit = value;
    }
    else if (division === 'buyout') {
      if (column === 'weight') sectionData.buyoutExerciseList[index].weightUnit = value;
      else if (column === 'distance') sectionData.buyoutExerciseList[index].distanceUnit = value;
      else if (column === 'height') sectionData.buyoutExerciseList[index].heightUnit = value;
      else if (column === 'calories') sectionData.buyoutExerciseList[index].caloriesUnit = value;
    }

    refreshSectionList();
  }

  // 시간 제한 변경시 //
  function onChangeTimeLimit(division, value) {
    if (division === 'Minute') {
      var second = parseInt(sectionData.timeLimit % 60);

      sectionData.timeLimit = parseInt((value * 60) + second);
    }
    else if (division === 'Second') {
      var minute = sectionData.timeLimit - parseInt(sectionData.timeLimit % 60);

      sectionData.timeLimit = parseInt(minute) + parseInt(value);
    }

    refreshSectionList();
  }

  // 동작 옵션 모달 열기 클릭시 //
  function onClickMovementOptionModal(division, index) {
    if (division === 'buyin') setMovementOptionData(sectionData.buyinExerciseList[index]);
    else if (division === 'main') setMovementOptionData(sectionData.mainExerciseList[index]);
    else if (division === 'buyout') setMovementOptionData(sectionData.buyoutExerciseList[index]);

    setIsClickedMovementOptions(true);
  }

  // 동작 영상 모달 열기 클릭시 //
  function onClickMovementVideoViewModal(division, index) {
    if (division === 'buyin') setVideoLink(sectionData.buyinExerciseList[index].videoLinkId);
    else if (division === 'main') setVideoLink(sectionData.mainExerciseList[index].videoLinkId);
    else if (division === 'buyout') setVideoLink(sectionData.buyoutExerciseList[index].videoLinkId);

    setIsClickedVideoView(true);
  }

  // 동작 삭제 버튼 클릭시 //
  function onClickDeleteMovement(division, index) {
    if (division === 'buyin') sectionData.buyinExerciseList.splice(index, 1);
    else if (division === 'main') sectionData.mainExerciseList.splice(index, 1);
    else if (division === 'buyout') sectionData.buyoutExerciseList.splice(index, 1);

    refreshSectionList();
  }

  // 메인운동 Tag 변경시 //
  function onChangeSectionMainTag(data) {
    if (data === 'ForTime') sectionData.tag = data + '-Rounds';
    else if (data === 'Rounds') sectionData.tag = sectionData.tag.split('-')[0] + '-Rounds';
    else if (data === 'Different Reps') sectionData.tag = sectionData.tag.split('-')[0] + '-Different Reps';
    else if (data === 'AMRAP') {
      sectionData.isUseBuyout = false;
      sectionData.buyoutExerciseList = [];
      sectionData.tag = data;
    }
    else {
      sectionData.isUseBuyin = false;
      sectionData.isUseBuyout = false;
      sectionData.buyinExerciseList = [];
      sectionData.buyoutExerciseList = [];
      sectionData.tag = data;
    }

    refreshSectionList();
  }

  // 메인운동 Round 변경시 //
  function onChangeSectionMainRound(data) {
    sectionData.round = data;

    refreshSectionList();
  }

  // 메인운동 ( ForTime - Different Reps ) Reps 추가 버튼 클릭시 //
  function onClickAddReps() {
    sectionData.reps.push(0);

    changeDifferentRepsData();
    refreshSectionList();
  }

  // 메인운동 ( ForTime - Different Reps ) Reps 삭제 버튼 클릭시 //
  function onClickDeleteReps() {
    sectionData.reps.splice(sectionData.reps.length - 1, 1);

    changeDifferentRepsData();
    refreshSectionList();
  }

  // 메인운동 ( ForTime - Different Reps ) Reps 변경시 //
  function onChangeRepsData(index, value) {
    sectionData.reps[index] = value;

    changeDifferentRepsData();
    refreshSectionList();
  }

  // DifferentReps Data Change //
  function changeDifferentRepsData() {

    var newReps = '';

    if (sectionData.reps.length <= 6) {
      for (var i = 0; i < sectionData.reps.length; i++) {
        if (i !== 0) newReps += ' - ';
        newReps += parseInt(sectionData.reps[i]);
      }
    }
    else {
      newReps += parseInt(sectionData.reps[0]) + ' - '
        + parseInt(sectionData.reps[1]) + ' - '
        + parseInt(sectionData.reps[2]) + ' - '
        + parseInt(sectionData.reps[3]) + ' - ... - '
        + parseInt(sectionData.reps[sectionData.reps.length - 1]);  
    }

    setDataReps(newReps);
  }


  // 메인운동 AMRAP, TABATA 운동시간 변경시 //
  function onChangeExerciseTime(division, value) {
    if (division === 'Minute') {
      var second = parseInt(sectionData.exerciseTime % 60);

      sectionData.exerciseTime = parseInt((value * 60) + second);
    }
    else if (division === 'Second') {
      var minute = sectionData.exerciseTime - parseInt(sectionData.exerciseTime % 60);

      sectionData.exerciseTime = parseInt(minute) + parseInt(value);
    }

    refreshSectionList();
  }

  // 메인운동 TABATA 쉬는시간 변경시 //
  function onChangeRestTime(division, value) {
    if (division === 'Minute') {
      var second = parseInt(sectionData.restTime % 60);

      sectionData.restTime = parseInt((value * 60) + second);
    }
    else if (division === 'Second') {
      var minute = sectionData.restTime - parseInt(sectionData.restTime % 60);

      sectionData.restTime = parseInt(minute) + parseInt(value);
    }

    refreshSectionList();
  }

  // Movement Component 이동 클릭시 //
  function onMouseDown(e, clickTarget, division, idx) {
    // 좌클릭에만 반응하도록 //
    if (e.button === 0) {
      // console.log(e);
      setTarget(clickTarget);
      setEmptyHeight(clickTarget.offsetHeight);
  
      clickTarget.style.width = clickTarget.clientWidth + 'px';
      clickTarget.style.position = 'fixed';
      clickTarget.style.zIndex = 50;

      // 마우스 커서 위치 - Movement Margin (23) - Movement Name Height / 2 //
      clickTarget.style.top = (e.clientY - (23 + 9)) + 'px';
      // clickTarget.style.left = (clickTarget.offsetLeft - totalXScroll) + 'px';

      // setTargetY(e.clientY - (23 + 9));

      setTargetStartY(e.clientY - (23 + 9));
      setTargetEndY(e.clientY - (23 + 9) + clickTarget.offsetHeight);
      setClickDivision(division);
      setClickIndex(idx);
      setChangedIndex(idx);
    }
    // 우클릭일 경우 원상복구 // 
    else if (target !== undefined && e.button === 2) {
      clickTarget.style.top = 'auto';
      // clickTarget.style.left = (clickTarget.offsetLeft - totalXScroll) + 'px';
      clickTarget.style.position = 'static';
      clickTarget.style.zIndex = 1;
      clickTarget.style.width = '100%';

      setTarget(undefined);
      setTargetStartY(0);
      setTargetEndY(0);
      setClickDivision(undefined);
      setClickIndex(undefined);
      setChangedIndex(undefined);
    }
  }

  // 선택된 Movement Component 이동 //
  function onMouseMove(e) {
    if (target === undefined) return;

    setTargetStartY(e.clientY - (23 + 9));
    setTargetEndY(e.clientY - (23 + 9) + target.offsetHeight);
    target.style.top = (e.clientY - (23 + 9)) + 'px';
  }

  // 선택된 Movement Component에서 Focus를 잃을경우 ( 마우스 클릭을 떼거나 컴포넌트 밖으로 나갈경우 ) //
  function onMouseUpAndMouseLeave(e) {
    if (target !== undefined) {
      var newList = [];

      // List의 자리변경을 위한 임시변수 //
      var swap = undefined;

      // // 원래의 자신의 자리인경우 실행하지 않음 //
      // if (changedIndex !== clickIndex) {
      if (changedIndex !== undefined) {
        // console.log("changedIndex : " + changedIndex);
        if (clickDivision === 'buyin') newList = lodash.clone(sectionData.buyinExerciseList);
        else if (clickDivision === 'main') newList = lodash.clone(sectionData.mainExerciseList);
        else if (clickDivision === 'buyout') newList = lodash.clone(sectionData.buyoutExerciseList);
      
        // 움직일 데이터 추출 //
        swap = newList[clickIndex];
        // 움직일 데이터 원본 삭제 //
        newList.splice(clickIndex, 1);
        // 움직인 자리에 데이터 삽입 //
        newList.splice(changedIndex, 0, swap);

        if (clickDivision === 'buyin') sectionData.buyinExerciseList = newList;
        else if (clickDivision === 'main') newList = sectionData.mainExerciseList = newList;
        else if (clickDivision === 'buyout') newList = sectionData.buyoutExerciseList = newList;
      }
     
      target.style.top = 'auto';
      // target.style.left = (target.offsetLeft - totalXScroll) + 'px';
      target.style.left = 'auto';
      target.style.position = 'static';
      target.style.zIndex = 1;
      target.style.width = '100%';

      setTarget(undefined);
      setTargetStartY(0);
      setTargetEndY(0);
      setClickDivision(undefined);
      setClickIndex(undefined);
      setChangedIndex(undefined);
    }
  }

  return (
    <TopLevelWrapper>
      { isClickedMovement && <AddMovementModal division={division} successAddMovement={successAddMovement} close={() => setIsClickedMovement(false)} />}
      { isClickedMovementOptions && <MovementOptionModal data={movementOptionData} refreshSectionList={refreshSectionList} close={() => setIsClickedMovementOptions(false)} />}
      { isClickedTagExplanation && <TagExplanationModal close={() => setIsClickedTagExplanation(!isClickedTagExplanation)} />}
      { isClickedVideoView && <VideoViewModal data={videoLink} close={() => setIsClickedVideoView(!isClickedVideoView)}/> }
      {
        // Buyin Setting //
        (sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps' || sectionData.tag === 'AMRAP') &&
        <BuyTotalWrapper>
          <BuyWrapper>
            <CheckBox isClicked={sectionData.isUseBuyin} onClick={onClickBuyin}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Regular' fontSize={14} color='#4F4F4F'>Buy in</Text>
          </BuyWrapper>
          {
            sectionData.isUseBuyin &&
            <TotalMovementWrapper ref={BuyinWrapRef} /*onMouseMove={onMouseMove} onMouseUp={onMouseUpAndMouseLeave} onMouseLeave={onMouseUpAndMouseLeave}*/>
              {
                sectionData.buyinExerciseList.map((data, idx) => (
                  // 동작 컴포넌트 //
                  <MovementBoxWrapper
                    // ref={element => (BuyinWrapRef.current[idx] = element)}
                    key={idx}>
                    <MovementBox
                      data={data}
                      dataListLength={sectionData.buyinExerciseList.length}
                      idx={idx}
                      tag='None'
                      division='buyin'
                      reps={dataReps}
                      target={target}
                      targetStartY={targetStartY}
                      targetEndY={targetEndY}
                      clickIndex={clickIndex}
                      changedIndex={changedIndex}
                      setChangedIndex={setChangedIndex}
                      clickDivision={clickDivision}
                      emptyHeight={emptyHeight}
                      onChangeData={onChangeData}
                      onChangeUnit={onChangeUnit}
                      onMouseDown={onMouseDown}
                      onClickMovementVideo={onClickMovementVideoViewModal}
                      onClickMovementOption={onClickMovementOptionModal}
                      onClickDeleteMovement={onClickDeleteMovement} />
                  </MovementBoxWrapper>
                ))
              }
              <AddMovementButton onClick={() => onClickAddMovement('buyin')}>
                <MovementPlus src={MovementPlusImage} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#6DD49E'>동작 추가하기</Text>
              </AddMovementButton>
            </TotalMovementWrapper>
          }
          <DivisionLine />
        </BuyTotalWrapper>
      }
      {
        sectionData.tag === 'Custom' &&
        <CustomComponentWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>운동 타이틀</Text>
          <TotalTitleInputBox value={sectionData.customTitle} setValue={onChangeCustomTitle} />
          <DivisionLine />
        </CustomComponentWrapper>
      }
      <MainComponentWrapper>
        <MainIconWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>메인 운동</Text>
          <Icon src={InfoIcon} onClick={() => setIsClickedTagExplanation(true)} />
        </MainIconWrapper>
        <MainSelectBoxWrapper>
          <TagSelectBox value={sectionData.tag.split('-')[0]} setValue={onChangeSectionMainTag} />
          <TagDetailSelectBox value={sectionData.tag.split('-')[1]} setValue={onChangeSectionMainTag} disabled={sectionData.tag.split('-')[0] !== 'ForTime'} />
        </MainSelectBoxWrapper>
        {
          // Section Tag가 For Time - Rounds일 경우 //
          sectionData.tag === 'ForTime-Rounds' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>라운드</Text>
            <MainExerciseRoundInput value={sectionData.round} setValue={onChangeSectionMainRound} />
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 For Time - Different Reps일 경우 //
          sectionData.tag === 'ForTime-Different Reps' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>랩 수</Text>
            <AddRepsButtonWrapper>
              <RepsButton onClick={onClickDeleteReps}>
                랩 삭제
                <RepsImage src={RepMinusImage} />
              </RepsButton>
              <RepsButton onClick={onClickAddReps}>
                랩 추가
                <RepsImage src={RepPlusImage} />
              </RepsButton>
            </AddRepsButtonWrapper>
            <RepsInputWrapper>
              {
                sectionData.reps.map((data, idx) => (
                  <RepsWrapper key={idx}>
                    <RepsInput index={idx} value={data} setValue={onChangeRepsData} />
                    {sectionData.reps.length - 1 !== idx && <NextRepsIcon src={NextRepsImage} />}
                  </RepsWrapper>
                ))
              }
            </RepsInputWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 AMRAP일 경우 //
          sectionData.tag === 'AMRAP' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>운동 시간</Text>
            <TimeWrapper>
              <TimeInput value={parseInt(sectionData.exerciseTime / 60) > 0 ? parseInt(sectionData.exerciseTime / 60) : 0} setValue={onChangeExerciseTime} division='Minute' unit='분' />
              <TimeInput value={parseInt(sectionData.exerciseTime % 60) > 0 ? parseInt(sectionData.exerciseTime % 60) : 0} setValue={onChangeExerciseTime} division='Second' unit='초' />
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 EMOM일 경우 //
          sectionData.tag === 'EMOM' &&
          <MainExerciseTagWrapper>
            <TimeTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>라운드 / 운동 시간</Text>
              <TextInfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959' style={{ marginRight: '4px' }}>총</Text>
                {
                  parseInt(sectionData.round * sectionData.exerciseTime / 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E'>{parseInt(sectionData.round * sectionData.exerciseTime / 60) + '분'}</Text>
                }
                {
                  parseInt(sectionData.round * sectionData.exerciseTime % 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E' style={{ marginLeft: '4px' }}>{parseInt(sectionData.round * sectionData.exerciseTime % 60) + '초'}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959'>동안 진행됩니다</Text>
              </TextInfoWrapper>
            </TimeTextWrapper>
            <MainExerciseRoundInput value={sectionData.round} setValue={onChangeSectionMainRound} />
            <TimeWrapper>
              <TimeInput value={parseInt(sectionData.exerciseTime / 60) > 0 ? parseInt(sectionData.exerciseTime / 60) : 0} setValue={onChangeExerciseTime} division='Minute' unit='분' />
              <TimeInput value={parseInt(sectionData.exerciseTime % 60) > 0 ? parseInt(sectionData.exerciseTime % 60) : 0} setValue={onChangeExerciseTime} division='Second' unit='초' />
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 TABATA일 경우 //
          sectionData.tag === 'TABATA' &&
          <MainExerciseTagWrapper>
            <TimeTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 별 라운드</Text>
              <TextInfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959' style={{ marginRight: '4px' }}>총</Text>
                {
                  parseInt(((sectionData.round * sectionData.exerciseTime) + (sectionData.round > 0 ? (sectionData.round - 1) * sectionData.restTime : 0)) / 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E'>{parseInt(((sectionData.round * sectionData.exerciseTime) + (sectionData.round > 0 ? (sectionData.round - 1) * sectionData.restTime : 0)) / 60) + '분'}</Text>
                }
                {
                  parseInt(((sectionData.round * sectionData.exerciseTime) + (sectionData.round > 0 ? (sectionData.round - 1) : 0 * sectionData.restTime)) % 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E' style={{ marginLeft: '4px' }}>{parseInt(((sectionData.round * sectionData.exerciseTime) + ((sectionData.round > 0 ? (sectionData.round - 1) : 0) * sectionData.restTime)) % 60) + '초'}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959'>동안 진행됩니다</Text>
              </TextInfoWrapper>
            </TimeTextWrapper>
            <MainExerciseRoundInput value={sectionData.round} setValue={onChangeSectionMainRound} />
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 별 운동 시간</Text>
            <TimeWrapper>
              <TimeInput value={parseInt(sectionData.exerciseTime / 60) > 0 ? parseInt(sectionData.exerciseTime / 60) : 0} setValue={onChangeExerciseTime} division='Minute' unit='분' />
              <TimeInput value={parseInt(sectionData.exerciseTime % 60) > 0 ? parseInt(sectionData.exerciseTime % 60) : 0} setValue={onChangeExerciseTime} division='Second' unit='초' />
            </TimeWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 별 쉬는 시간</Text>
            <TimeWrapper>
              <TimeInput value={parseInt(sectionData.restTime / 60) > 0 ? parseInt(sectionData.restTime / 60) : 0} setValue={onChangeRestTime} division='Minute' unit='분' />
              <TimeInput value={parseInt(sectionData.restTime % 60) > 0 ? parseInt(sectionData.restTime % 60) : 0} setValue={onChangeRestTime} division='Second' unit='초' />
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 Custom일 경우 //
          sectionData.tag === 'Custom' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>점수 기준</Text>
            <MainSelectBoxWrapper>
              <ScoreSelectBox value={sectionData.scoreKeyword} setValue={onChangeScoreKeyword} />
              <ScoreDetailSelectBox value={sectionData.scorePriority} setValue={onChangeScorePriority} disabled={sectionData.scoreKeyword === '점수 없음' || sectionData.scoreKeyword === '피크와트' || sectionData.scoreKeyword === '최고 속도'} />
            </MainSelectBoxWrapper>
            <DivisionLine />
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>운동 설명</Text>
            <DescriptionTextArea value={sectionData.description} setValue={onChangeDescription} />
          </MainExerciseTagWrapper>
        }
      </MainComponentWrapper>
      <DivisionLine />
      {
        sectionData.tag !== 'Custom' &&
      <TotalMovementWrapper ref={MainWrapRef} onMouseMove={onMouseMove} onMouseUp={onMouseUpAndMouseLeave} onMouseLeave={onMouseUpAndMouseLeave}>
        {
          sectionData.mainExerciseList.map((data, idx) => (
            // 동작 컴포넌트 //
            <MovementBoxWrapper
              key={idx}>
              <MovementBox
                data={data}
                dataListLength={sectionData.mainExerciseList.length}
                idx={idx}
                tag={sectionData.tag}
                reps={dataReps}
                division='main'
                target={target}
                targetStartY={targetStartY}
                targetEndY={targetEndY}
                clickIndex={clickIndex}
                changedIndex={changedIndex}
                setChangedIndex={setChangedIndex}
                clickDivision={clickDivision}
                emptyHeight={emptyHeight}
                onChangeData={onChangeData}
                onChangeUnit={onChangeUnit}
                onClickRest={onClickRest}
                onMouseDown={onMouseDown}
                onClickMovementVideo={onClickMovementVideoViewModal}
                onClickMovementOption={onClickMovementOptionModal}
                onClickDeleteMovement={onClickDeleteMovement} />
              </MovementBoxWrapper>
          ))
        }
        <AddMovementButton onClick={() => onClickAddMovement('main')}>
          <MovementPlus src={MovementPlusImage} />
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#6DD49E'>동작 추가하기</Text>
        </AddMovementButton>
        <DivisionLine />
      </TotalMovementWrapper>
      }
      {
        // Buyout Setting //
        ( sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps' ) &&
        <BuyTotalWrapper>
          <BuyWrapper>
            <CheckBox isClicked={sectionData.isUseBuyout} onClick={onClickBuyout}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Regular' fontSize={14} color='#4F4F4F'>Buyout</Text>
          </BuyWrapper>
          {
            sectionData.isUseBuyout &&
            <TotalMovementWrapper ref={BuyoutWrapRef} /* onMouseMove={onMouseMove} onMouseUp={onMouseUpAndMouseLeave} onMouseLeave={onMouseUpAndMouseLeave}*/>
              {
                sectionData.buyoutExerciseList.map((data, idx) => (
                  // 동작 컴포넌트 //
                  <MovementBoxWrapper
                    key={idx}>
                    <MovementBox
                      data={data}
                      dataListLength={sectionData.buyoutExerciseList.length}
                      idx={idx}
                      tag='None'
                      division='buyout'
                      reps={dataReps}
                      target={target}
                      targetStartY={targetStartY}
                      targetEndY={targetEndY}
                      clickIndex={clickIndex}
                      changedIndex={changedIndex}
                      setChangedIndex={setChangedIndex}
                      clickDivision={clickDivision}
                      emptyHeight={emptyHeight}
                      onChangeData={onChangeData}
                      onChangeUnit={onChangeUnit}
                      onMouseDown={onMouseDown}
                      onClickMovementVideo={onClickMovementVideoViewModal}
                      onClickMovementOption={onClickMovementOptionModal}
                      onClickDeleteMovement={onClickDeleteMovement} />
                  </MovementBoxWrapper>
                ))
              }
              <AddMovementButton onClick={() => onClickAddMovement('buyout')}>
                <MovementPlus src={MovementPlusImage} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#6DD49E'>동작 추가하기</Text>
              </AddMovementButton>
            </TotalMovementWrapper>
          }
          <DivisionLine />
        </BuyTotalWrapper>
        // Buyout Setting //
      }
      {
        // Time Limit Setting //
        ( sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps' || sectionData.tag === 'SINGLE' ) &&
        <TimeLimitWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>시간 제한</Text>
          <TimeLimitCustomWrapper gap={15}>
            <TimeLimitCustomWrapper gap={6}>
              <CheckBox isClicked={!sectionData.isUseTimeLimit} onClick={onClickTimeLimit}>
                <Check src={CheckImage} />
              </CheckBox>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#828282'>제한 없음</Text>
            </TimeLimitCustomWrapper>
            <TimeLimitCustomWrapper gap={24}>
              <TimeLimitInput disabled={!sectionData.isUseTimeLimit} value={parseInt(sectionData.timeLimit / 60) > 0 ? parseInt(sectionData.timeLimit / 60) : 0} setValue={onChangeTimeLimit} division='Minute' unit='분'/>
              <TimeLimitInput disabled={!sectionData.isUseTimeLimit} value={parseInt(sectionData.timeLimit % 60) > 0 ? parseInt(sectionData.timeLimit % 60) : 0} setValue={onChangeTimeLimit} division='Second' unit='초'/>
            </TimeLimitCustomWrapper>
          </TimeLimitCustomWrapper>
          <DivisionLine />
        </TimeLimitWrapper>
        // Time Limit Setting //
      }
    </TopLevelWrapper>
  )
}

export default ModifySectionBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 97.71%;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // height: 1px;

  margin-top: 24px;
  margin-bottom: 24px;

  border: 1px solid #ECECEC;
`;

const BuyTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const BuyWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  // margin-top: 24px;
  gap: 6px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 10px;
  height: 9px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TotalMovementWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: auto;

  // transition: all 0.3s ease-in-out;
`;

const MainIconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const AddMovementButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  width: calc(97.71% - 2px);
  height: 34px;

  gap: 4px;
  margin-top: 24px;

  border: 1px solid #6DD49E;
  border-radius: 12px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const MovementPlus = styled.img`
  width: 20px;
  height: 20px;
`;

const MainComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const CustomComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const MainSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 9px;
`;

const MainExerciseTagWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
  margin-top: 24px;

  width: 100%;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const AddRepsButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RepsButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 94px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;

  gap: 4px;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const RepsImage = styled.img`
  width: 20px;
  height: 20px;
`;

const RepsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
  margin-top: 8px;
`;

const RepsInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  // margin-top: 8px;

  width: 100%;
`;

const NextRepsIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const TimeTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TimeLimitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  
  gap: 6px;
`;

const TimeLimitCustomWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.gap}px;
`;

const MovementBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;