import { React } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const ThirdValidMemberInfo = (props) => {
  const { data, onClick } = props;
  
  return (
    <TopLevelWrapper onClick={onClick}>
      <Table>
        <Body>
          <Tr>
            <Td style={{ width: 'max(47px, 14.4vw)' }}>
              <TextWrapper style={{ maxWidth: 'max(47px, 14.4vw)' }}>
                <Text>{data.name}</Text>
              </TextWrapper>
            </Td>
            <Td style={{ width: 'max(45px, 13vw)' }}>
              <TextWrapper style={{ maxWidth: 'max(45px, 13vw)' }}>
                <Text>{data.gender === 'male' ? '남성' : '여성'}</Text>
              </TextWrapper>
            </Td>
            <Td style={{ width: 'max(133px, 41.5625vw)' }}>
              <TextWrapper style={{ maxWidth: 'max(133px, 41.5625vw)' }}>
                <Text>{data.memberShipName}</Text>
              </TextWrapper>
            </Td>
            {
              moment(data.endDate).diff(moment(), 'day') <= 5 &&
              <Td>
                <TextWrapper style={{ maxWidth: '45px' }}>
                  <Dday>{moment(data.endDate).diff(moment(), 'day') === 0 ? 'D-day' : 'D-' + moment(data.endDate).diff(moment(), 'day')}</Dday>
                </TextWrapper>
              </Td>
            }
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

export default ThirdValidMemberInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: max(281px, 87.8125vw);
  height: 17px;

  background: #FFFFFF;
  // background-color: red;

  margin-top: 4px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: max(10px, 3.125vw);
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  padding: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 10px;

  color: #595959;

  margin: 0 0 0 0;

  -webkit-transform: scale(0.9);
`;

const Dday = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 45px;
  height: 100%;

  border-radius: 30px;
  background-color: #FF8B48;

  font-family: NotoSansKR-Regular;
  font-size: 11px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;