import { React } from 'react';
import styled from 'styled-components';

import PrevButtonImage from '../../../image/NormalPrevInfoButton.png';
import ColorNextButtonImage from '../../../image/ColorNextInfoButton.png';

const FirstWaittingColumnView = (props) => {
  const { isOver, nextFn } = props;

  return (
    <TopLevelWrapper isOver={isOver}>
      {
        isOver ? 
          <ColumnWrapper>
            <Column style={{ marginRight: 'max(40px, 5.208333333333333vw)', marginLeft: 'max(15px, 1.953125vw)' }} isOver={isOver}>이름</Column>
            <Column style={{ marginRight: 'max(29px, 3.776041666666667vw)' }} isOver={isOver}>성별</Column>
            <Column style={{ marginRight: 'max(46px, 5.989583333333333vw)' }} isOver={isOver}>생년월일</Column>
            <Column style={{ marginRight: 'max(49px, 6.380208333333333vw)' }} isOver={isOver}>핸드폰번호</Column>
            <Column isOver={isOver}>지점</Column>
          </ColumnWrapper>
          :
          <ColumnWrapper>
            <Column style={{ marginRight: 'max(22px, 6.875vw)', marginLeft: 'max(5px, 1.5625vw)' }} isOver={isOver}>이름</Column>
            <Column style={{ marginRight: 'max(50px, 15.625vw)' }} isOver={isOver}>휴대폰</Column>
            <Column isOver={isOver}>ID</Column>
          </ColumnWrapper>
      }
      {
        isOver ?
          <ButtonWrapper>
            <Button src={PrevButtonImage} />
            <Column isOver={isOver}>다음 정보</Column>
            <Button src={ColorNextButtonImage} onClick={nextFn} />
          </ButtonWrapper>
          :
          null
      }
    </TopLevelWrapper>
  )
}

export default FirstWaittingColumnView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: ${props => props.isOver ? `space-between` : `flex-start`};
  align-items: ${props => props.isOver ? `center` : `flex-start`};

  width: ${props => props.isOver ? `max(664px, 86.45833333333333vw)` : `max(268px, 83.75vw)`};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Button = styled.img`
  width: 24px;
  height: 24px;

  border-radius: 24px;

  :hover {
    cursor: pointer;
  }
`;