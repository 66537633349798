import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

// import CalenderImagePath from '../../image/Calender-gray.png';
import CalenderRightArrowImagePath from '../../../image/CalenderRightArrow.png';
import CalenderLeftArrowImagePath from '../../../image/CalenderLeftArrow.png';
import CalenderLeftArrow2ImagePath from '../../../image/CalenderLeftArrow2.png';
import CalenderRightArrow2ImagePath from '../../../image/CalenderRightArrow2.png';

const CalenderInput = forwardRef((props, ref) => {
  const { isOver, date, clickDate, dateClickFn, heightFn } = props;

  const [today, setToday] = useState(date);
  const [isClicked, setIsClicked] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [value, setValue] = useState(clickDate);

  const TopLevelRef = useRef();

  const firstWeek = today.clone().startOf('month').week();
  const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

  useImperativeHandle(ref, () => ({
    setValue: (val) => {
      setValue(val)
    },
    getValue: () => {
      return value;
    },
    closeCalender: () => {
      setIsClicked(false);
    }
  }));

  useEffect(() => {
    heightFn(TopLevelRef.current.offsetHeight);
  })

  useEffect(() => {
    setToday(date);
  }, [date])

  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = today.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        newDateList.push(days);
      } 
    }
    setDateList(newDateList);
  }, [isClicked, today, firstWeek, lastWeek]) 


  function dateClick(index) {
    setValue(dateList[index].format('YYYY.MM.DD'));
    dateClickFn(dateList[index].format('YYYY.MM.DD'));
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
      <TotalWrapper>
        <TitleWrapper>
          <Arrow img={CalenderLeftArrow2ImagePath} onClick={() => setToday(today.clone().subtract(1, 'year'))} style={{ margin: `0 -7px 0 0` }} />
          <Arrow img={CalenderLeftArrowImagePath} onClick={() => setToday(today.clone().subtract(1, 'month'))} />
          <Title>{today.format('YYYY년 MM월')}</Title>
          <Arrow img={CalenderRightArrowImagePath} onClick={() => setToday(today.clone().add(1, 'month'))} />
          <Arrow img={CalenderRightArrow2ImagePath} onClick={() => setToday(today.clone().add(1, 'year'))} style={{ margin: `0 0 0 -7px` }} />
        </TitleWrapper>
        <DateColumnWrapper isOver={isOver}>
          <DateColumn style={{ width: `max(25px, 3.255208333333333vw)`, marginRight: isOver ? `max(17px, 2.213541666666667vw)` : `max(11px, 3.4375vw)` }}>SUN</DateColumn>
          <DateColumn style={{ width: `max(30px, 3.90625vw)`, marginRight: isOver ? `max(19px, 2.473958333333333vw)` : `max(12px, 3.75vw)` }}>MON</DateColumn>
          <DateColumn style={{ width: `max(23px, 2.994791666666667vw)`, marginRight: isOver ? `max(19px, 2.473958333333333vw)` : `max(12px, 3.75vw)` }}>TUE</DateColumn>
          <DateColumn style={{ width: `max(28px, 3.645833333333333vw)`, marginRight: isOver ? `max(19px, 2.473958333333333vw)` : `max(11px, 3.4375vw)`}}>WED</DateColumn>
          <DateColumn style={{ width: `max(25px, 3.255208333333333vw)`, marginRight: isOver ? `max(23px, 2.994791666666667vw)` : `max(16px, 5vw)`}}>THU</DateColumn>
          <DateColumn style={{ width: `max(18px, 2.34375vw)`, marginRight: isOver ? `max(23px, 2.994791666666667vw)` : `max(17px, 5.3125vw)` }}>FRI</DateColumn>
          <DateColumn style={{ width: `max(24px, 3.125vw)`/*, marginRight: `28px`*/ }}>SAT</DateColumn>
        </DateColumnWrapper>
        <DateWrapper isOver={isOver}>
          {
            dateList.map((data, index) => (
              data.format('MM') === today.format('MM') ?
                <Date key={index} isOver={isOver} isClicked={data.format('YYYY.MM.DD') === value} onClick={() => dateClick(index)}>
                  {data.format('D')}
                </Date>
                :
                <WithoutHoverDate key={index} isOver={isOver}>
                  {data.format('D')}
                </WithoutHoverDate>
            ))
          }
        </DateWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
})

export default CalenderInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 408px;
  width: ${props => props.isOver ? `max(353px, 45.96354166666667vw)` : `max(296px, 92.5vw)`};
  // height: 418px;
  min-height: ${props => props.isOver ? `421px` : `360px`};

  // padding-bottom: 28px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 28px;
`;

const Arrow = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  
  background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const DateColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  // align-items: center;

  // gap: 38px;
  margin-top: 29px;
  // margin-left: 28px;
  margin-left: ${props => props.isOver ? `max(8px, 1.041666666666667vw)` : `max(4px, 1.25vw)`};
  // margin-bottom: 23px;
  margin-bottom: 18px;
`;

const DateColumn = styled.p`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: Poppins-bold;
  font-size: 12px;

  color: #FF8B48;
  
  margin: 0 0 0 0;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: ${props => props.isOver ? `max(5px, 0.6510416666666667vw)` : `max(3px, 0.9375vw)`};
  // width: 408px;
  // width: 322px;
  width: ${props => props.isOver ? `max(316px, 41.14583333333333vw)` : `max(263px, 82.1875vw)`};
  // margin-left: 8px;
  // margin-left: 21px;

  margin-bottom: 18px;
`;

const Date = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  // width: 44px;
  width: ${props => props.isOver ? `max(40px, 5.208333333333333vw)` : `max(35px, 10.9375vw)`};
  // height: 44px;
  height: ${props => props.isOver ? `max(40px, 5.208333333333333vw)` : `max(35px, 10.9375vw)`};

  // border-radius: 44px;
  border-radius: ${props => props.isOver ? `max(40px, 5.208333333333333vw)` : `max(35px, 10.9375vw)`};

  background-color: ${props => props.isClicked ? `#FF7322` : `#FFFFFF`};
  
  font-family: Poppins-Medium;
  font-size: 14px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#595959`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF7322;
    color: #FFFFFF;

    font-fmaily: Poppins-Bold;
  }
`;

const WithoutHoverDate = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  // width: 44px;
  width: ${props => props.isOver ? `max(40px, 5.208333333333333vw)` : `max(35px, 10.9375vw)`};
  // height: 44px;
  height: ${props => props.isOver ? `max(40px, 5.208333333333333vw)` : `max(35px, 10.9375vw)`};

  // border-radius: 44px;
  border-radius: ${props => props.isOver ? `max(40px, 5.208333333333333vw)` : `max(35px, 10.9375vw)`};

  background-color: #FFFFFF;

  font-family: Poppins-Regular;
  font-size: 14px;

  color: #777777;

  :hover {
    cursor: default;
  }
`;