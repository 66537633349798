import { forwardRef, React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import MemberShipAndClassView from './MemberShipAndClassView.js';
import SystemSettingView from './SystemSettingView.js';
import CoachSettingView from './CoachSettingView.js';

import useWindowSize from '../../Share/Normal/useWindowSize.js';

function SettingView() {
  const size = useWindowSize();

  const [nowClicked, setNowClicked] = useState('MemberShip');
  const [isOver, setIsOver] = useState(false);

  const TopLevelRef = useRef();
  const MemberShipAndClassRef = useRef();
  const SystemRef = useRef();
  const CoachRef = useRef();

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  function AddCoachButtonClick() {
    CoachRef.current.setIsClicked(true);
  }

  function saveSystemButtonClick() {
    SystemRef.current.save();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TopBarWrapper isOver={isOver}>
         <TextWrapper>
           <Title isOver={isOver}>설정</Title>
           <Explanation isOver={isOver}>서비스의 원활한 이용을 위한 센터별 맞춤 설정을 할 수 있어요</Explanation>
         </TextWrapper>
      </TopBarWrapper>
      <TotalButtonWrapper isOver={isOver}>
        <ButtonWrapper>
          <MemberShipAndClassButton isOver={isOver} nowClicked={nowClicked} onClick={() => setNowClicked('MemberShip')}>회원권, 수업</MemberShipAndClassButton>
          <SystemButton isOver={isOver} nowClicked={nowClicked} onClick={() => setNowClicked('System')}>시스템</SystemButton>
          <CoachButton isOver={isOver} nowClicked={nowClicked} onClick={() => setNowClicked('Coach')}>코치</CoachButton>
        </ButtonWrapper>
        <SaveButton isOver={isOver} view={nowClicked === 'System'} onClick={saveSystemButtonClick}>저장하기</SaveButton>
        <CoachButtonWrapper view={nowClicked === 'Coach'}>
          <CoachControlButton isOver={isOver} onClick={AddCoachButtonClick}>코치 추가</CoachControlButton>
        </CoachButtonWrapper>
      </TotalButtonWrapper>
      { nowClicked === 'MemberShip' && <MemberShipAndClassView ref={MemberShipAndClassRef} isOver={isOver}/> }
      { nowClicked === 'System' && <SystemSettingView ref={SystemRef} isOver={isOver} onClick={saveSystemButtonClick}/> }
      { nowClicked === 'Coach' && <CoachSettingView ref={CoachRef} isOver={isOver}/> }
    </TopLevelWrapper>
  )
}

export default SettingView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: ${props => props.isOver ? `28px` : `14px`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const TotalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(296px, 92.5vw)`};
  
  margin-top: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 8px;

  // margin: 28px 0 16px 0;
`;

const MemberShipAndClassButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `98px` : `80px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: ${props => props.nowClicked === 'MemberShip' ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: ${props => props.nowClicked === 'MemberShip' ? `#FF8B48` : `#FFFFFF`};

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: ${props => props.nowClicked === 'MemberShip' ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
    font-family: NotoSansKR-Medium;
    color: #FFFFFF;
  }
`;

const SystemButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `65px` : `48px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: ${props => props.nowClicked === 'System' ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: ${props => props.nowClicked === 'System' ? `#FF8B48` : `#FFFFFF`};

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: ${props => props.nowClicked === 'System' ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
    font-family: NotoSansKR-Medium;
    color: #FFFFFF;
  }
`;

const CoachButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `52px` : `37px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: ${props => props.nowClicked === 'Coach' ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: ${props => props.nowClicked === 'Coach' ? `#FF8B48` : `#FFFFFF`};

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: ${props => props.nowClicked === 'Coach' ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
    font-family: NotoSansKR-Medium;
    color: #FFFFFF;
  }
`;

const SaveButton = styled.div`
  display: ${props => props.view ? `flex` : `none`};
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `81px`};
  height: ${props => props.isOver ? `40px` : `31px`};

  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const CoachButtonWrapper = styled.div`
  display: ${props => props.view ? `flex` : `none`};
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 16px;

  transition: all 0.3s ease-in-out;
`;

const CoachControlButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `98px` : `79px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;