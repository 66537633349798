import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';

import DefaultLogoPath from '../../image/DefaultLogo.png'

const LogoFileInput = forwardRef((props, ref) => {
  const { isOver } = props;

	const hiddenFileInput = useRef(null);
  const FileInputRef = useRef();
	const [imgBase64, setImgBase64] = useState();
  const [imgFile, setImgFile] = useState();
  const [imgLocalPath, setImgLocalPath] = useState('');
  
  useImperativeHandle(ref, () => ({
    getImgBase64: () => {
      return imgBase64;
    },
    getValue: () => {
      return imgLocalPath;
    },
    setBold: () => {

    },
    reset: () => {
      setImgBase64();
      setImgFile();
      setImgLocalPath();
    }
  }));

  const handleClick = e => {
    hiddenFileInput.current.click();
    FileInputRef.current.style.borderColor = '#FF8B48';
	};
	
  const handleChange = e => { 
    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우
      const reader = new FileReader();
      // setImgLocalPath(e.target.value);
      var tmp = e.target.value.split('\\');
      setImgLocalPath(tmp[tmp.length - 1]);
      reader.onloadend = () => {
        const base64 = reader.result;
        
        FileInputRef.current.style.borderColor = '#E8E8E8';
        if (base64){
          setImgBase64(base64.toString());
        }
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
        setImgFile(e.target.files[0]);
      }
    } 
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
    }

		
	};
  
  return (
    <TopLevelWrapper>
      <LogoExplanationWrapper isOver={isOver}>
        <LogoExplanation>400px X 400px 사이즈 권장 / jpg, png지원 최대 3MB</LogoExplanation>
      </LogoExplanationWrapper>
			<InputWrapper>
        <input type="file"
          ref={hiddenFileInput} 
          // isOver={isOver}
			    tabIndex={-1}
          onChange={handleChange}
          style={{display: 'none'}}/>
        <FileInput ref={FileInputRef} isOver={isOver}>{imgLocalPath}</FileInput>
        <InputButton onClick={handleClick}>찾아보기</InputButton>
        <LogoWrapper isOver={isOver}>
          <Logo src={imgFile ? imgBase64 : DefaultLogoPath}/>
        </LogoWrapper>
      </InputWrapper>
    </TopLevelWrapper>
  );
})

export default LogoFileInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const LogoExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // flex-wrap: wrap;

  width: ${props => props.isOver ? `max(278px, 36.19791666666667vw)` : `max(140px, 24.375vw)`};
`;

const LogoExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;

	// margin: 8px 0 0 0;
  margin: 0;

	color: rgba(203, 203, 203, 1);
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: flex-end;
`;

const FileInput = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  padding: 0 0 4px 0;
  margin-top: 23px;

  // width: 233px;
  width: ${props => props.isOver ? `max(233px, 30.33854166666667vw)` : `max(136px, 42.5vw)`};
  height: 25px;
  
  border: 1px solid;
  border-width: 0 0 1px 0;
  border-color: #E8E8E8;
  color: #000000;

  ::placeholder {
    color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
  }

  transition: all 0.5s ease-in-out;
`;

const InputButton = styled.div`
  display: flex;
  flex-direction: row;

  // width: 60px;

  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #777777;

  :hover {
    cursor: pointer;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: ${props => props.isOver ? `26px` : `4px`};
`;

const Logo = styled.img`
  width: 65px;
  height: 65px;
  
  object-fit: contain;
`;