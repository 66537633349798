import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import NavigationBar from './NavigationBar';

import FetchModule from '../Network/FetchModule';

import MobileNavIcon from '../../../image/MobileNavIcon.png';

function CenterInfo(props) {
  const { isOver, data } = props;

  const history = useHistory();
  const nowPage = window.location.href;

  // const [data, setData] = useState([]);
  const [navClick, setNavClick] = useState(false);

  const NavRef = useRef();

  // useEffect(() => {
  //   // const defaultURL = 'http://localhost:3001';
  //   const defaultURL = 'https://m.link-zone.io';
  //   // const defaultURL = 'http://172.30.1.6:3001';

  //   if (nowPage === defaultURL + '/'
  //   || nowPage.includes(defaultURL + '/certification')
  //   || nowPage.includes(defaultURL + '/identifier')
  //   || nowPage.includes(defaultURL + '/permute')
  //   || nowPage.includes(defaultURL + '/rule/')
  //   || nowPage.includes(defaultURL + '/complete')
  //   || nowPage.includes(defaultURL + '/payment')
  //   || nowPage === defaultURL + '/full/board/') return null;
  
  //   if (window.sessionStorage.getItem('StoreId') === null) return null;

  //   (
  //     async () => {
  //       const fetchModule = new FetchModule();
  //       const responseData = await fetchModule.getData('store/', 'GET');
        
  //       if (responseData.status === 200) {
  //         setData(responseData.data[0]);
	// 			}
  //       else if (responseData.status === 401) history.push('/');
  //     }
  //   )();
  // }, [history, nowPage])

  return (
    <TopLevelWrapper isOver={isOver}>
      {navClick && <NavigationBar ref={NavRef} cancelFn={() => setNavClick(false)}/> }
      <NavigationButton isOver={isOver}/* onClick={() => setNavClick(true)}*/>
        <NavigationImage src={MobileNavIcon} isOver={isOver}/>
      </NavigationButton>
      <TopBar isOver={isOver}>
        <GreetingWrapper isOver={isOver}>
          <Name isOver={isOver}>{data.ownerName}님</Name>
          <Greeting isOver={isOver}>, 안녕하세요!</Greeting>
        </GreetingWrapper>
        <InfoWrapper isOver={isOver}>
          <Info isOver={isOver}>센터명 -</Info>
          <UserInfo isOver={isOver}>{data.storeName}</UserInfo>
        </InfoWrapper>
      </TopBar>
    </TopLevelWrapper>
  )
}

export default CenterInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `8px` : `6px`};

  margin-top: 24px;
`;

const NavigationButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `48px` : `27px`};
  height: ${props => props.isOver ? `48px` : `27px`};

  border-radius: ${props => props.isOver ? `15px` : `5px`};
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background: linear-gradient(270deg, #FF8B48 0%, #FFA570 100%);

  :hover {
    cursor: pointer;
  }
`;

const NavigationImage = styled.img`
  width: ${props => props.isOver ? `24px` : `17px`};
  height: ${props => props.isOver ? `24px` : `17px`};
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // padding: ${props => props.isOver ? `0 24px 0 24px` : `0 9px 0 9px`};
  // width: 636px;
  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(263px, 82.1875vw)`};
  // width: ${props => props.isOver ? `max(588px, 76.5625vw)` : `max(245px, 76.5625vw)`};
  height: ${props => props.isOver ? `48px` : `27px`};

  border-radius: ${props => props.isOver ? `15px` : `5px`};
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background: linear-gradient(270deg, #FF8B48 0%, #FFA570 100%);
`;

const GreetingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin: ${props => props.isOver ? `0 0 0 24px` : `0 0 0 9px`};
  // margin-left: ${props => props.isOver ? `24px` : `9px`};
`;

const Name = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FFFFFF;
`;

const Greeting = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FFFFFF;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin: ${props => props.isOver ? `0 24px 0 0` : `0 9px 0 0`};
  // margin-right: ${props => props.isOver ? `24px` : `9px`};
`;

const Info = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FFFFFF;
`;

const UserInfo = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  margin-left: 5px;
  color: #FFFFFF;
`;