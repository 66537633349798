import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CommentTextArea from '../../../Component/NoticeComponent/CommentTextArea';
import CommentImageInput from '../../../Component/NoticeComponent/CommentImageInput';

import FetchModule from '../../Share/Network/FetchModule';

import ExitImage from '../../../image/ImageExit.png';

const CommentWriteBox = (props) => {
  const { isOver, noticeId, rendering, commentRef, name, commentId } = props;

  const history = useHistory();

  const [value, setValue] = useState('');
  const [image, setImage] = useState(undefined);

  const InputRef = useRef();
  const ImageRef = useRef();

  useEffect(() => {
    if (name !== undefined) {
      InputRef.current.setValue(name + ' ');
    }
  }, [name])

  function changeFn(value) {
    setValue(value);
  }

  function imageChange(value) {
    setImage(value);
  }

  function imageExitClick() {
    setImage(undefined);
    ImageRef.current.reset();
  }

  function saveCommentClick() {
    if (value === '' || value === undefined) {
      alert('내용을 입력해주세요');
      return;
    }

    if (value.length > 3000) {
      alert('허용 글자수를 초과하였습니다.');
      return;
    }

    // var storeId = window.sessionStorage.getItem('StoreId');
    var storeId = window.localStorage.getItem('StoreId');
    var memberId = -1;
    var text = value;
    var image = ImageRef.current.getImgBase64();
    
    // 댓글을 선택했거나 이름을 지우지 않았을 경우
    if (commentId !== -1 && name.length <= value.length) {
      // 답글로 적기
  
      var data = {
        storeId: parseInt(storeId),
        noticeId: parseInt(noticeId),
        memberId: memberId,
        commentId: commentId,
        text: text,
        image: image
      }

      console.log(data);
  
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('notice/reply/create', 'POST', data);
          
          if (responseData.status === 200) {
            InputRef.current.reset();
            ImageRef.current.reset();
            rendering();
          }
          else if (responseData.status === 401) history.push('/');
          else if (responseData.status !== 204) alert("서버와 연결이 불안정합니다.\n잠시후 다시시도 해주세요");
        }
      )();
    }
    else {
      // 댓글로 적기
  
      var data = {
        storeId: parseInt(storeId),
        noticeId: parseInt(noticeId),
        memberId: memberId,
        text: text,
        image: image
      }
  
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('notice/comment/create', 'POST', data);
          
          if (responseData.status === 200) {
            InputRef.current.reset();
            ImageRef.current.reset();
            rendering();
          }
          else if (responseData.status === 401) history.push('/');
          else if (responseData.status !== 204) alert("서버와 연결이 불안정합니다.\n잠시후 다시시도 해주세요");
        }
      )();
    }
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      <LeftWrapper>
        <Title isOver={isOver}>댓글 작성</Title>
        <CommentBox >
          <CommentTextArea id="test" ref={InputRef} isOver={isOver} placeholder='내용을 입력해주세요' changeFn={changeFn}/>
        <ImageBox isView={image !== undefined}>
          <ExitButton buttonPath={ExitImage} onClick={imageExitClick}/>
          <Image src={image ? URL.createObjectURL(image) : ''}/>
        </ImageBox>
        </CommentBox>
      </LeftWrapper>
      <RightWrapper isOver={isOver}>
        <CharacterCount isOver={isOver}>{value.length}/3000</CharacterCount>
        <ButtonWrapper>
          <CommentImageInput ref={ImageRef} changeFn={imageChange}/>
          <SaveButton ref={commentRef} available={value.length !== 0} onClick={saveCommentClick}>등록</SaveButton>
        </ButtonWrapper>
      </RightWrapper>
    </TopLevelWrapper>
  )
}

export default CommentWriteBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: flex-start;
  align-items: flex-start;

  gap: max(16px, 2.083333333333333vw);

  margin-bottom: 28px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const CommentBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
`;

const ImageBox = styled.div`
  display: ${props => props.isView ? `flex` : `none`};
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  margin: 8px 8px 8px 8px;
`;

const Image = styled.img`
  width: 160px;
  height: 160px;

  // background-color: red;
`;

const ExitButton = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  border-radius: 24px;
  background: url(${props => props.buttonPath}) no-repeat center center;
  background-color: rgba(0, 0, 0, 0.5);

  margin: 10px 10px 0 0;

  :hover {
    cursor: pointer;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `column` : `row`};

  justify-content: ${props => props.isOver ? `flex-start` : `space-between`};
  align-items: flex-start;

  gap: ${props => props.isOver ? `24px` : `0px`};

  width: ${props => props.isOver ? `` : `max(268px, 83.75vw)`};

  // background-color: red;
`;

const CharacterCount = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #CBCBCB;

  margin: ${props => props.isOver ? `50px 0 0 0` : `0 0 0 0`};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 66px;
  height: 38px;

  border: ${props => props.available ? `1px solid #5BD98A` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: ${props => props.available ? `#5BD98A` : `#FFFFFF`};

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.available ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: ${props => props.available ? `1px solid #2BCB67` : `1px solid #777777`};
    color: ${props => props.available ? `#FFFFFF` : `#777777`};
    background-color: ${props => props.available ? `#2BCB67` : `#FFFFFF`};
  }
`;