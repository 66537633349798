import { useState, React, useEffect, useRef } from 'react';
import styled from 'styled-components';

function RankingCommentTextArea(props, ref) {
	const { placeholder, value, setValue } = props;
	// const [value, setValue] = useState(defaultValue);

	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);

	const inputRef = useRef();

	useEffect(() => {
		inputRef.current.style.height = `${(inputRef.current.scrollHeight + 9)}px`;
	}, [])

	function handleChange(e) {
		// console.log(e.target.value);
		setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

  function onPress(e) {
  }
 
	return <Input
		ref={inputRef}
		type='text'
		placeholder={placeholder}
		value={value}
		changedColor={changedPlaceholderColor}
		onChange={handleChange}
		onFocus={handleFocus}
		onBlur={handleBlur} 
    onKeyPress={onPress}/>
}

export default RankingCommentTextArea;

const Input = styled.textarea`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 10px 10px 10px 10px;

  // width: 818px;
	width: calc(83.65% - 20px);
  max-height: 20px;

  resize: none;

  overflow-y: scroll;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  color: #595959;

	outline: none;

	::placeholder {
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;