import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Share/Network/FetchModule';
import useWindowSize from '../../Share/Normal/useWindowSize';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import TestClassSelectBox from '../../../Component/NoteComponent/TestClassSelectBox';
import ScheduleBox from './ScheduleBox';
import CalenderView from './CalenderView';
import RankingBox from './RankingBox';
import RankingExerciseBox from './RankingExerciseBox';

function RankingTotalView() {
  const history = useHistory();
  const size = useWindowSize();

  const [isOver, setIsOver] = useState(false);
  const [scheduleHeight, setScheduleHeight] = useState(421);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment());
  const [rendering, setRendering] = useState(false);
  const [scheduleId, setScheduleId] = useState(-1);
  const [rankingList, setRankingList] = useState([]);
  const [nowSection, setNowSection] = useState(0);
  const [nowClickLinkCoachId, setNowClickLinkCoachId] = useState('');
  const [isClickedDetail, setIsClickedDetail] = useState(false);
  const [isClickedModify, setIsClickedModify] = useState(false);
  const [isViewConfirm, setIsViewConfirm] = useState(false);
  const [lessonName, setLessonName] = useState('');
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [dateList, setDateList] = useState([]);
  const [useData, setUseData] = useState([{
    workoutId: 0,
    scheduleId: 0,
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    classDate: moment(),
    title: '작성이 필요합니다.',
    sectionList: []
  }]);

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  const TopLevelRef = useRef();
  const ExerciseRef = useRef();
  const ClassSelectRef = useRef();
  const ScheduleRef = useRef();
  const CalenderRef = useRef();

  // View에 띄울 컴포넌트 크기 확인 //
  useEffect(() => {
    // console.log(size.width);
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      } 
    }
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek]) 
  
  useEffect(() => {

    if (scheduleId === -1) return;
    
    var data = {
      scheduleId: scheduleId,
      month: date.clone().format('MM')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.postDataOfExercise('workout/ranking', 'POST', data);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          var workoutData = responseData.data.slice();

          // 운동 데이터 정리 //
          for (var i = 0; i < workoutData.length; i++) {

            workoutData[i].sectionList.sort(function (a, b) {
              return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
            })

            for (var j = 0; j < workoutData[i].sectionList.length; j++) {
              workoutData[i].sectionList[j].buyinExerciseList = [];
              workoutData[i].sectionList[j].mainExerciseList = [];
              workoutData[i].sectionList[j].buyoutExerciseList = [];
              workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');

              // Section Record Data 정리 //
              for (var u = 0; u < workoutData[i].sectionList[j].sectionRecordList.length; u++) {

                workoutData[i].sectionList[j].sectionRecordList[u].buyinExerciseList = [];
                workoutData[i].sectionList[j].sectionRecordList[u].mainExerciseList = [];
                workoutData[i].sectionList[j].sectionRecordList[u].buyoutExerciseList = [];


                for (var h = 0; h < workoutData[i].sectionList[j].sectionRecordList[u].exerciseList.length; h++) {
                  workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movementId = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movement.movementId;
                  workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movementName = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movement.movementName;
                  workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movement.videoLinkId;

                  if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Buyin') {
                    workoutData[i].sectionList[j].sectionRecordList[u].buyinExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Main') {
                    workoutData[i].sectionList[j].sectionRecordList[u].mainExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Buyout') {
                    workoutData[i].sectionList[j].sectionRecordList[u].buyoutExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
                  }
                }
              }

              for (var h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
                workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
                workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
                workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;

                if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
                  workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
                  workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
                  workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
              }

              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyinExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyoutExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
            }
          }

          setData(workoutData);
        }
        else if (responseData.status === 204) setData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, date, scheduleId, rendering])

  useEffect(() => {
    if (dateList.length === 0) return;

    var newData = data.slice();
    
    if (newData.length === 0) { // 데이터가 없으면 1개 넣고 시작
      var tmpData = {
        workoutId: 0,
        scheduleId: 0,
        storeId: parseInt(window.localStorage.getItem('StoreId')),
        classDate: dateList[0],
        title: '',
        sectionList: []
      }
    }

    for (var i = 0; i < dateList.length; i++) {
      var isNone = true;

      for (var j = 0; j < newData.length; j++) {
        if (dateList[i].format('MM.DD') === moment(newData[j].classDate).format('MM.DD')) {
          isNone = false;
          newData[j].classDate = moment(newData[j].classDate);
          break;
        }
      }
      
      if (isNone) {
        tmpData = {
          workoutId: 0,
          scheduleId: 0,
          storeId: parseInt(window.localStorage.getItem('StoreId')),
          classDate: dateList[i],
          title: '',
          sectionList: []
        }
        newData.push(tmpData);
      }
    }
    
		newData.sort(function (a, b) {
			return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
		})
    
    setUseData(newData);
  }, [dateList, data])


  // 순위 정리 //
  useEffect(() => {
    var userRankingList = [];

    for (var i = 0; i < useData.length; i++) {
      if (useData[i].classDate.format('YYYY.MM.DD') === clickDate) {
        // setSectionData(useData[i].sectionList);

        if (useData[i].sectionList[nowSection] === undefined) {
          setRankingList([]);
          return;
        }
        
       
        var tmpList = [];

        // Custom인 경우 순위 따로 산출 //
        if (useData[i].sectionList[nowSection].tag === 'Custom') {
          // 순위표 구성을 위한 커스텀 기준데이터 확인 //
          // 점수 산출 방법이 없을 경우 ( 입력 순서대로 오름차순 )//
          if (useData[i].sectionList[nowSection].scoreKeyword === 'Not Scored') {
            tmpList = useData[i].sectionList[nowSection].sectionRecordList.slice();

            tmpList.sort(function (a, b) {
              return a.createdDate < b.createdDate ? -1 : a.createdDate < b.createdDate ? 1 : 0;
            })

            var totalScore = tmpList.length;

            for (var j = 0; j < tmpList.length; j++) {
              totalScore -= j;

              // 이미 저장된 내역 확인 Flag //
              var isExist = false;
              // 이미 저장된 유저 점수가 있는지 확인 //
              for (var x = 0; x < userRankingList.length; x++) {
                // 이미 점수가 있으면 기존점수에 + 하기 //
                if (userRankingList[x].linkCoachId === tmpList[j].linkCoachId) {
                  totalScore += totalScore;
                  // 저장 플래그 //
                  isExist = true;
                }
              }

              // 저장된게 없으면 //
              if (!isExist) {
                var rankingInfo = {
                  linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                  userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                  userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                  createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,

                  totalScore: totalScore
                }

                userRankingList.push(rankingInfo);
              }
            }
          }
          // 시간 기준 점수 산출일 경우 //
          else if (useData[i].sectionList[nowSection].scoreKeyword === 'Time') {
            // 높은 점수 기준 //
            if (useData[i].sectionList[nowSection].scorePriority === 'heigher') {
              for (var j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
                totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].recordTime;

                // 이미 저장된 내역 확인 Flag //
                var isExist = false;
                // 이미 저장된 유저 점수가 있는지 확인 //
                for (var x = 0; x < userRankingList.length; x++) {
                  // 이미 점수가 있으면 기존점수에 + 하기 //
                  if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                    totalScore += totalScore;
                    // 저장 플래그 //
                    isExist = true;
                  }
                }

                // 저장된게 없으면 //
                if (!isExist) {
                  var rankingInfo = {
                    linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                    userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                    userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                    createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,

                    totalScore: totalScore
                  }

                  userRankingList.push(rankingInfo);
                }
              }
            }
            // 낮은 점수 기준 //
            else {
              for (var j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
                totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].recordTime * -1);

                // 이미 저장된 내역 확인 Flag //
                var isExist = false;
                // 이미 저장된 유저 점수가 있는지 확인 //
                for (var x = 0; x < userRankingList.length; x++) {
                  // 이미 점수가 있으면 기존점수에 + 하기 //
                  if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                    totalScore += totalScore;
                    // 저장 플래그 //
                    isExist = true;
                  }
                }

                // 저장된게 없으면 //
                if (!isExist) {
                  var rankingInfo = {
                    linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                    userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                    userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                    createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,

                    totalScore: totalScore
                  }

                  userRankingList.push(rankingInfo);
                }
              }
            }
          }
          // 횟수, 무게, 거리, 포인트 기준 점수 산출일 경우 //
          else if (useData[i].sectionList[nowSection].scoreKeyword === 'Reps'
            || useData[i].sectionList[nowSection].scoreKeyword === 'Weight'
            || useData[i].sectionList[nowSection].scoreKeyword === 'Distance'
            || useData[i].sectionList[nowSection].scoreKeyword === 'Points'
            || useData[i].sectionList[nowSection].scoreKeyword === 'Peak Watts'
            || useData[i].sectionList[nowSection].scoreKeyword === 'Max Speed') {
            // 높은 점수 기준 //
            if (useData[i].sectionList[nowSection].scorePriority === 'heigher') {
              for (var j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
                var totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue);

                // 이미 저장된 내역 확인 Flag //
                var isExist = false;
                // 이미 저장된 유저 점수가 있는지 확인 //
                for (var x = 0; x < userRankingList.length; x++) {
                  // 이미 점수가 있으면 기존점수에 + 하기 //
                  if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                    totalScore += totalScore;
                    // 저장 플래그 //
                    isExist = true;
                  }
                }

                // 저장된게 없으면 //
                if (!isExist) {
                  var rankingInfo = {
                    linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                    userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                    userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                    createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,

                    totalScore: totalScore
                  }

                  userRankingList.push(rankingInfo);
                }
              }
            }
            // 낮은 점수 기준 //
            else {
              for (var j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
                var totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * -1);

                // 이미 저장된 내역 확인 Flag //
                var isExist = false;
                // 이미 저장된 유저 점수가 있는지 확인 //
                for (var x = 0; x < userRankingList.length; x++) {
                  // 이미 점수가 있으면 기존점수에 + 하기 //
                  if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                    totalScore += totalScore;
                    // 저장 플래그 //
                    isExist = true;
                  }
                }

                // 저장된게 없으면 //
                if (!isExist) {
                  var rankingInfo = {
                    linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                    userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                    userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                    createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,

                    totalScore: totalScore
                  }

                  userRankingList.push(rankingInfo);
                }
              }
            }
          }
          // 라운드 횟수 기준 점수 산출일 경우 //
          else if (useData[i].sectionList[nowSection].scoreKeyword === 'Rounds And Reps') {
            // 높은 점수 기준 //
            if (useData[i].sectionList[nowSection].scorePriority === 'heigher') {
              for (var j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
                var round = (useData[i].sectionList[nowSection].sectionRecordList[j].customLastRound * 100);
                var reps = (useData[i].sectionList[nowSection].sectionRecordList[j].customLastReps);

                totalScore += (round + reps);

                // 이미 저장된 내역 확인 Flag //
                var isExist = false;
                // 이미 저장된 유저 점수가 있는지 확인 //
                for (var x = 0; x < userRankingList.length; x++) {
                  // 이미 점수가 있으면 기존점수에 + 하기 //
                  if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                    totalScore += totalScore;
                    // 저장 플래그 //
                    isExist = true;
                  }
                }

                // 저장된게 없으면 //
                if (!isExist) {
                  var rankingInfo = {
                    linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                    userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                    userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                    createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,

                    totalScore: totalScore
                  }

                  userRankingList.push(rankingInfo);
                }
              }
            }
            // 낮은 점수 기준 //
            else {
              for (var j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
                var round = (useData[i].sectionList[nowSection].sectionRecordList[j].customLastRound * 100);
                var reps = (useData[i].sectionList[nowSection].sectionRecordList[j].customLastReps);

                totalScore += ((round + reps) * -1);

                // 이미 저장된 내역 확인 Flag //
                var isExist = false;
                // 이미 저장된 유저 점수가 있는지 확인 //
                for (var x = 0; x < userRankingList.length; x++) {
                  // 이미 점수가 있으면 기존점수에 + 하기 //
                  if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                    totalScore += totalScore;
                    // 저장 플래그 //
                    isExist = true;
                  }
                }

                // 저장된게 없으면 //
                if (!isExist) {
                  var rankingInfo = {
                    linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                    userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                    userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                    createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,

                    totalScore: totalScore
                  }

                  userRankingList.push(rankingInfo);
                }
              }
            }
          }
        }
        // Custom이 아닌경우 점수로 순위 산출 //
        else {
          for (var h = 0; h < useData[i].sectionList[nowSection].sectionRecordList.length; h++) {
            var round = 0;
            var roundOfCount = 0;
            var weight = 0;
            var reps = 0;
            var calories = 0;
            var distance = 0;
            var height = 0;
            var timeRemaining = 0;
            var successScore = 0;

            // 운동 내역 전체 점수 부여 ( 아예 진행하지 않은 운동은 점수 부여 제외 ) //
            if (useData[i].sectionList[nowSection].sectionRecordList[h].success !== 'Not Done') {

              for (var z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList.length; z++) {
                // 무게 전부 더하기 //
                weight += useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].weight;
                // 횟수 전부 더하기 //
                reps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].count);
                // 칼로리 전부 더하기 //
                calories += useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].calories;
                // 거리 전부 더하기 //
                distance += useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].distance;
                // 높이 전부 더하기 //
                height += useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].height;
              }

              var roundOfCountList = parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].roundOfCount.split('-'));

              for (var z = 0; z < roundOfCountList.length; z++) {
                roundOfCount += roundOfCountList[z];
              }

              // 제한시간이 설정되어있는 경우 ( 제한시간 - 진행한 시간 ) 으로 추산 // 
              if (useData[i].sectionList[nowSection].isUseTimeLimit) {
                timeRemaining += useData[i].sectionList[nowSection].timeLimit - useData[i].sectionList[nowSection].sectionRecordList[h].recordTime;
              }

              // 운동을 끝까지 완료한 경우 //
              if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Success') {
                // 진행을 완료하였으면 10점 //
                successScore += 10;
                // 진행한 라운드 가산점 ( 운동 성공 => 전체 라운드 * 운동 개수 ) //
                round = useData[i].sectionList[nowSection].round * useData[i].sectionList[nowSection].exerciseList.length;
              }
              // 운동이 중간에 종료된 경우 //
              else {
                // 마지막 진행한 운동 찾기 //
                var division = 'Buyin';
                var index = 0;

                // 마지막 운동 찾기 //
                for (var z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList.length; z++) {
                  if (useData[i].sectionList[nowSection].sectionRecordList[h].lastExerciseId === useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].exerciseId) {
                    division = useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].division;
                    index = useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].exerciseIndex;
                  }
                }

                // 진행한 라운드 가산점 ( 운동 중도 포기 => 완벽히 이행한 라운드 * 운동 개수 + 마지막 운동 개수) //
                round = useData[i].sectionList[nowSection].sectionRecordList[h].completeRound * useData[i].sectionList[nowSection].exerciseList.length;

                // 이행하지 못하고 남은 라운드 //
                var remainingRound = useData[i].sectionList[nowSection].round - useData[i].sectionList[nowSection].sectionRecordList[h].completeRound;

                // Buyin에서 멈췄을 경우 //
                if (division === 'Buyin') {
                  round += (remainingRound + ((index * 0.1) * useData[i].sectionList[nowSection].sectionRecordList[h].lastRound)) * 0.3
                }
                // Main에서 멈췄을 경우 //
                else if (division === 'Buyin') {
                  round += (remainingRound + ((index * 0.1) * useData[i].sectionList[nowSection].sectionRecordList[h].lastRound)) * 0.6
                }
                // Buyout에서 멈췄을 경우 //
                else if (division === 'Buyout') {
                  round += (remainingRound + ((index * 0.1) * useData[i].sectionList[nowSection].sectionRecordList[h].lastRound)) * 0.9
                }
              }
            }

            // 이미 저장된 내역 확인 Flag //
            var isExist = false;
            // 이미 저장된 유저 점수가 있는지 확인 //
            for (var x = 0; x < userRankingList.length; x++) {
              // 이미 점수가 있으면 기존점수에 + 하기 //
              if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[h].linkCoachId) {
                userRankingList[x].round += (round * 1000000);
                userRankingList[x].roundOfCount += (roundOfCount * 100);
                userRankingList[x].weight += (weight * 10000)
                userRankingList[x].reps += reps * 100;
                userRankingList[x].calories = calories;
                userRankingList[x].distance = distance * 0.01;
                userRankingList[x].height = height * 0.0001;
                userRankingList[x].timeRemaining += timeRemaining * 0.0001;
                userRankingList[x].successScore += successScore;
                userRankingList[x].totalScore += ((round * 1000000) + (roundOfCount * 100) + (weight * 10000) + (reps * 100) + (calories) + (distance * 0.01) + (height * 0.0001) + timeRemaining * 0.0001 + successScore);
                // 저장 플래그 //
                isExist = true;
              }
            }

            // 저장된게 없으면 //
            if (!isExist) {
              var rankingInfo = {
                linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[h].linkCoachId,
                userName: useData[i].sectionList[nowSection].sectionRecordList[h].userName,
                userProfile: useData[i].sectionList[nowSection].sectionRecordList[h].userProfile,
                createdDate: useData[i].sectionList[nowSection].sectionRecordList[h].createdDate,
                round: (round * 1000000),
                roundOfCount: (roundOfCount * 100),
                weight: (weight * 10000),
                reps: reps * 100,
                calories: calories,
                distance: distance * 0.01,
                height: height * 0.0001,
                timeRemaining: timeRemaining * 0.0001,
                successScore: successScore,
                totalScore: ((round * 1000000) + (roundOfCount * 100) + (weight * 10000) + (reps * 100) + (calories) + (distance * 0.01) + (height * 0.0001) + timeRemaining * 0.0001 + successScore)
              }

              userRankingList.push(rankingInfo);
            }
          }
        }
      }
    }

    userRankingList.sort(function (a, b) {
      return a.totalScore > b.totalScore ? -1 : a.totalScore < b.totalScore ? 1 : 0;
    })

    setRankingList(userRankingList);
  }, [useData, clickDate, nowSection])

  // 저장 모달에 띄울 수업 이름 저장 //
  useEffect(() => {
    setLessonName(ClassSelectRef.current.getValue().className);
    // setWorkoutId(ExerciseRef.current.getWorkoutId());
  }, [isViewConfirm, ClassSelectRef, ExerciseRef])

  // 스케줄 변경 가능여부 확인 //
  function checkAvailableChangeScheduleData() {
    if (isClickedModify) {
      var exerciseData = ExerciseRef.current.getValue();

      if (exerciseData.length === 0) {
        setIsClickedModify(false);
        setIsClickedDetail(false);
        return true;
      }
      else return false;
    }
    else {
      setIsClickedDetail(false);
      return true;
    }
  }

  // 일정표내의 날짜 클릭시 //
  function dateClick(value) {
    setIsClickedDetail(false);
    // 운동 내용 변경중인지 확인 //
    if (isClickedModify) {
      var exerciseData = ExerciseRef.current.getValue();

      if (exerciseData.length === 0) {
        setIsClickedModify(false);

        setClickDate(value);
  
        ScheduleRef.current.setValue(value);
        CalenderRef.current.setValue(value);
      }
      else setIsViewConfirm(true);
    }
    else {
      setClickDate(value);

      ScheduleRef.current.setValue(value);
      CalenderRef.current.setValue(value);
    }
  }

  // 월 단위 날짜 변경 클릭시 //
  function onClickDateArrow(division) {
    setIsClickedDetail(false);
    if (isClickedModify) setIsViewConfirm(true);
    else {
      if (division === 'Prev') setDate(date.clone().subtract(1, 'month'));
      else if (division === 'Next') setDate(date.clone().add(1, 'month'));
    }
  }

  // 스크롤 애니메이션를 위한 UI제어 //
  function changeTopLevelWrapperPosition() {
    if (TopLevelRef.current.style.position === 'fixed') {
      TopLevelRef.current.style.position = 'relative';
    }
    else {
      TopLevelRef.current.style.position = 'fixed';
      TopLevelRef.current.style.top = '0px';
    }
  }

  function settingHeight(height) {
    setScheduleHeight(height);
  }

  // 자세히 보기 클릭시 //
  function onClickDetailView(data) {
    setNowClickLinkCoachId(data.linkCoachId);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TopBarWrapper isOver={isOver}>
        <TextWrapper>
          <Title isOver={isOver}>순위표</Title>
          <Explanation isOver={isOver}>운동 프로그램 별 수행 순위를 확인할 수 있어요</Explanation>
        </TextWrapper>
      </TopBarWrapper>
      <TopButtonWrapper isOver={isOver}>
        <LeftWrapper isOver={isOver}>
          <TestClassSelectBox ref={ClassSelectRef} isOver={isOver} date={date} setScheduleId={setScheduleId} checkAvailableChangeScheduleData={checkAvailableChangeScheduleData} rendering={() => setRendering(!rendering)}/>
          <DateButtonWrapper>
            <ArrowWrapper onClick={() => onClickDateArrow('Prev')}>
              <ArrowImage src={LeftArrow}/>
            </ArrowWrapper>
            <DateText>{date.clone().format('YYYY년 MM월')}</DateText>
            <ArrowWrapper onClick={() => onClickDateArrow('Next')} >
              <ArrowImage src={RightArrow}/>
            </ArrowWrapper>
          </DateButtonWrapper>
        </LeftWrapper>
      </TopButtonWrapper>
      <MainView>
        <LeftComponent isOver={isOver}>
          <ScheduleBox 
            ref={ScheduleRef} 
            isOver={isOver}
            clickDate={clickDate} 
            useData={useData} 
            dateClickFn={dateClick}
            height={scheduleHeight}
            changeTopLevelWrapperPosition={changeTopLevelWrapperPosition}/>
          <CalenderView ref={CalenderRef} isOver={isOver} clickDate={clickDate} date={date} dateClickFn={dateClick} heightFn={settingHeight}/>
        </LeftComponent>
        {
          isClickedDetail ?
          <RankingExerciseBox 
            isOver={isOver}
            clickDate={clickDate} 
            useData={useData} 
            rankingList={rankingList}
            nowSection={nowSection}
            setNowSection={setNowSection}
            setIsClickedDetail={setIsClickedDetail}
            nowClickLinkCoachId={nowClickLinkCoachId}
            changeTopLevelWrapperPosition={changeTopLevelWrapperPosition}/>
          :
          <RankingBox  
            isOver={isOver}
            useData={useData} 
            clickDate={clickDate}
            rankingList={rankingList}
            setRankingList={setRankingList}
            isClickedDetail={isClickedDetail}
            setIsClickedDetail={setIsClickedDetail}
            nowSection={nowSection} 
            setNowSection={setNowSection}
            onClickDetailView={onClickDetailView}/>
        }
       </MainView>
    </TopLevelWrapper>
  )
}

export default RankingTotalView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // margin-left: 34px;
  
  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: ${props => props.isOver ? `28px` : `14px`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 38px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: center;
  align-items: ${props => props.isOver ? `center` : `flex-start`};

  gap: 30px;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 205px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  gap: 37px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const MainView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 30px;
  margin-top: 16px;
  margin-bottom: 255px;
`;

const LeftComponent = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: center;
  align-items: center;

  gap: 30px;
`;