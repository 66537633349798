import { React, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

const StartCountInput = forwardRef((props, ref) => {
  const [nowClick, setNowClick] = useState(0);

  useImperativeHandle(ref, () => ({
		getValue: () => {
			return nowClick;
		}
	}));

  return (
    <TopLevelWrapper>
      <Box isClicked={nowClick === 0} onClick={() => setNowClick(0)}>없음</Box>
      <Box isClicked={nowClick === 3} onClick={() => setNowClick(3)}>3초</Box>
      <Box isClicked={nowClick === 5} onClick={() => setNowClick(5)}>5초</Box>
    </TopLevelWrapper>
  )
})

export default StartCountInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 86px;
  height: 34px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 14px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #777777`};
    color: ${props => props.isClicked ? `#FFFFFF` : `#777777`};
  }
`;