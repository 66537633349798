import { React, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import MemberInfo from './MemberInfo.js';
import TodayClass from './TodayClass.js';
import BoardView from './BoardView.js';
import LockerView from './LockerView.js';
import NoticeView from './NoticeView.js';
import useWindowSize from '../../Share/Normal/useWindowSize.js';

function MainView() {
  // const nowPage = window.location.href;
  // console.log(window.location.href);

  const size = useWindowSize();

  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])
  
  return (
    <TopLevelWrapper isOver={isOver}>
      <MemberInfo isOver={isOver}/>
      <TodayClass isOver={isOver}/> 
      <BoardView isOver={isOver}/>
      <LockerView isOver={isOver}/>
      {/* <NoticeView isOver={isOver}/> */}
    </TopLevelWrapper>
  )
}

export default MainView;

const startAnimation = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0.5; } 
  100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // vertical-align: bottom;
  background-color: #FDFCFB;
  
  gap: ${props => props.isOver ? `38px` : `13px`};

  animation-name: ${startAnimation};
  animation-duration: 0.3s;
`;
