import { React, useRef } from 'react';
import styled from 'styled-components';

import SearchImage from '../../image/SearchImage.png';

function MovementSearchInput(props) {
  const { value, setValue, disabled } = props;

  const InputRef = useRef();

  function handleChange(e) {
		setValue({ movementName: e.target.value });
	}

	function handleFocus(e) {
    
	}

	function handleBlur(e) {

	}

  return (
    <TopLevelWrapper disabled={disabled}>
      <SearchIcon src={SearchImage}/>
      <Input
        ref={InputRef}
        type='text'
        placeholder='동작을 검색해 주세요'
        value={value.movementName}
        disabled={disabled}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  )
}

export default MovementSearchInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: calc(100% - 18px);
  height: 30px;

  gap: 8px;
  margin-top: 16px;
  padding: 0 8px 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: ${props => props.disabled ? `#ECECEC` : `#FFFFFF`};

  transition: all 0.3s ease-in-out;
`;

const SearchIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const Input = styled.input`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  border: 0px;

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  outline: none;

  background-color: ${props => props.disabled ? '#ECECEC' : `#FFFFFF`};

  ::placeholder {
    color: #CBCBCB;
  }

  ::disabled {
    background-color: #ECECEC;
  }

  transition: all 0.3s ease-in-out;
`;