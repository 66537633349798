import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import NextButtonImage from '../../../../image/NormalNextInfoButton.png';
import ColorPrevButtonImage from '../../../../image/ColorPrevInfoButton.png';

const SecondCourseHistoryColumnView = (props) => {
  const { isOver, prevFn } = props;

  return (
    <TopLevelWrapper isOver={isOver}>
      {
        isOver ?
          <ColumnWrapper>
            <Column style={{ marginRight: 'max(53px, 6.901041666666667vw)', marginLeft: 'max(28px, 3.645833333333333vw)' }} isOver={isOver}>강사</Column>
            <Column style={{ marginRight: 'max(118px, 15.36458333333333vw)' }} isOver={isOver}>사용 회원권</Column>
            <Column style={{ marginRight: 'max(28px, 3.645833333333333vw)' }} isOver={isOver}>차감 횟수</Column>
            <Column style={{ marginRight: 'max(151px, 19.66145833333333vw)' }} isOver={isOver}>상태</Column>
          </ColumnWrapper>
          :
          <ColumnWrapper>
            <Column style={{ marginRight: 'max(24px, 7.5vw)', marginLeft: 'max(5px, 1vw)' }} isOver={isOver}>강사</Column>
            <Column style={{ marginRight: 'max(28px, 8.75vw)' }} isOver={isOver}>사용 회원권</Column>
            <Column style={{ marginRight: 'max(18px, 5.625vw)' }} isOver={isOver}>차감 횟수</Column>
            <Column isOver={isOver}>상태</Column>
          </ColumnWrapper>
      }
      {
        isOver ?
          <ButtonWrapper isOver={isOver}>
            <Button src={ColorPrevButtonImage} isOver={isOver} onClick={prevFn} />
            <Column isOver={isOver}>이전 정보</Column>
            <Button src={NextButtonImage} isOver={isOver} />
          </ButtonWrapper>
          :
          <ButtonWrapper isOver={isOver}>
            <Button src={ColorPrevButtonImage} isOver={isOver} onClick={prevFn} />
            <Button src={NextButtonImage} isOver={isOver}/>
          </ButtonWrapper>
      }
    </TopLevelWrapper>
  )
}

export default SecondCourseHistoryColumnView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: ${props => props.isOver ? `34px` : `23px`};
  margin-bottom: ${props => props.isOver ? `18px` : `15px`};

  width: ${props => props.isOver ? `` : `max(262px, 81.875vw)`};
  
  transition: all 0.3s ease-in-out;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `4px` : `6px`};
`;

const Button = styled.img`
  width: ${props => props.isOver ? `24px` : `15px`};
  height: ${props => props.isOver ? `24px` : `15px`};

  border-radius: ${props => props.isOver ? `24px` : `15px`};

  :hover {
    cursor: pointer;
  }
`;