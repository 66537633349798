import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import PhoneInput from "./PhoneInput";

import FetchModule from "../../Share/Network/FetchModule";
import moment from "moment";
import WaittingModal from "./WaittingModal";
import AlertModal from "./AlertModal";
import ClassSelectModal from "./ClassSelectModal";

import EntranceMP3 from '../../../audio/Entrance.mp3';
import ExpireErrorMP3 from '../../../audio/ExpireError.mp3';
import DuplicateMemberSelectModal from "./DuplicateMemberSelectModal";
import HoldingStateModal from "./HoldingStateModal";
import Image from "../../../Component/Share/Image";
import WeekAttendanceStatusView from "./WeekAttendanceStatusView";

import DefaultProfile from '../../../image/DefaultProfile.png';
import TicketWhite from '../../../image/TicketWhite.png';
import TicketGreen from '../../../image/TicketGreen.png';
import CloseEyesRingRing from '../../../image/CloseEyesRingRing.png';
import OpenEyesRingRing from '../../../image/OpenEyesRingRing.png';
import MondayRingRing from '../../../image/MondayRingRing.png';
import TuesdayRingRing from '../../../image/TuesdayRingRing.png';
import WedsdayRingRing from '../../../image/WednesdayRingRing.png';
import ThursdayRingRing from '../../../image/ThursdayRingRing.png';
import FridayRingRing from '../../../image/FridayRingRing.png';
import NotFoundedRingRing from '../../../image/NotFoundRingRing.png';
import RingRingHands from '../../../image/RingRingHands.png';
import RingRingHand from '../../../image/RingRingHand.png';
import PoweredBy from '../../../image/PoweredBy.png';
import NotFoundView from "./NotFoundView";

export default function KeypadView() {

  const history = useHistory();

  const [isProcessing, setIsProcessing] = useState(false);
  const [isViewAlert, setIsViewAlert] = useState(false);
  const [firstAlertText, setFirstAlertText] = useState('출석이 완료되었습니다');
  const [secondAlertText, setSecondAlertText] = useState('5초뒤 메인화면으로 이동합니다');
  const [processingText, setProcessingText] = useState('');
  const [middlePhone, setMiddlePhone] = useState('');
  const [lastPhone, setLastPhone] = useState('');
  const [memberInfo, setMemberInfo] = useState({
    profile: '',
  });
  const [usages, setUsages] = useState([]);

  const [scheduleData, setScheduleData] = useState([]);
  const [breakTimeData, setBreakTimeData] = useState([]);
  const [useScheduleData, setUseScheduleData] = useState([]);
  const [reserveData, setReserveData] = useState([]);
  const [today, setToday] = useState(moment());
  const [isInputPhone, setIsInputPhone] = useState(false);

  const [clickTicket, setClickTicket] = useState({

  });

  const [duplicateMembers, setDuplicateMembers] = useState([]);

  const [holdingUsage, setHoldingUsage] = useState(undefined);

  const [isSelectClass, setIsSelectBox] = useState(false);
  const [isDuplicateView, setIsDuplicateView] = useState(false);
  const [isViewHoldingModal, setIsViewHoldingModal] = useState(false);

  const [isViewCompleteAttendance, setIsViewCompleteAttendance] = useState(false);
  const [dayOfTheWeek, setDayOfWeek] = useState('Mon');
  const [attendanceData, setAttendanceData] = useState({
    storeName: '',
    totalAttendance: 0,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false
  });

  const KeypadRef = useRef();
  const InfoRef = useRef();
  const MiddleRef = useRef();
  const LastRef = useRef();
  const LogoRef = useRef();


  // Schedule Data API호출
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('schedule/', 'POST');
        // console.log(responseData.data);
        if (responseData.status === 200) setScheduleData(responseData.data);
      }
    )();
  }, [history])

  // Schedule Data를 사용 가능한 Data로 가공
  useLayoutEffect(() => {
    var tmp = scheduleData.slice();
    var newScheduleData = [];

    for (var i = 0; i < tmp.length; i++) {

      var endPoint = moment(tmp[i].classDate.split('T')[0]);

      while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {
        if (endPoint.diff(today, 'days') >= 30) break;

        if (tmp[i].applyWeek.includes(endPoint.format('ddd')) || tmp[i].applyWeek === 'All') {
          var colSpan = 1;
          var rowSpan = 1;

          // if (!clickLessonIds.includes(tmp[lessonId])) break;
          // 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
          colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);

          var newData = {
            classId: tmp[i].classId,
            classDate: endPoint.format('YYYY.MM.DD'),
            className: tmp[i].className,
            classTime: tmp[i].classTime,
            color: tmp[i].color,
            endDate: tmp[i].endDate,
            lessonId: tmp[i].lessonId,
            scheduleId: tmp[i].scheduleId,
            teacherName: tmp[i].teacherName,
            profile: tmp[i].profile,
            applyWeek: tmp[i].applyWeek,
            rowSpan: rowSpan,
            colSpan: colSpan,
            maxBookedPeople: tmp[i].maxBookedPeople,
            deduction: tmp[i].deduction
          }
          newScheduleData.push(newData);
        }
        endPoint = endPoint.clone().add(1, 'day');
      }
    }

    newScheduleData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    var newData = [];

    for (i = 0; i < newScheduleData.length; i++) {

      if (newScheduleData[i].classDate === today.clone().format('YYYY.MM.DD')) {
        newData.push(newScheduleData[i]);
      }
    }

    // breaktime 데이터 가공
    for (var z = 0; z < breakTimeData.length; z++) {
      if (breakTimeData[z].applyWeek.includes(today.clone().format('ddd')) || breakTimeData[z].applyWeek === 'All') {

        var breakData = {
          classId: -1,
          lessonId: -1,
          classDate: today.clone().format('YYYY.MM.DD'),
          color: '#ECECEC',
          classTime: breakTimeData[z].startTime + '-' + breakTimeData[z].endTime,
          className: '브레이크 타임',
          applyWeek: breakTimeData[z].applyWeek
        }

        newData.push(breakData);
      }
    }

    newData.sort(function (a, b) {
      return a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0;
    })

    // TimeDivisionLine에서 사용할 데이터 가공
    for (i = 0; i < newData.length; i++) {
      tmp = newData[i].classTime.split('-');
      var splitTmp = tmp[0].split(':');
      var Time = '';

      if (parseInt(splitTmp[0]) > 12) {
        newData[i].startTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
        Time = (parseInt(splitTmp[0]) - 12) + ' PM';
      }
      else {
        newData[i].startTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
        Time = parseInt(splitTmp[0]) + ' AM';
      }

      tmp = newData[i].classTime.split('-');
      splitTmp = tmp[1].split(':');

      if (parseInt(splitTmp[0]) > 12) {
        newData[i].endTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
      }

      else {
        newData[i].endTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
      }

      if (i !== 0 && newData[i - 1].startTime.split(':')[0] === newData[i].startTime.split(':')[0]) {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: false })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
      else {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: true })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
    }

    var newReserve = [];

    for (var h = 0; h < newData.length; h++) {
      newReserve.push(new Array());
    }

    setReserveData(newReserve);
    setUseScheduleData(newData);

  }, [scheduleData, breakTimeData, today])

  // 숫자 클릭시 //
  function onClickNumber(number) {
    // 링링이 눈감기기 //
    if (!isInputPhone) setIsInputPhone(true);

    if (lastPhone.length < 4) setLastPhone(lastPhone + number);
  }

  // 수정 클릭시 //
  function onClickRemove() {
    if (lastPhone.length === 1 && isInputPhone) setIsInputPhone(false);
    if (lastPhone.length > 0) setLastPhone(lastPhone.slice(0, lastPhone.length - 1));
  }

  // 확인 클릭시 //
  function onClickFindInfo() {
    setToday(moment());

    const audio = new Audio();

    if (lastPhone.length !== 4) {
      setLastPhone('');

      setFirstAlertText('올바른 전화번호를 입력해주세요');
      setSecondAlertText('');

      setIsViewAlert(true);
      setIsProcessing(false);
      setIsInputPhone(false);

      setLastPhone('');

      audio.src = ExpireErrorMP3;
      audio.play();

      setTimeout(() => {
        setIsViewAlert(false);
      }, 5000)

      return;
    }

    setProcessingText('회원권을 확인중 입니다');
    setIsProcessing(true);
    setIsInputPhone(false);

    let requestData = {
      phone: lastPhone
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('reserve/keypad', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          setMemberInfo(responseData.data);

          let usages = responseData.data.usages.slice();

          for (let i = 0; i < usages.length; i++) {
            let ids = usages[i].availableLessonIds.split(',');

            let newIds = [];

            for (let j = 0; j < ids.length; j++) {
              newIds.push(parseInt(ids[j]));
            }

            usages[i].lessonIds = newIds;
          }

          let finalUsage = [];

          for (let i = 0; i < usages.length; i++) {
            if (usages[i].count === undefined || usages[i].totalMax > usages[i].count || usages[i].totalMax === -1) {
              finalUsage.push(usages[i]);
            }
          }
          // scaleRepsFemaleRankData.sort((a, b) => b.completeRep - a.completeRep || a.tieBreak - b.tieBreak);
          // 회원권 시작날짜 순으로 오름차순 정렬 //
          finalUsage.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

          setUsages(finalUsage);

          if (usages.length === 1) {

            // 1개 소유하고 있는 사람의 회원권이 홀딩중일 경우 //
            if (usages[0].isHolding) {
              setIsViewHoldingModal(true);
              setHoldingUsage(usages[0]);
              setIsProcessing(false);
            }
            // 홀딩중이 아닐경우 //
            else {
              let checkRequestData = {
                usageId: usages[0].usageId
              };

              (
                async () => {
                  const checkResponseData = await fetchModule.postDataVersion2('reserve/attendance/check', 'POST', checkRequestData);

                  if (checkResponseData.status === 200) {
                    // setFirstAlertText('출석이 완료되었습니다');
                    // setSecondAlertText('3초뒤 메인화면으로 이동합니다');

                    audio.src = EntranceMP3;
                    audio.play();

                    setAttendanceData(checkResponseData.data);
                    setDayOfWeek(moment().format('ddd'));
                    setIsViewCompleteAttendance(true);
                    setIsProcessing(false);

                    // setIsViewAlert(true);
                    // setIsProcessing(false);

                    setTimeout(() => {
                      window.location.reload(true);
                    }, 5000)
                  }
                  else {
                    setIsProcessing(false);

                    setClickTicket(usages[0]);
                    setIsSelectBox(true);
                  }
                }
              )();
            }
          }
          else {

            setIsProcessing(false);

            InfoRef.current.style.zIndex = 1;
            KeypadRef.current.style.zIndex = 0;

            InfoRef.current.style.opacity = 1;
            KeypadRef.current.style.opacity = 0;
          }
        }
        // 유효회원권이 존재하지 않는 회원의 경우 //
        else if (responseData.status === 201) {
          // alert('사용가능한 회원권이 존재하지 않습니다.\n회원권을 구매해주세요');
          setFirstAlertText('사용가능한 회원권이 없습니다');
          setSecondAlertText('회원권 구매 후 이용해주세요');

          setIsViewAlert(true);
          setIsViewCompleteAttendance(false);
          setIsProcessing(false);

          setMiddlePhone('');
          setLastPhone('');

          audio.src = ExpireErrorMP3;
          audio.play();

          setTimeout(() => {
            setIsViewAlert(false);
          }, 5000)
          return;
        }
        else if (responseData.status === 202) {
          setFirstAlertText('금일 사용량을 초과하였습니다');
          setSecondAlertText('5초뒤 메인화면으로 이동합니다');

          setIsViewAlert(true);
          setIsViewCompleteAttendance(false);
          setIsProcessing(false);

          setMiddlePhone('');
          setLastPhone('');

          audio.src = ExpireErrorMP3;
          audio.play();

          setTimeout(() => {
            setIsViewAlert(false);
          }, 5000)
          return;
        }
        // 중복된 번호가 존재할 경우 //
        else if (responseData.status === 203) {
          setDuplicateMembers(responseData.data);
          setIsDuplicateView(true);
          setIsProcessing(false);
        }
        // 유효회원권이 존재하지 않는 회원의 경우 //
        else if (responseData.status === 204) {
          // alert('사용가능한 회원권이 존재하지 않습니다.\n회원권을 구매해주세요');
          setFirstAlertText('등록정보가 존재하지 않습니다.');
          setSecondAlertText('회원 등록 후 이용해주세요');

          setIsViewAlert(true);
          setIsViewCompleteAttendance(false);
          setIsProcessing(false);

          setMiddlePhone('');
          setLastPhone('');

          audio.src = ExpireErrorMP3;
          audio.play();

          setTimeout(() => {
            setIsViewAlert(false);
          }, 5000)
          return;
        }
        else if (responseData.status === 401) {
          setFirstAlertText('로그인 정보가 만료되었습니다.');
          setSecondAlertText('재 로그인 후 사용해주세요');

          setIsViewAlert(true);
          setIsViewCompleteAttendance(false);
          setIsProcessing(false);

          setMiddlePhone('');
          setLastPhone('');

          audio.src = ExpireErrorMP3;
          audio.play();

          setTimeout(() => {
            setIsViewAlert(false);
            window.close();
          }, 5000)
          return;
        }
        else {
          setFirstAlertText('회원권 조회에 실패했습니다');
          setSecondAlertText('잠시후 다시시도해주세요');

          setIsViewAlert(true);
          setIsViewCompleteAttendance(false);
          setIsProcessing(false);

          setMiddlePhone('');
          setLastPhone('');

          audio.src = ExpireErrorMP3;
          audio.play();

          setTimeout(() => {
            setIsViewAlert(false);
          }, 5000)
          return;
        }
      }
    )();
  }

  // 예약 내역 확인 텍스트 //
  function calcMyReserve(data) {
    if (data.classDate === undefined) return '없음';
    else {
      return moment(data.classDate).format('YY년 MM월 DD일') + ' ' + data.classTime.split('-')[0] + ' (' + data.lessonName + ')';
    }
  }

  // D-day 계산
  function calcMyRemainDate(data) {
    // 오늘이 끝나는 날이면 //
    if (moment(data.endDate).format('YYYY.MM.DD') === moment('YYYY.MM.DD')) return 'D-Day';
    else {
      return 'D-' + parseInt(moment.duration(moment(data.endDate).diff(moment())).asDays());
    }
  }

  // 남은 횟수 계산 //
  function calcMyRemainReserveCount(data) {
    // 오늘이 끝나는 날이면 //
    if (data.totalMax === -1) return '제한 없음';
    else {
      if (data.count === undefined) return '0/' + data.totalMax + '사용 횟수';
      else return data.count + '/' + data.totalMax + ' 사용 횟수';
    }
  }

  // 출석을 진행할 회원권 클릭시 //
  function onClickTicket(data) {
    const audio = new Audio();

    // 사용횟수가 초과된 경우 //
    if (data.count >= data.totalMax && data.totalMax !== -1) {

      // 최대 사용량을 초과하였습니다.
      setFirstAlertText('최대 사용량을 초과하였습니다');
      setSecondAlertText('다른 회원권을 사용해주세요');

      audio.src = ExpireErrorMP3;
      audio.play();

      setIsViewAlert(true);

      setTimeout(() => {
        // 데이터 초기화 //
        setIsViewAlert(false);
        window.location.reload(true);
      }, 3000)
    }
    else {
      setProcessingText('예약 내역을 확인중입니다.');
      setIsProcessing(true);

      if (data.isHolding) {
        setIsViewHoldingModal(true);
        setHoldingUsage(data);
        setIsProcessing(false);
      }
      else {
        let requestData = {
          usageId: data.usageId
        }
        // console.log(data);
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('reserve/attendance/check', 'POST', requestData);
            
            if (responseData.status === 200) {
              // setFirstAlertText('출석이 완료되었습니다');
              // setSecondAlertText('3초뒤 메인화면으로 이동합니다');

              audio.src = EntranceMP3;
              audio.play();

              // setIsViewAlert(true);
              // setIsProcessing(false);

              setAttendanceData(responseData.data);
              setDayOfWeek(moment().format('ddd'));
              setIsViewCompleteAttendance(true);
              setIsProcessing(false);

              setTimeout(() => {
                window.location.reload(true);
              }, 5000)
            }
            else if (responseData.status === 202) {
              // 금일 사용가능한 회원권이 아닙니다 //
              setFirstAlertText('사용가능한 회원권이 아닙니다');
              setSecondAlertText('3초뒤 메인화면으로 이동합니다');

              audio.src = ExpireErrorMP3;
              audio.play();

              setIsViewAlert(true);
              setIsProcessing(false);

              setTimeout(() => {
                window.location.reload(true);
              }, 5000)
            }
            else {
              setIsProcessing(false);

              setClickTicket(data);
              setIsSelectBox(true);
            }
          }
        )();
      }
    }
  }

  // 시간표가 아닌 방문을 클릭시 //
  function onClickEmptyClass() {
    let requestData = {
      usageId: clickTicket.usageId,
      reserveId: clickTicket.reserveId === undefined ? 0 : clickTicket.reserveId
    }

    setProcessingText('출석 처리를 진행중입니다');
    setIsProcessing(true);

    const audio = new Audio();

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('reserve/attendance', 'POST', requestData);
        
        if (responseData.status === 200) {
          // setFirstAlertText('출석이 완료되었습니다');
          // setSecondAlertText('3초뒤 메인화면으로 이동합니다');

          audio.src = EntranceMP3;
          audio.play();

          setAttendanceData(responseData.data);
          setDayOfWeek(moment().format('ddd'));
          setIsViewCompleteAttendance(true);
          setIsProcessing(false);

          setTimeout(() => {
            window.location.reload(true);
          }, 5000);
        }
        else if (responseData.status === 201) {
          // 금일 사용량을 초과하였습니다.
          setFirstAlertText('금일 사용량을 초과하였습니다');
          setSecondAlertText('5초뒤 메인화면으로 이동합니다');
          setIsProcessing(false);
          setIsViewAlert(true);

          audio.src = ExpireErrorMP3;
          audio.play();

          setTimeout(() => {
            window.location.reload(true);
          }, 5000);
        }
        else if (responseData.status === 202) {
          // 금주 사용량을 초과하였습니다.
          setFirstAlertText('금주 사용량을 초과하였습니다');
          setSecondAlertText('5초뒤 메인화면으로 이동합니다');
          setIsProcessing(false);
          setIsViewAlert(true);

          audio.src = ExpireErrorMP3;
          audio.play();

          setTimeout(() => {
            window.location.reload(true);
          }, 5000);
        }
        else if (responseData.status === 203) {
          // 최대 사용량을 초과하였습니다.
          setFirstAlertText('최대 사용량을 초과하였습니다');
          setSecondAlertText('5초뒤 메인화면으로 이동합니다');
          setIsProcessing(false);
          setIsViewAlert(true);

          audio.src = ExpireErrorMP3;
          audio.play();

          setTimeout(() => {
            window.location.reload(true);
          }, 5000);
        }
        // else if (responseData.status === 204 || responseData.status === 205) {
        //   // 예약내역이 없는경우.
        //   setFirstAlertText('올바르지 않은 예약 데이터입니다');
        //   setSecondAlertText('3초뒤 메인화면으로 이동합니다');
        // }
        // else if (responseData.status === 205) {
        //   // 올바르지 않은 예약 데이터의 경우 //
        //   setFirstAlertText('최대 사용량을 초과하였습니다');
        //   setSecondAlertText('3초뒤 메인화면으로 이동합니다');
        // }
        else {
          // 기타 에러
          setFirstAlertText('출석에 실패하였습니다.');
          setSecondAlertText('5초뒤 메인화면으로 이동합니다');
          setIsViewAlert(true);

          audio.src = ExpireErrorMP3;
          audio.play();

          setTimeout(() => {
            window.location.reload(true);
          }, 5000);
        }

        // setIsViewAlert(true);
        // setIsProcessing(false);
      }
    )();
  }

  // 출석을 진행할 수업을 선택시 //
  function onClickClass(data) {

    const audio = new Audio();
    console.log(data);
    console.log(clickTicket);
    // 예약 가능 수업이 아닌 경우 //
    if (!clickTicket.lessonIds.includes(data.lessonId)) {
      setFirstAlertText('예약이 불가능한 수업입니다');
      setSecondAlertText('다른 수업을 선택해주세요');

      setIsViewAlert(true);
      setIsProcessing(false);

      audio.src = ExpireErrorMP3;
      audio.play();

      setTimeout(() => {
        setIsViewAlert(false);
      }, 5000)

      return;
    }

    let requestData = {
      id: clickTicket.reserveId === undefined ? 0 : clickTicket.reserveId,
      scheduleId: data.scheduleId,
      lessonId: data.lessonId,
      classTime: data.classTime,
      classDate: data.classDate.replaceAll('.', '-') + 'T00:00:00',
      userId: '',
      status: 1,
      usageId: clickTicket.usageId
    }

    setProcessingText('출석 처리를 진행중입니다');
    setIsProcessing(true);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('reserve/attendance/class', 'POST', requestData);
        
        if (responseData.status === 200) {
          // setFirstAlertText('출석이 완료되었습니다');
          // setSecondAlertText('3초뒤 메인화면으로 이동합니다');

          audio.src = EntranceMP3;
          audio.play();

          setAttendanceData(responseData.data);
          setDayOfWeek(moment().format('ddd'));
          setIsViewCompleteAttendance(true);
          setIsProcessing(false);
  
          setTimeout(() => {
            // 데이터 초기화 //
            window.location.reload(true);
          }, 5000)
        }
        else if (responseData.status === 201) {
          // 금일 사용량을 초과하였습니다.
          setFirstAlertText('금일 사용량을 초과하였습니다');
          setSecondAlertText('5초뒤 메인화면으로 이동합니다');

          audio.src = ExpireErrorMP3;
          audio.play();

          setIsViewAlert(true);
          setIsProcessing(false);
  
          setTimeout(() => {
            // 데이터 초기화 //
            window.location.reload(true);
          }, 5000)
        }
        else if (responseData.status === 202) {
          // 금주 사용량을 초과하였습니다.
          setFirstAlertText('금주 사용량을 초과하였습니다');
          setSecondAlertText('5초뒤 메인화면으로 이동합니다');

          audio.src = ExpireErrorMP3;
          audio.play();

          setIsViewAlert(true);
          setIsProcessing(false);
  
          setTimeout(() => {
            // 데이터 초기화 //
            window.location.reload(true);
          }, 5000)
        }
        else if (responseData.status === 203) {
          // 최대 사용량을 초과하였습니다.
          setFirstAlertText('최대 사용량을 초과하였습니다');
          setSecondAlertText('5초뒤 메인화면으로 이동합니다');

          audio.src = ExpireErrorMP3;
          audio.play();

          setIsViewAlert(true);
          setIsProcessing(false);
  
          setTimeout(() => {
            // 데이터 초기화 //
            window.location.reload(true);
          }, 5000)
        }
        else if (responseData.status === 204 || responseData.status === 205) {
          // 예약내역이 없는경우.
          setFirstAlertText('올바르지 않은 예약 데이터입니다');
          setSecondAlertText('5초뒤 메인화면으로 이동합니다');

          audio.src = ExpireErrorMP3;
          audio.play();

          setIsViewAlert(true);
          setIsProcessing(false);
  
          setTimeout(() => {
            // 데이터 초기화 //
            window.location.reload(true);
          }, 5000)
        }
        else if (responseData.status === 206) {
          // 금주 사용량을 초과하였습니다.
          setFirstAlertText('수강 인원이 초과하였습니다');
          setSecondAlertText('5초뒤 메인화면으로 이동합니다');

          audio.src = ExpireErrorMP3;
          audio.play();

          setIsViewAlert(true);
          setIsProcessing(false);
  
          setTimeout(() => {
            // 데이터 초기화 //
            window.location.reload(true);
          }, 5000)
        }
        else if (responseData.status === 207) {
          // 금주 사용량을 초과하였습니다.
          setFirstAlertText('이미 수강한 수업입니다');
          setSecondAlertText('5초뒤 메인화면으로 이동합니다');

          audio.src = ExpireErrorMP3;
          audio.play();

          setIsViewAlert(true);
          setIsProcessing(false);
  
          setTimeout(() => {
            // 데이터 초기화 //
            window.location.reload(true);
          }, 5000)
        }
      }
    )();
  }

  // 뒷페이지에서 처음으로 되돌아가기 //
  function onClickLogo() {
    window.location.reload(true);
  }

  // 중복정보 창에서 회원 정보 클릭시 //
  function onClickMyInfo(data) {
    const audio = new Audio();

    setProcessingText('회원권을 확인중 입니다');
    setIsProcessing(true);

    let requestData = {
      phone: data.phone
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('reserve/history/check', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          setMemberInfo(responseData.data);

          let usages = responseData.data.usages.slice();

          for (let i = 0; i < usages.length; i++) {
            let ids = usages[i].availableLessonIds.split(',');

            let newIds = [];

            for (let j = 0; j < ids.length; j++) {
              newIds.push(parseInt(ids[j]));
            }

            usages[i].lessonIds = newIds;
          }

          setUsages(usages);

          setIsProcessing(false);
          setIsDuplicateView(false);

          // 유효회원권이 2개 이상인 경우 //
          if (usages.length > 1) {
            InfoRef.current.style.zIndex = 1;
            KeypadRef.current.style.zIndex = 0;

            InfoRef.current.style.opacity = 1;
            KeypadRef.current.style.opacity = 0;
          }
          // 유효회원권이 1개인 경우 //
          else {
            onClickTicket(usages[0]);
          }
        }
        // 유효회원권이 존재하지 않는 회원의 경우 //
        else if (responseData.status === 201) {
          // alert('사용가능한 회원권이 존재하지 않습니다.\n회원권을 구매해주세요');
          setFirstAlertText('사용가능한 회원권이 없습니다');
          setSecondAlertText('회원권 구매 후 이용해주세요');

          setIsViewAlert(true);
          setIsProcessing(false);

          setMiddlePhone('');
          setLastPhone('');

          audio.src = ExpireErrorMP3;
          audio.play();

          setTimeout(() => {
            setIsViewAlert(false);
            setIsDuplicateView(false);

            window.location.reload(true);
          }, 5000)
          return;
        }
        else if (responseData.status === 202) {
          setFirstAlertText('금일 사용량을 초과하였습니다');
          setSecondAlertText('3초뒤 메인화면으로 이동합니다');

          setIsViewAlert(true);
          setIsProcessing(false);

          setMiddlePhone('');
          setLastPhone('');

          audio.src = ExpireErrorMP3;
          audio.play();

          setTimeout(() => {
            setIsViewAlert(false);
            setIsDuplicateView(false);

            window.location.reload(true);
          }, 5000)
          return;
        }
        else {
          setFirstAlertText('회원권 조회에 실패했습니다');
          setSecondAlertText('잠시후 다시시도해주세요');

          setIsViewAlert(true);
          setIsProcessing(false);

          setMiddlePhone('');
          setLastPhone('');

          audio.src = ExpireErrorMP3;
          audio.play();

          setTimeout(() => {
            setIsViewAlert(false);
            setIsDuplicateView(false);

            window.location.reload(true);
          }, 5000)
          return;
        }
      }
    )();
  }

  function onClickExitSelectClass() {
    setLastPhone('');
    setIsSelectBox(false);
  }

  // 홀딩 해제 '예' 클릭시 //
  function onClickHoldingModalYes() {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('membership/release/holding/' + holdingUsage.usageId, 'POST');
        // console.log(holdingUsage);
        // console.log(responseData);
        if (responseData.status === 200) {
          let newData = holdingUsage;

          newData.isHolding = false;

          onClickTicket(newData);
          setHoldingUsage(undefined);
          setIsViewHoldingModal(false);
        }
      }
    )();
  }

  // 홀딩 해제 '아니요' 클릭시 //
  function onClickHoldingModalNo() {
    window.location.reload(true);
  }


  return (
    <TopLevelWrapper>
      {isProcessing && <WaittingModal text={processingText} />}
      {/* {isViewAlert && <AlertModal firstText={firstAlertText} secondText={secondAlertText} />} */}
      {isViewHoldingModal && <HoldingStateModal onClickYes={onClickHoldingModalYes} onClickNo={onClickHoldingModalNo} />}
      {
        isSelectClass &&
        <ClassSelectModal
          useScheduleData={useScheduleData}
          reserveData={reserveData}
          setReserveData={setReserveData}
          setIsSelectBox={onClickExitSelectClass}
          onClickEmptyClass={onClickEmptyClass}
          onClickClass={onClickClass} />
      }
      {isDuplicateView &&
        <DuplicateMemberSelectModal
          duplicateMembers={duplicateMembers}
          lastPhone={lastPhone}
          onClickMyInfo={onClickMyInfo}
          close={() => setIsDuplicateView(false)} />
      }
      {
        isViewCompleteAttendance &&
        <WeekAttendanceStatusView
          dayOfTheWeek={dayOfTheWeek}
          attendanceData={attendanceData} />
      }
      {
        isViewAlert && <NotFoundView firstText={firstAlertText} secondText={secondAlertText} />
      }
      <PadViewWrapper isCompleteAttendance={(isViewCompleteAttendance || isViewAlert)}>
        {/* <RingRingWrapper isCompleteAttendance={isViewCompleteAttendance} isViewAlert={isViewAlert} isFriday={dayOfTheWeek === 'Fri'} onClick={onClickLogo}>
          <RingRing src={OpenEyesRingRing} isView={(!isInputPhone && !isViewCompleteAttendance && !isViewAlert)}/>
          <RingRing src={CloseEyesRingRing} isView={(isInputPhone && !isViewCompleteAttendance && !isViewAlert)}/>
          <WeekRingRing src={MondayRingRing} isFriday={dayOfTheWeek === 'Fri'} isView={(dayOfTheWeek === 'Mon' && isViewCompleteAttendance)}/>
          <WeekRingRing src={TuesdayRingRing} isFriday={dayOfTheWeek === 'Fri'}  isView={(dayOfTheWeek === 'Tue' && isViewCompleteAttendance)}/>
          <WeekRingRing src={WedsdayRingRing} isFriday={dayOfTheWeek === 'Fri'}  isView={(dayOfTheWeek === 'Wed' && isViewCompleteAttendance)}/>
          <WeekRingRing src={ThursdayRingRing} isFriday={dayOfTheWeek === 'Fri'}  isView={(dayOfTheWeek === 'Thu' && isViewCompleteAttendance)}/>
          <WeekRingRing src={FridayRingRing} isFriday={dayOfTheWeek === 'Fri'}  isView={(dayOfTheWeek === 'Fri' && isViewCompleteAttendance)}/>
          <WeekRingRing src={MondayRingRing} isFriday={dayOfTheWeek === 'Fri'}  isView={(dayOfTheWeek === 'Sat' && isViewCompleteAttendance)}/>
          <WeekRingRing src={MondayRingRing} isFriday={dayOfTheWeek === 'Fri'}  isView={(dayOfTheWeek === 'Sun' && isViewCompleteAttendance)}/>
          <NotFoundRingRing src={NotFoundedRingRing} isView={isViewAlert}/>
        </RingRingWrapper> */}
        {
          isViewAlert ?
            <NotFoundRingRingWrapper isCompleteAttendance={isViewCompleteAttendance} isViewAlert={isViewAlert} isFriday={dayOfTheWeek === 'Fri'} onClick={onClickLogo}>
              <NotFoundRingRing src={NotFoundedRingRing} isView={isViewAlert} />
            </NotFoundRingRingWrapper>
            :
            <TotalRingRingWrapper>
              {
                isViewCompleteAttendance ?
                  <RingRingWrapper isCompleteAttendance={isViewCompleteAttendance} isViewAlert={isViewAlert} isFriday={dayOfTheWeek === 'Fri'} onClick={onClickLogo}>
                    <WeekRingRing src={MondayRingRing} isFriday={dayOfTheWeek === 'Fri'} isView={(dayOfTheWeek === 'Mon' && isViewCompleteAttendance)} />
                    <WeekRingRing src={TuesdayRingRing} isFriday={dayOfTheWeek === 'Fri'} isView={(dayOfTheWeek === 'Tue' && isViewCompleteAttendance)} />
                    <WeekRingRing src={WedsdayRingRing} isFriday={dayOfTheWeek === 'Fri'} isView={(dayOfTheWeek === 'Wed' && isViewCompleteAttendance)} />
                    <WeekRingRing src={ThursdayRingRing} isFriday={dayOfTheWeek === 'Fri'} isView={(dayOfTheWeek === 'Thu' && isViewCompleteAttendance)} />
                    <WeekRingRing src={FridayRingRing} isFriday={dayOfTheWeek === 'Fri'} isView={(dayOfTheWeek === 'Fri' && isViewCompleteAttendance)} />
                    <WeekRingRing src={MondayRingRing} isFriday={dayOfTheWeek === 'Fri'} isView={(dayOfTheWeek === 'Sat' && isViewCompleteAttendance)} />
                    <WeekRingRing src={MondayRingRing} isFriday={dayOfTheWeek === 'Fri'} isView={(dayOfTheWeek === 'Sun' && isViewCompleteAttendance)} />
                  </RingRingWrapper>
                  :
                  <RingRingWrapper isCompleteAttendance={isViewCompleteAttendance} isViewAlert={isViewAlert} isFriday={dayOfTheWeek === 'Fri'} onClick={onClickLogo}>
                    <RingRing src={OpenEyesRingRing} isView={(!isInputPhone && !isViewCompleteAttendance && !isViewAlert)} />
                    <RingRing src={CloseEyesRingRing} isView={(isInputPhone && !isViewCompleteAttendance && !isViewAlert)} />
                  </RingRingWrapper>
              }
            </TotalRingRingWrapper>
        }
        <PadView>
          {
            isViewCompleteAttendance &&
            <TotalRingRingHandImageWrapper isFriday={dayOfTheWeek === 'Fri'} onClick={onClickLogo}>
              <RingRingHandImageWrapper isFriday={dayOfTheWeek === 'Fri'}>
                <RingRingHandImage src={dayOfTheWeek === 'Fri' ? RingRingHand : RingRingHands} />
              </RingRingHandImageWrapper>
            </TotalRingRingHandImageWrapper>
          }
          <KeypadTotalView ref={KeypadRef}>
            <ExplanationWrapper>
              <ExplanationText color='#FF8B48'>예약 확인</ExplanationText>
              <ExplanationText color='#595959'>및</ExplanationText>
              <ExplanationText color='#FF8B48'>출석체크</ExplanationText>
              <ExplanationText color='#595959'>를 위해</ExplanationText>
            </ExplanationWrapper>
            <ExplanationWrapper>
              <ExplanationText color='#FF8B48'>전화번호 뒷자리</ExplanationText>
              <ExplanationText color='#595959'>를 입력해주세요</ExplanationText>
            </ExplanationWrapper>
            <InputWrapper>
              <PhoneInput InputRef={LastRef} value={lastPhone} setValue={setLastPhone} />
            </InputWrapper>
            <KeypadWrapper>
              <KeypadLayerWrapper>
                <NumberKey onClick={() => onClickNumber('1')}>
                  <NumText>1</NumText>
                </NumberKey>
                <NumberKey onClick={() => onClickNumber('2')}>
                  <NumText>2</NumText>
                </NumberKey>
                <NumberKey onClick={() => onClickNumber('3')}>
                  <NumText>3</NumText>
                </NumberKey>
              </KeypadLayerWrapper>
              <KeypadLayerWrapper>
                <NumberKey onClick={() => onClickNumber('4')}>
                  <NumText>4</NumText>
                </NumberKey>
                <NumberKey onClick={() => onClickNumber('5')}>
                  <NumText>5</NumText>
                </NumberKey>
                <NumberKey onClick={() => onClickNumber('6')}>
                  <NumText>6</NumText>
                </NumberKey>
              </KeypadLayerWrapper>
              <KeypadLayerWrapper>
                <NumberKey onClick={() => onClickNumber('7')}>
                  <NumText>7</NumText>
                </NumberKey>
                <NumberKey onClick={() => onClickNumber('8')}>
                  <NumText>8</NumText>
                </NumberKey>
                <NumberKey onClick={() => onClickNumber('9')}>
                  <NumText>9</NumText>
                </NumberKey>
              </KeypadLayerWrapper>
              <KeypadLayerWrapper>
                <RemoveKey onClick={onClickRemove}>
                  <ButtonText>수정</ButtonText>
                </RemoveKey>
                <NumberKey onClick={() => onClickNumber('0')}>
                  <NumText>0</NumText>
                </NumberKey>
                <OkayKey onClick={onClickFindInfo}>
                  <ButtonText>확인</ButtonText>
                </OkayKey>
              </KeypadLayerWrapper>
              <PoweredByWrapper>
                <PoweredByImage src={PoweredBy} />
              </PoweredByWrapper>
            </KeypadWrapper>
          </KeypadTotalView>
          <InfoTotalView ref={InfoRef}>
            {/* <TitleWrapper onClick={onClickLogo}>
            <LogoImage src='https://picture.link-zone.org/ZoneLogo.png' />
            <TitleText>Link Zone</TitleText>
          </TitleWrapper> */}
            {
              memberInfo.isPartnerGym &&
              <PartnerGymView>
                <StoreLogo src={memberInfo.storeLogo} />
                <PartnerGymTextWrapper>
                  <PartnerGymText>{'[' + memberInfo.storeName + '] PARTNER GYM'}</PartnerGymText>
                </PartnerGymTextWrapper>
              </PartnerGymView>
            }
            <MemberInfoWrapper>
              <ProfileWrapper >
                {/* <Profile src={memberInfo.profile === '' ? DefaultProfile : memberInfo.profile} /> */}
                <ProfileBox>
                  <Image src={memberInfo.profile} defaultSrc={DefaultProfile} />
                </ProfileBox>
              </ProfileWrapper>
              <TextInfoWrapper>
                <InfoTotalWrapper>
                  <MemberNameWrapper>
                    <NameText fontFmaily='NotoSansKR-Bold' color='#000000'>{memberInfo.userName}</NameText>
                    <NameText fontFmaily='NotoSansKR-Regular' color='#777777'>회원</NameText>
                  </MemberNameWrapper>
                  <TextColumnBoxWrapper>
                    <TextColumnBox>
                      <NameText fontFmaily='NotoSansKR-Regular' color='#CBCBCB'>성별</NameText>
                    </TextColumnBox>
                    <TextColumnBox>
                      <NameText fontFmaily='NotoSansKR-Medium' color='#595959'>{memberInfo.gender === 'male' ? '남성' : '여성'}</NameText>
                    </TextColumnBox>
                  </TextColumnBoxWrapper>
                  <TextColumnBoxWrapper>
                    <TextColumnBox>
                      {/* <NameText fontFmaily='NotoSansKR-Regular' color='#CBCBCB'>링크코치 아이디</NameText> */}
                      <NameText fontFmaily='NotoSansKR-Regular' color='#CBCBCB'>링크코치 ID</NameText>
                    </TextColumnBox>
                    <TextColumnBox>
                      <NameText fontFmaily='NotoSansKR-Medium' color='#595959'>{memberInfo.nickName}</NameText>
                    </TextColumnBox>
                  </TextColumnBoxWrapper>
                  <TextColumnBoxWrapper>
                    <TextColumnBox>
                      <NameText fontFmaily='NotoSansKR-Regular' color='#CBCBCB'>락커 번호</NameText>
                    </TextColumnBox>
                    <TextColumnBox>
                      <NameText fontFmaily='NotoSansKR-Medium' color='#595959'>{memberInfo.lockerName}</NameText>
                    </TextColumnBox>
                  </TextColumnBoxWrapper>
                </InfoTotalWrapper>
              </TextInfoWrapper>
            </MemberInfoWrapper>
            <InfoExplanationWrapper>
              <InfoExplanationText color='#6DD49E'>사용하실 회원권</InfoExplanationText>
              <InfoExplanationText color='#777777'>을 선택해주세요</InfoExplanationText>
            </InfoExplanationWrapper>
            <TicketTotalWrapper>
              {
                usages.map((data, index) => (
                  <TicketWrapper key={index} onClick={() => onClickTicket(data)}>
                    <TicketWhiteWrapper>
                      <TicketImage src={TicketWhite} />
                      <TicketMemberShipInfoWrapper>
                        <TicketMemberShipInfoLayerWrapper>
                          <IndividualInfoWrapper>
                            <NameText fontFmaily='NotoSansKR-Regular' color='#BDBDBD'>회원권</NameText>
                          </IndividualInfoWrapper>
                          <IndividualInfoWrapper>
                            <NameText fontFmaily='NotoSansKR-Regular' color='#333333'>{data.memberShipName}</NameText>
                          </IndividualInfoWrapper>
                        </TicketMemberShipInfoLayerWrapper>
                        <TicketMemberShipInfoLayerWrapper>
                          <IndividualInfoWrapper>
                            <NameText fontFmaily='NotoSansKR-Regular' color='#BDBDBD'>예약 내역</NameText>
                          </IndividualInfoWrapper>
                          <IndividualInfoWrapper>
                            <NameText fontFmaily='NotoSansKR-Regular' color='#333333'>{calcMyReserve(data)}</NameText>
                          </IndividualInfoWrapper>
                        </TicketMemberShipInfoLayerWrapper>
                      </TicketMemberShipInfoWrapper>
                    </TicketWhiteWrapper>
                    <TicketGreenWrapper>
                      <TicketImage src={TicketGreen} />
                      <TicketUsageWrapper>
                        <DdayText>{calcMyRemainDate(data)}</DdayText>
                        <RemainingText>{calcMyRemainReserveCount(data)}</RemainingText>
                        <DateText>{moment(data.startDate).format('MM.DD') + ' - ' + moment(data.endDate).format('MM.DD')}</DateText>
                      </TicketUsageWrapper>
                    </TicketGreenWrapper>
                  </TicketWrapper>
                ))
              }
            </TicketTotalWrapper>
          </InfoTotalView>
        </PadView>
      </PadViewWrapper>
    </TopLevelWrapper>
  )
}
const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const PadViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: ${props => props.isCompleteAttendance ? `center` : `flex-end`};

  transition: all 0.3s ease-in-out;
`;

// const RingRingWrapper = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column

//   justify-content: flex-start;
//   align-items: flex-start;

//   margin-right: ${props => props.isCompleteAttendance ? `0px` : `100px`};

//   width: 200px;
//   height: 191px;

//   z-index: 1000;

//   @media (min-height: 320px) {
//     margin-right: ${props => props.isCompleteAttendance ? `0px` : `20px`};

//     width: 110px;
//     height: 105px;
//   }
//   @media (min-height: 640px) {
//     margin-right: ${props => props.isCompleteAttendance ? `0px` : `30px`};

//     width: 120px;
//     height: 115px;
//   }
//   @media (min-height: 680px) {
//     margin-right: ${props => props.isCompleteAttendance ? `0px` : `40px`};

//     width: 140px;
//     height: 134px;
//   }
//   @media (min-height: 720px) {
//     margin-right: ${props => props.isCompleteAttendance ? `0px` : `60px`};

//     width: 150px;
//     height: 143px;
//   }
//   @media (min-height: 800px) {
//     margin-right: ${props => props.isCompleteAttendance ? `0px` : `70px`};

//     width: 160px;
//     height: 153px;
//   }
//   @media (min-height: 1000px) {
//     margin-right: ${props => props.isCompleteAttendance ? `0px` : `80px`};

//     width: 180px;
//     height: 172px;
//   }
//   @media (min-height: 1200px) {
//     margin-right: ${props => props.isCompleteAttendance ? `0px` : `100px`};

//     width: 200px;
//     height: 191px;
//   }
// `;

const TotalRingRingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RingRingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-right: ${props => props.isCompleteAttendance ? `0px` : props.isViewAlert ? `0px` : `100px`};

  width: ${props => props.isCompleteAttendance ? props.isFriday ? `473px` : `230px` : props.isViewAlert ? `453px` : `200px`};
  height: ${props => props.isCompleteAttendance ? props.isFriday ? `313px` : `179px` : props.isViewAlert ? `288px` : `191px`};
  z-index: 1000;

  // transition: all 0.3s ease-in-out;

  @media (min-height: 320px) {
    margin-right: ${props => props.isCompleteAttendance ? `0px` : props.isViewAlert ? `0px` : `20px`};

    width: ${props => props.isCompleteAttendance ? props.isFriday ? `208px` : `110px` : props.isViewAlert ? `199px` : `110px`};
    height: ${props => props.isCompleteAttendance ? props.isFriday ? `138px` : `85px` : props.isViewAlert ? `127px` : `105px`};
  }
  @media (min-height: 640px) {
    margin-right: ${props => props.isCompleteAttendance ? `0px` : props.isViewAlert ? `0px` : `30px`};

    width: ${props => props.isCompleteAttendance ? props.isFriday ? `246px` : `130px` : props.isViewAlert ? `236px` : `120px`};
    height: ${props => props.isCompleteAttendance ? props.isFriday ? `162px` : `101px` : props.isViewAlert ? `150px` : `115px`};
  }
  @media (min-height: 680px) {
    margin-right: ${props => props.isCompleteAttendance ? `0px` : props.isViewAlert ? `0px` : `40px`};

    width: ${props => props.isCompleteAttendance ? props.isFriday ? `302px` : `160px` : props.isViewAlert ? `290px` : `140px`};
    height: ${props => props.isCompleteAttendance ? props.isFriday ? `199px` : `124px` : props.isViewAlert ? `184px` : `134px`};
  }
  @media (min-height: 720px) {
    margin-right: ${props => props.isCompleteAttendance ? `0px` : props.isViewAlert ? `0px` : `60px`};

    width: ${props => props.isCompleteAttendance ? props.isFriday ? `359px` : `190px` : props.isViewAlert ? `345px` : `150px`};
    height: ${props => props.isCompleteAttendance ? props.isFriday ? `237px` : `148px` : props.isViewAlert ? `219px` : `143px`};
  }
  @media (min-height: 800px) {
    margin-right: ${props => props.isCompleteAttendance ? `0px` : props.isViewAlert ? `0px` : `70px`};

    width: ${props => props.isCompleteAttendance ? props.isFriday ? `397px` : `210px` : props.isViewAlert ? `381px` : `160px`};
    height: ${props => props.isCompleteAttendance ? props.isFriday ? `262px` : `164px` : props.isViewAlert ? `242px` : `153px`};
  }
  @media (min-height: 1000px) {
    margin-right: ${props => props.isCompleteAttendance ? `0px` : props.isViewAlert ? `0px` : `80px`};

    width: ${props => props.isCompleteAttendance ? props.isFriday ? `435px` : `230px` : props.isViewAlert ? `417px` : `180px`};
    height: ${props => props.isCompleteAttendance ? props.isFriday ? `288px` : `179px` : props.isViewAlert ? `265px` : `172px`};
  }
  @media (min-height: 1200px) {
    margin-right: ${props => props.isCompleteAttendance ? `0px` : props.isViewAlert ? `0px` : `100px`};

    width: ${props => props.isCompleteAttendance ? props.isFriday ? `473px` : `250px` : props.isViewAlert ? `453px` : `200px`};
    height: ${props => props.isCompleteAttendance ? props.isFriday ? `313px` : `195px` : props.isViewAlert ? `288px` : `191px`};
  }
`;

const NotFoundRingRingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 0px;

  width: 453px;
  height: 288px;

  z-index: 1000;

  transition: all 0.3s ease-in-out;

  @media (min-height: 320px) {
    width: 199px;
    height: 127px;
  }
  @media (min-height: 640px) {
    width: 236px;
    height: 150px;
  }
  @media (min-height: 680px) {
    width: 290px;
    height: 184px;
  }
  @media (min-height: 720px) {
    width: 345px;
    height: 219px;
  }
  @media (min-height: 800px) {
    width: 381px;
    height: 242px;
  }
  @media (min-height: 1000px) {
    width: 417px;
    height: 265px;
  }
  @media (min-height: 1200px) {
    width: 453px;
    height: 288px;
  }
`;

const NotFoundRingRing = styled.img`
  position: absolute;

  // margin-top: 7px;

  width: 200px;
  height: 191px;

  opacity: ${props => props.isView ? `1` : `0`};

  transition: ${props => props.isView ? `all 0.1s ease-in-out` : `all 0.3s ease-in-out`};

  :hover {
    cursor: pointer;
  }

  @media (min-height: 320px) {
    margin-top: 34px;

    width: 199px;
    height: 127px;
  }
  @media (min-height: 640px) {
    margin-top: 40px;

    width: 236px;
    height: 150px;
  }
  @media (min-height: 680px) {
    margin-top: 39px;

    width: 290px;
    height: 184px;
  }
  @media (min-height: 720px) {
    margin-top: 36px;

    width: 345px;
    height: 219px;
  }
  @media (min-height: 800px) {
    margin-top: 40px;

    width: 381px;
    height: 242px;
  }
  @media (min-height: 1000px) {
    margin-top: 44px;

    width: 417px;
    height: 265px;
  }
  @media (min-height: 1200px) {
    margin-top: 48px;

    width: 453px;
    height: 288px;
  }
`;

const RingRing = styled.img`
  position: absolute;

  // margin-top: 7px;

  width: 200px;
  height: 191px;

  opacity: ${props => props.isView ? `1` : `0`};

  transition: ${props => props.isView ? `all 0.1s ease-in-out` : `all 0.3s ease-in-out`};

  :hover {
    cursor: pointer;
  }

  @media (min-height: 320px) {
    margin-top: 4px;

    width: 110px;
    height: 105px;
  }
  @media (min-height: 640px) {
    margin-top: 4px;

    width: 120px;
    height: 115px;
  }
  @media (min-height: 680px) {
    margin-top: 5px;

    width: 140px;
    height: 134px;
  }
  @media (min-height: 720px) {
    margin-top: 5px;

    width: 150px;
    height: 143px;
  }
  @media (min-height: 800px) {
    margin-top: 6px;

    width: 160px;
    height: 153px;
  }
  @media (min-height: 1000px) {
    margin-top: 7px;

    width: 180px;
    height: 172px;
  }
  @media (min-height: 1200px) {
    margin-top: 7px;

    width: 200px;
    height: 191px;
  }
`;

const WeekRingRing = styled.img`
  position: absolute;

  width: ${props => props.isFriday ? `473px` : `250px`};
  height: ${props => props.isFriday ? `313px` : `195px`};

  opacity: ${props => props.isView ? `1` : `0`};

  transition: ${props => props.isView ? `all 0.1s ease-in-out` : `all 0.3s ease-in-out`};

  :hover {
    cursor: pointer;
  }

  @media (min-height: 320px) {
    margin-top: ${props => props.isFriday ? `0px` : `10px`};

    width: ${props => props.isFriday ? `208px` : `110px`};
    height: ${props => props.isFriday ? `138px` : `85px`};
  }
  @media (min-height: 640px) {
    margin-top: ${props => props.isFriday ? `0px` : `10px`};

    width: ${props => props.isFriday ? `246px` : `130px`};
    height: ${props => props.isFriday ? `162px` : `101px`};
  }
  @media (min-height: 680px) {
    margin-top: ${props => props.isFriday ? `-4px` : `6px`};

    width: ${props => props.isFriday ? `302px` : `160px`};
    height: ${props => props.isFriday ? `199px` : `124px`};
  }
  @media (min-height: 720px) {
    margin-top: ${props => props.isFriday ? `0px` : `-2px`};

    width: ${props => props.isFriday ? `359px` : `190px`};
    height: ${props => props.isFriday ? `237px` : `148px`};
  }
  @media (min-height: 800px) {
    margin-top: ${props => props.isFriday ? `-16px` : `-4px`};

    width: ${props => props.isFriday ? `397px` : `210px`};
    height: ${props => props.isFriday ? `262px` : `164px`};
  }
  @media (min-height: 1000px) {
    margin-top: ${props => props.isFriday ? `-18px` : `-2px`};

    width: ${props => props.isFriday ? `435px` : `230px`};
    height: ${props => props.isFriday ? `288px` : `179px`};
  }
  @media (min-height: 1200px) {
    margin-top: ${props => props.isFriday ? `-18px` : `-2px`};

    width: ${props => props.isFriday ? `473px` : `250px`};
    height: ${props => props.isFriday ? `313px` : `195px`};
  }
`;

const PadView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 48vh;
  min-width: 300px;
  max-width: 800px;
  // height: 90vh;
  height: 80vh;
  // height: 85vh;
  // height: 100%;
  min-height: 580px;
  // min-height: 500px;
  max-height: 1480px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border-radius: 30px;
  background-color: #FFFFFF;
`;

const KeypadTotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 74.625%;
  // height: 87.22%;
  height: 92.93%;

  margin-top: 28px;

  z-index: 1;
  opacity: 1;

  // background-color: red;
  transition: all 0.3s ease-in-out;
`;

const InfoTotalView = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 87%;
  height: 87.22%;

  opacity: 0;
  z-index: -1;

  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-left: -10px;

  // gap: 20px;
  gap: 10px;
`;

const PartnerGymView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  // justify-content: center;
  align-items: center;

  width: 100%;
  height: 66px;

  border-radius: 15px;
  background-color: #FFA570;

  @media (min-height: 320px) {
    height: 24px;
    border-radius: 6px;
  }
  @media (min-height: 640px) {
    height: 28px;
  }
  @media (min-height: 680px) {
    height: 30px;
    border-radius: 7px;
  }
  @media (min-height: 720px) {
    height: 32px;
  }
  @media (min-height: 800px) {
    height: 36px;
    border-radius: 8px;
  }
  @media (min-height: 1000px) {
    height: 50px;
    border-radius: 10px;
  }
  @media (min-height: 1200px) {
    height: 66px;
    border-radius: 15px;
  }

  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.10);
  // overflow: hidden;
`;

const StoreLogo = styled.img`
  // display: flex;
  // flex-direction: row;

  // justify-content: center;
  // align-items: center;

  width: 66px;
  // height: 100%;
  height: 66px;

  object-fit: contain;

  background-color: #FFFFFF;

  @media (min-height: 320px) {
    width: 24px;
    height: 24px;
    border-radius: 5px 0px 0px 5px;
  }
  @media (min-height: 640px) {
    width: 28px;
    height: 28px;
  }
  @media (min-height: 680px) {
    width: 30px;
    height: 30px;
    border-radius: 6px 0px 0px 6px;
  }
  @media (min-height: 720px) {
    width: 32px;
    height: 32px;
  }
  @media (min-height: 800px) {
    width: 36px;
    height: 36px;
    border-radius: 7px 0px 0px 7px;
  }
  @media (min-height: 1000px) {
    width: 50px;
    height: 50px;
    border-radius: 9px 0px 0px 9px;
  }
  @media (min-height: 1200px) {
    width: 66px;
    height: 66px;
    border-radius: 14px 0px 0px 14px;
  }
`;

const PartnerGymTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: calc(100% - 66px);
  width: 100%;
  height: 66px;

  @media (min-height: 320px) {
    height: 24px;
  }
  @media (min-height: 640px) {
    height: 28px;
  }
  @media (min-height: 680px) {
    height: 30px;
  }
  @media (min-height: 720px) {
    height: 32px;
  }
  @media (min-height: 800px) {
    height: 36px;
  }
  @media (min-height: 1000px) {
    height: 50px;
  }
  @media (min-height: 1200px) {
    height: 66px;
  }
`;

const PartnerGymText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 25px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 12px;
    margin-left: -24px;
  }
  @media (min-height: 640px) {
    font-size: 14px;
    margin-left: -28px;
  }
  @media (min-height: 680px) {
    font-size: 14px;
    margin-left: -30px;
  }
  @media (min-height: 720px) {
    font-size: 14px;
    margin-left: -32px;
  }
  @media (min-height: 760px) {
    font-size: 14px;
  }
  @media (min-height: 800px) {
    font-size: 16px;
    margin-left: -36px;
  }
  @media (min-height: 1000px) {
    font-size: 20px;
    margin-left: -50px;
  }
  @media (min-height: 1200px) {
    font-size: 25px;
    margin-left: -66px;
  }

  :hover {
    cursor: default;
  }
`;

// const LogoImage = styled.img`
//   width: 25%;
//   height: auto;
// `;

const TitleText = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 28px;

  color: #FF8B48;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 28px;
  }
  @media (min-height: 640px) {
    font-size: 32px;
  }
  @media (min-height: 680px) {
    font-size: 34px;
  }
  @media (min-height: 720px) {
    font-size: 36px;
  }
  @media (min-height: 760px) {
    font-size: 38px;
  }
  @media (min-height: 800px) {
    font-size: 40px;
  }
  @media (min-height: 1000px) {
    font-size: 50px;
  }
  @media (min-height: 1200px) {
    font-size: 60px;
  }

  :hover {
    cursor: default;
  }
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const ExplanationText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 16px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 16px;
  }
  @media (min-height: 640px) {
    font-size: 16px;
  }
  @media (min-height: 720px) {
    font-size: 18px;
  }
  @media (min-height: 760px) {
    font-size: 20px;
  }
  @media (min-height: 800px) {
    font-size: 21px;
  }
  @media (min-height: 1000px) {
    font-size: 26px;
  }
  @media (min-height: 1200px) {
    font-size: 30px;
  }

  :hover {
    cursor: default;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 84.89%;

  // gap: 4px;
  gap: 8px;
  margin-top: 43px;
  padding: 12px 0px 12px 0px;

  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #FF8B48;

  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #FF8B48;

  @media (min-height: 320px) {
    gap: 4px;
  }
  @media (min-height: 640px) {
    gap: 6px;
  }
  @media (min-height: 720px) {
    gap: 8px;
  }
  @media (min-height: 760px) {
    gap: 10px;
  }
  @media (min-height: 800px) {
    gap: 12px;
  }
  @media (min-height: 880px) {
    gap: 14px;
  }
  @media (min-height: 940px) {
    gap: 16px;
  }
  @media (min-height: 1000px) {
    gap: 18px;
  }
  @media (min-height: 1200px) {
    gap: 20px;
  }
`;

const PhoneText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 17px;

  color: #595959;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 17px;
  }
  @media (min-height: 640px) {
    font-size: 19px;
  }
  @media (min-height: 720px) {
    font-size: 21px;
  }
  @media (min-height: 760px) {
    font-size: 22px;
  }
  @media (min-height: 800px) {
    font-size: 23px;
  }
  @media (min-height: 1000px) {
    font-size: 30px;
  }
  @media (min-height: 1200px) {
    font-size: 40px;
  }

  :hover {
    cursor: default;
  }
`;

const KeypadWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  // margin-top: 80px;
  margin-top: 20px;

  width: 84.89%;
  // height: 54.37%;
  height: 68%;

  @media (min-height: 640px) {
    // margin-top: 40px;
    // height: 64.37%;
    margin-top: 20px;
    height: 60%;
  }
  @media (min-height: 720px) {
    // margin-top: 60px;
    // height: 59.37%;
    margin-top: 30px;
    height: 64%;
  }
  @media (min-height: 860px) {
    // margin-top: 80px;
    // height: 54.37%;
    margin-top: 40px;
    height: 68%;
  }
`;

const KeypadLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // margin-top: 34px;
  width: 100%;
`;

const NumberKey = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding-bottom: 29%;

  width: 29%;

  border-radius: 12px;
  background-color: #FF8B48;

  :hover {
    background-color: #FF7322;
  }

  transition: all 0.3s ease-in-out;
`;

const RemoveKey = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding-bottom: 29%;

  width: 29%;

  border-radius: 12px;
  background-color: #D9D9D9;

  :hover {
    background-color: #DFDFDF;
  }

  transition: all 0.3s ease-in-out;
`;

const OkayKey = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding-bottom: 29%;

  width: 29%;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    background-color:#2BCB67;
  }

  transition: all 0.3s ease-in-out;
`;

const NumText = styled.p`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 12px;

  font-family: NotoSansKR-Bold;
  font-size: 17px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 17px;
  }
  @media (min-height: 640px) {
    font-size: 19px;
  }
  @media (min-height: 720px) {
    font-size: 21px;
  }
  @media (min-height: 760px) {
    font-size: 22px;
  }
  @media (min-height: 800px) {
    font-size: 23px;
  }
  @media (min-height: 1000px) {
    font-size: 30px;
  }
  @media (min-height: 1200px) {
    font-size: 40px;
  }

  :hover {
    cursor: default;
  }
`;

const ButtonText = styled.p`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 12px;

  font-family: NotoSansKR-Bold;
  font-size: 17px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 17px;
  }
  @media (min-height: 640px) {
    font-size: 19px;
  }
  @media (min-height: 720px) {
    font-size: 20px;
  }
  @media (min-height: 760px) {
    font-size: 22px;
  }
  @media (min-height: 800px) {
    font-size: 24px;
  }
  @media (min-height: 1000px) {
    font-size: 26px;
  }
  @media (min-height: 1200px) {
    font-size: 28px;
  }

  :hover {
    cursor: default;
  }
`;

const MemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  // margin-top: 59px;
  margin-top: 24px;

  // width: 87.25%;
  width: 100%;
  // height: 15.47%;
`;

const ProfileWrapper = styled.div`
  position: relative;

  width: 32.8%;
  // padding-bottom: 32.8%;

   &::after {
    display: block;
    content: "";
    padding-bottom: 100%;
  }
`;

const Profile = styled.img`
  position: absolute;

  width: 100%;
  height: 100%;
  // width: 32.8%;

  border-radius: 24px;

  // object-fit: cover;
  object-fit: contain;

  background-color: #F4F4F4;
`;

const ProfileBox = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;

  border-radius: 24px;

  overflow: hidden;

  background-color: #F4F4F4;
`;

const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // padding: 25px 0px 35px 0px;
  // padding: 24px 0px 24px 0px;

  width: 61.74%;
  height: 100%;

  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  // border-radius: 18px;
  border-radius: 12px;
`;

const InfoTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-start;

  width: 87%;

  gap: 8px;
  // height: 90%;

  // gap: 9px;
`;

const MemberNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const TextColumnBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%
`;

const TextColumnBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 47.2%;
`;

const NameText = styled.p`
  font-family: ${props => props.fontFmaily};
  font-size: 13px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 12px;
  }
  @media (min-height: 640px) {
    font-size: 13px;
  }
  @media (min-height: 720px) {
    font-size: 14px;
  }
  @media (min-height: 760px) {
    font-size: 15px;
  }
  @media (min-height: 800px) {
    font-size: 16px;
  }
  @media (min-height: 1000px) {
    font-size: 17px;
  }
  @media (min-height: 1200px) {
    font-size: 18px;
  }

  :hover {
    cursor: default;
  }
`;

const InfoExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin: 45px 0px 45px 0px;
  margin: 30px 0px 30px 0px;

  @media (min-height: 320px) {
    margin: 12px 0px 12px 0px;
  }
  @media (min-height: 640px) {
    margin: 16px 0px 16px 0px;
  }
  @media (min-height: 800px) {
    margin: 20px 0px 20px 0px;
  }
  @media (min-height: 1200px) {
    margin: 30px 0px 30px 0px;
  }
`;

const InfoExplanationText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 18px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 620px) {
    font-size: 18px;
  }
  @media (min-height: 720px) {
    font-size: 20px;
  }
  @media (min-height: 760px) {
    font-size: 22px;
  }
  @media (min-height: 800px) {
    font-size: 24px;
  }
  @media (min-height: 1000px) {
    font-size: 26px;
  }
  @media (min-height: 1200px) {
    font-size: 28px;
  }

  :hover {
    cursor: default;
  }
`;

const TicketTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // padding-top: 20px;

  width: 100%;
  // height: calc(47.77% - 20px);
  // height: 47.77%;
  height: 66%;

  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const repeatAnimation = keyframes`
	0% { width: 99%; }
  50% { width: 100%; }
	100% { width: 99%; }
	// 50% { width: 100%; }
  // 75% { width: 98%; }
	// 100% { width: 96%; }
`;

const TicketWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // padding-top: 20px;
  // margin-bottom: 25px;
  padding: 40px 0px 40px 0px;
  // margin: 10px 0px 10px 0px;

  @media (min-height: 320px) {
    // padding: 10px 0px 10px 0px;
    padding: 20px 0px 20px 0px;
  }
  @media (min-height: 640px) {
    // padding: 15px 0px 15px 0px;
    padding: 20px 0px 20px 0px;
  }
  @media (min-height: 720px) {
    padding: 20px 0px 20px 0px;
  }
  @media (min-height: 760px) {
    padding: 25px 0px 25px 0px;
  }
  @media (min-height: 800px) {
    padding: 30px 0px 30px 0px;
  }
  @media (min-height: 880px) {
    padding: 35px 0px 35px 0px;
  }
  @media (min-height: 940px) {
    padding: 40px 0px 40px 0px;
  }
  @media (min-height: 1000px) {
    padding: 45px 0px 45px 0px;
  }
  @media (min-height: 1200px) {
    padding: 50px 0px 50px 0px;
  }

  // width: 100%;
  width: 99%;

  :hover {
    cursor: pointer;
  }

	// animation-name: ${repeatAnimation};
	// animation-duration: 2s;
	// animation-timing-function: ease-out;
  // animation-iteration-count: infinite;
`;

const TicketWhiteWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 62.62%;
`;

const TicketGreenWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 37.38%;
`;

const TicketMemberShipInfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-start;

  width: 82.91%;

  gap: 6px;

  @media (min-height: 320px) {
    gap: 8px;
  }
  @media (min-height: 640px) {
    gap: 10px;
  }
`;

const TicketMemberShipInfoLayerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 2px;

  @media (min-height: 320px) {
    gap: 4px;
  }
  @media (min-height: 640px) {
    gap: 6px;
  }
`;

const IndividualInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  overflow: hidden;

  @media (min-height: 320px) {
    height: 17px;
  }
  @media (min-height: 640px) {
    height: 19px;
  }
  @media (min-height: 720px) {
    height: 20px;
  }
  @media (min-height: 760px) {
    height: 21px;
  }
  @media (min-height: 800px) {
    height: 24px;
  }
  @media (min-height: 1000px) {
    height: 25px;
  }
  @media (min-height: 1200px) {
    height: 26px;
  }
`;

const TicketUsageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 85.87%;
`;

const TicketImage = styled.img`
  position: absolute;
  width: 100%;
`;

const DdayText = styled.p`
  font-family: Poppins-Bold;
  font-size: 24px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 24px;
  }
  @media (min-height: 640px) {
    font-size: 26px;
  }
  @media (min-height: 720px) {
    font-size: 28px;
  }
  @media (min-height: 760px) {
    font-size: 30px;
  }
  @media (min-height: 800px) {
    font-size: 32px;
  }
  @media (min-height: 1000px) {
    font-size: 35px;
  }
  @media (min-height: 1200px) {
    font-size: 55px;
  }

  :hover {
    cursor: default;
  }
`;

const RemainingText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 12px;
  }
  @media (min-height: 640px) {
    font-size: 13px;
  }
  @media (min-height: 720px) {
    font-size: 15px;
  }
  @media (min-height: 760px) {
    font-size: 16px;
  }
  @media (min-height: 800px) {
    font-size: 17px;
  }
  @media (min-height: 1000px) {
    font-size: 19px;
  }
  @media (min-height: 1200px) {
    font-size: 21px;
  }

  :hover {
    cursor: default;
  }
`;

const DateText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 10px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 10px;
  }
  @media (min-height: 640px) {
    font-size: 12px;
  }
  @media (min-height: 720px) {
    font-size: 14px;
  }
  @media (min-height: 760px) {
    font-size: 16px;
  }
  @media (min-height: 800px) {
    font-size: 18px;
  }
  @media (min-height: 1000px) {
    font-size: 20px;
  }
  @media (min-height: 1200px) {
    font-size: 21px;
  }

  :hover {
    cursor: default;
  }
`;

// const PoweredByText = styled.p`
//   font-family: NotoSansKR-Medium;
//   font-size: 16px;

//   color: #4F4F4F;

//   margin: 0 0 0 0;

//   @media (min-height: 320px) {
//     font-size: 10px;
//   }
//   @media (min-height: 640px) {
//     font-size: 10px;
//   }
//   @media (min-height: 720px) {
//     font-size: 10px;
//   }
//   @media (min-height: 760px) {
//     font-size: 12px;
//   }
//   @media (min-height: 800px) {
//     font-size: 14px;
//   }
//   @media (min-height: 1000px) {
//     font-size: 16px;
//   }
//   @media (min-height: 1200px) {
//     font-size: 18px;
//   }

//   :hover {
//     cursor: default;
//   }
// `;

// const LogoWrapper = styled.div`
//   display: flex;
//   flex-direction: row;

//   justify-content: center;
//   align-items: center;

//   gap: 4px;
// `;

// const LogoImage = styled.img`
//   width: 36px;
//   height: 36px;

//   @media (min-height: 320px) {
//     width: 26px;
//     height: 26px;
//   }
//   @media (min-height: 640px) {
//     width: 28px;
//     height: 28px;
//   }
//   @media (min-height: 720px) {
//     width: 30px;
//     height: 30px;
//   }
//   @media (min-height: 760px) {
//     width: 32px;
//     height: 32px;
//   }
//   @media (min-height: 800px) {
//     width: 34px;
//     height: 34px;
//   }
//   @media (min-height: 1000px) {
//     width: 36px;
//     height: 36px;
//   }
//   @media (min-height: 1200px) {
//     width: 38px;
//     height: 38px;
//   }
// `;

// const LogoText = styled.p`
//   font-family: NotoSansKR-Medium;
//   font-size: 10px;

//   color: ${props => props.color};

//   margin: 0 0 0 0;

//   @media (min-height: 320px) {
//     font-size: 10px;
//   }
//   @media (min-height: 640px) {
//     font-size: 10px;
//   }
//   @media (min-height: 720px) {
//     font-size: 12px;
//   }
//   @media (min-height: 760px) {
//     font-size: 12px;
//   }
//   @media (min-height: 800px) {
//     font-size: 14px;
//   }
//   @media (min-height: 1000px) {
//     font-size: 14px;
//   }
//   @media (min-height: 1200px) {
//     font-size: 16px;
//   }

//   :hover {
//     cursor: default;
//   }
// `;

const TotalRingRingHandImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;

  z-index: 1004;

  @media (min-height: 320px) {
    margin-top: ${props => props.isFriday ? `-80px` : `-40px`};
  }
  @media (min-height: 640px) {
    margin-top: ${props => props.isFriday ? `-100px` : `-40px`};
  }
  @media (min-height: 720px) {
    margin-top: ${props => props.isFriday ? `-120px` : `-60px`};
  }
  @media (min-height: 760px) {
    margin-top: ${props => props.isFriday ? `-130px` : `-70px`};
  }
  @media (min-height: 800px) {
    margin-top: ${props => props.isFriday ? `-140px` : `-80px`};
  }
  @media (min-height: 1000px) {
    margin-top: ${props => props.isFriday ? `-160px` : `-100px`};
  }
  @media (min-height: 1200px) {
    margin-top: ${props => props.isFriday ? `-180px` : `-120px`};
  }
`;

const RingRingHandImageWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  // height: 100%;
`;

const RingRingHandImage = styled.img`
  width: 100%;
`;

const PoweredByWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 16px;

  width: 342px;

  @media (min-height: 320px) {
    margin-top: 8px;
    
    width: 62px;
  }
  @media (min-height: 640px) {
    margin-top: 12px;
    
    width: 102px;
  }
  @media (min-height: 680px) {
    margin-top: 16px;
    
    width: 142px;
  }
  @media (min-height: 720px) {
    margin-top: 20px;
    
    width: 182px;
  }
  @media (min-height: 760px) {
    margin-top: 24px;
    
    width: 222px;
  }
  @media (min-height: 800px) {
    margin-top: 28px;
    
    width: 262px;
  }
  @media (min-height: 1000px) {
    margin-top: 32px;
    
    width: 302px;
  }
  @media (min-height: 1200px) {
    margin-top: 36px;

    width: 342px;
  }
`;

const PoweredByImage = styled.img`
  width: 100%;
`;
