import { React, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';

import PaymentModal from './PaymentModal.js';

import FetchModule from '../../Share/Network/FetchModule';
import OnSitePaymentMemberShipInfoView from './OnSitePaymentMemberShipInfoView';
import SessionStorage from '../../Share/Network/SessionStorage.js';

const OnSitePaymentModal = (props) => {
  const { userData, merchantUid, rerendering, setRerendering, cancel, closeFn } = props;

  const history = useHistory();

  const [data, setData] = useState(undefined);
  const [isClicked, setIsClicked] = useState(false);
  const [productName, setProductName] = useState('');
  const [productCoach, setProductCoach] = useState('');
  const [productValidity, setProductValidity] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productCash, setProductCash] = useState(0);

  const InfoViewRef = useRef();

  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  useEffect(() => {
    if (merchantUid === undefined) return;

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('sales/merchant_data/' + merchantUid, 'POST');
       
        if (responseData.status === 200) {
          setData(responseData.data[0])
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();

  }, [merchantUid])

  function onClickRegistrationButton() {
    var membershipData = InfoViewRef.current.getMemberShipData();
    var cardPrice = InfoViewRef.current.getCardPrice();
    var cashPrice = InfoViewRef.current.getCashPrice();

    setProductName(InfoViewRef.current.getMemberShipName());
    setProductCoach('');
    setProductValidity(membershipData.validity);
    setProductPrice(cardPrice);
    setProductCash(cashPrice);

    setIsClicked(true);
  }

  function cancelFunction(merchant_uid) {
    var name = userData.userName;
    var birthDay = (userData.birthDay.slice(7, 8) === '1' || userData.birthDay.slice(7, 8) === '2') ? '19' + userData.birthDay.slice(0, 6) : '20' + userData.birthDay.slice(0, 6);
    var gender = (userData.birthDay.slice(7, 8) === '1' || userData.birthDay.slice(7, 8) === '3') ? 'male' : 'female';
    var phone = userData.phone;
    var id = userData.userId;
    var memberShipName = productName;
    var profile = userData.profile;

    var requestData = {
      name: name,
      birthDay: birthDay,
      // storeId: SessionStorage.getStoreId(),
      storeId: window.localStorage.getItem('StoreId'),
      gender: gender,
      phone: phone,
      linkCoachId: id,
      memberShipName: memberShipName,
      profile: profile,
      memberDetail: '',
      approval: moment().format('YYYY-MM-DDTHH:mm:ss')
    }
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('member/registration/' + merchant_uid, 'POST', requestData);

        if (responseData.status === 200) {
          (
            async () => {
              const coachResponseData = await fetchModule.postDataOfCoach('registration/modify/' + userData.id + '/' +  merchant_uid + '/' + id + '/' + 1, 'POST');
             
              if (coachResponseData.status === 200) {
                // alert("등록이 완료되었습니다.");
                cancel(responseData.data[0]);
                setRerendering(!rerendering);
              }
              else if (coachResponseData.status === 401) {
                alert('로그인 후 다시시도하세요');
                history.push('/');
              }
            }
          )();
        }
        else if (responseData.status === 401) {
          alert('로그인 후 사용해주세요');
          history.push('/');
        }
        else {
          alert("회원등록에 실패하였습니다. 잠시후 다시시도해 주세요.");
          return;
        } 
      }
    )();

    setIsClicked(false);
    // cancel();
    closeFn();
  }

  return (
    <TopLevelWrapper>
      {
        isClicked &&
        <PaymentModal
          name={productName}
          coach={productCoach}
          validity={productValidity}
          price={productPrice}
          cashPrice={productCash}
          merchantUid={merchantUid}
          onRegist={cancelFunction}
          cancel={() => setIsClicked(false)} />
      }
      <OnSitePaymentMemberShipInfoView 
        ref={InfoViewRef} 
        data={data} 
        registerClick={onClickRegistrationButton} 
        closeFn={closeFn}/>
    </TopLevelWrapper> 
  )
}

export default  OnSitePaymentModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  overflow: scroll;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
  
  &::-webkit-scrollbar {
    height: 2px;
    width: 1px;
  }

  &::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;
