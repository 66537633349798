import { React, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import FetchModule from '../Network/FetchModule';

import HoverNoticePath from '../../../image/Notice.png';
// import NotHoverNoticePath from '../../../image/NotHoverNotice.png';
import NotHoverNoticePath from '../../../image/RedNotHoverNotice.png';

function NoticeBox(props) {
  const { isOver } = props;
  const [nowPage, setNowPage] = useState('Holding');
  const [onView, setOnView] = useState(false);

  const [holdData, setHoldData] = useState(new Array);
  const [expireData, setExpireData] = useState(new Array);

  const NoticeButtonRef = useRef();
  const NoticeRef = useRef();

  const history = useHistory();

  useEffect(() => {
    if (onView) {
      NoticeRef.current.style.height = `480px`;
      NoticeRef.current.style.border = `1px solid #FF8B48`;
    }
    else {
      NoticeRef.current.style.height = `0px`;
      NoticeRef.current.style.border = `0px`;
    }
  }, [onView])

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('store/notification', 'GET');
        // console.log("=========");
        // console.log(responseData);
        if (responseData.status === 200) {
          setExpireData(responseData.data[0].expires);
          setHoldData(responseData.data[0].hold);
				}
        // else if (responseData.status === 401) history.push('/');
        // else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }, [])

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  function onClickOutSide(e) {
    if (NoticeRef.current !== e.target && !NoticeRef.current.contains(e.target) && NoticeButtonRef.current !== e.target ) {
      setOnView(false);
    }
  }

  function onClickMember(data) {
    // data.division === 'MemberShip' ? '회원권' : '락커'
    if (data.division === 'Locker') history.push('/locker');
    else if (data.division === 'MemberShip') history.push('/member/valid/detail/' + data.memberId);
    else history.push('/member/valid/detail/' + data.memberId + '/holding');

    setOnView(!onView);
  }

  return (
    <TopLevelWrapper>
      <NoticeButton ref={NoticeButtonRef} onClick={() => setOnView(!onView)}/>
      <NoticeView ref={NoticeRef} isOver={isOver}>
        <InfoWrapper isOver={isOver}>
          <Title>알림</Title>
          <ButtonWrapper>
            <Button isClicked={nowPage === 'Holding'} onClick={() => setNowPage('Holding')}>홀딩 요청</Button>
            <Button isClicked={nowPage === 'Expired'} onClick={() => setNowPage('Expired')}>만료 예정</Button>
          </ButtonWrapper>
          {
            nowPage === 'Holding' &&
            <InfoBox>
              <ColumnWrapper>
                <Column isOver={isOver} style={isOver ? { marginRight: `48px` } : { marginRight: `34px` }}>이름</Column>
                <Column isOver={isOver} style={isOver ? { marginRight: `24px` } : { marginRight: `12px` }}>적용일수</Column>
                <Column isOver={isOver} style={isOver ? { marginRight: `36px` } : { marginRight: `11px` }}>남은 일수</Column>
                <Column isOver={isOver}>신청일</Column>
              </ColumnWrapper>
                {
                  holdData.map((data, index) => (
                    <Info key={index} isOver={isOver} onClick={() => onClickMember(data)}>
                      <Tr>
                        <Td style={{ width: isOver ? `66px` : `49px`, height: `20px`, overflow: `hidden`, marginRight: `8px` }}>{data.name}</Td>
                        <Td style={{ width: isOver ? `68px` : `51px`, marginRight: `8px` }}>{(moment(data.endDate.split('T')[0]).diff(moment(data.startDate.split('T')[0]), 'day') + 1) + '일'}</Td>
                        <Td style={{ width: isOver ? `83px` : `50px`, marginRight: `8px` }}>{data.redundancyDay}일</Td>
                        <Td style={{ width: isOver ? `70px` : `60px` }}>{moment(data.startDate).format('YYYY.MM.DD')}</Td>
                      </Tr>
                    </Info>
                  ))
              }
              {/* <Info>
                <Tr>
                  <Td style={{ width: `66px`, marginRight: `8px` }}>박박박박</Td>
                  <Td style={{ width: `68px`, marginRight: `8px` }}>3일</Td>
                  <Td style={{ width: `83px`, marginRight: `8px` }}>13일</Td>
                  <Td style={{ width: `70px` }}>2022.12.13</Td>
                </Tr>
              </Info> */}
            </InfoBox>
          }
          {
            nowPage === 'Expired' &&
            <InfoBox>
              <ColumnWrapper>
                <Column isOver={isOver} style={ isOver ? { marginRight: `48px` } : { marginRight: `38px` }}>이름</Column>
                <Column isOver={isOver} style={ isOver ? { marginRight: `50px` } : { marginRight: `27px` }}>구분</Column>
                <Column isOver={isOver} style={ isOver ? { marginRight: `36px` } : { marginRight: `16px` }}>남은 일수</Column>
                <Column isOver={isOver} >만료일</Column>
              </ColumnWrapper>
              {
                  expireData.map((data, index) => (
                    <Info key={index} isOver={isOver} onClick={() => onClickMember(data)}>
                      <Tr>
                        <Td style={{ width: isOver ? `66px` : `53px`, height: `20px`, marginRight: `8px`, overflow: `hidden` }}>{data.name}</Td>
                        <Td style={{ width: isOver ? `68px` : `42px`, marginRight: `8px` }}>{data.division === 'MemberShip' ? '회원권' : '락커'}</Td>
                        <Td style={{ width: isOver ? `83px` : `55px`, marginRight: `8px` }}>{moment(data.endDate).diff(moment(), 'day') === 0 ? 'D-day' : moment(data.endDate).diff(moment(), 'day') + '일'}</Td>
                        <Td style={{ width: isOver ? `70px` : `60px`}}>{moment(data.endDate).format('YYYY.MM.DD')}</Td>
                      </Tr>
                    </Info>
                  ))
                }
              {/* <Info>
                <Tr>
                  <Td style={{ width: `66px`, marginRight: `8px` }}>박박박박</Td>
                  <Td style={{ width: `68px`, marginRight: `8px` }}>락커</Td>
                  <Td style={{ width: `83px`, marginRight: `8px` }}>3일</Td>
                  <Td style={{ width: `70px` }}>2022.12.13</Td>
                </Tr>
              </Info> */}
            </InfoBox>
          }
        </InfoWrapper>
      </NoticeView>
    </TopLevelWrapper>
  )
}

export default NoticeBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;
`;

const NoticeButton = styled.div`
  display: flex;
  flex-direction: row;
  
  width: 32px;
  height: 32px;

  border-radius: 32px;

  justify-content: center;
  align-items: center;

  background: url(${NotHoverNoticePath}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: url(${HoverNoticePath}) no-repeat center center;
    background-color: #FF8B48;
  }
`;

const NoticeView = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 40px;
  margin-right: -20px;

  width: ${props => props.isOver ? `399px` : `300px`};
  // height: 480px;
  height: 0px;

  overflow: hidden;

  z-index: 3;

  // border: 1px solid #FF8B48;
  border: 0px;
  border-radius: 30px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isOver ? `343px` : `244px`};
  height: ${props => props.isOver ? `424px` : `403px`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  gap: 16px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding-bottom: 5px;

  border-bottom: ${props => props.isClicked ? `2px solid #FF8B48` : `2px solid #FFFFFF`};

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: ${props => props.isClicked ? `#232323` : `#BDBDBD`};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border-bottom: 2px solid #FF8B48;
    color: #232323;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  overflow-x: default;
  overflow-y: scroll;

  margin-top: 20px;

  width: 100%;
  height: 332px;

  // border-top: 2px solid #ECECEC;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    // height: 92px;
    background-color: #DADADA;
    border-radius: 200px;
  }

  &::-webkit-scrollbar-track {
    // height: 439px;
    // border: 4px solid transparent;
    background-color: #F0F0F0;
    border-radius: 200px;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #595959;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 16px;
  padding-bottom: 16px;
  
  border-bottom: 1px solid #F5F5F5;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #595959;

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
    color: #FF8B48;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Td = styled.tr`
  
`;