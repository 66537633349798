import { React, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import PriceInput from '../../../Component/MySettingPageComponent/PrintInput';
import FetchModule from '../../Share/Network/FetchModule';


function RefundModal(props) {
  const { className, rendering, exitClick, refundData, index } = props;

  const history = useHistory();

  const [refundPrice, setRefundPrice] = useState(0);
  const [refundCardPrice, setRefundCardPrice] = useState(0);
  const [refundCashPrice, setRefundCashPrice] = useState(0);

  // 센터 환불인지 링크 환불인지 여부
  const [isCenterRefund, setIsCenterRefund] = useState(false);

  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  useEffect(() => {
    if (refundData.merchantUid === undefined) return;

    if (refundData.merchantUid.split('-')[2] !== '3') {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.getIndividualData('sales/refund_info/' + refundData.merchantUid, 'GET');

          if (responseData.status === 200) setRefundPrice(responseData.data[0]);
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    else {
      setIsCenterRefund(true);
    }
  }, [history, refundData])

  function onClickRefund() {

    if (!isCenterRefund) {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/refund/application/' + refundData.merchantUid, 'POST');
          
          if (responseData.status === 200) {
            rendering();
            exitClick();
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    else {
      if (parseInt(refundCardPrice) + parseInt(refundCashPrice) === 0) {
        alert("환불금액을 입력해주세요");
        return;
      } 

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/refund/center/application/' + refundData.merchantUid + '/' + refundCardPrice + '/' + refundCashPrice, 'POST');
          
          if (responseData.status === 200) {
            rendering();
            exitClick();
          }
          else if (responseData.status === 204) alert("환불 가능 금액을 초과하였습니다.");
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TextWrapper topMagin={28}>
          {
            isCenterRefund ?
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>센터에서 직접 환불하기</Text>
                :
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>링크 환불 요청하기</Text>
          }
        </TextWrapper>
        <TextWrapper topMagin={16}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>선택한 결제 내역에 대한 환불을 진행하며</Text>
        </TextWrapper>
        <TextWrapper topMagin={14}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>되돌릴 수 없습니다. 환불을 계속 하시겠습니까?</Text>
        </TextWrapper>
        {
          isCenterRefund ?
            <ComponentWrapper>
              <TextWrapper topMagin={13}>
                <PriceInput division='카드' value={refundCardPrice} setValue={setRefundCardPrice} />
              </TextWrapper>
              <TextWrapper topMagin={13}>
                <PriceInput division='현금' value={refundCashPrice} setValue={setRefundCashPrice} />
              </TextWrapper>
              <CenterTextWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>총</Text>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF3131' style={{ marginLeft: `4px` }}>{(parseInt(refundCashPrice) + parseInt(refundCardPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 </Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>을 환급합니다.</Text>
              </CenterTextWrapper>
            </ComponentWrapper>
            :
            <ComponentWrapper>
              <TextWrapper topMagin={27}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>이 회원은</Text>
              </TextWrapper>
              <TextWrapper topMagin={14}>
                <LayerWrapper>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>지금 환불 요청하면 </Text>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959'> {refundPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 </Text>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'> 환급 가능합니다.</Text>
                </LayerWrapper>
                <RefundRuleButton onClick={() => history.push('/rule/refund')}>[환불 규정 확인하기]</RefundRuleButton>
              </TextWrapper>
            </ComponentWrapper>
        }
        <ButtonWrapper isCenterRefund={isCenterRefund}>
          <ExitButton onClick={exitClick}>취소</ExitButton>
          <SaveButton onClick={onClickRefund}>확인</SaveButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default RefundModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;
  
  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 371px;
  height: 343px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  margin-top: ${props => props.isCenterRefund ? `28px` : `37px`};
  gap: 16px;

  width: 314px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start
  
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const CenterTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;

  margin-top: 22px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: ${props => props.topMagin}px;

  width: 314px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RefundRuleButton = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: red;

  margin: 0 0 0 160px;

  :hover {
    cursor: pointer;
  }
`;