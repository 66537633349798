import { React } from 'react';
import styled from 'styled-components';

function ShareView() {

  return (
    <TopLevelWrapper>
      <TopBarWrapper>
        <TextWrapper>
          <Title>공유</Title>
          <Explanation>센터 별 프로그램을 구매하거나 공유할 수 있어요</Explanation>
        </TextWrapper>
      </TopBarWrapper>
    </TopLevelWrapper>
  )
}

export default ShareView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 34px;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;