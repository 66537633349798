import { React, useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import NotReceivedList from './NotReceivedList';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

const NotReceivedHistory = (props) => {
  const { today, data, isOver, isNotReceivedLoading, setIsNotReceivedLoading } = props;

  useEffect(() => {
    setTimeout(() => {
      setIsNotReceivedLoading(false)
    }, 1000)
  }, [data])

  return (
    <TopLevelWrapper isOver={isOver}>
      <InfoBox isOver={isOver}>
        <Title isOver={isOver}>{today.clone().format('YYYY년 MM월 미수금 내역')}</Title>
        {
          isNotReceivedLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <NotReceivedList data={data} isOver={isOver} />
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default NotReceivedHistory;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(300px, 93.75vw)`};

  margin-top: ${props => props.isOver ? `38px` : `15px`};
  // margin-bottom: ${props => props.isOver ? `38px` : `15px`};

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  width: 98%;
  height: ${props => props.isOver ? `528px` : `248px`};

  justify-conetent: center;
  align-items: flex-start;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  height: 100%;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 28px 0 0 13px;
`;