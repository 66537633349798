import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import CalenderModal from '../NoteModel/CalenderModal';
import MemberListView from './MemberListView';
import ExerciseBox from './ExerciseBox';
import NoticeView from './NoticeView';
import StopWatchView from './StopWatchView';
import MemberListModal from './MemberListModal';

import FetchModule from '../../Share/Network/FetchModule';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import CalenderBasic from '../../../image/calendarB.png'//캘린더 기본
import CalenderHover from '../../../image/calendarH.png'
import useWindowSize from '../../Share/Normal/useWindowSize';

function BoardView() {
  const history = useHistory();
  const size = useWindowSize();

  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment());
  const [dateList, setDateList] = useState([]);
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [workoutId, setWorkoutId] = useState(0);
  const [memberAddClick, setMemberAddClick] = useState(false);
  const [calenderClick, setCalenderClick] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [scheduleId, setScheduleId] = useState(-1);
  const [classTime, setClassTime] = useState(undefined);
  const [lessonId, setLessonId] = useState(-1);
  const [rendering, setRendering] = useState(false);
  const [memberListRendering, setMemberListRendering] = useState(false);

  const [isOver, setIsOver] = useState(false);

  const [useData, setUseData] = useState([{
    workoutId: 0,
    scheduleId: 0,
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    classDate: moment(),
    title: '작성이 필요합니다.',
    sectionList: []
  }]);

  const [notepad, setNotepad] = useState({
    id: 0,
    workoutId: 0,
    contents: '',
  });

  const ExerciseRef = useRef();
  const CalenderButtonRef = useRef();
  const CalenderRef = useRef();
  const MemberListRef = useRef();

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  // Display Size Setting //
  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  // Calender Date Setting //
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');

        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      }
    }
    setClickDate(date.clone().format('YYYY.MM.DD'));
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek])

  // Alive Member API Call (Light Ver...) //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.getData('member/alive/light/', 'GET');

        if (responseData.status === 200) {
          // console.log(responseData);
          setMemberList(responseData.data);
        }
        else if (responseData.status === 204) { }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history])

  // Workout API Call //
  useEffect(() => {
    if (scheduleId === -1) return;

    var data = {
      scheduleId: scheduleId,
      month: date.clone().format('MM')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.postDataOfExercise('workout/', 'POST', data);
        // console.log(responseData);
        if (responseData.status === 200) {
          var workoutData = responseData.data.slice();

          // 운동 데이터 정리 //
          for (var i = 0; i < workoutData.length; i++) {

            workoutData[i].sectionList.sort(function (a, b) {
              return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
            })

            for (var j = 0; j < workoutData[i].sectionList.length; j++) {
              workoutData[i].sectionList[j].buyinExerciseList = [];
              workoutData[i].sectionList[j].mainExerciseList = [];
              workoutData[i].sectionList[j].buyoutExerciseList = [];
              workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');

              for (var h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
                workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
                workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
                workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;

                if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
                  workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
                  workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
                  workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
              }

              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyinExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyoutExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
            }
          }

          setData(workoutData);
        }
        else if (responseData.status === 204) setData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, date, scheduleId, rendering])


  // Workout Data Setting //
  useEffect(() => {
    if (dateList.length === 0) return;

    var newData = data.slice();

    if (newData.length === 0) { // 데이터가 없으면 1개 넣고 시작
      var tmpData = {
        workoutId: 0,
        scheduleId: 0,
        storeId: parseInt(window.localStorage.getItem('StoreId')),
        classDate: dateList[0],
        title: '',
        sectionList: []
      }
    }

    for (var i = 0; i < dateList.length; i++) {
      var isNone = true;

      for (var j = 0; j < newData.length; j++) {
        if (dateList[i].format('MM.DD') === moment(newData[j].classDate).format('MM.DD')) {
          isNone = false;
          newData[j].classDate = moment(newData[j].classDate);
          break;
        }
      }

      if (isNone) {
        tmpData = {
          workoutId: 0,
          scheduleId: 0,
          storeId: parseInt(window.localStorage.getItem('StoreId')),
          classDate: dateList[i],
          title: '',
          sectionList: []
        }
        newData.push(tmpData);
      }
    }

    newData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    setUseData(newData);
  }, [dateList, data])


  // 운동 메모 불러오기 //
  useEffect(() => {
    if (workoutId === 0) return;

    var requestData = {
      workoutId: workoutId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.postDataOfExercise('notepad/', 'POST', requestData);

        if (responseData.status === 200) {
          if (responseData.data[0] !== null) setNotepad(responseData.data[0]);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [workoutId])

  function onMouseOver() {
    CalenderButtonRef.current.style.border = `1px solid #777777`;
    CalenderButtonRef.current.style.background = `url(${CalenderHover}) no-repeat center center`;
  }

  function onMouseLeave() {
    CalenderButtonRef.current.style.border = `1px solid #DFDFDF`;
    CalenderButtonRef.current.style.background = `url(${CalenderBasic}) no-repeat center center`;
  }

  function dateClick(value) {
    setClickDate(value.format('YYYY.MM.DD'));
    setDate(value);
    // ScheduleRef.current.setValue(value);
    if (calenderClick) CalenderRef.current.setValue(value);
  }

  function openFullScreenClick() {
    alert("지원하지 않는 디바이스입니다.");
    return;
  }

  // Member Reserve Add Button Click //
  function onClickAddMemberSave(memberId) {

    var index = -1;

    for (var i = 0; i < memberList.length; i++) {
      if (memberList[i].memberId === memberId) index = i;
    }

    var requestData = {
      scheduleId: scheduleId,
      lessonId: lessonId,
      userId: memberList[index].memberId,
      classDate: date.format('YYYY-MM-DD') + "T00:00:00",
      classTime: classTime,
      status: 0
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/create/manage', 'POST', requestData);

        if (responseData.status === 200) setMemberListRendering(!memberListRendering);
        else if (responseData.status === 401) history.push('/');
      }
    )();

    setMemberAddClick(false);
  }

  return (
    <TopLevelWrapper>
      {calenderClick && <CalenderModal ref={CalenderRef} isOver={isOver} clickDate={clickDate} date={date} dateClickFn={dateClick} exitFn={setCalenderClick} />}
      {memberAddClick && <MemberListModal ref={MemberListRef} memberData={memberList} saveClick={onClickAddMemberSave} exitClick={() => setMemberAddClick(false)} />}
      <TopBarWrapper isOver={isOver}>
        <TextWrapper>
          <Title isOver={isOver}>보드</Title>
          <Explanation isOver={isOver}>센터의 일정이나 계획을 간편하게 입력할 수 있어요</Explanation>
        </TextWrapper>
      </TopBarWrapper>
      <TopButtonWrapper isOver={isOver}>
        <LeftWrapper>
          <DateButtonWrapper isOver={isOver}>
            <ArrowWrapper isOver={isOver} onClick={() => setDate(date.clone().subtract(1, 'day'))}>
              <ArrowImage src={LeftArrow} />
            </ArrowWrapper>
            <DateText isOver={isOver}>
              {
                isOver ?
                  date.clone().format('YYYY년 MM월 DD일')
                  :
                  date.clone().format('MM월 DD일')
              }
            </DateText>
            <ArrowWrapper isOver={isOver} onClick={() => setDate(date.clone().add(1, 'day'))} >
              <ArrowImage src={RightArrow} />
            </ArrowWrapper>
          </DateButtonWrapper>
          <TodayButton isOver={isOver} onClick={() => setDate(moment())}>오늘</TodayButton>
          <CalenderButton ref={CalenderButtonRef} img={CalenderBasic} isOver={isOver} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={() => setCalenderClick(!calenderClick)} />
        </LeftWrapper>
        <RightWrapper>
          <Button isOver={isOver} onClick={openFullScreenClick}>전체화면 보기</Button>
        </RightWrapper>
      </TopButtonWrapper>
      <MainView isOver={isOver}>
        <MemberListView isOver={isOver} date={date} scheduleId={scheduleId} classTime={classTime} memberListRendering={memberListRendering} setMemberAddClick={setMemberAddClick} />
        <ExerciseBox 
          isOver={isOver}
          ref={ExerciseRef} 
          clickDate={clickDate} 
          useData={useData} 
          date={date}
          classTime={classTime}
          workoutId={workoutId}
          setWorkoutId={setWorkoutId}
          setLessonId={setLessonId} 
          setScheduleId={setScheduleId} 
          setClassTime={setClassTime} 
          rendering={() => setRendering(!rendering)}/>
        <NoticeView isOver={isOver} notepad={notepad} setNotepad={setNotepad} workoutId={workoutId} clickDate={clickDate}/>
        {isOver && <StopWatchView isOver={isOver} />}
      </MainView>
    </TopLevelWrapper>
  )
}

export default BoardView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: ${props => props.isOver ? `28px` : `14px`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 100%;
  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(296px, 92.5vw)`};

  // margin-top: 38px;
  margin-top: 32px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `205px` : `101px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: #FFFFFF;

  gap: ${props => props.isOver ? `20px` : `10px`};
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `22px` : `17px`};
  height: ${props => props.isOver ? `22px` : `17px`};

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;
`;

const TodayButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `52px` : `42px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const CalenderButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `38px` : `29px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: #FFFFFF;
  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `127px` : `78px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `9px`};
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const MainView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `30px` : `17px`};
  margin-top: 16px;
  margin-bottom: 96px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  gap: 30px;
`;