import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import lodash from 'lodash';

import SubjectInput from '../../../Component/NoteComponent/SubjectInput';
import TitleSectionInput from '../../../Component/NoteComponent/TitleSectionInput';
import TextArea from '../../../Component/NoteComponent/TextArea';
import VideoEditModal from './VideoModal';

import FetchModule from '../../Share/Network/FetchModule';

import ClearButton from '../../../image/ClearButton.png';
import VideoEditButtonImg from '../../../image/VideoEditButton.png';

const OneOnOneExerciseBox = (props) => {
  const { isOver, useData, clickDate, memberId, rendering } = props;

  const history = useHistory();

  const [index, setIndex] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [copyData, setCopyData] = useState([]);
  const [videoClick, setVideoClick] = useState(false);
  const [videoClass, setVideoClass] = useState('None');
  const [detailIndex, setDetailIndex] = useState(-1);
  const [iframeWidth, setIframeWidth] = useState(482);
  const [iframeHeight, setIframeHeight] = useState(270);

  const TotalTitleRef = useRef();

  const WarmupTitleRef = useRef([]);
  const WarmupTextRef = useRef([]);

  const ExerciseTitleRef = useRef([]);
  const ExerciseTextRef = useRef([]);

  useEffect(() => {
    for (var i = 0; i < useData.length; i++) {
      if (useData[i].classDate.format('YYYY.MM.DD') === clickDate) {
        setIndex(i);
        break;
      }
    }
  }, [clickDate, useData])

  useEffect(() => {
    if (isOver) {
      setIframeWidth(480);
      setIframeHeight(270);
    }
    else {
      setIframeWidth(240);
      setIframeHeight(135);
      // setIframeWidth(200);
      // setIframeHeight(169);
    }
  }, [isOver])

  function modifyButtonClick() {
    if (isClicked) {
      var newCopyData = copyData.slice();

      newCopyData[index].title = TotalTitleRef.current.getValue();
      newCopyData[index].memberId = memberId;
      newCopyData[index].classDate = copyData[index].classDate.format('YYYY-MM-DDT00:00:00');

      for (var i = 0; i < newCopyData[index].warmupList.length; i++) {
        newCopyData[index].warmupList[i].title = WarmupTitleRef.current[i].getValue();
        newCopyData[index].warmupList[i].text = WarmupTextRef.current[i].getValue();

        newCopyData[index].warmupList[i].link = '';

        for (var j = 0; j < newCopyData[index].warmupList[i].linkList.length; j++) {
          if (j === newCopyData[index].warmupList[i].linkList.length - 1) newCopyData[index].warmupList[i].link += newCopyData[index].warmupList[i].linkList[j];
          else newCopyData[index].warmupList[i].link += (newCopyData[index].warmupList[i].linkList[j] + "/");
        }
      }

      for (i = 0; i < newCopyData[index].exerciseList.length; i++) {
        newCopyData[index].exerciseList[i].title = ExerciseTitleRef.current[i].getValue();
        newCopyData[index].exerciseList[i].text = ExerciseTextRef.current[i].getValue();

        newCopyData[index].exerciseList[i].link = '';

        for (j = 0; j < newCopyData[index].exerciseList[i].linkList.length; j++) {
          if (j === newCopyData[index].exerciseList[i].linkList.length - 1) newCopyData[index].exerciseList[i].link += newCopyData[index].exerciseList[i].linkList[j];
          else newCopyData[index].exerciseList[i].link += (newCopyData[index].exerciseList[i].linkList[j] + "/");
        }
      }

      if (copyData[index].id !== 0) {

        const fetchModule = new FetchModule();
        (
          async () => {
            var responseData = await fetchModule.patchData('oneonone/modify', 'PATCH', newCopyData[index]);

            if (responseData.status === 200) {
              rendering();
            }
            else if (responseData.status === 401) history.push('/');
            else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
          }
        )();
      }
      else {
        const fetchModule = new FetchModule();
        (
          async () => {
            var responseData = await fetchModule.postData('oneonone/create', 'POST', newCopyData[index]);

            if (responseData.status === 200) {
              rendering();
            }
            else if (responseData.status === 401) history.push('/');
            else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
          }
        )();
      }
    }
    else {
      newCopyData = lodash.cloneDeep(useData);
      setCopyData(newCopyData);
    }

    setIsClicked(!isClicked);
  }

  function changeStateClick(isSucceed) {
    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.postData('oneonone/state/'
          + useData[index].id
          + '/'
          + isSucceed, 'POST');

        if (responseData.status === 200) {
          rendering();
        }
        // else if (responseData.status === 204) alert('운동 내역이 존재하지 않습니다.\n내용을 등록후 다시시도해 주세요');
        else if (responseData.status === 401) history.push('/');
        else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }

  function warmupAddButtonClick() {
    var copy = copyData.slice();

    var newData = {
      id: -1,
      // storeId: window.sessionStorage.getItem('StoreId'),
      storeId: window.localStorage.getItem('StoreId'),
      title: '',
      text: '',
      link: ' ',
      linkList: []
    }
    copy[index].warmupList.push(newData);
    setCopyData(copy);
  }

  function exerciseAddButtonClick() {
    var copy = copyData.slice();

    var newData = {
      id: -1,
      // storeId: window.sessionStorage.getItem('StoreId'),
      storeId: window.localStorage.getItem('StoreId'),
      title: '',
      text: '',
      link: ' ',
      linkList: []
    }
    copy[index].exerciseList.push(newData);
    setCopyData(copy);
  }

  function warmupClearButtonClick(idx) {
    var newData = copyData.slice();

    newData[index].warmupList.splice(idx, 1);

    setCopyData(newData);
  }

  function exerciseClearButtonClick(idx) {
    var newData = copyData.slice();

    newData[index].exerciseList.splice(idx, 1);

    setCopyData(newData);
  }

  function videoEditClick(index, className) {
    if (videoClick) {
      setVideoClass('None');
      setVideoClick(false);
      setDetailIndex(-1);
    }
    else {
      setVideoClass(className);
      setVideoClick(true);
      setDetailIndex(index);
    }
  }

  function videoSaveClick(dataList) {

    if (videoClass === 'Warmup') copyData[index].warmupList[detailIndex].linkList = dataList;
    else copyData[index].exerciseList[detailIndex].linkList = dataList;

    setVideoClass('None');
    setVideoClick(false);
    setDetailIndex(-1);
  }

  function videoDeleteClick(detailIdx, idx, className) {
    var newCopyData = copyData.slice();

    if (className === 'Warmup') {
      newCopyData[index].warmupList[detailIdx].linkList.splice(idx, 1);
    }
    else if (className === 'Exercise') {
      newCopyData[index].exerciseList[detailIdx].linkList.splice(idx, 1);
    }

    setCopyData(newCopyData);
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      {videoClick &&
        <VideoEditModal
          data={videoClass === 'Warmup' ? copyData[index].warmupList[detailIndex].linkList : copyData[index].exerciseList[detailIndex].linkList}
          saveClickFn={videoSaveClick}
          exitClickFn={videoEditClick} />}
      <TopWrapper isOver={isOver}>
        <Title>{clickDate}</Title>
        <TopButtonWrapper>
          {
            isClicked ?
              <ExitButton onClick={() => setIsClicked(false)}>취소</ExitButton>
              :
              null
          }
          <ModifyButton isClicked={isClicked} onClick={modifyButtonClick}>{isClicked ? '완료' : '수정'}</ModifyButton>
        </TopButtonWrapper>
      </TopWrapper>
      <InfoBox isOver={isOver}>
        <SubjectWrapper>
          <Subject>제목</Subject>
          {
            isClicked ?
              <SubjectInput ref={TotalTitleRef} defaultValue={copyData[index].title} isOver={isOver} />
              :
              <SubjectView isOver={isOver}>{useData[index].title}</SubjectView>
          }
        </SubjectWrapper>
        <ExerciseInfoWrapper isOver={isOver}>
          <Subject>준비 운동</Subject>
          {
            isClicked ?
              copyData[index].warmupList.map((data, idx) => (
                <ExerciseInfoBox key={idx} isOver={isOver}>
                  <ExerciseTopWrapper isOver={isOver}>
                    <ColumnSection isWarmup={true} isOver={isOver}>{String.fromCharCode(idx + 65)}</ColumnSection>
                    <TitleSectionInput ref={element => (WarmupTitleRef.current[idx] = element)} defaultValue={data.title} placeholder='운동명을 입력해주세요' isOver={isOver} />
                    <ClearSection img={ClearButton} isOver={isOver} onClick={() => warmupClearButtonClick(idx)} />
                  </ExerciseTopWrapper>
                  <ExerciseBodyWrapper>
                    <VideoEditButtonWrapper isOver={isOver}>
                      <VideoEditButton src={VideoEditButtonImg} isOver={isOver} onClick={() => videoEditClick(idx, 'Warmup')} />
                    </VideoEditButtonWrapper>
                    <TextSection>
                      <TextArea ref={element => (WarmupTextRef.current[idx] = element)} defaultValue={data.text} placeholder='운동 내용을 적어주세요' isOver={isOver} />
                    </TextSection>
                    {
                      data.linkList.map((data, idx2) => (
                        data !== '' &&
                        <VideoSection key={idx2} modify={true} isOver={isOver}>
                          <VideoDeleteButton img={ClearButton} onClick={() => videoDeleteClick(idx, idx2, 'Warmup')} />
                          <iframe
                            width={iframeWidth}
                            height={iframeHeight}
                            src={`https://www.youtube.com/embed/${data}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            // allowFullScreen={true}
                            // webkitallowfullscreen={true}
                            // mozallowfullscreen={true}
                            title="Embedded youtube"
                          />
                        </VideoSection>
                      ))
                    }
                  </ExerciseBodyWrapper>
                </ExerciseInfoBox>
              ))
              :
              useData[index].warmupList.map((data, idx) => (
                <ExerciseInfoBox key={idx} isOver={isOver}>
                  <ExerciseTopWrapper isOver={isOver}>
                    <ColumnSection isWarmup={true} isOver={isOver}>{String.fromCharCode(idx + 65)}</ColumnSection>
                    <TitleSection isOver={isOver}>{data.title}</TitleSection>
                  </ExerciseTopWrapper>
                  <ExerciseBodyWrapper>
                    <TextSection>
                      <Text isOver={isOver}>{data.text}</Text>
                    </TextSection>
                    {
                      data.linkList.map((data, idx) => (
                        data !== '' &&
                        <VideoSection key={idx} isOver={isOver}>
                          <iframe
                            display='flex'
                            width={iframeWidth}
                            height={iframeHeight}
                            src={`https://www.youtube.com/embed/${data}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            // allowFullScreen={true}
                            // webkitallowfullscreen={true}
                            // mozallowfullscreen={true}
                            title="Embedded youtube"
                          />
                        </VideoSection>
                      ))
                    }
                  </ExerciseBodyWrapper>
                </ExerciseInfoBox>
              ))
          }
        </ExerciseInfoWrapper>
        {isClicked &&
          <ButtonWrapper isOver={isOver}>
            <WarmupAddButton isOver={isOver} onClick={warmupAddButtonClick}>준비 운동 추가</WarmupAddButton>
          </ButtonWrapper>}
        <ExerciseInfoWrapper>
          <Subject>운동 프로그램</Subject>
          {
            isClicked ?
              copyData[index].exerciseList.map((data, idx) => (
                <ExerciseInfoBox key={idx} isOver={isOver}>
                  <ExerciseTopWrapper isOver={isOver}>
                    <ColumnSection isOver={isOver}>{String.fromCharCode(idx + 65)}</ColumnSection>
                    <TitleSectionInput ref={element => (ExerciseTitleRef.current[idx] = element)} defaultValue={data.title} placeholder='운동명을 입력해주세요' isOver={isOver} />
                    <ClearSection img={ClearButton} isOver={isOver} onClick={() => exerciseClearButtonClick(idx)} />
                  </ExerciseTopWrapper>
                  <ExerciseBodyWrapper>
                    <VideoEditButtonWrapper isOver={isOver}>
                      <VideoEditButton src={VideoEditButtonImg} isOver={isOver} onClick={() => videoEditClick(idx, 'Exercise')} />
                    </VideoEditButtonWrapper>
                    <TextSection>
                      <TextArea ref={element => (ExerciseTextRef.current[idx] = element)} defaultValue={data.text} placeholder='운동 내용을 적어주세요' isOver={isOver} />
                    </TextSection>
                    {
                      data.linkList.map((data, idx2) => (
                        data !== '' &&
                        <VideoSection key={idx2} modify={true} isOver={isOver}>
                          <VideoDeleteButton img={ClearButton} onClick={() => videoDeleteClick(idx, idx2, 'Exercise')} />
                          <iframe
                            width={iframeWidth}
                            height={iframeHeight}
                            src={`https://www.youtube.com/embed/${data}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            // allowFullScreen={true}
                            // webkitallowfullscreen={true}
                            // mozallowfullscreen={true}
                            title="Embedded youtube"
                          />
                        </VideoSection>
                      ))
                    }
                  </ExerciseBodyWrapper>
                </ExerciseInfoBox>
              ))
              :
              useData[index].exerciseList.map((data, idx) => (
                <ExerciseInfoBox key={idx} isOver={isOver}>
                  <ExerciseTopWrapper isOver={isOver}>
                    <ColumnSection isOver={isOver}>{String.fromCharCode(idx + 65)}</ColumnSection>
                    <TitleSection isOver={isOver}>{data.title}</TitleSection>
                  </ExerciseTopWrapper>
                  <ExerciseBodyWrapper>
                    <TextSection>
                      <Text isOver={isOver}>{data.text}</Text>
                    </TextSection>
                    {
                      data.linkList.map((data, idx) => (
                        data !== '' &&
                        <VideoSection key={idx} isOver={isOver}>
                          <iframe
                            width={iframeWidth}
                            height={iframeHeight}
                            src={`https://www.youtube.com/embed/${data}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            // allowFullScreen={true}
                            // webkitallowfullscreen={true}
                            // mozallowfullscreen={true}
                            title="Embedded youtube"
                          />
                        </VideoSection>
                      ))
                    }
                  </ExerciseBodyWrapper>
                </ExerciseInfoBox>
              ))
          }
        </ExerciseInfoWrapper>
        {isClicked &&
          <ButtonWrapper isOver={isOver}>
            <ExerciseAddButton isOver={isOver} onClick={exerciseAddButtonClick}>운동 추가</ExerciseAddButton>
          </ButtonWrapper>}
      </InfoBox>
      {
        !isClicked &&
        <StateButtonWrapper isOver={isOver}>
          <FailButton isOver={isOver} onClick={() => changeStateClick(false)}>운동 실패</FailButton>
          <SuccessButton isOver={isOver} onClick={() => changeStateClick(true)}>운동 성공</SuccessButton>
        </StateButtonWrapper>
      }
    </TopLevelWrapper>
  )
}

export default OneOnOneExerciseBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(296px, 92.5vw)`};
  // min-height: 378px;
  
  padding-bottom: 28px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // width: max(632px, 82.29166666666667vw);
  width: ${props => props.isOver ? `max(640px, 83.33333333333333vw)` : `max(252px, 78.75vw)`};
  max-height: 817px;
  
  margin-top: 24px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: ${props => props.isOver ? `max(632px, 82.29166666666667vw)` : `max(244px, 76.25vw)`};

  margin-top: 28px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const ModifyButton = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: ${props => props.isClicked ? `#5BD98A` : `#FF8B48`};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const ExitButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const SubjectWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 8px;
`;

const Subject = styled.p`
  // font-family: NotoSansKR-Regular;
  font-family: NotoSansKR-Medium;
  // font-size: 12px;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const SubjectView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: ${props => props.isOver ? `max(618px, 80.46875vw)` : `max(226px, 70.625vw)`};
  height: ${props => props.isOver ? `36px` : `27px`};

  padding-left: 16px;

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `11px`};
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #595959;
`;

const ExerciseInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;

  margin-top: ${props => props.isOver ? `24px` : `15px`};
`;

const ExerciseInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(244px, 76.25vw)`};
  min-height: ${props => props.isOver ? `70px` : `62px`};

  margin-top: 8px;
  padding: 0 0 0 0;
  overflow: hidden;

  // border: 1px solid #ECECEC;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const ExerciseTopWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: ${props => props.isOver ? `36px` : `27px`};

  maring: 0 0 0 0;
  padding: 0 0 0 0;

  // border-bottom: 1px solid #ECECEC;
  // background-color: red;

  overflow: hidden;
`;

const ExerciseBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: calc(100% - 2px);

  border: 1px solid #ECECEC;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

const ColumnSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `43px` : `31px`};
  height: ${props => props.isOver ? `36px` : `29px`};

  background-color: ${props => props.isWarmup ? `#5BD98A` : `#FF8B48`};

  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: clac(100% - 16px);
  height: ${props => props.isOver ? `36px` : `29px`};

  padding-left: 16px;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #595959;

  border: 1px solid #ECECEC;
  border-top-right-radius: 15px;
`;

const ClearSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 43px;
  height: ${props => props.isOver ? `36px` : `27px`};

  background: url(${props => props.img}) no-repeat center center;
  background-color: #ECECEC;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  :hover {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  margin-top: 8px;

  width: 99%;
  height: ${props => props.isOver ? `40px` : `32px`};
`;

const WarmupAddButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `132px` : `98px`};
  height: ${props => props.isOver ? `40px` : `30px`};

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #FFFFFF;

  // margin-top: 8px;
  // margin-left: 380px;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const ExerciseAddButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  border-radius: 15px;
  // background-color: #5BD98A;
  background-color: #FF8B48;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #FFFFFF;
  
  width: ${props => props.isOver ? `103px` : `98px`};
  height: ${props => props.isOver ? `40px` : `30px`};

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
    background-color: #FF8B48;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;

  gap: 5px;
  margin: 8px 0 8px 16px;
`;

const Text = styled.pre`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #595959;

  margin: 0 0 0 0;

  white-space: pre-wrap;
`;

const VideoSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  width: ${props => props.isOver ? `482px` : `240px`};
  height: ${props => props.modify ? 306 : 270}px;

  margin: 0 16px 16px 16px;
`;

const VideoDeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 44px;
  height: 36px;

  border-radius: 15px 15px 0px 0px;
  background: url(${props => props.img}) no-repeat center center;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;

const VideoEditButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;
  
  width: ${props => props.isOver ? `calc(100% - 8px)` : `calc(100% - 4px)`};

  padding: ${props => props.isOver ? `0 8px 0 0` : `0 4px 0 0`};
`;

const VideoEditButton = styled.img`
  width: 20px;
  height: 20px;

  // margin-left: 487px;
  // margin-left: ${props => props.isOver ? `max(610px, 79.42708333333333vw)` : `max(220px, 68.75vw)`};

  :hover {
    cursor: pointer;
  }
`;

const StateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `16px` : `13px`};
  margin-top: 16px;
  // margin-left: 310px;
  margin-left: ${props => props.isOver ? `max(448px, 58.33333333333333vw)` : `max(78px, 24.375vw)`};
`;

const SuccessButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `80px`};
  height: ${props => props.isOver ? `40px` : `32px`};

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #FFFFFF;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const FailButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `98px` : `78px`};
  height: ${props => props.isOver ? `38px` : `30px`};

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;