import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Lottie from 'lottie-react-web';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

export default function WaittingModal(props) {
  const { text } = props;

  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  return (
    <TopLevelWrapper>
      <Modal>
        <AnimationBox>
          <LottieWrapper>
            <Lottie options={{ animationData: LoadingAnimation }} />
          </LottieWrapper>
        </AnimationBox>
        <Text style={{ marginTop: '50px' }}>회원권을 확인중 입니다</Text>
      </Modal>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 50vh;
  width: 30vh;
  min-width: 280px;
  max-width: 400px;
  // height: 50vh;
  height: 30vh;
  min-height: 200px;
  max-height: 300px;

  overflow: hidden;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  // width: 100%;
  // height: 100%;

  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 10px;

  color: #000000;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 10px;
  }
  @media (min-height: 640px) {
    font-size: 12px;
  }
  @media (min-height: 720px) {
    font-size: 14px;
  }
  @media (min-height: 760px) {
    font-size: 16px;
  }
  @media (min-height: 800px) {
    font-size: 18px;
  }
  @media (min-height: 1000px) {
    font-size: 20px;
  }
  @media (min-height: 1200px) {
    font-size: 21px;
  }

  :hover {
    cursor: default;
  }
`;