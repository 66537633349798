import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Model Import //
import LogoView from '../../Model/Individual/AccountModel/LogoView.js';
import InfoView from '../../Model/Individual/AccountModel/InfoView.js';
import CompleteSignUp from '../../Model/Individual/AccountModel/CompleteSignUp.js';
import KakaoAddress from '../../Model/Share/KakaoComponent/KakaoAddress.js';
import useWindowSize from '../../Model/Share/Normal/useWindowSize.js';

function AccountPage() {
  const size = useWindowSize();

  const [isOver, setIsOver] = useState(false);
  const [isViewAddress, setIsViewAddress] = useState(false);

  const KakaoAddressRef = useRef();
  const InfoViewRef = useRef();
  const LogoViewRef = useRef();
  const CompleteRef = useRef();

  const history = useHistory();

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])
  
  useEffect(() => {
    // if (window.localStorage.getItem('Token') !== 'null') history.push('/main');
    if (window.localStorage.getItem('StoreId') !== null) history.push('/main');

    setTimeout(() => {
      setIsViewAddress(true);
    }, 3000)
  }, [])

  function kakaoAddrClick() {
    KakaoAddressRef.current.setOpacity(1);
  }

  function exitClick() {
    KakaoAddressRef.current.setOpacity(0);
    InfoViewRef.current.setAddress(KakaoAddressRef.current.getFullAddress());
  }

  function signUpClick() {
    CompleteRef.current.setUserName(InfoViewRef.current.getUserName());
    InfoViewRef.current.setOpacity(0);
    LogoViewRef.current.setOpacity(0);
    CompleteRef.current.setOpacity(1);
  }

  return (
    <Container>
      <CompleteSignUp ref={CompleteRef} isOver={isOver}/>
      <TabletViewWrapper>
        <LogoView ref={LogoViewRef} isOver={isOver}/>
        <InfoView ref={InfoViewRef} isOver={isOver} kakaoAddrClick={kakaoAddrClick} signUpClick={signUpClick} />
      </TabletViewWrapper>
      { isViewAddress && <KakaoAddress ref={KakaoAddressRef} exitClick={exitClick} isOver={isOver}/> }
    </Container>
  )
}

export default AccountPage;

const Container = styled.div`
  position: fixed;
  // position: absolute;
  display: flex;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  width: 100vw;
  // min-width: 1194px;
  height: 100vh;

  // gap: 132px;

  background-color: #FDFCFB;
`;

const TabletViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;