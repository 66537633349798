import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

const AccountInput = forwardRef((props, ref) => {
	const { className, placeholder, type, clickCountFn } = props;
	const [value, setValue] = useState('');
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		},
		focus: () => {
			inputRef.current.focus();
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			setChangedPlaceholderColor(true);
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#E8E8E8';
		}
	}));

	useEffect(() => {
		clickCountFn(className, clickCount);
	}, [clickCount, value])

	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';
		// setDefaultPlaceholder('');
	}

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#E8E8E8';
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return <Input
				ref={inputRef}
				clickCount={clickCount}
				type={type}
				tabIndex={-1}
				placeholder={defaultPlaceholder}
				value={value}
				changedColor={changedPlaceholderColor}
				className={`${className} ${changed ? "changed" : ""}`}
				onClick={handleClick}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur} />
});

export default AccountInput;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	
	padding: 0 0 4px 0;
	margin-top: 12.5px;

	width: 100%;

	outline: none;
	border-width: 0 0 1px 0;
	border-color: #E8E8E8;
	color: #000000;

	::placeholder {
		// color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.5s ease-in-out;
`;