import { useState, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

function DescriptionTextArea(props) {
	const { value, setValue } = props;
  
	const [changed, setChanged] = useState(false);

	const inputRef = useRef();

	useEffect(() => {
		inputRef.current.style.height = `${(inputRef.current.scrollHeight + 9)}px`;
	}, [])

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		// setChangedPlaceholderColor(false);
	}

	function handleBlur(e) {
		// setClickCount(clickCount + 1);
	}

  function onPress(e) {
    if (e.key === 'Enter') {
      inputRef.current.style.height = `${(inputRef.current.scrollHeight + 9)}px`;
    }
  }

 
	return (
    <InputWrapper>
      <Input
        ref={inputRef}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={onPress} />
    </InputWrapper>
  )
}

export default DescriptionTextArea;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 512px;
  height: 544px;
  max-height: 544px;

  padding: 0px;
  margin: 0px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const Input = styled.textarea`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(100% - 34px);
  height: calc(100% - 18px);
  
  resize: none;
  // overflow-y: hidden;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
  border: 0px solid #DFDFDF;

  padding: 0px;
  margin: 0px;

	outline: none;

  background-color: #FFFFFF;

	::placeholder {
    color: #CBCBCB;
	}
	
  // overflow-x: hidden;
  // overflow-y: scroll;

  // &::-webkit-scrollbar {
  //   width: 4px;
  // }

  // &::-webkit-scrollbar-thumb {
	// 	border: 3px solid transparent;
	// 	// height: 92px;
	// 	background-color: #DADADA;
	// 	border-radius: 200px;
	// }

  // &::-webkit-scrollbar-track {
  //   // height: 439px;
	// 	// border: 4px solid transparent;
	// 	background-color: #F0F0F0;
	// 	border-radius: 200px;
	// }
  // transition: all 0.3s ease-in-out;
`;