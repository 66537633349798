import { React } from 'react';
import styled from 'styled-components';

import LocationButton from './LocationButton';
import TopBar from './TopBar';

function RefundModel() {

  return (
    <TopLevelWrapper>
      <TopBar />
      <BottomWrapper>
        <LocationButton nowPage={'Refund'} />
        <TextWrapper>
          <BlackTitle style={{ marginTop: `38px` }}>실행일자: 2021.10.19</BlackTitle>
          
          <BlackTitle style={{ marginTop: `14px` }}>안녕하세요. 여러분의 건강을 우선으로 생각하는 Hivis(이하 ‘회사’)입니다.</BlackTitle>
          <Text>Link soft 서비스 환불정책(이하 ‘이 정책’)은 Link soft서비스 이용과 관련하여 환불 기준에 관한 내용을 담고 있습니다.Link soft 서비스 이용 전 꼭 확인하여 주세요.</Text>
        
          <Title style={{ marginTop: `38px` }}>1. link zone 이용권</Title>

          <BlackTitle style={{ marginTop: `14px` }}>결제 및 해지</BlackTitle>

          <BlackTitle style={{ marginTop: `14px` }}>1-1. 결제 주기</BlackTitle>
          <Text>Link soft 서비스 멤버십 요금과 서비스 이용과 관련해 발생하는 기타 청구 금액(예: 세금, 거래 수수료 등)은 '계정' 페이지에 표시된 결제일에 등록된 결제 수단으로 청구됩니다. 결제 주기는 회원이 Link soft 가입 시 선택한 멤버십 유형에 따라 결정됩니다. 등록된 결제 수단이 정상적으로 처리되지 않거나, 특정 월에 존재하지 않는 날짜에 유료 멤버십이 시작된 경우이거나, 회원이 멤버십 유형을 변경하는 경우 등의 일부 상황에서는 요금 결제일이 변경될 수 있습니다. Link soft 웹사이트로 이동하여 '계정' 페이지의 '결제 상세 정보' 링크를 클릭하면 다음 결제일을 확인할 수 있습니다. 타사 계정을 결제 수단으로 사용하여 Link soft에 가입한 경우에는, 해당 타사 계정에서 회원의 Link soft 멤버십 결제 정보를 확인할 수 있습니다. </Text>
        
          <BlackTitle style={{ marginTop: `14px` }}>1-2. 결제 수단</BlackTitle>
          <Text>Link soft 서비스를 이용하려면 하나 이상의 결제 수단을 제공해야 합니다. 미결제 금액에 대한 책임은 회원에게 있습니다. 유효기간 만료, 잔고 부족 등 사유로 결제가 정상적으로 처리되지 않았는데도 회원이 계정을 해지하지 않는 경우, 유효한 결제 수단에 청구가 완료될 때까지 회원의 서비스 이용이 제한될 수 있습니다. 일부 결제 수단의 경우, 발급자가 회원에게 해외 거래 수수료나 기타 결제 수단 처리 관련 수수료 등의 수수료를 청구할 수 있습니다. 현지 세금은 사용된 결제 수단에 따라 달라질 수 있습니다. 자세한 사항은 결제 수단 서비스 제공업체를 통해 확인하시기 바랍니다.</Text>
        
          <BlackTitle style={{ marginTop: `14px` }}>1-3. 결제 수단 업데이트</BlackTitle>
          <Text>회원은 '계정' 페이지로 이동하여 결제 수단을 업데이트할 수 있습니다. Link soft에서도 해당 결제 서비스 제공업체에서 제공하는 정보를 이용하여 회원의 결제 수단을 업데이트할 수 있습니다. 업데이트를 하는 경우, 회원은 Link soft가 해당 결제 수단으로 계속 청구하는 것을 승인하는 것입니다. 회원은 기본 결제 수단이 거부되거나 가입 요금 결제에 더 이상 사용될 수 없는 경우, Link soft가 회원의 계정에 연결된 다른 결제 수단으로 청구하는 것을 승인합니다.</Text>
        
          <BlackTitle style={{ marginTop: `14px` }}>1-4. 해지</BlackTitle>
          <Text>회원은 언제라도 Link soft 멤버십을 해지할 수 있으며, 이 경우 결제 주기가 종료될 때까지는 Link soft 서비스를 계속 이용할 수 있습니다. 본 약관 1.5.에서 정한 요건을 충족하는 해지와 Link soft의 고의 또는 과실을 이유로 한 환불 의무를 제외하고, 멤버십을 이용하지 않은 기간이나 시청하지 않은 Link soft 콘텐츠에 대한 환불 또는 크레딧은 제공되지 않습니다. 멤버십을 해지하려면 '계정' 페이지로 이동하여 해지 관련 안내를 따르시기 바랍니다. 멤버십을 해지하는 경우, 계정은 현재 결제 주기가 종료될 때 자동으로 종료됩니다. 다만, 본 약관 1.5.에 따라 해지를 요청한 경우, 계정은 즉시 종료됩니다. 계정이 종료되는 시점은 '계정' 페이지에서 '결제 상세 정보'를 클릭하면 확인할 수 있습니다. 회원이 타사 계정을 결제 수단으로 사용하여 Link soft에 가입한 경우, 계정을 해지하려면 (예를 들어, 해당 타사 계정에 접속해 자동 갱신을 해제하거나 해당 타사를 통해 Link soft 서비스 멤버십을 해지하는 등) 해당 타사를 통해서 해지해야 할 수도 있습니다.</Text>

          <BlackTitle style={{ marginTop: `14px` }}>1-5. 해지</BlackTitle>
          <Text>회원이 결제일로부터 7일 이내 Link soft 서비스를 이용하지않고 Link soft 멤버십을 해지하는 경우, 회원은 해당 결제 주기에 청구된 멤버십 요금을 전액 환불 요청할 수 있습니다.</Text>
        
          <BlackTitle style={{ marginTop: `14px` }}>1-6. 요금 및 멤버십 변경</BlackTitle>
          <Text>Link soft는 서비스 요금 및 멤버십을 변경할 수 있습니다. Link soft는 서비스 요금 및 멤버십의 변경에 대하여 적용 시기 등을 포함하여 회원에게 통지하여 동의를 받습니다. 회원은 해당 변경에 동의하지 않는 경우 Link soft 멤버십을 해지할 수 있습니다.</Text>
        
          <Title style={{ marginTop: `38px` }}>2. Link zone 수강권</Title>
          <Table>
            <Tr>
              <Td style={{ backgroundColor: `#FF8B48` }}>
                <Text style={{ color: `#FFFFFF` }}>환불요청일</Text>
              </Td>
              <Td style={{ backgroundColor: `#FF8B48` }}>
                <Text style={{ color: `#FFFFFF` }}>환불금액</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>수강기간 시작일로부터 7일 이내 3개 이하 수강권 (샘플  제외)을 이용한 경우</Text>
              </Td>
              <Td>
                <Text>수강기간 시작일로부터 7일 이내 3개 이하 수강권 (샘플  제외)을 이용한 경우</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>수강기간 또는 수강진도 2/Day(수강권) 경과 전</Text>
              </Td>
              <Td>
                <Text>결제대금의 Day - 3일/ Day(수강권)</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>수강기간 또는 수강진도 3/ Day(수강권)  경과 전</Text>
              </Td>
              <Td>
                <Text>결제대금의 Day - 3일/ Day(수강권)</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>수강기간 또는 수강진도 6/ Day(수강권)  경과 전</Text>
              </Td>
              <Td>
                <Text>결제대금의 Day - 6/ Day(수강권)</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>수강기간 또는 수강진도 8/ Day(수강권)  경과 전</Text>
              </Td>
              <Td>
                <Text>결제대금의 Day - 8/ Day(수강권)</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>수강기간 또는 수강진도 9/ Day(수강권)  경과 전</Text>
              </Td>
              <Td>
                <Text>결제대금의 Day - 9/ Day(수강권)</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>수강기간 또는 수강진도 12/ Day(수강권)  경과 전</Text>
              </Td>
              <Td>
                <Text>결제대금의 Day - 12/ Day(수강권)</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>수강기간 또는 수강진도 14/ Day(수강권)  경과 전</Text>
              </Td>
              <Td>
                <Text>결제대금의 Day - 14/ Day(수강권)</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>수강기간 또는 수강진도 15/ Day(수강권)  경과 전</Text>
              </Td>
              <Td>
                <Text>결제대금의 Day - 15/ Day(수강권)</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>수강기간 또는 수강진도 15/ Day(수강권)  경과 후</Text>
              </Td>
              <Td>
                <Text>환불 불가</Text>
              </Td>
            </Tr>
          </Table>
          <Text style={{marginTop: `16px`}}>ο 수강기간이란 회사가 회원에게 유상으로 제공하는 수강권이용기간을 의미합니다.</Text>
          <Text>ο 수강기간은 결제일부터 시작합니다. 단, 결제일 당시 수강권을 이용할 수 없는 경우 수강기간은 수강권영상의 이용 가능일부터 시작합니다.</Text>
          <Text>ο 수강진도란 총 수강권영상 수 중 기 이용한 영상 수의 비율을 의미합니다. 단, 수강권영상 일부 재생 시 이용으로 간주합니다.</Text>
          <Text>ο 환불요청일 기준 수강기간과 수강진도 중 높은 값을 적용하여 환불금액을 산정합니다.</Text>
          <Text>ο 회원이 수강권와 함께 제공되는 자료 또는 정보를 다운로드하는 경우 해당 자료 또는 정보가 포함된 수강권영상을 이용한 것으로 간주합니다.</Text>
          
          <Title style={{ marginTop: `38px` }}>3. 상품</Title>
          <Table>
            <Tr>
              <Td style={{ backgroundColor: `#FF8B48` }}>
                <Text style={{ color: `#FFFFFF` }}>환불요청일</Text>
              </Td>
              <Td style={{ backgroundColor: `#FF8B48` }}>
                <Text style={{ color: `#FFFFFF` }}>환불금액</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>배송 시작 전</Text>
              </Td>
              <Td>
                <Text>결제대금 전액</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>출고 시부터 상품 수령 후 7일 경과 전</Text>
              </Td>
              <Td>
                <Text>결제대금 전액 (단, 상품 반송에 소요되는 운송비를 공제)</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>상품 수령일로부터 7일 경과 후</Text>
              </Td>
              <Td>
                <Text>환불 불가</Text>
              </Td>
            </Tr>
          </Table>

          <Text style={{marginTop: `16px`}}>ο 상품에 대한 환불절차는 상품의 미배송 또는 반송이 확인된 이후 진행됩니다.</Text>
          <Text>ο 상품 구성내용이 표시·광고의 내용과 다른 경우에는 상품을 수령한 날로부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날로부터 30일 이내에 환불이 가능합니다.</Text>
          <BlackTitle>ο 아래의 경우에는 환불을 요청할 수 없습니다.</BlackTitle>
          <Text style={{ marginLeft: `12px`}}>- 회원에게 책임이 있는 사유로 상품이 멸실되거나 훼손된 경우</Text>
          <Text style={{ marginLeft: `12px`}}>- 회원이 상품을 사용하거나 또는 일부 소비하여 상품의 가치가 현저히 감소한 경우</Text>
          <Text style={{ marginLeft: `12px`}}>- 시간이 지나 재판매가 곤란할 정도로 상품의 가치가 현저히 감소한 경우</Text>
          <Text style={{ marginLeft: `12px`}}>- 복제가 가능한 상품의 포장을 훼손한 경우</Text>
          <Text style={{ marginLeft: `12px`}}>- 디지털콘텐츠 상품의 제공이 개시된 경우</Text>
          <Text style={{ marginLeft: `12px`}}>- 회사가 주문에 따라 개별적으로 생산되는 상품 등에 대하여 사전에 환불이 제한될 수 있음을 고지하고 동의를 받은 경우</Text>
          <Text style={{ marginLeft: `12px`}}>- 그 밖에 관계 법령 또는 약관에 정하여진 사유가 있는 경우</Text>
        
        
          <Title style={{ marginTop: `38px` }}>4. 정기구독서비스</Title>

          <Table>
            <Tr>
              <Td style={{ backgroundColor: `#FF8B48` }}>
                <Text style={{ color: `#FFFFFF` }}>구분</Text>
              </Td>
              <Td style={{ backgroundColor: `#FF8B48` }}>
                <Text style={{ color: `#FFFFFF` }}>환불금액</Text>
              </Td>
            </Tr>
            <Tr>
              <Td style={{height: `76px`}}>
                <Text>결제일(결제일보다 수강권 공급이 늦게 이루어진 경우 수강권 공급일</Text>
                <Text>부터 7일까지 5번 이하 수강권을 이용한 경우</Text>
              </Td>
              <Td style={{height: `76px`}}>
                <Text>결제대금 전액</Text>
              </Td>
            </Tr>
            <Tr>
              <Td style={{height: `136px`}}>
                <Text>출결제일(결제일에 수강권를 이용할 수 없는 경우 수강권 공급일)부터</Text>
                <Text>7일까지 11개 이상 수강권을 이용한 경우 또는</Text>
                <Text>결제일(결제일에 수강권를 이용할 수 없는 경우 수강권 공급일)부터</Text>
                <Text>7일 경과 후 이용기간 종료 전인 경우</Text>
              </Td>
              <Td style={{height: `136px`}}>
                <Text>아래 “A”와 “B” 중 낮은 금액에서 결제금액의 100분의 10을 공제한 금액</Text>
                <Text>* A=결제금액X[잔여 이용기간(일)/전체 이용기간(일)]</Text>
                <Text>* B=결제금액X(미 이용 수강권영상 수/전체 수강권영상 수)</Text>
                <Text>- 수강권영상 수 산정 시 무료영상 제외</Text>
                <Text>- 정기구독서비스 결제일 기준으로 수강권영상 수 산정</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text>이용기간(보너스기간 제외) 종료 시</Text>
              </Td>
              <Td>
                <Text>환불 불가</Text>
              </Td>
            </Tr>
          </Table>

          <Text style={{marginTop: `16px`}}>ο 정기구독서비스 이용기간은 개별 상품 안내페이지를 통하여 고지합니다.</Text>

          <Title style={{ marginTop: `38px` }}>5. 기타 사항</Title>

          <Text style={{marginTop: `16px`}}>5-1. 회사가 마케팅, 이벤트 등과 관련하여 무상으로 부여하는 추가 이용기간(보너스기간)은 환불에 영향을 미치지 아니합니다.</Text>
          <Text>5-2. 회사는 환불 시 금융거래수수료, 제세공과금 등을 공제할 수 있습니다.</Text>
          <Text>5-3. 회사는 회원이 관계 법령 또는 이용약관 등을 위반한 경우 이용약관 및 정책에 따라 환불을 거부할 수 있습니다.</Text>
          <Text>5-4. 결합 상품의 경우 각 구성 상품(용역)의 환불 조건에 의하되 개별 상품 안내페이지를 통하여 고지합니다.</Text>
        
          <Title style={{ marginTop: `38px` }}>6. 정책의 개정</Title>

          <Text style={{marginTop: `16px`}}>6-1. 회사는 「약관의 규제에 관한 법률」, 「전자상거래 및 소비자보호에 관한 법률」 등 관계 법령을 위배하지 아니하는 범위 내에서 이 정책을 개정할 수 있습니다.</Text>
          <Text style={{marginBottom: `96px`}}>6-2. 회사가 이 정책을 개정하는 경우 회원에게 주요 개정내용과 시행일 등을 명시하여 시행일로부터 7일 전에 전자적 방법으로 알려드리겠습니다. 다만, 회원에게 불리한 내용이 포함되는 경우 시행일로부터 30일 전에 알려드리겠습니다.Link soft 서비스와 관련하여 궁금하신 사항이 있으시면 고객센터(대표번호: 070-9028-3943 / 평일 10:00~17:00)로 문의 주시기 바랍니다.</Text>
        </TextWrapper>
      </BottomWrapper>
    </TopLevelWrapper>
  )
}

export default RefundModel;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 1194px;
  width: 90%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BlackTitle = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #FF8B48;

  margin: 0 0 0 0;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Table = styled.table`
  display: flex;
  flex-direction: column;

  justify-content: center;
  aling-items: center;

  margin-top: 16px;
`;

const Tr = styled.tr`
  display: flex;
  flex-directi: row;

  justify-content: center;
  aling-items: center;
`;

const Td = styled.td`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 597px;
  height: 56px;

  border: 1px solid #BDBDBD
`;