import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import DataBar from './DataBar';

import FetchModule from '../../Share/Network/FetchModule';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

const StatisticsChart = (props) => {
  const { today, isOver, size } = props;

  const history = useHistory();

  const monthlyDate = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
  const [yearSalesData, setYearSalesData] = useState([]);
  const [usedData, setUsedData] = useState([]);
  const [date, setDate] = useState(undefined);

  const [maximum, setMaximum] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (date !== undefined && today.format('YYYY') === date.format('YYYY')) return;

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('sales/all/' + today.clone().format('YYYY'), 'GET');

        if (responseData.status === 200) setYearSalesData(responseData.data);
        else if (responseData.status === 401) history.push('/');
        else if (responseData.status !== 204) setYearSalesData([]);
      }
    )();

    setDate(today);
    setUsedData([]);
  }, [history, today])

  useEffect(() => {

    var one = { totalPrice: 0, cashPrice: 0, cardPrice: 0, notReceived: 0 };
    var two = { totalPrice: 0, cashPrice: 0, cardPrice: 0, notReceived: 0 };
    var three = { totalPrice: 0, cashPrice: 0, cardPrice: 0, notReceived: 0 };
    var four = { totalPrice: 0, cashPrice: 0, cardPrice: 0, notReceived: 0 };
    var five = { totalPrice: 0, cashPrice: 0, cardPrice: 0, notReceived: 0 };
    var six = { totalPrice: 0, cashPrice: 0, cardPrice: 0, notReceived: 0 };
    var seven = { totalPrice: 0, cashPrice: 0, cardPrice: 0, notReceived: 0 };
    var eight = { totalPrice: 0, cashPrice: 0, cardPrice: 0, notReceived: 0 };
    var nine = { totalPrice: 0, cashPrice: 0, cardPrice: 0, notReceived: 0 };
    var ten = { totalPrice: 0, cashPrice: 0, cardPrice: 0, notReceived: 0 };
    var eleven = { totalPrice: 0, cashPrice: 0, cardPrice: 0, notReceived: 0 };
    var twelve = { totalPrice: 0, cashPrice: 0, cardPrice: 0, notReceived: 0 };

    var maxPrice = 0;

    for (var i = 0; i < yearSalesData.length; i++) {
      if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '01') {
        one.totalPrice += yearSalesData[i].originalPrice;
        one.cashPrice += yearSalesData[i].cashPrice;
        one.cardPrice += yearSalesData[i].cardPrice;
        one.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        if (one.cashPrice + one.cardPrice + one.notReceived > maxPrice) maxPrice = one.cashPrice + one.cardPrice + one.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '02') {
        two.totalPrice += yearSalesData[i].originalPrice;
        two.cashPrice += yearSalesData[i].cashPrice;
        two.cardPrice += yearSalesData[i].cardPrice;
        two.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        if (two.cashPrice + two.cardPrice + two.notReceived > maxPrice) maxPrice = two.cashPrice + two.cardPrice + two.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '03') {
        three.totalPrice += yearSalesData[i].originalPrice;
        three.cashPrice += yearSalesData[i].cashPrice;
        three.cardPrice += yearSalesData[i].cardPrice;
        three.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        if (three.cashPrice + three.cardPrice + three.notReceived > maxPrice) maxPrice = three.cashPrice + three.cardPrice + three.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '04') {
        four.totalPrice += yearSalesData[i].originalPrice;
        four.cashPrice += yearSalesData[i].cashPrice;
        four.cardPrice += yearSalesData[i].cardPrice;
        four.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        if (four.cashPrice + four.cardPrice + four.notReceived > maxPrice) maxPrice = four.cashPrice + four.cardPrice + four.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '05') {
        five.totalPrice += yearSalesData[i].originalPrice;
        five.cashPrice += yearSalesData[i].cashPrice;
        five.cardPrice += yearSalesData[i].cardPrice;
        five.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        if (five.cashPrice + five.cardPrice + five.notReceived > maxPrice) maxPrice = five.cashPrice + five.cardPrice + five.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '06') {
        six.totalPrice += yearSalesData[i].originalPrice;
        six.cashPrice += yearSalesData[i].cashPrice;
        six.cardPrice += yearSalesData[i].cardPrice;
        six.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        if (six.cashPrice + six.cardPrice + six.notReceived > maxPrice) maxPrice = six.cashPrice + six.cardPrice + six.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '07') {
        seven.totalPrice += yearSalesData[i].originalPrice;
        seven.cashPrice += yearSalesData[i].cashPrice;
        seven.cardPrice += yearSalesData[i].cardPrice;
        seven.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        if (seven.cashPrice + seven.cardPrice + seven.notReceived > maxPrice) maxPrice = seven.cashPrice + seven.cardPrice + seven.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '08') {
        eight.totalPrice += yearSalesData[i].originalPrice;
        eight.cashPrice += yearSalesData[i].cashPrice;
        eight.cardPrice += yearSalesData[i].cardPrice;
        eight.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        if (eight.cashPrice + eight.cardPrice + eight.notReceived > maxPrice) maxPrice = eight.cashPrice + eight.cardPrice + eight.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '09') {
        nine.totalPrice += yearSalesData[i].originalPrice;
        nine.cashPrice += yearSalesData[i].cashPrice;
        nine.cardPrice += yearSalesData[i].cardPrice;
        nine.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        if (nine.cashPrice + nine.cardPrice + nine.notReceived > maxPrice) maxPrice = nine.cashPrice + nine.cardPrice + nine.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '10') {
        ten.totalPrice += yearSalesData[i].originalPrice;
        ten.cashPrice += yearSalesData[i].cashPrice;
        ten.cardPrice += yearSalesData[i].cardPrice;
        ten.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        if (ten.cashPrice + ten.cardPrice + ten.notReceived > maxPrice) maxPrice = ten.cashPrice + ten.cardPrice + ten.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '11') {
        eleven.totalPrice += yearSalesData[i].originalPrice;
        eleven.cashPrice += yearSalesData[i].cashPrice;
        eleven.cardPrice += yearSalesData[i].cardPrice;
        eleven.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        if (eleven.cashPrice + eleven.cardPrice + eleven.notReceived > maxPrice) maxPrice = eleven.cashPrice + eleven.cardPrice + eleven.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '12') {
        twelve.totalPrice += yearSalesData[i].originalPrice;
        twelve.cashPrice += yearSalesData[i].cashPrice;
        twelve.cardPrice += yearSalesData[i].cardPrice;
        twelve.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        if (twelve.cashPrice + twelve.cardPrice + twelve.notReceived > maxPrice) maxPrice = twelve.cashPrice + twelve.cardPrice + twelve.notReceived;
      }
    }

    var newData = [];

    newData.push(one);
    newData.push(two);
    newData.push(three);
    newData.push(four);
    newData.push(five);
    newData.push(six);
    newData.push(seven);
    newData.push(eight);
    newData.push(nine);
    newData.push(ten);
    newData.push(eleven);
    newData.push(twelve);

    setUsedData(newData);
    setMaximum(maxPrice + (maxPrice * 0.1));

    setTimeout(() => (
      setIsLoading(false)
    ), 1000)
  }, [yearSalesData])

  return (
    <TopLevelWrapper isOver={isOver}>
      <InfoBox>
        <Title isOver={isOver}>{today.clone().format('YYYY년')} 월 별 매출 비교</Title>
        {
          isLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <InfoView>
              <TotalChartWrapper isOver={isOver}>
                <MonthlyDateWrapper isOver={isOver}>
                  {
                    monthlyDate.map((data, index) => (
                      <MonthlyDateText key={index} isOver={isOver}>{data}</MonthlyDateText>
                    ))
                  }
                </MonthlyDateWrapper>
                <ChartView isOver={isOver}>
                  {
                    usedData.map((data, index) => (
                      <DataBar key={index} data={data} maximum={maximum} isOver={isOver} size={size} />
                    ))
                  }
                </ChartView>
              </TotalChartWrapper>
            </InfoView>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default StatisticsChart;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(300px, 93.75vw)`};
  height: ${props => props.isOver ? `574px` : `372px`};

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;

  width: 90.89%;
  height: 90.24%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: center;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  height: 100%;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 526px;
  height: 474px;

  margin-top: 24px;
  border-top: 1px solid #DFDFDF;
`;

const TotalChartWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: ${props => props.isOver ? `24px` : `9px`};
  gap: ${props => props.isOver ? `max(34px, 4.427083333333333vw)` : `max(3px, 0.9375vw)`};

  width: ${props => props.isOver ? `max(629px, 81.90104166666667vw)` : `max(262px, 81.875vw)`};
  height: ${props => props.isOver ? `450px` : `320px`};
  
  border-top: 1px solid #DFDFDF;
`;

const MonthlyDateWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `18px` : `9px`};
  // margin-right: 43px;
`;

const MonthlyDateText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const ChartView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isOver ? `max(565px, 73.56770833333333vw)` : `max(235px, 73.4375vw)`};
  height: ${props => props.isOver ? `440px` : `288px`};

  gap: ${props => props.isOver ? `21px` : `12px`};

  border-left: 1px solid #CBCBCB;
  border-bottom: 1px solid #CBCBCB;
`;