import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import ExpiredMemberView from '../../Model/Individual/MemberModel/ExpiredMemberView.js';

import FetchModule from '../../Model/Share/Network/FetchModule.js';
import useWindowSize from '../../Model/Share/Normal/useWindowSize.js';

function ExpiredMemberInfoPage() {
  const history = useHistory();
  const size = useWindowSize();

  const [data, setData] = useState([]);
  const [isOver, setIsOver] = useState([]);

  const ViewRef = useRef();

  useEffect(() => {
    // const fetchModule = new FetchModule();
		// (
		// 	async () => {
		// 		const responseData = await fetchModule.getData('member/expired/', 'GET');

    //     if (responseData.status === 200) setData(responseData.data);
    //     else if (responseData.status === 401) history.push('/');
    //     // else alert("회원정보를 불러오는데 실패하였습니다.\n잠시후 다시시도 해주세요");
		// 	}
		// )();
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('member/expired/', 'POST');

        if (responseData.status === 200) {
          var newData = responseData.data.slice();

          setData(newData);
        }
        else if (responseData.status === 401) history.push('/');
        // else alert("회원정보를 불러오는데 실패하였습니다.\n잠시후 다시시도 해주세요");
			}
		)();
  }, [history])

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])

  return (
    <TopLevelWrapper>
      <ExpiredMemberView ref={ViewRef} data={data} isOver={isOver}/>
    </TopLevelWrapper>
  )
}

export default ExpiredMemberInfoPage;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // width: 100%;
`;