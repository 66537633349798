import { React } from 'react';
import styled from 'styled-components';

function DeleteConfirmModal(props) {
  const { isOver, title, itemName, clickNoFn, clickYesFn } = props;

	return (
    <Background>
      <ModalView isOver={isOver}>
        <InfoWrapper>
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
        <ExplanationWrapper topMargin={16}>
          <Explanation isOver={isOver} color='#595959'>{itemName}을 삭제하시겠습니까?</Explanation>
          <Explanation isOver={isOver} color='#FF3131'>삭제시 되돌릴 수 없습니다.</Explanation>
          <Explanation isOver={isOver} color='#595959'>확인을 누르면 삭제를 진행합니다.</Explanation>
        </ExplanationWrapper>
        <ButtonWrapper isOver={isOver} topMargin={24}>
          <NoButton isOver={isOver} onClick={clickNoFn}>취소</NoButton>
          <YesButton isOver={isOver} onClick={clickYesFn}>확인</YesButton>
        </ButtonWrapper>
        </InfoWrapper>
      </ModalView>
    </Background>
  )
}
 
export default  DeleteConfirmModal;

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalView = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

	width: ${props => props.isOver ? `372px` : `280px`};
	height: 249px;
  
	border-radius: 30px;
  background-color: #FFFFFF;

  transition: all 0.5s ease-in-out;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 84.6774%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;

  margin-top: ${props => props.topMargin}px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: ${props => props.color};

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `16px` : `8px`};

  margin-top: ${props => props.topMargin}px;
  margin-left: ${props => props.isOver ? `125px` : `104px`};
`;

const YesButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `100px` : `68px`};
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const NoButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `72px` : `48px`};
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #232323;
    color: #232323;
  }
`;