import { React, useRef } from 'react';
import styled from 'styled-components';

function PriceInput(props) {
  const { division, value, setValue } = props;
  const InputRef = useRef();

	function handleClick(e) {

	}

	function handleChange(e) {
    var regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    var result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    
    if (result === '') setValue(0);
    else setValue(parseInt(result));
	}

	function handleFocus(e) {
		// if (changed) {
		// 	// do nothing
		// } else {
		// 	setValue("");
		// }
	}

	function handleBlur(e) {
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

  return (
    <TopLevelWrapper>
      <Text fontSize={14} color='#595959'>{division}</Text>
      <ComponentWrapper>
        <Input
          ref={InputRef}
          value={value}
          onClick={handleClick}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}/>
        <Text fontSize={12} color='#777777'>원</Text>
      </ComponentWrapper>
    </TopLevelWrapper>
  )
}

export default PriceInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  } 
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 73.33%;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	
	padding: 0 8px 0 8px;

	outline: none;
	border: 1px solid #E8E8E8;
	border-radius: 8px;
	color: #CBCBCB;

	width: 193px;
	height: 30px;

	::placeholder {
		color: #CBCBCB;
	}
`;