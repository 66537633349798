import { React, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../Share/Network/FetchModule';

const SecondExpiredMemberInfo = (props) => {
  const { data, isOver, nowPage } = props;
  
  const SendRef = useRef();
  const DeleteRef = useRef();

  const history = useHistory();

  function mouseOver() {
    SendRef.current.style.color = `#FF8B48`;
    DeleteRef.current.style.color = `#FF8B48`;
  }

  function mouseLeave() {
    SendRef.current.style.color = `#FFFFFF`;
    DeleteRef.current.style.color = `#FFFFFF`;
  }
  
  function onClickMemberInfo(e) {
    if (SendRef.current !== e.target && DeleteRef.current !== e.target) {
      window.localStorage.setItem('InvalidMemberPage', nowPage);
      history.push('/member/valid/detail/' + data.memberId);
    }
  }
  
  function onClickSendMessageButton() {
    var text = data.name + '님!, 지금까지 이용하셨던 ' + data.memberShipName + '이(가) 만료되었어요!\n빠른 결제로 Link Coach와 함께 더 건강한 하루를 만들어보아요! :)';

    var requestData = {
      userId: data.linkCoachId,
      data: text,
      location: 'Reservation',
      jsonData: ''
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfCoach('push_notification/send', 'POST', requestData);
        
        if (responseData.status === 200) {
          alert("푸시알림을 전송하였습니다!");
        }
        else if (responseData.status === 204) {
          alert("Link Coach를 탈퇴한 회원입니다!");
        }
      }
    )();
  }

  // 회원권 삭제 클릭시
  function onClickMemberInfoDelete() {
    
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('member/delete/' + data.memberId, 'POST');
        // console.log(responseData);
				if (responseData.status === 200) {
          const coachResponseData = await fetchModule.postDataOfCoach('registration/zone/delete/' + window.localStorage.getItem('StoreId') + '/' + data.linkCoachId, 'POST');
          // console.log(coachResponseData);
          if (coachResponseData.status === 200) {
            alert("회원 정보가 삭제되었습니다.");
            history.push('/member/valid');
          }
				}
        else if (responseData.status === 201) {
          alert("유효한 회원정보가 아닙니다.");
          history.push('/member/valid');
        }
        else if (responseData.status === 202) {
          alert("현재 유효한 회원권이 존재합니다.\n삭제후 다시시도해 주세요.");
        }
        else if (responseData.status === 204) {
          alert("회원정보가 존재하지 않습니다.");
          history.push('/member/valid');
        }
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }
  
  return (
    <TopLevelWrapper onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={onClickMemberInfo}>
      <Table>
        <Body>
          <Tr>
            <Td style={{ width: 'max(98px, 10.6vw)' }}>
              <TextWrapper style={{ maxWidth: 'max(98px, 10.6vw)' }} isOver={isOver}>
                <Text>{data.startDate.split('T')[0].replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
            <Td style={{ width: 'max(98px, 10.5vw)' }}>
              <TextWrapper style={{ maxWidth: 'max(98px, 10.5vw)' }} isOver={isOver}>
                <Text>{data.endDate.split('T')[0].replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
            <Td style={{ width: 'max(329px, 42.83854166666667vw)' }}>
              <TextWrapper style={{ maxWidth: 'max(329px, 42.83854166666667vw)' }} isOver={isOver}>
                <Text>{data.categoryName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <SendButton ref={SendRef} onClick={() => onClickSendMessageButton()}>문자발송</SendButton>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <SendButton ref={DeleteRef} onClick={() => onClickMemberInfoDelete()}>삭제</SendButton>
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

export default SecondExpiredMemberInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: max(690px, 89.84375vw);
  height: 50px;

  background: #FFFFFF;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  margin-bottom: 8px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: center;

  // margin-left: 28px;
  margin-left: max(28px, 3.645833333333333vw);

  width: max(636px, 82.8125vw);
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  padding: 0 0 0 0;
  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: ${props => props.isOver ? `20px` : `15px`};
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const SendButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Bold;
  }
`;