import { React, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function PaymentRequestPage({match}) {
  
  const history = useHistory();

  useEffect(() => {
    window.sessionStorage.setItem("MerchantUid", match.params.merchantUid);
    window.sessionStorage.setItem("Extend", match.params.extend);
    window.sessionStorage.setItem("MembershipId", match.params.membershipId);
    window.sessionStorage.setItem("Validity", match.params.validity);
    window.sessionStorage.setItem("ExistingEndDate", match.params.existingEndDate);
    window.localStorage.setItem('Token', "Bearer " + match.params.access);
    window.sessionStorage.setItem('PureToken', match.params.access);
    // :extend/:membershipId/:validity/:existingEndate
    
    history.push('/payment');
  }, [])

  return <div/>;
}

export default PaymentRequestPage;