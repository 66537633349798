import { React, useRef } from 'react';
import styled from 'styled-components';

import DateControlModel from './DateControlModel';
import ScheduleSettingView from './ScheduleSettingView';

function ScheduleTotalView() {

  const ScheduleSettingRef = useRef();

  function ChangeToday(startThisWeek) {
    ScheduleSettingRef.current.setStartThisWeek(startThisWeek.clone());
  }

  function onClickPrevPage() {
    console.log('prev');
  }

  function onClickNextPage() {
    console.log('next');
  }

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <Title>시간표 제작</Title>
      </TitleWrapper>
      <ExplanationWrapper>
        <Explanation>센터의 특성에 맞는 시간표를 제작할 수 있어요</Explanation>
      </ExplanationWrapper>
      <DateControlModel clickFn={ChangeToday} onClickLeft={onClickPrevPage} onClickRight={onClickNextPage}/>
      <ScheduleSettingView ref={ScheduleSettingRef}/>
    </TopLevelWrapper>
  )
}

export default ScheduleTotalView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 34px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 24px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 4px;
  margin-left: 24px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;