import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import moment from 'moment';

import MemberShipHistoryInfoButtonImagePath from '../../../../image/MemberShipHistoryInfoButton.png';

const MemberShipHistoryInfoView = forwardRef((props, ref) => {
  const { data, index, modifyClick, holdingClick, extensionClick, deleteClick } = props;
  const [isClicked, setIsClicked] = useState(false);

  const OptionBoxRef = useRef();
  const OptionButtonRef = useRef();

  useImperativeHandle(ref, () => ({
    setIsClicked: () => {
      setIsClicked(false);
    }
  }))

  useEffect(() => {
    if (isClicked) {
      OptionBoxRef.current.style.height = `142px`;
      OptionBoxRef.current.style.border = `1px solid #FF8B48`;
    }
    else {
      OptionBoxRef.current.style.height = `0px`;
      OptionBoxRef.current.style.border = `0px solid #FF8B48`;
    }
  }, [isClicked])

  function mouseOver() {
    OptionButtonRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    OptionButtonRef.current.style.opacity = 0;
  }

  return (
    <TopLevelWrapper onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      <Table>
        <Body>
          <Tr style={{ width: '103px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '103px' }}>
                <Text>{data.memberShipName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '156px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '127px' }}>
                <Text>
                  {data.startDate.slice(2, 10).replaceAll('-', '.')} - {data.endDate.slice(2, 10).replaceAll('-', '.')}
                </Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '83px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '53px' }}>
                <Text>{data.totalMax === -1 ? '제한없음' : data.totalMax}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '84px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '17px' }}>
                <Text>{data.courseTakeCount}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '62px' }}>
                <Text>{data.totalPrice}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '62px' }}>
                <Text>{data.cardPrice}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '62px' }}>
                <Text>{data.cashPrice}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '62px' }}>
                <Text>{data.notReceivedPrice}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '93px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '93px' }}>
                <Text>{data.approvalDate.slice(2, 10).replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '67px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '67px' }}>
                <Text>{ moment(data.endDate).diff(moment(new Date()), 'days') > 0 ? moment(data.endDate).diff(moment(new Date()), 'days') + '일' : '만료' }</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <OptionButton ref={OptionButtonRef} src={MemberShipHistoryInfoButtonImagePath} onClick={() => setIsClicked(!isClicked)} />
              <OptionWrapper ref={OptionBoxRef}>
                <Option onClick={() => modifyClick(index)}>수정</Option>
                <Option onClick={() => holdingClick(index)}>홀딩</Option>
                <Option onClick={() => extensionClick(index)}>연장</Option>
                <Option onClick={() => deleteClick(index)}>삭제</Option>
              </OptionWrapper>
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
});

export default MemberShipHistoryInfoView;

const TopLevelWrapper = styled.div`
    display: flex;
    flex-direction: row;

    // justify-content: space-around;
    // justify-content: center;
    align-items: center;

    width: 1018px;
    height: 50px;

    background: #FFFFFF;

    box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
    border: 1px solid #FFFFFF;
    border-radius: 30px;
    
    transition: all 0.3s ease-in-out;

    :hover {
      cursor: pointer;
      border: 1px solid #FF8B48;
    }
  `;

const Table = styled.table`
    display: flex;
    flex-direction: row;
  `;

const Body = styled.tbody`
    display: flex;
    flex-direction: row;
    
    margin-left: 28px;
  `;

const Tr = styled.tr`
    display: flex;
    flex-direction: row;
  `;

const Td = styled.td`
    display: flex;
    flex-direction: row;
  `;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: flex-start;

    overflow: hidden;

    max-height: 20px;
    // border: 1px solid #232323;
  `;

const Text = styled.span`
    font-family: NotoSansKR-Regular;
    font-size: 14px;

    color: #595959;

    margin: 0 0 0 0;
    padding: 0 0 0 0;
  `;

const OptionButton = styled.img`
    opacity: 0;
    
    transition: all 0.3s ease-in-out;
  `;

const OptionWrapper = styled.div`
    position: absolute;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    overflow: hidden;

    margin-top: 40px;
    margin-left: -20px;

    border-radius: 8px;

    transition: all 0.2s ease-in-out;
  `;

const Option = styled.div`
    display: flex;
    flex-direction: column;;

    justify-content: center;
    align-items: center;

    width: 58px;
    height: 38px;

    margin: 0 0 0 0;

    font-family: NotoSansKR-Regular;
    font-size: 14px;

    color: #FF8B48;
    background-color: #FFFFFF;

    transition: all 0.3s ease-in-out;

    :hover {
      cursor: pointer;
      color: #FFFFFF;
      background-color: #FF8B48;
    }
  `;