import { React, useEffect, useLayoutEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import FetchModule from '../../Share/Network/FetchModule';
import ClassInfoBox from './ClassInfoBox';

import DefaultProfile from '../../../image/DefaultProfile.png';
import Image from '../../../Component/Share/Image';
import OpenEyesRingRing from '../../../image/OpenEyesRingRing.png';

export default function DuplicateMemberSelectModal(props) {
  const {
    duplicateMembers,
    lastPhone,
    onClickMyInfo,
    close
  } = props;

  // 모달창 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // 뒷페이지에서 처음으로 되돌아가기 //
  function onClickLogo() {
    window.location.reload(true);
  }

  return (
    <TopLevelWrapper>
    <PadViewWrapper>
      <RingRingWrapper>
        {/* <RingRing src={OpenEyesRingRing}/> */}
      </RingRingWrapper>
      <Modal>
        {/* <TitleWrapper onClick={onClickLogo}>
          <LogoImage src='https://picture.link-zone.org/ZoneLogo.png' />
          <TitleText>Link Zone</TitleText>
        </TitleWrapper> */}
        <ExplanationWrapper>
          <ExplanationText color='#FF8B48'>중복된 번호입니다</ExplanationText>
        </ExplanationWrapper>
        <ExplanationWrapper>
          <ExplanationText color='#FF8B48'>회원님의 정보를 선택해주세요</ExplanationText>
        </ExplanationWrapper>
        <InputWrapper>
          <PhoneText>{lastPhone}</PhoneText>
        </InputWrapper>
        <ClassTotalView>
          <ClassInnerView>
          {
            duplicateMembers.map((data, index) => (
              <MemberInfoWrapper key={index} onClick={() => onClickMyInfo(data)}>
                <ProfileWrapper>
                  {/* <Profile src={data.profile === '' ? DefaultProfile : data.profile} /> */}
                  <ProfileBox>
                    <Image src={data.profile} defaultSrc={DefaultProfile}/>
                  </ProfileBox>
                </ProfileWrapper>
                <TextInfoWrapper>
                  <InfoTotalWrapper>
                    <MemberNameWrapper>
                      <NameText fontFmaily='NotoSansKR-Bold' color='#000000'>{data.userName}</NameText>
                      <NameText fontFmaily='NotoSansKR-Regular' color='#777777'>회원</NameText>
                    </MemberNameWrapper>
                    <TextColumnBoxWrapper>
                      <TextColumnBox>
                        <NameText fontFmaily='NotoSansKR-Regular' color='#CBCBCB'>성별</NameText>
                      </TextColumnBox>
                      <TextColumnBox>
                        <NameText fontFmaily='NotoSansKR-Medium' color='#595959'>{data.gender === 'male' ? '남성' : '여성'}</NameText>
                      </TextColumnBox>
                    </TextColumnBoxWrapper>
                    <TextColumnBoxWrapper>
                      <TextColumnBox>
                        <NameText fontFmaily='NotoSansKR-Regular' color='#CBCBCB'>링크코치 ID</NameText>
                      </TextColumnBox>
                      <TextColumnBox>
                        <NameText fontFmaily='NotoSansKR-Medium' color='#595959'>{data.nickName}</NameText>
                      </TextColumnBox>
                    </TextColumnBoxWrapper>
                    <TextColumnBoxWrapper>
                      <TextColumnBox>
                        <NameText fontFmaily='NotoSansKR-Regular' color='#CBCBCB'>락커 번호</NameText>
                      </TextColumnBox>
                      <TextColumnBox>
                        <NameText fontFmaily='NotoSansKR-Medium' color='#595959'>{data.lockerName}</NameText>
                      </TextColumnBox>
                    </TextColumnBoxWrapper>
                  </InfoTotalWrapper>
                </TextInfoWrapper>
              </MemberInfoWrapper>
            ))
          }
          </ClassInnerView>
        </ClassTotalView>
        {/* <ButtonWrapper>
          <ExitButton onClick={close}>
            <ButtonText color="#FFFFFF">닫기</ButtonText>
          </ExitButton>
        </ButtonWrapper> */}
      </Modal>
      </PadViewWrapper>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 900;

  width: 100vw;
  height: 100vh;

  // background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;


const PadViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;
`;

const RingRingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 100px;

  // width: 336px;
  // height: 322px;
  width: 200px;
  height: 191px;
  
  z-index: 903;

  @media (min-height: 320px) {
    margin-right: 20px;

    width: 110px;
    height: 105px;
  }
  @media (min-height: 640px) {
    margin-right: 30px;

    width: 120px;
    height: 115px;
  }
  @media (min-height: 680px) {
    margin-right: 40px;

    width: 140px;
    height: 134px;
  }
  @media (min-height: 720px) {
    margin-right: 60px;

    width: 150px;
    height: 143px;
  }
  @media (min-height: 800px) {
    margin-right: 70px;

    width: 160px;
    height: 153px;
  }
  @media (min-height: 1000px) {
    margin-right: 80px;

    width: 180px;
    height: 172px;
  }
  @media (min-height: 1200px) {
    margin-right: 100px;

    width: 200px;
    height: 191px;
  }
`;

const RingRing = styled.img`
  position: absolute;

  margin-top: 7px;

  width: 200px;
  height: 191px;

  opacity: ${props => props.isView ? `1` : `0`};

  transition: ${props => props.isView ? `all 0.1s ease-in-out` : `all 0.3s ease-in-out`};

  :hover {
    cursor: pointer;
  }

  @media (min-height: 320px) {
    margin-top: 4px;

    width: 110px;
    height: 105px;
  }
  @media (min-height: 640px) {
    margin-top: 4px;

    width: 120px;
    height: 115px;
  }
  @media (min-height: 680px) {
    margin-top: 5px;

    width: 140px;
    height: 134px;
  }
  @media (min-height: 720px) {
    margin-top: 5px;

    width: 150px;
    height: 143px;
  }
  @media (min-height: 800px) {
    margin-top: 6px;

    width: 160px;
    height: 153px;
  }
  @media (min-height: 1000px) {
    margin-top: 7px;

    width: 180px;
    height: 172px;
  }
  @media (min-height: 1200px) {
    margin-top: 7px;

    width: 200px;
    height: 191px;
  }
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48vh;
  min-width: 300px;
  max-width: 800px;

  // height: 90vh;
  height: 80vh;
  min-height: 580px;
  max-height: 1480px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border-radius: 30px;
  background-color: #FFFFFF;

  overflow: hidden;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-left: -10px;

  gap: 10px;
`;

const LogoImage = styled.img`
  width: 25%;
  height: auto;
`;

const TitleText = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 28px;

  color: #FF8B48;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 28px;
  }
  @media (min-height: 640px) {
    font-size: 32px;
  }
  @media (min-height: 680px) {
    font-size: 34px;
  }
  @media (min-height: 720px) {
    font-size: 36px;
  }
  @media (min-height: 760px) {
    font-size: 38px;
  }
  @media (min-height: 800px) {
    font-size: 40px;
  }
  @media (min-height: 1000px) {
    font-size: 50px;
  }
  @media (min-height: 1200px) {
    font-size: 60px;
  }

  :hover {
    cursor: default;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 84.89%;
  width: 60%;

  // gap: 4px;
  gap: 8px;
  margin-top: 43px;
  padding: 12px 0px 12px 0px;

  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #FF8B48;

  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #FF8B48;

  @media (min-height: 320px) {
    gap: 4px;
  }
  @media (min-height: 640px) {
    gap: 6px;
  }
  @media (min-height: 720px) {
    gap: 8px;
  }
  @media (min-height: 760px) {
    gap: 10px;
  }
  @media (min-height: 800px) {
    gap: 12px;
  }
  @media (min-height: 880px) {
    gap: 14px;
  }
  @media (min-height: 940px) {
    gap: 16px;
  }
  @media (min-height: 1000px) {
    gap: 18px;
  }
  @media (min-height: 1200px) {
    gap: 20px;
  }
`;

const PhoneText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 17px;

  color: #595959;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 17px;
  }
  @media (min-height: 640px) {
    font-size: 19px;
  }
  @media (min-height: 720px) {
    font-size: 21px;
  }
  @media (min-height: 760px) {
    font-size: 22px;
  }
  @media (min-height: 800px) {
    font-size: 23px;
  }
  @media (min-height: 1000px) {
    font-size: 30px;
  }
  @media (min-height: 1200px) {
    font-size: 40px;
  }

  :hover {
    cursor: default;
  }
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const ExplanationText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 17px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 17px;
  }
  @media (min-height: 640px) {
    font-size: 19px;
  }
  @media (min-height: 720px) {
    font-size: 21px;
  }
  @media (min-height: 760px) {
    font-size: 22px;
  }
  @media (min-height: 800px) {
    font-size: 23px;
  }
  @media (min-height: 1000px) {
    font-size: 30px;
  }
  @media (min-height: 1200px) {
    font-size: 32px;
  }

  :hover {
    cursor: default;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 17px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 17px;
  }
  @media (min-height: 640px) {
    font-size: 19px;
  }
  @media (min-height: 720px) {
    font-size: 21px;
  }
  @media (min-height: 760px) {
    font-size: 22px;
  }
  @media (min-height: 800px) {
    font-size: 23px;
  }
  @media (min-height: 1000px) {
    font-size: 30px;
  }
  @media (min-height: 1200px) {
    font-size: 32px;
  }

  :hover {
    cursor: default;
  }
`;

const ClassTotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 43px;

  // width: 74.625%;
  // width: 90%;
  width: 100%;
  height: 55%;

  gap: 43px;

  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  transition: all 0.3s ease-in-out;
`;

const ClassInnerView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  height: 6vh;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30%;
  height: 100%;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 15px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 15px;
  }
  @media (min-height: 640px) {
    font-size: 17px;
  }
  @media (min-height: 720px) {
    font-size: 19px;
  }
  @media (min-height: 760px) {
    font-size: 21px;
  }
  @media (min-height: 800px) {
    font-size: 23px;
  }
  @media (min-height: 1000px) {
    font-size: 25px;
  }
  @media (min-height: 1200px) {
    font-size: 27px;
  }

  :hover {
    cursor: default;
  }
`;

const EmptyInfoBox = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: center;
	align-items: center;

  padding: 0px 8px 0px 8px;

  width: calc(100% - 26px);
  height: 68px;
  min-height: 68px;

	border: 1px solid #ECECEC;
	border-radius: 15px;
  
  background-color: #FFFFFF;
	
  transition: all 0.3s ease-in-out;
  
	:hover {
    cursor: pointer;
		border: 1px solid ${props => props.color};
	}	
`;

const EmptyText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 12px;
  }
  @media (min-height: 640px) {
    font-size: 14px;
  }
  @media (min-height: 720px) {
    font-size: 16px;
  }
  @media (min-height: 760px) {
    font-size: 18px;
  }
  @media (min-height: 800px) {
    font-size: 20px;
  }
  @media (min-height: 1000px) {
    font-size: 22px;
  }
  @media (min-height: 1200px) {
    font-size: 24px;
  }

  :hover {
    cursor: default;
  }
`;


const MemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 59px;

  // width: 87.25%;
  width: 100%;
  // height: 15.47%;
`;

const ProfileWrapper = styled.div`
  position: relative;
  
  width: 32.8%;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const Profile = styled.img`
  position: absolute;

  width: 100%;
  height: 100%;

  object-fit: cover;
  
  border-radius: 24px;
  // width: 32.8%;

  // border-radius: 24px;
`;

const ProfileBox = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;
  
  border-radius: 24px;

  overflow: hidden;
`;

const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // padding: 25px 0px 35px 0px;

  width: 61.74%;
  height: 100%;

  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border-radius: 12px;
`;

const InfoTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-start;

  width: 87%;
  height: 90%;

  // gap: 9px;
`;

const MemberNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const TextColumnBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%
`;

const TextColumnBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 47.2%;
`;

const NameText = styled.p`
  font-family: ${props => props.fontFmaily};
  font-size: 13px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 12px;
  }
  @media (min-height: 640px) {
    font-size: 13px;
  }
  @media (min-height: 720px) {
    font-size: 14px;
  }
  @media (min-height: 760px) {
    font-size: 15px;
  }
  @media (min-height: 800px) {
    font-size: 16px;
  }
  @media (min-height: 1000px) {
    font-size: 17px;
  }
  @media (min-height: 1200px) {
    font-size: 18px;
  }

  :hover {
    cursor: default;
  }
`;