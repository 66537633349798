import { forwardRef, React, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

import LinkManagerLogoPath from '../../../image/LinkManagerLogo.png';

const LogoView = forwardRef((props, ref) => {
	const { isOver } = props;
	const TopLevelRef = useRef();

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			if (val === 0) {
				TopLevelRef.current.style.opacity = val;
			}
			else {
				TopLevelRef.current.style.transform = val;
			}
		}
	}))

	return (
		<TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
			<ViewWrapper isOver={isOver}>
				<LogoWrapper>
					<Logo src={LinkManagerLogoPath} /> 
				</LogoWrapper>
				<TextWrapper>
					<MainName>Link Zone</MainName>
					<Explanation isOver={isOver}>쉽고 간편한 피트니스 회원 관리 프로그램</Explanation>
				</TextWrapper>
			</ViewWrapper>
		</TopLevelWrapper>
	)
});

export default LogoView;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;
	
	// width: 449px;
	width: ${props => props.isOver ? `max(449px, 58.46354166666667vw)` : `max(290px, 90.625vw)`};
	min-height: ${props => props.isOver ? `115px` : `92px`};

  margin-bottom: ${props => props.isOver ? `16px` : `3px`};

	border-radius: 30px;
	box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

	background-color: #FF8B48;
`;

const ViewWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: center;
	align-items: center;

	// gap: 16px;
	gap: ${props => props.isOver ? `16px` : `6px`};
`;

const LogoWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Logo = styled.img`
	width: 68px;
	height: 68px;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;
`;

const MainName = styled.p`
	font-family: Poppins-Regular;
	font-size: 28px;

	color: rgba(255, 255, 255, 1);
		
	margin: 0 0 0 0;

	cursor: default;
`;

const Explanation = styled.p`
	font-family: NotoSansKR-Medium;
	// font-size: 12px;
	font-size: ${props => props.isOver ? `12px` : `10px`};

	color: #FFFFFF;

	margin: ${props => props.isOver ? `4px 0 0 0` : `-6px 0 0 0`};
	opacity: 0.7;
		
	cursor: default;
`;
