import { React, useState, useEffect, forwardRef } from 'react';
import styled from 'styled-components';

import CheckImg from '../../image/checkall.png'
import CheckImgWhite from '../../image/checkwhite.png';

const BackgroundSetting = forwardRef((props, ref) => {
  const { changeFn } = props;

  const [clickColor, setClickColor] = useState('#F4A1A9');

  useEffect(() => {
    changeFn(clickColor);
  }, [clickColor, changeFn])

  return (
    <TopLevelWrapper>
      <Button isClicked={console.log(clickColor === '#F4A1A9')}
        img={clickColor === '#F4A1A9' ? CheckImgWhite : ``}
        borderColor='#F4A1A9'
        backgroundColor='#F4A1A9'
        onClick={() => setClickColor('#F4A1A9')} />
      <Button
        isClicked={clickColor === '#FF8B48'}
        img={clickColor === '#FF8B48' ? CheckImgWhite : ``}
        borderColor='#FF8B48'
        backgroundColor='#FF8B48'
        onClick={() => setClickColor('#FF8B48')} />
      <Button
        isClicked={clickColor === '#6DB5CB'}
        img={clickColor === '#6DB5CB' ? CheckImgWhite : ``}
        borderColor='#6DB5CB'
        backgroundColor='#6DB5CB'
        onClick={() => setClickColor('#6DB5CB')} />
      <Button
        isClicked={clickColor === '#9A9CE9'}
        img={clickColor === '#9A9CE9' ? CheckImgWhite : ``}
        borderColor='#9A9CE9'
        backgroundColor='#9A9CE9'
        onClick={() => setClickColor('#9A9CE9')} />
      <Button
        isClicked={clickColor === '#0BB68C'}
        img={clickColor === '#0BB68C' ? CheckImgWhite : ``}
        borderColor='#0BB68C'
        backgroundColor='#0BB68C'
        onClick={() => setClickColor('#0BB68C')} />
      <Button
        isClicked={clickColor === '#FFFFFF'}
        img={clickColor === '#FFFFFF' ? CheckImg : ``}
        borderColor='#E8E8E8'
        backgroundColor='#FFFFFF'
        onClick={() => setClickColor('#FFFFFF')} />
      <Button
        isClicked={clickColor === '#000000'}
        img={clickColor === '#000000' ? CheckImgWhite : ``}
        borderColor='#000000'
        backgroundColor='#000000'
        onClick={() => setClickColor('#000000')} />
    </TopLevelWrapper>
  )
})

export default BackgroundSetting;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  gap: 7px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid ${props => props.borderColor};
  border-radius: 8px;
  background: url(${props => props.img}) no-repeat center center;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;