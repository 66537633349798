import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import RightButton from '../../../image/PictureRightButton.png';
import LeftButton from '../../../image/PictureLeftButton.png';

function ExericseReview(props) {
  const { sectionList, sectionRecordData } = props;

  const [isClickedView, setIsClickedView] = useState(true);
  const [success, setSuccess] = useState('완료했어요!');
  const [lastMovement, setLastMovement] = useState('');
  const [image, setImage] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);


  // 띄울 데이터 정리 //
  useEffect(() => {
    console.log(sectionRecordData);

    // 운동 성공 여부 //
    if (sectionRecordData.success === 'Success') setSuccess('완료했어요!');
    else if (sectionRecordData.success === 'Not Success') setSuccess('완료하지 못했어요!');
    else setSuccess('진행하지 않았어요!');

    // 마지막 운동 몇 개 했는지 //
    for (var i = 0; i < sectionRecordData.exerciseList.length; i++) {
      if (sectionRecordData.exerciseList[i].exerciseId === sectionRecordData.lastExerciseId) {
        setLastMovement(sectionRecordData.lastRound + ' ' + sectionRecordData.exerciseList[i].movement.movementName)
        break;
      }
    }

    // 운동 사진 리스트 생성 //
    var newImageList = [];

    if (sectionRecordData.photos !== '') newImageList = sectionRecordData.photos.split('<-@->');
    setImage(newImageList);

  }, [sectionRecordData])

  // 사진 왼쪽으로 넘기기 버튼 클릭시 //
  function onClickPictureLeftButton() {
    if (imageIndex !== 0) setImageIndex(imageIndex - 1);
  }

  // 사진 오른쪽으로 넘기기 버튼 클릭시 //
  function onClickPictureRightButton() {
    if (imageIndex !== image.length - 1) setImageIndex(imageIndex + 1);
  }

  return (
    <TopLevelWrapper>
      <ViewButton onClick={() => setIsClickedView(!isClickedView)}>
        <HoverText fontFamily='NotoSansKR-Medium' fontSize={14} color='#6DD49E'>{isClickedView ? '동작 기록 접기' : '동작 기록 펼치기'}</HoverText>
      </ViewButton>
      {
        isClickedView &&
        <TotalWrapper>
          <ComponentWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작을 성공적으로 완료하셨나요?</Text>
            <ContentsBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{success}</Text>
            </ContentsBox>
          </ComponentWrapper>
          {
            (sectionList.tag === 'ForTime-Rounds' || sectionList.tag === 'ForTime-Different Reps') &&
            sectionRecordData.success === 'Success' &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>기록된 시간을 알려주세요</Text>
              <TimeBoxWrapper>
                <TimeBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>
                    {
                      parseInt(sectionRecordData.recordTime / 60)
                    }
                  </Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>MIN</Text>
                </TimeBox>
                <TimeBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>
                    {
                      parseInt(sectionRecordData.recordTime % 60)
                    }
                  </Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>SEC</Text>
                </TimeBox>
              </TimeBoxWrapper>
            </ComponentWrapper>
          }
          {
            (sectionList.tag === 'ForTime-Rounds' || sectionList.tag === 'ForTime-Different Reps' || sectionList.tag === 'AMRAP') &&
            sectionRecordData.success === 'Not Success' &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>몇 라운드를 완벽하게 해내셨나요?</Text>
              <RoundBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{sectionRecordData.completeRound}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>R</Text>
              </RoundBox>
            </ComponentWrapper>
          }
          {
            (sectionList.tag === 'ForTime-Rounds' || sectionList.tag === 'ForTime-Different Reps' || sectionList.tag === 'AMRAP') &&
            sectionRecordData.success === 'Not Success' &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>어떤운동 몇개에서 끝났나요?</Text>
              <ContentsBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{lastMovement}</Text>
              </ContentsBox>
            </ComponentWrapper>
          }
          {
            (sectionList.tag === 'TABATA' || sectionList.tag === 'EMOM') &&
            sectionRecordData.success !== 'Not Done' &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>라운드 별 성공 횟수</Text>
              <ContentsBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{sectionRecordData.roundOfCount}</Text>
              </ContentsBox>
            </ComponentWrapper>
          }
          {/* Custom */}
          {
            sectionList.tag === 'Custom' && sectionList.scoreKeyword === 'Time' && sectionRecordData.success === 'Success' &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>기록된 시간을 알려주세요</Text>
              <TimeBoxWrapper>
                <TimeBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>
                    {
                      parseInt(sectionRecordData.recordTime / 60)
                    }
                  </Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>MIN</Text>
                </TimeBox>
                <TimeBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>
                    {
                      parseInt(sectionRecordData.recordTime % 60)
                    }
                  </Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>SEC</Text>
                </TimeBox>
              </TimeBoxWrapper>
            </ComponentWrapper>
          }
          {
            sectionList.tag === 'Custom' && sectionList.scoreKeyword === 'Reps' && sectionRecordData.success === 'Success' &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>총 횟수</Text>
              <RoundBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{sectionRecordData.customKeywordValue}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>REPS</Text>
              </RoundBox>
            </ComponentWrapper>
          }
          {
            sectionList.tag === 'Custom' && sectionList.scoreKeyword === 'Rounds And Reps' && sectionRecordData.success === 'Success' &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>마지막 라운드는 어디서 끝났나요?</Text>
              <TimeBoxWrapper>
                <TimeBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>
                    {sectionRecordData.customLastRound}
                  </Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>R</Text>
                </TimeBox>
                <TimeBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>
                    {sectionRecordData.customLastReps}
                  </Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>REPS</Text>
                </TimeBox>
              </TimeBoxWrapper>
            </ComponentWrapper>
          }
          {
            sectionList.tag === 'Custom' && sectionList.scoreKeyword === 'Distance' && sectionRecordData.success === 'Success' &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>총 거리</Text>
              <RoundBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{sectionRecordData.customKeywordValue}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>{sectionRecordData.customKeywordUnit}</Text>
              </RoundBox>
            </ComponentWrapper>
          }
          {
            sectionList.tag === 'Custom' && sectionList.scoreKeyword === 'Weight' && sectionRecordData.success === 'Success' &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>총 무게</Text>
              <RoundBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{sectionRecordData.customKeywordValue}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>{sectionRecordData.customKeywordUnit}</Text>
              </RoundBox>
            </ComponentWrapper>
          }
          {
            sectionList.tag === 'Custom' && sectionList.scoreKeyword === 'Points' && sectionRecordData.success === 'Success' &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>포인트</Text>
              <RoundBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{sectionRecordData.customKeywordValue}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>{sectionRecordData.customKeywordUnit}</Text>
              </RoundBox>
            </ComponentWrapper>
          }
          {
            sectionList.tag === 'Custom' && sectionList.scoreKeyword === 'Peak Watts' && sectionRecordData.success === 'Success' &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>피크와트</Text>
              <RoundBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{sectionRecordData.customKeywordValue}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>{sectionRecordData.customKeywordUnit}</Text>
              </RoundBox>
            </ComponentWrapper>
          }
          {
            sectionList.tag === 'Custom' && sectionList.scoreKeyword === 'Max Speed' && sectionRecordData.success === 'Success' &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>최고 속도</Text>
              <RoundBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{sectionRecordData.customKeywordValue}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#DBDBDB'>{sectionRecordData.customKeywordUnit}</Text>
              </RoundBox>
            </ComponentWrapper>
          }
          {/* Custom */}
          {
            image.length !== 0 &&
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>운동 사진</Text>
              <ImageBoxWrapper>
                <ImageBox src={image[imageIndex]} />
                <PictureButtonWrapper>
                  {
                    imageIndex === 0 ?
                      <EmptyBox />
                      :
                      <PictureButton src={LeftButton} onClick={onClickPictureLeftButton} />
                  }
                  {
                    imageIndex === image.length - 1 ?
                      <EmptyBox />
                      :
                      <PictureButton src={RightButton} onClick={onClickPictureRightButton} />
                  }
                </PictureButtonWrapper>
              </ImageBoxWrapper>
            </ComponentWrapper>
          }
          <ComponentWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>운동 후 느낀점</Text>
            <ContentsBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{sectionRecordData.userExperience}</Text>
            </ContentsBox>
          </ComponentWrapper>
        </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

export default ExericseReview;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  aling-items: flex-start;

  margin-top: 12px;

  width: 100%;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  aling-items: flex-start;

  width: 100%;
`;

const ViewButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-bottom: 4px;

  width: calc(100% - 2px);
  height: 34px;

  border: 1px solid #6DD49E;
  border-radius: 12px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;

  gap: 8px;
`;

const ContentsBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 16px 8px 16px;

  width: calc(100% - 34px);
  min-height: 20px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
`;

const RoundBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 8px 16px 8px 16px;

  width: calc(100% - 34px);

  border: 1px solid #DFDFDF;
  border-radius: 15px;
`;

const TimeBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TimeBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 8px 16px 8px 16px;

  width: calc(48.39% - 34px);

  border: 1px solid #DFDFDF;
  border-radius: 15px;
`;

const ImageBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // overflow: hidden;
`;

const ImageBox = styled.img`
  width: 100%;
  // height: 514px;

  border-radius: 12px;
`;

const PictureButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // margin-top: -257px;

  width: calc(100% - 24px);
  height: 20px;
`;

const EmptyBox = styled.div`
  width: 23.1px;
  height: 23.1px;
`;

const PictureButton = styled.img`
  width: 23.1px;
  height: 23.1px;

  :hover {
    cursor: pointer;
  }
`;