import { React } from 'react';
import styled from 'styled-components';

import ScheduleTotalView from '../../Model/Individual/ScheduleModel/ScheduleTotalView';
import MakingView from '../../Model/Share/Normal/MakingView';

function SchedulePage() {

  return (
    <Container>
      {/* <ScheduleTotalView/> */}
      <MakingView/>
    </Container>
  );
}

export default SchedulePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

