import { React, useEffect, useState, forwardRef } from 'react';
import styled from 'styled-components';

const WeeklyColumn = forwardRef((props, ref) => {
  const { today } = props;
  const [dateList, setDateList] = useState([today]);
 
  useEffect(() => {
    var newDateList = [];

    for (var i = 0; i < 7; i++) {
      newDateList.push(today.clone().add(i, 'day'));
    }
    setDateList(newDateList);
  }, [today])

  return (
    <TopLevelWrapper>
        {
          dateList.map((data, index) => (
            <ColumnWrapper key={index}>
              <Column>{data.format('DD일 (dd)')}</Column>
            </ColumnWrapper>
          ))
        }
    </TopLevelWrapper>
  )
})

export default WeeklyColumn;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 911px;
  
  gap: 6px;
  margin-left: 52px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 125px;
`;

const Column = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;
`;