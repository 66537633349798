import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import useWindowSize from './useWindowSize';

import FetchModule from '../Network/FetchModule';

function BottomInfo() {
  const history = useHistory();
  const nowPage = window.location.href;
  // const defaultURL = 'http://localhost:3000';
  // const defaultURL = 'http://172.30.1.8:3001';
  const defaultURL = 'https://m.link-zone.io';

  const size = useWindowSize();

  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    if (size.width >= 768) setIsOver(true);
    else setIsOver(false);
  }, [size])
  
  // if (nowPage === defaultURL + '/'
  // || nowPage.includes(defaultURL + '/certification')
  // || nowPage.includes(defaultURL + '/identifier')
  // || nowPage.includes(defaultURL + '/permute')
  // // || nowPage.includes(defaultURL + '/rule/')
  // || nowPage === defaultURL + '/full/board/'
  // || nowPage.includes(defaultURL + '/complete')
  // || nowPage.includes(defaultURL + '/payment')
  // || nowPage.includes(defaultURL + '/ruler')
  // || nowPage.includes(defaultURL + '/keypad')) return null;

  if (nowPage === defaultURL + '/'
  || nowPage.includes('/certification')
  || nowPage.includes('/identifier')
  || nowPage.includes('/permute')
  // || nowPage.includes(defaultURL + '/rule/')
  || nowPage === defaultURL + '/full/board/'
  || nowPage.includes('/complete')
  || nowPage.includes('/payment')
  || nowPage.includes('/ruler')
  || nowPage.includes('/keypad')
  || nowPage.includes('/view')
  || nowPage.includes('/user/registration_card')
  || nowPage.includes('/complete/registration_card')
  || nowPage.includes('/user/registration_billing')
  || nowPage.includes('/complete/registration_billing')) return null;


  // 로그인 정보 변경 버튼 클릭시 //
  function onClickChangeAuthorizationButton() {
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     var responseData = await fetchModule.postData('account/asp/login/change', 'POST');
        
    //     if (responseData.status === 200) {
    //       console.log(responseData);
		// 			window.localStorage.setItem('Token', 'Bearer ' + responseData.token);
		// 			window.localStorage.setItem('RefreshToken', 'Bearer ' + responseData.refreshToken);
		// 			window.localStorage.setItem('StoreId', responseData.storeId);
		// 			// window.localStorage.setItem('AccountId', userId);
		// 			// window.sessionStorage.setItem('Token', "Bearer " + responseData.token);
		// 			// window.sessionStorage.setItem('StoreId', responseData.storeId);
		// 			// window.sessionStorage.setItem('AccountId', userId);
		// 			history.push('/main');
    //     }
    //     // else if (responseData.status === 401) history.push('/');
    //   }
    // )();
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      <InfoWrapper>
        <ButtonWrapper isOver={isOver}>
          <Button isOver={isOver} onClick={() => history.push('/rule/service')}>이용약관</Button>
          <Button isOver={isOver} onClick={() => history.push('/rule/location')}>위치정보</Button>
          <Button isOver={isOver} onClick={() => history.push('/rule/privacy')}>개인정보</Button>
          <Button isOver={isOver} onClick={() => history.push('/rule/promotion')}>프로모션</Button>
          <Button isOver={isOver} onClick={() => history.push('/rule/refund')}>환불규정</Button>
        </ButtonWrapper>
        {
          isOver ?
            <LayerWrapper>
              <TextWrapper>
                <Text>회사명 : 링크 소프트(Link Soft)</Text>
                <Text>대표 : 이은표</Text>
                <Text>사업자등록번호 : 603-18-51389</Text>
                <Text onClick={onClickChangeAuthorizationButton}>개인정보책임자 : 안형진</Text>
                <Text>통신판매업신고 : 제 2022-충북충주-0029 호</Text>
              </TextWrapper>
              <TextWrapper>
                <Text>EMAIL : help@link-soft.io</Text>
                <Text>TEL : 070-8028-3943</Text>
                <Text>주소 : 충청북도 충주시 연수동산로 38 ( 203동 1803호 )</Text>
              </TextWrapper>
            </LayerWrapper>
            :
            <LayerWrapper>
              <TextWrapper>
                <Text>회사명 : 링크 소프트(Link Soft)</Text>
                <Text>대표 : 이은표</Text>
              </TextWrapper>
              <TextWrapper>
                <Text>사업자등록번호 : 603-18-51389</Text>
                <Text onClick={onClickChangeAuthorizationButton}>개인정보책임자 : 안형진</Text>
              </TextWrapper>
              <TextWrapper>
                <Text>EMAIL : help@link-soft.io</Text>
                <Text>TEL : 070-8028-3943</Text>
              </TextWrapper>
              <TextWrapper>
                <Text>주소 : 충청북도 충주시 연수동산로 38 ( 203동 1803호 )</Text>
                <Text>통신판매업신고 : 제 2022-충북충주-0029 호</Text>
              </TextWrapper>
            </LayerWrapper>
        }
        <ExplanationWrapper>
          <Explanation>ⓒ 2021. Link Manager Co. all rights reserved.</Explanation>
        </ExplanationWrapper>
      </InfoWrapper>
    </TopLevelWrapper>
  )
}

export default BottomInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  // height: 296px;
  height: ${props => props.isOver ? `223px` : `185px`};

  background-color: #FFFFFF;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${props => props.isOver ? `44px` : `12px`};

  margin-bottom: ${props => props.isOver ? `40px` : `16px`};
`;

const Button = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 5px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;

  gap: ${props => props.isOver ? `24px` : `16px`};

  // margin-bottom: 16px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #969696;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const ExplanationWrapper = styled.div`
  display: flex;

  margin-top: ${props => props.isOver ? `20px` : `18px`};
`;

const Explanation = styled.p`
  font-family: Poppins-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;