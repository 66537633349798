import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

const MemberShipColumn = () => {

  return (
    <TopLevelWrapper>
      <Column style={{marginRight: '65px', marginLeft: '28px'}}>회원권</Column>
      <Column style={{marginRight: '132px'}}>기간</Column>
      <Column style={{marginRight: '28px'}}>최대 수강</Column>
      <Column style={{marginRight: '28px'}}>수강 횟수</Column>
      <Column style={{marginRight: '39px'}}>결제금액</Column>
      <Column style={{marginRight: '65px'}}>카드</Column>
      <Column style={{marginRight: '65px'}}>현금</Column>
      <Column style={{marginRight: '52px'}}>미수금</Column>
      <Column style={{marginRight: '49px'}}>결제일</Column>
      <Column>남은 일수</Column>
      
    </TopLevelWrapper>
  )
}

export default MemberShipColumn;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  
  // justify-content: center;
  align-items: center;

  width: 1020px;
  margin-top: 34px;
  margin-bottom: 18px;
  
  transition: all 0.3s ease-in-out;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;
