import { React } from 'react';
import styled from 'styled-components';

import PrevButtonImage from '../../../image/NormalPrevInfoButton.png';
import ColorNextButtonImage from '../../../image/ColorNextInfoButton.png';

//NomalPrevInfoButton.png

const FirstValidColumnView = (props) => {
  const { isOver, nextFn } = props;

  return (
    <TopLevelWrapper isOver={isOver}>
      {
        isOver ?
          <ColumnWrapper>
            <Column style={{ marginRight: 'max(40px, 5.208333333333333vw)', marginLeft: 'max(15px, 1.953125vw)' }} isOver={isOver}>이름</Column>
            <Column style={{ marginRight: 'max(29px, 3.776041666666667vw)' }} isOver={isOver}>성별</Column>
            <Column style={{ marginRight: 'max(46px, 5.989583333333333vw)' }} isOver={isOver}>생년월일</Column>
            <Column style={{ marginRight: 'max(49px, 6.380208333333333vw)' }} isOver={isOver}>핸드폰번호</Column>
            <Column isOver={isOver}>회원권</Column>
          </ColumnWrapper>
          :
          <ColumnWrapper>
            <Column style={{ marginRight: 'max(28px, 8.75vw)', marginLeft: 'max(5px, 1.5625vw)' }} isOver={isOver}>이름</Column>
            <Column style={{ marginRight: 'max(27px, 8.4375vw)' }} isOver={isOver}>성별</Column>
            <Column style={{ marginRight: 'max(26px, 8.125vw)' }} isOver={isOver}>생년월일</Column>
            <Column isOver={isOver}>휴대폰</Column>
          </ColumnWrapper>
      }
      {
        isOver ?
          <ButtonWrapper isOver={isOver}>
            <Button src={PrevButtonImage} isOver={isOver}/>
            <Column isOver={isOver}>다음 정보</Column>
            <Button src={ColorNextButtonImage} isOver={isOver} onClick={nextFn} />
          </ButtonWrapper>
          :
          <ButtonWrapper isOver={isOver}>
            <Button src={PrevButtonImage} />
            <Button src={ColorNextButtonImage} onClick={nextFn} />
          </ButtonWrapper>
      }
    </TopLevelWrapper>
  )
}

export default FirstValidColumnView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: max(636px, 82.8125vw);
  width: ${props => props.isOver ? `max(664px, 86.45833333333333vw)` : `max(268px, 83.75vw)`};
  
  margin-top: ${props => props.isOver ? `38px` : `9px`};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `4px` : `6px`};
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Button = styled.img`
  width: ${props => props.isOver ? `24px` : `15px`};
  height: ${props => props.isOver ? `24px` : `15px`};

  border-radius: ${props => props.isOver ? `24px` : `15px`};

  :hover {
    cursor: pointer;
  }
`;