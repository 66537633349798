import { React, useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CoachSettingInfoBox from './CoachSettingInfoBox';
import CoachAddModal from './Modal/CoachAddModal';

import FetchModule from '../../Share/Network/FetchModule';

const CoachSettingView = forwardRef((props, ref) => {
  const { isOver } = props;
  const history = useHistory();

  const [isClicked, setIsClicked] = useState(false);
  const [data, setData] = useState([]);
  const [clickIndex, setClickIndex] = useState(-1);
  const [rendering, setRendering] = useState(false);

  const TopLevelRef = useRef();

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
    setZindex: (val) => {
      TopLevelRef.current.style.zIndex = val;
    },
    setIsClicked: (val) => {
      setIsClicked(val);
    }
	}))

  // 렌더링시 데이터 호출하는 API
  useEffect(() => {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.getData('coach/', 'GET');
        
				if (responseData.status === 200) setData(responseData.data);
				else if (responseData.status === 401) {
          alert('로그인후 이용해주세요.');
          history.push('/');
        }
        else {}
			}
		)();
  }, [history, rendering])
  
  useEffect(() => {
    if (!isClicked) setClickIndex(-1);
  }, [isClicked])

  function modifyClick(index) {
    setClickIndex(index);
    setIsClicked(true);
  }

  function deleteClick(index) {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.deleteData('coach/delete/' + data[index].id, 'DELETE');
        
				if (responseData.status === 200) setRendering(!rendering);
        else if (responseData.status === 203) {
          alert("코치님이 진행하시는 수업이 존재합니다.\n수업 삭제 혹은 코치 변경 후 다시 시도해주세요");
        }
				else if (responseData.status === 401) {
          alert("로그인후 사용해주세요.");
          history.push('/');
        }
        else {}
			}
		)();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
      { isClicked ? <CoachAddModal isOver={isOver} data={data} index={clickIndex} exitClickFn={() => setIsClicked(false)} rendering={() => setRendering(!rendering)}/> : null }
      {
        data.map((data, index) => (
          <CoachSettingInfoBox key={index} isOver={isOver} data={data} index={index} modifyClickFn={modifyClick} deleteClickFn={deleteClick}/>
        ))
      }
      {/* <CoachSettingInfoBox/>
      <CoachSettingInfoBox/>
      <CoachSettingInfoBox/>
      <CoachSettingInfoBox/>
      <CoachSettingInfoBox/> */}
    </TopLevelWrapper>
  )
})

export default CoachSettingView;

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  // flex-wrap: wrap;

  // width: 100%;
  
  gap: ${props => props.isOver ? `30px` : `12px`};

  // opacity: 0;
  // z-index: -3000;

  // margin: ${props => props.isOver ? `-910px 0 96px 0` : `-1220px 0 96px 0`};
  margin: 17px 0 96px 0;
  
  // margin-left: 34px;

  transition: all 0.3s ease-in-out;
`;