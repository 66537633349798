import { React } from 'react';
import styled from 'styled-components';

import SettingView from '../Model/Individual/SettingModel/SettingView.js';

function SettingPage() {

  return (
    <Container>
      <SettingView/>
    </Container>
  )
}

export default SettingPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;