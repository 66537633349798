import { React, forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Model/Share/Network/FetchModule';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

const ClassSelectBox = forwardRef((props, ref) => {
	const { isOver, date, setScheduleId, rendering, checkAvailableChangeScheduleData } = props;
  const history = useHistory();

	const [isClicked, setIsClicked] = useState(false);
	const [scheduleData, setScheduleData] = useState([]);
  const [options, setOptions] = useState([]);
	const [categoryId, setCategoryId] = useState('');
	const [defaultVal, setDefaultVal] = useState({ name: '' });

	const SelectRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setCategoryId(val);
		},
		getValue: () => {
			return defaultVal;
		}
	}))

	// Schedule Data API호출
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('schedule/', 'GET');
         
        if (responseData.status === 200) setScheduleData(responseData.data);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history])

	// Schedule Data를 사용 가능한 Data로 가공
  useEffect(() => {
    var tmp = scheduleData.slice();
    var newScheduleData = [];

    for (var i = 0; i < tmp.length; i++) {
        var endPoint = moment(tmp[i].classDate.split('T')[0]);

        while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {
          if (endPoint.diff(date, 'days') >= 30) break;

          // if (tmp[i].applyWeek.includes(endPoint.format('ddd')) || tmp[i].applyWeek === 'All') {
            var colSpan = 1;
            var rowSpan = 1;

            // 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
            colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);

            var newData = {
              classId: tmp[i].classId,
              classDate: endPoint.format('YYYY.MM.DD'),
              className: tmp[i].className,
              classTime: tmp[i].classTime,
              color: tmp[i].color,
              endDate: tmp[i].endDate,
              lessonId: tmp[i].lessonId,
              scheduleId: tmp[i].scheduleId,
              teacherName: tmp[i].teacherName,
              applyWeek: tmp[i].applyWeek,
              rowSpan: rowSpan,
              colSpan: colSpan,
              maxBookedPeople: tmp[i].maxBookedPeople,
              deduction: tmp[i].deduction
            }
            newScheduleData.push(newData);
          // }
          endPoint = endPoint.clone().add(1, 'day');
        }
    }

    newScheduleData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    var newData = [];

    for (i = 0; i < newScheduleData.length; i++) {

      if (newScheduleData[i].classDate === date.clone().format('YYYY.MM.DD')) {
        newData.push(newScheduleData[i]);
      }
    }

		newData.sort(function (a, b) {
      return a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0;
    })

		if (newData.length !== 0) setScheduleId(newData[0].scheduleId);
		setOptions(newData);
  }, [scheduleData, date])
  

  useEffect(() => {
    if (options.length === 0) return;

		if (options.findIndex(i => i.id === categoryId) === -1 ) setDefaultVal(options[0]);
		else setDefaultVal(options[options.findIndex(i => i.id === categoryId)]);
  }, [options, categoryId])

	function onClick() {
		if (isClicked) {
			SelectRef.current.style.border = '1px solid #CBCBCB';
			if (isOver) SelectRef.current.style.height = `38px`;
			else SelectRef.current.style.height = `29px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			// SelectRef.current.style.height = `240px`;
			SelectRef.current.style.height = (32 * (options.length + 1 )) > 500 ? `500px` : ((32 * (options.length + 1)) + 'px');
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	// function optionalClick(index) {
	// 	setDefaultVal(options[index]);
	// 	rendering();
	// }

	// 수업 시간대 변경 클릭시 //
	function optionalClick(index) {
    if (checkAvailableChangeScheduleData()) {
      setDefaultVal(options[index]);
      setScheduleId(options[index].scheduleId);
      rendering();
    }
    else {
      alert("디자인된 운동을 저장 후 실행해 주세요!");
    }
	}

	return (
		<TopLevelWrapper isOver={isOver}>
			<SelectBox
				ref={SelectRef}
				isOver={isOver}
				isClicked={isClicked}
				buttonPath={isClicked ? SelectOnPath : SelectOffPath}
				onClick={onClick}>
				{
					isClicked ?
						options.map((data, index) =>
							<OptionWrapper key={index} onClick={() => optionalClick(index)}>
								<Option isOver={isOver} isClicked={isClicked}>{data.className} {data.classTime}</Option>
							</OptionWrapper>
						)
						:
						<OptionWrapper>
							{/* <Option isOver={isOver}> */}
								<Option isOver={isOver} isClicked={isClicked}>{options.length === 0 ? '일정 없음' : defaultVal.className + ` (` + defaultVal.classTime + `)`}</Option>
							{/* </Option> */}
						</OptionWrapper>
				}
			</SelectBox>
		</TopLevelWrapper>
	)
})

export default ClassSelectBox;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
  
	// width: ${props => props.isOver ? `145px` : `120px`};
	// width: ${props => props.isOver ? `192px` : `162px`};
  width: ${props => props.isOver ? `max(319px, 41.53645833333333vw)` : `max(296px, 92.5vw)`};
	height: ${props => props.isOver ? `40px` : `31px`};
`;

const SelectBox = styled.div`
	position: absolute;
	
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: flex-start;

	// width: ${props => props.isOver ? `143px` : `118px`};
	// width: ${props => props.isOver ? `190px` : `160px`};
  width: ${props => props.isOver ? `max(319px, 41.53645833333333vw)` : `max(296px, 92.5vw)`};
	height: ${props => props.isOver ? `38px` : `29px`};

  z-index: 3;

	border: 1px solid #CBCBCB;
	border-radius: ${props => props.isOver ? `15px` : `9px`};
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

	width: 100%;

	overflow: hidden;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

	font-family: NotoSansKR-Medium;
	font-size: ${props => props.isOver ? `14px` : `12px`};

	margin: ${props => props.isOver ? `8px 0 8px 16px` : `5px 0 5px 8px`};

	width: ${props => props.isOver ? `calc(96% - 16px)` : `calc(96% - 8px)`};

	overflow: hidden;

  transition: all 0.3s ease-in-out;

	:hover {
		color: #FF8B48;
	}
`;