import { React } from 'react';
import styled from 'styled-components';

const DietInfo = (props) => {
  const { isOver } = props;

  return (
    <TopLevelWrapper isOver={isOver}>

    </TopLevelWrapper>
  )
}

export default DietInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(299px, 93.4375vw)`};
  height: ${props => props.isOver ? `268px` : `313px`};

  border-radius: ${props => props.isOver ? `30px` : `17px`};
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;