import { React, useEffect, useState } from 'react';
import styled from 'styled-components';
import MyMemberPaymentColumn from './MyMemberPaymentColumn';
import MyMemberPaymentColumnFirst from './MyMemberPaymentColumnFirst';
import MyMemberPaymentColumnSecond from './MyMemberPaymentColumnSecond';
import MyMemberPaymentInfo from './MyMemberPaymentInfo';
import MyMemberPaymentInfoFirst from './MyMemberPaymentInfoFirst';
import MyMemberPaymentInfoSecond from './MyMemberPaymentInfoSecond';

import SortingModule from '../../Share/Normal/SortingModule';

import NormalSortButtonPath from '../../../image/NormalSortButton.png';
import AscSortButtonPath from '../../../image/AscSortButton.png';
import DescSortButtonPath from '../../../image/DescSortButton.png';

function MyZoneMemberPaymentView(props) {
  const { salesList, onClickRefundButton } = props;

  const [isFirst, setIsFirst] = useState(true);

  const [dataList, setDataList] = useState(new Array);
  const [pageMax, setPageMax] = useState(1);
  const [nowPage, setNowPage] = useState(0);

  const [sortingData, setSortingData] = useState(new Array);

  const [approvalButtonImg, setApprovalButtonImg] = useState(AscSortButtonPath);
  const [approvalDateButtonImg, setApprovalDateButtonImg] = useState(AscSortButtonPath);

  const [approvalSort, setApprovalSort] = useState('normal');
  const [approvalDateSort, setApprovalDateSort] = useState('normal');
  

  useEffect(() => {
    if (approvalSort === 'normal') setApprovalButtonImg(NormalSortButtonPath);
    else if (approvalSort === 'asc') setApprovalButtonImg(AscSortButtonPath);
    else if (approvalSort === 'desc') setApprovalButtonImg(DescSortButtonPath);
  }, [approvalSort])
  
  useEffect(() => {
    if (approvalDateSort === 'normal') setApprovalDateButtonImg(NormalSortButtonPath);
    else if (approvalDateSort === 'asc') setApprovalDateButtonImg(AscSortButtonPath);
    else if (approvalDateSort === 'desc') setApprovalDateButtonImg(DescSortButtonPath);
  }, [approvalDateSort])

  // 회원 정보 소팅
  useEffect(() => {
    var newData = salesList.slice();
    
    if (approvalSort === 'asc') newData = SortingModule.dataSort(newData, 'status', 'asc', 'status');
    else if (approvalSort === 'desc') newData = SortingModule.dataSort(newData, 'status', 'desc', 'status');
    else if (approvalDateSort === 'asc') newData = SortingModule.dataSort(newData, 'approvalDate', 'asc', 'approvalDate');
    else if (approvalDateSort === 'desc') newData = SortingModule.dataSort(newData, 'approvalDate', 'desc', 'approvalDate');
    
    setSortingData(newData);
  }, [salesList, approvalSort, approvalDateSort])

  useEffect(() => {
    setPageMax(Math.ceil(sortingData.length / 10) === 0 ? 1 : Math.ceil(sortingData.length / 10));

    setDataList(sortingData.slice(nowPage * 10, (nowPage * 10) + 9));
  }, [sortingData, nowPage])

  function onClickPrevButton() {
    if (nowPage !== 0) setNowPage(nowPage - 1);
  }

  function onClickNextButton() {
    if (nowPage + 1 !== pageMax) {
      setNowPage(nowPage + 1);
    }
  }

  // 컬럼 정렬버튼 클릭시
  function sortButtonFn(division, sort) {
    if (division === 'status') {
      setApprovalSort(sort);
      setApprovalDateSort('normal');
    }
    else if (division === 'approvalDate') {
      setApprovalSort('normal');
      setApprovalDateSort(sort);
    }
  }

  function onClickApprovalSort() {
    setApprovalDateSort('normal');

    if (approvalSort === 'normal') {
      setApprovalSort('asc');
      sortButtonFn('status', 'asc');
    }
    else if (approvalSort === 'asc') {
      setApprovalSort('desc');
      sortButtonFn('status', 'desc');
    }
    else if (approvalSort === 'desc') {
      setApprovalSort('normal');
      sortButtonFn('status', 'normal');
    }
  }

  function onClickApprovalDateSort() {
    setApprovalSort('normal');

    if (approvalDateSort === 'normal') {
      setApprovalDateSort('asc');
      sortButtonFn('approvalDate', 'asc');
    }
    else if (approvalDateSort === 'asc') {
      setApprovalDateSort('desc');
      sortButtonFn('approvalDate', 'desc');
    }
    else if (approvalDateSort === 'desc') {
      setApprovalDateSort('normal');
      sortButtonFn('approvalDate', 'normal');
    }
  }

  return (
    <TopLevelWrapper>
      <LayerWrapper>
        <TextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>회원 이용권 결제정보</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777'>링크존을 이용한 회원들의 결제 정보를 확인할 수 있어요</Text>
        </TextWrapper>
        {
          isFirst ?
            <MyMemberPaymentColumnFirst approvalDateButtonImg={approvalDateButtonImg} onClickApprovalDateSort={onClickApprovalDateSort} nextFn={() => setIsFirst(false)}/>
            :
            <MyMemberPaymentColumnSecond approvalButtonImg={approvalButtonImg} onClickApprovalSort={onClickApprovalSort} prevFn={() => setIsFirst(true)}/>
        }
        {
          isFirst ?
            dataList.map((data, index) => (
              <MyMemberPaymentInfoFirst key={index} data={data} onClickRefundButton={onClickRefundButton}/> 
            ))
            :
            dataList.map((data, index) => (
              <MyMemberPaymentInfoSecond key={index} data={data} onClickRefundButton={onClickRefundButton}/> 
            ))
        }
        <Pagination>
          <Button isFinished={nowPage + 1 === 1} onClick={onClickPrevButton}>이전</Button>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323'>{nowPage + 1}/{pageMax}</Text>
          <Button isFinished={nowPage + 1 === pageMax} onClick={onClickNextButton}>다음</Button>
        </Pagination>
      </LayerWrapper>
    </TopLevelWrapper>
  )
}

export default MyZoneMemberPaymentView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 51px;
  margin-bottom: 242px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-start;
  
  // width: 1191px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-start;
  
  margin-left: 28px;
  
  gap: 4px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;
  
  margin: 0 0 0 0;
  
  color: ${props => props.color};
  
  :hover {
    cursor: default;
  }
`;

const Pagination = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 44px;
  gap: 12px;

  width: 100%;
`;

const Button = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  color: ${props => props.isFinished ? `#A7A7A7` : `#232323`};

  :hover {
    cursor: pointer;
  }
`;