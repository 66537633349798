import { React, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import RingRingHands from '../../../image/RingRingHands.png';
import RingRingHand from '../../../image/RingRingHand.png';
import PoweredBy from '../../../image/PoweredBy.png';

export default function WeekAttendanceStatusView(props) {
  const { dayOfTheWeek, attendanceData } = props;

  // 모달창 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // 글자 수 세기 //
  function decideStoreNameText() {
    let name = attendanceData.storeName;

    if (name.length <= 9) return name;
    else return name.slice(0, 9) + '...';
  }

  // 요일별 화이팅 문구 변경 //
  function decideFighttingText() {
    if (dayOfTheWeek === 'Mon') return '시작이 좋아 월요팅!!';
    else if (dayOfTheWeek === 'Tue') return '화요일도 출석 응원해!';
    else if (dayOfTheWeek === 'Wed') return '벌써 수요일 오늘도 화이팅!';
    else if (dayOfTheWeek === 'Thu') return '목요일 이제 거의 다 왔어!';
    else if (dayOfTheWeek === 'Fri') return '금요일 이번 한주도 수고했어!!!';
    else if (dayOfTheWeek === 'Sat') return '토요일까지 운동!';
    else if (dayOfTheWeek === 'Sun') return '일요일도 운동은 해야지!!';
  }

  return (
    <TopLevelWrapper>
      <PadViewWrapper>
        <RingRingWrapper>
        </RingRingWrapper>
        <Modal>
          {/* <RingRingHandImageWrapper isFriday={dayOfTheWeek === 'Fri'}>
            <RingRingHandImage src={dayOfTheWeek === 'Fri' ? RingRingHand : RingRingHands}/>
          </RingRingHandImageWrapper> */}
          <ExplanationWrapper>
            <ExplanationText color='#595959'>{decideStoreNameText()}</ExplanationText>
          </ExplanationWrapper>
          <ExplanationWrapper>
            <EmphasizeExplanationText color='#595959'>{attendanceData.totalAttendance.toLocaleString('ko-KR')}번 출석중</EmphasizeExplanationText>
          </ExplanationWrapper>
          <ExplanationWrapper style={{ marginTop: `26px` }}>
            <FighttingText color='#FF8B48'>{decideFighttingText()}</FighttingText>
          </ExplanationWrapper>
          <ClassTotalView>
            <KeypadLayerWrapper>
              <NumberKey isAttendance={attendanceData.mon}>
                <NumText>월</NumText>
              </NumberKey>
              <NumberKey isAttendance={attendanceData.tue}>
                <NumText>화</NumText>
              </NumberKey>
              <NumberKey isAttendance={attendanceData.wed}>
                <NumText>수</NumText>
              </NumberKey>
            </KeypadLayerWrapper>
            <KeypadLayerWrapper>
              <NumberKey isAttendance={attendanceData.thu}>
                <NumText>목</NumText>
              </NumberKey>
              <NumberKey isAttendance={attendanceData.fri}>
                <NumText>금</NumText>
              </NumberKey>
              <NumberKey isAttendance={attendanceData.sat}>
                <NumText>토</NumText>
              </NumberKey>
            </KeypadLayerWrapper>
            <KeypadLayerWrapper>
              <NumberKey isAttendance={attendanceData.sun}>
                <NumText>일</NumText>
              </NumberKey>
            </KeypadLayerWrapper>
          </ClassTotalView>
          <FiveSecondsTextWrapper>
            <FiveSecondsText>5초뒤 메인화면으로 이동합니다</FiveSecondsText>
          </FiveSecondsTextWrapper>
          <PoweredByWrapper>
            <PoweredByImage src={PoweredBy}/>
          </PoweredByWrapper>
        </Modal>
      </PadViewWrapper>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 900;

  width: 100vw;
  height: 100vh;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;


const PadViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
`;

const RingRingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 100px;

  // width: 336px;
  // height: 322px;
  width: 200px;
  height: 191px;

  @media (min-height: 320px) {
    margin-right: 20px;

    width: 110px;
    height: 105px;
  }
  @media (min-height: 640px) {
    margin-right: 30px;

    width: 120px;
    height: 115px;
  }
  @media (min-height: 680px) {
    margin-right: 40px;

    width: 140px;
    height: 134px;
  }
  @media (min-height: 720px) {
    margin-right: 60px;

    width: 150px;
    height: 143px;
  }
  @media (min-height: 800px) {
    margin-right: 70px;

    width: 160px;
    height: 153px;
  }
  @media (min-height: 1000px) {
    margin-right: 80px;

    width: 180px;
    height: 172px;
  }
  @media (min-height: 1200px) {
    margin-right: 100px;

    width: 200px;
    height: 191px;
  }
`;

const Modal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48vh;
  min-width: 300px;
  max-width: 800px;

  // height: 90vh;
  height: 80vh;
  min-height: 580px;
  max-height: 1480px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border-radius: 30px;
  background-color: #FFFFFF;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const ExplanationText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 18px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 18px;
  }
  @media (min-height: 640px) {
    font-size: 20px;
  }
  @media (min-height: 720px) {
    font-size: 22px;
  }
  @media (min-height: 760px) {
    font-size: 24px;
  }
  @media (min-height: 800px) {
    font-size: 26px;
  }
  @media (min-height: 1000px) {
    font-size: 28px;
  }
  @media (min-height: 1200px) {
    font-size: 30px;
  }

  :hover {
    cursor: default;
  }
`;

const EmphasizeExplanationText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 18px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 18px;
  }
  @media (min-height: 640px) {
    font-size: 20px;
  }
  @media (min-height: 720px) {
    font-size: 22px;
  }
  @media (min-height: 760px) {
    font-size: 24px;
  }
  @media (min-height: 800px) {
    font-size: 26px;
  }
  @media (min-height: 1000px) {
    font-size: 28px;
  }
  @media (min-height: 1200px) {
    font-size: 30px;
  }

  :hover {
    cursor: default;
  }
`;

const FighttingText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 16px;
  }
  @media (min-height: 640px) {
    font-size: 18px;
  }
  @media (min-height: 720px) {
    font-size: 20px;
  }
  @media (min-height: 760px) {
    font-size: 22px;
  }
  @media (min-height: 800px) {
    font-size: 24px;
  }
  @media (min-height: 1000px) {
    font-size: 26px;
  }
  @media (min-height: 1200px) {
    font-size: 28px;
  }

  :hover {
    cursor: default;
  }
`;

const ClassTotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 24px;

  // width: 84.89%;
  width: 64.75%;

  // gap: 43px;
  gap: 24px;


  transition: all 0.3s ease-in-out;
`;

const KeypadLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // margin-top: 34px;
  width: 100%;
`;

const NumberKey = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding-bottom: 29%;

  width: 29%;

  border-radius: 12px;
  background-color: ${props => props.isAttendance ? `#FF8B48` : `#DDDDDD`};

  transition: all 0.3s ease-in-out;
`;

const NumText = styled.p`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 12px;

  font-family: NotoSansKR-Bold;
  font-size: 17px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 17px;
  }
  @media (min-height: 640px) {
    font-size: 19px;
  }
  @media (min-height: 720px) {
    font-size: 21px;
  }
  @media (min-height: 760px) {
    font-size: 22px;
  }
  @media (min-height: 800px) {
    font-size: 23px;
  }
  @media (min-height: 1000px) {
    font-size: 30px;
  }
  @media (min-height: 1200px) {
    font-size: 40px;
  }

  :hover {
    cursor: default;
  }
`;

const FiveSecondsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 86px;
`;

const FiveSecondsText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 12px;
  }
  @media (min-height: 640px) {
    font-size: 14px;
  }
  @media (min-height: 680px) {
    font-size: 16px;
  }
  @media (min-height: 720px) {
    font-size: 18px;
  }
  @media (min-height: 760px) {
    font-size: 20px;
  }
  @media (min-height: 800px) {
    font-size: 22px;
  }
  @media (min-height: 1000px) {
    font-size: 24px;
  }
  @media (min-height: 1200px) {
    font-size: 26px;
  }
`;

const PoweredByWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 36px;

  width: 342px;

  @media (min-height: 320px) {
    margin-top: 8px;
    
    width: 62px;
  }
  @media (min-height: 640px) {
    margin-top: 12px;
    
    width: 102px;
  }
  @media (min-height: 680px) {
    margin-top: 16px;
    
    width: 142px;
  }
  @media (min-height: 720px) {
    margin-top: 20px;
    
    width: 182px;
  }
  @media (min-height: 760px) {
    margin-top: 24px;
    
    width: 222px;
  }
  @media (min-height: 800px) {
    margin-top: 28px;
    
    width: 262px;
  }
  @media (min-height: 1000px) {
    margin-top: 32px;
    
    width: 302px;
  }
  @media (min-height: 1200px) {
    margin-top: 36px;

    width: 342px;
  }
`;

const PoweredByImage = styled.img`
  width: 100%;
`;

const RingRingHandImageWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: ${props => props.isFriday ? `-210px` : `-130px`};

  width: 100%;
  height: 100%;

  z-index: 1004;
`;

const RingRingHandImage = styled.img`
  width: 100%;
`;