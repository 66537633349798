import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import AscSortButtonPath from '../../../image/AscSortButton.png';
import DescSortButtonPath from '../../../image/DescSortButton.png';

import NextButtonImage from '../../../image/NormalNextInfoButton.png';
import ColorPrevButtonImage from '../../../image/ColorPrevInfoButton.png';

const SecondWaittingColumnView = (props) => {
  const { prevFn } = props;

  const [startButtonImg, setStartButtonImg] = useState(AscSortButtonPath);

  // true === ACS Sort && false === DESC
  const [startDateSort, setStartDateSort] = useState(true);

  useEffect(() => {
    if (startDateSort) setStartButtonImg(AscSortButtonPath);
    else setStartButtonImg(DescSortButtonPath)
  }, [startDateSort])

  return (
    <TopLevelWrapper>
      <ColumnWrapper>
        <Column style={{ marginRight: 'max(119px, 15.49479166666667vw)',marginLeft: 'max(15px, 1.953125vw)' }}>회원권</Column>
        <SortingWrapper>
          <Column style={{ marginRight: '4px' }}>등록 요청 날짜</Column>
          <SortingButton img={startButtonImg} onClick={() => setStartDateSort(!startDateSort)} style={{ marginRight: '35px' }} />
        </SortingWrapper>
      </ColumnWrapper>
      <ButtonWrapper>
        <Button src={ColorPrevButtonImage} onClick={() => prevFn()}/>
        <Column>이전 정보</Column>
        <Button src={NextButtonImage}/>
      </ButtonWrapper>
    </TopLevelWrapper>
  )
}

export default SecondWaittingColumnView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: max(664px, 86.45833333333333vw);
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SortingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const SortingButton = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Button = styled.img`
  width: 24px;
  height: 24px;

  border-radius: 24px;

  :hover {
    cursor: pointer;
  }
`;