import { React, useEffect, useLayoutEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import FetchModule from '../../Share/Network/FetchModule';
import ClassInfoBox from './ClassInfoBox';


export default function ClassSelectModal(props) {
  const {
    useScheduleData,
    reserveData,
    setReserveData,
    setIsSelectBox,
    onClickEmptyClass,
    onClickClass
  } = props;

  const [allReserveLock, setAllReserveLock] = useState(false);


  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  // useEffect(() => {
  //   console.log(useScheduleData);
  // }, [useScheduleData])

  // ReservationData
  useEffect(() => {
    let schedules = useScheduleData.slice();
    let scheduleIds = [];

    for (let i = 0; i < schedules.length; i++) {
      scheduleIds.push(schedules[i].scheduleId);
    }

    let undefinedCount = 0;

    for (let i = 0; i < schedules.length; i++) {
      if (scheduleIds[i] === undefined) undefinedCount += 1;
    }

    if (scheduleIds.length === undefinedCount) return;

    // 조회할 데이터가 있을경우 //
    if (scheduleIds.length > 0) {
      let requestData = {
        scheduleIds: scheduleIds,
        classDate: schedules[0].classDate.replaceAll('.', '-') + 'T00:00:00'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('reserve/status/check', 'POST', requestData);

          if (responseData.status === 200) {
            setAllReserveLock(!responseData.data.isAllLock);
            setReserveData(responseData.data.status);
          }
        }
      )();
    }
    // 조회할 데이터가 없을경우 //
    else setAllReserveLock(false);
  }, [useScheduleData])

  // 스케줄 데이터 클릭시 //
  function onClickScheduleData(data) {
    if (data.classId === -1) return;

    onClickClass(data);
  }

  return (
    <TopLevelWrapper>
      <PadViewWrapper>
        <RingRingWrapper />
        <Modal>
          <Text>참여할 수업을 선택해주세요</Text>
          <ClassTotalView>
            {
              useScheduleData.map((data, index) => (
                <ClassInfoBox
                  key={index}
                  data={data}
                  reserveData={reserveData[index]}
                  onClickScheduleData={onClickScheduleData} />
              ))
            }
            <EmptyInfoBox color='#6DD49E' onClick={onClickEmptyClass}>
              <EmptyText color='#232323'>일반 방문</EmptyText>
            </EmptyInfoBox>
          </ClassTotalView>
          <ButtonWrapper>
            <ExitButton onClick={setIsSelectBox}>
              <ButtonText color="#FFFFFF">닫기</ButtonText>
            </ExitButton>
          </ButtonWrapper>
        </Modal>
      </PadViewWrapper>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 900;

  width: 100vw;
  height: 100vh;

  // background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const PadViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;
`;

const RingRingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 100px;

  // width: 336px;
  // height: 322px;
  width: 200px;
  height: 191px;
  
  z-index: 1000;

  @media (min-height: 320px) {
    margin-right: 20px;

    width: 110px;
    height: 105px;
  }
  @media (min-height: 640px) {
    margin-right: 30px;

    width: 120px;
    height: 115px;
  }
  @media (min-height: 680px) {
    margin-right: 40px;

    width: 140px;
    height: 134px;
  }
  @media (min-height: 720px) {
    margin-right: 60px;

    width: 150px;
    height: 143px;
  }
  @media (min-height: 800px) {
    margin-right: 70px;

    width: 160px;
    height: 153px;
  }
  @media (min-height: 1000px) {
    margin-right: 80px;

    width: 180px;
    height: 172px;
  }
  @media (min-height: 1200px) {
    margin-right: 100px;

    width: 200px;
    height: 191px;
  }
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48vh;
  min-width: 300px;
  max-width: 800px;

  // height: 90vh;
  height: 80vh;
  min-height: 580px;
  max-height: 1480px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border-radius: 30px;
  background-color: #FFFFFF;

  overflow: hidden;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const Text = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 17px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 17px;
  }
  @media (min-height: 640px) {
    font-size: 19px;
  }
  @media (min-height: 720px) {
    font-size: 21px;
  }
  @media (min-height: 760px) {
    font-size: 22px;
  }
  @media (min-height: 800px) {
    font-size: 23px;
  }
  @media (min-height: 1000px) {
    font-size: 30px;
  }
  @media (min-height: 1200px) {
    font-size: 32px;
  }

  :hover {
    cursor: default;
  }
`;

const ClassTotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 24px;

  width: 74.625%;
  // height: 87.22%;
  height: 70%;

  gap: 8px;

  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  transition: all 0.3s ease-in-out;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  height: 6vh;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30%;
  height: 100%;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 15px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 15px;
  }
  @media (min-height: 640px) {
    font-size: 17px;
  }
  @media (min-height: 720px) {
    font-size: 19px;
  }
  @media (min-height: 760px) {
    font-size: 21px;
  }
  @media (min-height: 800px) {
    font-size: 23px;
  }
  @media (min-height: 1000px) {
    font-size: 25px;
  }
  @media (min-height: 1200px) {
    font-size: 27px;
  }

  :hover {
    cursor: default;
  }
`;

const EmptyInfoBox = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: center;
	align-items: center;

  padding: 0px 8px 0px 8px;

  width: calc(100% - 26px);
  height: 68px;
  min-height: 68px;

	border: 1px solid #ECECEC;
	border-radius: 15px;
  
  background-color: #FFFFFF;
	
  transition: all 0.3s ease-in-out;
  
	:hover {
    cursor: pointer;
		border: 1px solid ${props => props.color};
	}	
`;

const EmptyText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  @media (min-height: 320px) {
    font-size: 12px;
  }
  @media (min-height: 640px) {
    font-size: 14px;
  }
  @media (min-height: 720px) {
    font-size: 16px;
  }
  @media (min-height: 760px) {
    font-size: 18px;
  }
  @media (min-height: 800px) {
    font-size: 20px;
  }
  @media (min-height: 1000px) {
    font-size: 22px;
  }
  @media (min-height: 1200px) {
    font-size: 24px;
  }

  :hover {
    cursor: default;
  }
`;
