import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import MemberShipHistoryInfoButtonImagePath from '../../../../image/MemberShipHistoryInfoButton.png';

const CourseHistoryInfoView = (props) => {
  const { data } = props;
  const [isClicked, setIsClicked] = useState(false);

  const OptionBoxRef = useRef();
  const OptionButtonRef = useRef();

  useEffect(() => {
    if (isClicked) {
      OptionBoxRef.current.style.height = `180px`;
      OptionBoxRef.current.style.border = `1px solid #FF8B48`;
    }
    else {
      OptionBoxRef.current.style.height = `0px`;
      OptionBoxRef.current.style.border = `0px solid #FF8B48`;
    }
  }, [isClicked])

  function mouseOver() {
    OptionButtonRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    OptionButtonRef.current.style.opacity = 0;
  }

  return (
    <TopLevelWrapper onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      <Table>
        <Body>
        <Tr style={{ width: '53px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '23px' }}>
                <Text>121</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '89px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '61px' }}>
                <Text>21.05.30</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '244px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '39px' }}>
                <Text>반야사</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '87px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '58px' }}>
                <Text>18:00PM</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '80px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '52px' }}>
                <Text>남궁민수</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '185px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '129px' }}>
                <Text>필라테스 무제한 3개월</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '82px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '14px' }}>
                <Text>-1</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '125px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '26px' }}>
                <Text>결석</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <OptionButton ref={OptionButtonRef} src={MemberShipHistoryInfoButtonImagePath} onClick={() => setIsClicked(!isClicked)}/>
              <OptionWrapper ref={OptionBoxRef}>
                <Option>예약중</Option>
                <Option>예약 취소</Option>
                <Option>출석 처리</Option>
                <Option>지각 처리</Option>
                <Option>결석 처리</Option>
              </OptionWrapper>
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

export default CourseHistoryInfoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-around;
  // justify-content: center;
  align-items: center;

  width: 1018px;
  height: 50px;

  background: #FFFFFF;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const OptionButton = styled.img`
  opacity: 0;
  
  transition: all 0.3s ease-in-out;
`;

const OptionWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  overflow: hidden;

  // margin-top: 200px;
  // margin-left: 960px;
  margin-top: 40px;
  margin-left: -20px;

  // border: 1px solid #FF8B48;
  border-radius: 8px;
  // background-color: #FFFFFF;

  transition: all 0.2s ease-in-out;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 78px;
  height: 36px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF8B48;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FFFFFF;
    background-color: #FF8B48;
  }
`;