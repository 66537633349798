import { React, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../../Share/Network/FetchModule';

import MemberShipHistoryInfoButtonImagePath from '../../../../image/MemberShipHistoryInfoButton.png';

const SecondCourseHistoryInfoView = (props) => {
  const { data, isOver, reserveRerendering  } = props;

	const history = useHistory();

  const [isClicked, setIsClicked] = useState(false);
  const [state, setState] = useState('예약중');
  const [isAvailableExit, setIsAvailableExit] = useState(false);
  const [isAbsent, setIsAbsent] = useState(false);
  const [isLate, setIsLate] = useState(false);
  const [isAttendance, setIsAttendance] = useState(false);

  const OptionBoxRef = useRef();
  const OptionButtonRef = useRef();

  useEffect(() => {
    if (isClicked) {
      // if (isOver) OptionBoxRef.current.style.height = `180px`;
      if (isOver) OptionBoxRef.current.style.height = `144px`;
      else OptionBoxRef.current.style.height = `130px`;
      OptionBoxRef.current.style.border = `1px solid #FF8B48`;
    }
    else {
      OptionBoxRef.current.style.height = `0px`;
      OptionBoxRef.current.style.border = `0px solid #FF8B48`;
    }
  }, [isClicked, isOver])
  
  useEffect(() => {
    if (data.status === 0) {
      // console.log(data.classDate.split('T')[0] + data.classTime.split('-')[0] + ':00')
      // console.log(moment().format('YYYY-MM-DDTHH:mm:ss'))
      // console.log(moment(data.classDate.split('T')[0] + "T" + data.classTime.split('-')[0] + ':00').isAfter(moment()));
      if (moment(data.classDate.split('T')[0] + "T" + data.classTime.split('-')[0] + ':00').isAfter(moment())) {
        setState('예약중');
        setIsAvailableExit(true);
        setIsAbsent(true);
        setIsLate(true);
        setIsAttendance(true);
      }
      else {
        setState('결석');
        setIsAvailableExit(false);
        setIsAbsent(false);
        setIsLate(true);
        setIsAttendance(true);
      }
    }
    else if (data.status === 1) {
      setState('출석');
      setIsAvailableExit(false);
      setIsAbsent(true);
      setIsLate(true);
      setIsAttendance(false);
    }
    else if (data.status === 2) {
      setState('지각');
      setIsAvailableExit(false);
      setIsAbsent(true);
      setIsLate(false);
      setIsAttendance(true);
    }
    else if (data.status === 3) {
      setState('결석');
      setIsAvailableExit(false);
      setIsAbsent(false);
      setIsLate(true);
      setIsAttendance(true);
    }
  }, [data])

  function mouseOver() {
    OptionButtonRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    OptionButtonRef.current.style.opacity = 0;
  }
  
  function onClickChangeState(state) {
    if (!isAttendance && state === 1) return;
    else if (!isLate && state === 2) return;
    else if (!isAbsent && state === 3) return;

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/modify/' + data.id + '/' + state, 'POST');
     
        if (responseData.status === 200) reserveRerendering();
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  function onClickDeleteLessonReservation() {
    if (!isAvailableExit) return;

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/delete/manage/' + data.id, 'POST');
        
        if (responseData.status === 200) reserveRerendering();
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  return (
    <TopLevelWrapper isOver={isOver} onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      {
        isOver ?
          <Table>
            <Body isOver={isOver}>
              <Tr>
                <Td style={{ width: 'max(80px, 9.3vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(80px, 9.3vw)' /*maxWidth: 'max(52px, 6.770833333333333vw)'*/ }} isOver={isOver}>
                    <Text isOver={isOver}>{data.teacherName}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(185px, 22.1vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(129px, 16.796875vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.memberShipName}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(82px, 8.9vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(14px, 1.822916666666667vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.deduction}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(271px, 40vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(26px, 3.385416666666667vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{state}</Text>
                  </TextWrapper>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <OptionButton ref={OptionButtonRef} src={MemberShipHistoryInfoButtonImagePath} isOver={isOver} onClick={() => setIsClicked(!isClicked)} />
                  <OptionWrapper ref={OptionBoxRef} isOver={isOver}>
                    {/* <Option isOver={isOver}>예약중</Option> */}
                    <Option isOver={isOver} isAvailable={isAvailableExit} onClick={onClickDeleteLessonReservation}>예약 취소</Option>
                    <Option isOver={isOver} isAvailable={isAttendance} onClick={() => onClickChangeState(1)}>출석 처리</Option>
                    <Option isOver={isOver} isAvailable={isLate} onClick={() => onClickChangeState(2)}>지각 처리</Option>
                    <Option isOver={isOver} isAvailable={isAbsent} onClick={() => onClickChangeState(3)}>결석 처리</Option>
                  </OptionWrapper>
                </Td>
              </Tr>
            </Body>
          </Table>
          :
          <Table>
            <Body isOver={isOver}>
              <Tr>
                <Td style={{ width: 'max(43px, 12.4vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(43px, 12.4vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.teacherName}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(78px, 23vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(78px, 23vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.memberShipName}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(58px, 15.8vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(58px, 15.8vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.deduction}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(48px, 15vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(48px, 15vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.status === 0 ? '결석' : data.status === 1 ? '임시용' : '임니다'}</Text>
                  </TextWrapper>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <OptionButton ref={OptionButtonRef} src={MemberShipHistoryInfoButtonImagePath} isOver={isOver} onClick={() => setIsClicked(!isClicked)} />
                  <OptionWrapper ref={OptionBoxRef} isOver={isOver}>
                    {/* <Option isOver={isOver}>예약중</Option> */}
                    <Option isOver={isOver}isAvailable={isAvailableExit} onClick={onClickDeleteLessonReservation}>예약 취소</Option>
                    <Option isOver={isOver}isAvailable={isAttendance} onClick={() => onClickChangeState(1)}>출석 처리</Option>
                    <Option isOver={isOver}isAvailable={isLate} onClick={() => onClickChangeState(2)}>지각 처리</Option>
                    <Option isOver={isOver}isAvailable={isAbsent} onClick={() => onClickChangeState(3)}>결석 처리</Option>
                  </OptionWrapper>
                </Td>
              </Tr>
            </Body>
          </Table>
      }
    </TopLevelWrapper>
  )
};

export default SecondCourseHistoryInfoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: ${props => props.isOver ? `max(690px, 89.84375vw)` : `max(280px, 87.5vw)`};
  height: ${props => props.isOver ? `50px` : `17px`};

  background: #FFFFFF;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
    
  margin-left: ${props => props.isOver ? `max(28px, 3.645833333333333vw)` : `max(10px, 3.125vw)`};

  width: ${props => props.isOver ? `max(640px, 83.33333333333333vw)` : `max(262px, 81.875vw)`};
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  padding: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: ${props => props.isOver ? `20px` : `15px`};
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};
`;

const OptionButton = styled.img`
  width: ${props => props.isOver ? `20px` : `9px`};
  height: ${props => props.isOver ? `20px` : `9px`};

  opacity: 0;
  
  transition: all 0.3s ease-in-out;
`;

const OptionWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  overflow: hidden;

  z-index: 5;
  
  height: 0px;

  margin-top: ${props => props.isOver ? `40px` : `16px`};
  margin-left: ${props => props.isOver ? `-20px` : `-42px`};

  border: 0px solid #FF8B48;
  border-radius: 8px;

  transition: all 0.2s ease-in-out;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `78px` : `58px`};
  height: ${props => props.isOver ? `36px` : `26px`};

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOVer ? `14px` : `10px`};

  color: ${props => props.isAvailable ? `#FF8B48` : `#CBCBCB`};
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: ${props => props.isAvailable ? `#FFFFFF` : `#CBCBCB`};
    background-color: #FF8B48;
  }
`;