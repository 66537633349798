import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import MemberShipHistoryInfoButtonImagePath from '../../../../image/MemberShipHistoryInfoButton.png';

const FirstMemberShipHistoryInfoView = forwardRef((props, ref) => {
  const { data, index, isOver, modifyClick, holdingClick, extensionClick, refundClick, deleteClick } = props;
  const [isClicked, setIsClicked] = useState(false);

  const OptionBoxRef = useRef();
  const OptionEditRef = useRef();
  const OptionButtonRef = useRef();
  const OptionHoldRef = useRef();
  const OptionExtendRef = useRef();
  const OptionDeleteRef = useRef();

  useImperativeHandle(ref, () => ({
    setIsClicked: () => {
      setIsClicked(false);
    },
    getButtonRef: () => {
      return OptionButtonRef.current;
    },
    getBoxRef: () => {
      return OptionBoxRef.current;
    }
  }))

  useEffect(() => {
    if (isClicked) {
      // if (isOver) OptionBoxRef.current.style.height = `142px`;
      // else OptionBoxRef.current.style.height = `80px`;
      if (isOver) OptionBoxRef.current.style.height = `176px`;
      else OptionBoxRef.current.style.height = `100px`;
      OptionBoxRef.current.style.border = `1px solid #FF8B48`;
    }
    else {
      OptionBoxRef.current.style.height = `0px`;
      OptionBoxRef.current.style.border = `0px solid #FF8B48`;
    }
  }, [isClicked, isOver])
  
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  function onClickOutSide(e) {
    if (OptionBoxRef.current !== e.target 
      && OptionButtonRef.current !== e.target
      && OptionEditRef.current !== e.target
      && OptionHoldRef.current !== e.target
      && OptionExtendRef.current !== e.target
      && OptionDeleteRef.current !== e.target) {
      setIsClicked(false);
    }
  }

  function mouseOver() {
    OptionButtonRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    OptionButtonRef.current.style.opacity = 0;
  }

  return (
    <TopLevelWrapper isOver={isOver} onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      {
        isOver ?
          <Table>
            <Body isOver={isOver}>
              <Tr>
                <Td style={{ width: 'max(103px, 12vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(103px, 12vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.memberShipName}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(156px, 20vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(127px, 16.53645833333333vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>
                      {data.startDate.slice(2, 10).replaceAll('-', '.')} - {data.endDate.slice(2, 10).replaceAll('-', '.')}
                    </Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(83px, 9vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(53px, 6.901041666666667vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.totalMax === -1 ? '제한없음' : data.totalMax}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(84px, 9.3vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(17px, 2.213541666666667vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.courseTakeCount}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(180px, 23.4375vw)' }}>
                  <TextWrapper style={{ maxWidth: 'max(100px, 13.02083333333333vw)' }} isOver={isOver}>
                    <Text isOver={isOver}>{data.totalPrice}</Text>
                  </TextWrapper>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <OptionButton ref={OptionButtonRef} src={MemberShipHistoryInfoButtonImagePath} isOver={isOver} onClick={() => setIsClicked(!isClicked)} />
                  <OptionWrapper ref={OptionBoxRef} isOver={isOver}>
                    <Option ref={OptionEditRef} isOver={isOver} onMouseDown={() => modifyClick(index)}>수정</Option>
                    <Option ref={OptionHoldRef} isOver={isOver} onMouseDown={() => holdingClick(index)}>홀딩</Option>
                    <Option ref={OptionExtendRef} isOver={isOver} onMouseDown={() => extensionClick(index)}>연장</Option>
                    <Option ref={OptionExtendRef} isOver={isOver} onMouseDown={() => refundClick(index)}>환불</Option>
                    <Option ref={OptionDeleteRef} isOver={isOver} onMouseDown={() => deleteClick(index)}>삭제</Option>
                  </OptionWrapper>
                </Td>
              </Tr>
            </Body>
          </Table>
          :
          <Table>
            <Body isOver={isOver}>
              <Tr>
                <Td style={{ width: 'max(61px, 17vw)' }} isOver={isOver}>
                  <TextWrapper style={{ maxWidth: 'max(61px, 17vw)' }}>
                    <Text isOver={isOver}>{data.memberShipName}</Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(95px, 29vw)' }} isOver={isOver}>
                  <TextWrapper style={{ maxWidth: 'max(95px, 29vw)' }}>
                    <Text isOver={isOver}>
                      {data.startDate.slice(2, 10).replaceAll('-', '.')} - {data.endDate.slice(2, 10).replaceAll('-', '.')}
                    </Text>
                  </TextWrapper>
                </Td>
                <Td style={{ width: 'max(71px, 22.1875vw)' }} isOver={isOver}>
                  <TextWrapper style={{ maxWidth: 'max(71px, 22.1875vw)' }}>
                    <Text isOver={isOver}>{data.totalMax === -1 ? '제한없음' : data.totalMax}</Text>
                  </TextWrapper>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <OptionButton ref={OptionButtonRef} src={MemberShipHistoryInfoButtonImagePath} isOver={isOver} onClick={() => setIsClicked(!isClicked)} />
                  <OptionWrapper ref={OptionBoxRef} isOver={isOver}>
                    {/* <Option isOver={isOver} onMouseDown={() => modifyClick(index)}>수정</Option> */}
                    <Option ref={OptionHoldRef} isOver={isOver} onClick={() => holdingClick(index)}>홀딩</Option>
                    <Option ref={OptionExtendRef} isOver={isOver} onClick={() => extensionClick(index)}>연장</Option>
                    <Option ref={OptionExtendRef} isOver={isOver} onClick={() => refundClick(index)}>환불</Option>
                    <Option ref={OptionDeleteRef} isOver={isOver} onClick={() => deleteClick(index)}>삭제</Option>
                  </OptionWrapper>
                </Td>
              </Tr>
            </Body>
          </Table>
      }
    </TopLevelWrapper>
  )
});

export default FirstMemberShipHistoryInfoView;

const TopLevelWrapper = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    width: ${props => props.isOver ? `max(690px, 89.84375vw)` : `max(280px, 87.5vw)`};
    // width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(281px, 87.8125vw)`};
    height: ${props => props.isOver ? `50px` : `17px`};

    background: #FFFFFF;

    box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
    border: 1px solid #FFFFFF;
    border-radius: 30px;
    
    transition: all 0.3s ease-in-out;

    :hover {
      cursor: pointer;
      border: 1px solid #FF8B48;
    }
  `;

const Table = styled.table`
    display: flex;
    flex-direction: row;
  `;

const Body = styled.tbody`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
    
    margin-left: ${props => props.isOver ? `max(28px, 3.645833333333333vw)` : `max(10px, 3.125vw)`};

    width: ${props => props.isOver ? `max(640px, 83.33333333333333vw)` : `max(262px, 81.875vw)`};
  `;

const Tr = styled.tr`
    display: flex;
    flex-direction: row;
  `;

const Td = styled.td`
    display: flex;
    flex-direction: row;

    padding: 0 0 0 0;
  `;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: flex-start;

    overflow: hidden;

    max-height: ${props => props.isOver ? `20px` : `15px`};
  `;

const Text = styled.span`
    font-family: NotoSansKR-Regular;
    font-size: ${props => props.isOver ? `14px` : `10px`};

    color: #595959;

    margin: 0 0 0 0;
    padding: 0 0 0 0;

    -webkit-transform: ${props => props.isOver ? `scale(1)` : `scale(0.9)`};
  `;

const OptionButton = styled.img`
    width: ${props => props.isOver ? `20px` : `9px`};
    height: ${props => props.isOver ? `20px` : `9px`};

    opacity: 0;
    
    transition: all 0.3s ease-in-out;
  `;

const OptionWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    overflow: hidden;

    height: 0px;
    
    z-index: 5;

    margin-top: ${props => props.isOver ? `40px` : `16px`};
    margin-left: ${props => props.isOver ? `-20px` : `-22px`};

    border: 0px solid #FF8B48;
    border-radius: 8px;

    transition: all 0.2s ease-in-out;
  `;

const Option = styled.div`
    display: flex;
    flex-direction: column;;

    justify-content: center;
    align-items: center;

    width: ${props => props.isOver ? `58px` : `38px`};
    height: ${props => props.isOver ? `38px` : `20px`};

    margin: 0 0 0 0;

    font-family: NotoSansKR-Regular;
    font-size: ${props => props.isOver ? `14px` : `10px`};

    color: #FF8B48;
    background-color: #FFFFFF;

    transition: all 0.3s ease-in-out;

    :hover {
      cursor: pointer;
      color: #FFFFFF;
      background-color: #FF8B48;
    }
  `;