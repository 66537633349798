import { React } from 'react';
import styled from 'styled-components';

import FullBoardView from '../../Model/Individual/BoardModel/FullBoardView';

function FullScreenBoard() {

  return (
    <Container>
      <FullBoardView/>
    </Container>
  )
}

export default FullScreenBoard;

const Container = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;

  // justify-content: center;
  align-items: center;
  justify-content: flex-start;
  // align-items: flex-start;

  width: 100vw;
  min-height: 100vh;
  // height: 100vh;
  // max-width: 100%;
  // margin: 0 0 0 0;
  // padding: 0 0 0 0;
  background-color: #FDFCFB;
`;