import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

const ReasonInput = forwardRef((props, ref) => {
	const { className, placeholder, type, clickCountFn, disabled, isOver } = props;
	const [value, setValue] = useState('');
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			setChangedPlaceholderColor(true);
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#CBCBCB';
		}
	}));

	// useEffect(() => {
	// 	clickCountFn(className, clickCount);
	// }, [clickCount, value])

	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';
		// setDefaultPlaceholder('');
	}

	function handleChange(e) {
    setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#CBCBCB';
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return <Input
		ref={inputRef}
		isOver={isOver}
		clickCount={clickCount}
		type={type}
		placeholder={placeholder}
		value={value}
    disabled={disabled}
		changedColor={changedPlaceholderColor}
		className={`${className} ${changed ? "changed" : ""}`}
		onClick={handleClick}
		onChange={handleChange}
		onFocus={handleFocus}
		onBlur={handleBlur}/>
});

export default ReasonInput;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

  margin: 8px 0 0 0;
  padding: 0 0 0 8px;

  width: ${props => props.isOver ? `578px` : `224px`};
  height: 30px;

	outline: none;
  border: 1px solid #CBCBCB;
  border-radius: 8px;
	color: #000000;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 14px;
    
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.3s ease-in-out;
`;