import { React } from 'react';
import styled from 'styled-components';

const InfoPagination = (props) => {
  const { nowPage, totalPage, isOver, nextClickFn, prevClickFn } = props;

  return (
    <TopLevelWrapper>
      <Button isOver={isOver} onClick={() => prevClickFn()}>이전</Button>
      <Page isOver={isOver}>{nowPage}/{totalPage}</Page>
      <Button isOver={isOver} onClick={() => nextClickFn()}>다음</Button>
    </TopLevelWrapper>
  )
}

export default InfoPagination;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Button = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #A7A7A7;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
    color: #232323;
  }
`;

const Page = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 10px 0 10px;
`;