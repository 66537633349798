class SessionStorage {

  getToken() {
    return window.sessionStorage.getItem('Token');
  }

  getStoreId() {
    return window.sessionStorage.getItem('StoreId');
  }
}

export default new SessionStorage();