import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

const CommentTextArea = forwardRef((props, ref) => {
	const { isOver, className, placeholder, type, defaultValue, changeFn } = props;
	const [value, setValue] = useState(defaultValue);
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			return value;
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		reset: () => {
			setValue('');
			inputRef.current.style.height = `88px`;
		}
	}));

	useEffect(() => {
		inputRef.current.style.height = `${(inputRef.current.scrollHeight + 9)}px`;
	}, [])

	function handleChange(e) {
		// console.log(e.target.value);
		setValue(e.target.value);
		changeFn(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
	}

  function onPress(e) {
    // if (e.key === 'Enter') {
    //   inputRef.current.style.height = `${(inputRef.current.scrollHeight + 9)}px`;
    // }
  }

 
	return <Input
		ref={inputRef}
		isOver={isOver}
		clickCount={clickCount}
		type={type}
		placeholder={placeholder}
		value={value}
		changedColor={changedPlaceholderColor}
		className={`${className} ${changed ? "changed" : ""}`}
		onChange={handleChange}
		onFocus={handleFocus}
		onBlur={handleBlur} 
    onKeyPress={onPress}/>
});

export default CommentTextArea;

const Input = styled.textarea`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 6px 8px 6px 8px;

  // width: 818px;
	width: ${props => props.isOver ? `max(486px, 63.28125vw)` : `max(250px, 78.125vw)`};
  // height: 70px;
	height: 74px;

  resize: none;
  overflow-y: scroll;
  // resize: vertical;

  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;
  border: 0px solid #DFDFDF;
  border-radius: 8px;

  color: #595959;

	outline: none;

	::placeholder {
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;