import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import GraphBox from './GraphBox';
import MemberMovementHistoryBox from './MemberMovementHistoryBox';
import NotReceivedHistory from './NotReceivedHistory';
import SalesHistory from './SalesHistory';

import FetchModule from '../../Share/Network/FetchModule';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import NotAvailableRightArrow from '../../../image/NotAvailableRight.png';

const StatisticsView = forwardRef((props, ref) => {
  const { isOver, size } = props;

  const history = useHistory();

  const [today, setToday] = useState(moment());

  const [isAliveLoading, setIsAliveLoading] = useState(true);
  const [isExpiredLoading, setIsExpiredLoading] = useState(true);
  const [expiredData, setExpiredData] = useState([]);
  const [aliveData, setAliveData] = useState([]);

  const [leaveMemberData, setLeaveMemberData] = useState([]);
  const [aliveMemberData, setAliveMemberData] = useState([]);

  const [isDoughnutLoading, setIsDoughnutLoading] = useState(true);
  const [isSalesLoading, setIsSalesLoading] = useState(true);
  const [salesData, setSalesData] = useState([]);

  const [isNotReceivedLoading, setIsNotReceivedLoading] = useState(true);
  const [notReceivedData, setNotReceivedData] = useState([]);

  const TopLevelRef = useRef();
 
  useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
    setZindex: (val) => {
			TopLevelRef.current.style.zIndex = val;
    }
	}))

  useEffect(() => {
    if (today === undefined) return;

    // 도넛차트 로딩 에니메이션
    setIsDoughnutLoading(true);

    // 구매내역 로딩 에니메이션
    setIsSalesLoading(true);

    // 미수금 로딩 에니메이션
    setIsNotReceivedLoading(true);

    // 월 단위 구매내역 조회 ( YYYY-MM-01 ) //
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('sales/' + today.clone().format('YYYY-MM') + '-01T00:00:00' + '/', 'GET');

        if (responseData.status === 200) setSalesData(responseData.data);
        else if (responseData.status === 204) setSalesData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
    (
      async () => {
        const responseData = await fetchModule.getData('sales/notreceived/' + today.clone().format('MM') + '/', 'GET');
       
        if (responseData.status === 200) setNotReceivedData(responseData.data);
        else if (responseData.status === 204) setNotReceivedData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [today])

  useEffect(() => {
    setIsAliveLoading(true);
    setIsExpiredLoading(true);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('member/alive/', 'GET');
        
        if (responseData.status === 200) setAliveData(responseData.data);
        else if (responseData.status === 204) setAliveData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
    (
      async () => {
        const responseData = await fetchModule.getData('member/expired/', 'GET');
        
        if (responseData.status === 200) setExpiredData(responseData.data);
        else if (responseData.status === 204) setExpiredData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, today])

  useEffect(() => {
    var newAliveData = [];
    var newLeaveData = [];

    for (var i = 0; i < aliveData.length; i++) {
      if (aliveData[i].endDate > today.clone().format('YYYY-MM-DDTHH:mm:ss')) {
        newAliveData.push(aliveData[i]);
      }
      else {
        newLeaveData.push(aliveData[i]);
      }
    }

    for (i = 0; i < expiredData.length; i++) {
      if (expiredData[i].endDate > today.clone().format('YYYY-MM-DDTHH:mm:ss')) {
        newAliveData.push(expiredData[i]);
      }
      else {
        newLeaveData.push(expiredData[i]);
      }
    }

    setAliveMemberData(newAliveData);
    setLeaveMemberData(newLeaveData);
  }, [today, aliveData, expiredData])

  function onClickNextMonth() {
    if (today.clone().add(1, 'month').format('YYYY.MM') <= moment().format('YYYY.MM')) {
      setIsDoughnutLoading(true);
      setToday(today.clone().add(1, 'month'));
    }
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TitleWrapper isOver={isOver}>
        <Title isOver={isOver}>통계</Title>
        <Explanation isOver={isOver}>센터의 매출 및 회원 등 모든 비즈니스 관련 통계를 확인하세요</Explanation>
      </TitleWrapper>
      <DateButton isOver={isOver}>
        <Button src={LeftArrow} onClick={() => setToday(today.clone().subtract(1, 'month'))}/>
        <DateText isOver={isOver}>{today.clone().format('YYYY년 MM월')}</DateText>
        <Button src={today.clone().format('YYYY.MM') === moment().format('YYYY.MM') ? NotAvailableRightArrow  : RightArrow} onClick={onClickNextMonth}/>
      </DateButton>
      <GraphBox 
        today={today} 
        data={salesData} 
        isOver={isOver} 
        size={size}
        isDoughnutLoading={isDoughnutLoading} 
        setIsDoughnutLoading={setIsDoughnutLoading}/>
      <MemberMovementHistoryBox 
        today={today} 
        leaveData={leaveMemberData} 
        aliveData={aliveMemberData} 
        isOver={isOver}
        isAliveLoading={isAliveLoading}
        setIsAliveLoading={setIsAliveLoading}
        isExpiredLoading={isExpiredLoading}
        setIsExpiredLoading={setIsExpiredLoading}/>
      <NotReceivedHistory today={today} data={notReceivedData} isOver={isOver} isNotReceivedLoading={isNotReceivedLoading} setIsNotReceivedLoading={setIsNotReceivedLoading}/>
      <SalesHistory today={today} data={salesData} isOver={isOver} isSalesLoading={isSalesLoading} setIsSalesLoading={setIsSalesLoading}/>
    </TopLevelWrapper>
  )
})

export default StatisticsView;

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  z-index: -3;
  opacity: 0;
  // z-index: 1;
  // opacity: 1;
  
  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 4px;
  margin-left: ${props => props.isOver ? `28px` : `14px`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 0 0 0;
`;

const DateButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  align-items: center;

  width: ${props => props.isOver ? `205px` : `101px`};
  height: ${props => props.isOver ? `38px` : `29px`};

  gap: ${props => props.isOver ? `37px` : `7px`};
  margin-top: ${props => props.isOver ? `38px` : `10px`};

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `15px` : `9px`};
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const Button = styled.img`
  width: 10px;
  height: 17px;

  :hover {
    cursor: pointer;
  }
`;