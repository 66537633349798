import { React } from 'react';
import styled from 'styled-components';

import LogoPath from '../../../image/CompleteSignUpLogo.png';

function CompleteSignUp(props) {
  const { isOver, text, closeFn } = props;

	return (
    <Background>
      <TopLevelWrapper isOver={isOver}>
        <LogoWrapper isOver={isOver}>
          <Logo src={LogoPath}  isOver={isOver}/>
        </LogoWrapper>
        <ExplanationWrapper isOver={isOver}>
          <KorExplanation isOver={isOver}>{text}</KorExplanation>
        </ExplanationWrapper>
        <GoHomeButton isOver={isOver} onClick={closeFn} >
          <ButtonText isOver={isOver}>돌아가기</ButtonText>
        </GoHomeButton>
      </TopLevelWrapper>
    </Background>
  )
}
 
export default CompleteSignUp;

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

	width: ${props => props.isOver ? `454px` : `280px`};
	height: ${props => props.isOver ? `268px` : `220px`};
  
	border-radius: ${props => props.isOver ? `50px` : `25px`};
  background-color: #FFFFFF;

  transition: all 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: ${props => props.isOver ? `-160px` : `-140px`};
`;

const Logo = styled.img`
  width: ${props => props.isOver ? `200px` : `150px` };
  height: ${props => props.isOver ? `200px` : `150px` };
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  margin-top: ${props => props.isOver ? `0px` : `15px`};

  width: 90%;
`;

const KorExplanation = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `18px` : `14px`};

  margin: 0 0 0 0;
`;

const GoHomeButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `282px` : `220px`};
  height: ${props => props.isOver ? `36px` : `30px`};

  background-color: #FF8B48;
  border-radius: ${props => props.isOver ? `23px` : `18px`};

  margin-top: ${props => props.isOver ? `34px` : `26px`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #FF7322;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `18px` : `14px`};
  color: #FFFFFF;

  margin: 0 0 0 0;
`;