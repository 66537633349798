import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

function ClassDetailModal(props) {
  const { isOver, data, date, cancel } = props;

  const [term, setTerm] = useState('없음');

  useEffect(() => {
    document.body.style.cssText = `
            position: fixed; 
            top: 0px;
            left: 0px;
            overflow-y: hidden;
            width: 100vm;
            height: 100vm`
      ;
    return () => {
      document.body.style.cssText = '';
      window.scrollTo(0, 0);
    };
  }, []);
  
  useEffect(() => {
    if (data.term === 0) setTerm('없음');
    else if (data.term === 1) setTerm('1주 마다');
    else if (data.term === 2) setTerm('2주 마다');
    else if (data.term === 3) setTerm('3주 마다');
    else if (data.term === 4) setTerm('4주 마다');
  }, [data])

  return (
    <TopLevelWrapper>
      <ModalView isOver={isOver}>
        <TitleWrapper isOver={isOver}>
          <Title isOver={isOver}>수업 상세정보</Title>
          <DateText isOver={isOver}>{date.format('YYYY.MM.DD')}</DateText>
        </TitleWrapper>
        <TotalWrapper isOver={isOver}>
          <LayerWrapper isOver={isOver}>
            <ComponentWrapper>
              <ComponentTitle>수업</ComponentTitle>
              <InputView width={isOver ? `570px` : `216px`}>{data.className}</InputView>
            </ComponentWrapper>
          </LayerWrapper>
          <LayerWrapper isOver={isOver}>
            <ComponentWrapper>
              <ComponentTitle>수업 일시</ComponentTitle>
              <InputView width={isOver ? `264px` : `216px`}>{data.classDate.split('T')[0].replaceAll('-', '.')}</InputView>
            </ComponentWrapper>
            <ComponentWrapper>
              <ComponentTitle>시간</ComponentTitle>
              <InputView width={isOver ? `264px` : `216px`}>{data.classTime}</InputView>
            </ComponentWrapper>
          </LayerWrapper>
          <LayerWrapper isOver={isOver}>
            <ComponentWrapper>
              <ComponentTitle>강사</ComponentTitle>
              <InputView width={isOver ? `570px` : `216px`}>{data.teacherName}</InputView>
            </ComponentWrapper>
          </LayerWrapper>
          <LayerWrapper isOver={isOver}>
            <ComponentWrapper>
              <ComponentTitle>차감 횟수</ComponentTitle>
              <InputView width={isOver ? `264px` : `216px`}>{data.deduction}</InputView>
            </ComponentWrapper>
            <ComponentWrapper>
              <ComponentTitle>예약 정원</ComponentTitle>
              <InputView width={isOver ? `264px` : `216px`}>{data.maxBookedPeople}</InputView>
            </ComponentWrapper>
          </LayerWrapper>
          <LayerWrapper isOver={isOver}>
            <ComponentWrapper>
              <ComponentTitle>반복 개설 일정</ComponentTitle>
              <InputView width={isOver ? `570px` : `216px`}>{term}</InputView>
            </ComponentWrapper>
          </LayerWrapper>
          <ButtonWrapper isOver={isOver}>
            <ExitButton onClick={cancel}>닫기</ExitButton>
          </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default ClassDetailModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `644px` : `290px`};
  height: ${props => props.isOver ? `552px` : `612px`}; 

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: ${props => props.isOver ? `588px` : `234px`};
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `14px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const DateText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `12px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `24px` : `12px`};
  margin-top: ${props => props.isOver ? `16px` : `12px`};
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `24px` : `12px`};
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: ${props => props.isOver ? `588px` : `234px`};

  margin-top: ${props => props.isOver ? `32px` : `24px`};
`;

const InputView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;
  margin: 8px 0 0 0;

  width: ${props => props.width};
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #595959;
    color: #595959;
  }
`;