class SortingModule {
  dataSort(data, division, state, keyword) {
    var returnData = data.slice();
    if (state === 'default') {
      returnData.sort(function (a, b) {
        return a[keyword] - b[keyword];
      });
    }
    else if (state === 'asc') {
      returnData.sort(function (a, b) {
        return a[division] < b[division] ? -1 : a[division] > b[division] ? 1 : 0;
      });
    }
    else if (state === 'desc') {
      returnData.sort(function (a, b) {
        return a[division] > b[division] ? -1 : a[division] < b[division] ? 1 : 0;
      });
    }
    
    return returnData;
  }
}

export default new SortingModule();