import { React, useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

import SearchImgPath from '../../image/SearchIcon.png';

const MemberSearchInput = forwardRef((props, ref) => {
	const { memberData, type } = props;

	const [value, setValue] = useState('');
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(-1);
	const [clickCount, setClickCount] = useState(0);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
		},
		getValue: () => {
      if (index === -1) return 'None';
      else return dataList[index].memberId;
		},
    getDataList: () => {
      return dataList;
    },
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
	}));

  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [dataList])

  useEffect(() => {
    var tmpList = memberData.slice();

    for (var i = 0; i < tmpList.length; i++) {
      tmpList[i] = Object.assign(tmpList[i], { isChecked: false })
    }

    if (tmpList.length !== 0) setIndex(0); 

    setData(tmpList);
    setDataList(tmpList);
  }, [memberData])

  // useEffect(() => {
  //   console.log(dataList);
  // }, [dataList])

	function handleClick(index) {
    var tmpList = dataList.slice();

		for (var i = 0; i < dataList.length; i++) {
      tmpList[i].isChecked = false;
    }

    tmpList[index].isChecked = true;

    setDataList(tmpList);
    setIndex(index);
	}

	function handleChange(e) {
		setValue(e.target.value);

    var indexList = [];
    var tmp = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].name.indexOf(e.target.value) > -1) {
        indexList.push(i);
      }
      else if (data[i].phone.indexOf(e.target.value) > -1){
        indexList.push(i);
      }
    }

    if (indexList.length !== 0) {
      for (i = 0; i < indexList.length; i++) {
        tmp.push(data[indexList[i]]);
      }
      console.log(tmp)
      setDataList(tmp);
    }
    else {
      setDataList(data);
    }
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#E8E8E8';
	}
  
  return (
    <TopLevelWrapper>
      <SearchWrapper>
        <Input
          ref={inputRef}
          img={SearchImgPath}
          clickCount={clickCount}
          type={type}
          placeholder='회원의 이름이나 전화번호를 검색해 주세요'
          value={'' || value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur} />
        <ColumnWrapper>
          <Column style={{ marginRight: `34px` }}>이름</Column>
          <Column style={{ marginRight: `60px` }}>전화번호</Column>
          <Column style={{ marginRight: `9px` }}>링크코치 아이디</Column>
        </ColumnWrapper>
        <InfoWrapper>
          {
            dataList.map((data, index) => (
              <InfoBox key={index} onClick={() => handleClick(index)}>
                <Table>
                  <Body>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `50px`, marginRight: `7px`}}>
                          <Name isChecked={data.isChecked}>{data.name}</Name>
                        </TextWrapper>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `85px`, marginRight: `20px` }}>
                          <Phone isChecked={data.isChecked}>{data.phone.slice(0, 3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11)}</Phone>
                        </TextWrapper>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `80px` }}>
                          <Phone isChecked={data.isChecked}>{data.linkCoachId}</Phone>
                        </TextWrapper>
                      </Td>
                    </Tr>
                  </Body>
                </Table>
              </InfoBox>
            ))
          }
        </InfoWrapper>
      </SearchWrapper>
    </TopLevelWrapper>
  )
})

export default MemberSearchInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  // margin-top: 15px;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 0 0 0 16px;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	line-height: 20px;

  padding: 0 0 0 40px;
  width: 223px;
  height: 30px;

	outline: none;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
	color: #000000;

  background: url(${props => props.img}) no-repeat 8px center;

	::placeholder {
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.3s ease-in-out;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 267px;
  height: 124px;

  overflow-y: scroll;

  margin-top: 13px;

  &::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 253px;
  height: 32px;
  
  border-bottom: 1px solid #F5F5F5;

  :hover {
    cursor: pointer;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;

  height: 32px;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const ColumnWrapper = styled.div`
  font-family: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 16px;
`;

const Column = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #D0D0D0;

  margin: 0 0 0 0;
`;

const Name = styled.span`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color:  ${props => props.isChecked ? `#FF8B48` : `#595959`};

  margin: 0 0 0 0;

  transition: all 0.2s ease-in-out;
`;

const Phone = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: ${props => props.isChecked ? `#FF8B48` : `#777777`};

  margin: 0 0 0 0;

  transition: all 0.2s ease-in-out;
`;
