import { React } from 'react';
import styled from 'styled-components';

const MonthlyColumn = () => {

  return (
    <TopLevelWrapper>
      <Date>일요일</Date>
      <Date>월요일</Date>
      <Date>화요일</Date>
      <Date>수요일</Date>
      <Date>목요일</Date>
      <Date>금요일</Date>
      <Date>토요일</Date>
    </TopLevelWrapper>
  )
}

export default MonthlyColumn;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 9px;
  // margin-top: 28px;
  margin-bottom: 28px;

  width: 964px;
`;

const Date = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 130px;

  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #595959;
`;