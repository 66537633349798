import { React, forwardRef, useState, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

const BoardSettingModeSelect = forwardRef((props, ref) => {
  const { changeValueFn } = props;

	const [isClicked, setIsClicked] = useState(false);
	const [defaultVal, setDefaultVal] = useState({ value: '수업' });

	const SelectRef = useRef()

	const options = [
		{ value: '수업' },
		{ value: '스탑워치' },
	];

	useImperativeHandle(ref, () => ({
		getValue: (val) => {
			return defaultVal.value;
		}
	}))

	function onClick() {
		if (isClicked) {
			SelectRef.current.style.border = '1px solid #CBCBCB';
			// SelectRef.current.style.height = `40px`;
      SelectRef.current.style.height = `1.979166666666667vw`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			// SelectRef.current.style.height = `85px`;
      SelectRef.current.style.height = `4.427083333333333vw`;
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
		setDefaultVal(options[index]);
    changeValueFn(options[index].value);
	}

	return (
		<TopLevelWrapper>
			<SelectBox
				ref={SelectRef}
				isClicked={isClicked}
				buttonPath={isClicked ? SelectOnPath : SelectOffPath}
				onClick={onClick}>
				{
					isClicked ?
						options.map((data, index) =>
							<OptionWrapper key={index} onClick={() => optionalClick(index)}>
								<Option>{data.value}</Option>
							</OptionWrapper>
						)
						:
						<OptionWrapper>
							<Option>
								{
									defaultVal.value
								}
							</Option>
						</OptionWrapper>
				}
			</SelectBox>
		</TopLevelWrapper>
	)
})

export default BoardSettingModeSelect;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
  
	// width: 202px;
  width: 10.52083333333333vw;
	// height: 38px;
  height: 1.979166666666667vw;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: flex-start;

	// width: 202px;
  width: 10.52083333333333vw;
	// height: 38px;
  height: 1.979166666666667vw;

	border: 1px solid #CBCBCB;
	border-radius: 15px;
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	// font-size: 14px;
  font-size: 0.7291666666666667vw;

	// margin: 10px 0 10px 13px;
  margin-top: 0.5208333333333333vw;
  margin-right: 0px;
  margin-bottom: 0.5208333333333333vw;
  margin-left: 0.6770833333333333vw;
`;