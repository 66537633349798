import { React, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function TempKeypadPage({match}) {
  
  const history = useHistory();

  useEffect(() => {
    window.localStorage.setItem("StoreId", match.params.storeId);
    window.localStorage.setItem("Token", match.params.accessToken);
    window.localStorage.setItem('RefreshToken', match.params.refreshToken);
    
    history.push('/keypad');
  }, [])

  return <div/>
}