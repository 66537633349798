import React, { useEffect } from "react";

export default function CoachPasswordPassPage({match}) {
  useEffect(() => {
    let token = match.params.token;
    
    window.location.href = "linkcoach://ChangePasswordOfLink/" + token;
  }, [])

  return <></>
}