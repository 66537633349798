import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import MemberShipHistoryColumn from './MemberShipHistoryColumn.js';
import FirstMemberShipHistoryColumn from './FirstMemberShipHistoryColumn.js';
import MemberShipHistoryInfoView from './MemberShipHistoryInfoView.js';
import CourseHistoryColumnView from './CourseHistoryColumnView.js';
import CourseHistoryInfoView from './CourseHistoryInfoView.js';
import LockerHistoryColumnView from './LockerHistoryColumnView.js';
import LockerHistoryInfoView from './LockerHistoryInfoView.js';
import HoldingHistoryColumn from './HoldingHistoryColumn.js';
import HoldingHistoryInfoView from './HoldingHistoryInfoView.js';

import FirstMemberShipHistoryInfoView from './FirstMemberShipHistoryInfoView.js';

import InfoPagination from '../../../../Component/MemberComponent/InfoPagination.js';

import FetchModule from '../../../Share/Network/FetchModule.js';

// import Modal //
import PaymentModal from '../PaymentModal.js';
import MemberShipAddModal from './Modal/MemberShipAddModal.js';
import MemberShipEditModal from './Modal/MemberShipEditModal.js';
import MemberShipDeleteModal from './Modal/MemberShipDeleteModal.js';
import MemberShipHoldingModal from './Modal/MemberShipHoldingModal.js';
import MemberShipExtendModal from './Modal/MemberShipExtensionModal.js';

import SessionStorage from '../../../Share/Network/SessionStorage.js';

import ButtonImagePath from '../../../../image/CreateMemberShipButton.png';
import CoachHistoryView from './CoachHistoryView.js';
import SecondMemberShipHistoryColumn from './SecondMemberShipHistoryColumn.js';
import SecondMemberShipHistoryInfoView from './SecondMemberShipHistoryInfoView.js';
import FirstCourseHistoryColumnView from './FirstCourseHistoryColumnView.js';
import SecondCourseHistoryColumnView from './SecondCourseHistoryColumnView.js';
import ThirdMemberShipHistoryColumn from './ThirdMemberShipHistoryColumn.js';
import FirstCourseHistoryInfoView from './FirstCourseHistoryInfoView.js';
import SecondCourseHistoryInfoView from './SecondCourseHistoryInfoView.js';
import ThirdMemberShipHistoryInfoView from './ThirdMemberShipHistoryInfoView.js';
import UnSupportedDeviceModal from './Modal/UnsupportedDeviceModal.js';
import RefundModal from '../../MySettingModel/RefundModal.js';
import HoldingCancelModal from './Modal/HoldingCancelModal.js';
import HoldingDeleteModal from './Modal/HoldingDeleteModal.js';



const HistoryView = forwardRef((props, ref) => {
  const {
    data,
    holdingData,
    memberId,
    division,
    lessonHistoryData,
    physicalData,
    lockerData,
    isOver,
    rendering,
    reserveRerendering
  } = props;

  const history = useHistory();

  const [nowPage, setNowPage] = useState('MemberShip');
  const [index, setIndex] = useState(-1);
  const [buttonText, setButtonText] = useState('회원권 추가');
  const [memberShipData, setMemberShipData] = useState(data);
  const [refundData, setRefundData] = useState(null);
  const [holdingCancelData, setHoldingCancelData] = useState(undefined);
  const [holdingDeleteData, setHoldingDeleteData] = useState(undefined);

  const [productName, setProductName] = useState('');
  const [productCoach, setProductCoach] = useState('');
  const [productValidity, setProductValidity] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productMerchantUid, setProductMerchantUid] = useState('');

  const [isClickedPayment, setIsClickedPayment] = useState(false);
  const [isClickedAddMemberShip, setIsClickedAddMemberShip] = useState(false);
  const [isClickedModify, setIsClickedModify] = useState(false);
  const [isClickedUnSupported, setIsClickedUnSupported] = useState(false);
  const [isClickedHolding, setIsClickedHolding] = useState(false);
  const [isClickedRefund, setIsClickedRefund] = useState(false);
  const [isClickedExtension, setIsClickedExtension] = useState(false);
  const [isClickedDelete, setIsClickedDelete] = useState(false);
  const [isClickedHoldingCancel, setIsClickedHoldingCancel] = useState(false);
  const [isClickedHoldingDelete, setIsClickedHoldingDelete] = useState(false);

  const [firstMemberShip, setFirstMemberShip] = useState(true);
  const [firstCourse, setFirstCourse] = useState(true);
  const [nowSlideMemberShip, setNowSlideMemberShip] = useState(1);

  const [isExtend, setIsExtend] = useState(false);

  const MemberShipAddModalRef = useRef();
  const AddButtonRef = useRef();
  const MemberShipRef = useRef();
  const CourseRef = useRef();
  const CoachRef = useRef();
  const LockerRef = useRef();
  const HoldingRef = useRef();
  const PaginationRef = useRef();
  const HistoryButtonRef = useRef();

  useImperativeHandle(ref, () => ({
    setMemberShipData: (val) => {
      setMemberShipData(val);
    },
    setIsClicked: () => {
      HistoryButtonRef.current.setIsClicked();
    },
    getButtonRef: () => {
      return HistoryButtonRef.current.getButtonRef();
    },
    getBoxRef: () => {
      return HistoryButtonRef.current.getBoxRef();
    }
  }))

  useEffect(() => {
    if (division === 'locker') setNowPage('Locker');
    else if (division === 'holding') setNowPage('Holding');
    else if (division === 'course') setNowPage('Course');
  }, [division])

  useEffect(() => {
    if (nowPage === 'MemberShip') {
      setButtonText('회원권 추가');

      AddButtonRef.current.style.opacity = 1;
      AddButtonRef.current.style.zIndex = 1;

      // MemberShipRef.current.style.opacity = 1;
      // MemberShipRef.current.style.zIndex = 1;

      // CourseRef.current.style.opacity = 0;
      // CourseRef.current.style.zIndex = -1;

      // CoachRef.current.style.opacity = 0;
      // CoachRef.current.style.zIndex = -1;

      // LockerRef.current.style.opacity = 0;
      // LockerRef.current.style.zIndex = -1;

      // HoldingRef.current.style.opacity = 0;
      // HoldingRef.current.style.zIndex = -1;

      PaginationRef.current.style.opacity = 1;
      PaginationRef.current.style.zIndex = 1;
    }
    else if (nowPage === 'Course') {
      AddButtonRef.current.style.opacity = 0;
      AddButtonRef.current.style.zIndex = -1;

      // MemberShipRef.current.style.opacity = 0;
      // MemberShipRef.current.style.zIndex = -1;

      // CourseRef.current.style.opacity = 1;
      // CourseRef.current.style.zIndex = 1;

      // CoachRef.current.style.opacity = 0;
      // CoachRef.current.style.zIndex = -1;

      // LockerRef.current.style.opacity = 0;
      // LockerRef.current.style.zIndex = -1;

      // HoldingRef.current.style.opacity = 0;
      // HoldingRef.current.style.zIndex = -1;

      PaginationRef.current.style.opacity = 1;
      PaginationRef.current.style.zIndex = 1;
    }
    else if (nowPage === 'Coach') {
      AddButtonRef.current.style.opacity = 0;
      AddButtonRef.current.style.zIndex = -1;

      // MemberShipRef.current.style.opacity = 0;
      // MemberShipRef.current.style.zIndex = -1;

      // CourseRef.current.style.opacity = 0;
      // CourseRef.current.style.zIndex = -1;

      // CoachRef.current.style.opacity = 1;
      // CoachRef.current.style.zIndex = 1;

      // LockerRef.current.style.opacity = 0;
      // LockerRef.current.style.zIndex = -1;

      // HoldingRef.current.style.opacity = 0;
      // HoldingRef.current.style.zIndex = -1;

      PaginationRef.current.style.opacity = 0;
      PaginationRef.current.style.zIndex = -1;
    }
    else if (nowPage === 'Locker') {
      setButtonText('락커 추가');

      // AddButtonRef.current.style.opacity = 1;
      // AddButtonRef.current.style.zIndex = 1;

      AddButtonRef.current.style.opacity = -1;
      AddButtonRef.current.style.zIndex = -1;

      // MemberShipRef.current.style.opacity = 0;
      // MemberShipRef.current.style.zIndex = -1;

      // CourseRef.current.style.opacity = 0;
      // CourseRef.current.style.zIndex = -1;

      // CoachRef.current.style.opacity = 0;
      // CoachRef.current.style.zIndex = -1;

      // LockerRef.current.style.opacity = 1;
      // LockerRef.current.style.zIndex = 1;

      // HoldingRef.current.style.opacity = 0;
      // HoldingRef.current.style.zIndex = -1;

      PaginationRef.current.style.opacity = 1;
      PaginationRef.current.style.zIndex = 1;
    }
    else if (nowPage === 'Holding') {

      AddButtonRef.current.style.opacity = -1;
      AddButtonRef.current.style.zIndex = -1;

      // MemberShipRef.current.style.opacity = 0;
      // MemberShipRef.current.style.zIndex = -1;

      // CourseRef.current.style.opacity = 0;
      // CourseRef.current.style.zIndex = -1;

      // CoachRef.current.style.opacity = 0;
      // CoachRef.current.style.zIndex = -1;

      // LockerRef.current.style.opacity = 0;
      // LockerRef.current.style.zIndex = -1;

      // HoldingRef.current.style.opacity = 1;
      // HoldingRef.current.style.zIndex = 1;

      PaginationRef.current.style.opacity = 1;
      PaginationRef.current.style.zIndex = 1;
    }
  }, [nowPage])

  // 회원권 수정 버튼 클릭시
  function memberShipModifyClick(index) {
    if (isOver) setIsClickedModify(!isClickedModify);
    else setIsClickedUnSupported(true);
    setIndex(index);
  }

  // 회원권 홀딩 버튼 클릭시
  function memberShipHoldingClick(index) {
    setIsClickedHolding(!isClickedHolding);
    setIndex(index);
  }

  // 회원권 연장 버튼 클릭시
  function memberShipExtensionClick(index) {
    setIsClickedExtension(!isClickedExtension);
    setIndex(index);
  }

  // 회원권 삭제 버튼 클릭시
  function memberShipExtensionClick(index) {
    // setIsClickedExtension(!isClickedExtension);
    setIsClickedAddMemberShip(true);
    setIsExtend(true);
    setIndex(index);
  }

  // 회원권 삭제 버튼 클릭시
  function memberShipDeleteClick(index) {
    setIsClickedDelete(!isClickedDelete);
    setIndex(index);
  }

  // 회원권 홀딩 내역 삭제 버튼 클릭시 //
  function onClickDeleteHoldingHistory(data) {
    setHoldingDeleteData(data);
    setIsClickedHoldingDelete(true);
  }

  // 홀딩 삭제를 진행하지 않을경우 //
  function onClickHoldingDeleteExit() {
    setHoldingDeleteData(undefined);
    setIsClickedHoldingDelete(false);
  }


  // 모달창에서 닫기 버튼 클릭시
  function exitClick(className) {
    if (className === 'Modify') setIsClickedModify(false);
    else if (className === 'Holding') setIsClickedHolding(false);
    else if (className === 'Extension') setIsClickedExtension(false);
    else if (className === 'Delete') setIsClickedDelete(false);

    HistoryButtonRef.current.setIsClicked();
    setIndex(-1);
    rendering();
  }

  // 회원권 추가 버튼 클릭
  function onClickAddMemberShipOrLockerButton(className) {
    if (!isOver) {
      setIsClickedUnSupported(true);
    }

    if (className === '회원권 추가') {
      // console.log(className);
      setIsClickedAddMemberShip(true);
    }
    else if (className === '락커 추가') {
      // console.log(className);
    }
  }

  // 홀딩 취소버튼 클릭
  function onClickHoldingCancel(data) {

    if (moment(data.endDate).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD')) {
      setHoldingCancelData(data);
      setIsClickedHoldingCancel(true);
    }
    else {
      alert("이미 종료된 홀딩 정보입니다!");
    }
  }

  // 홀딩 취소를 진행하지 않을경우
  function onClickHoldingCancelExit() {
    setHoldingCancelData(undefined);
    setIsClickedHoldingCancel(false);
  }

  // 회원권 홀딩 내역 삭제 버튼 클릭시 //
  function onClickDeleteHoldingHistory(data) {
    setHoldingDeleteData(data);
    setIsClickedHoldingDelete(true);
  }

  // 홀딩 내역 삭제를 진행하지 않을경우 //
  function onClickHoldingDeleteExit() {
    setHoldingDeleteData(undefined);
    setIsClickedHoldingDelete(false);
  }

  // 회원권 환불 버튼 클릭
  function onClickRefundButton(index) {
    var merchantList = data[index].merchantUids.split(',');
    var lastMerchantUid = merchantList[merchantList.length - 1];

    setRefundData({ merchantUid: lastMerchantUid });
    setIsClickedRefund(true);
  }

  function onRegist() {
    // cancelFunction();
    var memberShipData = MemberShipAddModalRef.current.getMemberShipData();
    var cardPrice = MemberShipAddModalRef.current.getCardPrice();
    var cashPrice = MemberShipAddModalRef.current.getCashPrice();

    if (memberShipData.name === '선택') {
      alert('회원권을 설정해주세요');
      return;
    }
    else if (cardPrice + cashPrice === 0) {
      alert('결제 금액을 설정해주세요');
      return;
    }

    var requestData = {
      itemName: memberShipData.name,
      cashPrice: cashPrice,
      cardPrice: cardPrice,
      // storeId: SessionStorage.getStoreId(),
      storeId: window.localStorage.getItem('StoreId'),
      memberId: parseInt(memberId),
      status: 0
    }
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('sales/merchant', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          setProductName(responseData.data[0].itemName);
          setProductCoach('');
          setProductValidity(memberShipData.validity);
          setProductPrice(responseData.data[0].cardPrice);
          setProductMerchantUid(responseData.data[0].merchantUid);

          setIsClickedPayment(true);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();
  }

  function cancelFunction(merchantUid) {

    // var storeId = SessionStorage.getStoreId();
    var storeId = window.localStorage.getItem('StoreId');
    var memberShipId = MemberShipAddModalRef.current.getMemberShipId();
    var cardPrice = MemberShipAddModalRef.current.getCardPrice();
    var cashPrice = MemberShipAddModalRef.current.getCashPrice();
    var startDate = MemberShipAddModalRef.current.getStartDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss');
    var endDate = MemberShipAddModalRef.current.getEndDate().replaceAll('.', '-') + 'T23:59:59';
    var approvalDate = MemberShipAddModalRef.current.getApprovalDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss');

    var data = {
      storeId: parseInt(storeId),
      memberId: parseInt(memberId),
      memberShipId: memberShipId,
      merchantUid: merchantUid,
      courseTakeCount: 0,
      cardPrice: cardPrice,
      cashPrice: cashPrice,
      startDate: startDate,
      endDate: endDate,
      approvalDate: approvalDate
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('membership/usage', 'POST', data);

        if (responseData.status === 200) {
          alert("등록이 완료되었습니다.");
          history.push('/main');
        }
        else if (responseData.status === 401) {
          alert("로그인 후 사용해주세요.");
          history.push('/');
        }
      }
    )();

    setIsClickedPayment(false);
    setIsExtend(false);
  }

  function saveRegistData(merchantUid) {
    // var storeId = SessionStorage.getStoreId();
    var storeId = window.localStorage.getItem('StoreId');
    var memberShipId = MemberShipAddModalRef.current.getMemberShipId();
    var cardPrice = MemberShipAddModalRef.current.getCardPrice();
    var cashPrice = MemberShipAddModalRef.current.getCashPrice();
    var startDate = MemberShipAddModalRef.current.getStartDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss');
    var endDate = MemberShipAddModalRef.current.getEndDate().replaceAll('.', '-') + 'T23:59:59';
    var approvalDate = MemberShipAddModalRef.current.getApprovalDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss');

    var data = {
      storeId: parseInt(storeId),
      memberId: parseInt(memberId),
      memberShipId: memberShipId,
      merchantUid: merchantUid,
      courseTakeCount: 0,
      cardPrice: cardPrice,
      cashPrice: cashPrice,
      startDate: startDate,
      endDate: endDate,
      approvalDate: approvalDate
    }

    window.sessionStorage.setItem("RegistData", 'None');
    window.sessionStorage.setItem("UsageData", JSON.stringify(data));
  }


  return (
    <TopLevelWrapper isOver={isOver}>
      {
        isClickedPayment &&
        <PaymentModal
          name={productName}
          coach={productCoach}
          validity={productValidity}
          price={productPrice}
          merchantUid={productMerchantUid}
          onRegist={cancelFunction}
          cancel={() => setIsClickedPayment(false)}
          saveRegistData={saveRegistData} />
      }
      {isClickedAddMemberShip && <MemberShipAddModal ref={MemberShipAddModalRef} isExtend={isExtend} data={memberShipData} index={index} registerClick={onRegist} cancel={() => setIsClickedAddMemberShip(false)} />}
      {isClickedModify && <MemberShipEditModal className='Modify' data={memberShipData} index={index} isOver={isOver} exitClickFn={exitClick} />}
      {isClickedHolding && <MemberShipHoldingModal className='Holding' data={memberShipData} index={index} isOver={isOver} exitClickFn={exitClick} />}
      {/* { isClickedExtension && <MemberShipExtendModal className='Extension' data={memberShipData} index={index} isOver={isOver} exitClickFn={exitClick} />} */}
      {isClickedRefund && <RefundModal refundData={refundData} rendering={rendering} exitClick={() => setIsClickedRefund(false)} />}
      {isClickedDelete && <MemberShipDeleteModal className='Delete' data={memberShipData} index={index} isOver={isOver} exitClickFn={exitClick} rendering={rendering} />}
      {isClickedUnSupported && <UnSupportedDeviceModal isModal={true} closeFn={() => setIsClickedUnSupported(false)} />}
      {isClickedHoldingCancel && <HoldingCancelModal isOver={isOver} data={holdingCancelData} exitClickFn={onClickHoldingCancelExit} rendering={rendering} />}
      {isClickedHoldingDelete && <HoldingDeleteModal isOver={isOver} data={holdingDeleteData} exitClickFn={onClickHoldingDeleteExit} rendering={rendering} />}
      <ButtonWrapper isOver={isOver}>
        <SortButtonWrapper isOver={isOver}>
          <Button className='MemberShip' nowPage={nowPage} isOver={isOver} width={isOver ? `64px` : `40px`} onClick={() => setNowPage('MemberShip')}>회원권</Button>
          <Button className='Holding' nowPage={nowPage} isOver={isOver} width={isOver ? `75px` : `34px`} onClick={() => setNowPage('Holding')}>{isOver ? `홀딩내역` : `홀딩`}</Button>
          <Button className='Course' nowPage={nowPage} isOver={isOver} width={isOver ? `75px` : `57px`} onClick={() => setNowPage('Course')}>수강내역</Button>
          <Button className='Coach' nowPage={nowPage} isOver={isOver} width={isOver ? `75px` : `55px`} onClick={() => setNowPage('Coach')}>링크코치</Button>
          <Button className='Locker' nowPage={nowPage} isOver={isOver} width={isOver ? `53px` : `34px`} onClick={() => setNowPage('Locker')}>락커</Button>
        </SortButtonWrapper>
        <CreateButton ref={AddButtonRef} img={ButtonImagePath} isOver={isOver} onClick={() => onClickAddMemberShipOrLockerButton(buttonText)}>{buttonText}</CreateButton>
      </ButtonWrapper>
      {
        nowPage === 'MemberShip' &&
        <InfoBox ref={MemberShipRef}>
          {
            isOver ?
              <InfoWrapper isOver={isOver}>
                {
                  firstMemberShip ?
                    <FirstMemberShipHistoryColumn isOver={isOver} nextFn={() => setFirstMemberShip(false)} />
                    :
                    <SecondMemberShipHistoryColumn isOver={isOver} prevFn={() => setFirstMemberShip(true)} />
                }
                {
                  memberShipData.map((data, index) => (
                    firstMemberShip ?
                      <FirstMemberShipHistoryInfoView
                        key={index}
                        ref={HistoryButtonRef}
                        data={data}
                        index={index}
                        isOver={isOver}
                        modifyClick={memberShipModifyClick}
                        holdingClick={memberShipHoldingClick}
                        extensionClick={memberShipExtensionClick}
                        refundClick={onClickRefundButton}
                        deleteClick={memberShipDeleteClick} />
                      :
                      <SecondMemberShipHistoryInfoView
                        key={index}
                        ref={HistoryButtonRef}
                        data={data}
                        index={index}
                        isOver={isOver}
                        modifyClick={memberShipModifyClick}
                        holdingClick={memberShipHoldingClick}
                        extensionClick={memberShipExtensionClick}
                        refundClick={onClickRefundButton}
                        deleteClick={memberShipDeleteClick} />
                  ))
                }
              </InfoWrapper>
              :
              <InfoWrapper isOver={isOver}>
                {nowSlideMemberShip === 1 && <FirstMemberShipHistoryColumn isOver={isOver} nextFn={() => setNowSlideMemberShip(2)} />}
                {nowSlideMemberShip === 2 && <SecondMemberShipHistoryColumn isOver={isOver} prevFn={() => setNowSlideMemberShip(1)} nextFn={() => setNowSlideMemberShip(3)} />}
                {nowSlideMemberShip === 3 && <ThirdMemberShipHistoryColumn isOver={isOver} prevFn={() => setNowSlideMemberShip(2)} />}
                {
                  nowSlideMemberShip === 1 &&
                  memberShipData.map((data, index) => (
                    <FirstMemberShipHistoryInfoView
                      key={index}
                      ref={HistoryButtonRef}
                      data={data}
                      index={index}
                      isOver={isOver}
                      modifyClick={memberShipModifyClick}
                      holdingClick={memberShipHoldingClick}
                      extensionClick={memberShipExtensionClick}
                      refundClick={onClickRefundButton}
                      deleteClick={memberShipDeleteClick} />
                  ))
                }
                {
                  nowSlideMemberShip === 2 &&
                  memberShipData.map((data, index) => (
                    <SecondMemberShipHistoryInfoView
                      key={index}
                      ref={HistoryButtonRef}
                      data={data}
                      index={index}
                      isOver={isOver}
                      modifyClick={memberShipModifyClick}
                      holdingClick={memberShipHoldingClick}
                      extensionClick={memberShipExtensionClick}
                      refundClick={onClickRefundButton}
                      deleteClick={memberShipDeleteClick} />
                  ))
                }
                {
                  nowSlideMemberShip === 3 &&
                  memberShipData.map((data, index) => (
                    <ThirdMemberShipHistoryInfoView
                      key={index}
                      ref={HistoryButtonRef}
                      data={data}
                      index={index}
                      isOver={isOver}
                      modifyClick={memberShipModifyClick}
                      holdingClick={memberShipHoldingClick}
                      extensionClick={memberShipExtensionClick}
                      refundClick={onClickRefundButton}
                      deleteClick={memberShipDeleteClick} />
                  ))
                }
              </InfoWrapper>
          }
        </InfoBox>
      }
      {
        nowPage === 'Course' &&
        <InfoBox ref={CourseRef}>
          <InfoWrapper isOver={isOver}>
            {
              firstCourse ?
                <FirstCourseHistoryColumnView isOver={isOver} nextFn={() => setFirstCourse(false)} />
                :
                <SecondCourseHistoryColumnView isOver={isOver} prevFn={() => setFirstCourse(true)} />
            }
            {
              firstCourse ?
                lessonHistoryData.map((data, index) => (
                  <FirstCourseHistoryInfoView isOver={isOver} key={index} data={data} reserveRerendering={reserveRerendering} />
                ))
                :
                lessonHistoryData.map((data, index) => (
                  <SecondCourseHistoryInfoView isOver={isOver} key={index} data={data} reserveRerendering={reserveRerendering} />
                ))
            }
          </InfoWrapper>
        </InfoBox>
      }
      {
        nowPage === 'Locker' &&

        <InfoBox ref={LockerRef}>
          <LockerHistoryColumnView isOver={isOver} />
          {/* <LockerHistoryInfoView isOver={isOver}/> */}
          {
            lockerData.map((data, index) => (
              <LockerHistoryInfoView isOver={isOver} key={index} data={data} />
            ))
          }
        </InfoBox>
      }
      {
        nowPage === 'Coach' &&
        <InfoBox ref={CoachRef}>
          <CoachHistoryView physicalData={physicalData} isOver={isOver} />
        </InfoBox>
      }
      {
        nowPage === 'Holding' &&
        <InfoBox ref={HoldingRef}>
          <HoldingHistoryColumn isOver={isOver}/>
          {
            holdingData.map((data, index) => (
              <HoldingHistoryInfoView isOver={isOver} key={index} data={data} onClickHoldingCancel={onClickHoldingCancel} onClickDeleteHoldingHistory={onClickDeleteHoldingHistory}/>
            ))
          }
        </InfoBox>
      }
      <PaginationWrapper ref={PaginationRef}>
        <InfoPagination />
      </PaginationWrapper>
    </TopLevelWrapper>
  )
})

export default HistoryView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: ${props => props.isOver ? `32px` : `14px`};
  margin-bottom: 96px;

  transition: all 0.3s ease-in-out;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(300px, 93.75vw)`};
`;

const SortButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: ${props => props.isOver ? `323px` : `222px`};
  width: ${props => props.isOver ? `416px` : `242px`};
`;

const Button = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: ${props => props.width};
  height: ${props => props.isOver ? `38px` : `28px`};

  border: ${props => props.nowPage === props.className ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: ${props => props.isOver ? `15px` : `8px`};
  background-color: ${props => props.nowPage === props.className ? `#FF8B48` : `#FFFFFF`};
 
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: ${props => props.nowPage === props.className ? `#FFFFFF` : `#777777`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Medium;
    color: #FFFFFF;
    background-color: #FF8B48;
    border: 1px solid #FF8B48;
  }
`;

const CreateButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `90px` : `60px`};
  height: ${props => props.isOver ? `40px` : `30px`};

  border-radius: ${props => props.isOver ? `15px` : `8px`};

  padding: ${props => props.isOver ? `0 0 0 20px` : `0 0 0 0`};
  background: ${props => props.isOver ? `url(${props.img}) no-repeat 16px center` : ``};
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  align-items: center;
  
  min-height: 333px;

  gap: 8px;

  transition: all 0.3s ease-in-out;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: ${props => props.isOver ? `flex-start` : `center`};
  align-items: ${props => props.isOver ? `flex-start` : `center`};

  gap: 8px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 44px;
`;