import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import lodash from 'lodash';

import TextArea from '../../../Component/BoardComponent/NoticeTextArea';

import FetchModule from '../../Share/Network/FetchModule';

const FullNoticeView = (props) => {
  const { useData, clickDate, rendering } = props;

  const history = useHistory();

  const [index, setIndex] = useState(0);
  const [isClicked, setIsClicked] = useState(false);

  const TextRef = useRef();

  useEffect(() => {
    for (var i = 0; i < useData.length; i++) {
      if (useData[i].classDate.format('YYYY.MM.DD') === clickDate) {
        setIndex(i);
        break;
      }
    }
    setIsClicked(false);
  }, [clickDate, useData])

  function modifyButtonClick() {
    if (isClicked) {
      
      var data = lodash.cloneDeep(useData[index].notice);

      if (data.storeId === undefined) {
        alert('운동을 먼저 등록해주세요!');
        setIsClicked(!isClicked);
        return;
      }

      data.text = TextRef.current.getValue();

      if (data.text === undefined) data.text = '';

      (
        async () => {
          const fetchModule = new FetchModule();
          const responseData = await fetchModule.patchData('workout/notice/', 'PATCH', data);
          
          if (responseData.status === 200) {
            console.log(responseData);
            rendering();
          }
          else if (responseData.status === 401) history.push('/');
          else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
        }
      )();
    }

    setIsClicked(!isClicked);
  }

  return (
    <TopLevelWrapper>
      <ViewWrapper>
        <TopLineWrapper>
          <Title>메모</Title>
          <ModifyButton isClicked={isClicked} onClick={modifyButtonClick}>{isClicked ? '완료' : '수정'}</ModifyButton>
        </TopLineWrapper>
        {
          isClicked ?
          <TextSection>
            <TextArea ref={TextRef} defaultValue={useData[index].notice.text}/>
          </TextSection>
          :
          <TextSection>
            <Text>{useData[index].notice.text}</Text>
          </TextSection>
        }
      </ViewWrapper>
    </TopLevelWrapper>
  )
}

export default FullNoticeView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 378px;
  width: 19.6875vw;
  // height: 468px;
  height: 24.375vw;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  // width: 324px;
  width: 16.875vw;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  margin: 0 0 0 0;

  color: #232323;
`;

const ModifyButton = styled.p`
  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  margin: 0 0 0 0;

  color: ${props => props.isClicked ? `#777777` : `#5BD98A`};

  :hover {
    cursor: pointer;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;

  // gap: 5px;
  gap: 0.2604166666666667vw;
  // margin: 8px 0 8px 16px;
  // margin-top: 8px;
  margin-top: 0.4166666666666667vw;
  // padding: 8px 16px 0 16px;
  padding-top: 0.4166666666666667vw;
  padding-right: 0.8333333333333333vw;
  padding-bottom: 0px;
  padding-left: 0.8333333333333333vw;

  // width: 298px;
  width: 15.52083333333333vw;
  // height: 374px;
  height: 19.47916666666667vw;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  overflow-y: scroll;

  &::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const Text = styled.pre`
  font-family: NotoSansKR-Regular;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #595959;

  margin: 0 0 0 0;
`;
