import { React, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import MemberShipInfoEditView from './MemberShipInfoEditView.js';
import FetchModule from '../../../../Share/Network/FetchModule.js';

const MemberShipEditModal = (props) => {
  const { className, exitClickFn, data, index, isOver } = props;

  const history = useHistory();

  const EditRef = useRef();

  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, []);

  function exitClick() {
    exitClickFn(className);
  }

  function saveClick() {
    var id = data[index].usageId;
    // var storeId = window.sessionStorage.getItem('StoreId');
    var storeId = window.localStorage.getItem('StoreId');
    var memberId = data[index].memberId;
    var memberShipId = EditRef.current.getMemberShipId();
    var startDate = EditRef.current.getStartDate();
    var endDate = EditRef.current.getEndDate();
    var approvalDate = EditRef.current.getApprovalDate();
    var cashPrice = EditRef.current.getCashPrice();
    var cardPrice = EditRef.current.getCardPrice();

    var requestData = {
      id: id,
      storeId: storeId,
      memberId: memberId,
      memberShipId: memberShipId,
      startDate: startDate.replaceAll('.', '-') + 'T00:00:00',
      endDate: endDate.replaceAll('.', '-') + 'T00:00:00',
      approvalDate: approvalDate.replaceAll('.', '-') + 'T00:00:00',
      cashPrice: cashPrice,
      cardPrice: cardPrice
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.patchData('membership/usage/modify', 'PATCH', requestData);
       
        if (responseData.status === 200) {
          alert('저장이 완료되었습니다.');
          exitClickFn(className);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
        else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();
  }
  
  return (
    <TopLevelWrapper>
      <ModalView isOver={isOver}>
        <MemberShipInfoEditView ref={EditRef} data={data} index={index}/>
        <ButtonWrapper>
          <ExitButton onClick={exitClick}>취소</ExitButton>
          <SaveButton onClick={saveClick}>저장하기</SaveButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default MemberShipEditModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;
  
  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);

	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `290px`};
  height: ${props => props.isOver ? `573px` : `665px`};

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 16px;

  margin: 32px 0 28px max(474px, 61.71875vw);
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;