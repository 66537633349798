import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import MemberReservationModal from '../../Model/Individual/ClassManageModel/ClassManageModal/MemberReservationModal';
import Image from '../Share/Image';

import DefaultLogoPath from '../../image/DefaultLogo.png';

function ClassInfoBox(props) {
  const { data, isOver, reserveData, reserveRendering, setReserveRendering } = props;

  const [isClickedReserve, setIsClickedReserve] = useState(false);

  const InfoBoxRef = useRef();

	function onMouseOver() {
		if (data.classId === -1) return;

		InfoBoxRef.current.style.border = `1px solid ` + data.color;
	}

	function onMouseLeave() {
		if (data.classId === -1) return;
		
		InfoBoxRef.current.style.border = `1px solid #ECECEC`;
	}


  return (
    <TopLevelWrapper /*onClick={() => (!isClickedReserve && setIsClickedReserve(true))}*/ onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      { isClickedReserve && <MemberReservationModal isOver={isOver} data={reserveData} scheduleData={data} reserveDataReLoad={reserveRendering} setReserveDataReLoad={setReserveRendering} cancel={() => setIsClickedReserve(false)}/> }
      <InfoBox ref={InfoBoxRef} isOver={isOver}>
        <ClassNameWrapper>
          <ClassName isOver={isOver}>{data.className}</ClassName>
        </ClassNameWrapper>
        {
          data.classId === -1 ?
            <InfoWrapper>
              <TimeWrapper>
                <Time isOver={isOver}>{data.startTime} ~ {data.endTime}</Time>
              </TimeWrapper>
            </InfoWrapper>
            :
            <InfoWrapper>
              <TimeWrapper>
                <Time isOver={isOver}>{data.startTime} ~ {data.endTime}</Time>
                <TimeDivision />
                <Number isOver={isOver}>예약 {reserveData && reserveData.length}명</Number>
              </TimeWrapper>
              <TrainerWrapper isOver={isOver}>
                {/* <TrainerImage src={data.profile} isOver={isOver} /> */}
                <TrainerImage isOver={isOver}>
                  <Image src={data.profile} defaultSrc={DefaultLogoPath}/>
                </TrainerImage>
                <TrainerName isOver={isOver}>{data.teacherName}</TrainerName>
              </TrainerWrapper>
            </InfoWrapper>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default ClassInfoBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 0 0 0;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;

  // width: ${props => props.isOver ? `max(555px, 72.265625vw)` : `max(200px, 62.5vw)`};
  width: ${props => props.isOver ? `max(520px, 67.7083333vw)` : `max(200px, 62.5vw)`};
  height: ${props => props.isOver ? `66px` : `64px`};

  border: 1px solid #ECECEC;
  border-radius: 15px;

  padding-left: ${props => props.isOver ? `20px` : `10px`};
  margin-bottom: ${props => props.isOver ? `12px` : `14px`};
`;

const ClassNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ClassName = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isOver ? `row` : `column`};

  justify-content: space-between;
  align-items: ${props => props.isOver ? `center` : `flex-start`};
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Time = styled.p`
  font-family: Poppins-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: 0 8px 0 0;
`;

const TimeDivision = styled.div`
  height: 10px;
  border-right: 1px solid #969696;
`;

const Number = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #777777;

  margin: -3px 0 0 8px;
`;

const TrainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;

  // margin-left: 148px;
  margin-right: ${props => props.isOver ? `16px` : `0px`};
  margin-left: ${props => props.isOver ? `0px` : `109px`};
`;

const TrainerImage = styled.div`
  width: ${props => props.isOver ? `20px` : `10px`};
  height: ${props => props.isOver ? `20px` : `10px`};

  border-radius: ${props => props.isOver ? `20px` : `10px`};
  
  margin-right: 8px;

  object-fit: cover;

  overflow: hidden;
`;

const TrainerName = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;