import { React, useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../../Share/Network/FetchModule';

import NoUserPhotoImagePath from '../../../../image/NoUserPhoto.png';
import PencelImg from '../../../../image/Pencel.png';
import DefaultProfile from '../../../../image/DefaultProfile.png';

import TextArea from '../../../../Component/MemberComponent/MemberDetail/TextArea';
import UserNameModifyModal from './Modal/UserNameModifyModal';

const IndividualInfo = forwardRef((props, ref) => {
  const { isOver, memberData, rendering } = props;

  const history = useHistory();

  const [defaultVal, setDefaultVal] = useState(memberData);
  const [isFocused, setIsFocused] = useState(false);
  const [memberDetail, setMemberDetail] = useState(memberData.memberDetail);
  const [originalDetail, setOriginalDetail] = useState(undefined);
  const [isClickedModifyUserName, setIsClickedModifyUserName] = useState(false);


  useImperativeHandle(ref, () => ({
    setMemberData: (val) => {
      setDefaultVal(val);
    }
  }));

  useEffect(() => {
    setMemberDetail(memberData.memberDetail);
  }, [memberData])

  useEffect(() => {
    if (isFocused) setOriginalDetail(memberDetail);
  }, [isFocused, memberDetail])


  function onClickMemberInfoDelete() {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('member/delete/' + memberData.memberId, 'POST');
        // console.log(responseData);
				if (responseData.status === 200) {
          const coachResponseData = await fetchModule.postDataOfCoach('registration/zone/delete/' + memberData.storeId + '/' + memberData.linkCoachId, 'POST');
          // console.log(coachResponseData);
          if (coachResponseData.status === 200) {
            alert("회원 정보가 삭제되었습니다.");
            history.push('/member/valid');
          }
				}
        else if (responseData.status === 201) {
          alert("유효한 회원정보가 아닙니다.");
          history.push('/member/valid');
        }
        else if (responseData.status === 202) {
          alert("현재 유효한 회원권이 존재합니다.\n삭제후 다시시도해 주세요.");
        }
        else if (responseData.status === 204) {
          alert("회원정보가 존재하지 않습니다.");
          history.push('/member/valid');
        }
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  } 
  
  // 취소버튼 누를시
  function onClickExit() {
    setMemberDetail(originalDetail);
    setIsFocused(false);
  }

  // 수정버튼 클릭시
  function onClickModity() {
    var data = {
      storeId: window.localStorage.getItem('StoreId'),
      memberId: memberData.memberId,
      memberDetail: memberDetail
    }
    // console.log(data);
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('member/modify/member_detail', 'POST', data);
        // console.log(responseData);
				if (responseData.status === 200) {
          alert('정보수정을 완료하였습니다.');
          setIsFocused(false);
          rendering();
				}
        else if (responseData.status === 400) {
          alert("회원 정보 수정의 권한이 없습니다.");
          history.push('/member/valid');
        }
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }

  // 변경된 이름 저장하기 클릭시 //
  function onClickSave(changeName, changeDate, changeGender) {
    var data = {
      memberId: memberData.memberId,
      userName: changeName,
      birthDay: changeDate,
      gender: changeGender
    }
    
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('member/modify/username', 'POST', data);
        // console.log(responseData);
				if (responseData.status === 200) {
          alert('정보수정을 완료하였습니다.');
          setIsClickedModifyUserName(false);
          rendering();
				}
        else if (responseData.status === 201) {
          alert("회원 정보 수정의 권한이 없습니다.");
          history.push('/member/valid');
        }
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }

  return (
    <TopLevelWrapper isOver={isOver}>
      { isClickedModifyUserName && <UserNameModifyModal userName={defaultVal.name} birthDay={defaultVal.birthDay} gender={defaultVal.gender} onClickSave={onClickSave} onClickClose={() => setIsClickedModifyUserName(false)}/> }
      <TotalWrapper isOver={isOver}>
        <UserPhotoWrapper isOver={isOver}>
          <UserPhoto src={defaultVal.profile === '' ? DefaultProfile : defaultVal.profile} isOver={isOver}/>
        </UserPhotoWrapper>
        <UserInfoView isOver={isOver}>
          <RowLayerWrapper style={isOver ? {justifyContent: `space-between`, width: `max(391px, 50.91145833333333vw)`} : {justifyContent: `space-between`, width: `max(178px, 55.625vw)`}}>
            <UserNameWrapper>
              <UserName isOver={isOver}>{defaultVal.name}</UserName>
              <Explanation style={isOver ? { fontSize: '16px'/*, marginRight: '232px'*/ } : { fontSize: '10px'/*, marginRight: '68px' */}} isOver={isOver}>회원</Explanation>
              <NameModifyButton isOver={isOver} onClick={() => setIsClickedModifyUserName(true)}>
                <Pencel src={PencelImg} />
              </NameModifyButton>
            </UserNameWrapper>
              { isFocused && <ExitButton isOver={isOver} onClick={() => onClickExit()}>취소</ExitButton> }
              { isFocused && <ModifyButton isOver={isOver} onClick={() => onClickModity()}>수정</ModifyButton> }
            <DeleteButton isOver={isOver}  onClick={onClickMemberInfoDelete}>회원정보 삭제</DeleteButton>
          </RowLayerWrapper>
          <RowLayerWrapper style={isOver ? { marginTop: '18px' } : { marginTop: '1px'}}>
            <ColumnLayerWrapper isOver={isOver}>
              <RowLayerWrapper>
                <Explanation style={{ marginRight: '48px' }} isOver={isOver}>성별</Explanation>
                <UserInfoText isOver={isOver}>{defaultVal.gender === 'male' ? '남성' : '여성'}</UserInfoText>
              </RowLayerWrapper>
              <RowLayerWrapper>
                <Explanation style={{ marginRight: '22px' }} isOver={isOver}>생년월일</Explanation>
                <UserInfoText isOver={isOver}>{defaultVal.birthDay}</UserInfoText>
              </RowLayerWrapper>
              <RowLayerWrapper>
                <Explanation style={{ marginRight: '22px' }} isOver={isOver}>전화번호</Explanation>
                <UserInfoText isOver={isOver}>{defaultVal.phone}</UserInfoText>
              </RowLayerWrapper>
              <RowLayerWrapper>
                <Explanation style={{ marginRight: '35px' }} isOver={isOver}>아이디</Explanation>
                <UserInfoText isOver={isOver}>{defaultVal.linkCoachId}</UserInfoText>
              </RowLayerWrapper>
              <RowLayerWrapper>
                <Explanation style={{ marginRight: '48px' }} isOver={isOver}>락커</Explanation>
                <UserInfoText isOver={isOver}>-</UserInfoText>
              </RowLayerWrapper>
            </ColumnLayerWrapper>
          </RowLayerWrapper>
        </UserInfoView>
      </TotalWrapper>
      <MemberNoticeBox isOver={isOver}>
        <MemberNoticeWrapper isOver={isOver}>
          <MemberNoticeTitle isOver={isOver}>회원별 기록사항</MemberNoticeTitle>
          <MemberNoticeWindow isOver={isOver}>
            <TextArea value={memberDetail} setValue={setMemberDetail} setIsFocused={setIsFocused} placeholder='해당 회원에 대해 기록해야 할 사항들을 입력해주세요' />
          </MemberNoticeWindow>
        </MemberNoticeWrapper>
      </MemberNoticeBox>
    </TopLevelWrapper>
  )
})

export default IndividualInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `max(16px, 2.083333333333333vw)` : `11px`};

  transition: all 0.3s ease-in-out;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.isOver ? `max(16px, 2.083333333333333vw)` : `8px`};

  transition: all 0.3s ease-in-out;
`;

const UserPhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `227px` : `87px`};
  height: ${props => props.isOver ? `227px` : `87px`};

  overflow: hidden;

  border: 1px solid #DFDFDF;
  border-radius: 24px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  background-color: #FFFFFF;
`;

const UserPhoto = styled.img`
  display: flex;
  flex-direction: column;

  // width: ${props => props.isNonePicture ? props.isOver ? `64px` : `50px` : `229px`};
  // height: ${props => props.isNonePicture ? props.isOver ? `64px` : `50px` : `229px`};

  // width: ${props => props.isOver ? `227px` : `87px`};
  // height: ${props => props.isOver ? `227px` : `87px`};

  width: 100%;
  height: 100%;
`;

const UserInfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding-left: ${props => props.isOver ? `max(28px, 3.645833333333333vw)` : `max(13px, 4.0625vw)`};

  width: ${props => props.isOver ? `max(419px, 54.55729166666667vw)` : `max(191px, 59.6875vw)`};
  height: ${props => props.isOver ? `229px` : `120px`};

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border-radius: ${props => props.isOver ? `30px` : `13px;`};
  background-color: #FFFFFF
`;

const ColumnLayerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: ${props => props.isOver ? `8px` : `5px`};
`;

const RowLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const UserNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 3px;
`;

const UserName = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: ${props => props.isOver ? `16px` : `10px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #CBCBCB;

  margin: 0 0 0 0;
`;

const UserInfoText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const DeleteButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const ModifyButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #87C8A6;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ExitButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `10px`};

  color: #FF3131;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const MemberNoticeBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(299px, 93.4375vw)`};
  height: ${props => props.isOver ? `186px` : `169px`};

  border-radius: ${props => props.isOver ? `30px` : `15px`};
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const MemberNoticeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: ${props => props.isOver ? `18px` : `4px`};
`;

const MemberNoticeTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #CBCBCB;

  margin: 0 0 0 0;
`;

const MemberNoticeWindow = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: ${props => props.isOver ? `16px 16px 16px 16px` : `12px 12px 12px 12px`};
  margin: 0 0 0 0;

  width: ${props => props.isOver ? `max(604px, 78.64583333333333vw)` : `max(253px, 79.0625vw)`};
  height: ${props => props.isOver ? `66px` : `95px`};

  overflow-x: hidden;
  overflow-y: scroll;

  border: 1px solid #DFDFDF;
  border-radius: ${props => props.isOver ? `24px` : `14px`};
  background-color: #FFFFFF;

  &::-webkit-scrollbar {
    height: 4px;
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const NameModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: ${props => props.isOver ? `232px` : `68px`};

  :hover {
    cursor: pointer;
  }
`;

const Pencel = styled.img`
  width: 16px;
  height: 16px;
`;