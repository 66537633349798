import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

function RulerPage({match}) {
  const [screenWidth, setScreenWidth] = useState(0);

  const CanvasRef = useRef();

  useEffect(() => {
    setScreenWidth(parseInt(match.params.screenWidth));
  }, [match])
  
  useEffect(() => {
    const canvas = CanvasRef.current;
    
    if (canvas) {
      const ctx = canvas.getContext('2d');

      canvas.width = 21000 + screenWidth - 6;
      canvas.height = 60;
  
      for (var i = 0; i <= 3000; i++) {
        ctx.strokeStyle = '#BDBDBD';
        // ctx.strokeStyle = 'red';
        ctx.beginPath();
        ctx.moveTo((screenWidth / 2 ) + (i * 7), 0);
        ctx.lineTo((screenWidth / 2 ) + (i * 7), i % 5 === 0 ? 25 : 17);
  
        if (i % 10 === 0) {
          ctx.font = '14px Arial';
          ctx.fillText(i / 10, ((screenWidth / 2) + (i * 7) - 5), 45);
        }
        ctx.stroke();
      }
    }
  }, [CanvasRef, screenWidth]);

  return (
    <Contanier rulerWidth={21000 + screenWidth}>
      <canvas ref={CanvasRef}/>
    </Contanier>
  )
}

export default RulerPage;

const Contanier = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  left: 0;

  // width: 21000px;
  width: ${props => props.rulerWidth}px;
  height: 70px;

  // overflow-x: hidden;

  justify-content: flex-start;
  align-items: flex-start;

  // overflow-x: hidden;

  background-color: #F9F9F9;
  // background-color: red;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;