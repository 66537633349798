import React, { useEffect } from "react";
import styled from "styled-components";
import KeypadView from "../../Model/Individual/KeypadModel/KeypadView";

export default function KeypadForAttendancePage() {
  // 현재 페이지 내의 채널톡 지우기 //
  useEffect(() => {
    setTimeout(() => {
      document.getElementById('ch-plugin').style.opacity = 0;
      document.getElementById('ch-plugin').style.zIndex = -10;
    }, 100)
  }, [])

  return (
    <TopLevelWrapper>
      <KeypadView/>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  // height: 100%;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;