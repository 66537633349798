import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';

import DefaultProfile from '../../../image/DefaultProfile.png';

const MemberListView = (props) => {
  const { isOver, date, scheduleId, classTime, memberListRendering, setMemberAddClick } = props;

  const history = useHistory();

  const [memberData, setMemberData] = useState([]);

  useEffect(() => {
    if (scheduleId === -1) return;

    (
      async () => {
        const fetchModule = new FetchModule();
        const responseData = await fetchModule.getIndividualData('reserve/board_member/'
                                              + scheduleId + '/'
                                              + classTime + '/'
                                              + date.clone().format('YYYY-MM-DD'), 'GET');
        
        if (responseData.status === 200) setMemberData(responseData.data); 
        else if (responseData.status === 204) setMemberData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, date, scheduleId, classTime, memberListRendering])

  useEffect(() => {
    console.log(memberData);
  }, [memberData])

  return (
    <TopLevelWrapper isOver={isOver}>
        <TopLineWrapper isOver={isOver}>
          <LeftWrapper>
            <Title isOver={isOver}>회원 명단</Title>
            <Count isOver={isOver}>{memberData.length}</Count>
          </LeftWrapper>
          <AddButton isOver={isOver} onClick={() => setMemberAddClick(true)}>추가</AddButton>
        </TopLineWrapper>
      <ViewWrapper isOver={isOver}>
        {
          memberData.map((data, index) => (
            <MemberWrapper key={index}>
              <Profile src={data.profile === '' ? DefaultProfile : data.profile}/>
              <InfoWrapper>
                <Text color='#232323' isOver={isOver}>{data.name}</Text>
                <Text color='#777777' isOver={isOver}>{data.phone[0] 
                                      + data.phone[1]
                                      + data.phone[2]
                                      + '-'
                                      + data.phone[3]
                                      + data.phone[4]
                                      + data.phone[5]
                                      + data.phone[6]
                                      + '-'
                                      + '****'}</Text>
              </InfoWrapper>
            </MemberWrapper>
          ))
        }
      </ViewWrapper>
    </TopLevelWrapper>
  )
}

export default MemberListView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 204px;
  width: ${props => props.isOver ? `max(692px, 90.10416666666667vw)` : `max(296px, 92.5vw)`};
  // height: 364px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 24px;

  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(244px, 76.25vw)`};
  height: 64px;

  overflow-x: scroll;

  // background-color: red;
  &::-webkit-scrollbar {
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 100%;
  width: ${props => props.isOver ? `max(636px, 82.8125vw)` : `max(244px, 76.25vw)`};

  margin-top: 28px;
  margin-bottom: 24px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #232323;

  margin: 0 0 0 0;
`;

const Count = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #FF8B48;

  margin: 0 0 0 0;
`;

const AddButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `14px` : `11px`};

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 16px;

  // margin-bottom: 28px;

  width: 134px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 15px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 2px;
  width: 90px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.isOver ? `12px` : `10px`};

  margin: 0 0 0 0;

  color: ${props => props.color};
`;