import { React, useRef } from 'react';
import styled from 'styled-components';

export default function PhoneInput(props) {
	const { InputRef, value, setValue, placeholder, disabled } = props;

	// const InputRef = useRef();

	function handleClick(e) {
	}

	function handleChange(e) {
    let regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    let result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    
    // if (result === '') setValue('');
    // else setValue(parseInt(result));
    if (result.length <= 4) setValue(result);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

  return (
    <TopLevelWrapper>
      <Input
        ref={InputRef}
        type='text'
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 24%;
`;

const Input = styled.input` 
	font-family: NotoSansKR-Regular;
	font-size: 17px;
	
  display: flex;
  flex-direction: row;

  width: 100%;

  border: 0px;
  background-color: #FFFFFF;

  color: #595959;

	outline: none;

  @media (min-height: 320px) {
    font-size: 17px;
  }
  @media (min-height: 640px) {
    font-size: 19px;
  }
  @media (min-height: 720px) {
    font-size: 21px;
  }
  @media (min-height: 760px) {
    font-size: 22px;
  }
  @media (min-height: 800px) {
    font-size: 23px;
  }
  @media (min-height: 1000px) {
    font-size: 30px;
  }
  @media (min-height: 1200px) {
    font-size: 40px;
  }

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 17px;

		color: #CBCBCB;
	}

  &:disabled {
    background-color: #ECECEC;
  }

  transition: all 0.3s ease-in-out;
`;