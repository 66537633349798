import { React, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export default function ScheduleEmptyModal(props) {
  const { isOver } = props;

  const history = useHistory();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  function onClickYes() {
    history.push('/class');
  }

	return (
    <Background>
      <Modal>
        <ModalInnerWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#333333' hover='default'>수업 일정이 존재하지 않습니다</Text>
          <Text style={{ marginTop: '16px' }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' hover='default'>수업일정이 존재하지 않아 </Text>
          <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' hover='default'>프로그램 설정이 불가능 합니다.</Text>
          <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' hover='default'>수업 페이지에서 수업을 설정해주세요!</Text>
          <ButtonWrapper>
            <YesButton onClick={onClickYes}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#FFFFFF' hover='pointer'>설정하러 가기</Text>
            </YesButton>
          </ButtonWrapper>
        </ModalInnerWrapper>
      </Modal>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 300px;
  height: 220px;

  border-radius: 16px;
  background-color: #FFFFFF;
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 85%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 24px;

  width: 100%;
`;

const YesButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 48px;

  border-radius: 8px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;
