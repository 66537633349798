import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import NormalMovementBox from './NormalMovementBox';
import TagExplanationModal from './TagExplanationModal';
import VideoViewModal from './VideoViewModal';

// Image Import //
import CheckImage from '../../../image/WhiteCheck.png';
import NextRepsImage from '../../../image/NextRepsImage.png';
import InfoIcon from '../../../image/InfomationIcon.png';
// Image Import //

function NormalSectionBox(props) {
  const { index, scale, sectionData } = props;

  const [title, setTitle] = useState('');
  const [scoreKeyword, setScoreKeyword] = useState('점수 없음');
  const [scorePriority, setScorePriority] = useState('');
  const [videoLink, setVideoLink] = useState('');

  const [isClickedTagExplanation, setIsClickedTagExplanation] = useState(false);
  const [isClickedVideoView, setIsClickedVideoView] = useState(false);

  useEffect(() => {
    var newTitle = '';

    if (sectionData.tag === 'ForTime-Rounds') {
      if (sectionData.round === 1) newTitle = 'For Time';
      else newTitle = sectionData.round + ' Rounds For Time';
    }
    else if (sectionData.tag === 'ForTime-Different Reps') {
      newTitle = 'For Time ';

      for (var i = 0; i < sectionData.reps.length; i++) {
        if (i === 0) newTitle += sectionData.reps[i];
        else newTitle += '-' + sectionData.reps[i];
      }
    }
    else if (sectionData.tag === 'AMRAP') {
      var minute = parseInt(sectionData.exerciseTime / 60);
      var second = parseInt(sectionData.exerciseTime % 60);

      if (minute > 0) {
        if (second > 0) newTitle += minute + ' Minute ' + second + ' Second AMRAP';
        else newTitle += minute + ' Minute AMRAP';
      }
      else {
        if (second > 0) newTitle += second + ' Second AMRAP';
        else newTitle += 'AMRAP';
      }
    }
    else if (sectionData.tag === 'EMOM') {
      var minute = parseInt((sectionData.exerciseTime * sectionData.round) / 60);
      var second = parseInt((sectionData.exerciseTime * sectionData.round) % 60);
      
      if (minute > 0) {
        if (second > 0) newTitle += minute + ' Minute ' + second + ' Second EMOM';
        else newTitle += minute + ' Minute EMOM';
      }
      else {
        if (second > 0) newTitle += second + ' Second EMOM';
        else newTitle += 'EMOM';
      }
    }
    else if (sectionData.tag === 'TABATA') {
      var exerciseMinute = parseInt(sectionData.exerciseTime / 60);
      var exerciseSecond = parseInt(sectionData.exerciseTime % 60);
      var restMinute = parseInt(sectionData.restTime / 60);
      var restSecond = parseInt(sectionData.restTime % 60);

      newTitle += sectionData.round + ' Rounds TABATA ';

      if (exerciseMinute > 0) {
        if (exerciseSecond > 0) newTitle += exerciseMinute + ' m ' + exerciseSecond + ' s ON / ';
        else newTitle += exerciseMinute + ' m ON / ';
      }
      else {
        if (exerciseSecond > 0) newTitle += exerciseSecond + ' s ON / ';
        else newTitle += ' 0 s ON / ';
      }

      if (restMinute > 0) {
        if (restSecond > 0) newTitle += restMinute + ' m ' + restSecond + ' s OFF ';
        else newTitle += restMinute + ' m OFF ';
      }
      else {
        if (restSecond > 0) newTitle += restSecond + ' s OFF ';
        else newTitle += ' 0 s OFF ';
      }

      newTitle += 'With no break between movement'
    }
    else if (sectionData.tag === 'SINGLE') {
      if (sectionData.mainExerciseList.length === 1) newTitle += sectionData.mainExerciseList[0].movementName;
      else newTitle += 'Multiple Single';
    }
    else if (sectionData.tag === 'Custom') {
      newTitle += sectionData.customTitle;

      // '점수 없음', '시간', '횟수', '라운드 + 횟수', '거리', '무게', '포인트', '피크와트', '최고 속도' //
      if (sectionData.scoreKeyword === 'Not Scored') setScoreKeyword('점수 없음');
      else if (sectionData.scoreKeyword === 'Time') setScoreKeyword('시간');
      else if (sectionData.scoreKeyword === 'Reps') setScoreKeyword('횟수');
      else if (sectionData.scoreKeyword === 'Rounds And Reps') setScoreKeyword('라운드 + 횟수');
      else if (sectionData.scoreKeyword === 'Distance') setScoreKeyword('거리');
      else if (sectionData.scoreKeyword === 'Weight') setScoreKeyword('무게');
      else if (sectionData.scoreKeyword === 'Points') setScoreKeyword('포인트');
      else if (sectionData.scoreKeyword === 'Peak Watts') setScoreKeyword('피크와트');
      else if (sectionData.scoreKeyword === 'Max Speed') setScoreKeyword('최고 속도');

      if (sectionData.scorePriority === 'heigher') setScorePriority('높을 수록');
      else if (sectionData.scorePriority === 'lower') setScorePriority('낮을 수록');
    }
    // console.log(sectionData);
    // console.log(newTitle);
    setTitle(newTitle);
  }, [sectionData])

  // 동작 영상 모달 열기 클릭시 //
  function onClickMovementVideoViewModal(division, index) {
    if (division === 'buyin') setVideoLink(sectionData.buyinExerciseList[index].movement.videoLinkId);
    else if (division === 'main') setVideoLink(sectionData.mainExerciseList[index].movement.videoLinkId);
    else if (division === 'buyout') setVideoLink(sectionData.buyoutExerciseList[index].movement.videoLinkId);

    setIsClickedVideoView(true);
  }

  return (
    <TopLevelWrapper>
      { isClickedTagExplanation && <TagExplanationModal close={() => setIsClickedTagExplanation(!isClickedTagExplanation)} /> }
      { isClickedVideoView && <VideoViewModal data={videoLink} close={() => setIsClickedVideoView(!isClickedVideoView)}/> }
      <DivisionLine/>
      <SectionDivisionWrapper>
        <SectionNumberBox size={32 + (32 * scale / 100)} fontSize={14 + (14 * scale / 100)}>{String.fromCharCode(index + 65)}</SectionNumberBox>
        <SectionTitleBox blank={24 + (24 * scale / 100)} height={32 + (32 * scale / 100)}>
          <TextWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#333333'>{title}</Text>
          </TextWrapper>
        </SectionTitleBox>
      </SectionDivisionWrapper>
      {
        // Time Limit Setting //
        ( sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps' || sectionData.tag === 'SINGLE' ) &&
        <TimeLimitWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12 + (12 * scale / 100)} color='#595959'>시간 제한</Text>
          <TimeComponentWrapper>
            <CheckBox isClicked={sectionData.isUseTimeLimit}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Medium' fontSize={14 + (9 * scale / 100)} color='#828282'>제한 없음</Text>
            <TimeDataBoxWrapper blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
              <TimeDataBox isClicked={sectionData.isUseTimeLimit} blank={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{parseInt(sectionData.timeLimit / 60) > 0 ? parseInt(sectionData.timeLimit / 60) : 0}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>분</Text>
              </TimeDataBox>
              <TimeDataBox isClicked={sectionData.isUseTimeLimit} blank={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{parseInt(sectionData.timeLimit % 60) > 0 ? parseInt(sectionData.timeLimit % 60) : 0}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>초</Text>
              </TimeDataBox>
            </TimeDataBoxWrapper>
          </TimeComponentWrapper>
          { sectionData.tag !== 'SINGLE' && <DivisionLine /> }
        </TimeLimitWrapper>
        // Time Limit Setting //
      }
      {
        // Buy in Setting //
        sectionData.isUseBuyin && (sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps' || sectionData.tag === 'AMRAP') &&
        <BuyWrapper isAMRAP={sectionData.tag === 'AMRAP'}>
          <BuyCheckBoxWrapper>
            <CheckBox isClicked={sectionData.isUseBuyin}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>Buy in</Text>
          </BuyCheckBoxWrapper>
          {
            sectionData.buyinExerciseList.map((data, idx) => (
              <NormalMovementBox
                key={idx}
                data={data}
                idx={idx}
                tag='None'
                scale={scale}
                division='buyin'
                onClickMovementVideo={onClickMovementVideoViewModal}/>
            ))
          }
        </BuyWrapper>
        // Buy In Setting //
      }
      {/* Main */}
      <MainComponentWrapper>
        { sectionData.isUseBuyin && <DivisionLine/> }
        <MainIconWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12 + (12 * scale / 100)} color='#595959'>메인 운동</Text>
          <Icon size={20 + (20 * scale / 100)} src={InfoIcon} onClick={() => setIsClickedTagExplanation(true)} />
        </MainIconWrapper>
        <MainSelectBoxWrapper>
          <TagBox height={34 + (34 * scale / 100)}>
            <Text fontFamily='Poppins-Medium' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{sectionData.tag.split('-')[0]}</Text>
          </TagBox>
          <TagBox height={34 + (34 * scale / 100)} isEmpty={sectionData.tag.split('-')[1] === undefined}>
            <Text fontFamily='Poppins-Medium' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{sectionData.tag.split('-')[1] === undefined ? '-' : sectionData.tag.split('-')[1]}</Text>
          </TagBox>
        </MainSelectBoxWrapper>
        {
          // Section Tag가 For Time - Rounds일 경우 //
          sectionData.tag === 'ForTime-Rounds' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12 + (12 * scale / 100)} color='#595959'>라운드</Text>
            <RoundBox height={34 + (34 * scale / 100)}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{sectionData.round}</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>R</Text>
            </RoundBox>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 For Time - Different Reps일 경우 //
          sectionData.tag === 'ForTime-Different Reps' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12 + (12 * scale / 100)} color='#595959'>랩 수</Text>
            <RepsInputWrapper>
              {
                sectionData.reps.map((data, idx) => (
                  <RepsWrapper key={idx}>
                    <RepsBox height={34 + (34 * scale / 100)}>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{data}</Text>
                    </RepsBox>
                    {sectionData.reps.length - 1 !== idx && <NextRepsIcon src={NextRepsImage} />}
                  </RepsWrapper>
                ))
              }
            </RepsInputWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 AMRAP일 경우 //
          sectionData.tag === 'AMRAP' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12 + (12 * scale / 100)} color='#595959'>운동 시간</Text>
            <TimeWrapper>
              <TagBox height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{parseInt(sectionData.exerciseTime / 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>분</Text>
              </TagBox>
              <TagBox height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{parseInt(sectionData.exerciseTime % 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>초</Text>
              </TagBox>
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 EMOM일 경우 //
          sectionData.tag === 'EMOM' &&
          <MainExerciseTagWrapper>
            <TimeTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12 + (12 * scale / 100)} color='#595959'>라운드 / 운동 시간</Text>
              <TextInfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12 + (12 * scale / 100)} color='#595959' style={{ marginRight: '4px' }}>총</Text>
                {
                  parseInt(sectionData.round * sectionData.exerciseTime / 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12 + (12 * scale / 100)} color='#6DD49E'>{parseInt(sectionData.round * sectionData.exerciseTime / 60) + '분'}</Text>
                }
                {
                  parseInt(sectionData.round * sectionData.exerciseTime % 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12 + (12 * scale / 100)} color='#6DD49E' style={{ marginLeft: '4px' }}>{parseInt(sectionData.round * sectionData.exerciseTime % 60) + '초'}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={12 + (12 * scale / 100)} color='#595959'>동안 진행됩니다</Text>
              </TextInfoWrapper>
            </TimeTextWrapper>
            <RoundBox height={34 + (34 * scale / 100)}> 
              <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{sectionData.round}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>R</Text>
            </RoundBox>
            <TimeWrapper>
              <TagBox height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{parseInt(sectionData.exerciseTime / 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>분</Text>
              </TagBox>
              <TagBox height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{parseInt(sectionData.exerciseTime % 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>초</Text>
              </TagBox>
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 TABATA일 경우 //
          sectionData.tag === 'TABATA' &&
          <MainExerciseTagWrapper>
            <TimeTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12 + (12 * scale / 100)} color='#595959'>동작 별 라운드</Text>
              <TextInfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12 + (12 * scale / 100)} color='#595959' style={{ marginRight: '4px' }}>총</Text>
                {
                  parseInt(((sectionData.round * sectionData.exerciseTime) + (sectionData.round > 0 ? (sectionData.round - 1) * sectionData.restTime : 0)) / 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12 + (12 * scale / 100)} color='#6DD49E'>{parseInt(((sectionData.round * sectionData.exerciseTime) + (sectionData.round > 0 ? (sectionData.round - 1) * sectionData.restTime : 0)) / 60) + '분'}</Text>
                }
                {
                  parseInt(((sectionData.round * sectionData.exerciseTime) + (sectionData.round > 0 ? (sectionData.round - 1) : 0 * sectionData.restTime)) % 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12 + (12 * scale / 100)} color='#6DD49E' style={{ marginLeft: '4px' }}>{parseInt(((sectionData.round * sectionData.exerciseTime) + ((sectionData.round > 0 ? (sectionData.round - 1) : 0) * sectionData.restTime)) % 60) + '초'}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={12 + (12 * scale / 100)} color='#595959'>동안 진행됩니다</Text>
              </TextInfoWrapper>
            </TimeTextWrapper>
            <RoundBox height={34 + (34 * scale / 100)}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{sectionData.round}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>R</Text>
            </RoundBox>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12 + (12 * scale / 100)} color='#595959'>동작 별 운동 시간</Text>
            <TimeWrapper>
              <TagBox height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{parseInt(sectionData.exerciseTime / 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>분</Text>
              </TagBox>
              <TagBox height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{parseInt(sectionData.exerciseTime % 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>초</Text>
              </TagBox>
            </TimeWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12 + (12 * scale / 100)} color='#595959'>동작 별 쉬는 시간</Text>
            <TimeWrapper>
              <TagBox height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{parseInt(sectionData.restTime / 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>분</Text>
              </TagBox>
              <TagBox height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{parseInt(sectionData.restTime % 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>초</Text>
              </TagBox>
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          sectionData.tag === 'Custom' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12 + (12 * scale / 100)} color='#595959'>점수 기준</Text>
            <MainSelectBoxWrapper>
              <TagBox height={34 + (34 * scale / 100)}>
                <Text fontFamily='Poppins-Medium' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{scoreKeyword}</Text>
              </TagBox>
              <TagBox height={34 + (34 * scale / 100)} isEmpty={scoreKeyword === '점수 없음' || scoreKeyword === '피크와트' || scoreKeyword === '최고 속도'}>
                <Text fontFamily='Poppins-Medium' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{(scoreKeyword === '점수 없음' || scoreKeyword === '피크와트' || scoreKeyword === '최고 속도') ? '-' : scorePriority}</Text>
              </TagBox>
            </MainSelectBoxWrapper>
            <DivisionLine />
            <Text fontFamily='NotoSansKR-Regular' fontSize={12 + (12 * scale / 100)} color='#595959'>운동 설명</Text>
            <DescriptionBox>
              <DescriptionText fontSize={14 + (14 * scale / 100)}>{sectionData.description}</DescriptionText>
            </DescriptionBox>
          </MainExerciseTagWrapper>
        }
        {
          sectionData.tag !== 'Custom' &&
          <MainExerciseDesignWrapper>
            <DivisionLine />
            <MainExerciseTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12 + (12 * scale / 100)} color='#595959'>메인 운동 디자인</Text>
            </MainExerciseTextWrapper>
            {
              sectionData.mainExerciseList.map((data, idx) => (
                <NormalMovementBox
                  key={idx}
                  data={data}
                  idx={idx}
                  scale={scale}
                  tag={sectionData.tag}
                  division='main'
                  onClickMovementVideo={onClickMovementVideoViewModal} />
              ))
            }
            { (sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps') && <DivisionLine /> }
        </MainExerciseDesignWrapper>
}
      </MainComponentWrapper>
      {/* Main */}
      {
        // Buy out Setting //
        sectionData.isUseBuyout && (sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps') &&
        <BuyWrapper>
          <BuyCheckBoxWrapper>
            <CheckBox isClicked={sectionData.isUseBuyout}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>Buy out</Text>
          </BuyCheckBoxWrapper>
          {
            sectionData.buyoutExerciseList.map((data, idx) => (
              <NormalMovementBox
                key={idx}
                data={data}
                idx={idx}
                scale={scale}
                tag='None'
                division='buyout'
                onClickMovementVideo={onClickMovementVideoViewModal}/>
            ))
          }
        </BuyWrapper>
        // Buy out Setting //
      }
    </TopLevelWrapper>
  )
}

export default NormalSectionBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 97.71%;
`;

const SectionDivisionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // margin-top: 24px;

  width: 100%;
`;

const SectionNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 32px;
  // height: 32px;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  border-radius: 6px;
  background-color: #6DD49E;

  font-family: Poppins-Bold;
  // font-size: 14px;
  font-size: ${props => props.fontSize}px;

  color: #FFFFFF;
`;

const SectionTitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: calc(92.21% - 24px);
  // height: 32px;
  width: calc(92.21% - ${props => props.blank}px);
  height: ${props => props.height}px;

  padding: 0px 12px 0px 12px;

  font-family: NotoSansKR-Medium;
  font-size: 14px;
  
  color: #333333;

  overflow: hidden;
  
  border-radius: 6px;
  background-color: #F9F9F9;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  max-height: 39px;

  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const MainExerciseDesignWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // height: 1px;

  margin-top: 24px;
  margin-bottom: 24px;

  border: 1px solid #ECECEC;
`;

const BuyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  margin-top: ${props => props.isAMRAP ? `24px` : `0px`};
`;

const BuyCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  // margin-top: 24px;
  gap: 6px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};
`;

const Check = styled.img`
  width: 10px;
  height: 9px;
`;

const MainComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const MainSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 9px;
`;

const MainIconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const TagBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(47.66% - 34px);
  // height: 34px;
  height: ${props => props.height}px;

  margin: 0 0 0 0;
  padding: 0 16px 0 16px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: ${props => props.isEmpty ? `#ECECEC` : `#FFFFFF`};
`;

const RoundBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(100% - 34px);
  height: ${props => props.height}px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  margin: 0 0 0 0;
  padding: 0 16px 0 16px;
`;

const Icon = styled.img`
  // width: 20px;
  // height: 20px;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  :hover {
    cursor: pointer;
  }
`;

const MainExerciseTagWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
  margin-top: 24px;

  width: 100%;
`;

const MainExerciseTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const RepsInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  width: 100%;
`;

const RepsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: 25.29%;
  width: 25%;
  // background-color: red;
  gap: 4px;
  margin-top: 8px;
`;

const RepsBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(84.61% - 34px);
  // height: 34px;
  height: ${props => props.height}px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  margin: 0 0 0 0;
  padding: 0 16px 0 16px;
`;

const NextRepsIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TimeTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TimeLimitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  
  gap: 6px;

  margin-top: 24px;
`;

const TimeComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 7px;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`;

const TimeDataBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(82.29% - ${props => props.blank}px);
  // height: 34px;
  height: ${props => props.height}px;

  margin: 0 0 0 0;
`;

const TimeDataBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: calc(47.11% - 34px);
  // height: 34px;
  width: calc(47.11% - ${props => props.blank}px);
  height: 100%;

  padding: 0 16px 0 16px;
  margin: 0 0 0 0;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: ${props => props.isClicked ? `#ECECEC` : `#FFFFFF`};
`;

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 34px);
  height: auto;
  min-height: 497px;

  padding: 8px 16px 8px 16px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const DescriptionText = styled.pre`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.fontSize}px;

  color: #595959;

  margin: 0 0 0 0;
`;