import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { loadTossPayments } from '@tosspayments/payment-sdk';

import FetchModule from '../../Model/Share/Network/FetchModule';

import ProductLogo from '../../image/ProductLogo.png';
import ExitButtonImg from '../../image/BlackDeleteButton.png';
import TossLogoImg from '../../image/TossLogo.png';

function PaymentPage() {

  // 테스트 클라이언트 키 //
  // const clientKey = 'test_ck_dP9BRQmyarYAYvaBWd9rJ07KzLNk';
  // 라이브 클라이언트 키 (일반 결제) //
  const normalClientKey = 'live_ck_dP9BRQmyarYOKL40JyW3J07KzLNk';
  // 라이브 클라이언트 키 (빌링키)//
  const billingClientKey = 'live_ck_4vZnjEJeQVxmOL22lRqrPmOoBN0k';

  const history = useHistory();

  const [data, setData] = useState(undefined);
  const [isInProgress, setIsInProgress] = useState(false);
  const [normalPaymentClick, setNormalPaymentClick] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  // Data API Call //
  useEffect(() => {
    var merchantUid = window.sessionStorage.getItem("MerchantUid");
    
    (
      async () => {
        const fetchModule = new FetchModule();
        const response = await fetchModule.postData('sales/check/merchant/' + merchantUid, 'POST');
        // console.log(response);
        if (response.status === 200) {
          setData(response.data[0]);
          
          window.sessionStorage.setItem("StoreId", response.data[0].storeId);
          window.sessionStorage.setItem("StoreName", response.data[0].storeName);
          window.sessionStorage.setItem("ItemName", response.data[0].itemName);
        }
        else if (response.status === 201) {
          // 데이터 없음
          alert("유효한 데이터가 아닙니다.");
          window.open("about:blank", "_self");
          window.close();

          return;
        }
        else if (response.status === 202) {
          // 이미 처리된 데이터
          alert("이미 결제가 완료되었습니다.");
          window.open("about:blank", "_self");
          window.close();

          return;
        }
        else {
          // 데이터 없음
          alert("유효한 데이터가 아닙니다.");
          window.open("about:blank", "_self");
          window.close();

          return;
        }
      }
    )();
  }, [])

  // Import Payment Tool //
  useEffect(() => {
    const jquery = document.createElement('script');
    jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";

    const iamport = document.createElement('script');
    iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.2.0.js";

    document.head.appendChild(jquery);
    document.head.appendChild(iamport);

    return () => {
      document.head.removeChild(jquery);
      document.head.removeChild(iamport);
    }
  }, []);

  
  async function onClickPayment(className) {
    
    if (className === 'toss') {
    (
      async () => {
        const fetchModule = new FetchModule();
        const response = await fetchModule.postData('sales/change/merchant/' + data.merchantUid + "/normal/" + data.cardPrice, 'POST');
        // console.log(response.data);
        if (response.status === 200) {
          const { IMP } = window;
          IMP.init('imp22047599');

          setIsClicked(true);

          // Payment Data
          const requestData = {
            pg: 'tosspay',
            pay_method: 'card',
            name: data.itemName,
            amount: data.cardPrice,
            // amount: '100',
            merchant_uid: data.merchantUid,
            // m_redirect_url: 'https://m.link-zone.io/complete/'
            m_redirect_url: 'https://m.link-zone.io/complete/'
          }

          IMP.request_pay(requestData, callback);
        }
        else if (response.status === 201) {
          // 데이터 없음
          alert("유효한 데이터가 아닙니다.");
          return;
        }
        else if (response.status === 202) {
          // 이미 처리된 데이터
          alert("이미 결제가 완료되었습니다.");
          return;
        }
        else if (response.status === 203) {
          // 현금결제가 같이있는 경우
          alert("복합결제( 현금결제가 포함된 경우 )는 결제 서비스 이용이 불가능합니다.");
          return;
        }
        else if (response.status === 204) {
          alert("결제서비스는 31일 이하의 회원권에만 적용됩니다!");
          return;
        }
        else if (response.status === 205) {
          alert("이미 진행중인 정기결제 내역이 존재합니다.");
          return;
        }
        else if (response.status === 207) {
          alert("이 체육관은 현장결제만 진행하고 있어요!\n현장에서 결제를 진행하시기 바랍니다");
          return;
        }
        else {
          // 데이터 없음
          alert("유효한 데이터가 아닙니다.");
          return;
        }
      }
    )();
    }
    else {
      // var data = window.sessionStorage.getItem("RegistData");
      // console.log(JSON.parse(data));
      const tossPayments = await loadTossPayments(normalClientKey);

      (
        async () => {
          const fetchModule = new FetchModule();
          const response = await fetchModule.postData('sales/change/merchant/' + data.merchantUid + "/normal/" + data.cardPrice, 'POST');
          // console.log(response.data);
          if (response.status === 200) {
            // saveRegistData(data.merchantUid);

            tossPayments.requestPayment('카드', { // 결제 수단 파라미터
              // 결제 정보 파라미터
              amount: data.cardPrice,
              orderId: data.merchantUid,
              orderName: data.itemName,
              successUrl: 'https://m.link-zone.io/payment/coach/complete/' + window.sessionStorage.getItem('PureToken') + '/success',
              failUrl: 'https://m.link-zone.io/payment/coach/complete/' + window.sessionStorage.getItem('PureToken') + '/fail',
              // successUrl: 'http://localhost:3000/payment/coach/complete/' + window.sessionStorage.getItem('PureToken') + '/success',
              // failUrl: 'http://localhost:3000/payment/coach/complete/' + window.sessionStorage.getItem('PureToken') + '/fail',
            }).catch(function (error) {  
              if (error.code === 'USER_CANCEL') {
                alert("결제 진행중에 취소하셨습니다.");
              }
            })
          }
          else if (response.status === 201) {
            // 데이터 없음
            alert("유효한 데이터가 아닙니다.");
            return;
          }
          else if (response.status === 202) {
            // 이미 처리된 데이터
            alert("이미 결제가 완료되었습니다.");
            return;
          }
          else if (response.status === 203) {
            // 현금결제가 같이있는 경우
            alert("복합결제( 현금결제가 포함된 경우 )는 결제 서비스 이용이 불가능합니다.");
            return;
          }
          else if (response.status === 204) {
            alert("결제서비스는 31일 이하의 회원권에만 적용됩니다!");
            return;
          }
          else if (response.status === 205) {
            alert("이미 진행중인 정기결제 내역이 존재합니다.");
            return;
          }
          else if (response.status === 207) {
            alert("이 체육관은 현장결제만 진행하고 있어요!\n현장에서 결제를 진행하시기 바랍니다");
            return;
          }
          else {
            // 데이터 없음
            alert("유효한 데이터가 아닙니다.");
            return;
          }
        }
      )();
    }
  }

  async function onClickRegularPayment() {
    // var data = window.sessionStorage.getItem("RegistData");
    // console.log(JSON.parse(data));
    const tossPayments = await loadTossPayments(billingClientKey);

    (
      async () => {
        const fetchModule = new FetchModule();
        const response = await fetchModule.postData('sales/change/merchant/' + data.merchantUid + "/regular/" + data.cardPrice, 'POST');
        // console.log(response.data);
        if (response.status === 200) {
          // saveRegistData(response.data[0].merchantUid);
          var success = await window.localStorage.getItem('PureToken') + '/success';
          var fail = await window.localStorage.getItem('PureToken') + '/fail';

          tossPayments.requestBillingAuth('카드', { // 결제 수단 파라미터
            // 결제 정보 파라미터
            customerKey: response.data[0].userId,
            successUrl: 'https://m.link-zone.io/payment/coach/subscribe/' + success,
            failUrl: 'https://m.link-zone.io/payment/coach/subscribe/' + fail,
            // successUrl: 'http://localhost:3000/payment/coach/subscribe/' + success,
            // failUrl: 'http://localhost:3000/payment/coach/subscribe/' + fail,
          }).catch(function (error) {
            if (error.code === 'USER_CANCEL') {
              alert("결제 진행중에 취소하셨습니다.");
            }
          })
        }
        else if (response.status === 201) {
          // 데이터 없음
          alert("유효한 데이터가 아닙니다.");
          return;
        }
        else if (response.status === 202) {
          // 이미 처리된 데이터
          alert("이미 결제가 완료되었습니다.");
          return;
        }
        else if (response.status === 203) {
          // 현금결제가 같이있는 경우
          alert("복합결제( 현금결제가 포함된 경우 )는 결제 서비스 이용이 불가능합니다.");
          return;
        }
        else if (response.status === 204) {
          alert("정기결제는 31일 이하의 회원권에만 적용됩니다!");
          return;
        }
        else if (response.status === 205) {
          alert("이미 진행중인 정기결제 내역이 존재합니다.");
          return;
        }
        else if (response.status === 206) {
          alert("정기결제를 지원하지 않는 회원권 입니다!");
          return;
        }
        else if (response.status === 207) {
          alert("이 체육관은 현장결제만 진행하고 있어요!\n현장에서 결제를 진행하시기 바랍니다");
          return;
        }
        else {
          // 데이터 없음
          alert("유효한 데이터가 아닙니다.");
          return;
        }
      }
    )();
  }


  // function onClickPayment(className) {
  //   // var str = "linkcoach://membershipPurchase?imp_uid=imp1234567890&merchant_uid=20220202-1-233216";
  //   // alert(str);
  //   // window.location.href = str;

  //   const { IMP } = window;
  //   IMP.init('imp22047599');

  //   setIsClicked(true);

  //   // Payment Data
  //   const requestData = {
  //     pg: className === 'toss' ? 'tosspay' : 'jtnet',
  //     pay_method: 'card',
  //     name: data.itemName,
  //     // amount: data.cardPrice,
  //     amount: '100',
  //     merchant_uid: data.merchantUid,
  //     // m_redirect_url: 'http://172.30.1.38:3001/complete/'
  //     m_redirect_url: 'https://m.link-zone.io/complete/'
  //   }
  //   // console.log(requestData);
  //   IMP.request_pay(requestData, callback);
  // }

  // function onClickRegularPayment() {
  //   var merchantUid = window.sessionStorage.getItem("MerchantUid");

  //   (
  //     async () => {
  //       const fetchModule = new FetchModule();
  //       const response = await fetchModule.postData('sales/change/merchant/' + merchantUid + "/regular", 'POST');
  //       console.log(response.data);
  //       if (response.status === 200) {
  //         setData(response.data[0]);
  //         // alert(response.data[0].merchantUid);
  //         // alert(response.data[0].userId);
  //         const { IMP } = window;
  //         IMP.init('imp22047599');

  //         setIsClicked(true);
  //         // Payment Data
  //         const requestData = {
  //           pg: 'jtnet.tpaytest2m',
  //           pay_method: 'card',
  //           name: response.data[0].itemName,
  //           // amount: data.cardPrice,
  //           amount: '1000',
  //           merchant_uid: response.data[0].merchantUid,
  //           customer_uid: response.data[0].userId,
  //           // m_redirect_url: 'http://172.30.1.38:3001/complete/'
  //           m_redirect_url: 'https://m.link-zone.io/complete/'
  //         }
  //         IMP.request_pay(requestData, callback);
  //       }
  //       else if (response.status === 201) {
  //         // 데이터 없음
  //         alert("유효한 데이터가 아닙니다.");
  //         window.open("about:blank", "_self");
  //         window.close();

  //         return;
  //       }
  //       else if (response.status === 202) {
  //         // 이미 처리된 데이터
  //         alert("이미 결제가 완료되었습니다.");
  //         window.open("about:blank", "_self");
  //         window.close();

  //         return;
  //       }
  //       else if (response.status === 203) {
  //         // 현금결제가 같이있는 경우
  //         alert("복합결제( 현금결제가 포함된 경우 )는 정기결제 등록이 불가능합니다.");
  //         window.open("about:blank", "_self");
  //         window.close();

  //         return;
  //       }
  //       else if (response.status === 204) {
  //         alert("정기결제는 31일 이하의 회원권에만 적용됩니다!");
  //         return;
  //       }
  //       else if (response.status === 205) {
  //         alert("이미 진행중인 정기결제 내역이 존재합니다.");
  //         window.open("about:blank", "_self");
  //         window.close();

  //         return;
  //       }
  //       else {
  //         // 데이터 없음
  //         alert("유효한 데이터가 아닙니다.");
  //         window.open("about:blank", "_self");
  //         window.close();

  //         return;
  //       }
  //     }
  //   )();
  // }

  function callback(response) {
    const { success, error_msg, imp_uid, merchant_uid, pay_method, paid_amount, status } = response;

    if (success) {
      console.log('error_msg : ' + error_msg);
      console.log('imp_uid : ' + imp_uid);
      console.log('merchant_uid : ' + merchant_uid);
      console.log('pay_method : ' + pay_method);
      console.log('paid_amount : ' + paid_amount);
      console.log('status : ' + status);

      var requestData = {
        imp_uid: imp_uid,
        merchant_uid: data.merchantUid
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/effectiveness', 'POST', requestData);

          if (responseData.status === 200) {
            // onRegist();
            // alert("성공!");
          }
          else {
            // cancel();
          }
        }
      )();
    }
    else {
      console.log('error_msg : ' + error_msg);
      console.log('imp_uid : ' + imp_uid);
      console.log('merchant_uid : ' + merchant_uid);
      console.log('pay_method : ' + pay_method);
      console.log('paid_amount : ' + paid_amount);
      console.log('status : ' + status);
    }

    setIsClicked(false);
  }

  function exitClick() {
    window.open("about:blank", "_self");
    window.close();
  }

  // 일반결제 버튼 클릭시 ( 페이플 ) //
  function onClickPaymentOnPayple() {
    if (isInProgress) {
      alert('결제를 진행중입니다!');
      return;
    }

    setIsInProgress(true);

    const obj = {};

    obj.PCD_PAY_TYPE = 'card';
    // obj.PCD_PAY_WORK = 'PAY'
    obj.PCD_PAY_WORK = 'CERT';
    obj.PCD_CARD_VER = '02';
    obj.PCD_PAYER_AUTHTYPE = 'sms';

    obj.PCD_PAY_GOODS = data.itemName;
    obj.PCD_PAY_TOTAL = data.cardPrice;
    // obj.PCD_PAY_TOTAL = 100;
    obj.PCD_PAY_OID = data.merchantUid;


    obj.callbackFunction = getResult;
    // obj.clientKey = 'test_DF55F29DA654A8CBC0F0A9DD4B556486';
    obj.clientKey = '0F44C92BFB07F1BE60C5235A7254316D';
    
    window.PaypleCpayAuthCheck(obj);
  }

  // 결제 이후 콜백 //
  function getResult(res) {
    if (res.PCD_PAY_RST === 'success' && res.PCD_PAY_CODE === '0000') {
      let authKey = res.PCD_AUTH_KEY;
      let reqKey = res.PCD_PAY_REQKEY;
      let billingKey = res.PCD_PAYER_ID;
      let merchant_uid = res.PCD_PAY_OID;
      let approval = res.PCD_PAY_TIME;
      let cardPrice = parseInt(res.PCD_PAY_TOTAL);
      let approval_date = approval.slice(0, 4) + '-' + approval.slice(4, 6) + '-' + approval.slice(6, 8) + 'T' + approval.slice(8, 10) + ':' + approval.slice(10, 12) + ':' + approval.slice(12, 14);


      let requestData = {
        merchantUid: merchant_uid,
        approvalDate: approval_date,
        cardPrice: cardPrice,
        division: 'Normal',
        authKey: authKey,
        reqKey: reqKey,
        billingKey: billingKey
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('sales/payple/effectiveness', 'POST', requestData);

          // console.log(responseData);
          if (responseData.status === 200) alert("결제가 완료되었습니다!\n앱에서 등록을 마무리해주세요!");
          else if (responseData.status === 201) alert('내역이 존재하지 않습니다');
          else if (responseData.status === 202) alert('데이터가 올바르지 않습니다');
          else if (responseData.status === 203) alert(responseData.error);

          setIsInProgress(false);

          window.location.href = "linkcoach://membershipPurchase/";

          setTimeout(() => (
            window.open("about:blank", "_self"),
            window.close()
          ), 5000)
        }
      )();
    }
    else {
      console.log('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
      alert('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);

      setIsInProgress(false);

      window.location.href = "linkcoach://membershipPurchase/";

      setTimeout(() => (
        window.open("about:blank", "_self"),
        window.close()
      ), 5000)
    }
  }

  return (
    data !== undefined &&
    <TopLevelWrapper>
      <ModalView isClicked={isClicked}>
        <TotalWrapper>
          <TitleLineWrapper>
            <Title>상품 정보</Title>
            <ExitButton src={ExitButtonImg} onClick={() => exitClick()}/> 
          </TitleLineWrapper>
          <ProductInfoBox>
            <Logo src={ProductLogo} />
            <TextWrapper>
              <TextEng>Link Zone</TextEng>
              <TextKor>{data.itemName}</TextKor>
              <TextExplanation>{data.validity} 일</TextExplanation>
            </TextWrapper>
          </ProductInfoBox>
          <PriceWrapper>
            <Title>최종 결제 금액</Title>
            <LayerWrapper style={{ marginTop: `24px` }}>
              <TextNormal style={{ color: `#595959` }}>결제 금액</TextNormal>
              <DivisionLine />
              <TextNormal style={{ color: `#595959` }}>{data.cardPrice.toLocaleString('ko-KR')}원</TextNormal>
            </LayerWrapper>
            <LayerWrapper style={{ marginTop: `8px` }}>
              <TextBold style={{ color: `#232323` }}>최종 금액</TextBold>
              <DivisionLine />
              <TextBold style={{ color: `#FF8B48` }}>{data.cardPrice.toLocaleString('ko-KR')}원</TextBold>
            </LayerWrapper>
          </PriceWrapper>
            {
              normalPaymentClick ?
                <ButtonWrapper>
                  <NormalPayment onClick={() => onClickPayment('jtnet')}>카드결제</NormalPayment>
                  <NormalPayment onClick={() => onClickPayment('toss')}>
                    <TossLogo src={TossLogoImg}/>
                  </NormalPayment>
                </ButtonWrapper>
                :
                <ButtonWrapper>
                  {/* <NormalPayment onClick={() => onClickPayment('jtnet')}>일반 결제</NormalPayment> */}
                  <NormalPayment onClick={onClickPaymentOnPayple}>일반 결제</NormalPayment>
                  <RegularPayment onClick={onClickRegularPayment}>정기 결제</RegularPayment>
                </ButtonWrapper>
            }
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default PaymentPage;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background-color: #FDFCFB;
`;

const ModalView = styled.div`
  display: ${props => props.isClicked ? `none` : `flex`};
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 378px;
  width: 300px;
  height: 416px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 100%;
  width: 85.18%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ExitButton = styled.img`
  width: 16px;
  height: 16px;

  :hover {
    cursor: pointer;
  }
`;

const ProductInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 16px;
  margin: 24px 0 0 0;
  padding: 0 16px 0 16px;

  // width: 290px;
  width: 76.71%;
  height: 100px;

  border-radius: 15px;
  background-color: #FF8B48;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TextEng = styled.p`
  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const TextKor = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const TextExplanation = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: rgba(255, 255, 255, 0.5);

  margin: 0 0 0 0;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin: 24px 0 0 0;
  padding: 24px 0 0 0;

  // width: 322px;
  width: 85.18%;

  border-top: 1px solid #ECECEC;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TextBold = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  // color: #232323;

  margin: 0 0 0 0;
`;

const TextNormal = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #232323;

  margin: 0 0 0 0;
`;

const DivisionLine = styled.div`
  // width: 180px;
  width: 30%;
  height: 1px;

  border-top: 1px dashed #ECECEC;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // gap: 12px;
  margin-top: 36px;

  width: 85.18%;
`;

const NormalPayment = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 48.13%;
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #BDBDBD;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
    color: #FF8B48;
  }
`;

const RegularPayment = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 155px;
  width: 48.13%;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const TossLogo = styled.img`
  width: 55.38px;
  height: 32px;
`;